import {Popover} from 'antd'
import React from 'react'
import avatars from 'src/_metronic/assets/media/avatars/300-1.jpg'
import Search from 'src/components/base/search'
import {PageLink} from 'src/constants'

const ModalSearch = () => {
  return (
    <Popover
      placement='bottomRight'
      content={
        <div className='p-8 overflow-hidden w-300px w-md-325px'>
          <div data-kt-search-element='wrapper'>
            <div data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {/* start:: title */}
                <h3 className='fs-5 text-muted m-0 pb-5'>
                  Users
                </h3>
                {/* end:: title */}
                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <img src={avatars} alt='' />
                  </div>
                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Karina Clark</span>
                    <span className='fs-7 fw-semibold text-muted'>Marketing Manager</span>
                  </div>
                </a>
                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <img src={avatars} alt='' />
                  </div>
                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Olivia Bold</span>
                    <span className='fs-7 fw-semibold text-muted'>Software Engineer</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <img src='assets/media/avatars/300-9.jpg' alt='' />
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Ana Clark</span>
                    <span className='fs-7 fw-semibold text-muted'>UI/UX Designer</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <img src='assets/media/avatars/300-14.jpg' alt='' />
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Nick Pitola</span>
                    <span className='fs-7 fw-semibold text-muted'>Art Director</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <img src='assets/media/avatars/300-11.jpg' alt='' />
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Edward Kulnic</span>
                    <span className='fs-7 fw-semibold text-muted'>System Administrator</span>
                  </div>
                </a>

                <h3
                  className='fs-5 text-muted m-0 pt-5 pb-5'
                  data-kt-search-element='category-title'
                >
                  Customers
                </h3>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <img
                        className='w-20px h-20px'
                        src='assets/media/svg/brand-logos/volicity-9.svg'
                        alt=''
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Company Rbranding</span>
                    <span className='fs-7 fw-semibold text-muted'>UI Design</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <img
                        className='w-20px h-20px'
                        src='assets/media/svg/brand-logos/tvit.svg'
                        alt=''
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Company Re-branding</span>
                    <span className='fs-7 fw-semibold text-muted'>Web Development</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <img
                        className='w-20px h-20px'
                        src='assets/media/svg/misc/infography.svg'
                        alt=''
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Business Analytics App</span>
                    <span className='fs-7 fw-semibold text-muted'>Administration</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <img
                        className='w-20px h-20px'
                        src='assets/media/svg/brand-logos/leaf.svg'
                        alt=''
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>EcoLeaf App Launch</span>
                    <span className='fs-7 fw-semibold text-muted'>Marketing</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <img
                        className='w-20px h-20px'
                        src='assets/media/svg/brand-logos/tower.svg'
                        alt=''
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-semibold'>
                    <span className='fs-6 fw-semibold'>Tower Group Website</span>
                    <span className='fs-7 fw-semibold text-muted'>Google Adwords</span>
                  </div>
                </a>

                <h3
                  className='fs-5 text-muted m-0 pt-5 pb-5'
                  data-kt-search-element='category-title'
                >
                  Projects
                </h3>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-notepad fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='fs-6 fw-semibold'>Si-Fi Project by AU Themes</span>
                    <span className='fs-7 fw-semibold text-muted'>#45670</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-frame fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='fs-6 fw-semibold'>Shopix Mobile App Planning</span>
                    <span className='fs-7 fw-semibold text-muted'>#45690</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-message-text-2 fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='fs-6 fw-semibold'>Finance Monitoring SAAS Discussion</span>
                    <span className='fs-7 fw-semibold text-muted'>#21090</span>
                  </div>
                </a>

                <a
                  href={PageLink.AUTH_LOGIN}
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-profile-circle fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='fs-6 fw-semibold'>Dashboard Analitics Launch</span>
                    <span className='fs-7 fw-semibold text-muted'>#34560</span>
                  </div>
                </a>
              </div>
            </div>

            <div className='' data-kt-search-element='main'>
              <div className='d-flex flex-stack fw-semibold mb-4'>
                <span className='text-muted fs-6 me-2'>Recently Searched:</span>

                <div className='d-flex' data-kt-search-element='toolbar'>
                  <div
                    data-kt-search-element='preferences-show'
                    className='btn btn-icon w-20px btn-sm btn-active-color-primary me-2 data-bs-toggle='
                    title='Show search preferences'
                  >
                    <i className='ki-outline ki-setting-2 fs-2'></i>
                  </div>

                  <div
                    data-kt-search-element='advanced-options-form-show'
                    className='btn btn-icon w-20px btn-sm btn-active-color-primary me-n1'
                    data-bs-toggle='tooltip'
                    title='Show more search options'
                  >
                    <i className='ki-outline ki-down fs-2'></i>
                  </div>
                </div>
              </div>

              <div className='scroll-y mh-200px mh-lg-325px'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-laptop fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      BoomApp by Keenthemes
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#45789</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-chart-simple fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      "Kept API Project Meeting
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#84050</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-chart fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      "KPI Monitoring App Launch
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#84250</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-chart-line-down fs-2 text-primary'></i>
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      Project Reference FAQ
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#67945</span>
                  </div>
                </div>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-sms fs-2 text-primary'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      "FitPro App Development
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#84250</span>
                  </div>
                </div>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-bank fs-2 text-primary'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      Shopix Mobile App
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#45690</span>
                  </div>
                </div>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <i className='ki-outline ki-chart-line-down fs-2 text-primary'></i>
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <a
                      href={PageLink.AUTH_LOGIN}
                      className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                    >
                      "Landing UI Design" Launch
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#24005</span>
                  </div>
                </div>
              </div>
            </div>
            <div data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <i className='ki-outline ki-search-list fs-4x opacity-50'></i>
              </div>
              <div className='pb-15 fw-semibold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>
        </div>
      }
      trigger='click'
      arrow={false}
    >
      <>
        <Search bgSearch={false}/>
      </>
    </Popover>
  )
}

export default ModalSearch
