import ActionCell from 'src/components/base/action/ActionCell'

interface IProps {
  Action: any
  data: any
}
const NotificationAction = ({Action, data}: IProps) => {
  return (
    <>
      <ActionCell>
        <div className='menu-item px-3' onClick={() => Action('edit', data)}>
          <div className='menu-link px-3'>Edit</div>
        </div>
      </ActionCell>
    </>
  )
}
export default NotificationAction
