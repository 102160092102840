import {zodResolver} from '@hookform/resolvers/zod'
import {Select} from 'antd'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTIcon} from 'src/_metronic/helpers'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import {
  DEFAULT_SELECT_ALL,
  FILTER_SELECTALL_SORTBY,
  PageLink,
} from 'src/constants'
import {LANG_ENTRANCE_TEST, LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs, Role} from 'src/type'
import {z} from 'zod'
import TableTest from './tableTest '
import {replaceValueAll} from 'src/utils/string'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import useChecked from 'src/hooks/use-checked'
import {ICourseCategoies} from 'src/type/courses'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import ModalImportClasses from 'src/components/classes/ModalImportClasses'
import {EntranceApi} from 'src/apis/entrance-test'
import {CoursesAPI} from 'src/apis/courses'
import {OPTIONS_SUBJECTS} from 'src/constants/classes'
import {OPTIONS_OF_STATUS} from 'src/constants/entrancetest'
import {TITLE_OPTIONS_ENTRANCE} from 'src/constants/entrancetest'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_QUIZ_GR } from 'src/constants/permission'

const {Option} = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Entrance Test',
  },
  {
    link: '',
    title: TITLE_OPTIONS_ENTRANCE.listEntrance,
  },
]
const fieldNames = ['name', 'course_category_id', 'course_level_id', 'subject','sortType','fromDate','toDate','status']
const initialValues: any = {
  name: '',
  course_category_id: '',
  course_level_id: '',
  subject: '',
  sortType:'',
  fromDate:'',
  toDate:'',
  status:'',
  quizType: 'ENTRANCE_TEST',
}
const EntranceTestList = () => {
  const [EntranceTestList, setEntranceTestList] = useState<any>({
    quizzes: [{}],
    metadata: {},
  })
  const { profileMe } = useUserContext()
  const allowRenderCreateEntranceTest = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_QUIZ_GR.CREATE_QUIZ) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const [openImportClass, setOpenImportClass] = useState<boolean>(false)
  const navigate = useNavigate()
  const {search} = useLocation()
  const [loading, setLoading] = useState(true)
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const searchParams = new URLSearchParams(search)
  const [dateField, setDateField] = useState<string>('updated_at')
  const queryParams = {
    name: searchParams.get('name') ?? '',
    course_category_id: searchParams.get('course_category_id'),
    subject: searchParams.get('subject'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    status: searchParams.get('status') as string,
    page_index: parseInt(searchParams.get('page_index') as string) ?? '',
    page_size: parseInt(searchParams.get('page_size') as string) ?? '',
  }
  const validationSchema = z.object({
    name: z.string().optional(),
    course_category_id: z.string().optional(),
    subject: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })
  const {control, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: getValues('name'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      subject: replaceValueAll(getValues('subject')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      status: replaceValueAll(getValues('status')),
      page_index: currenPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    name: queryParams.name.trimStart().trimEnd() ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    subject: queryParams.subject ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
    status: queryParams.status ?? '',
  })
 
  const getParams = (
    name: string,
    course_category_id: string,
    subject: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    status?: string,
    quizType?:string,
    dateField?: string
  ) => ({
    name,
    course_category_id,
    subject,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    status,
    quizType,
    dateField,
  })
  const {checkedList, toggleCheck, toggleCheckAll, isCheckedAll} = useChecked<any>(
    EntranceTestList?.quizzes
  )
  const fetchEntranceTestList = async (currentPage: number, pageSize: number, params?: any) => {
    try {
      const res = await EntranceApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: {
          ...params,
          quizType: 'ENTRANCE_TEST',
          dateField: "created_at",
        },
      })
      setEntranceTestList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('subject')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        replaceValueAll(getValues('status')),
      )
    )

    setLoading(true)

    fetchEntranceTestList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setDateField('')
    setLoading(true)
    navigate(PageLink.ENTRANCE_TEST_LIST)
    fetchEntranceTestList(1, 10)
  }

  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({page_index: 1, page_size: 10,params:{getType:'ALL'} })
      setCourseCategory(res?.data)
    } catch (error) {}
  }
  useEffect(() => {
    fetchCourseCategory()
  }, [])
  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )
  const dataAll = EntranceTestList?.quizzes
  const listSubject: {label: any; value: any}[] = []
  const listProgram: {label: any; value: any}[] = []

  dataAll.forEach((quizzes: any) => {
    listProgram.push({
      label: quizzes.quiz_type || '',
      value: quizzes.quiz_type || '',
    })

    listSubject.push({
      label: quizzes.subject || '',
      value: quizzes.subject || '',
    })
  })

  return (
    <PageLayouts pageTitle={TITLE_OPTIONS_ENTRANCE.listEntrance} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-9 border-0 pt-6'>
          <div className=''>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4 '>
                <div className='card-title justify-content-center mx-0 mt-2'>
                  <div
                  >
                    <HookFormTextField
                      control={control}
                      name='name'
                      placeholder='Search'
                      defaultValue={queryParams?.name}
                      onSubmit={onSubmit}
                    />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='course_category_id'
                  placeholder='Program'
                  defaultValue={queryParams?.course_category_id ?? ''}
                  showSearch
                  // onFocus={async () => {
                  //   if (newCourseCategory ?? ([] as Array<any>)?.length <= 0) {
                  //     await fetchCourseCategory()
                  //     return
                  //   }
                  // }}
                >
                  {DEFAULT_SELECT_ALL?.concat(newCourseCategory ?? ([] as Array<any>)).map(
                    (course_category_ids) => (
                      <Option key={course_category_ids.value} value={course_category_ids.value}>
                        {course_category_ids.label}
                      </Option>
                    )
                  )}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='subject'
                  placeholder='Subject'
                  defaultValue={queryParams.subject ?? ''}
                >
                  {DEFAULT_SELECT_ALL?.concat(OPTIONS_SUBJECTS ?? ([] as Array<any>)).map(
                    (subject: any) => (
                      <Option key={subject.value} value={subject.value}>
                        {subject.label}
                      </Option>
                    )
                  )}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort by'
                  control={control}
                  size='large'
                  defaultValue={queryParams?.sortType ?? ''}
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormSelectAntd
                  name='status'
                  placeholder='Status'
                  control={control}
                  size='large'
                  defaultValue={queryParams?.status ?? ''}
                >
                  {OPTIONS_OF_STATUS.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  className='sapp-h-datimepicker'
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  className='sapp-h-datimepicker'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-xl-4 col-lg-7 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  disabled={false}
                />
              </div>
              <div className='col-sm-6 col-xl-8 col-lg-5 px-xl-3 px-md-0 px-0 pe-xl-0'>
                <div className='card-toolbar justify-content-end d-flex my-0 gap-5'>
                  {allowRenderCreateEntranceTest && (
                     <ButtonIcon
                     title={LANG_ENTRANCE_TEST.addEntranceTest}
                     className='sapp-height-min--content'
                     link={`${PageLink.ENTRANCE_CREATE_TEST_LIST}`}
                   >
                     <KTIcon iconName='plus' className='fs-2' />
                   </ButtonIcon>
                  )}
                  {/* start:: button open modal */}
                  {/* end:: button open modal */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableTest
          EntranceTestList={EntranceTestList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchEntranceTestList={fetchEntranceTestList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        <ModalImportClasses
          open={openImportClass}
          setOpen={setOpenImportClass}
        ></ModalImportClasses>
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(EntranceTestList)
