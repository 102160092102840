import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
interface IStep {
  step: {
    title: string
    status: string
  }[]
  setNewStep: any
  isNext?: boolean
}

const Processbar = ({step, setNewStep, isNext = true}: IStep) => {
  const navigate = useNavigate()
  const {id} = useParams()
  const ProcessStyled = styled.div<{$title?: any}>`
    display: flex;
    width: 100%;
    align-items: center;
    .sapp-steps {
      position: relative;
      width: 50px;
      height: 50px;
      border: 1px #E1E3EA solid;
      border-radius: 50px;
      background: #ffffff;
      color: #E1E3EA;
      font-family: 'Inter';
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        border:none;
        color: #fff;
        background: #faaf00;
      }
      &.current {
        cursor: initial;
        border:none;
        background: #f9f9f9;
        border: 1px #faaf00 solid;
        color: #faaf00
      }
      ${(props: any) =>
        props.$title.map(
          (e: any, i: any) => `&:nth-child(${2 * i + 1})::before {
            position: absolute;
            content: '${e.title}';
            color: #7E8299;
            font-size: 16px;
            font-weight: 400;
            top: -40px;
            flex: 1;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            word-break: unset;
            font-family: 'Inter'
          }`
        )}
      &.current::before {
        color: #faaf00;
        font-weight: 600;
      }
    }
    .sapp-steps-line {
      height: 2px;
      background: #d9d9d9;
      flex: 1;
      margin: 0 32px 0 32px;
      position: relative;
      &:last-child {
        display: none;
      }
      &.active::before {
        width: 100%;
        height: 100%;
        background: #faaf00;
        content: '';
        position: absolute;
        left: 0;
      }
    }
  `
  const handleGotoStep = (index: number) => {
    const _new = step.map((e, idx) => {
      if (index > idx) {
        return {...e, status: 'active'}
      }
      if (index === idx) {
        return {...e, status: 'current'}
      }
      if (index < idx) {
        return {...e, status: ''}
      }
    })
    if(id !== 'undefined'){
      isNext && setNewStep(_new)
    }
  }
  return (
    <ProcessStyled $title={step}>
      {step?.map((e: any, i: number) => {
        return (
          <React.Fragment key={i}>
            <div
              className={`sapp-steps ${
                (e.status === 'active' || e.status === 'current') && 'active '
              }${e.status === 'current' && 'current'}`}
              onClick={() => {
                e.link ? navigate(e.link) : handleGotoStep(i)
              }}
            >
              {i + 1}
            </div>
            <div
              className={`sapp-steps-line ${
                (e.status === 'active') && i < step.length && 'active'
              }`}
            ></div>
          </React.Fragment>
        )
      })}
    </ProcessStyled>
  )
}
export default Processbar
