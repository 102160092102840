import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import choosefourlevel from 'src/_metronic/assets/icons/choosefourlevel.svg'
import choosetwolevel from 'src/_metronic/assets/icons/choosetwolevel.svg'
import choosetwolevelHover from 'src/_metronic/assets/icons/chooseTwoLevelHover.svg'
import choosefourlevelHover from 'src/_metronic/assets/icons/chooseFourLevelHover.svg'
import {Link, useParams} from 'react-router-dom'
import {PageLink} from 'src/constants'
import SappModal from '../base/SappModal'
import './ModalChooseStructure.scss'
import {setCourseLevel} from 'src/utils'
import toast from 'react-hot-toast'
import { FourLevelPanel, FourLevelPanelActive, TwoLevelPanel, TwoLevelPanelActive } from 'src/common/icons'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const ModalChooseStructure = ({open, setOpen}: IProps) => {
  const [isHoveredFourLevel, setIsHoveredFourLevel] = useState(false)
  const [isHoveredTwoLevel, setIsHoveredTwoLevel] = useState(false)
  const { id } = useParams();

  const handlePartialLevel = () => {
    setCourseLevel('false')
    setOpen(false)
    toast.error('Chức năng đang phát triển')
  }

  const handleFullLevel = () => {
    setCourseLevel('true')
  }

  useEffect(() => {
    setIsHoveredTwoLevel(false)
  }, [open])

  return (
    <SappModal
      centered
      dialogClassName='mw-650px'
      open={open}
      title='Choose Structure'
      handleClose={() => setOpen(false)}
      showFooter={false}
    >
      <div className='d-md-flex justify-content-xl-between justify-content-md-evenly'>
        <Link
          to={`${PageLink.COURSE_FULL}/${id}`}
          className='text-center text-gray-800'
          onClick={handleFullLevel}
        >
          <div
            onMouseEnter={() => setIsHoveredFourLevel(true)}
            onMouseLeave={() => setIsHoveredFourLevel(false)}
          >
            {isHoveredFourLevel ? (
              <FourLevelPanelActive/>
            ) : (
              <FourLevelPanel/>
            )}
            <p className={`mt-2 level-name ${isHoveredFourLevel ? 'text-primary' : ''}`}>4 level</p>
          </div>
        </Link>
        <Link
          to={''}
          className='text-center text-gray-800'
          onClick={handlePartialLevel}
        >
          <div
            onMouseEnter={() => setIsHoveredTwoLevel(true)}
            onMouseLeave={() => setIsHoveredTwoLevel(false)}
          >
            {isHoveredTwoLevel ? (
              <TwoLevelPanelActive/>
            ) : (
              <TwoLevelPanel/>
            )}
            <p className={`mt-2 level-name ${isHoveredTwoLevel ? 'text-primary' : ''}`}>2 level</p>
          </div>
        </Link>
      </div>
    </SappModal>
  )
}

export default ModalChooseStructure
