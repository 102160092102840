import { KTCard } from "src/_metronic/helpers"
import { NewsEditModal } from "./components/new-edit-modal/NewsEditModal"
import { NewsListHeader } from "./components/header/NewsListHeader"
import { NewsTable } from "./components/table/NewsTable"
import { QueryRequestProvider } from "./components/core/QueryRequestProvider"
import { QueryResponseProvider } from "./components/core/QueryResponseProvider"
import { ListViewProvider, useListView } from "./components/core/ListViewProvider"

const NewsList = () => {
    const { itemIdForUpdate } = useListView()
    return (
        <>
            <KTCard>
                <NewsListHeader />
                <NewsTable />
            </KTCard>
            {itemIdForUpdate !== undefined && <NewsEditModal />}
        </>
    )
}

const NewsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <NewsList />
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export { NewsListWrapper }
