import { Col, Row } from 'react-bootstrap'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  PageLink,
  VALIDATE_FILED_MAX_LENGTH,
  VALIDATE_NUMBER,
  VALIDATE_PASS_NOTE,
  VALIDATE_PASS_POINT,
  VALIDATE_SUBJECT,
  VALIDATION_FILED,
} from 'src/constants'
import { z } from 'zod'
import { Select } from 'antd'
import { zodResolver } from '@hookform/resolvers/zod'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import { toast } from 'react-hot-toast'
import { TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'
import { EntranceApi } from 'src/apis/entrance-test'
import InformationTab from '../informationTab'
import QuestionList from '../QuestionList'
import SAPPDialogButtonsCancel from 'src/common/SAPPDialogButtonCancel'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers/components/KTIcon'
import { IQuestion } from 'src/components/question-bank/shared/interfaces'
import { LIST_TYPE } from 'src/components/courses/shared/Interfaces'
import { TYPE_OF_TEST } from 'src/components/courses/shared/consts'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import MenuTabsSection from '../create/MenuTabsSection'
import ChooseQuestionsModal from '../create/QuestionListComponent'
import SettingTab from '../create/SettingSection'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { IProps, Section, SettingCategory } from 'src/type/entrancetest'
import PopupConfirmCategory from './PopupCategory'
import { debounce } from 'lodash'
import PopupConfirm from 'src/common/PopupConfirm'

type InformationType = {
  name: String,
  courseCategoryId: String,
  subject: String,
  hour: String,
  minutes: String
}

const EditTestInfoComp = ({ ids, oldData, editId }: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [categoryState, setCategorySate] = useState<any>()
  const [previousCategoryId, setPreviousCategoryId] = useState<any>();
  const location = useLocation()
  const {
    handleSubmit,
    control,
    getValues,
    // setError,
    formState: { errors },
    setValue,
  } = useForm()
  const { Option } = Select
  const [sections, setSections] = useState<Section[]>([])
  const [openChooseQuestion, setOpenChooseQuestion] = useState<any>()
  const [listSectionSetting, setListSectionSetting] = useState<SettingCategory[]>([])
  const [quizId, setQuizId] = useState<any>('')
  const [index, setIndex] = useState<number>(-1)
  const [indexSetting, setIndexSetting] = useState<number>(-1)
  const [categorySections, setCategorySections] = useState<[]>([])
  const [sectionQuestions, setSectionQuestions] = useState<IQuestion[]>([])
  const [editMode, setEditMode] = useState<boolean>(true)
  const [isAddSection, setAddSection] = useState<boolean>(true)
  const [isLocked, setIsLocked] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isOnUsed, setIsOnUsed] = useState<boolean>(false)
  const [testInfo, setTestInfo] = useState<InformationType>()
  const [categoryData, setCategoryData] = useState<any>('')
  const [detailProgram, setDetailProgram] = useState<any>()

  const validationInformationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, { message: VALIDATION_FILED })
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    course_category_id: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, { message: VALIDATION_FILED }),
    subject: z
      .string({ required_error: VALIDATION_FILED })
      .regex(/^(?:[0-9]+)?$/, { message: VALIDATE_NUMBER })
      .transform((val) => parseInt(val))
      .refine((val) => val >= 1, { message: VALIDATE_SUBJECT })
      .refine((val) => val <= 59, { message: VALIDATE_SUBJECT })
      .or(z.number().int(VALIDATE_NUMBER).min(1).max(59)),
    hour: z
      .string({ required_error: VALIDATION_FILED })
      .refine(
        (val) => {
          if (!getValues('minutes')) {
            return true
          }
          return false
        },
        { message: VALIDATION_FILED }
      )
      .optional(),
    minutes: z
      .string({ required_error: VALIDATION_FILED })
      .transform((val) => parseInt(val))
      .refine(
        (val) => {
          if (!getValues('hour') && val < 0) {
            if (!val) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        },
        { message: VALIDATION_FILED }
      )
      .optional(),
  })
  const validationSettingSectionSchema = z
    .object({
      setting: z.array(
        z.object({
          pass_point: z
            .string({ required_error: VALIDATION_FILED })
            .regex(/^[0-9]*$/, VALIDATE_NUMBER)
            .transform((val) => parseInt(val))
            .refine((val) => val >= 1, { message: VALIDATE_PASS_POINT })
            .refine((val) => val <= 100, { message: VALIDATE_PASS_POINT })
            .or(z.number().int(VALIDATE_NUMBER).min(1, { message: VALIDATE_PASS_NOTE }).max(100)),
          pass_note: z.any().optional(),
          failure_note: z.any().optional(),
        })
      ),
    })
    .optional()
  const validationSectionSchema = z.object({
    section: z.array(
      z
        .object({
          name: z
            .string({ required_error: VALIDATION_FILED })
            .trim()
            .min(1, VALIDATION_FILED)
            .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
          pass_point: z
            .string({ required_error: VALIDATION_FILED })
            .regex(/^[0-9]*$/, VALIDATE_NUMBER)
            .transform((val) => parseInt(val))
            .refine((val) => val >= 1, { message: VALIDATE_PASS_POINT })
            .refine((val) => val <= 100, { message: VALIDATE_PASS_POINT })
            .or(z.number().int(VALIDATE_NUMBER).min(1).max(100)),
          pass_note: z
            .string({ required_error: VALIDATION_FILED })
            .min(1, { message: VALIDATE_PASS_NOTE }),
          failure_note: z
            .string({ required_error: VALIDATION_FILED })
            .min(1, { message: VALIDATE_PASS_NOTE }),
          question_category_id: z.string({ required_error: VALIDATION_FILED }).min(1),
        })
        .optional()
    ),
  })

  const {
    handleSubmit: handleSubmitInformation,
    control: controlInformation,
    formState: { errors: errorsInformation, isSubmitted },
    setValue: setValueInformation,
    setError,
    watch,
  } = useForm({
    resolver: zodResolver(validationInformationSchema),
  })

  const {
    handleSubmit: handleSubmitSettingSection,
    control: controlSettingSection,
    formState: { errors: errorsSettingSection },
    setValue: setValueSettingSection,
    reset: resetSectionSetting,
  } = useForm({
    resolver: zodResolver(validationSettingSectionSchema),
  })

  const {
    handleSubmit: handleSubmitSection,
    control: controlSection,
    formState: { errors: errorsSection },
    setValue: setValueSection,
    getValues: getValue,
    reset: resetSection,
  } = useForm({
    resolver: zodResolver(validationSectionSchema),
  })
  const displayValidationErrors = (validationErrors: any) => {
    Object.keys(validationErrors).forEach((fieldName) => {
      const errors = validationErrors[fieldName]
      errors.forEach((error: any) => {
        toast.error(`${fieldName}: ${error}`)
      })
    })
  }

  const addQuestioned = useRef<{
    multiple: boolean
    constructed: boolean
  }>({ multiple: false, constructed: false })

  const fetchListSection = async (id: string) => {
    try {
      if (id) {
        const response = await EntranceApi.getListSection(id)
        setSections(response.data)
      }
    } catch (error) { }
  }
  const fetchListSectionSetting = async (id: string) => {
    try {
      if (id) {
        const response = await EntranceApi.getListSettingSection(id)
        setListSectionSetting(response.data)
      }
    } catch (error) { }
  }

  const fetchCategory = async (page_index: number, page_size: number) => {
    try {
      const response = await EntranceApi.getCategory({ page_index: page_index, page_size: page_size })
      const _categoryTest = response.data
      const newData = response?.data?.course_categories || [];
      setCategoryData((prevState: { course_categories: any; metadata: any }) => ({
        ...prevState,
        metadata: response?.data?.metadata || prevState.metadata,
        course_categories: prevState.course_categories ? [...prevState.course_categories, ...newData] : newData,
      }))
    } catch (error) { }
  }
  const handleNextPage = (e: any) => {
    const total_pages = categoryData.metadata?.total_pages
    const page_index = categoryData.metadata?.page_index
    const page_size = categoryData.metadata?.page_size
    if (total_pages && page_index && page_index < total_pages) {
      fetchCategory(page_index + 1, page_size);
    }
  }
  const debouncedHandleNextPage = debounce(handleNextPage, 200)
  const fetchQuestionCategory = async () => {
    try {
      const response = await EntranceApi.getQuestionCategory({ page_index: 1, page_size: 10 })
      const _categorySections = response.data
      setCategorySections(_categorySections.data)
    } catch (error) { }
  }

  const onChangeCategory = (value: any) => {
    setCategorySate(value)
  }
  const newCategory = useMemo(() => categoryData?.course_categories?.map((category: any) => ({
    label: category.name,
    value: category.id,
  })), [categoryData])

  const showCategoryACCA =
    newCategory?.find((category: any) => category?.value === watch('course_category_id'))?.label ===
    'ACCA'
  const fetchDataEdit = async (ids: string) => {
    const response = await EntranceApi.editEntranceTest(ids)
    const data = response.data
    setIsLocked(data.status === 'LOCKED')
    const totalMinutes = parseInt(data.quiz_timed, 10)
    const hours = Math.floor(totalMinutes / 60)
    const mins = totalMinutes % 60
    const processedSubject = data.subject.replace(/F/g, '')
    handleInformationForm({
      name: data.name,
      courseCategoryId: data?.course_category?.name || data.course_category_id,
      subject: processedSubject, hour: hours.toString(),
      minutes: mins.toString()
    })
    setTestInfo({
      name: data.name,
      courseCategoryId: data?.course_category?.name || data.course_category_id,
      subject: processedSubject,
      hour: hours.toString(),
      minutes: mins.toString()
    })
    setDetailProgram(data.course_category)
  }
  useEffect(() => {
    if (ids) {
      const fetchData = async (ids: string) => {
        const response = await EntranceApi.editEntranceTest(ids)
        const data = response.data
        setIsOnUsed(data.is_quiz_used)
        setIsLocked(data.status === 'LOCKED')
        setIsActive(data.status === 'ACTIVE')
        const totalMinutes = parseInt(data.quiz_timed, 10)
        const hours = Math.floor(totalMinutes / 60)
        const mins = totalMinutes % 60
        const processedSubject = data.subject.replace(/F/g, '')
        handleInformationForm({
          name: data.name,
          courseCategoryId: data?.course_category?.name || data.course_category_id,
          subject: processedSubject,
          hour: hours.toString(),
          minutes: mins.toString()
        })
        setTestInfo({
          name: data.name,
          courseCategoryId: data?.course_category?.name || data.course_category_id,
          subject: processedSubject,
          hour: hours.toString(),
          minutes: mins.toString()
        })
        setDetailProgram(data.course_category)
      }
      fetchData(ids)
      fetchListSection(ids)
      fetchListSectionSetting(ids)
      setEditMode(false)
    }else {
      fetchListSectionSetting(ids)
      fetchListSection(ids)
    }
  }, [ids])
  useEffect(() => {
    fetchCategory(1, 10)
    fetchQuestionCategory()
    setQuizId(ids)
  }, [ids])
  const [openPopupChageSubject, setOpenPopupChageSubject] = useState<boolean>(false)
  const courseCategoryId = watch('course_category_id');
  const subject = watch('subject');
  const handleChangeStatus = (e: any) => {
    if (isActive === true) {
      setOpenPopupChageSubject(true);
      return true;
    }
    return false;
  };
  const [open, setOpen] = useState<boolean>(false)
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const handleChooseQuestions = (type: LIST_TYPE, sectionId: any) => {
    if (categoryState === '' || undefined) {
      setOpen(true)
      return
    }
    if (getValues('type') === TYPE_OF_TEST.mix.value) {
      if (type === 'multiple' && !Number(getValues('number_of_multiple_choice_questions'))) {
        setError('number_of_multiple_choice_questions', { message: VALIDATION_FILED })
        addQuestioned.current = { ...addQuestioned.current, multiple: true }
        return
      }
      if (type === 'constructed' && !Number(getValues('number_of_essay_questions'))) {
        setError('number_of_essay_questions', { message: VALIDATION_FILED })
        addQuestioned.current = { ...addQuestioned.current, constructed: true }
        return
      }
    }

    setOpenChooseQuestion({ status: true, type: type, sectionId: sectionId })
  }
  const handleCancelInformation = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        if (ids) {
          setEditMode(false)
          testInfo && handleInformationForm(testInfo)
        } else {
          navigate(`${PageLink.ENTRANCE_TEST_LIST + (location.search || '')}`)
        }
      },
    })
  }

  const [showLabel, setShowLabel] = useState(false)

  const handleToggleLabel = () => {
    setShowLabel(!showLabel)
  }
  const handleCancelUpdateSection = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: async() => {
        resetSection()
        setIndex(-1)
        await fetchListSection(ids)
        setIsEdit(!isEdit)
        setShowLabel(false)
      },
    })
  }
  const handleCancelUpdateSetting = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: async () => {
        resetSectionSetting()
        setIndexSetting(-1); 
        await fetchListSectionSetting(ids); 
        setShowLabel(false); 
      }
    })
  }
  const submitSection = async (item: any) => {
    try {
      const data = sections.find((e, idx) => idx === index)
      const section = item
      const passPoints = Number(section.pass_point)
      let reg = {
        name: section.name,
        question_category_id: section.question_category_id,
        failure_note: section.failure_note,
        pass_note: section.pass_note,
        pass_point: passPoints,
        quiz_id: ids,
      }
      const request = {
        ...reg,
        questions: sectionQuestions.map((item) => ({ id: item.id })),
      }
      setLoading(true)
      if (!!data?.id) {
        const res = await EntranceApi.EditSection(data.id, { data: request })
        setIndex(-1)
        setIsEdit(false)
        if (res) {
          fetchListSection(ids)
          fetchListSectionSetting(ids)
          toast.success('Test saved successfully!')
          setLoading(false)
          setIsEdit(false)
        } else {
          toast.error('Test saved failed!')
        }
      } else {
        const res = await EntranceApi.addSectionEntranceTest({ data: request })
        if (res) {
          toast.success('Test saved successfully!')
          fetchListSection(ids)
          fetchListSectionSetting(ids)
          setIsEdit(false)
        } else {
          toast.error('Test saved failed!')
          fetchListSection(ids)
          fetchListSectionSetting(ids)
        }
        setIndex(-1)
      }
    } catch (error) {
      toast.error('An error occurred while saving the setting')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const submitSetting = async (setting: any, id: string) => {
    setLoading(true)
    try {
      const submitSection = setting
      const passPointNumber = parseInt(submitSection.pass_point, 10)
      const passPoint = isNaN(passPointNumber) ? 0 : passPointNumber
      let settingSection: any = {
        pass_point: passPoint,
        pass_note: submitSection.pass_note,
        failure_note: submitSection.failure_note,
      }

      const res = await EntranceApi.UpdateSettingSection({ id, data: { ...settingSection } })
      if (res) {
        toast.success('Setting saved successfully!')
        setListSectionSetting(res?.data)
        setIndexSetting(-1)
        fetchListSection(ids)
        fetchListSectionSetting(ids)
        setLoading(false)
        setIsEdit(false)
      } else {
        throw new Error('API call failed') // Add more meaningful error message if needed
      }
    } catch (error) {
      toast.error('An error occurred while saving the setting')
      console.error(error) // Log the error for debugging
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    const hour = isNaN(data.hour) ? 0 : Number(data.hour || 0)
    const minutes = isNaN(data.minutes) ? 0 : Number(data.minutes || 0)

    if (!data.hour && !data.minutes) {
      setError('minutes', { message: VALIDATION_FILED })
      setLoading(false)
      return
    }
    const subject =
      data.subject !== undefined && data.subject !== null ? data.subject.toString() : ''
    const totalMinutes = hour * 60 + minutes
    const getCourseCategoryId = detailProgram?.name.trim() === data?.course_category_id.trim() ? detailProgram?.id : data?.course_category_id
    let request: any = {
      name: data.name,
      course_category_id: getCourseCategoryId,
      subject: subject,
      quiz_timed: totalMinutes,
      description: '',
      quiz_question_mode: 'ALL',
      is_published: true,
      is_public: true,
      password: '',
      is_graded: true,
      grading_method: 'AUTO',
      multiple_choice_multiplier: 100,
      essay_multiplier: 0,
      required_percent_score: 0,
      minimum_score: 0,
      is_limited: true,
      limit_count: 1,
      is_tutor: false,
      setting: {},
      quiz_question_type: 'MULTIPLE_CHOICE',
      quiz_type: 'ENTRANCE_TEST',
      grading_preference: 'AFTER_ALL_QUESTIONS',
      quiz_for: 'ENTRANCE_TEST',
    }
    try {
      if (editMode && !ids) {
        const res = await EntranceApi.createEntranceTest({ data: { ...request } })
        if (res) {
          setEditMode(false)
          navigate(`/entrance-test/list-entrance-test/edit/${res.data.id}`)
          setQuizId(res.data.id ?? '')
          toast.success('Test created successfully!')
        }
      } else if (editMode && ids) {
        const updatedRequest = { ...request }
        delete updatedRequest.status
        const res = await EntranceApi.updateEntranceTest(ids, { data: { ...updatedRequest } })
        if (res) {
          navigate(`/entrance-test/list-entrance-test/edit/${res.data.id}`)
          setQuizId(res.data.id ?? '')
          setEditMode(false)
          fetchDataEdit(ids)
          toast.success('Test saved successfully!')
        }
      }
    } catch (error: any) {
      displayValidationErrors(error.errors)
    } finally {
      setLoading(false)
    }
  }
  const createNewSection = (): Section => ({
    questions: [],
    course_category_id: '',
    name: '',
    pass_note: '',
    failure_note: '',
    pass_point: '',
    question_category_id: '',
  })

    const handleAddNewSection = () => {
      const newSection = createNewSection()
      setIndex(sections.length)
      onSetQuestions([])
      setSections((prevSections) => {
        const newSections = [...prevSections, newSection]
        const length = newSections.length
        setIndex(length - 1)
        return newSections
      })
    }

  const onHandleEditAble = () => {
    setEditMode(true)
  }

  const HandleEditSections = (indexSection: any) => {
    setIndex(indexSection)
  }
  const onSetQuestions = (data: any) => {
    const _sections = sections.map((e, idx) => {
      if (idx === index) {
        return { ...e, questions: data }
      }
      return e
    })
    setSections(_sections)
    setSectionQuestions(data)
    setPreviousCategoryId(categoryState)
  }
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const onEditSection = (e: number) => {
    setIsEdit(true)
    setIndex(e)
  }
  const onEditSetting = (e: number) => {
    setIsEdit(true)
    setIndexSetting(e)
  }
  const handleDeleteSection = async (id: any) => {
    try {
      const response = await EntranceApi.DeleteSection(id)
      if (response.success) {
        toast.success('Successfully deleted section')
        setSections(sections.filter((e) => e.id !== id))
        fetchListSectionSetting(quizId)
        setAddSection(true)
        setIsEdit(false)
      }
    } catch (error) {
      console.error('Error handling delete section:', error)
    }
  }

  const handleInformationForm = (data: InformationType) => {
    setValueInformation('name', data?.name)
    setValueInformation('course_category_id', data?.courseCategoryId)
    setValueInformation('subject', data?.subject)
    setValueInformation('hour', data?.hour)
    setValueInformation('minutes', data?.minutes)
  }
  return (
    <div className='p-10'>
      {contextHolder}
      <div>
        <label className='sapp-fs-20 fw-bold pt-2 pb-3'>
          <span className=''>{'Test Information'}</span>
        </label>
      </div>
      <div className='h-xl-100 card'>
        <div className='card-header'>
          <label className='sapp-fs-20 fw-bold pt-7'>
            <span className=''>{TITLE_OPTIONS_ENTRANCE.detail}</span>
          </label>
          {ids && !isLocked && !isOnUsed && (
            <div className='d-flex justify-content-end mt-5'>
              <ButtonIconOnly
                iconName={'notepad-edit'}
                activeColor='primary'
                bg='gray-100'
                onClick={onHandleEditAble}
              />
            </div>
          )}
        </div>
        <div className='border d-block p-10 pb-0 bg-white'>
          <div className='class_name card-title d-flex flex-wrap mb-8 w-100'>
            <HookFormTextField
              label={TITLE_OPTIONS_ENTRANCE.testname}
              required
              control={controlInformation}
              name='name'
              placeholder=' '
              disabled={!editMode}
              className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
            ></HookFormTextField>
          </div>
          <Row>
            <Col md={6} className='mb-8'>
              <label className='sapp-fs-14 fw-bold form-label'>
                <span className='required'>{TITLE_OPTIONS_ENTRANCE.course_category_id}</span>
              </label>
              <div className='position-relative'>
                <HookFormSelectAntd
                  required
                  control={controlInformation}
                  name='course_category_id'
                  placeholder=' '
                  disabled={!editMode}
                  className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                  handleNextPage={debouncedHandleNextPage}
                >
                  {newCategory?.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </Col>
            <Col md={6} className='mb-8'>
              <label className='sapp-fs-14 fw-bold form-label'>
                <span className='required'>{TITLE_OPTIONS_ENTRANCE.subject}</span>
              </label>
              <div className='position-relative'>
                <HookFormTextField
                  required
                  control={controlInformation}
                  name='subject'
                  placeholder=' '
                  type='text'
                  disabled={!editMode}
                  className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                  groupText={showCategoryACCA ? 'F' : undefined}
                ></HookFormTextField>
              </div>
            </Col>
          </Row>
          <Col md={6} className=''>
            <label className='sapp-fs-14 fw-bold form-label'>
              <span className='required'>{TITLE_OPTIONS_ENTRANCE.quiz_timed}</span>
            </label>
          </Col>
          <Row>
            <Col md={6} className='mb-8'>
              <div className='position-relative'>
                <HookFormTextField
                  required
                  control={controlInformation}
                  name='hour'
                  placeholder=' '
                  disabled={!editMode}
                  type='number'
                  className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                  postFix={<div className='px-2'>hours</div>}
                ></HookFormTextField>
              </div>
            </Col>
            <Col md={6} className='mb-8'>
              <div className='position-relative'>
                <HookFormTextField
                  required
                  control={controlInformation}
                  name='minutes'
                  placeholder=' '
                  type='number'
                  disabled={!editMode}
                  className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                  postFix={<div className='px-2'>mins</div>}
                ></HookFormTextField>
              </div>
            </Col>
          </Row>
          <div
            className='d-flex border-0 justify-content-end pt-4 pb-10'
            style={{ marginLeft: '10px' }}
          >
            {editMode && (
              <SAPPDialogButtonsCancelSubmit
                type='button'
                cancelClick={handleCancelInformation}
                cancelButtonCaption='Cancel'
                okButtonCaption='Save'
                okOnClick={(e:any) => {
                  if (!handleChangeStatus(e)) {
                    handleSubmitInformation((e) => {
                      onSubmit(e);
                      setAddSection(true);
                    })();
                  }
                }}
                className='justify-content-between d-flex'
                classNameCancel='px-7 py-5 fs-4 lh-1 fw-bold me-4'
                classNameSubmit='px-8 py-5 fs-4 lh-1 fw-bold'
                loading={loading}
              />
            )}
          </div>
        </div>
      </div>
      {listSectionSetting.length > 0 && (
        <div className='pt-10'>
          <div>
            <div>
              {listSectionSetting.length > 0 && ids && (
                <label className='sapp-fs-20 fw-bold form-label'>
                  <span className=''>{TITLE_OPTIONS_ENTRANCE.settingCategory}</span>
                </label>
              )}
            </div>
            {listSectionSetting.map((setting: SettingCategory, idx) => {
              return (
                <div key={setting.id} className='border-solid mt-4'>
                  <div className='border-solid mt-4 card'>
                    <div>
                      <Row>
                        <Col>
                          <div>
                            <div>
                              <SettingTab
                                index={idx}
                                id={listSectionSetting[idx]?.id}
                                error={errors[''] as any}
                                control={controlSettingSection}
                                confirm={confirm}
                                editMode={indexSetting !== idx}
                                setting={listSectionSetting[idx]}
                                isEdit={indexSetting !== idx}
                                toggleLabelCallback={handleToggleLabel}
                                typeofSection={setting.question_category.name}
                                onHandleEditAble={onEditSetting}
                                setValue={setValueSettingSection}
                                isLocked={!isLocked}
                                isOnUsed={isOnUsed}
                              />
                            </div>
                          </div>
                        </Col>
                        {indexSetting === idx && (
                          <div className='d-flex border-0 justify-content-end pt-4 pb-10 px-12'>
                            <SAPPDialogButtonsCancelSubmit
                              type='button'
                              cancelClick={handleCancelUpdateSetting}
                              cancelButtonCaption='Cancel'
                              okButtonCaption='Save'
                              okOnClick={handleSubmitSettingSection((setting) => {
                                submitSetting(setting.setting[idx], listSectionSetting[idx].id)
                              })}
                              className='justify-content-between d-flex'
                              classNameCancel='px-7 py-5 fs-4 lh-1 fw-bold me-4'
                              classNameSubmit='px-8 py-5 fs-4 lh-1 fw-bold'
                              loading={loading}
                            />
                          </div>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <div>
        <div>
          {isAddSection && ids && (
            <label className='sapp-fs-20 fw-bold pt-10'>
              <span className=''>{TITLE_OPTIONS_ENTRANCE.listsection}</span>
            </label>
          )}
          {sections.map((section, idx) => {
            return (
              <div key={section.id} className='border-solid mt-2'>
                <Row>
                  <Col md={12} className='mb-8'>
                    <div className='border-solid rounded border border-gray-300 p-5'>
                      <div>
                        <MenuTabsSection
                          index={idx}
                          toggleLabelCallback={handleToggleLabel}
                          confirm={confirm}
                          typeofSection={section.name}
                          SectionName={section?.question_category?.name}
                          onDeleteSection={handleDeleteSection}
                          setEditAble={onEditSection}
                          isEdit={index !== idx}
                          id={sections[idx]?.id}
                          isLocked={isLocked}
                          isOnUsed={isOnUsed}
                          setAddSection={setAddSection}
                          setCategorySate={setCategorySate}
                          categoryState={sections[idx]?.question_category?.id}
                          isQuestion={sections[idx]?.questions?.length > 0}
                          setPopup={setOpenPopup}
                          section={sections[idx]}
                          setValue={setValueSection}
                          setPreviousCategoryId={setPreviousCategoryId}
                          postionIndex={((sections?.length || 0) - 1)}
                          tabs={[
                            {
                              id: 'tab1',
                              title: 'Thông tin',
                              content: (
                                <InformationTab
                                  error={errors[''] as any}
                                  control={controlSection}
                                  index={idx}
                                  postionIndex={index}
                                  categorySections={categorySections}
                                  section={sections[idx]}
                                  setValue={setValueSection}
                                  getValue={getValue}
                                  onChangeCategory={onChangeCategory}
                                ></InformationTab>
                              ),
                            },
                            {
                              id: 'tab2',
                              title: 'Danh sách câu hỏi',
                              content: (
                                <QuestionList
                                  maxQuestionsNumber={'NO_MAX'}
                                  error={errors[''] as any}
                                  questions={section?.questions}
                                  setQuestions={(data) => onSetQuestions(data)}
                                  handleChooseQuestions={() =>
                                    handleChooseQuestions('all', section.id)
                                  }
                                  confirm={confirm}
                                  categoryState={categoryState}
                                  section={sections[idx]}
                                  isLocked={isLocked}
                                  isOnUsed={isOnUsed}
                                  isEdit={isEdit}
                                  sectionDisable={index !== idx}
                                  index={idx}
                                  previousCategoryId={previousCategoryId ?? ''}
                                  setOpenPopup={setOpenPopup}
                                ></QuestionList>
                              ),
                            },
                          ]}
                        />
                        {index === idx && (
                          <div
                            className='d-flex border-0 justify-content-end card-footer pt-4 pb-10'
                            style={{ marginLeft: '10px' }}
                          >
                            {index === idx && (
                              <SAPPDialogButtonsCancelSubmit
                                type='button'
                                cancelClick={handleCancelUpdateSection}
                                cancelButtonCaption='Cancel'
                                okButtonCaption='Save'
                                okOnClick={handleSubmitSection((e) => {
                                  if (previousCategoryId !== categoryState
                                    && previousCategoryId !== ''
                                    && section?.questions.length > 0

                                  ) {
                                    setOpenPopup(true);
                                    return;
                                  }
                                  submitSection(e.section[idx]);
                                  setIsEdit(false);
                                  fetchListSection(ids)
                                })}
                                className='justify-content-between d-flex'
                                classNameCancel='px-7 py-5 fs-4 lh-1 fw-bold me-4'
                                classNameSubmit='px-8 py-5 fs-4 lh-1 fw-bold'
                                loading={loading}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })}
        </div>
        <div>
          {isAddSection && ids && !isEdit && !isLocked && !isOnUsed && (
            <div className='mt-4 '>
              <div className='card p-10'>
                <ButtonIcon
                  title={'Add New Sections'}
                  className='sapp-h-48px d-flex justify-content-start align-items-center w-100 '
                  customButton='btn btn-outline border-gray-300 text-gray-500 p-0 position-relative'
                  type='button'
                  onClick={() => {
                    handleAddNewSection()
                    HandleEditSections(index)
                    setIsEdit(true)
                    setCategorySate('')
                    setPreviousCategoryId('')
                  }}
                >
                  <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                </ButtonIcon>
              </div>
            </div>
          )}
          <div className='d-flex border-0 justify-content-start pt-4 pb-10 pl-10 sapp-fs-16px'>
            <SAPPDialogButtonsCancel
              className=''
              cancelButtonCaption={'List Entrance Test'}
              cancelClick={() => navigate(`${PageLink.ENTRANCE_TEST_LIST}`)}
              buttonIcon
            />
          </div>
        </div>
      </div>
      <ChooseQuestionsModal
        questions={sections[index]?.questions || []}
        maxQuestionsNumber={'NO_MAX'}
        setQuestions={onSetQuestions}
        open={openChooseQuestion?.status}
        setOpen={setOpenChooseQuestion}
        type={openChooseQuestion?.type as LIST_TYPE | boolean}
        editing={openChooseQuestion?.sectionId}
        categoryState={categoryState}
        quizId={quizId}
      ></ChooseQuestionsModal>
      <PopupConfirmCategory
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={open}
        setOpen={setOpen}
        onClick={() => {
          setOpen(false)
        }}
        body='Bạn cần lựa chọn Question category trước khi thêm câu hỏi vào Entrance Test'
      />
      <PopupConfirmCategory
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openPopup}
        setOpen={setOpenPopup}
        onClick={() => {
          setOpenPopup(false)
        }}
        body='Bạn cần xóa các câu hỏi trước khi thay đổi lựa chọn Question Category.'
      />
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openPopupChageSubject}
        setOpen={setOpenPopupChageSubject}
        onClick={() => {
          handleSubmitInformation((e) => {
            onSubmit(e);
            setAddSection(true);
          })();
          setOpenPopupChageSubject(false);
        }}
        onClose={() => {
          testInfo && handleInformationForm(testInfo)
        }}
        body='Please confirm that all Entrance tests having the same program will be deactivated'
      />
    </div>
  )
}

export default EditTestInfoComp
