import {zodResolver} from '@hookform/resolvers/zod'
import {Select} from 'antd'
import {uniqueId} from 'lodash'
import {useEffect, useRef, useState} from 'react'
import {Accordion, Col, Row} from 'react-bootstrap'
import {useFieldArray, useForm} from 'react-hook-form'
import {KTIcon} from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {VALIDATION_FILED} from 'src/constants'
import {getUppercaseByNumber} from 'src/utils'
import {z} from 'zod'
import {defaultQuestionValues, validationQuestionSchema} from '../shared/consts'
import {
  ANSWER_CORRECT_TYPE,
  IInputQuestionProps,
  IQuestion,
  IQuestionAnswer,
  QUESTION_TYPES,
} from '../shared/interfaces'
import {generateGroups, inValidAnswers, validateAnswers} from '../shared/utils'
import LayoutQuestion from './LayoutQuestion'
import SappLabel from 'src/components/base/label/SappLabel'

type ISelectMissingWordQuestionProp = {
  open: boolean
  onClose: () => void
  type: string
  id?: string
  actionType?: 'edit' | 'duplicate'
}

interface IAnswer {
  answer: string
  answerId: string
  is_correct: ANSWER_CORRECT_TYPE
}
interface IGroup {
  groupId: string
  answers: IAnswer[]
  customAnswersError?: string
}
interface IInputProps extends IInputQuestionProps {
  groups?: IGroup[] | undefined
}

const defaultValues = {
  ...defaultQuestionValues,
  groups: [],
}

const SelectMissingWordQuestion = ({
  open,
  onClose,
  type,
  id,
  actionType,
}: ISelectMissingWordQuestionProp) => {
  const note = [
    'Mỗi [dấu ngoặc vuông] được hiểu là một chỗ trống. Mỗi chỗ trống là một nhóm các đáp án, có thể có nhiều đáp án đúng trong một chỗ trống. Người làm bài chỉ cần chọn 1 đáp án trong các đáp án đúng sẽ được tính là trả lời đúng câu hỏi.',
  ]

  const [activeKey, setActiveKey] = useState<string>('0')

  const validationSchema = z
    .object({
      ...validationQuestionSchema,
      groups: z.array(
        z
          .object({
            answers: z.array(
              z.object({
                answer: z
                  .string({required_error: VALIDATION_FILED})
                  .trim()
                  .min(1, VALIDATION_FILED)
                  .max(1000),
                is_correct: z.string(),
                answerId: z.string(),
              })
            ),
          })
          .superRefine((arg: any & {answers: IAnswer}, ctx) => {
            const isInValidAnswers = inValidAnswers<IAnswer>(arg.answers, 'F')
            if (isInValidAnswers) {
              ctx.addIssue(isInValidAnswers)
            }
          })
      ),
    })
    .superRefine((arg: any & {groups: IGroup[]}, ctx) => {
      if (!arg.groups?.length || arg.groups.length <= 0) {
        ctx.addIssue({
          message: 'Answer is required',
          path: ['groups'],
          code: 'custom',
        })
      }
    })

  const useFormProp = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {
    control,
    watch,
    setValue,
    formState: {errors, isSubmitted, isSubmitting},
    setError,
    clearErrors,
    getValues,
  } = useFormProp

  const {fields: groups, update} = useFieldArray({
    control,
    name: 'groups',
  })

  const question = watch('question_content')
  const timeout = useRef<NodeJS.Timeout>()
  const firstRenderRef = useRef<boolean>(true)

  useEffect(() => {
    if (isSubmitting === false && errors?.groups) {
      setActiveKey(Number(Object.keys(errors.groups)[0]).toString())
    }
  }, [isSubmitting])

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(() => {
      const newGroup = generateGroups<IGroup>(question, groups)
      setValue('groups', newGroup)
    }, 500)
  }, [question, setValue])

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        if (groups.length <= 0) {
          setError('groups', {message: 'Answer is required'})
        } else {
          clearErrors('groups')
        }

        groups?.forEach((e, i) => {
          validateAnswers({
            setError,
            clearErrors,
            answers: e.answers,
            path: `groups.${i}.customAnswersError`,
            checkEvery: 'F',
          })
        })
      })
    }
  }, [groups])

  const buildRequest = (data: IInputProps): {partialQuestion: Partial<IQuestion>} => {
    const answers: IQuestionAnswer[] | undefined = data.groups?.reduce((acc, g, gi) => {
      const transformedAnswers = g.answers?.map((a) => {
        return {
          is_correct: a.is_correct === 'T' ? true : false,
          answer: (a.answer ?? '').trim(),
          answer_position: gi + 1,
        }
      })
      return acc.concat(transformedAnswers)
    }, [] as IQuestionAnswer[])

    return {
      partialQuestion: {
        qType: QUESTION_TYPES.SELECT_WORD,
        answers,
      },
    }
  }

  const fetchData = (data: IQuestion): Partial<IInputProps> => {
    if (!data) {
      return {}
    }
    const oldGroups = generateGroups<IGroup>(data.question_content, [])

    const groups: IGroup[] | undefined = data.answers?.reduce((acc, a) => {
      const groupIndex = a.answer_position - 1
      if (!acc[groupIndex]) {
        acc[groupIndex] = {
          groupId: oldGroups?.[groupIndex]?.groupId ?? groupIndex.toString(),
          answers: [],
        }
      }

      acc[groupIndex].answers.push({
        is_correct: a.is_correct ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F,
        answer: a.answer,
        answerId: uniqueId('answer_'),
      })
      return acc
    }, [] as IGroup[])

    return {
      groups,
    }
  }

  const handleToggle = (eventKey: string) => {
    if (activeKey === eventKey) {
      setActiveKey('')
    } else {
      setActiveKey(eventKey)
    }
  }

  return (
    <LayoutQuestion
      defaultValues={defaultValues}
      actionType={actionType}
      fetchData={fetchData}
      id={id}
      buildRequest={buildRequest}
      open={open}
      onClose={onClose}
      useForm={useFormProp}
      type={type}
      note={note}
    >
      {/* start:: answer */}
      <div>
        <SappLabel label='Danh Sách Đáp Án' required />
        <ErrorMessage>{errors.groups?.message}</ErrorMessage>
        <div>
          <Accordion activeKey={[activeKey]}>
            {groups.map((group, index) => {
              return (
                <Accordion.Item className='border-gray-300' eventKey={index.toString()} key={index}>
                  <Accordion.Header
                    className='sapp-accordion-header'
                    onClick={() => {
                      handleToggle(index.toString())
                    }}
                  >
                    Chỗ Trống {index + 1}
                  </Accordion.Header>
                  <Accordion.Body>
                    {group.answers
                      .filter((e: any) => e?.answerId)
                      .map((answer, answerIndex) => {
                        return (
                          <div key={answer.answerId} className={answerIndex !== 0 ? 'mt-5' : ''}>
                            <Row>
                              <Col md={9}>
                                <div className='d-flex align-items-center'>
                                  <div className='w-100'>
                                    <HookFormTextField
                                      className='sapp-h-45px fs-6'
                                      groupText={getUppercaseByNumber(answerIndex + 1)}
                                      control={control}
                                      name={
                                        `groups.${index}.answers.${answerIndex}.answer` as const
                                      }
                                      placeholder={'Đáp án ' + (answerIndex + 1)}
                                      guideline={[
                                        'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự.',
                                      ]}
                                    ></HookFormTextField>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3}>
                                <label className='d-flex justify-content-between align-items-center'>
                                  <div className='sapp-flex-1'>
                                    <HookFormSelectAntd
                                      size='large'
                                      name={
                                        `groups.${index}.answers.${answerIndex}.is_correct` as const
                                      }
                                      control={control}
                                      customError={!!errors.groups?.[index]?.['customAnswersError']}
                                      className='sapp-h-45px fs-6'
                                      onChange={() => {
                                        validateAnswers({
                                          setError,
                                          clearErrors,
                                          answers: getValues(`groups.${index}.answers`),
                                          path: `groups.${index}.customAnswersError`,
                                          checkEvery: 'F',
                                        })
                                      }}
                                    >
                                      <Select.Option value={ANSWER_CORRECT_TYPE.T}>
                                        Đúng
                                      </Select.Option>
                                      <Select.Option value={ANSWER_CORRECT_TYPE.F}>
                                        Sai
                                      </Select.Option>
                                    </HookFormSelectAntd>
                                  </div>
                                  {group.answers.length > 2 && (
                                    <div className='ms-4'>
                                      <ButtonIconOnly
                                        iconName={'trash'}
                                        activeColor='danger'
                                        onClick={() =>
                                          setTimeout(() => {
                                            update(index, {
                                              ...group,
                                              answers: group.answers.filter(
                                                (item) => item.answerId !== answer.answerId
                                              ),
                                            })
                                          })
                                        }
                                      />
                                    </div>
                                  )}
                                </label>
                                {group?.answers?.length - 1 === answerIndex && (
                                  <ErrorMessage>
                                    {' '}
                                    {errors.groups?.[index]?.['customAnswersError']?.message}
                                  </ErrorMessage>
                                )}
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    <Row className='mt-5'>
                      {/* start:: Add New*/}
                      <Col md={9}>
                        <ButtonIcon
                          title={'Thêm đáp án'}
                          className='h-45px w-100 d-flex justify-content-start align-items-center mt-xl-0 mt-4'
                          customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0'
                          type='button'
                          onClick={() => {
                            setTimeout(() => {
                              update(index, {
                                ...group,
                                answers: [
                                  ...group.answers,
                                  {
                                    answer: '',
                                    answerId: uniqueId('answer_'),
                                    is_correct: ANSWER_CORRECT_TYPE.F,
                                  },
                                ],
                              })
                            })
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
                        </ButtonIcon>
                      </Col>
                      {/* end:: Add New*/}
                      <Col></Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
      </div>
      {/* end:: answer*/}
    </LayoutQuestion>
  )
}
export default SelectMissingWordQuestion
