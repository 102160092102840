import {useEffect, useState} from 'react'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import {getFileSizeMB} from 'src/utils'

interface IProps {
  defaultValue: string | undefined
  value?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  multiple: boolean | undefined
  required: boolean | undefined
  accept?: string
  maxFileSize?: number
  src: string | undefined
  onRemove: () => void
  errorMessage: string | undefined
  guideline?: string[]
  removeAvatar: string | undefined
  width?: string
  height?: string
  disabled?: boolean
}

export const SAPPUploadField = ({
  defaultValue,
  value,
  onChange,
  multiple,
  accept,
  maxFileSize,
  src,
  onRemove,
  errorMessage,
  guideline,
  removeAvatar,
  height,
  width,
  disabled = false,
}: IProps) => {
  const [error, setError] = useState('')

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!maxFileSize) return onChange && onChange(e)

    const fileSize = getFileSizeMB(e?.target?.files?.item(0)?.size ?? 0, 2)
    setError(fileSize > maxFileSize ? 'Maximum file size: 20 MB' : '')
    onChange && onChange(e)
  }

  const onRemoveImage = (e: React.ChangeEvent<any>) => {
    onChange && onChange(e)
    onRemove()
    setError('')
  }

  const [image, setImage] = useState<string | undefined>(src)

  useEffect(() => {
    setImage(src)
  }, [src])

  return (
    <>
      <div className='image-input image-input-outline image-input-placeholder'>
        <div
          className={`image-input-wrapper ${width ?? 'w-150px'} ${height ?? 'h-150px'}`}
          style={{backgroundImage: `url(${image})`}}
        ></div>

        {!disabled && (
          <label
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='change'
          >
            <i className='ki-outline ki-pencil fs-7'></i>
            <input
              type='file'
              accept={accept}
              defaultValue={value ?? defaultValue}
              onChange={handleOnChange}
              multiple={multiple}
            />
            <input type='hidden' name='avatar_remove' />
          </label>
        )}
        <span
          className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          data-kt-image-input-action='cancel'
        >
          <i className='ki-outline ki-cross fs-2'></i>
        </span>
        {image && image !== removeAvatar && !disabled && (
          <span
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='remove'
            data-bs-toggle='tooltip'
            title='Remove avatar'
          >
            <i className='ki-outline ki-cross fs-2' onClick={onRemoveImage}></i>
          </span>
        )}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {guideline && (
        <div className='form-text mt-3'>
          <GuidelineField guideline={guideline} />
        </div>
      )}
    </>
  )
}
