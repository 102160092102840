// import { QUESTION_TYPES } from '../shared/interfaces'
import './PreviewQuestion.scss'
// import TrueFalseQuestionPreview from './true-false-question'
// import MultiQuestionPreview from './multi-question'
// import MatchingPreview from './matching-question'
// import AddWordPreview from './add-word'
// import DragNDropPreivew from './drag-n-drop-preview'
// import SelectWord from './select-word-preview'
import 'preview-question-bank/dist/index.css'
// import TinyEditor from 'src/components/form/editor'
import QuestTionPreview from 'preview-question-bank'
import {useEffect, useState} from 'react'
import {QuestionBankAPI} from 'src/apis/question-bank'
import {useLoadingContext} from 'src/context/LoadingContext'
import {mergeImageToEditor} from 'src/utils/upload'
import ModalPreviewFile from './ModalPreviewFile'
type ILayoutQuestionProp = {
  open: boolean
  onClose: () => void
  data: any
}
const PreviewQuestion = ({open, onClose, data}: ILayoutQuestionProp) => {
  const [newData, setNewData] = useState<any>()
  const [topicDescription, setTopicDescription] = useState<any>(undefined)
  const {loading, setLoading} = useLoadingContext()
  const [viewFile, setViewFile] = useState({status:false, url:undefined})
  useEffect(() => {
    async function getData() {
      setLoading(true)
      const oldData = {...data}
      if (data?.question_topic_id) {
        const topic_id = data.question_topic_id
        try {
          const {data: topic} = await QuestionBankAPI.getTopicById(topic_id)
          setTopicDescription(topic)
          // if (data?.question_content) {
          //   const dataDes = await mergeImageToEditor(data.question_content, [])
          //   oldData.question_content = dataDes
          // }
          // if (data?.solution) {
          //   const dataDes = await mergeImageToEditor(data.solution, [])
          //   oldData.solution = dataDes
          // }
          // if (data?.exhibits?.length > 0) {
          //   for (let i = 0; i < data.exhibits.length; i++) {
          //     const dataDes = await mergeImageToEditor(data.exhibits[i].description, [])
          //     oldData.exhibits[i].description = dataDes
          //   }
          // }
          // if (data?.requirements?.length > 0) {
          //   for (let i = 0; i < data.requirements.length; i++) {
          //     const dataDes = await mergeImageToEditor(data.requirements[i].description, [])
          //     oldData.requirements[i].description = dataDes
          //   }
          // }
        } catch (err) {
          console.log(err)
        } finally {
          setNewData((prev: any) => {
            setLoading(false)
            return oldData
          })
        }
      }
    }
    if (open === true && data?.question_topic_id) {
      getData()
    }
  }, [data?.question_topic_id, open])
  const handleClose = () => {
    setLoading(false)
    setNewData(undefined)
    onClose()
  }
  
  return (
    <div>
      {newData && (
        <QuestTionPreview
          data={newData}
          type={data?.qType}
          topicDescription={topicDescription?.description}
          handleClose={handleClose}
          open={open}
          topic={topicDescription}
          setOpenViewFile={setViewFile}
        />
      )}
      <ModalPreviewFile open={viewFile.status} setOpen={setViewFile} url={viewFile.url}/>
    </div>
  )
}
export default PreviewQuestion
