import React from 'react'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'

interface IProps {
  titleReset: string
  resetClick: () => void
  titleSubmit: string
  okClick: () => void
  disabled: boolean
  loading?: boolean
  classNameSubmit?: string
  classNameCancel?: string
}

const SAPPFIlterButton = ({okClick, resetClick, titleReset, titleSubmit, disabled, loading, classNameSubmit, classNameCancel}: IProps) => {
  return (
    <div className='row'>
      <div className='col-md-6 col-xl-4 col-lg-3 pe-md-0 ps-md-3 ps-xl-0 ps-0 pe-0'>
        <ButtonSecondary
          title={titleReset}
          className={`w-100 sapp-height-min--content mb-md-0 mb-3 ${classNameCancel}`}
          onClick={resetClick}
          disabled={disabled}
        />
      </div>
      <div className='col-md-6 col-xl-4 col-lg-3 pe-xl-0 pe-md-3 ps-md-3 ps-xl-3 ps-0 pe-0 sapp-min-width-fit'>
        <ButtonPrimary
          title={titleSubmit}
          className={`w-100 sapp-height-min--content mb-md-0 mb-3 ${classNameSubmit}`}
          onClick={okClick}
          disabled={disabled}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default SAPPFIlterButton
