import { FC, PropsWithChildren } from 'react'
import { HeaderProps } from 'react-table'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { User } from 'src/components/user-list/components/core/_models'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<User>>
}

const NewsSelectionHeader: FC<Props> = ({ tableProps }) => {
  const { isAllSelected, onSelectAll } = useListView()
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <SAPPCheckbox
        checked={isAllSelected}
        ktCheck={isAllSelected}
        onChange={onSelectAll}
      />
    </th>
  )
}

export { NewsSelectionHeader }
