import {fetcher, getBaseUrl} from 'src/services/request'
import {IResponse} from 'src/type'
import {IClass} from 'src/type/classes'

export class ClassesApi {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`classes?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
  static getAreaClasses({
    page_index,
    page_size,
  }: {
    page_index: number
    page_size: number
  }): Promise<IResponse<any>> {
    return fetcher(`areas?page_index=${page_index}&page_size=${page_size}`)
  }

  static getAPIStaffs({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params: Object
  }): Promise<IResponse<any>> {
    return fetcher(
      `/staffs?page_index=${page_index}&page_size=${page_size}`,
      {method: 'GET', params: params}
    )
  }
  static createClass({data}: {data: IClass}): Promise<IResponse<IClass>> {
    return fetcher(getBaseUrl() + '/classes', {method: 'POST', data: data})
  }

  static updateClass(data: any): Promise<IResponse<any>> {
    return fetcher(`/classes`, {method: 'PUT', data})
  }

  static detail(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/classes/${id}`)
  }

  static getClassStudent({
    class_id,
    page_index,
    page_size,
    params,
  }: {
    class_id?: string | undefined
    page_index?: number
    page_size?: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(
      `/classes/${class_id}/students?page_index=${page_index}&page_size=${page_size}`,
      {method: 'GET', params: params}
    )
  }

  static addStudentInClass(data: any): Promise<IResponse<any>> {
    return fetcher(`classes/students`, {
      method: 'POST',
      data,
    })
  }

  static deleteStudentInClass(data: any): Promise<IResponse<any>> {
    return fetcher(`classes/student`, {
      method: 'DELETE',
      data,
    })
  }

  static allowFoundation(user: string[], classId: string | undefined): Promise<any> {
    return fetcher('classes/student-pass-foundation',{
      method: 'PATCH',
      data: {
        user: user,
        classId: classId,
      },
    })
  }
  static getStudentCourseContent(id: string): Promise<IResponse<any>> {
    return fetcher(`courses/${id}/sections/compact`, {method: 'GET'})
  }
  static getExceptedSectionStudent({
    id,
    student_id,
  }: {
    id: string
    student_id: string
  }): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/students/${student_id}`)
  }
  static editCourseContentClass(data: any, id: string): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/course-content`, {method: 'PUT', data: data})
  }
  static editCourseContentStudent(
    data: any,
    id: string,
    student_id: string
  ): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/students/${student_id}/course-content`, {
      method: 'PUT',
      data: data,
    })
  }
  static getListExam({
    id,
    page_index,
    page_size,
    params,
  }: {
    id?: string | undefined
    page_index: number
    page_size: number
    params?: Object
  }):Promise<IResponse<any>> {    
    return fetcher(`/examination/${id}?page_index=${page_index}&page_size=${page_size}`, {method: 'GET', params: params})
  }

  static updateUserDuration(data: any): Promise<IResponse<any>> {
    return fetcher(`/classes/duration`, {method: 'PUT', data})
  }

  static getCourseDetail({
    id, 
    params,
  }: {
    id?: string | number | undefined
    params?: Object
  }): Promise<any> {
    return fetcher(`classes/${id}/user/course/detail`, {method: 'GET', params: params})
  }
  
  static getCodes(
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponse<any>> {
    return fetcher(`classes/codes?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

}
