import {zodResolver} from '@hookform/resolvers/zod'
import {useEffect, useRef, useState} from 'react'
import {useFieldArray, useForm} from 'react-hook-form'
import {CoursesAPI} from 'src/apis/courses'
import ErrorMessage from 'src/common/ErrorMessage'
import SappModal from 'src/components/base/SappModal'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {TYPE_COURSE, VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED} from 'src/constants'
import {useCourseContext} from 'src/context/CourseProvider'
import {ICourseLearnOutCome} from 'src/type/courses'
import {v4 as uuid} from 'uuid'
import {z} from 'zod'
import {PlusVideoIcon} from 'src/common/icons'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import {useConfirm} from 'src/hooks/use-confirm'

interface IProps {
  open: boolean
  setOpen: any
  course_section_id: string
  type?: string
  onlyEditName?: boolean
}

const defaultValues = {
  name: '',
  description: '',
  outcomes: [],
}

interface ILearningOutcome {
  uuid?: string | undefined;
  id?: string | undefined;
  description: string;
}

const validationSchema = z.object({
  name: z
    .string({required_error: VALIDATION_FILED})
    .trim()
    .min(1, VALIDATION_FILED)
    .refine((e) => (e || '').length < 1000, {
      message: VALIDATE_FILED_MAX_LENGTH('Name', 1000),
    }),
  description: z.string().optional().default(''),
  outcomes: z
    .array(
      z.object({
        uuid: z.string().optional(),
        id: z.string().optional(),
        description: z
          .string({required_error: VALIDATION_FILED})
          .trim()
          .min(1, VALIDATION_FILED)
          .default(''),
      })
    )
    .refine((val) => val && val.length > 0, {
      message: 'Learning Outcome must contain at least 1 element(s)',
      path: ['outcomes_errors'],
    })
    .default([]),
})

const CreateLearningOutCome = ({open, setOpen, course_section_id, type, onlyEditName}: IProps) => {
  const learningOutcome = useRef<ICourseLearnOutCome>()
  const {getCourseDetail, idCourse} = useCourseContext()
  const {confirm, contextHolder} = useConfirm()

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {errors},
    trigger,
  } = useForm<ICourseLearnOutCome>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {append, remove} = useFieldArray({
    control,
    name: 'outcomes',
  })
  const [loading, setLoading] = useState<boolean>()

  useEffect(() => {
    if (type === TYPE_COURSE.LEARNINGOUTCOME) {
      ;(async () => {
        setLoading(true)
        try {
          const response = await CoursesAPI.getLearnOutcome(course_section_id)
          if (response) {
            const data = {
              name: response.data?.name,
              description: response.data?.description || '',
              outcomes: response.data?.course_outcomes?.map((e: any) => ({
                id: e.id,
                description: e.description,
                uuid: uuid(),
              })),
            }
            setValue('name', data.name)
            setValue('description', data.description)
            setValue('outcomes', data.outcomes)
            learningOutcome.current = data
          }
        } catch (error) {
          reset(defaultValues)
          setOpen({status: false})
        } finally {
          setLoading(false)
        }
      })()
    } else {
      learningOutcome.current = undefined
    }
  }, [course_section_id, type])

  const onSubmit = async (dataValue: ICourseLearnOutCome) => {
    dataValue = {
      ...dataValue,
      name: dataValue?.name?.trim() || '',
      description: dataValue?.description?.trim() || '',
      outcomes:
        dataValue?.outcomes?.map((e) => ({
          ...e,
          description: e.description?.trim() || '',
          uuid: undefined,
        })) || [],
    }
    setLoading(true)
    try {
      if (type !== TYPE_COURSE.LEARNINGOUTCOME) {
        await CoursesAPI.createLearningOutcome({
          ...dataValue,
          course_section_id: course_section_id,
        })
      } else {
        await CoursesAPI.updateLearnOutcome({
          ...dataValue,
          id: course_section_id,
        })
      }
      reset(defaultValues)
      setOpen({status: false})
      getCourseDetail(idCourse)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    reset(defaultValues)
    setOpen({status: false})
    learningOutcome.current = undefined
  }
  const handleAddOutCome = () => {
    append({
      uuid: uuid(),
      description: '',
    })
    const newTrigger = trigger as any
    newTrigger('outcomes.outcomes_errors')
  }

  const handleDeleteOutCome = async (i: number, e: ILearningOutcome) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: e?.id ? ['OutCome này có liên kết với Activity!', 'Bạn có chắc chắn muốn xóa không?'] : ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => {
        remove(i)
        const newTrigger = trigger as any
        newTrigger('outcomes.outcomes_errors')
      },
      onClose: () => {
        return
      },
    })
  }

  return (
    <div>
      {contextHolder}
      <SappModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit(onSubmit)}
        title={
          type !== TYPE_COURSE.LEARNINGOUTCOME ? `Create Learning Outcome` : 'Edit Learning Outcome'
        }
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={true}
        cancelButtonCaption={'Cancel'}
        okButtonCaption={'Save'}
        loading={loading}
        confirmOnclose
        footerEnd
      >
        <div>
          <div>
            <div className='mb-8'>
              <HookFormTextField
                required
                label='Name'
                control={control}
                name='name'
                placeholder=''
              />
            </div>
            <div>
              <HookFormEditor
                label='Describe'
                control={control}
                name='description'
                placeholder=''
                defaultValue={getValues('description')}
              />
            </div>
          </div>
          <div className='separator separator-dashed mb-5 mt-10 border-2 sapp-border-gray_ECECEC'></div>
          <ErrorMessage>{(errors.outcomes as any)?.outcomes_errors?.message}</ErrorMessage>
          {getValues('outcomes')?.map((e: ILearningOutcome, i: number) => {
            return (
              <div key={e.uuid}>
                <div className={`d-flex gap-6 justify-content-between align-items-end mb-4 ${onlyEditName && 'mt-10'}`}>
                  <label className='fs-6 form-label required fw-bold'>
                    Learning Outcome {i + 1}
                  </label>
                  {!onlyEditName && (
                    <ButtonIconOnly
                      iconName='trash'
                      onClick={() => handleDeleteOutCome(i, e)}
                      iconType='outline'
                      bg={'sapp-custom-bg-transparent'}
                      activeColor='danger'
                    />
                  )}
                </div>
                <div>
                  <HookFormEditor
                    control={control}
                    name={`outcomes.${i}.description`}
                    placeholder=''
                    defaultValue={getValues(`outcomes.${i}.description`)}
                    // disabled={onlyEditName}
                  />
                </div>
                {!onlyEditName && (
                  <div className='separator separator-dashed mb-5 mt-10 border-2 sapp-border-gray_ECECEC'></div>
                )}
              </div>
            )
          })}
          {!onlyEditName && (
            <div
              onClick={(e) => {
                handleAddOutCome()
                // e.target.blur()
              }}
              className='d-flex justify-content-start align-items-center sapp-add-learn mt-10'
              // customButton='bg-transparent'
            >
              <span className='d-flex align-items-center me-2'>
                <PlusVideoIcon />
              </span>
              Add more learning outcome
            </div>
          )}
        </div>
      </SappModal>
    </div>
  )
}
export default CreateLearningOutCome
