/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useDebounce from 'src/hooks/use-debounce'
import { initialQueryState } from 'src/_metronic/helpers'
import { useQueryRequest } from 'src/components/user-list/components/core/QueryRequestProvider'
import Search from 'src/components/base/search'
import { LANG_NEWS } from 'src/constants/lang'

const NewsListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  // Effect for call API search
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ text: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm]
  )

  return (
    <div className='card-title'>
      {/* start:: search */}
      <Search placeholder={LANG_NEWS.searchNews} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
      {/* end:: search */}
    </div>
  )
}

export { NewsListSearchComponent }
