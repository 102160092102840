import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import ActionCell from 'src/components/base/action/ActionCell'
import {PageLink} from 'src/constants'
import {CODE_ADMIN, TITLE_STORY_GR} from 'src/constants/permission'
import {useUserContext} from 'src/context/UserProvider'
import {Role} from 'src/type'

interface IProps {
  id: string
  // filterParams?: Object
  fetchStoryList: (currentPage: number, pageSize: number, queryString?: string) => void
  queryString?: string
  setCurrentPage?: number
  // setLoading: Dispatch<SetStateAction<boolean>>
}

const StoryActionsCell = ({id}: IProps) => {
  const navigate = useNavigate()
  // const [openModalDelete,setOpenModalDelete] = useState<boolean>(false)
  // const {search} = useLocation()
  // const searchParams = new URLSearchParams(search)
  // const page_size = parseInt(searchParams.get('page_size') as string)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const {profileMe} = useUserContext()
  const allowRenderEditStory = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STORY_GR.EDIT_STORY) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      {allowRenderEditStory && (
        <ActionCell customWidth='w-150px'>
          <div className='menu-item px-3'>
            <div className='menu-link px-3' onClick={() => navigate(`${PageLink.STORY}/${id}`)}>
              Edit
            </div>
          </div>
        </ActionCell>
      )}
    </>
  )
}

export {StoryActionsCell}
