import {isEmpty} from 'lodash'
import React, {PropsWithChildren, createContext, useContext, useEffect, useState} from 'react'
import {StaffAPI} from 'src/apis/staffs'
import {UsersAPI} from 'src/apis/user'
import {AuthAPI} from 'src/apis/user/auth'
import {IForgotPassword} from 'src/type'
import {IStudentDetail} from 'src/type/students'
import {getActToken, removeJwtToken, setActToken, setRefreshToken} from 'src/utils'

// type for context
type Context = {
  loginUsername: (username: string, password: string) => Promise<void>
  logOut: () => Promise<void>
  forgotPasswordOTP: (email: string) => Promise<void>
  staffForgot: any
  emailStaff: string
  verifyOTP: (email: string, code: string) => Promise<void>
  sendEmailOTP: (id: string | undefined, email: string) => Promise<void>
  emailEditStaff: string
  sendEmailOTPUser: (id: string | undefined, email: string) => Promise<void>
  emailEditUser: string
  getStaffDetail: (id: string | undefined) => Promise<any>
  getStudentDetail: (id: string | undefined) => Promise<any>
  getProfile: () => any
  profileMe: IStudentDetail | null | undefined
  makeUseContactDefault: ({
    userId,
    userContactId,
  }: {
    userId: string
    userContactId: string
  }) => Promise<void>
}

// initContext
const initContext: Context = {
  logOut: async () => {},
  loginUsername: async () => {},
  forgotPasswordOTP: async () => {},
  staffForgot: null,
  emailStaff: '',
  verifyOTP: async () => {},
  sendEmailOTP: async () => {},
  emailEditStaff: '',
  sendEmailOTPUser: async () => {},
  emailEditUser: '',
  getStaffDetail: async () => {},
  getStudentDetail: async () => {},
  getProfile: async () => {},
  profileMe: null,
  makeUseContactDefault: async () => {},
}

const UserContext = createContext<Context>(initContext)

export function UserProvider(props: PropsWithChildren<{}>) {
  const [emailStaff, setEmailStaff] = useState('')
  const [staffForgot, setStaffForgot] = useState<IForgotPassword>()
  const [emailEditStaff, setEmailEdit] = useState('')
  const [emailEditUser, setEmailEditUser] = useState('')
  const [profileMe, setProfileMe] = useState<IStudentDetail>()

  // login username
  const loginUsername = async (username: string, password: string) => {
    const res = await AuthAPI.loginByUsername({username, password})
    if (!res) return
    const userInfo = res?.data?.tokens
    if(userInfo) {
      UsersAPI.myprofile().then((res) => setProfileMe(res))
    }
    setActToken(userInfo?.act)
    setRefreshToken(userInfo?.rft)
  }

  // logOut
  const logOut = async () => {
    await AuthAPI.logout().then(() => removeJwtToken())
  }

  const forgotPasswordOTP = async (email: string) => {
    await StaffAPI.forgotPassword(email).then(() => setEmailStaff(email))
  }

  const verifyOTP = async (email: string, code: string) => {
    await StaffAPI.verifyEmailOTP(email, code).then((res) => setStaffForgot(res?.data))
  }

  const sendEmailOTP = async (id: string | undefined, email: string) => {
    await StaffAPI.sendOTP(id, email).then(() => setEmailEdit(email))
  }

  const sendEmailOTPUser = async (id: string | undefined, email: string) => {
    await UsersAPI.sendOTP(id, email).then(() => setEmailEditUser(email))
  }

  const getStaffDetail = async (id: string | undefined) => {
    const res = await StaffAPI.detail(id)
    return res
  }

  const getStudentDetail = async (id: string | undefined) => {
    const res = await UsersAPI.detail(id)
    return res
  }

  const getProfile = async () => {
    const res = await UsersAPI.myprofile()
    return res
  }

  const makeUseContactDefault = async ({
    userId,
    userContactId,
  }: {
    userId: string
    userContactId: string
  }) => {
    try {
      await UsersAPI.makeUseContactDefault({userId, userContactId})
    } catch (error) {}
  }

  useEffect(() => {
    if (!isEmpty(getActToken())) {
      UsersAPI.myprofile().then((res) => setProfileMe(res))
    }
  }, [getActToken()])

  return (
    <UserContext.Provider
      value={{
        loginUsername,
        logOut,
        forgotPasswordOTP,
        staffForgot,
        emailStaff,
        verifyOTP,
        emailEditStaff,
        sendEmailOTP,
        emailEditUser,
        sendEmailOTPUser,
        getStaffDetail,
        getStudentDetail,
        getProfile,
        profileMe,
        makeUseContactDefault,
      }}
      {...props}
    />
  )
}

export function useUserContext(): Context {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('Error!')
  }

  return context
}
