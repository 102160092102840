import React from 'react'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import {PageLink} from 'src/constants'

const LoginSessions = () => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title'>
          <h3>Login Sessions</h3>
        </div>

        <div className='card-toolbar'>
          <div className='my-0 me-4'>
            <SAPPSelect
              size='middle'
              options={[
                {
                  label: 'Role',
                  value: 'Role',
                },
                {
                  label: 'One',
                  value: 'One',
                },
              ]}
            />
          </div>
          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary my-1'>
            View All
          </a>
        </div>
      </div>

      <div className='card-body p-0'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed table-row-solid gy-4 gs-9'>
            <thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
              <tr>
                <th className='min-w-250px'>Location</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-150px'>Device</th>
                <th className='min-w-150px'>IP Address</th>
                <th className='min-w-150px'>Time</th>
              </tr>
            </thead>

            <tbody className='fw-6 fw-semibold text-gray-600'>
              <tr>
                <td>
                  <a href={PageLink.DASHBOARD} className='text-hover-primary text-gray-600'>
                    USA(5)
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-success fs-7 fw-bold'>OK</span>
                </td>
                <td>Chrome - Windows</td>
                <td>236.125.56.78</td>
                <td>2 mins ago</td>
              </tr>
              <tr>
                <td>
                  <a href={PageLink.DASHBOARD} className='text-hover-primary text-gray-600'>
                    United Kingdom(10)
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-success fs-7 fw-bold'>OK</span>
                </td>
                <td>Safari - Mac OS</td>
                <td>236.125.56.78</td>
                <td>10 mins ago</td>
              </tr>
              <tr>
                <td>
                  <a href={PageLink.DASHBOARD} className='text-hover-primary text-gray-600'>
                    Norway(-)
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-danger fs-7 fw-bold'>ERR</span>
                </td>
                <td>Firefox - Windows</td>
                <td>236.125.56.10</td>
                <td>20 mins ago</td>
              </tr>
              <tr>
                <td>
                  <a href={PageLink.DASHBOARD} className='text-hover-primary text-gray-600'>
                    Japan(112)
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-success fs-7 fw-bold'>OK</span>
                </td>
                <td>iOS - iPhone Pro</td>
                <td>236.125.56.54</td>
                <td>30 mins ago</td>
              </tr>
              <tr>
                <td>
                  <a href={PageLink.DASHBOARD} className='text-hover-primary text-gray-600'>
                    Italy(5)
                  </a>
                </td>
                <td>
                  <span className='badge badge-light-warning fs-7 fw-bold'>WRN</span>
                </td>
                <td>Samsung Noted 5- Android</td>
                <td>236.100.56.50</td>
                <td>40 mins ago</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default LoginSessions
