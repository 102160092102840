import React from 'react'
import {TEST_TYPE} from 'src/components/courses/shared/Interfaces'
import TestComponent from 'src/components/courses/test/TestComponent'

interface IProps {
  tabId: string
  setData: any
  onSubmit: any
  handleCancel: any
  edit?: boolean
  doc?: any
}
const AddTabQuiz = ({tabId, onSubmit, handleCancel, edit, doc}: IProps) => {
  // format data de fill form edit
  return (
    <div className='mb-6'>
      <TestComponent
        id={tabId}
        testFor=''
        type={TEST_TYPE.QUIZ}
        customOnSubmit={(e: any) => onSubmit(e)}
        customOnCancel={handleCancel}
        forTab
        oldData={edit ? doc.quiz : undefined}
        buttonSaveTitle='Save Document'
        CancelSubmitBtnClass='d-flex justify-content-end'
      />
    </div>
  )
}
export default AddTabQuiz
