import {fetcher} from 'src/services/request'

export class CommonAPI {
  static getTags({apiEndpoint, params}: {apiEndpoint: string; params: Object}) {
    return fetcher(apiEndpoint, {
      method: 'GET',
      params,
    })
  }
}
