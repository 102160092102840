import React from 'react'
import {Link} from 'react-router-dom'
import {bytesToGB} from 'src/utils'
import {KTIcon} from 'src/_metronic/helpers'

const CardResource = ({totalSize}: {totalSize: any}) => {
  return (
    <div className='card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10'>
      <div className='card-header py-10'>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-circle me-5'>
            <div className='symbol-label bg-transparent text-primary border border-secondary border-dashed'>
              <KTIcon
                iconName='abstract-47'
                iconType='outline'
                className='fs-2x text-primary'
              ></KTIcon>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <h2 className='mb-1'>Resource Manager</h2>
            <div className='text-muted fw-bold'>
              {bytesToGB(totalSize?.totalSize)}
              GB <span className='mx-3'>|</span> {totalSize?.totalRecord} items
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardResource
