import React from 'react'
import avatar from "src/_metronic/assets/media/avatars/300-14.jpg";
import doc from "src/_metronic/assets/media/svg/files/doc.svg";
import files from "src/_metronic/assets/media/svg/files/css.svg";
import stock from "src/_metronic/assets/media/stock/600x400/img-29.jpg";
import { PageLink } from 'src/constants';

const ActivityProfile = () => {
  return (
    <div className='card'>
      <div className='card-header card-header-stretch'>
        <div className='card-title d-flex align-items-center'>
          <i className='ki-outline ki-calendar-8 fs-1 text-primary me-3 lh-0'></i>
          <h3 className='fw-bold m-0 text-gray-800'>Jan 23, 2023</h3>
        </div>

        <div className='card-toolbar m-0'>
          <ul
            className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold'
            role='tablist'
          >
            <li className='nav-item' role='presentation'>
              <a
                id='kt_activity_today_tab'
                className='nav-link justify-content-center text-active-gray-800 active'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_today'
              >
                Today
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_activity_week_tab'
                className='nav-link justify-content-center text-active-gray-800'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_week'
              >
                Week
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_activity_month_tab'
                className='nav-link justify-content-center text-active-gray-800'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_month'
              >
                Month
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_activity_year_tab'
                className='nav-link justify-content-center text-active-gray-800 text-hover-gray-800'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_activity_year'
              >
                2023
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className='card-body'>
        <div className='tab-content'>
          <div
            id='kt_activity_today'
            className='card-body p-0 tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='kt_activity_today_tab'
          >
            <div className='timeline'>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-message-text-2 fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      There are 2 new tasks for you in “AirPlus Mobile App” project:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Nina Nilson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px'
                      >
                        Meeting with customer
                      </a>

                      <div className='min-w-175px pe-2'>
                        <span className='badge badge-light text-muted'>Application Design</span>
                      </div>

                      <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
                        <div className='symbol symbol-circle symbol-25px'>
                          <img src={avatar} alt='img' />
                        </div>

                        <div className='symbol symbol-circle symbol-25px'>
                          <img src={avatar} alt='img' />
                        </div>

                        <div className='symbol symbol-circle symbol-25px'>
                          <div className='symbol-label fs-8 fw-semibold bg-primary text-inverse-primary'>
                            A
                          </div>
                        </div>
                      </div>

                      <div className='min-w-125px pe-2'>
                        <span className='badge badge-light-primary'>In Progress</span>
                      </div>

                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='btn btn-sm btn-light btn-active-light-primary'
                      >
                        View
                      </a>
                    </div>

                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px'
                      >
                        Project Delivery Preparation
                      </a>

                      <div className='min-w-175px'>
                        <span className='badge badge-light text-muted'>CRM System Development</span>
                      </div>

                      <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px'>
                        <div className='symbol symbol-circle symbol-25px'>
                          <img src={avatar} alt='img' />
                        </div>

                        <div className='symbol symbol-circle symbol-25px'>
                          <div className='symbol-label fs-8 fw-semibold bg-success text-inverse-primary'>
                            B
                          </div>
                        </div>
                      </div>

                      <div className='min-w-125px'>
                        <span className='badge badge-light-success'>Completed</span>
                      </div>

                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='btn btn-sm btn-light btn-active-light-primary'
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-flag fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                  <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Invitation for crafting engaging designs that speak human workshop
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Alan Nilson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-disconnect fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='mb-5 pe-3'>
                    <a href={PageLink.DASHBOARD} className='fs-5 fw-semibold text-gray-800 text-hover-primary mb-2'>
                      3 New Incoming Project Files:
                    </a>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 10:30 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Jan Hummer'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5'>
                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img alt='' className='w-30px me-3' src={avatar} />

                        <div className='ms-1 fw-semibold'>
                          <a
                            href={PageLink.AUTH_LOGIN}
                            className='fs-6 text-hover-primary fw-bold'
                          >
                            Finance KPI App Guidelines
                          </a>

                          <div className='text-gray-400'>1.9mb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={doc}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Client UAT Testing Results
                          </a>

                          <div className='text-gray-400'>18kb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={files}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Finance Reports
                          </a>

                          <div className='text-gray-400'>20mb</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-abstract-26 fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Task
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      merged with
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      in “Ads Pro Admin Dashboard project:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Initiated at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Nina Nilson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-pencil fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      3 new application design concepts added:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Created at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Marcus Dotson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7'>
                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-sms fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New case
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'> #67890</a>
                      is assigned to you in Multi-platform Database Design project
                    </div>

                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

                        <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                          Alice Tan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-pencil fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>You have received a new order:</div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Placed at 5:05 AM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Robert Rich'
                      >
                        {/* <img src={avatar} alt='img' /> */}
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='notice d-flex bg-secondary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6'>
                      <i className='ki-outline ki-devices-2 fs-2tx text-primary me-4'></i>

                      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                        <div className='mb-3 mb-md-0 fw-semibold'>
                          <h4 className='text-gray-900 fw-bold'>
                            Database Backup Process Completed!
                          </h4>
                          <div className='fs-6 text-gray-700 pe-7'>
                            Login into Admin Dashboard to make sure the data integrity is OK
                          </div>
                        </div>

                        <a href={PageLink.DASHBOARD} className='btn btn-primary px-6 align-self-center text-nowrap'>
                          Proceed
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-basket fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New order
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #67890
                      </a>
                      is placed for Workshow Planning & Budget Estimation
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Placed at 4:23 PM by</div>

                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        Jimmy Bold
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id='kt_activity_week'
            className='card-body p-0 tab-pane fade show'
            role='tabpanel'
            aria-labelledby='kt_activity_week_tab'
          >
            <div className='timeline'>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-flag fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                  <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Invitation for crafting engaging designs that speak human workshop
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Alan Nilson'
                      >
                        <img src='assets/media/avatars/300-1.jpg' alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-disconnect fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='mb-5 pe-3'>
                    <a href={PageLink.DASHBOARD} className='fs-5 fw-semibold text-gray-800 text-hover-primary mb-2'>
                      3 New Incoming Project Files:
                    </a>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 10:30 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Jan Hummer'
                      >
                        <img src='assets/media/avatars/300-23.jpg' alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5'>
                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img alt='' className='w-30px me-3' src='assets/media/svg/files/pdf.svg' />

                        <div className='ms-1 fw-semibold'>
                          <a
                            href={PageLink.AUTH_LOGIN}
                            className='fs-6 text-hover-primary fw-bold'
                          >
                            Finance KPI App Guidelines
                          </a>

                          <div className='text-gray-400'>1.9mb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={doc}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Client UAT Testing Results
                          </a>

                          <div className='text-gray-400'>18kb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={files}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Finance Reports
                          </a>

                          <div className='text-gray-400'>20mb</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-abstract-26 fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Task
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      merged with
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      in “Ads Pro Admin Dashboard project:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Initiated at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Nina Nilson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-pencil fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      3 new application design concepts added:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Created at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Marcus Dotson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7'>
                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-sms fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New case
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'> #67890</a>
                      is assigned to you in Multi-platform Database Design project
                    </div>

                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

                        <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                          Alice Tan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id='kt_activity_month'
            className='card-body p-0 tab-pane fade show'
            role='tabpanel'
            aria-labelledby='kt_activity_month_tab'
          >
            <div className='timeline'>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-pencil fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      3 new application design concepts added:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Created at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Marcus Dotson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7'>
                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-sms fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New case
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'> #67890 </a>
                      is assigned to you in Multi-platform Database Design project
                    </div>

                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

                        <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                          Alice Tan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-basket fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New order
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #67890
                      </a>
                      is placed for Workshow Planning & Budget Estimation
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Placed at 4:23 PM by</div>

                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        Jimmy Bold
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-flag fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                  <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Invitation for crafting engaging designs that speak human workshop
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Alan Nilson'
                      >
                        <img src='assets/media/avatars/300-1.jpg' alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-disconnect fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='mb-5 pe-3'>
                    <a href={PageLink.DASHBOARD} className='fs-5 fw-semibold text-gray-800 text-hover-primary mb-2'>
                      3 New Incoming Project Files:
                    </a>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 10:30 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Jan Hummer'
                      >
                        <img src='assets/media/avatars/300-23.jpg' alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5'>
                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img alt='' className='w-30px me-3' src='assets/media/svg/files/pdf.svg' />

                        <div className='ms-1 fw-semibold'>
                          <a
                            href={PageLink.AUTH_LOGIN}
                            className='fs-6 text-hover-primary fw-bold'
                          >
                            Finance KPI App Guidelines
                          </a>

                          <div className='text-gray-400'>1.9mb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={doc}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Client UAT Testing Results
                          </a>

                          <div className='text-gray-400'>18kb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={files}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Finance Reports
                          </a>

                          <div className='text-gray-400'>20mb</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-abstract-26 fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Task
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      merged with
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      in “Ads Pro Admin Dashboard project:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Initiated at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Nina Nilson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id='kt_activity_year'
            className='card-body p-0 tab-pane fade show'
            role='tabpanel'
            aria-labelledby='kt_activity_year_tab'
          >
            <div className='timeline'>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-disconnect fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='mb-5 pe-3'>
                    <a href={PageLink.DASHBOARD} className='fs-5 fw-semibold text-gray-800 text-hover-primary mb-2'>
                      3 New Incoming Project Files:
                    </a>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Sent at 10:30 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Jan Hummer'
                      >
                        <img src='assets/media/avatars/300-23.jpg' alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5'>
                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img alt='' className='w-30px me-3' src='assets/media/svg/files/pdf.svg' />

                        <div className='ms-1 fw-semibold'>
                          <a
                            href={PageLink.AUTH_LOGIN}
                            className='fs-6 text-hover-primary fw-bold'
                          >
                            Finance KPI App Guidelines
                          </a>

                          <div className='text-gray-400'>1.9mb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={doc}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Client UAT Testing Results
                          </a>

                          <div className='text-gray-400'>18kb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center'>
                        <img
                          alt={PageLink.AUTH_LOGIN}
                          className='w-30px me-3'
                          src={files}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href={PageLink.DASHBOARD} className='fs-6 text-hover-primary fw-bold'>
                            Finance Reports
                          </a>

                          <div className='text-gray-400'>20mb</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-abstract-26 fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      Task
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      merged with
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #45890
                      </a>
                      in “Ads Pro Admin Dashboard project:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Initiated at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Nina Nilson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-pencil fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      3 new application design concepts added:
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Created at 4:23 PM by</div>

                      <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Marcus Dotson'
                      >
                        <img src={avatar} alt='img' />
                      </div>
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7'>
                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay me-10'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className='overlay'>
                        <div className='overlay-wrapper'>
                          <img
                            alt='img'
                            className='rounded w-150px'
                            src={stock}
                          />
                        </div>

                        <div className='overlay-layer bg-dark bg-opacity-10 rounded'>
                          <a href={PageLink.DASHBOARD} className='btn btn-sm btn-primary btn-shadow'>
                            Explore
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-sms fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New case
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'> #67890</a>
                      is assigned to you in Multi-platform Database Design project
                    </div>

                    <div className='overflow-auto pb-5'>
                      <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

                        <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                          Alice Tan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <i className='ki-outline ki-basket fs-2 text-gray-500'></i>
                  </div>
                </div>

                <div className='timeline-content mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-semibold mb-2'>
                      New order
                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        #67890
                      </a>
                      is placed for Workshow Planning & Budget Estimation
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>Placed at 4:23 PM by</div>

                      <a href={PageLink.DASHBOARD} className='text-primary fw-bold me-1'>
                        Jimmy Bold
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityProfile
