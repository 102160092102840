import { useNavigate } from 'react-router-dom'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs, Role } from 'src/type'
import withAuthComponents from 'src/components/auth/with-auth-components'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {RolesAPI} from 'src/apis/roles'
import { useEffect, useState} from 'react'
import RoleInfo from 'src/components/roles/list/RoleInfo'
import { IRoles, IRolesList } from 'src/type/roles'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {KTIcon} from 'src/_metronic/helpers'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import './RolesList.scss'
import AddStaff from 'src/components/roles/add-staff/AddStaff'
import AddRole from 'src/components/roles/add-role/AddRole'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_ROLE_GR } from 'src/constants/permission'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.ROLES}`,
    title: 'Roles List'
  }
]

const RolesList = () => {
  const navigate = useNavigate()
  const [rolesList, setRolesList] = useState<IRolesList>()
  const [openModelStaff, setOpenModelStaff] = useState<boolean>(false)
  const [roleId, setRoleId] = useState<string>('')
  const [roleData, setRoleData] = useState<any>()
  const [openModelRole, setOpenModelRole] = useState<boolean>(false)
  const { profileMe } = useUserContext()
  const allowRenderCreate = profileMe?.roles?.some((role: Role)  => role.permissions?.includes(TITLE_ROLE_GR.CREATE_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN);

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const fetchRoutesList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await RolesAPI.getListRoles({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      if (res) {
        setRolesList(res?.data)
      }
    } catch (error) {
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchRoutesList(page_index, page_size)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  const handleOpenModelStaffs = (id: string, role?: any) => {
    setRoleId(id)
    setOpenModelStaff(true)
    setRoleData(role)
  }

  const handleOpenEditRole = (id: string, role: any) => {
    setRoleId(id)
    setRoleData(role)
    setOpenModelRole(true)
  }

  const handleAddRole = () => {
    setRoleId('')
    setRoleData({})
    setOpenModelRole(true)
  }

  useEffect(() => {
    fetchRoutesList(1, 10, {})
  }, [])
  return (
    <PageLayouts
      pageTitle='Roles List'
      breadcrumbs={breadcrumbs}
    >
      <div className='row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-5 g-xl-9'>
        <div className='sapp-table-min-height'>
          <div className='card h-md-100'>
            <div className='card-body d-flex flex-center'>
              {allowRenderCreate && (
                 <ButtonIcon
                 title={'New Role'}
                 className='sapp-h-45px'
                 customButton='btn btn-light d-flex align-items-center btn-active-primary sapp-font-import-student sapp-role-btn-icon fw-semibold px-4'
                 onClick={() => handleAddRole()}
               >
                 <KTIcon iconName='plus' className='fs-2 position-relative sapp-top-px' />
               </ButtonIcon>
              )}
            </div>
          </div>
        </div>
        {rolesList?.roles?.map((role: IRoles) => (
          <RoleInfo
            key={role?.id}
            role={role}
            handleOpenModelStaffs={handleOpenModelStaffs}
            handleOpenModelRole={handleOpenEditRole}
          />
        ))}
      </div>
      <PagiantionSAPP
        currentPage={rolesList?.metadata?.page_index || 1}
        pageSize={rolesList?.metadata?.page_size || 10}
        totalItems={rolesList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      <AddStaff
        open={openModelStaff}
        setOpen={setOpenModelStaff}
        id={roleId as string}
        roleData={roleData}
        functionCallBack={() => {
          fetchRoutesList(
            rolesList?.metadata?.page_index || 1,
            rolesList?.metadata?.page_size || 10
          )
        }}
      />
      <AddRole
        open={openModelRole}
        setOpen={setOpenModelRole}
        id={roleId as string}
        roleData={roleData}
        functionCallBack={() => {
          fetchRoutesList(
            rolesList?.metadata?.page_index || 1,
            rolesList?.metadata?.page_size || 10
          )
        }}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(RolesList)
