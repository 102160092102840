import {fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {ICaseStudy} from 'src/type/case-study'

export class CaseStudyAPI {
  static getById(id: string): Promise<IResponse<ICaseStudy>> {
    return fetcher(`/quiz/case-study/${id}`, {
      method: 'GET',
    })
  }

  static create(data: ICaseStudy): Promise<void> {
    return fetcher('/quiz/case-study', {
      method: 'POST',
      data,
    })
  }
  static update(id: string, data: ICaseStudy): Promise<void> {
    return fetcher(`/quiz/case-study/${id}`, {
      method: 'PUT',
      data,
    })
  }
  static delete(id: string): Promise<void> {
    return fetcher(`/quiz/case-study/${id}`, {
      method: 'DELETE',
    })
  }
}
