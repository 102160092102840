import axios, {AxiosResponse, CancelTokenSource} from 'axios'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {VALID_UPLOAD_EDITOR, VALID_UPLOAD_FILES} from 'src/constants/upload'
import {fetcher, request} from 'src/services/request'
import {IResponse} from 'src/type'
import {validateFile} from 'src/utils/upload'
import {ResourcesAPI} from '../resources'
import {ResourceAPI} from '../resource-bank'

type PartUploadDto = {part_number: number; upload_url: string}

type PartUploadedDto = {eTag: string; part_number: number}

type StartMultipartResponse = {
  uploadId: string
  parts: PartUploadDto[]
  metadata: {
    partSize: number
    numberOfParts: number
  }
}
export class UploadAPI {
  static async startUpload({
    content_type,
    name,
    size,
    blob,
    description,
    source,
    getProgress,
    responsePreUpload,
    saveLocation,
  }: {
    content_type: string
    name: string
    size: string
    blob: Blob
    description: string
    source: CancelTokenSource
    getProgress: (percent: number) => void
    responsePreUpload?: {
      data: {
        exist_type: 'BOTH' | 'OVERRIDE'
        type: string
        file_key: string
        upload_url: string
        display_name: string
      }
    }
    saveLocation?: {
      location: string
      parent_id: string
    }
  }) {
    try {
      // const saveLocation = await this.prepareSaveLocation({
      //   resourceLocation,
      //   parentId,
      //   source,
      // })
      // const responsePreUpload = await this.preUpload({
      //   content_type,
      //   name: (name || '').split('.').slice(0, -1).join('.'),
      //   location: saveLocation?.location || '',
      //   size,
      //   source,
      // })

      if (!responsePreUpload) {
        return
      }

      await uploadFile(
        {
          upload_url: responsePreUpload?.data.upload_url,
          file_key: responsePreUpload?.data.file_key,
          type: responsePreUpload?.data.type as 'SINGLE_PART' | 'MULTIPLE_PART',
          contentType: content_type,
          blob,
        },
        source,
        getProgress
      )

      const response = await addFileResource({
        name: responsePreUpload.data.display_name,
        file_key: responsePreUpload?.data.file_key,
        location: saveLocation?.location || '',
        description,
        size,
        parent_id: saveLocation?.parent_id || null,
      })

      if (response?.data?.[0]?.id) {
        const responseFile = await ResourcesAPI.getFileFromResource(response.data[0].id, source)
        return responseFile.data
      }
    } catch (error) {
      throw error
    }
  }

  static prepareSaveLocation = async ({
    parentId,
    source,
    resourceLocation,
  }: {
    resourceLocation: RESOURCE_LOCATION
    source: CancelTokenSource
    parentId?: string
  }) => {
    let location
    let saveLocation

    if (!parentId || parentId === 'null') {
      if (resourceLocation) {
        location = await getLocation(source, resourceLocation)
        if (!location?.data?.id) {
          throw new Error('No location')
        }
        saveLocation = {
          parent_id: location.data.id,
          location: `${location.data.location ? `${location.data.location}/` : ''}${
            location.data.name
          }/`,
        }
      }
    } else {
      const parent = await ResourceAPI.getResourceDetail(parentId)
      location = parent.data.location
      saveLocation = {
        parent_id: parent.data.id,
        location: `${parent.data.location ? `${parent.data.location}/` : ''}${parent.data.name}/`,
      }
    }

    return saveLocation
  }

  static upload = async (
    upload_files: any,
    listPayload: any[],
    acceptFiles: {
      editor: {type: string; size: number}[]
      upload: {type: string; size: number}[]
    } = {
      editor: VALID_UPLOAD_EDITOR,
      upload: VALID_UPLOAD_FILES,
    }
  ) => {
    try {
      if (upload_files) {
        for await (const file of upload_files) {
          const crrFile = listPayload.find((crrFile) => crrFile.dom_id === file.dom_id)
          if (crrFile && file.upload_url) {
            const acceptType =
              file.type && file.type !== 'editor' ? acceptFiles.upload : acceptFiles.editor
            if (!validateFile(crrFile, acceptType)) continue
            await uploadFile({
              ...file,
              blob: crrFile.value,
              file_key: file.resource.file_key,
              contentType: crrFile.contentType,
            })
          }
        }
      }
    } catch (error) {
      throw error
    }
  }
  static preUpload = async ({
    content_type,
    name,
    location,
    size,
    source,
    display_name,
    exist_type,
    parent_id,
  }: {
    content_type: string
    name: string
    location: string | null
    size: string
    source: CancelTokenSource
    display_name?: string
    exist_type?: 'BOTH' | 'OVERRIDE'
    parent_id?: string
  }): Promise<
    IResponse<{
      exist_type: 'BOTH' | 'OVERRIDE'
      type: string
      file_key: string
      upload_url: string
      display_name: string
      parent_id: string
    }>
  > => {
    return fetcher(`/resources/pre-upload/metadata`, {
      cancelToken: source.token,
      params: {
        content_type,
        name,
        location,
        size,
        display_name,
        exist_type: exist_type || undefined,
        parent_id,
      },
    })
  }
}

const getLocation = async (
  source: CancelTokenSource,
  resourceLocation: RESOURCE_LOCATION
): Promise<
  IResponse<{
    id: string
    created_at: string
    updated_at: string
    deleted_at: string
    name: string
    file_key: string
    location: string
    stream_url: string
    resource_type: string
    suffix_type: string
    is_default: string
    thumbnail: string
    cloudflare_video_id: string
    size: string
    time_line: string
    description: string
    status: string
    parent_id: string
  }>
> => {
  return fetcher(`/resources/upload/folder`, {
    cancelToken: source.token,
    params: {
      name: resourceLocation,
    },
  })
}

const addFileResource = async ({
  name,
  file_key,
  location,
  description,
  size,
  parent_id,
}: {
  name: string
  file_key: string
  location: string
  description: string
  size: string
  parent_id: string | null
}) => {
  return fetcher(`/resources/upload/add-file-resource`, {
    method: 'POST',
    data: {
      files: [
        {
          name,
          file_key,
          location,
          description,
          size,
          parent_id,
        },
      ],
    },
  })
}

let percent = 0

const uploadFile = async (
  file: {
    contentType: string
    file_key: string
    upload_url: string
    blob: Blob
    type: 'SINGLE_PART' | 'MULTIPLE_PART'
  },
  source?: CancelTokenSource,
  getProgress?: (percent: number) => void
) => {
  const fileBlob = file.blob
  try {
    if (file.type === 'SINGLE_PART') {
      const onUploadProgress = (progressEvent: any) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (getProgress) {
          getProgress(percent)
        }
      }
      try {
        await axios.put(file.upload_url, fileBlob, {
          headers: {'Content-Type': fileBlob.type},
          ...(source && {cancelToken: source.token}),
          onUploadProgress,
        })
      } catch (error) {
        throw error
      }
      return
    }

    const startResp = await request.post(
      `/resources/upload/start`,
      {
        file_key: file.file_key,
        content_type: fileBlob.type,
        size: fileBlob.size,
      },
      {...(source && {cancelToken: source.token})}
    )
    const startMultipartResponse: StartMultipartResponse = startResp.data.data
    const {uploadId, metadata, parts} = startMultipartResponse

    const batchSize = 2
    percent = 0
    const uploadPartsArray: PartUploadedDto[] = await uploadMultipart(
      {
        batchSize,
        chunkSize: metadata.partSize,
        fileBlob,
        numberOfChunks: metadata.numberOfParts,
        parts,
        ...(getProgress ? {getProgress} : {getProgress: () => {}}),
      },
      source
    )

    await request.post(
      `/resources/upload/complete`,
      {
        file_key: file.file_key,
        parts: uploadPartsArray,
        uploadId: uploadId,
      },
      {...(source && {cancelToken: source.token})}
    )
  } catch (error) {
    throw error
  }
}

type UploadMultipartParams = {
  chunkSize: number
  numberOfChunks: number
  fileBlob: Blob
  index?: number
  batchSize: number
  parts: PartUploadDto[]
  getProgress: (percent: number) => void
}
async function uploadMultipart(
  params: UploadMultipartParams,
  source?: CancelTokenSource
): Promise<PartUploadedDto[]> {
  const {chunkSize, numberOfChunks, fileBlob, batchSize, parts, getProgress} = params
  let index = params.index === undefined ? 0 : params.index

  const partNumbers = parts.map((part) => part.part_number)

  const batchUploadPromises: Promise<any>[] = []

  let batchElementIndex = 0

  while (batchElementIndex < batchSize && index < numberOfChunks) {
    index += 1
    batchElementIndex += 1

    const start = (index - 1) * chunkSize

    const end = index * chunkSize

    const blob = index < numberOfChunks ? fileBlob.slice(start, end) : fileBlob.slice(start)

    const uploadUrl = parts[partNumbers.indexOf(index)]?.upload_url
    try {
      const uploadPromise = getUploadPromise(
        {
          blob,
          contentType: fileBlob.type,
          index,
          uploadUrl,
          fileSize: fileBlob.size,
          chunkSize: blob.size,
          getProgress,
        },
        source
      )
      batchUploadPromises.push(uploadPromise)
    } catch (error) {
      throw error
    }
  }

  const batchUploadResults = await Promise.allSettled(batchUploadPromises)
  const partsUploadResults: PartUploadedDto[] = []

  for (let result of batchUploadResults) {
    if (result.status === 'fulfilled') {
      let {response, index: part_number} = result.value
      partsUploadResults.push({
        eTag: response.headers.etag,
        part_number,
      })
    } else {
      throw new Error('One request failed: ' + result.reason)
    }
  }

  if (index < numberOfChunks) {
    const nextPartsUploadResults = await uploadMultipart({...params, index})
    return [...partsUploadResults, ...nextPartsUploadResults]
  }

  return partsUploadResults
}

type GetUploadPromiseParams = {
  blob: Blob
  uploadUrl?: string
  index: number
  contentType: string
  getProgress: (percent: number) => void
  fileSize: number
  chunkSize: number
}

function getUploadPromise(
  params: GetUploadPromiseParams,
  source?: CancelTokenSource
): Promise<{response: AxiosResponse<any, any>; index: number}> {
  const {blob, index, uploadUrl, contentType, getProgress, fileSize, chunkSize} = params
  return new Promise((resolve, reject) => {
    if (!uploadUrl) {
      reject('Upload url is empty at part_number: ' + index)
    } else {
      axios
        .put(uploadUrl, blob, {
          headers: {'Content-Type': contentType},
          ...(source && {cancelToken: source.token}),
        })
        .then((response) => {
          let percentCompleted = Math.round((chunkSize / fileSize) * 100)
          percent += percentCompleted
          getProgress(percent >= 100 ? 100 : percent)
          resolve({response, index})
        })
        .catch((error) => {
          source && source.cancel('One request failed, canceling all the others')
          getProgress(100)
          reject(error)
        })
    }
  })
}
