import React from 'react'
import {PageLink} from 'src/constants'

const PopularTickets = () => {
  return (
    <div className='col-md-6'>
      <div className='card card-md-stretch me-xl-3 mb-md-0 mb-6'>
        <div className='card-body p-10 p-lg-15'>
          <div className='d-flex flex-stack mb-7'>
            <h1 className='fw-bold text-dark'>Popular Tickets</h1>

            <div className='d-flex align-items-center'>
              <a href='https://keenthemes.com/support' className='text-primary fw-bold me-1'>
                Support
              </a>

              <i className='ki-outline ki-arrow-right fs-2 text-primary'></i>
            </div>
          </div>

          <div className='m-0'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_support_1_1'
            >
              <div className='ms-n1 me-5'>
                <i className='ki-outline ki-down toggle-on text-primary fs-2'></i>
                <i className='ki-outline ki-right toggle-off fs-2'></i>
              </div>

              <div className='d-flex align-items-center flex-wrap'>
                <h3 className='text-gray-800 fw-semibold cursor-pointer me-3 mb-0'>
                  What admin theme does?
                </h3>

                <span className='badge badge-light my-1 d-block'>React</span>
              </div>
            </div>

            <div id='kt_support_1_1' className='collapse show fs-6 ms-10'>
              <div className='mb-4'>
                <span className='text-muted fw-semibold fs-5'>
                  By Keenthemes to save tons and more to time money projects are listed and
                  outstanding
                </span>

                <a href={PageLink.DASHBOARD} className='fs-5 link-primary fw-semibold'>
                  Check Out
                </a>
              </div>
            </div>
          </div>

          <div className='m-0'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_support_1_2'
            >
              <div className='ms-n1 me-5'>
                <i className='ki-outline ki-down toggle-on text-primary fs-2'></i>
                <i className='ki-outline ki-right toggle-off fs-2'></i>
              </div>

              <div className='d-flex align-items-center flex-wrap'>
                <h3 className='text-gray-800 fw-semibold cursor-pointer me-3 mb-0'>
                  How Extended Licese works?
                </h3>

                <span className='badge badge-light my-1 d-block'>Laravel</span>
              </div>
            </div>

            <div id='kt_support_1_2' className='collapse fs-6 ms-10'>
              <div className='mb-4'>
                <span className='text-muted fw-semibold fs-5'>
                  By Keenthemes to save tons and more to time money projects are listed and
                  outstanding
                </span>

                <a href={PageLink.DASHBOARD} className='fs-5 link-primary fw-semibold'>
                  Check Out
                </a>
              </div>
            </div>
          </div>

          <div className='m-0'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_support_1_3'
            >
              <div className='ms-n1 me-5'>
                <i className='ki-outline ki-down toggle-on text-primary fs-2'></i>
                <i className='ki-outline ki-right toggle-off fs-2'></i>
              </div>

              <div className='d-flex align-items-center flex-wrap'>
                <h3 className='text-gray-800 fw-semibold cursor-pointer me-3 mb-0'>
                  How to install on a local machine?
                </h3>

                <span className='badge badge-light my-1 d-block'>VueJS</span>
              </div>
            </div>

            <div id='kt_support_1_3' className='collapse fs-6 ms-10'>
              <div className='mb-4'>
                <span className='text-muted fw-semibold fs-5'>
                  By Keenthemes to save tons and more to time money projects are listed and
                  outstanding
                </span>

                <a href={PageLink.DASHBOARD} className='fs-5 link-primary fw-semibold'>
                  Check Out
                </a>
              </div>
            </div>
          </div>

          <div className='m-0'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_support_1_4'
            >
              <div className='ms-n1 me-5'>
                <i className='ki-outline ki-down toggle-on text-primary fs-2'></i>
                <i className='ki-outline ki-right toggle-off fs-2'></i>
              </div>

              <div className='d-flex align-items-center flex-wrap'>
                <h3 className='text-gray-800 fw-semibold cursor-pointer me-3 mb-0'>
                  How can I import Google fonts?
                </h3>

                <span className='badge badge-light my-1 d-block'>Angular 9</span>
              </div>
            </div>

            <div id='kt_support_1_4' className='collapse fs-6 ms-10'>
              <div className='mb-4'>
                <span className='text-muted fw-semibold fs-5'>
                  By Keenthemes to save tons and more to time money projects are listed and
                  outstanding
                </span>

                <a href={PageLink.DASHBOARD} className='fs-5 link-primary fw-semibold'>
                  Check Out
                </a>
              </div>
            </div>
          </div>

          <div className='m-0'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_support_1_5'
            >
              <div className='ms-n1 me-5'>
                <i className='ki-outline ki-down toggle-on text-primary fs-2'></i>
                <i className='ki-outline ki-right toggle-off fs-2'></i>
              </div>

              <div className='d-flex align-items-center flex-wrap'>
                <h3 className='text-gray-800 fw-semibold cursor-pointer me-3 mb-0'>
                  How long the license is valid?
                </h3>

                <span className='badge badge-light my-1 d-block'>Bootstrap 5</span>
              </div>
            </div>

            <div id='kt_support_1_5' className='collapse fs-6 ms-10'>
              <div className='mb-4'>
                <span className='text-muted fw-semibold fs-5'>
                  By Keenthemes to save tons and more to time money projects are listed and
                  outstanding
                </span>

                <a href={PageLink.DASHBOARD} className='fs-5 link-primary fw-semibold'>
                  Check Out
                </a>
              </div>
            </div>
          </div>

          <div className='m-0'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_support_1_6'
            >
              <div className='ms-n1 me-5'>
                <i className='ki-outline ki-down toggle-on text-primary fs-2'></i>
                <i className='ki-outline ki-right toggle-off fs-2'></i>
              </div>

              <div className='d-flex align-items-center flex-wrap'>
                <h3 className='text-gray-800 fw-semibold cursor-pointer me-3 mb-0'>
                  How many end projects I can build?
                </h3>

                <span className='badge badge-light my-1 d-block'>PHP</span>
              </div>
            </div>

            <div id='kt_support_1_6' className='collapse fs-6 ms-10'>
              <div className='mb-4'>
                <span className='text-muted fw-semibold fs-5'>
                  By Keenthemes to save tons and more to time money projects are listed and
                  outstanding
                </span>

                <a href={PageLink.DASHBOARD} className='fs-5 link-primary fw-semibold'>
                  Check Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopularTickets
