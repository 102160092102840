interface IAssigned {
  name: string
  classNameColor?: 'primary' | 'danger' | 'success' | 'info' | 'warning'
  id: string
}

const AssignedTo = ({roles}: {roles: IAssigned[] | undefined}) => {
  const getColorRole = (index: number) => {
    switch (index) {
      case 0:
        return 'primary'
      case 1:
        return 'danger'
      case 2:
        return 'success'
      case 3:
        return 'info'
      case 4:
        return 'warning'
      case 5:
        return 'secondary'
      case 6:
        return 'light'
      case 7:
        return 'dark'
      default:
        return 'primary'
    }
  }
  return (
    <>
      {roles?.map((role, index) => (
        <div key={role.id} className={`badge badge-light-${getColorRole(index)} fs-7 my-1 me-1`}>
          {role.name}
        </div>
      ))}
    </>
  )
}

export default AssignedTo
