import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { ITabs } from "src/type";

const Tabs = ({ tabs }: { tabs: ITabs[] }) => {
    const location = useLocation()
    return (
        <ul className='nav flex-wrap border-transparent fw-bold'>
            {
                tabs.map((tab) => (
                    <li className='nav-item my-1' key={tab.title}>
                        <Link
                            className={`${location.pathname === tab.link ? 'active' : ''} btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase`}
                            to={tab.link}
                        >
                            {tab.title}
                        </Link>
                    </li>
                ))
            }
        </ul>
    )
}

export default Tabs