import React from 'react'
import UserStatusCell from '../user-management/UserStatusCell'

interface IProps {
  university?: string | undefined
  phone: number
  email: string | undefined
  status?: 'ACTIVE' | 'BLOCKED' | 'INACTIVE' | 'MERGED' | undefined
}

const MoreInfoUser = ({email, phone, university, status}: IProps) => {
  return (
    <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
      <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
        {university ? (
          <i className='ki-outline ki-briefcase fs-4 me-1' />
        ) : (
          <i className='ki-outline ki-user-tick fs-4 me-1' />
        )}
        {university ? university : <UserStatusCell status={status} />}
      </div>
      <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
        <i className='ki-outline ki-phone fs-4 me-1'></i>
        {phone}
      </div>
      <div className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
        <i className='ki-outline ki-sms fs-4 me-1'></i>
        <>{email}</>
      </div>
    </div>
  )
}

export default MoreInfoUser
