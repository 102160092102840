import React, {Dispatch, SetStateAction, useState} from 'react'
import DeactivateAccount from 'src/components/my-profile/setting-profile/DeactivateAccount'
// import EmailPreferences from 'src/components/my-profile/setting-profile/EmailPreferences'
// import Notifications from 'src/components/my-profile/setting-profile/Notifications'
import {IStudentDetail} from 'src/type/students'
import EditStudentProfile from './EditStudentProfile'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import toast from 'react-hot-toast'
import { useUserContext } from 'src/context/UserProvider'

interface IProps {
  studentDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const SettingStudent = ({studentDetail, setLoading, loading,setStudentDetail}: IProps) => {
  const { id } = useParams()
  const [confirmDeactive, setConfirmDeactive] = useState(false)
  const {getStudentDetail} = useUserContext()

  const blockedStudent = async () => {
    await UsersAPI.blocked([id as string]).then(() => toast.success('Blocked Successfully!'))
    await getStudentDetail(id).then((res)=>setStudentDetail(res?.data))
  }

  return (
    <>
      <EditStudentProfile studentDetail={studentDetail} setStudentDetail={setStudentDetail} />
      {/* <ConnectAccount /> */}

      {/* <EmailPreferences />

      <Notifications /> */}

      <DeactivateAccount confirmDeactive={confirmDeactive} setConfirmDeactive={setConfirmDeactive} blockedUser={blockedStudent} />
    </>
  )
}

export default SettingStudent
