import React, {Dispatch, SetStateAction} from 'react'
import EditClassProfile from './EditClassProfile'

interface IProps {
  classDetail: any | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setClassDetail: Dispatch<SetStateAction<any>>
}

const SettingClassUser = ({classDetail, setLoading, loading, setClassDetail}: IProps) => {
  return (
    <>
      <EditClassProfile
        loading={loading}
        setLoading={setLoading}
        classDetail={classDetail}
        setClassDetail={setClassDetail}
      />
    </>
  )
}

export default SettingClassUser
