import {AutoComplete, Skeleton} from 'antd'
import {DefaultOptionType} from 'antd/es/select'
import _debounce from 'lodash/debounce'
import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {Control, Controller} from 'react-hook-form'
import {KTIcon} from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'
import './HookFormTag.scss'
import {normalizeLikeParam} from 'src/utils/string'

interface ITag {
  id?: string
  name?: string
  description?: string
}

type FilterFunc<OptionType> = (inputValue: string, option?: OptionType) => boolean

interface IHookFormTagProps {
  name: string
  control: Control<any>
  defaultValue?: ITag[]
  label?: string
  dropdownStyle?: React.CSSProperties | undefined
  fetch: (params: any) => Promise<any>
  filterOption?: boolean | FilterFunc<DefaultOptionType> | undefined
  placeholder?: string
  skeleton?: boolean
  required?: boolean
  className?: string
  guideline?: string[]
  disabled?: boolean
  classCustomTag?: string
  isCanAddNew?: boolean
  dataName?: string
}

/**
 * Component HookFormTagFetch
 * @component
 * @param {IHookFormTagProps} props - Props của component
 * @returns {JSX.Element}
 */
const HookFormTag = ({
  name,
  control,
  defaultValue,
  dropdownStyle,
  fetch,
  filterOption,
  placeholder,
  skeleton,
  label,
  required,
  className = '',
  guideline,
  disabled,
  classCustomTag,
  isCanAddNew = true,
  dataName,
}: IHookFormTagProps) => {
  const [firstOption, setFirstOption] = React.useState<string | undefined>('')
  const [apiOptions, setApiOptions] = React.useState<ITag[]>([])
  const autoCompleteRef = React.useRef<any>()
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Hàm gọi API với debounce
   * @param {string} text - Đoạn văn bản tìm kiếm
   * @returns {void}
   */
  const debounceFetchApiOptions = React.useCallback(
    _debounce(async (text: string) => {
      try {
        const response = await fetch({text, page_index: 1, page_size: 10})
        if (dataName && response?.success) {
          setApiOptions(response?.data?.[dataName])
        } else {
          setApiOptions(response?.data)
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }, 1000),
    []
  )

  useEffect(() => {
    setLoading(true)
    debounceFetchApiOptions(firstOption || '')
  }, [debounceFetchApiOptions, firstOption])

  /**
   * Hàm xử lý sự kiện khi chọn
   * @param {string} item - Mục đã chọn
   * @param {ITag[]} value - Giá trị hiện tại
   * @param {(...event: any[]) => void} onChange - Hàm xử lý thay đổi
   * @returns {void}
   */
  const onSelect = (item: string, value: ITag[], onChange: (...event: any[]) => void): void => {
    const parseItem = JSON.parse(item)
    const text = parseItem.name
    setFirstOption('')
    if (!value?.some((e) => e.name === text)) {
      onChange([...(value ?? []), parseItem ?? []])
    }
  }

  /**
   * Hàm xử lý sự kiện xóa một tag
   * @param {string[]} value - Giá trị hiện tại
   * @param {number} i - Index của tag cần xóa
   * @param {(...event: any[]) => void} onChange - Hàm xử lý thay đổi
   * @returns {void}
   */
  const onRemove = (value: string[], i: number, onChange: (...event: any[]) => void) => {
    onChange(value?.filter((_: string, di: number) => di !== i) ?? [])
  }

  /**
   * Hàm xử lý sự kiện tìm kiếm
   * @param {string} text - Đoạn văn bản tìm kiếm
   * @returns {void}
   */
  const onSearch = async (text: string) => {
    setFirstOption(text)
  }

  /**
   * Hàm lọc cho AutoComplete
   * @type {FilterFunc<DefaultOptionType>}
   */
  const customFilterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    const inputText = input?.toLowerCase() ?? ''
    if (!inputText) {
      return false
    }
    if (option?.value?.toString()) {
      try {
        const parseOption = JSON.parse(option.value.toString())
        return normalizeLikeParam(parseOption?.name).includes(normalizeLikeParam(inputText))
      } catch (error) {}
    }
    return false
  }

  const apiOptionsName = useMemo(() => {
    if (!apiOptions) return {}

    return Object.fromEntries(apiOptions?.map((e: ITag) => [e.name, true]))
  }, [apiOptions])

  return (
    <div className='w-100'>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={
          !skeleton
            ? ({field: {onChange, value}, fieldState: {error}}) => {
                const newFirstOption = {name: firstOption || ''}

                const valueNames: Record<string, boolean> = Object.fromEntries(
                  value?.map((e: ITag) => [e.name, true]) || []
                )

                let options: ITag[] = []
                if (isCanAddNew && firstOption) {
                  if (!apiOptionsName[firstOption]) {
                    options = [newFirstOption, ...(apiOptions || [])]
                  } else {
                    options = apiOptions
                  }
                } else {
                  options = apiOptions
                }

                return (
                  <div>
                    <div
                      className={`${
                        disabled ? 'sapp-hook-form-tag-disabled' : ''
                      } ${className} sapp-hook-form-tag ${error?.message ? 'is-error' : ''}`}
                      onClick={() => autoCompleteRef.current?.focus()}
                    >
                      {value?.map((t: ITag, i: number) => (
                        <div key={i} className='sapp-tag'>
                          <span>{t.name}</span>
                          <div
                            className='sapp-tag-delete'
                            onClick={() => onRemove(value, i, onChange)}
                          >
                            {!disabled && (
                              <KTIcon iconName='cross' iconType='outline' className='fs-3' />
                            )}
                          </div>
                        </div>
                      ))}
                      {!disabled && (
                        <div
                          className={`${
                            classCustomTag ?? ''
                          } position-relative sapp-tag-auto-complete`}
                        >
                          <AutoComplete
                            value={firstOption}
                            ref={autoCompleteRef}
                            className='sapp-tag-auto-complete-component fs-6'
                            size='large'
                            defaultActiveFirstOption
                            filterOption={filterOption ?? customFilterOption}
                            onSearch={onSearch}
                            onSelect={(e) => onSelect(e, value, onChange)}
                            placeholder={(!value?.length || value.length === 0) && placeholder}
                            dropdownStyle={{
                              zIndex: '2000',
                              ...(dropdownStyle && dropdownStyle),
                              minWidth: '150px',
                            }}
                            disabled={disabled}
                            notFoundContent={
                              <div className='ant-select-item-empty m-4'>
                                <div className='ant-empty ant-empty-normal ant-empty-small text-center'>
                                  <div className='ant-empty-image'>
                                    <svg
                                      width='64'
                                      height='41'
                                      viewBox='0 0 64 41'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <g transform='translate(0 1)' fill='none' fillRule='evenodd'>
                                        <ellipse
                                          fill='#f5f5f5'
                                          cx='32'
                                          cy='33'
                                          rx='32'
                                          ry='7'
                                        ></ellipse>
                                        <g fillRule='nonzero' stroke='#d9d9d9'>
                                          <path d='M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z'></path>
                                          <path
                                            d='M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z'
                                            fill='#fafafa'
                                          ></path>
                                        </g>
                                      </g>
                                    </svg>
                                  </div>
                                  <div
                                    className='ant-empty-description'
                                    style={{color: 'rgba(0, 0, 0, 0.25)'}}
                                  >
                                    No data
                                  </div>
                                </div>
                              </div>
                            }
                          >
                            {options?.map((item: ITag, i: number) => {
                              return (
                                <Fragment key={i}>
                                  {item?.name && (
                                    <AutoComplete.Option
                                      value={JSON.stringify(item)}
                                      disabled={valueNames[item.name]}
                                    >
                                      {item.name}
                                    </AutoComplete.Option>
                                  )}
                                </Fragment>
                              )
                            })}
                          </AutoComplete>
                          {loading ? (
                            <div className='sapp-arrow-icon-tag_spinner'>
                              <div className='spinner-border ' role='status'>
                                <span className='sr-only'></span>
                              </div>
                            </div>
                          ) : (
                            <svg
                              className='sapp-arrow-icon-tag'
                              xmlns='http://www.w3.org/2000/svg'
                              width='15'
                              height='9'
                              fill='none'
                              viewBox='0 0 15 9'
                            >
                              <path
                                fill='#7E8299'
                                d='M7.89 8.24a1 1 0 01-.71-.29l-6-6A1 1 0 112.59.53l5.3 5.3 5.29-5.3a1 1 0 01.71 1.42l-6 6a1 1 0 01-.7.29z'
                              ></path>
                            </svg>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <GuidelineField guideline={guideline} />
                      <ErrorMessage>{error?.message}</ErrorMessage>
                    </div>
                  </div>
                )
              }
            : () => <Skeleton.Input active size='large' className='w-100'></Skeleton.Input>
        }
      />
    </div>
  )
}

export default HookFormTag
