import {createRoot} from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import 'src/_metronic/assets/fonticon/fonticon.css'
import 'src/_metronic/assets/keenicons/duotone/style.css'
import 'src/_metronic/assets/keenicons/outline/style.css'
import 'src/_metronic/assets/keenicons/solid/style.css'
import 'src/_metronic/assets/sass/style.scss'
import 'src/_metronic/assets/sass/plugins.scss'
import 'src/styles/index.scss'
import App from './App'
import {UserProvider} from './context/UserProvider'
import {Toaster} from 'react-hot-toast'
import {CourseProvider} from './context/CourseProvider'
import {LoadingProvider} from './context/LoadingContext'

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <CourseProvider>
          <LoadingProvider>
            <App />
          </LoadingProvider>
          <Toaster />
        </CourseProvider>
      </UserProvider>
    </QueryClientProvider>
  )
}
