import React from 'react'

interface IProps {
  pageTitle: string | undefined
}

const HeaderEntranceTest = ({pageTitle}: IProps) => {
  return (
    <div className='sapp-custom-background-layout-2'>
      <div className='app-container container-lg d-flex pt-5'>
        <div
          className='d-flex justify-content-start flex-row-fluid border-bottom border-grey pb-5 px-6 mx-3'
        >
          <div className='page-title d-flex align-items-center'>
            <h1 className='page-heading d-flex text-dark fw-bolder fs-2x flex-column justify-content-center '>
              {pageTitle}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderEntranceTest
