import {useEffect, useState} from 'react'

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    // Function to update window width state
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    // Attach the event listener
    window.addEventListener('resize', updateWindowWidth)

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, []) // Empty dependency array to run the effect only once

  return windowWidth
}

export default useWindowWidth
