import {useLocation, useParams} from 'react-router-dom'
import CaseStudyComponent from 'src/components/courses/course-detail/case-study/Index'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'

// const breadcrumbs: ITabs[] = [
//   {
//     link: `${PageLink.AUTH_LOGIN}`,
//     title: LANG_SIDEBAR.lms,
//   },
//   {
//     link: `${PageLink.COURSES}`,
//     title: LANG_SIDEBAR.courses,
//   },
//   {
//     link: `${PageLink.CASE_STUDY}`,
//     title: LANG_SIDEBAR.caseStudy,
//   },
// ]

const CaseStudy = () => {
  const params = useParams()
  const {state} = useLocation() 
  return (
    <PageLayouts
      pageTitle=''
      breadcrumbs={[]}
      titleTab='CaseStudy'
      backgroudColor='bg-white'
      showSideBar={false}
      removePadding={true}
    >
      {params.courseSectionId && params.courseId && (
        <> <div className='sapp-text-primary fs-3 fw-bold pb-8 border-gray-300 border-bottom mb-8'>
        {`${params?.id ? 'Edit' : 'Create'} Case Study`}
      </div>
          <CaseStudyComponent
            courseSectionId={params.courseSectionId}
            courseId={params.courseId}
            id={params.id}
            editOnlyName={state?.onlyEditName}
          ></CaseStudyComponent>
        </>
      )}
    </PageLayouts>
  )
}

export default CaseStudy
