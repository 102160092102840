import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  newPassword: string
  content: string
}

const ModalResetPassword = ({open, setOpen, newPassword, content}: IProps) => {
  return (
    <Modal
      dialogClassName='modal-dialog mw-430px swal2-container sapp-popup-confirm'
      contentClassName='sapp-p-content-modal'
      centered
      show={open}
      onHide={() => setOpen(false)}
    >
      {/* start:: header modal */}
      <div className='swal2-icon swal2-success swal2-icon-show d-flex'>
        <div
          className='swal2-success-circular-line-left'
          style={{backgroundColor: 'rgb(255, 255, 255)'}}
        ></div>
        <span className='swal2-success-line-tip'></span>{' '}
        <span className='swal2-success-line-long'></span>
        <div className='swal2-success-ring'></div>{' '}
        <div className='swal2-success-fix' style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
        <div
          className='swal2-success-circular-line-right'
          style={{backgroundColor: 'rgb(255, 255, 255)'}}
        ></div>
      </div>
      {/* end:: header modal */}

      {/* start:: body modal */}
      {/* <div className='modal-body py-5 px-lg-17'> */}
      {/* start:: textfield description */}
      <div className='swal2-html-container text-center d-block'>
        {content} <span className='h5'>{newPassword}</span>
      </div>
      {/* </div> */}
      {/* end:: body modal */}
      {/* start:: dialog buton cancel submit */}

      <div className='text-center swal2-actions d-flex'>
        <ButtonPrimary title='Hoàn tất' onClick={() => setOpen(false)} />
      </div>

      {/* </div> */}
    </Modal>
  )
}

export default ModalResetPassword
