import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import {htmlToRaw} from 'src/utils'
import {LayoutQues, qType} from 'src/utils/string'

interface IProps {
  questionList: any
  loading: boolean
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  singleCheck?: boolean
}

// define headers
const headers = [
  {
    label: 'Item Set NAME',
    className: 'min-w-200px',
  },
  {
    label: 'TOPIC DESCRIPTION',
    className: 'min-w-350px',
  },
  {
    label: 'QUESTION',
    className: 'min-w-350px ',
  },
  {
    label: 'Type',
    className: 'min-w-100px ',
  },
  {
    label: 'LEVEL',
    className: 'min-w-100px',
  },
  {
    label: 'LAYOUT',
    className: 'min-w-50px',
  },
]

const TableListQuestion = ({
  questionList,
  loading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  singleCheck = false,
}: IProps) => {
  return (
    <div className='card-body'>
      <SappTable
        headers={headers}
        loading={loading}
        data={questionList}
        isCheckedAll={isCheckedAll}
        hasCheckAll={!singleCheck}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {questionList?.map((question: any, index: number) => {
              const isChecked = checkedList.includes(question.id)
              const type = question.qType
              const layout = question.display_type

              return (
                <tr key={question.id}>
                  <td className='mw-50px w-50px'>
                    {singleCheck ? (
                      <SAPPRadio
                        checkTarget='#kt_table_users .form-check-input'
                        checked={isChecked}
                        ktCheck={isChecked}
                        onChange={() => {
                          toggleCheck(question.id)
                        }}
                      />
                    ) : (
                      <SAPPCheckbox
                        checkTarget='#kt_table_users .form-check-input'
                        checked={isChecked}
                        ktCheck={isChecked}
                        onChange={() => {
                          toggleCheck(question.id)
                        }}
                      />
                    )}
                  </td>
                  <td className='text-start'>{question?.question_topic?.name ?? '-'}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-2'>
                      {htmlToRaw(question?.question_topic?.description)}
                    </div>
                  </td>
                  <td className='text-start'>
                    <div className='sapp-text-primary fs-6 fw-semibold sapp-text-truncate-2'>
                      {htmlToRaw(question.question_content) ?? '-'}{' '}
                    </div>
                  </td>
                  <td className='text-start'>{qType[type as keyof typeof qType] ?? '-'}</td>
                  <td className='text-start text-capitalize'>{question?.level?.toLowerCase()}</td>
                  <td className='text-start'>
                    {LayoutQues[layout as keyof typeof LayoutQues] ?? '-'}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
    </div>
  )
}

export default TableListQuestion
