import { useParams } from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import CreateNotiComponent from 'src/components/notifications/components/createNotiComponent/CreateNotiComponent'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Gửi thông báo',
  },
]

const SendNotification = () => {
  let { id } = useParams()

  return (
    <PageLayouts
      pageTitle=''
      titleTab={id ? 'Edit Notification/ Email' : 'Create Notification/ Email'}
      breadcrumbs={breadcrumbs}
      backgroudColor='bg-white'
      showSideBar={false}
      removePadding={true}
      showFooter={false}
    >
      <div className='sapp-text-primary fs-3 fw-bold pb-8 border-gray-300 border-bottom sapp-mb-24px px-10'>
        {id ? 'Edit' : 'Create'} Notification/ Email
      </div>
      <CreateNotiComponent id={id} />
    </PageLayouts>
  )
}

export default withAuthComponents(SendNotification)
