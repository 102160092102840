import React from 'react'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import {PageLink} from 'src/constants'
import sapp from 'src/_metronic/assets/media/logos/sapp-logo-default.svg'
import success from 'src/_metronic/assets/media/logos/success.png'

const ChangePasswordSuccess = () => {
  return (
    <div className='d-flex flex-column flex-root sapp-height-layout--login justify-content-center'>
      <div className='d-flex justify-content-center'>
        <img className='sapp-logo w-125px object-fit-contain' src={sapp} alt='sapp' />
      </div>
      <div className='d-flex flex-column  flex-lg-row justify-content-center'>
        <div className='d-flex flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 box-shadow--custom'>
            <div className='d-flex flex-center flex-column'>
              {/* Start Form Login */}
              <div className='form w-100'>
                <div className='d-flex justify-content-center'>
                  <img className='w-125px' src={success} alt='sapp' />
                </div>
                <div className='text-center mb-20'>
                  <h1 className='text-dark fw-bolder mb-5 mt-7 sapp-title--success'>Successfully</h1>
                  <div className='sapp-body--success'>
                    Your password has been reset successfully
                  </div>
                </div>

                {/* Start Button Login */}
                <div className='d-grid mb-5'>
                  <ButtonPrimary title='Login' link={PageLink.AUTH_LOGIN} className='py-3' classNameTitle='sapp-title-button' />
                </div>
                {/* End Button Login */}
              </div>
              {/* End Form Login */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordSuccess
