import { useParams } from "react-router-dom";
import withAuthComponents from "src/components/auth/with-auth-components";
import ImportLogTableDetail from "src/components/base/upload-file/ImportLogTableDetail";
import PageLayouts from "src/components/layout/PageLayouts";
import { PageLink } from "src/constants";
import { LANG_SIDEBAR } from "src/constants/lang";
import { ITabs } from "src/type";

let defaultBreadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
]

const ImportStudentDetail = () => {
  const params = useParams()

  const setBreadcrumbs = (type?: string) => {
    let breadcrumbs = defaultBreadcrumbs;
    if (type === 'CLASS_STUDENT') {
      breadcrumbs = [...breadcrumbs,
      {
        link: `/logs/CLASS_STUDENT`,
        title: LANG_SIDEBAR.importLog,
      },]
    }

    return [...breadcrumbs, {
      link: `${PageLink.IMPORT_LOG}`,
      title: 'Detail',
    }]
  }
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.importLog} breadcrumbs={setBreadcrumbs(params.type) ?? defaultBreadcrumbs}>
      <div className="card" >
         <ImportLogTableDetail params={params} type={'CLASS_STUDENT'}></ImportLogTableDetail>
      </div>
    </PageLayouts >
  )
}

export default withAuthComponents(ImportStudentDetail)