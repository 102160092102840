import React, {Dispatch, SetStateAction} from 'react'
import {Drawer, Popover} from 'antd'
import avatar from 'src/_metronic/assets/media/avatars/300-2.jpg'
import {LANG_HEADER} from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'

interface IProps {
  setOpenNotifications: Dispatch<SetStateAction<boolean>>
  openNotifications: boolean
}

const Notifications = ({setOpenNotifications, openNotifications}: IProps) => {
  const {profileMe} = useUserContext()

  const getDrawerWidth = () => {
    if (window.innerWidth >= 1200) {
      return 550
    } else {
      return 350
    }
  }
  return (
    <Drawer
      placement='right'
      closable={false}
      onClose={() => setOpenNotifications(false)}
      open={openNotifications}
      width={getDrawerWidth()}
    >
      <div className='card w-100 border-0 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a href='/' className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                {profileMe?.detail?.full_name}
              </a>

              {/* <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-semibold text-muted'>Active</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default Notifications
