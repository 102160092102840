import { Skeleton } from 'antd'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'

interface IHookFormCheckBoxProps {
  name: string
  control: Control<any>
  defaultValue?: string | boolean
  title?: string
  className?: string
  onChange?: React.ChangeEventHandler<any>
  checked?: string | boolean
  label?: string | undefined
  required?: boolean
  disabled?: boolean
  classNameTitle?: string
  classLabel?: string
  loading?: boolean
}

const HookFormCheckBox = ({
  name,
  control,
  defaultValue,
  title,
  className = '',
  onChange,
  checked,
  label,
  required,
  disabled,
  classNameTitle,
  classLabel,
  loading
}: IHookFormCheckBoxProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <>
          {
            loading ? (
              <Skeleton.Button active block className='sapp-h-45px' />
            ) : (
              <label className={`form-check form-check-custom form-check-solid form-label ${classLabel ?? ''}`}>
                <input
                  className={`${className} form-check-input`}
                  type='checkbox'
                  checked={checked ?? field.value}
                  onChange={(event: React.ChangeEvent<any>) => {
                    field.onChange(event.target.checked)
                    onChange && onChange(event.target.checked)
                  }}
                  disabled={disabled}
                />
                <span className={`form-check-label fw-semibold sapp-text-primary fs-6 ${classNameTitle ?? ''}`}>{title}</span>
              </label>
            )
          }
          <ErrorMessage>{error?.message}</ErrorMessage>
        </>
      )}
    />
  )
}

export default HookFormCheckBox
