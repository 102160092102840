import {Control, Controller} from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import '../radiobutton/HookFormRadioGroup.scss'
import './HookFormCheckBoxGroup.scss'
import { Tooltip } from 'antd'

interface IHookFormRadioGroupProps {
  name: string
  control: Control<any>
  defaultValue?: unknown
  options: Array<{display_name?: string; key: string | boolean; description?: string; group?: string}>
  direction?: 'horizontal' | 'vertical'
  separator?: boolean
  justify?: 'between' | 'start' | 'center' | 'end'
  gap?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  labelClass?: string
  labelClassChecked?: string
  disabled?: boolean
  itemWidth?: any
  className?: string
  classItem?: string
  showToolTip?: boolean
}

const HookFormCheckBoxGroup = ({
  name,
  control,
  defaultValue,
  options,
  direction,
  separator,
  gap,
  onChange,
  justify = 'between',
  labelClass = '',
  labelClassChecked = '',
  disabled,
  itemWidth,
  className = '',
  classItem = '',
  showToolTip = false
}: IHookFormRadioGroupProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field, fieldState: {error}}) => {        
        return(
        <>
          <div
            className={`${className}`}
          >
            {options.map((option, index) => {
              const checked: boolean = field.value?.includes(option.key.toString())
              return (
                <div className={`${classItem}`} key={option.key.toString() + index}>
                  <div className='d-flex fv-row'>
                    <label className='form-check form-check-solid sapp-form-check-custom ps-9 cursor-pointer'>
                      <input
                        {...field}
                        disabled={disabled}
                        onChange={(event: React.ChangeEvent<any>) => {
                          let arr = [] as any
                          if (field.value?.length > 0) {
                            arr = [...field.value]
                            if (arr.includes(event.target.value)) {
                              const newArr = arr.filter(
                                (e: any) => e !== event.target.value,
                                )
                              arr = [...newArr]
                            } else {
                              arr.push(event.target.value)
                            }
                          } else {
                            arr.push(event.target.value)
                          }
                          field.onChange(arr)
                          onChange && onChange(arr)
                        }}
                        className={`form-check-input bg-gray-300 me-2 cursor-pointer`}
                        type='checkbox'
                        key={option.display_name}
                        value={option.key.toString()}
                        checked={checked}
                      />
                      <span className='form-check-label ps-1 d-block'>
                        <div
                          className={`d-flex align-items-center ${
                            checked
                              ? `sapp-text-primary ${
                                  labelClassChecked ? labelClassChecked : 'fw-semibold fs-6 lh-1'
                                }`
                              : `sapp-box-no-check ${labelClass ? labelClass : 'fw-semibold fs-6 lh-1'}`
                          }`}
                        >
                          {option.display_name}
                          {showToolTip &&
                            <Tooltip title={option.description} color='#ffffff' placement='top'>
                              <i className='ki-duotone ki-question-2 fs-1 ps-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </Tooltip>
                          }
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              )
            })}
          </div>
          <ErrorMessage>{error?.message}</ErrorMessage>
        </>)}
      }
    />
  )
}

export default HookFormCheckBoxGroup
