import React from 'react'
import {getYear} from 'date-fns'

// define footer
const footer = [
  {
    title: 'About',
    link: 'https://sapp.edu.vn/ve-chung-toi/',
  },
  {
    title: 'Support',
    link: 'https://sapp.edu.vn/lien-he/',
  },
]

const Footer = () => {
  const currentDate = new Date()
  const currentYear = getYear(currentDate)

  return (
    <div className='app-footer'>
      <div className='app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3'>
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-semibold me-1'>{currentYear}&copy;</span>
          <a
            href='https://sapp.edu.vn'
            target='_blank'
            className='text-gray-800 text-hover-primary'
            rel='noreferrer'
          >
            SAPP Academy
          </a>
        </div>

        <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
          {footer.map((footer) => (
            <li className='menu-item' key={footer.title}>
              <a href={footer.link} target='_blank' className='menu-link px-2' rel='noreferrer'>
                {footer.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Footer
