import React from 'react'
import { PageLink } from 'src/constants'

const Documentations = () => {
  return (
    <div className='card mb-2'>
      <div className='card-body p-10 p-lg-15'>
        <h1 className='fw-bold text-dark mb-12 ps-0'>Products Documentations</h1>

        <div className='row'>
          <div className='col-sm-4'>
            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                Free Admin Dashboard
              </a>
            </div>

            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                Bootstrap 5 Admin Template
              </a>
            </div>

            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                Google Admin Dashboard
              </a>
            </div>
          </div>

          <div className='col-sm-4'>
            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                Free Vector Laravel Starter Kit
              </a>
            </div>

            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                React Admin Dashboard
              </a>
            </div>

            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                HTML Admin Dashboard
              </a>
            </div>
          </div>

          <div className='col-sm-4'>
            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                Best VueJS Admin Template
              </a>
            </div>

            <div className='d-flex align-items-center mb-6'>
              <i className='ki-outline ki-right fs-2 ms-n1 me-4'></i>

              <a href={PageLink.DASHBOARD} className='fw-semibold text-gray-800 text-hover-primary fs-3 m-0'>
                Forest Front-end Template
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Documentations