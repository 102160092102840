import {Skeleton} from 'antd'
import React from 'react'

const LoadingCreateNews = () => {
  return (
    <div className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                <Skeleton.Input size='default' active />
              </h2>
            </div>
          </div>

          <div className='card-body text-center pt-0'>
            <Skeleton.Image active className='w-200px' />
          </div>
        </div>

        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                <Skeleton.Input size='default' active />
              </h2>
            </div>

            <div className='card-toolbar'>
              <Skeleton.Avatar size='default' active />
            </div>
          </div>

          <div className='card-body pt-0'>
            <Skeleton.Input active size='large' className='w-100' />

            <div className='d-none mt-10'>
              <label className='form-label'>
                <Skeleton.Input size='default' active />
              </label>
              <input
                className='form-control'
                id='kt_ecommerce_add_product_status_datepicker'
                placeholder='Pick date & time'
              />
            </div>
          </div>
        </div>

        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                <Skeleton.Input size='default' active />
              </h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <Skeleton.Input active size='default' />

            <Skeleton.Button active size='default' className='mx-1' />
          </div>
        </div>

        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                <Skeleton.Input active size='default' />
              </h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <span className='text-muted'>
              <Skeleton.Input active size='large' className='w-100' />
            </span>
          </div>
        </div>

        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                <Skeleton.Input active size='default' />
              </h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <Skeleton.Input active size='large' />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='tab-content'>
          <div className='tab-pane fade show active'>
            <div className='d-flex flex-column gap-7 gap-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>
                      <Skeleton.Input active size='default' />
                    </h2>
                  </div>
                </div>

                <div className='card-body pt-0'>
                  <div className='mb-10 fv-row'>
                    <div className='text-muted fs-7'>
                      <Skeleton.Input size='large' active className='w-100' />
                    </div>
                  </div>
                  <div>
                    <label className='form-label'>
                      <Skeleton.Input size='default' active />
                    </label>
                    <Skeleton.Input size='large' active block />
                  </div>
                </div>
              </div>

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>
                      <Skeleton.Input size='default' active block />
                    </h2>
                  </div>
                </div>

                <div className='card-body pt-0'>
                  <Skeleton.Input size='large' active block />
                </div>
              </div>

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>
                      <Skeleton.Input active size='default' />
                    </h2>
                  </div>
                </div>

                <div className='card-body pt-0'>
                  <div className='mb-10 fv-row'>
                    <div className='text-muted fs-7'>
                      <Skeleton.Input active size='default' block />
                    </div>
                  </div>

                  <div className='fv-row mb-10'>
                    <label className='fs-6 fw-bold mb-3'>
                      <Skeleton.Input size='default' active />
                    </label>

                    <div className='row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9'>
                      <div className='col'>
                        <Skeleton.Input active size='large' />
                      </div>

                      <div className='col'>
                        <Skeleton.Input active size='large' />
                      </div>

                      <div className='col'>
                        <Skeleton.Input active size='large' />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap gap-5'>
                    <div className='fv-row w-100 flex-md-root'>
                      <label className='form-label'>
                        <Skeleton.Input active size='default' />
                      </label>
                      <div>
                      <Skeleton.Input active size='large' block/>
                      </div>
                    </div>

                    <div className='fv-row w-100 flex-md-root'>
                      <label className='form-label'>
                      <Skeleton.Input active size='default' />
                      </label>
                      <div className='text-muted fs-7'>
                      <Skeleton.Input active size='large' block/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className='text-center d-flex justify-content-end'>
            <Skeleton.Button size='large' active className='me-2'/>
            <Skeleton.Button size='large' active/>
        </div>
      </div>
    </div>
  )
}

export default LoadingCreateNews
