import {IMeta, IResponseMeta} from 'src/type'
import {IFile} from 'src/type/question-bank'

export enum QUESTION_LEVELS {
  FUNDAMENTAL = 'FUNDAMENTAL',
  ADVANCED = 'ADVANCED',
}
export enum QUESTION_ASSIGNMENT_TYPE {
  FILE = 'FILE',
  TEXT = 'TEXT',
  ALL = 'ALL',
}
export enum QUESTION_RESPONSE_OPTION {
  WORD = 'WORD',
  SHEET = 'SHEET',
  NONE = 'NONE',
}
export enum QUESTION_TYPES {
  TRUE_FALSE = 'TRUE_FALSE',
  ONE_CHOICE = 'ONE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MATCHING = 'MATCHING',
  SELECT_WORD = 'SELECT_WORD',
  FILL_WORD = 'FILL_WORD',
  DRAG_DROP = 'DRAG_DROP',
  ESSAY = 'ESSAY',
}

export interface IInputQuestionProps {
  level: QUESTION_LEVELS
  course: string
  part: string
  chapter: string
  unit: string
  activity: string
  category: IQuestionTag[]
  display_type: 'VERTICAL' | 'HORIZONTAL'
  question_content: string
  solution: string
  hint: string
  tags: IQuestionTag[]
  quiz_id: string
}

export interface IQuestionAnswer {
  is_correct: boolean
  answer: string
  answer_position: number
}
export interface IQuestion {
  id?: string
  qType?: QUESTION_TYPES
  assignment_type: string
  response_option?: string | null
  level: QUESTION_LEVELS
  display_type: string
  question_content: string
  solution: string
  hint: string
  tags?: IQuestionTag[]
  answers?: IQuestionAnswer[]
  question_matchings?: {
    content: string
    answer: IQuestionAnswer
  }[]
  question_filter?: {
    course_id: string
    part_id: string
    chapter_id?: string | undefined
    unit_id?: string | undefined
    activity_id?: string | undefined
    quiz_id?: string | undefined
  }
  question_category_id?: string[]
  question_topic_id?: string
  requirements?: {
    name: string
    type?: 'TEXT' | 'FILE'
    description: string
    files?: IFile[]
  }[]
  exhibits?: {
    name: string
    type?: 'TEXT' | 'FILE'
    description: string
    files?: IFile[]
  }[]
  files?: IFile[]
  question_topic?: {
    id: string
    name: string
    description: string
  }
  question_category?: {
    id: string
    created_at: string
    updated_at: string
    deleted_at: string
    name: 'EQ' | 'IQ'
    description: string
  }[]
  setting_grade?: string | number
}

export enum ANSWER_CORRECT_TYPE {
  T = 'T',
  F = 'F',
}

export interface IQuestionTag {
  id?: string
  name?: string
  description?: string
}
export interface IQuestionCategory {
  id?: string
  name?: string
  description?: string
}

export interface IResponseQuestionsData<T> {
  meta: IMeta
  questions: T
}
export interface IResponseQuestionMeta<T> extends IResponseMeta {
  data: IResponseQuestionsData<T>
}
