export const STAFF_PROFILE = '/staff/profile'
export const STUDENT_PROFILE = '/student/profile'
export const TEACHER_PROFILE = '/teacher/profile'

export const MY_PROFILE = {
  OVERVIEW: 'overview',
  SETTING: 'setting',
  SECURITY: 'security',
  ACTIVITY: 'activity',
  BILLING: 'billing',
  STATEMENTS: 'statements',
  CERTIFICATE: 'certificate',
  API_KEY: 'api-keys',
  LOGS: 'logs',
  COURSE: 'course',
  CLASS: 'class',
  REFFERRALS: 'refferrals',
}

export const PageLink = {
  COURSES: '/courses',
  COURSE_CATEGORY: '/course/category',
  TEST: '/course/test',
  TEST_EDIT: '/course/edit-test',
  CASE_STUDY: '/course/case-study',
  COURSE_INFO_CREATE: '/courses-info/create',
  COURSE_INFO_UPDATE: '/courses-info/update',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  PROFILE_OVERVIEW: '/profile/overview',
  PROFILE_SETTING: '/profile/setting',
  PROFILE_SECURITY: '/profile/security',
  PROFILE_ACTIVITY: '/profile/activity',
  PROFILE_BILLING: '/profile/billing',
  PROFILE_STATEMENTS: '/profile/statements',
  PROFILE_REFERRALS: '/profile/refferrals',
  PROFILE_API_KEYS: '/profile/api-keys',
  PROFILE_LOGS: '/profile/logs',

  ENTRANCE_TEST_LIST:'/entrance-test/list-entrance-test',
  ENTRANCE_CREATE_TEST_LIST: '/entrance-test/list-entrance-test/create',
  ENTRANCE_EDIT_TEST_LIST: '/entrance-test/list-entrance-test/edit',
  ENTRANCE_CREATE_TEST_LIST_TOPIC: '/entrance-test/list-entrance-test/create/add-topic',
  PARTICIPANTS_LIST:'/entrance-test/participants-list/',
  ENTRANCE_TEST_RESULT:'/entrance-test/list-entrance-test/result',
  ENTRANCE_TEST_RESULT_SCORE_DETAIL:'/entrance-test/participants-list/result/score-detail',
  ENTRANCE_QUIZ_RESULT_TABLE:'/entrance-test/list-entrance-test/quiz-result/table-quiz',
  ENTRANCE_QUIZ_EXPLANATION:'/entrance-test/test-result',
  
  SUPPORT_CENTER: '/support-center',
  SUPPORT_OVERVIEW: '/support-center/overview',
  SUPPORT_TICKETS: '/support-center/tickets',
  SUPPORT_FAQS: '/support-center/faqs',
  STAFFS: '/staffs',
  CREATE_STAFF: '/staffs/create',
  UPDATE_STAFF: '/staffs/update',
  STUDENTS: '/students',
  CREATE_STUDENTS: '/students/create',
  UPDATE_STUDENT: '/students/update',
  ROLES: '/roles',
  ADD_ROLES: '/roles/create',
  UPDATE_ROLES: '/roles/update',
  ROLE_DETAIL: '/roles/detail',
  PERMISSIONS: '/permissions',
  NEWS: '/news',
  CREATE_NEWS: '/news/create',
  SEND_NOTIFICATIONS: '/send-notifications',
  NOTIFICATIONS: '/notifications',
  AUTH_LOGIN: '/auth/login',
  AUTH_FORGOTPASSWORD: '/auth/forgot-password',
  ENTER_OTP: '/auth/enter-otp',
  AUTH_CHAGEPASSWORD: '/auth/change-password',
  RESOURCE_BANK: '/resource-bank',
  DICTIONARY: '/dictionary',
  TOPICS: '/topics',
  TOPIC: '/topic',
  SETTING: '/setting',
  TEACHERS: '/teachers',
  CREATE_TEACHERS: '/teachers/create',
  UPDATE_TEACHERS: '/teachers/update',
  AUTH_CHANGE_PASSWORD_SUCCESS: '/auth/password-success',
  IMPORT_LOG: '/import-history',
  COURSE_FULL: '/course-full',
  STORY: '/case-study',
  CREATE_STORY: '/case-study/create',
  STORY_DETAIL: '/case-study/detail',
  FOLDER_LIST: '/folder',
  CLASSES: '/classes',
  CREATE_CLASSES: '/classes/create',
  CLASS_DETAIL: '/classes/detail',
  PREVIEW_PART: '/preview-part',
  PREVIEW_UNIT: '/preview-unit',
  RESOURCE_MANAGER: '/resource-manager',
  CERTIFICATES: '/certificates',
  CREATE_TEMPLATE: '/create-template',
  LIST_GROUP: '/list-group',
  CREATE_GROUP: '/create-group',
  UPDATE_GROUP: '/update-group',
  UPDATE_CLASS: '/classes/update'
}

export const AppConfig = {
  imageFileTypeWhiteList: ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'],
}

export const ACCEPT_UPLOAD_MIME_TYPE = 'image/jpg, image/jpeg, image/gif, image/png'

export const DEFAULT_MAX_FILE_SIZE = 20

export const FORMAT_DATE_COUNT_DOWN = 'MMMM dd yyyy HH:mm:ss z'

export const DENIED_PERMISSIONS = 'Tạm thời bạn không có quyền thực hiện thao tác này'
export const DENIED_MESSAGES ='Tạm thời bạn không có quyền xem phần này'
export const VALIDATION_FILED = 'This field is required'
export const VALIDATION_MIN = (min: number) => `Only numbers > ${min} are allowed.`
export const VALIDATION_MIN_EQUAL = (min: number) => `Only numbers >= ${min} are allowed.`
export const VALIDATE_TIME_MINUTE = 'Only numbers (0 - 59) are allowed'
export const VALIDATE_TIME = 'At least one field should be > 1'
export const VALIDATE_FILED_MAX = 'Tab name should be less than 1000 charater'
export const VALIDATE_GROUP_NAME = 'Group name should be less than 1000 charater'
export const VALIDATE_FILED_MAX_LENGTH = (field: string, max: number) =>
  `${field} should be less than ${max} character`

export const TITLE_SIDEBAR = {
  DASHBOARD: 'Dashboard',
  NEWS: 'Tin tức',
  LIST_NEWS: 'Danh sách',
  CREATE_NEWS: 'Tạo tin tức',
  COURSES: 'Courses',
  COURSE_CATEGORY: 'Course category',
  RESOURCE_BANK: 'Kho học liệu',
  QUESTION_BANK: 'Question banks',
  DICTIONARY: 'Dictionary',
  SUPPORT_CENTER: 'Trung tâm hỗ trợ',
  OVERVIEW: 'Báo cáo',
  TICKETS: 'Tickets',
  FAQs: 'FAQs',
  NOTIFICATIONS: 'Notifications',
  SEND_NOTIFICATIONS: 'Gửi thông báo',
  LIST_NOTIFICATIONS: 'Notification List',
  STAFFS: 'Staffs',
  LIST_STAFFS: 'List Staffs',
  CREATE_STAFFS: 'Create Staffs',
  STUDENTS: 'Students',
  LIST_STUDENTS: 'Danh sách',
  CREATE_STUDENTS: 'Create students',
  TEACHERS: 'Teachers',
  LIST_TEACHERS: 'Danh sách',
  CREATE_TEACHERS: 'Create Teachers',
}

export const FILTER_SORTBY = [
  // {
  //   label: 'All',
  //   value: '',
  // },
  {
    label: 'A-Z',
    value: 'A_Z',
  },
  {
    label: 'Z-A',
    value: 'Z_A',
  },
  {
    label: 'Latest',
    value: 'LATEST',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
  },
]

export const FILTER_SELECTALL_SORTBY = [
  // {
  //   label: 'All',
  //   value: 'all',
  // },
  {
    label: 'A-Z',
    value: 'A_Z',
  },
  {
    label: 'Z-A',
    value: 'Z_A',
  },
  {
    label: 'Latest',
    value: 'LATEST',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
  },
]

export const STATUS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Block',
    value: 'BLOCKED',
  },
  // {
  //   label: 'Inactive',
  //   value: 'INACTIVE',
  // },
]
export const STATUS_TEST = [
  {
    label: 'Finish',
    value: 'FINISH',
  },
  {
    label: 'UnFinish',
    value: 'UNFINISH',
  },
]

export const STATUS_FORM = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Block',
    value: 'BLOCKED',
  },
  // {
  //   label: 'Inactive',
  //   value: 'INACTIVE',
  // },
]

export const FILTER_CLASS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'ACCA',
    value: 'ACCA',
  },
  {
    label: 'CFA',
    value: 'CFA',
  },
]
export const FILTER_EXAM = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'ACCA',
    value: 'ACCA',
  },
  {
    label: 'CFA',
    value: 'CFA',
  },
  {
    label: 'CMA',
    value: 'CMA',
  },
]

export const GENDER_FORM = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
]

export const GENDER = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
]
export const USERTYPE = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Giáo viên',
    value: 'TEACHER',
  },
  {
    label: 'Học viên',
    value: 'STUDENT',
  },
]

export const MESSAGE_VALIDATION_USERNAME =
  'Input at least 6 characters without space between words and special characters (except 3 characters . - _)'

export const MESSAGE_CODE_INCORRECT = 'The code is incorrect!'

export const FORMAT_DATETIME = 'dd/MM/yyyy HH:mm'
export const VALIDATE_PHONE = 'Phone numbers include 10 or 11 digits and starting with 0'
export const VALIDATE_PASSWORD =
  'Password must contain at least 8 characters, 1 uppercase character, 1 number'

export const GUIDELINE_USERNAME = [
  'Tối thiểu 6 ký tự, tối đa 40 ký tự',
  'Không chứa khoảng trắng, ký tự tiếng Việt có dấu và ký tự đặc biệt (ngoại trừ 3 ký tự . - _)',
  `Không trùng username đã tồn tại`,
]

export const GUIDELINE_EMAIL = [
  'Nhập đúng định dạng email abc@abc.abc',
  'Không trùng email đã tồn tại',
]

export const GUIDELINE_PHONE = [
  'Chỉ nhập ký tự số',
  'Bắt đầu là ký tự 0',
  'Chỉ gồm 10 hoặc 11 ký tự',
  'Không trùng SĐT đã tồn tại',
]

export const GUIDELINE_PASSWORD = ['Tối thiểu 8 ký tự, ít nhất 1 ký tự hoa, 1 ký tự số']
export const GUIDELINE_FULLNAME = [
  'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, tối đa 100 ký tự',
]
export const GUIDELINE_OPTIONAL = [
  'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự',
]
export const GUIDELINE_ADDRESS = [
  'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, tối đa 255 ký tự',
]
export const GUIDELINE_NOT_MAXIMUM = ['Cho phép nhập tự do, không giới hạn ký tự']

export const GUIDELINE_IMAGE = ['Cho phép upload ảnh png, jpg, jpeg', 'Dung lượng tối đa 20mb']

export const pageSizeOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

export const DEFAULT_ALL = [
  {
    label: 'All',
    value: '',
  },
]

export const DEFAULT_SELECT_ALL = [
  {
    label: 'All',
    value: 'all',
  },
]

export const BREAKPOINT = {
  xs: 300,
  md: 768,
  lg: 960,
  xl: 1140,
}

export const COURSE_TYPES = [
  {
    label: 'Foundation Course',
    value: 'FOUNDATION_COURSE',
  },
  {
    label: 'Trial Course',
    value: 'TRIAL_COURSE',
  },
  {
    label: 'Practice Course',
    value: 'PRACTICE_COURSE',
  },
  {
    label: 'Normal Course',
    value: 'NORMAL_COURSE',
  },
]
export const COURSE_TYPES_CLASS = [
  {
    label: 'Trial Course',
    value: 'TRIAL_COURSE',
  },
  {
    label: 'Practice Course',
    value: 'PRACTICE_COURSE',
  },
  {
    label: 'Normal Course',
    value: 'NORMAL_COURSE',
  },
]
export const COURSE_STATUS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Publish',
    value: 'PUBLISH',
  },
  {
    label: 'Lock',
    value: 'LOCK',
  },
  {
    label: 'Block',
    value: 'BLOCK',
  },
]
export const TYPE_COURSE = {
  SECTION: 'SECTION',
  SUBSECTION: 'SUBSECTION',
  UNIT: 'UNIT',
  ACTIVITY: 'ACTIVITY',
  PART: 'PART',
  CHAPTER: 'CHAPTER',
  PARTIALCHAPTER: 'PARTIALCHAPTER',
  PARTIALACTIVITY: 'PARTIALACTIVITY',
  LEARNINGOUTCOME: 'LEARNINGOUTCOME',
  FINAL_TEST: 'FINAL_TEST',
  MID_TERM_TEST: 'MID_TERM_TEST',
  PART_TEST: 'PART_TEST',
  CASE_STUDY: 'CASE_STUDY_STORY',
  STORY: 'STORY',
}
export const TYPE_DOCUMENT = {
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
  QUIZ: 'QUIZ',
  PAST_EXAM_ANALYSIS: 'PAST_EXAM_ANALYSIS',
}

export const VALIDATE_MIN_FULLNAME = 'Fullname must contain at least 3 character(s)'
export const VALIDATE_MAX_FULLNAME = 'Fullname must contain at most 100 character(s)'
export const VALIDATE_MIN_USERNAME = 'Username must contain at least 6 character(s)'

export const pageSizeOptionsDetail = [
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

export const COURSE_STATUS_FORM = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Publish',
    value: 'PUBLISH',
  },
  {
    label: 'Lock',
    value: 'LOCK',
  },
  {
    label: 'Block',
    value: 'BLOCK',
  },
]

export const VALIDATE_CODE_MIN = 'Code must contain at least 1 character(s)'
export const VALIDATE_CODE_MAX = 'Code must contain at most 255 character(s)'
export const VALIDATE_NAME_MIN = 'Name must contain at least 1 character(s)'
export const VALIDATE_NAME_MAX = 'Name must contain at most 1000 character(s)'
export const VALIDATE_CATEGORY_MIN = 'Category must contain at least 1 character(s)'
export const VALIDATE_NUMBER = 'Only whole numbers allowed'
export const VALIDATE_SETTING = 'Only numbers (0 - 100) are allowed'
export const VALIDATE_DURATION = 'Only numbers (1 - 999) are allowed'
export const VALIDATE_DURATION_9999 = 'Only numbers not less than 1 are allowed'
export const VALIDATE_DIFFICULTY = 'Only numbers (1 - 50) are allowed'
export const VALIDATE_QUESTIONS = 'Only numbers >= 1 are allowed.'
export const VALIDATE_PASS_POINT = 'Only numbers (1 - 100) are allowed'
export const VALIDATE_PASS_NOTE = 'Note must contain at least 1 character(s)'
export const VALIDATE_SUBJECT = 'Only numbers (1 - 59) are allowed'
export const VALIDATE_MINUTES = 'Only numbers >=0 are allowed'
export const VALIDATE_TYPE = 'Type must contain at least 1 character(s)'
export const GUIDELINE_NAME = [VALIDATE_NAME_MIN, VALIDATE_NAME_MAX]
export const GUIDELINE_CODE = [VALIDATE_CODE_MIN, VALIDATE_CODE_MAX]
export const GUIDELINE_DURATION = [VALIDATE_NUMBER]
export const GUIDELINE_LEVEL = [VALIDATE_NUMBER, VALIDATE_DIFFICULTY]
export const GUIDELINE_SETTING = [VALIDATE_NUMBER, VALIDATE_SETTING]
export const GUIDELINE_PASSPOINT = [VALIDATE_NUMBER, VALIDATE_PASS_POINT]
export const VALIDATE_FOLDER_NAME = `The file name cant contain any of the following characters: \\:*?"<>|`
export const VALIDATE_MAX_NUMBER = 9999
export const TIME_OF_DAY= 24 * 60 * 60 * 1000;

export const MOCKUP_HEADER = [
  'Order',
  'Name',
  'Category',
  'Type',
  'Create At',
  'Update at',
  'Action',
  'Code',
  'Exam',
]
export const HEADER_CLASS_LIST = [
  'Order',
  'Class',
  'Foundation',
  'Program',
  'Schedule',
  'Opening',
  'Status',
  'Note',
]
export const HEADER_ENTRANCE_TEST_LIST = [
  'Order',
  'Test name',
  'Program',
  'Subject',
  'Create At',
  'Submissions count',
]
export const HEADER_QUESTION_LIST = [
  'Order',
  'List question  ',
  'Type',
  'Action',
]

export const VALIDATE_MIN_NAME = 'Name must contain at least 1 character(s)'
export const VALIDATE_MAX_NAME = 'Name must contain at most 100 character(s)'
export const VALIDATE_MAX_DESCRIPTION = 'Description must contain at most 2000 character(s)'

export const DURATION_EMAIL = 1800
export const OPTIONS_OF_NOTITYPE = [
  {
    label: 'All', 
    value: 'all'
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Notifications',
    value: 'notifications',
  },
]

export const SELECT_TYPE = [
  {
    label: 'All', 
    value: 'ALL'
  },
  {
    label: 'Selected',
    value: 'SELECTED',
  },
  {
    label: 'Not Selected',
    value: 'NOT_SELECTED',
  },
]

export const IS_STAFF = [
  {
    label: 'No',
    value: 'false'
  },
  {
    label: 'Yes',
    value: 'true'
  },
]

