import {Dispatch, SetStateAction, useEffect} from 'react'
import LoadingTable from 'src/common/LoadingTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import {MOCKUP_HEADER} from 'src/constants'
import {IStoryList} from 'src/type/story'
import {KTCardBody} from 'src/_metronic/helpers'
import {StoryActionsCell} from './columns/StoryActionsCell'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SappTable from 'src/components/base/SappTable'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import {PageLink} from 'src/constants'
import SappTooltip from 'src/common/SappTooltip'

const headers = [
  {
    label: 'Case Study Name',
    key: 'case_study_name',
    className: 'min-w-250px',
  },
  {
    label: 'Date',
    key: 'update_at',
    className: 'min-w-200px',
  },
  {
    label: 'Action',
    key: 'action',
    className: 'w-50px',
  },
]

interface IProps {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  storyList: IStoryList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchStoryList: (currentPage: number, pageSize: number, queryString?: string) => void
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  queryString?: string
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
}

const StoryTable = ({
  setLoading,
  loading,
  fetchStoryList,
  storyList,
  currentPage,
  setCurrentPage,
  handleChangeParams,
  pageSize,
  setPageSize,
  queryString,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  useEffect(() => {
    setLoading(true)
    fetchStoryList(currentPage, pageSize, queryString)
  }, [currentPage, pageSize])
  return (
    <KTCardBody>
      <div className='table-responsive'>
        <SappTable
          headers={headers}
          loading={loading}
          data={storyList?.stories}
          isCheckedAll={isCheckedAll}
          onChange={() => {
            toggleCheckAll(!isCheckedAll, true)
          }}
          // className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          {loading ? (
            <>
              {MOCKUP_HEADER.map((_header, i) => (
                <LoadingTable key={i} headers={MOCKUP_HEADER} />
              ))}
            </>
          ) : (
            <>
              {storyList?.stories?.map((stories, index) => {
                const isChecked = checkedList && checkedList.includes(stories.id)
                return (
                  <tr key={stories.id ?? index}>
                    <td>
                      <SAPPCheckbox
                        checked={isChecked}
                        ktCheck={isChecked}
                        onChange={() => {
                          toggleCheck(stories.id)
                        }}
                      />
                    </td>
                    <td>
                      <div className='show-text-editor'>
                        <SappTooltip title={stories.name} link={`${PageLink.STORY}/${stories.id}`} max_length={140} />
                      </div>
                    </td>
                    <td>
                      <DatetimeColumn
                        created_at={stories?.created_at}
                        updated_at={stories?.updated_at}
                      />
                    </td>
                    <td className='text-end'>
                      <StoryActionsCell
                        id={stories?.id}
                        fetchStoryList={fetchStoryList}
                        queryString={queryString}
                        setCurrentPage={currentPage}
                      />
                    </td>
                  </tr>
                )
              })}
            </>
          )}
        </SappTable>
      </div>

      {/* {!isEmpty(rows) && ( */}
      <PagiantionSAPP
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={storyList?.meta?.total_records}
        handleChangeParams={handleChangeParams}
      />
      {/* )} */}
    </KTCardBody>
  )
}

export {StoryTable}
