import React, {useState} from 'react'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'
import Tabs from 'src/components/tabs/Tabs'
import sketchy from 'src/_metronic/assets/media/illustrations/sketchy-1/20.png'
import CreateTickets from './support-overview/CreateTickets'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'

const tabs: ITabs[] = [
  {
    link: `${PageLink.SUPPORT_OVERVIEW}`,
    title: 'BÁO CÁO',
  },
  {
    link: `${PageLink.SUPPORT_TICKETS}`,
    title: 'TICKETS',
  },
  {
    link: `${PageLink.SUPPORT_FAQS}`,
    title: 'FAQs',
  },
]

const Card = () => {
  const [openTicket, setOpenTicket] = useState(false)

  return (
    <div className='card mb-12'>
      <div className='card-body flex-column p-5'>
        <div className='d-flex align-items-center h-lg-300px p-5 p-lg-15'>
          <div className='d-flex flex-column align-items-start justift-content-center flex-equal me-5'>
            <h1 className='fw-bold fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-10'>
              How Can We Help You?
            </h1>

            <div className='position-relative w-100'>
              <i className='ki-outline ki-magnifier fs-2 text-primary position-absolute top-50 translate-middle ms-8'></i>
              <input
                type='text'
                className='form-control fs-4 py-4 ps-14 text-gray-700 placeholder-gray-400 mw-500px'
                name='search'
                placeholder='Ask a question'
              />
            </div>
          </div>

          <div className='flex-equal d-flex justify-content-center align-items-end ms-5'>
            <img src={sketchy} alt='' className='mw-100 mh-125px mh-lg-275px mb-lg-n12' />
          </div>
        </div>

        <div className='card-rounded bg-light d-flex flex-stack flex-wrap p-5'>
          {/* start:: tabs */}
          <Tabs tabs={tabs} />
          {/* end:: tabs */}

          {/* start:: button create ticket */}
          <ButtonPrimary
            title='Create Ticket'
            className='fw-bold fs-8 fs-lg-base'
            onClick={() => setOpenTicket(true)}
          />
          {/* end:: button create ticket */}
        </div>
        {/* start:: modal create ticket */}
        <CreateTickets openTicket={openTicket} setOpenTicket={setOpenTicket} />
        {/* end:: modal create ticket */}
      </div>
    </div>
  )
}

export default Card
