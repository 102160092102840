import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ITabs } from 'src/type';

const SappTabs = ({ tabs }: { tabs: ITabs[] }) => {
    const location = useLocation()
    return (
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {
                tabs.map((tab) => (
                    <li className="nav-item mt-2" key={tab.title}>
                        <Link className={`${location.pathname === tab.link ? 'active sapp-text-primary' : ''} nav-link text-gray-500 fs-4 lh-1 ms-0 me-10 py-5`} to={tab.link}>{tab.title}</Link>
                    </li>
                ))
            }
        </ul>
    )
}

export default SappTabs
