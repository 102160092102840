import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import {VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED} from 'src/constants'
import {
  CLASS_PROFILE,
  TITLE_OPTIONS_CLASS,
  OPTIONS_OF_STATUS,
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_TYPE_CLASS,
  OPTIONS_OF_DURATION,
  categoryCourseType
} from 'src/constants/classes'
import { z } from 'zod'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import { toast } from 'react-hot-toast'
import {IClass} from 'src/type/classes'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import { useConfirm } from 'src/hooks/use-confirm'
import {ClassesApi} from 'src/apis/classes'
import {getAPIUser} from 'src/apis/user'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import AddExam from 'src/components/classes/add-exam/AddExam'
import {KTIcon} from 'src/_metronic/helpers'
import AddCourseToClass from 'src/components/classes/add-course/AddCourseToClass'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {Skeleton} from 'antd'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import dayjs from 'dayjs'
import SappDrawer from 'src/components/base/SappDrawer'
import {debounce} from 'lodash'
import {format} from 'date-fns'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'

const { Option } = Select

interface IProps {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  classDetail: any | undefined
  setClassDetail: Dispatch<SetStateAction<any>>
}

const EditClassProfile = ({ loading, setLoading, classDetail, setClassDetail }: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [areaClasses, setAreaClasses] = useState<any[]>([])
  const [selectedRadio, setSelectedRadio] = useState<string>(classDetail?.duration_type)
  const [lecturerClasses, setLecturerClasses] = useState<any>()
  const [mentorClasses, setMentorClasses] = useState<any>()
  const [checkedListCourse, setCheckedListCourse] = useState<any>([classDetail?.course])
  const [openAddCourse, setOpenAddCourse] = useState<boolean>(false)
  const [typeCourse, setTypeCourse] = useState<string>('')
  const [loadingField, setLoadingField] = useState<boolean>(false)
  const [openAddExam, setOpenAddExam] = useState<{status: boolean; type: string}>({
    status: false,
    type: '',
  })
  const [checkedListExam, setCheckedListExam] = useState<any>([])
  const [openBlock, setOpenBlock] = useState<boolean>(false)
  const [statusBlock, setStatusBlock] = useState<any>(classDetail?.status)
  const [firstLoad, setFirstLoad ] = useState(true)
  const isCheckEnded = classDetail?.status === 'ENDED' || classDetail?.type === 'FOUNDATION' ? true : false
  const isStudentStudying = classDetail?.is_student_studying
  const [typeInstruction, setTypeInstruction] = useState(classDetail?.instruction_mode)

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(`${CLASS_PROFILE}/${id}/overview`),
    })
  }

  const schema = {
    name: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    code: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Code', 1000)),
    status: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    // area_id: z
    //   .string({required_error: VALIDATION_FILED})
    //   .trim()
    //   .min(1, VALIDATION_FILED),
    instruction_mode: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    type: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    capacity: z
      .coerce
      .number()
      .min(1, VALIDATION_FILED),
    lecture_id: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .optional(),
    mentor_id: z
      .string({required_error: VALIDATION_FILED})
      .optional(),
    duration_type: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    ...(statusBlock === 'BLOCKED' && {
      reason: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED)
    }),
    ...(selectedRadio === 'FIXED' && {
      duration_at: z.object({
        fromDate: z.date(),
        toDate: z.date()
      }).optional().refine((data) => data?.fromDate  && data?.toDate , VALIDATION_FILED)
    }),
    ...(selectedRadio === 'FLEXIBLE' && {
      flexible_days: z
        .coerce
        .number()
        .min(1, VALIDATION_FILED),
    }),
  }

  const schemaOnline = z
    .object(
      Object.assign({}, schema, {
        area_id: z.any(),
      })
    )

  const schemaOffline = z
    .object(
      Object.assign({}, schema, {
        area_id: z
        .string({required_error: VALIDATION_FILED})
        .min(1, { message: VALIDATION_FILED })
      })
    )

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm<any>({
    resolver: zodResolver(typeInstruction !== 'ONLINE' ? schemaOffline : schemaOnline),
    mode: 'onSubmit',
  })

  // function scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // submit form
  const onSubmit = async (data: IClass) => {
    try {
      setLoading(true)
      setOpenBlock(false)
      const getIdLecturer = classDetail?.lecture?.detail?.full_name.trim() === data?.lecture_id?.trim() ? classDetail?.lecture?.id : data.lecture_id
      const getIdMentor = classDetail?.mentor?.detail?.full_name.trim() === data?.mentor_id?.trim() ? classDetail?.mentor?.id : data.mentor_id

      const paramsUpdate: IClass = {
        id: id,
        name: data.name,
        code: data.code,
        status: data.status,
        area_id: data.area_id || null,
        lecture_id: getIdLecturer,
        instruction_mode: data.instruction_mode,
        type: data.type,
        capacity: data.capacity,
        mentor_id: getIdMentor,
        duration_type: data.duration_type,
        excepted_course_section: [],
        description: getValues('description'),
        reason: data?.reason,
        course_id: null,
      }

      if (data.duration_type === 'FLEXIBLE' && data.flexible_days) {
        const getThisDay = new Date()
        const startAt = getThisDay.toISOString()
        const openAt = getThisDay.toISOString()
        const finishAt = new Date(getThisDay.setDate(getThisDay.getDate() + data.flexible_days)).toISOString()
        const flexibleDays = {
          flexible_days: data.flexible_days,
          started_at: startAt,
          opening_at: openAt,
          finished_at: finishAt
        }
        Object.assign(paramsUpdate, flexibleDays)
      } else {
        const objectDateFixed = {
          opening_at: getValues('opening_at'),
          started_at: data?.duration_at.fromDate, 
          finished_at: data?.duration_at.toDate,
          flexible_days: 1,
        }
        Object.assign(paramsUpdate, objectDateFixed)
      }

      if (checkedListCourse[0]?.id !== undefined) {
        Object.assign(paramsUpdate, {course_id: checkedListCourse[0]?.id})
      }

      let objectListExam = {}
      if (checkedListExam[0]?.type === 'CFA') {
        objectListExam = {
          cfa_examination_id: `${checkedListExam[0]?.id}`
        }
      } else if (checkedListExam[0]?.type === 'ACCA') {
        objectListExam = {
          acca_examination_id: `${checkedListExam[0]?.id}`
        }
      } else if (checkedListExam[0]?.type === 'CMA') {
        objectListExam = {
          cma_examination_id: `${checkedListExam[0]?.id}`
        }
      } else {
        objectListExam = {
          cfa_examination_id: null,
          acca_examination_id: null,
          cma_examination_id: null
        }
      }
      Object.assign(paramsUpdate, objectListExam)

      await ClassesApi.updateClass({...paramsUpdate})
      toast.success('Update Successfully!')

      const res = await ClassesApi.detail(id)
      setClassDetail(()=>{
        navigate(`/classes/detail/${res?.data?.id}/overview`)
        scrollToTop()
        return res?.data
      })
   } catch (error) {
      console.log(error)
   } finally {
      setLoading(false)
   }
  }

  const handleChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.currentTarget.value)
  }

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusBlock(e)
  }

  const fetchAreaClasses = async ({page_index, page_size}: any) => {
    try {
      const res = await ClassesApi.getAreaClasses({page_index: page_index, page_size: page_size})
      setAreaClasses(res?.data?.areas)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLectureClasses = async ({page_index, page_size, type, params}: any) => {
    try {
      const res = await getAPIUser({page_index: page_index, page_size: page_size, type: type, params: params})
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMentorClasses = async ({page_index, page_size, params}: any) => {
    try {
      const res = await ClassesApi.getAPIStaffs({page_index: page_index, page_size: page_size, params: params})
      return res
    } catch (error) {
      console.log(error)
    }
  }  

  const handlNextPageMentor = async ({params}: any) => {
    const totalPages = mentorClasses?.metadata?.total_pages
    const pageIndex = mentorClasses?.metadata?.page_index
    const pageSize = mentorClasses?.metadata?.page_size
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchMentorClasses({page_index: pageIndex + 1, page_size: pageSize, params: params})
      const results = mentorClasses.staffs.concat(res?.data?.staffs) 
      setMentorClasses({
        metadata: res?.data?.metadata,
        staffs: results,
      })
    }
  }

  const handlNextPageLecture = async ({params}: any) => {
    const totalPages = lecturerClasses?.metadata?.total_pages
    const pageIndex = lecturerClasses?.metadata?.page_index
    const pageSize = lecturerClasses?.metadata?.page_size
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchLectureClasses({page_index: pageIndex + 1, page_size: pageSize, type: 'TEACHER', params: params})
      const results = lecturerClasses.users.concat(res?.data?.users) 
      setLecturerClasses({
        metadata: res?.data?.metadata,
        users: results,
      })
    }
  }

  const getLectureClasses = async ({params}: any) => {
    const resTeacher = await fetchLectureClasses({page_index: 1, page_size: 20, type: 'TEACHER', params: params})
    setLecturerClasses(resTeacher?.data)
  }

  const getMentorClasses = async ({params}: any) => {
    const resMentor = await fetchMentorClasses({page_index: 1, page_size: 20, params: params})
    setMentorClasses(resMentor?.data)
  }

  const debounceSearchLecturer = debounce((e) => {
    getLectureClasses({params: {text: e}})
  }, 500)

  const debounceSearchMentor = debounce((e) => {
    getMentorClasses({params: {text: e}})
  }, 500)

  const handleCloseBlock = () => {
    setOpenBlock(false)
  }
  
  useEffect(() => {
    async function fetchData (){
      try {
        setLoadingField(true)
        await fetchAreaClasses({page_index: 1, page_size: 20})
        getLectureClasses({params: ''})
        getMentorClasses({params: ''})
      } catch (err) {
      } finally {
        setLoadingField(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (id && classDetail !== undefined) {
      setValue('name', classDetail?.name)
      setValue('code', classDetail.code)
      setValue('status', classDetail.status)
      setValue('area_id', classDetail?.area_id || '')
      setValue('lecture_id', classDetail?.lecture?.detail?.full_name)
      setValue('instruction_mode', classDetail?.instruction_mode)
      setValue('type', classDetail?.type)
      setValue('capacity', classDetail?.capacity)
      setValue('course_id', classDetail?.course_id)
      setValue('mentor_id', classDetail?.mentor?.detail?.full_name)
      setValue('mentor_type', classDetail?.mentor_type)
      setValue('duration_type', classDetail?.duration_type)
      setValue('excepted_course_section', [])
      setValue('description', classDetail?.description)
      setValue('reason', classDetail?.reason)
      setValue('opening_at', classDetail?.opening_at)
      setValue('duration_at', {
        fromDate: classDetail?.started_at ? dayjs(classDetail?.started_at)?.toDate() : null,
        toDate: classDetail?.finished_at ? dayjs(classDetail.finished_at)?.toDate() : null
      })
    }
  }, [
    id,
    setValue,
    classDetail,
  ])

  const handleChangeType = () => {
    setCheckedListCourse([])
  }

  useEffect(() => {
    if (firstLoad === false){
      setCheckedListExam([])
    }
  }, [checkedListCourse])

  useEffect(() => {
    setFirstLoad(true)
    setCheckedListExam(()=>{
      setFirstLoad(false)
      return [classDetail?.acca_examination || classDetail?.cfa_examination || classDetail?.cma_examination]})
  }, [])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Edit Class Information</h3>
          </div>
        </div>

        <div className='collapse show'>
          <div className='form'>
            <div className='card-body card-body px-10 pt-8 pb-4'>
              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.name}
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    required
                    control={control}
                    name='name'
                    placeholder=' '
                    className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    defaultValue={classDetail?.name}
                    disabled={isCheckEnded}
                  ></HookFormTextField>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.code}
                </label>
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    required
                    control={control}
                    name='code'
                    placeholder=' '
                    className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    defaultValue={classDetail.code}
                    disabled={isCheckEnded}
                  ></HookFormTextField>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.status}
                </label>
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    required
                    size='large'
                    name='status'
                    control={control}
                    placeholder=' '
                    className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    filterOption={true}
                    defaultValue={classDetail.status}
                    onSelect={handleChangeStatus}
                    disabled={isCheckEnded || isStudentStudying}
                  >
                    {OPTIONS_OF_STATUS?.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.constructionMode}
                </label>
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    required
                    size='large'
                    name='instruction_mode'
                    control={control}
                    placeholder=' '
                    className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    filterOption={true}
                    defaultValue={classDetail?.instruction_mode}
                    disabled={isCheckEnded || isStudentStudying}
                    onChange={(e: unknown) => setTypeInstruction(e as unknown)}
                  >
                    {OPTIONS_OF_CONTRUCTION_MODE?.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.area}
                </label>
                <div className='col-lg-9 fv-row'>
                  {loadingField ?
                    <Skeleton.Input size='large' active />
                  :
                    <HookFormSelectAntd
                      required
                      size='large'
                      name='area_id'
                      control={control}
                      placeholder=' '
                      className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                      filterOption={true}
                      defaultValue={classDetail?.area_id}
                      disabled={isCheckEnded}
                      allowClear
                    >
                      {areaClasses?.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                          {item.name}
                          </Option>
                        )
                      })}
                    </HookFormSelectAntd>
                  }
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.type}
                </label>
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    required
                    size='large'
                    name='type'
                    control={control}
                    placeholder=' '
                    className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    filterOption={true}
                    defaultValue={classDetail?.type}
                    onChange={() => {
                      handleChangeType()
                    }}
                    disabled={isCheckEnded || isStudentStudying}
                  >
                    {OPTIONS_OF_TYPE_CLASS?.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.maximumStudents}
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    required
                    control={control}
                    name='capacity'
                    placeholder=' '
                    type='number'
                    className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    defaultValue={classDetail?.capacity}
                    disabled={isCheckEnded}
                    minNumber={1}
                    requiredZero={true}
                  ></HookFormTextField>
                </div>
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.lecturer}
                </label>
                <div className='col-lg-9 fv-row'>
                  {loadingField ?
                    <Skeleton.Input size='large' active />
                  :
                    <HookFormSelectAntd
                      required
                      size='large'
                      name='lecture_id'
                      control={control}
                      placeholder=' '
                      className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                      handleNextPage={(e: any) => handlNextPageLecture({params: {text: e}})}
                      defaultValue={classDetail?.lecture?.detail?.full_name}
                      showSearch
                      onSearch={(e: any) => {
                        if (e === undefined) {
                          return
                        }
                        debounceSearchLecturer(e)
                      }}
                      disabled={isCheckEnded}
                    >
                      {lecturerClasses?.users?.map((item: any) => {
                        return (
                          <Option key={item.id} userName={item?.detail?.full_name} email={item?.detail?.email} value={item.id} className={'p-4'}>
                            {item?.detail?.full_name}
                          </Option>
                          )
                      })}
                    </HookFormSelectAntd>
                  }
                </div>
              </div>

              <div className='row mb-11'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.mentor}
                </label>
                <div className='col-lg-9 fv-row'>
                  {loadingField ?
                    <Skeleton.Input size='large' active />
                  :
                    <HookFormSelectAntd
                      required
                      size='large'
                      name='mentor_id'
                      control={control}
                      placeholder=' '
                      className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                      handleNextPage={(e: any) => handlNextPageMentor({params: {text: e}})}
                      defaultValue={classDetail?.mentor?.detail?.full_name}
                      showSearch
                      onSearch={(e: any) => {
                        if (e === undefined) {
                          return
                        }
                        debounceSearchMentor(e)
                      }}
                      disabled={isCheckEnded}
                    >          
                      {mentorClasses?.staffs?.map((item: any) => {
                        return (
                          <Option key={item.id} userName={item?.detail?.full_name} email={item?.detail?.email} value={item.id} className={'p-4'}>
                            {item?.detail?.full_name}
                          </Option>
                          )
                      })}
                    </HookFormSelectAntd>
                  }
                </div>
              </div>

              <div className='row mb-5'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500'>
                  {TITLE_OPTIONS_CLASS.duration}
                </label>
                <div className='col-lg-9 fv-row'>
                  <HookFormRadioGroup
                    direction='horizontal'
                    separator={false}
                    name='duration_type'
                    control={control}
                    justify='start'
                    gap={32}
                    labelClass='fw-semibold fs-6 sapp-table-class-field'
                    onChange={handleChangeDuration}
                    defaultValue={classDetail?.duration_type}
                    options={[
                      {
                        label: `${OPTIONS_OF_DURATION.labelFixed}`,
                        value: `${OPTIONS_OF_DURATION.valueFixed}`,
                      },
                      {
                        label: `${OPTIONS_OF_DURATION.labelFlexible}`,
                        value: `${OPTIONS_OF_DURATION.valueFlexible}`,
                      },
                    ]}
                    disabled={isCheckEnded || isStudentStudying}
                  />
                  <div className='row mt-8'>
                    {selectedRadio === `${OPTIONS_OF_DURATION.valueFixed}` && (
                      <>
                        <div className='col-lg-6 fv-row'>
                          <HookFormDateTime
                            control={control}
                            name='opening_at'
                            placeholder='Opening Date'
                            defaultValue={classDetail?.opening_at}
                            className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                            disabled={isCheckEnded || isStudentStudying}
                          />
                        </div>
                        <div className='col-lg-6 fv-row'>
                          <RangeDateTimePicker
                            control={control}
                            name='duration_at'
                            allowClear={false}
                            className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                            disabled={isCheckEnded || isStudentStudying}
                          />
                        </div>
                      </>
                    )}

                    {selectedRadio === `${OPTIONS_OF_DURATION.valueFlexible}` && (
                      <div className='position-relative col-lg-6'>
                        <HookFormTextField
                          required
                          control={control}
                          name='flexible_days'
                          placeholder=' '
                          type='number'
                          className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                          defaultValue={classDetail?.flexible_days}
                          postFix={<div className='px-2'>days</div>}
                          disabled={isCheckEnded || isStudentStudying}
                          minNumber={1}
                          requiredZero={true}
                        ></HookFormTextField>
                      </div>
                    )}
                  </div>
                </div>         
              </div>

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                  {TITLE_OPTIONS_CLASS.course}
                </label>
                <div className='col-lg-9 fv-row position-relative'>
                  <ButtonIcon
                    title={`${checkedListCourse[0]?.id !== undefined ? ' ' : 'Add Course'}`}
                    className={`sapp-h-48px d-flex justify-content-start align-items-center opacity-100 w-100 ${isCheckEnded || isStudentStudying ? 'disabled-background' : ''} `}
                    customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-font-medium text-gray-500 p-0 position-relative'
                    type='button'
                    onClick={() => {
                      if (getValues('type') !== '') {
                        setOpenAddCourse(true)
                        setTypeCourse(getValues('type'))
                      }
                    }}
                    disabled={isCheckEnded || isStudentStudying}
                  >
                    {checkedListCourse[0]?.id !== undefined ?
                      <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>{checkedListCourse[0]?.name}</div>
                    :
                      <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                    }
                    <KTIcon iconName='notepad-edit' className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${checkedListCourse[0]?.id !== undefined ? 'last' : ''}`} iconType='outline' />
                  </ButtonIcon>
                  {checkedListCourse[0]?.id !== undefined &&
                    <div className="sapp-icon-last"> 
                      <ButtonIconOnly
                        iconName='trash'
                        onClick={() => {
                          !isCheckEnded && !isStudentStudying && handleChangeType()
                        }}
                        iconType='outline'
                        bg={`sapp-custom-bg-transparent ${isCheckEnded || isStudentStudying ? 'pe-none' : ''}`}
                        activeColor='danger'
                        iconGrayColor={'500'}
                      />
                    </div>
                  }
                </div>
              </div>
              {checkedListCourse[0]?.id !== undefined && categoryCourseType.some((color: any) => color.includes(checkedListCourse[0]?.course_categories[0]?.name)) &&
                <div className='row mb-8'>
                  <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500 d-flex align-items-center'>
                    {TITLE_OPTIONS_CLASS.exam}
                  </label>
                  <div className='col-lg-9 fv-row position-relative'>
                    <ButtonIcon
                      title={`${checkedListExam[0]?.id !== undefined ? ' ' : 'Exam'}`}
                      className={`sapp-h-48px d-flex justify-content-start align-items-center opacity-100 w-100 sapp-bg-disable-antd ${isCheckEnded || isStudentStudying ? 'disabled-background' : ''} `}
                      customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-text-primary p-0 position-relative'
                      type='button'
                      onClick={() => {
                        setOpenAddExam({status: true, type: ''})
                      }}
                      disabled={isCheckEnded || isStudentStudying}
                    >
                      {checkedListExam[0]?.id !== undefined ?
                        <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                          {(() => {
                            const date = new Date(checkedListExam[0]?.season || null)
                            return `${format(date, 'dd/MM/yyyy')}`
                          })() ?? '-'}
                        </div>
                      :
                        <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                      }
                      <KTIcon iconName='notepad-edit' className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${checkedListExam[0]?.id !== undefined ? 'last' : ''}`} iconType='outline' />
                    </ButtonIcon>
                    {checkedListExam[0]?.id !== undefined &&
                      <div className="sapp-icon-last"> 
                        <ButtonIconOnly
                          iconName='trash'
                          onClick={() => {
                            if (!isCheckEnded && !isStudentStudying) {
                              setCheckedListExam([])
                            }
                          }}
                          iconType='outline'
                          bg={`sapp-custom-bg-transparent ${isCheckEnded || isStudentStudying ? 'pe-none' : ''}`}
                          activeColor='danger'
                          iconGrayColor={'500'}
                        />
                      </div>
                    }
                  </div>
                </div>
              }

              <div className='row mb-8'>
                <label className='col-lg-3 fs-4 lh-1 fw-bold text-gray-500'>
                  {TITLE_OPTIONS_CLASS.describe}
                </label>
                <div className='col-lg-9 fv-row'>
                  <HookFormEditor
                    height={352}
                    placeholder=' '
                    name='description'
                    control={control}
                    math={true}
                    className='w-100 fs-6 lh-1 sapp-table-class-field'
                    defaultValue={classDetail?.description}
                    disabled={isCheckEnded}
                  />
                </div>
              </div>
            </div>
            <SappDrawer
              open={openBlock}
              title='Block Class'
              width={'50%'}
              handleSubmit={() => {
                handleSubmit((data: any) => {
                  onSubmit(data)
                })()
              }}
              handleClose={handleCloseBlock}
              confirmOnclose
              cancelButtonCaption={'Cancel'}
              okButtonCaption={'Save'}
              footerClassName='border-0'
            >
              <HookFormEditor
                label='Reason'
                placeholder=' '
                required
                control={control}
                name='reason'
                math={true}
                height={500}
                defaultValue={classDetail?.reason}
              />
            </SappDrawer>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary
                title='Discard'
                className='btn-active-light-primary px-8 py-5 fs-4 lh-1 me-5'
                onClick={hanleCancel}
              />
              <ButtonPrimary
                title='Save'
                onClick={() => {
                  if (statusBlock === 'BLOCKED') {
                    setOpenBlock(true)
                  } else {
                    handleSubmit((data: any) => {
                        onSubmit(data)
                    })()
                  }
                }}
                className='px-8 py-5 fs-4 lh-1'
                loading={loading}
                disabled={isCheckEnded}
              />
            </div>
          </div>     
          <AddCourseToClass
            open={openAddCourse}
            setOpen={setOpenAddCourse}
            checkedList={checkedListCourse}
            setCheckedList={setCheckedListCourse}
            typeCourse={typeCourse}
          />
          <AddExam
            open={openAddExam.status}
            setOpen={setOpenAddExam}
            courseId={checkedListCourse[0]?.id}
            course_category_id={checkedListCourse?.[0]?.course_categories?.[0]?.id}
            course_category_type={checkedListCourse?.[0]?.course_categories?.[0]?.name}
            checkedList={checkedListExam}
            setCheckedList={setCheckedListExam}
          />
        </div>
      </div>
    </>
  )
}

export default EditClassProfile
