import React from 'react'
import Attention from './Attention'
import PaymentMethods from './PaymentMethods'
import BillingAddress from './BillingAddress'
import BillingHistory from './BillingHistory'

const BillingProfile = () => {
  return (
    <>
      <Attention />

      <PaymentMethods />

      <BillingAddress />

      <BillingHistory />
    </>
  )
}

export default BillingProfile
