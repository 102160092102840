import {format} from 'date-fns'
import {FC} from 'react'
import {FORMAT_DATETIME} from 'src/constants'

type Props = {
  created_at: Date
  updated_at: Date
}

const DatetimeColumn: FC<Props> = ({created_at, updated_at}) => (
  <>
    <div className='text-nowrap'>
      {created_at ? `Created: ${format(new Date(created_at), 'dd/MM/yyyy')}` : '-'}{' '}
    </div>
    <div className='text-nowrap'>
      {updated_at ? `Updated: ${format(new Date(updated_at), 'dd/MM/yyyy')}` : '-'}{' '}
    </div>
  </>
)

export default DatetimeColumn
