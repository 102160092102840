import React, { useEffect, useState } from 'react'
import PreviewDetail from 'preview-part'
import 'preview-part/dist/index.css'
import { useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import PageLayouts from 'src/components/layout/PageLayouts'
import { TreeHelper } from 'src/helper/tree'

const PreviewCourseDetail = () => {
  const [previewPart, setPreviewPart] = useState<any>()
  const [chapterDetail, setChapterDetail] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [loadingChapter, setLoadingChapter] = useState(false)
  const { id, unitId } = useParams()

  const fetchGetSection = async (id: string | undefined) => {
    setLoading(true)
    await CoursesAPI.getSectionDetail(id).then((res) => {
      const nodeList = res?.data
      const tree = TreeHelper.convertFromArray(nodeList)
      setPreviewPart(tree[0])
    }).finally(() => setLoading(false))
  }

  const fetchChapterDetail = async (id: string | undefined, course_section_id: string | undefined) => {
    setLoadingChapter(true)
    await CoursesAPI.getSectionDetail(course_section_id).then((res) => {
      const nodeList = res?.data
      const tree = TreeHelper.convertFromArray(nodeList)
      const detail = tree[0]
      setChapterDetail(detail)
    }).finally(() => setLoadingChapter(false))
  }

  useEffect(() => {
    fetchGetSection(id)
  }, [])

  return (
    <PageLayouts breadcrumbs={[]} pageTitle='' titleTab='Preview' showSideBar={false} showFooter={false} classNameLayout='sapp-custom-layout'>
      <PreviewDetail
        chapterMenu={previewPart}
        chapterDetail={chapterDetail}
        fetchChapterDetail={fetchChapterDetail}
        loading={loading}
        loadingChapter={loadingChapter}
        setLoadingChapter={setLoadingChapter}
        defaultActive={previewPart?.children?.find((preview: { id: string | undefined} ) => preview.id === unitId)} 
        course_section_id={id ?? ""}
        course_id={unitId ?? ""} 
        handleRouterActivity={() => {}}
        handleLearningOutCome={() => {}} 
        readMore={false} 
        handleRouterCaseStudy={() => {}}
        setReadMore={() => {}}
        handleRouterChapter={() => {}}
      />
    </PageLayouts>
  )
}

export default PreviewCourseDetail