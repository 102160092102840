import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import {NewsListWrapper} from 'src/components/news-list/NewsList'
import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.listNews,
  },
]

const NewsList = () => {
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.listNews} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <NewsListWrapper />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(NewsList)
