import {ReactNode} from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonPrimary from './ButtonPrimary'
import './ButtonDropdown.scss'

interface IButtonDropdown {
  title: string
  onClick?: () => void
  className?: string
  children: ReactNode
  loading?: boolean
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
}

const ButtonDropdown = ({
  title,
  onClick,
  className = '',
  loading = false,
  disabled = false,
  children,
  type = 'button',
}: IButtonDropdown) => {
  return (
    <div>
      <Dropdown as={ButtonGroup} className={`${className} sapp-button-dropdown`} align='end'>
        <ButtonPrimary
          type={type}
          loading={disabled || loading}
          onClick={onClick}
          title={title}
        ></ButtonPrimary>
        <Dropdown.Toggle split variant='primary' disabled={disabled || loading} />
        <Dropdown.Menu className='min-100'>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ButtonDropdown
