import {ReactNode} from 'react'
import {KTIcon} from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'

interface IProps {
  okOnClick: any
  className?: string
  classNameSubmit?: string
  classNameCancel?: string
  cancelButtonCaption: string
  okButtonCaption: string
  cancelClick?: () => void
  loading?: boolean
  linkCancel?: string
  bg?: 'danger' | 'primary'
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  buttonIcon?: boolean
  buttonSubmitIcon?: string
  cancelButton?: ({
    cancelButtonCaption,
    cancelClick,
    loading,
  }: {
    cancelButtonCaption: string
    cancelClick?: () => void
    loading?: boolean
  }) => ReactNode
}

const SAPPDialogButtonsCancelSubmit = ({
  okOnClick,
  className,
  classNameSubmit,
  classNameCancel = 'fw-bold min-w-100px me-5',
  cancelButtonCaption,
  okButtonCaption,
  cancelClick,
  loading,
  linkCancel,
  bg,
  type,
  disabled,
  buttonIcon = false,
  buttonSubmitIcon,
  cancelButton,
}: IProps) => {
  return (
    <div className={`text-center ${className ?? ''}`}>
      {!cancelButton ? (
        <>
          {buttonIcon ? (
            <ButtonIcon
              title={cancelButtonCaption}
              className='btn btn-sapp-icon me-5'
              link={linkCancel}
              onClick={cancelClick}
            >
              <KTIcon iconName='black-left' className='fs-3 pe-1' iconType='outline' />
            </ButtonIcon>
          ) : (
            <ButtonSecondary
              type='button'
              title={cancelButtonCaption}
              onClick={cancelClick}
              link={linkCancel}
              className={`${classNameCancel}`}
            />
          )}
        </>
      ) : (
        cancelButton({
          cancelButtonCaption,
          cancelClick,
          loading,
        })
      )}

      {buttonSubmitIcon ? (
        <ButtonIcon
          type={type}
          disabled={loading || disabled}
          title={okButtonCaption}
          onClick={okOnClick}
          loading={loading}
        >
          <KTIcon iconName={buttonSubmitIcon} className='fs-3 pe-1' iconType='outline' />
        </ButtonIcon>
      ) : (
        <ButtonPrimary
          type={type}
          disabled={loading || disabled}
          title={okButtonCaption}
          onClick={okOnClick}
          loading={loading}
          bg={bg}
          className={`${classNameSubmit ? classNameSubmit : 'fw-bold min-w-100px'}`}
        />
      )}
    </div>
  )
}

export default SAPPDialogButtonsCancelSubmit
