import {ComponentType} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {PageLink} from 'src/constants'
import {getActToken} from 'src/utils'

const withAuthComponents = (Component: ComponentType) => {
  const AuthRoute = () => {
    const {search, pathname} = useLocation()
    window.localStorage.setItem('historyUrl', `${pathname}${search}`)
    const isAuth = !!getActToken()
    if (isAuth) {
      return <Component />
    } else {
      return <Navigate to={PageLink.AUTH_LOGIN} />
    }
  }

  return AuthRoute
}

export default withAuthComponents
