import { format } from 'date-fns'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { FORMAT_DATETIME, MY_PROFILE, STAFF_PROFILE } from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'

interface IProps {
  staffDetail: IStudentDetail | undefined
  loading: boolean
  setStaffDetail: Dispatch<SetStateAction<any>>
}

const StaffProfileDetail = ({ staffDetail, loading, setStaffDetail }: IProps) => {
  const { id } = useParams()
  const { getStaffDetail } = useUserContext()
  const {profileMe} = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.EDIT_STAFF) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  useEffect(() => {
    if (!id || loading) return

    getStaffDetail(id).then((res) => setStaffDetail(res?.data))
  }, [id])

  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Profile Details</h3>
            </div>
            {allowRenderEdit && (
                <Link
                to={`${STAFF_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
                className='btn btn-sm btn-primary align-self-center'
              >
                Edit Profile
              </Link>
            )}
          </div>

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={staffDetail?.key} />
            <ItemProfile title={LANG_PLACEHOLDER.FULLNAME} body={staffDetail?.detail?.full_name} />
            <ItemProfile title='Username' body={staffDetail?.username} />
            <ItemProfile title='Email' body={staffDetail?.detail?.email} />
            <ItemProfile title='Phone Number' body={staffDetail?.detail?.phone} />
            <ItemProfile title='Role' roles={staffDetail?.roles} />
            <ItemProfile title='Status' status={staffDetail?.status} />
            <ItemProfile title='Gender' body={staffDetail?.detail?.sex === 'MALE' ? 'Male' : staffDetail?.detail?.sex === 'FEMALE' ? 'Female' : staffDetail?.detail?.sex === 'OTHERS' ? 'Others' : '-'} />
            <ItemProfile
              title='Updated At'
              body={
                staffDetail?.updated_at
                  ? format(new Date(staffDetail?.updated_at), FORMAT_DATETIME)
                  : ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default StaffProfileDetail
