import {zodResolver} from '@hookform/resolvers/zod'
import {useCallback, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {CoursesAPI} from 'src/apis/courses'
import SappModal from 'src/components/base/SappModal'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormSelect from 'src/components/base/select/HookFormSelect'
import SAPPSelectLoadMore from 'src/components/base/select/SAPPSelectLoadmore'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import {TYPE_DOCUMENT, VALIDATE_NUMBER, VALIDATION_FILED} from 'src/constants'
import {useCourseContext} from 'src/context/CourseProvider'
import {htmlToRaw} from 'src/utils'
import {z} from 'zod'
import SelectedLearningOutCome from '../selected-learning-outcome-item'
import {changeNodeAtPath} from '../tree-course'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
interface IProps {
  open: boolean
  setOpen: any
  data?: any
  id?: string
  lo?: any
  selfId?: string
  treeData?: any
  setData?: any
  path?: any
  position: number
  onlyEditName?: boolean
}
const CreateActivity = ({
  open,
  setOpen,
  data,
  id,
  lo,
  selfId,
  treeData,
  setData,
  path,
  position,
  onlyEditName,
}: IProps) => {
  // const [uploadFiles, setUploadFiles] = useState<any[]>([])
  // const [loading, setLoading] = useState(false)
  const defaultValues = {
    name: '',
    time: {
      hours: '',
      mins: '',
    },
    code: '',
    is_graded: 'true',
    display_icon: 'TEXT',
    files: [],
  }
  const validationSchema = z.object({
    name: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
    code: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
    display_icon: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
    is_graded: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
    time: z.object({
      hours: z
        .string({required_error: VALIDATION_FILED})
        .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
        .refine(
          (val) => {
            if (
              (!Number(val) && !Number(getValues('time.mins'))) ||
              (Number(val) === 0 && Number(getValues('time.mins')) === 0)
            ) {
              return false
            }
            return true
          },
          {message: VALIDATION_FILED}
        )
        .optional(),
      mins: z
        .string({required_error: VALIDATION_FILED})
        .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
        .refine((val) => {
          if (
            (!Number(val) && !Number(getValues('time.hours'))) ||
            (Number(val) === 0 && Number(getValues('time.hours')) === 0)
          ) {
            return false
          }
          return true
        }, VALIDATION_FILED)
        .optional(),
    }),
    files: z.array(z.any().optional()).default([]),
  })
  // .superRefine((arg: any, ctx) => {
  //   if (arg.files && arg.files?.length <= 0) {
  //     ctx.addIssue({
  //       message: VALIDATION_FILED,
  //       code: 'custom',
  //       path: ['files'],
  //     })
  //   }
  // })

  
  useEffect(() => {
    async function fetchlistCourseOutComes() {
      try {
        const res = await CoursesAPI.getCourseOutcomes(lo?.id)
        setOutComeList(res.data.course_outcomes)
      } catch (err) {
        console.log(err)
      }
    }
    if (lo?.id) {
      fetchlistCourseOutComes()
    }
  }, [lo])

  const useFormProp = useForm<{
    name: string
    time: {
      hours: string
      mins: string
    }
    code: string
    is_graded: string
    display_icon: string
    files: any[]
  }>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    trigger,
    setError,
    watch,
    formState: {errors, isSubmitted},
  } = useFormProp
  const filesFormAddNew = watch('files')

  const [activityDetail, setActivityDetail] = useState<any>()
  const {getCourseDetail, idCourse} = useCourseContext()
  const [selectedLearningOutCome, setSelectedLearningOutcome] = useState<any>([])
  const [outcomesList, setOutComeList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    reset()
    setOpen({status: false})
    setSelectedLearningOutcome([])
    setOutComeList([])
    // setUploadFiles([])
  }
  // const checkFileUpload = useMemo(() => {
  //   if (uploadFiles.length <= 0) {
  //     return false
  //   }
  //   return true
  // }, [uploadFiles])

  useEffect(() => {
    async function getOldAcitivity() {
      if (selfId) {
        try {
          const res = await CoursesAPI.getActivityDetail(selfId)
          const oldData = res.data
          Object.entries(oldData || {}).forEach(([key, value]: [any, any]) => {
            setValue(key, value)
          })
          setValue('is_graded', oldData.is_graded.toString())
          setValue('time.hours', Math.floor(oldData.duration / 60).toString())
          setValue('time.mins', Math.floor(oldData.duration % 60).toString())

          const course_outcome_ids = []
          for (let e of oldData.course_outcomes) {
            course_outcome_ids.push(e.id)
          }
          setSelectedLearningOutcome(course_outcome_ids)
          const files = []
          for (let e of oldData.files) {
            files.push(e.resource)
          }
          setValue('files', files)
          setActivityDetail(oldData)
        } catch (err) {
          console.log(err)
        }
      }
    }
    getOldAcitivity()
  }, [selfId])

  const onSubmit = async (dataValue: any) => {
    setLoading(true)
    const resource_ids = []
    if (filesFormAddNew) {
      for (let e of filesFormAddNew) {
        resource_ids.push({resource_id: e.resource_id || e.id, type: 'attached'})
      }
    }

    const payload = {
      // ...dataValue,
      name: dataValue.name?.trim(),
      parent_id: !selfId ? id : dataValue.parent_id,
      is_graded: dataValue.is_graded === 'true' ? true : false,
      duration: Number(dataValue.time.hours) * 60 + Number(dataValue.time.mins || 0),
      display_icon: dataValue.display_icon,
      files: resource_ids,
      code: dataValue.code,
      position: selfId ? activityDetail?.position : position + 1,
      course_id:idCourse
    } as any
    try {
      if (lo?.id) {
        payload.course_learning_outcome_id = lo?.id
        payload.course_outcome_ids = []
        for (let e of selectedLearningOutCome) {
          payload.course_outcome_ids.push(e)
        }
      }
      if (selfId) {
        const res = await CoursesAPI.editActivity({id: selfId, data: payload})
        const getNodeKey = ({treeIndex}: any) => treeIndex
        // getCourseDetail(idCourse)

        const newNode = {
          ...res.data,
          children: [],
          total_tab: data.total_tab,
        }
        if (treeData) {
          setData(changeNodeAtPath({treeData: treeData, path, getNodeKey, newNode}))
        }
        getCourseDetail(idCourse)
      } else {
        await CoursesAPI.createCourseAcitivy(payload)
        getCourseDetail(idCourse)
      }
      handleClose()
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '422|000405') {
        setError('code', {
          type: 'custom',
          message: 'Code does exist!',
        })
      }
    } finally {
      setLoading(false)
    }
  }
  const convertValue = useCallback(() => {
    if (outcomesList.length > 0) {
      const arr = []
      for (let e of outcomesList) {
        arr.push({label: htmlToRaw(e.description), value: e.id})
      }
      return arr
    }
  }, [outcomesList])
  const handleDeleteSelected = (value: string) => {
    setSelectedLearningOutcome((prev: any) => {
      const arr = [...prev]
      const newArr = arr.filter((e) => {
        return e !== value
      })
      return newArr
    })
  }
  const handleOnchangeFile = (files: any) => {
    setValue(
      'files',
      files.map((e: {id: string; name: string; resource_id: string}) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name,
        type: 'attached',
      }))
    )
  }
  return (
    <SappModal
      open={open}
      handleClose={handleClose}
      title={selfId ? 'Edit Activity' : 'Create Activity'}
      handleSubmit={handleSubmit(onSubmit)}
      cancelButtonCaption='Cancel'
      okButtonCaption='Save'
      dialogClassName={'m-0 modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
      classNameBody={'sapp-m-h-unset'}
      loading={loading}
      footerEnd
      confirmOnclose
    >
      {/* <div className='d-flex flex-column'> */}
      <div className='mb-10 fv-row'>
        <div className='mb-10 fv-row'>
          <HookFormTextField
            control={control}
            name='name'
            placeholder='Name'
            label='Activity Name'
            required
            className='sapp-h-48px'
          />
        </div>
        <HookFormTextField
          control={control}
          name='code'
          placeholder='Code'
          disabled={selfId ? true : false}
          label='Activity Code'
          required
          className='sapp-h-48px'
        />
      </div>

      <div className='mb-10'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label required'>
          <span>Duration</span>
        </label>

        <Row className='align-items-start'>
          <Col>
            <HookFormTextField
              disabled={onlyEditName}
              control={control}
              name='time.hours'
              // placeholder='Hours'
              className='sapp-h-48px fs-6'
              labelClass='d-flex align-items-center fs-6 fw-bold mb-3'
              type='number'
              // disabled={!editAble}
              onChange={() => {
                if (isSubmitted) {
                  trigger('time.mins')
                }
              }}
              // skeleton={skeleton}
              postFix={<div className='sapp-post-fix-text-field '>hours</div>}
              // groupText='hours'
            ></HookFormTextField>
          </Col>
          <Col>
            <HookFormTextField
              disabled={onlyEditName}
              control={control}
              name='time.mins'
              // placeholder='Mins'
              className='sapp-h-48px fs-6'
              type='number'
              // disabled={!editAble}
              onChange={() => {
                if (isSubmitted) {
                  trigger('time.hours')
                }
              }}
              // skeleton={skeleton}
              postFix={<div className='sapp-post-fix-text-field '>mins</div>}
            ></HookFormTextField>
          </Col>
        </Row>
      </div>

      <div className='mb-10 fv-row'>
        <div className='d-flex flex-column sapp-w-49'>
          {/* <div className='sapp-title-checkbox-activity'>
            Type of Activity (Only display in student site)
          </div> */}
          <HookFormSelect
            disabled={onlyEditName}
            control={control}
            name='display_icon'
            options={[
              {label: 'Text', value: TYPE_DOCUMENT.TEXT},
              {label: 'Video', value: TYPE_DOCUMENT.VIDEO},
              {label: 'Quiz', value: TYPE_DOCUMENT.QUIZ},
              {label: 'Past Exam Analysis', value: TYPE_DOCUMENT.PAST_EXAM_ANALYSIS},
            ]}
            label='Type of Activity (Only display in student site)'
            required
            className='w-50 sapp-h-48px sapp-select-custom'
            defaultValue={TYPE_DOCUMENT.TEXT}
          />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <div className='d-flex flex-column'>
          <label className='fs-6 fw-bold form-label required'>Grade Activity</label>
          <HookFormRadioGroup
            disabled={onlyEditName}
            control={control}
            name='is_graded'
            options={[
              {label: 'Yes', value: 'true'},
              {label: 'No', value: 'false'},
            ]}
            direction='horizontal'
            justify='start'
            separator={false}
            gap={20}
            defaultValue={'true'}
          />
        </div>
      </div>
      <div className={'mb-10'}>
        <SAPPSelectLoadMore
          disabled={onlyEditName}
          onChange={(e) => {
            setSelectedLearningOutcome(e)
          }}
          options={convertValue()}
          size={'large'}
          loadmore={false}
          handleNextPage={() => {}}
          placeholder='Choose Learning Outcome'
          mode='multiple'
          value={selectedLearningOutCome}
          className='sapp-h-48px sapp-select-custom'
        />
      </div>
      {selectedLearningOutCome?.length > 0 && (
        <div className=' mb-8 fv-row'>
          {selectedLearningOutCome?.map((e: any, i: number) => {
            return (
              <SelectedLearningOutCome
                id={e}
                key={e}
                handleDelete={handleDeleteSelected}
                index={i}
              />
            )
          })}
        </div>
      )}
      <div className='d-flex flex-column'>
        <UploadMulti
          fileList={filesFormAddNew}
          setFileList={handleOnchangeFile}
          error={errors.files}
          resourceLocation={RESOURCE_LOCATION.activity}
          label='Resources'
          disabled={onlyEditName}
        />
      </div>
    </SappModal>
  )
}
export default CreateActivity
