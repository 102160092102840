import React from 'react'
import {ControllerRenderProps, FieldError} from 'react-hook-form'
import './Hookformtextfield.scss'
interface IProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: 'number' | 'password' | 'email' | 'text'
  placeholder?: string
  className?: string
  value?: any
  defaultValue?: string
  disabled?: boolean
  label?: string
  labelClass?: string
  tooltipIcon?: string | undefined
  onChangeType?: () => void
  passwordVisible?: boolean
  showIconPassword?: boolean
  error?: FieldError
  required?: boolean
  maxLength?: number
  groupText?: string
  guideline?: string[]
  field?: ControllerRenderProps<any, string>
  postFix?: any
  id?: string
  onPaste?: any
  preIcon?: any
  minNumber?: number
  requiredZero? : boolean
}

const SAPPTextFiled = ({
  type,
  value,
  defaultValue,
  onChange,
  className,
  placeholder,
  disabled,
  // labelClass = 'd-flex align-items-center form-label fs-6 fw-bold',
  onChangeType,
  passwordVisible,
  showIconPassword,
  error,
  maxLength,
  groupText,
  field,
  postFix,
  id,
  onPaste,
  preIcon,
  minNumber = 0,
  requiredZero = false,
}: IProps) => {
  return (

      <div className={`${className ?? ''} position-relative w-100`}>
        <div className={`${groupText || postFix ? 'input-group flex-nowrap' : ''}`}>
          {groupText && (
            <div className='input-group-text sapp-input-group-text justify-content-center'>
              {groupText}
            </div>
          )}
          {preIcon}
          <input
            {...field}
            type={type}
            value={value ?? ''}
            defaultValue={value ? defaultValue : undefined}
            onChange={onChange}
            className={`${className ?? ''} form-control bg-transparent sapp-form-control-custom ${
              groupText && 'rounded-end'
            } ${preIcon && 'ps-13'}`}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            id={id}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (requiredZero && type === 'number') {
                if (parseInt(e.currentTarget.value, 10) === 0) {
                  e.currentTarget.value = ''
                } else {
                  e.currentTarget.value = e.currentTarget.value.replace(/[-eE]/g, '')
                }
              }
            }}
            onKeyDown={(e: any) => {
              // Lọc các ký tự "e", "E" hoặc số ấm trong number
              if ((e.keyCode === 69 || e.keyCode === 189 ) && type === 'number') {
                e.preventDefault()
                e.currentTarget.value = e.currentTarget.value.replace(/[-eE]/g, '')
              }
            }}
            min={`${minNumber}`}
            onPaste={onPaste}
          />
          {postFix && (
            <span className='input-group-text ps-1 pe-1' id='basic-addon2'>
              {postFix}
            </span>
          )}
        </div>
        {showIconPassword && (
          <span
            className={`btn btn-sm btn-icon position-absolute translate-middle top-50 ${
              error ? 'd-none' : 'end-0'
            } me-n2`}
            onClick={onChangeType}
          >
            {passwordVisible ? (
              <i className='ki-duotone ki-eye-slash fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
              </i>
            ) : (
              <i className='ki-duotone ki-eye fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            )}
          </span>
        )}
      </div>

  )
}

export default SAPPTextFiled
