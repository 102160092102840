import {FC} from 'react'
import {TITLE_USER_STATUS, CONTENT_USER_STATUS} from 'src/constants/classes'

type Props = {
  status?: string | undefined
  className?: string
}

const UserStatusCell: FC<Props> = ({status, className}) => {
  const renderUser = () => {
    switch(status) {
      case `${TITLE_USER_STATUS.RESERVED}`:
        return (
          <>{CONTENT_USER_STATUS.RESERVED}</>
        )
        break
      case `${TITLE_USER_STATUS.MOVED_OUT}`:
        return (
          <>{CONTENT_USER_STATUS.MOVED_OUT}</>
        )
        break
      case `${TITLE_USER_STATUS.MOVED_IN}`:
        return (
          <>{CONTENT_USER_STATUS.MOVED_IN}</>
        )
        break
      case `${TITLE_USER_STATUS.TRANSFER_TO}`:
        return (
          <>{CONTENT_USER_STATUS.TRANSFER_TO}</>
        )
        break
      case `${TITLE_USER_STATUS.TRANSFERRED}`:
        return (
          <>{CONTENT_USER_STATUS.TRANSFERRED}</>
        )
        break
      case `${TITLE_USER_STATUS.CANCELED}`:
        return (
          <>{CONTENT_USER_STATUS.CANCELED}</>
        )
        break
      default:
        return (
          <>{CONTENT_USER_STATUS.NORMAL}</>
        )
    }
  }

  return (
    <>
      <span
        className={`sapp-user-icon sapp-user-icon-${status?.toLowerCase()} f-flex justify-content-center ${className ? `${className}` : 'min-w-44px'}`}
      >
        {renderUser()}
      </span>
    </>
  )
}

export default UserStatusCell
