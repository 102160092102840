import {Select} from 'antd'
import {DefaultOptionType} from 'antd/es/select'
import {FilterFunc} from 'rc-select/lib/Select'
import {ReactNode, useState} from 'react'
import {Control, Controller} from 'react-hook-form'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'

interface IProps {
  name: string
  control: Control<any>
  defaultValue?: string
  className?: string
  label?: string
  size?: 'large' | 'middle' | 'small'
  dropdownStyle?: React.CSSProperties | undefined
  placeholder?: string
  showSearch?: boolean
  skeleton?: boolean
  required?: boolean
  guideline?: string[]
  onSearch?: (value: string) => Promise<void>
  disabled?: boolean
  allowClear?: boolean
  options?: DefaultOptionType[]
  children?: ReactNode
  filterOption?: boolean | FilterFunc<DefaultOptionType> | undefined
  suffixIcon?: any
  onClear?: () => void
}

const HookFormSelect = ({
  control,
  name,
  allowClear,
  className,
  defaultValue,
  disabled,
  label,
  onSearch,
  placeholder,
  showSearch,
  size,
  options,
  filterOption,
  guideline,
  required,
  suffixIcon,
  onClear
}: IProps) => {
  const [suffIcon, setSuffIcon] = useState<any>(suffixIcon)
  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue||undefined}
        render={({field}) => (
          <Select
            defaultValue={defaultValue || null}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            placeholder={placeholder}
            allowClear={allowClear}
            onMouseEnter={() => {
              if (field.value && allowClear) {
                setSuffIcon(null)
              }
            }}
            onMouseLeave={() => setSuffIcon(suffixIcon)}
            // onDropdownVisibleChange={()=>{
            //   if(suffIcon !== null && !field.value && !allowClear){
            //     setSuffIcon(null)
            //   }
            //   else {
            //     setSuffIcon(suffixIcon)
            //   }
            // }}
            options={options}
            size={size}
            dropdownStyle={{minWidth: 350}}
            className={`${className} w-100 sapp-hook-form-select`}
            showSearch={showSearch}
            onSearch={onSearch}
            filterOption={
              filterOption ??
              (showSearch &&
                ((input, option) =>
                  (option?.children ?? option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input?.toLowerCase() ?? '')))
            }
            disabled={disabled}
            suffixIcon={suffIcon}
            onClear={onClear}
          />
        )}
      />
      <GuidelineField guideline={guideline} />
    </>
  )
}

export default HookFormSelect
