import React, {Dispatch, SetStateAction, useEffect} from 'react'
import {Link} from 'react-router-dom'
import ItemProfile from 'src/components/base/ItemProfile'
import {FORMAT_DATETIME, PageLink} from 'src/constants'
import {IStudentDetail} from 'src/type/students'
import LoadingProfileDetail from './loading/LoadingProfileDetail'
import {format} from 'date-fns'
import {LANG_PLACEHOLDER} from 'src/constants/lang'
import {useUserContext} from 'src/context/UserProvider'

interface IProps {
  userDetail: IStudentDetail | undefined
  loading: boolean
  setProfile: Dispatch<SetStateAction<any>>
}

const ProfileDetail = ({userDetail, loading, setProfile}: IProps) => {
  const {getProfile} = useUserContext()

  useEffect(() => {
    getProfile().then((res: any) => setProfile(res))
  }, [])

  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Profile Details</h3>
            </div>

            <Link
              to={PageLink.PROFILE_SETTING}
              className='btn btn-sm btn-primary align-self-center'
            >
              Edit Profile
            </Link>
          </div>

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={userDetail?.key} />
            <ItemProfile title={LANG_PLACEHOLDER.FULLNAME} body={userDetail?.detail?.full_name} />
            <ItemProfile title='Username' body={userDetail?.username} />
            <ItemProfile title='Email' body={userDetail?.detail?.email} />
            <ItemProfile title='Phone Number' body={userDetail?.detail?.phone} />
            <ItemProfile title='Role' roles={userDetail?.roles} />
            <ItemProfile title='Status' status={userDetail?.status} />
            <ItemProfile
              title='Updated At'
              body={
                userDetail?.updated_at
                  ? format(new Date(userDetail?.updated_at), FORMAT_DATETIME)
                  : ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileDetail
