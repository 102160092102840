import { useNavigate } from 'react-router-dom'
import ActionCellEntrance from '../base/actionEntranceTest/ActionCellEntrance'
import {EntranceApi} from 'src/apis/entrance-test'
import { ENTRANCE_TEST, ENTRANCE_TEST_RESULT_SCORE_DETAIL_URL } from 'src/constants/entrancetest'
import { PageLink } from 'src/constants'

interface IProps {
  Action: any
  data: any
}

const ParticipantAction = ({Action, data}: IProps) => {

  const navigate = useNavigate()

  const getParticipantsDetail = async (id: string) => {
    try {
      const res = await EntranceApi.detailAttempts(id)
      if (res) {
        Action('content', res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <ActionCellEntrance>
        <div className='menu-item px-3' onClick={() => getParticipantsDetail(data?.id)}>
          <div className='menu-link px-3'>Detail</div>
        </div>
        <div className='menu-item px-3' onClick={() => navigate(`${PageLink.ENTRANCE_TEST_RESULT_SCORE_DETAIL}/${data.id}/`)}>
          <div className='menu-link px-3'>Test Result</div>
        </div>
      </ActionCellEntrance>
    </>
  )
}
export default ParticipantAction
