import React, {Dispatch, SetStateAction, useState} from 'react'
import DeactivateAccount from 'src/components/my-profile/setting-profile/DeactivateAccount'
// import EmailPreferences from 'src/components/my-profile/setting-profile/EmailPreferences'
// import Notifications from 'src/components/my-profile/setting-profile/Notifications'
import {IStudentDetail} from 'src/type/students'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import toast from 'react-hot-toast'
import { useUserContext } from 'src/context/UserProvider'
import StudentProfileSecurity from './SecurityProfile'

interface IProps {
  studentDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const SecurityStudent = ({studentDetail, setLoading, loading,setStudentDetail}: IProps) => {
  const { id } = useParams()
  const [confirmDeactive, setConfirmDeactive] = useState(false)
  const {getStudentDetail} = useUserContext()


  return (
    <>
    <StudentProfileSecurity studentDetail={studentDetail} loading={loading} setStudentDetail={setStudentDetail} setLoading={setLoading} id={id}/>
    </>
  )
}

export default SecurityStudent
