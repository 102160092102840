import React, {Dispatch, SetStateAction, useState} from 'react'
import ProfileDetail from './ProfileDetail'
import Method from './Method'
// import ConnectAccount from './ConnectAccount'
// import EmailPreferences from './EmailPreferences'
// import Notifications from './Notifications'
import DeactivateAccount from './DeactivateAccount'
import {IStudentDetail} from 'src/type/students'
import {useUserContext} from 'src/context/UserProvider'
import {toast} from 'react-hot-toast'
import {StaffAPI} from 'src/apis/staffs'

interface IProps {
  userDetail: IStudentDetail | undefined
  setProfile: Dispatch<SetStateAction<any>>
}

const SettingProfile = ({userDetail, setProfile}: IProps) => {
  const [confirmDeactive, setConfirmDeactive] = useState(false)
  const {getProfile} = useUserContext()

  const blockedProfileMe = async () => {
    await StaffAPI.blocked([userDetail?.id as string]).then(() =>
      toast.success('Blocked Successfully!')
    )
    await getProfile().then((res: any) => setProfile(res?.data))
  }
  
  return (
    <>
      <ProfileDetail userDetail={userDetail} />
      <Method />
      {/* <ConnectAccount /> */}

      {/* <EmailPreferences />

      <Notifications /> */}

      <DeactivateAccount
        confirmDeactive={confirmDeactive}
        setConfirmDeactive={setConfirmDeactive}
        blockedUser={blockedProfileMe}
      />
    </>
  )
}

export default SettingProfile
