import {zodResolver} from '@hookform/resolvers/zod'
import React, {Dispatch, SetStateAction} from 'react'
import {useForm} from 'react-hook-form'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelect from 'src/components/base/select/HookFormSelect'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {PageLink, VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import {Modal} from 'react-bootstrap'
import {
  LANG_DASHBOARD,
  LANG_NEWS,
  LANG_RESOURCEBANK,
  LANG_SIDEBAR,
  LANG_SIGNIN,
  LANG_SUPPORTCENTER,
} from 'src/constants/lang'

interface IInputProps {
  subject: string
  product: string
  assign: string
  status: string
  dueDate: Date | number
  description: string
  email: boolean
  phone: boolean
}

interface IProps {
  openTicket: boolean
  setOpenTicket: Dispatch<SetStateAction<boolean>>
}

const CreateTickets = ({openTicket, setOpenTicket}: IProps) => {
  const today = new Date()
  const timeStamps = today.getTime()

  // validate for input
  const schema = z.object({
    subject: z.string({required_error: VALIDATION_FILED}).min(1),
    product: z.string(),
    assign: z.string(),
    status: z.string(),
    dueDate: z
      .date({required_error: VALIDATION_FILED})
      .or(z.number().min(timeStamps, {message: 'Date must be greater than today'})),
    description: z.string({required_error: VALIDATION_FILED}).min(1),
    email: z.boolean().optional(),
    phone: z.boolean().optional(),
  })

  const {control, handleSubmit} = useForm<IInputProps>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  // function when submit form
  const onSubmit = (data: IInputProps) => {
    console.log(data)
  }

  return (
    <Modal dialogClassName='modal-dialog modal-dialog-centered mw-750px' show={openTicket}>
      <div className='modal-content rounded'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          {/* start:: icon close ticket */}
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setOpenTicket(false)}
          >
            <i className='ki-outline ki-cross fs-1'></i>
          </div>
          {/* end:: icon close ticket */}
        </div>

        <div className='modal-body scroll-y px-10 px-lg-15 pt-0 pb-15'>
          <form id='kt_modal_new_ticket_form' className='form' action={PageLink.DASHBOARD}>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>{LANG_SUPPORTCENTER.createTicket}</h1>

              <div className='text-gray-400 fw-semibold fs-5'>
                {LANG_SUPPORTCENTER.pleaseCheck}
                <a href='/' className='fw-bold link-primary'>
                  {' '}
                  {LANG_SUPPORTCENTER.supportGuidelines}
                </a>
              </div>
            </div>

            {/* start:: textfield subject */}
            <div className='d-flex flex-column mb-8 fv-row'>
              <HookFormTextField
                name='subject'
                control={control}
                placeholder='Enter your ticket subject'
                label={LANG_RESOURCEBANK.subject}
              />
            </div>
            {/* end:: textfield subject */}

            <div className='row g-9 mb-8'>
              {/* start:: select product */}
              <div className='col-md-6 fv-row'>
                <HookFormSelect
                  name='product'
                  control={control}
                  label={LANG_SUPPORTCENTER.product}
                  // size='lg'
                >
                  <option value=''>Select a product...</option>
                  <option value='1'>HTML Theme</option>
                  <option value='2'>Angular App</option>
                </HookFormSelect>
              </div>
              {/* end:: select product */}

              {/* start:: select assign */}
              <div className='col-md-6 fv-row'>
                <HookFormSelect
                  name='assign'
                  control={control}
                  label={LANG_SUPPORTCENTER.assign}
                  // size='lg'
                >
                  <option value='Karina Clark'>Karina Clark</option>
                  <option value='Robert Doe'>Robert Doe</option>
                  <option value='Niel Owen'>Niel Owen</option>
                </HookFormSelect>
              </div>
              {/* end:: select assign */}
            </div>

            <div className='row g-9 mb-8'>
              {/* start:: select status */}
              <div className='col-md-6 fv-row'>
                <HookFormSelect
                  name='status'
                  control={control}
                  label={LANG_DASHBOARD.status}
                  // size='lg'
                >
                  <option value='Karina Clark'>Karina Clark</option>
                  <option value='Robert Doe'>Robert Doe</option>
                  <option value='Niel Owen'>Niel Owen</option>
                </HookFormSelect>
              </div>
              {/* end:: select status */}

              {/* start:: dateTime dueDate */}
              <div className='col-md-6 fv-row'>
                <HookFormDateTime
                  name='dueDate'
                  control={control}
                  label={LANG_SUPPORTCENTER.dueDate}
                />
              </div>
              {/* end:: dateTime dueDate */}
            </div>

            {/* start:: textarea description */}
            <div className='d-flex flex-column mb-8 fv-row'>
              <HookFormTextArea
                name='description'
                control={control}
                label={LANG_NEWS.description}
                placeholder='Type your ticket description'
              />
            </div>
            {/* end:: textarea description */}

            <div className='fv-row mb-8'>
              {/* start:: label */}
              <label className='fs-6 fw-bold mb-3'>{LANG_SUPPORTCENTER.attachments}</label>
              {/* end:: label */}

              <div className='dropzone' id='kt_modal_create_ticket_attachments'>
                <div className='dz-message needsclick align-items-center'>
                  <i className='ki-outline ki-file-up fs-3hx text-primary'></i>
                  <div className='ms-4'>
                    <h3 className='fs-5 fw-bold text-gray-900 mb-1'>{LANG_NEWS.dropFiles}</h3>
                    <span className='fw-semibold fs-7 text-gray-400'>{LANG_NEWS.upload}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-15 fv-row'>
              <div className='d-flex flex-stack'>
                {/* start:: label */}
                <div className='fw-semibold me-5'>
                  <label className='fs-6'>{LANG_SIDEBAR.notifications}</label>
                  <div className='fs-7 text-gray-400'>{LANG_SUPPORTCENTER.allowNotifications}</div>
                </div>
                {/* end:: label */}

                <div className='d-flex align-items-center'>
                  {/* start:: checkbox email */}
                  <HookFormCheckBox
                    name='email'
                    control={control}
                    title={LANG_SIGNIN.email}
                    defaultValue={false}
                  />
                  {/* end:: checkbox email */}

                  {/* start:: checkbox phone */}
                  <HookFormCheckBox
                    className='ms-5'
                    name='phone'
                    control={control}
                    title={LANG_SUPPORTCENTER.phone}
                    defaultValue={false}
                  />
                  {/* end:: checkbox phone */}
                </div>
              </div>
            </div>

            {/* start:: dialog button cancel submit */}
            <SAPPDialogButtonsCancelSubmit
              okOnClick={handleSubmit(onSubmit)}
              cancelButtonCaption='Cancel'
              okButtonCaption='Submit'
              cancelClick={() => setOpenTicket(false)}
            />
            {/* end:: dialog button cancel submit */}
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default CreateTickets
