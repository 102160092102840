import {useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import PageLayouts from 'src/components/layout/PageLayouts'
import {useCourseContext} from 'src/context/CourseProvider'
import './Tree.scss'
import CourseContent from './course-content'
import Processbar from './progress-bar'
import CourseResource from './course-resource'
import CreateAndUpdateCourseInfo from 'src/pages/courses/CreateAndUpdateCourseInfo'
import CourseCertificate from './certificate/CourseCertificate'

const CourseDetail = () => {
  const {setId} = useCourseContext()
  const {id} = useParams()
  const navigate = useNavigate() // hook để điều hướng lập trình
  const [searchParams, setSearchParams] = useSearchParams() // hook để lấy và cập nhật các
  const [newStep, setNewStep] = useState<any>([
    {
      title: 'Course Info',
      status: 'current',
      link: ``,
    },
    {
      title: 'Course Content',
      status: '',
      link: '',
    },
    {
      title: 'Resource',
      status: '',
      link: '',
    },
    {
      title: 'Certificate',
      status: '',
      link: '',
    },
  ])

  useEffect(() => {
    if (id) {
      setId(id as string)
      // set default page bằng tham số page
      if (searchParams.has('page')) {
        const index = Number(searchParams.get('page'))
        setNewStep((step: any[]) => {
          const _new = step.map((e, idx) => {
            if (index > idx) {
              return {...e, status: 'active'}
            }
            if (index === idx) {
              return {...e, status: 'current'}
            }
            if (index < idx) {
              return {...e, status: ''}
            }
          })
          return _new
        })
        searchParams.delete('page')
        setSearchParams(searchParams)
        navigate('.', {replace: true})
      }
    }
  }, [id])
  return (
    <PageLayouts
      breadcrumbs={[]}
      pageTitle=''
      showSideBar={false}
      titleTab='Course'
      removePadding={true}
      showFooter={false}
      // backgroudColor={`${newStep[0]?.status === 'current' ? '' : 'bg-white'}`}
    >
      <div className='pb-6'>
        <div className='sapp-proress-bar-wrapper'>
          <Processbar step={newStep} setNewStep={setNewStep} />
        </div>
        {newStep[0].status === 'current' && (
          <CreateAndUpdateCourseInfo setStep={setNewStep} step={newStep} />
        )}
        {newStep[1].status === 'current' && <CourseContent />}
        {newStep[2].status === 'current' && <CourseResource />}
        {newStep[3].status === 'current' && <CourseCertificate />}
      </div>
    </PageLayouts>
  )
}
export default CourseDetail
