import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Control, FieldError} from 'react-hook-form'
import {Col} from 'react-bootstrap'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {LIST_SECTION_OPTION} from 'src/constants/entrancetest'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'

type Props = {
  error?: FieldError | undefined
  control: Control<any>
  index: any
  confirm: (option: any) => void
  toggleLabelCallback: () => void
  typeofSection: any
  passPoint?: string
  passNote?: string
  failureNote?: string
  editMode?: any
  onHandleEditAble: (idx: number) => void
  isEdit: boolean
  id?: string
  setting: any
  setValue: any
  isLocked: boolean
  isOnUsed: boolean
}

const SettingTab = ({
  control,
  index,
  confirm,
  toggleLabelCallback,
  typeofSection,
  isEdit,
  editMode,
  onHandleEditAble,
  id,
  setting,
  setValue,
  isLocked,
  isOnUsed,
}: Props) => {
  useEffect(() => {
    Object.entries(setting).map((e) => {
      setValue(`setting.${index}.${e[0]}`, e[1])
    })
  }, [setting])
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)
  const [isRotated, setIsRotated] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const toggleEditAble = () => {
    onHandleEditAble(index)
    toggleLabelCallback()
    setIsRotated(!isAccordionOpen)
    setIsAccordionOpen(!isAccordionOpen)
  }

  const toggleAccordion = (index: number) => {
    setIsAccordionOpen(!isAccordionOpen)
    setIsRotated(!isAccordionOpen)
    toggleLabelCallback()
  }
  useEffect(() => {
    setIsEditing(isEdit)
  },[isEdit])

  useLayoutEffect(() => {
    if (isEditing === false) {
      setIsAccordionOpen(true)
    } else if (isEditing === true) {
      setIsRotated(false)
      setIsAccordionOpen(false)
    }
  }, [isEditing])

  const accordionStyle = {
    height: isAccordionOpen ? 'auto' : '64px',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
  }
  return (
    <div className='w-full ' style={accordionStyle}>
      <div>
        <div
          className={
            isAccordionOpen
              ? 'd-flex justify-content-between border-bottom p-4'
              : 'd-flex justify-content-between p-4 pt-3'
          }
        >
          {isAccordionOpen ? (
            <div className='pt-2 px-4 sapp-fs-16px fw-bold'>{typeofSection}</div>
          ) : (
            <div className='pt-3 px-4 sapp-fs-16px fw-bold'>{typeofSection}</div>
          )}
          <div className='d-flex gap-3'>
            {isEdit && isLocked && !isOnUsed && (
              <div className='d-flex gap-3'>
                <div>
                  <ButtonIconOnly
                    iconName={'notepad-edit'}
                    activeColor='dark'
                    bg='gray-100'
                    onClick={toggleEditAble}
                  />
                </div>
              </div>
            )}
            {isEdit && (
              <div
                style={{
                  transform: !isRotated ? 'rotate( 90deg)' : '',
                  cursor: 'pointer',
                }}
              >
                <ButtonIconOnly
                  iconName={'down'}
                  activeColor='dark'
                  bg='gray-100'
                  onClick={toggleAccordion}
                />
              </div>
            )}
          </div>
        </div>
        {isAccordionOpen && (
          <div className='px-8 pb-8'>
            <div className='mt-8 row'>
              <Col md={6} className='mb-8 w-100'>
                <HookFormTextField
                  label={LIST_SECTION_OPTION.pass_point}
                  required
                  control={control}
                  name={`setting.${index}.pass_point`}
                  placeholder=' '
                  disabled={editMode}
                  className='sapp-h-48px fs-6 lh-1 sapp-table-class-field w-100 '
                />
              </Col>
            </div>
            <div>
              <HookFormEditor
                label={LIST_SECTION_OPTION.pass_note}
                required
                height={300}
                defaultValue={setting.pass_note}
                placeholder=' '
                name={`setting.${index}.pass_note`}
                control={control}
                math={true}
                disabled={editMode}
                className='w-100 fs-6'
              />
            </div>
            <div>
              <Col className='mt-8'>
                <HookFormEditor
                  label={LIST_SECTION_OPTION.failure_note}
                  required
                  defaultValue={setting.failure_note}
                  height={300}
                  placeholder=' '
                  name={`setting.${index}.failure_note`}
                  control={control}
                  math={true}
                  disabled={editMode}
                  className='w-100 fs-6'
                />
              </Col>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingTab
