import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  ChooseWordIcon,
  ChooseWordIconActive,
  DragNDropIcon,
  DragNDropIconActive,
  EssayIcon,
  EssayIconActive,
  FillTextIcon,
  FillTextIconActive,
  MatchingIcon,
  MatchingIconActive,
  MultipleChoiceActive,
  MultipleChoiceIcon,
  OnceChoiceIcon,
  OnceChoiceIconActive,
  TrueFalseIcon,
  TrueFalseIconActive,
} from 'src/_metronic/assets/images/question/icons'
import SappModal from 'src/components/base/SappModal'
import { IResponseQuestionsData, QUESTION_TYPES } from '../shared/interfaces'
import ActiveQuestForm from './ActiveQuestForm'
import './ChooseQuestionType.scss'

interface IChooseQuestionTypeProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setQuestions?: Dispatch<SetStateAction<IResponseQuestionsData<IQuestion[]> | undefined>>
}
interface IQuestion {
  id: string
  title: string
  image: any
  imageHovered: any
}

const ChooseQuestionType = ({open, setOpen}: IChooseQuestionTypeProps) => {
  const titleModal = 'Choose type of question'
  const handleClose = () => setOpen(false)

  const [openQuestForm, setOpenQuestForm] = useState<boolean>(false)
  const [activeQuestForm, setActiveQuestForm] = useState<QUESTION_TYPES | null>(null)

  const selectQuestion: IQuestion[] = [
    {
      id: QUESTION_TYPES.TRUE_FALSE,
      title: 'Đúng/Sai',
      image: <TrueFalseIcon />,
      imageHovered: <TrueFalseIconActive />,
    },
    {
      id: QUESTION_TYPES.ONE_CHOICE,
      title: 'Một lựa chọn',
      image: <OnceChoiceIcon />,
      imageHovered: <OnceChoiceIconActive />,
    },
    {
      id: QUESTION_TYPES.MULTIPLE_CHOICE,
      title: 'Nhiều lựa chọn',
      image: <MultipleChoiceIcon />,
      imageHovered: <MultipleChoiceActive />,
    },
    {
      id: QUESTION_TYPES.MATCHING,
      title: 'Ghép Đáp Án',
      image: <MatchingIcon />,
      imageHovered: <MatchingIconActive />,
    },
    {
      id: QUESTION_TYPES.SELECT_WORD,
      title: 'Chọn từ',
      image: <ChooseWordIcon />,
      imageHovered: <ChooseWordIconActive />,
    },
    {
      id: QUESTION_TYPES.FILL_WORD,
      title: 'Điền từ',
      image: <FillTextIcon />,
      imageHovered: <FillTextIconActive />,
    },
    {
      id: QUESTION_TYPES.DRAG_DROP,
      title: 'Drag Drop',
      image: <DragNDropIcon />,
      imageHovered: <DragNDropIconActive />,
    },
    {
      id: QUESTION_TYPES.ESSAY,
      title: 'Tự luận',
      image: <EssayIcon />,
      imageHovered: <EssayIconActive />,
    },
  ]

  const handleOpenAddNew = (id: QUESTION_TYPES) => {
    setOpen(false)
    if (activeQuestForm !== id) {
      setActiveQuestForm(id)
    }
    setOpenQuestForm(true)
  }

  return (
    <div>
      <SappModal
        open={open}
        handleClose={handleClose}
        title={titleModal}
        handleSubmit={() => {}}
        loading={false}
        cancelButtonCaption='Cancel'
        okButtonCaption='Import'
        dialogClassName='mw-850px modal-dialog-centered'
        showFooter={false}
      >
        <div className='d-flex flex-wrap w-full justify-content-center'>
          {selectQuestion.map((item) => {
            return (
              <div key={item.id} className='mb-7 sapp-question-type'>
                <div
                  className='sapp-question-type_content'
                  role='button'
                  onClick={() => handleOpenAddNew(item.id as QUESTION_TYPES)}
                >
                  <div className='sapp-question-type_img w-100'>{item.image}</div>
                  <div className='sapp-question-type_img__hovered w-100'>{item.imageHovered}</div>
                  <p className='sapp-question-type_name mt-2 text-capitalize'>{item.title}</p>
                </div>
              </div>
            )
          })}
        </div>
      </SappModal>
      {/* start:: question form modal */}
      {activeQuestForm && (
        <ActiveQuestForm
          activeQuestForm={activeQuestForm}
          setActiveQuestForm={setActiveQuestForm}
          openQuestForm={openQuestForm}
          setOpenQuestForm={setOpenQuestForm}
        ></ActiveQuestForm>
      )}
      {/* end:: question form modal */}
    </div>
  )
}
export default ChooseQuestionType
