export const TYPE_OF_TEST = {
  multipleChoice: {
    label: 'Multiple Choice',
    value: 'MULTIPLE_CHOICE',
  },
  constructed: {
    label: 'Constructed',
    value: 'ESSAY',
  },
  mix: {
    label: 'Mixed (Multiple Choice + Constructed)',
    value: 'MIX',
  },
}
export const TEST_MODE = {
  tutor: {
    label: 'Tutor',
    value: 'TUTOR',
  },
  timed: {
    label: 'Timed',
    value: 'TIMED',
  },
}
export const GRADED_ASSIGNMENT = {
  yes: {
    label: 'Yes',
    value: 'YES',
  },
  no: {
    label: 'No',
    value: 'NO',
  },
}

export const GRADING_PREFERENCE = {
  each: {
    label: 'After each question',
    value: 'AFTER_EACH_QUESTION',
  },
  all: {
    label: 'After all questions',
    value: 'AFTER_ALL_QUESTIONS',
  },
}
export const GRADING_PREFERENCE_VID_SETTING = {
  each: {
    label: 'Each Question',
    value: 'AFTER_EACH_QUESTION',
  },
  all: {
    label: 'All questions',
    value: 'AFTER_ALL_QUESTIONS',
  },
}

export const PASS_POINT = {
  auto: {
    label: 'Auto 50%',
    value: 'AUTO',
  },
  manual: {
    label: 'Manual',
    value: 'MANUAL',
  },
}
export const ATTEMPT = {
  unlimited: {
    label: 'Unlimited',
    value: 'UNLIMITED',
  },
  limited: {
    label: 'Limited',
    value: 'LIMITED',
  },
}
