import {fetcher} from 'src/services/request'

export class DictionariesAPI {
  static getDictionary(skipPage: number, limit: number): Promise<void> {
    return fetcher(`/dictionaries/specialization/words?skipPage=${skipPage}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      },
    })
  }
}
