import React, {Dispatch, SetStateAction} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {MY_PROFILE} from 'src/constants'
import {IStudentDetail} from 'src/type/students'
import ClassUser from '../ClassUser'
import SettingTeacher from './setting-profile'
import OverviewTeacherProfile from './teacher-overview'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const RenderTeacherProfile = ({teacherDetail, setLoading, loading,setTeacherDetail}: IProps) => {
  const location = useLocation()
  const {id} = useParams()

  const renderProfile = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case MY_PROFILE.OVERVIEW:
        return <OverviewTeacherProfile teacherDetail={teacherDetail} loading={loading}setTeacherDetail={setTeacherDetail}/>
      case MY_PROFILE.SETTING:
        return <SettingTeacher teacherDetail={teacherDetail} setLoading={setLoading} loading={loading} setTeacherDetail={setTeacherDetail}/>
      case MY_PROFILE.CLASS:
        return <ClassUser />
        // case MY_PROFILE.SECURITY:
      //   return <SecurityProfile />
      // case MY_PROFILE.ACTIVITY:
      //   return <ActivityProfile />
      // case MY_PROFILE.BILLING:
      //   return <BillingProfile />
      // case MY_PROFILE.STATEMENTS:
      //   return <StatementsProfile />
      // case MY_PROFILE.CERTIFICATE:
      //   return <ReferralsProfile />
      // case MY_PROFILE.LOGS:
      //   return <LogsProfile />
      default:
        return <OverviewTeacherProfile teacherDetail={teacherDetail}  loading={loading} setTeacherDetail={setTeacherDetail}/>
    }
  }
  return <>{renderProfile()}</>
}

export default RenderTeacherProfile
