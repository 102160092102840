import React from 'react'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import Card from 'src/components/support-center/Card'
import RenderSupport from 'src/components/support-center/RenderSupport'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'LMS'
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Support center'
  }
]

const SupportCenter = () => {
  return (
    <PageLayouts pageTitle='Support center' breadcrumbs={breadcrumbs}>
      <Card />
      <RenderSupport />
    </PageLayouts>
  )
}

export default withAuthComponents(SupportCenter)
