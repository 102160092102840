export const TITLE_OPTIONS_ENTRANCE = {
    listEntrance: 'Entrance Test List',
    createEntranceTest: 'Create Entrance Test',
    EntranceDetail: 'Entrance Test Detail',
    testname: 'Test Name',
    quiz_timed: 'Duration',
    listsection: 'List Sections',
    describe: 'Describe',
    reason: 'Block Reason',
    course_category_id: 'Program',
    subject: 'Subject',
    detail: 'Detail',
    settingCategory:'Cài đặt các Section cùng Question Category'
}
export const ENTRANCE_TEST_DETAIL = '/entrance-test/detail'
export const ENTRANCE_TEST = '/entrance-test'
export const ENTRANCE_TEST_RESULT_SCORE_DETAIL_URL = '/participants-list/result/score-detail'
export const LIST_SECTION_OPTION = {
    name: 'Section Name',
    question_category_id: 'Question Category',
    pass_point:'Pass Point',
    pass_note: 'Nhận xét Pass',
    failure_note: 'Nhận xét không pass'
}
export const OPTIONS_OF_CREATE_ENTRANCE_STATUS = [
  {
    label: 'Draft',
    value: 'draft',
  }
]
export const OPTIONS_OF_CREATE_SECTION = [
  {
    label: 'IQ',
    value: 'IQ',
  },
  {
    label: 'EQ',
    value: 'EQ',
  },
  {
    label: 'Chuyên ngành ',
    value: 'Chuyên ngành',
  }
]
export const OPTIONS_OF_STATUS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Locked',
    value: 'LOCKED',
  },
  {
    label: 'Deactive',
    value: 'DEACTIVE',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
]