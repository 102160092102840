import {useParams} from 'react-router-dom'
import TableImportLog from 'src/components/base/upload-file/TableImportLog'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'

let defaultBreadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
]

const ImportLogStudents = () => {
  const params = useParams()

  const setBreadcrumbs = (type?: string) => {
    let breadcrumbs = defaultBreadcrumbs
    if (type === 'CLASS_STUDENT') {
      return [
        ...breadcrumbs,
        {
          link: `${PageLink.IMPORT_LOG}/CLASS_STUDENT`,
          title: LANG_SIDEBAR.importLog,
        },
      ]
    }
  }
  return (
    <PageLayouts
      pageTitle={LANG_SIDEBAR.importLog}
      breadcrumbs={setBreadcrumbs(params.type) ?? defaultBreadcrumbs}
    >
      <div className='card'>
        <TableImportLog type={'CLASS_STUDENT'} />
      </div>
    </PageLayouts>
  )
}

export default ImportLogStudents
