import React from 'react'

interface IProps {
    ktCheck?: boolean;
    checkTarget?: string;
    checked: boolean
    onChange: (e?: any) => void
    className?: string
    disabled?: boolean
}

const SAPPCheckbox = ({ ktCheck, checkTarget, checked, onChange, className, disabled }: IProps) => {
    return (
        <div className={`form-check form-check-custom form-check-solid ${className}`}>
            <input
                className='form-check-input'
                type='checkbox'
                data-kt-check={ktCheck}
                data-kt-check-target={checkTarget}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}

export default SAPPCheckbox