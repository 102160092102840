import React from 'react'
import Channels from './Channels'
import Documentation from './Documentation'
import {PageLink} from 'src/constants'

const SupportTickets = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='d-flex flex-column flex-xl-row p-7'>
          <div className='flex-lg-row-fluid me-xl-15 mb-20 mb-xl-0'>
            <div className='mb-0'>
              <form method='post' action={PageLink.DASHBOARD} className='form mb-15'>
                <div className='position-relative'>
                  <i className='ki-outline ki-magnifier fs-1 text-primary position-absolute top-50 translate-middle ms-9'></i>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid ps-14'
                    name='search'
                    placeholder='Search'
                  />
                </div>
              </form>

              <h1 className='text-dark mb-10'>Public Tickets</h1>

              <div className='mb-10'>
                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-file-added fs-2x me-5 ms-n1 mt-2 text-success'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        How to use Netronic with Django Framework?
                      </a>

                      <span className='badge badge-light my-1'>React</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects are listed amazing
                      <br />
                      outstanding projects are listed
                    </span>
                  </div>
                </div>

                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-add-files fs-2x me-5 ms-n1 mt-2 text-warning'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        Admin theme npm issue
                      </a>

                      <span className='badge badge-light my-1'>Angular</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects
                    </span>
                  </div>
                </div>

                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-file-added fs-2x me-5 ms-n1 mt-2 text-success'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        Admin theme authentication to node.js / strapi backend
                      </a>

                      <span className='badge badge-light my-1'>warning</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects
                    </span>
                  </div>
                </div>

                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-file-added fs-2x me-5 ms-n1 mt-2 text-success'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        How to use Admin theme with Django Framework?
                      </a>

                      <span className='badge badge-light my-1'>HTML</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects are listed amazing
                      <br />
                      outstanding projects are listed
                    </span>
                  </div>
                </div>

                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-add-files fs-2x me-5 ms-n1 mt-2 text-warning'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        Reducing footprint of Start theme
                      </a>

                      <span className='badge badge-light my-1'>Angular</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects
                    </span>
                  </div>
                </div>

                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-file-added fs-2x me-5 ms-n1 mt-2 text-success'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        Admin theme node.js / strapi backend
                      </a>

                      <span className='badge badge-light my-1'>React</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects are listed amazing
                      <br />
                      outstanding projects are listed
                    </span>
                  </div>
                </div>

                <div className='d-flex mb-10'>
                  <i className='ki-outline ki-add-files fs-2x me-5 ms-n1 mt-2 text-warning'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        Admin theme npm issue
                      </a>

                      <span className='badge badge-light my-1'>View</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects
                    </span>
                  </div>
                </div>

                <div className='d-flex'>
                  <i className='ki-outline ki-file-added fs-2x me-5 ms-n1 mt-2 text-success'></i>

                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href={PageLink.AUTH_LOGIN}
                        className='text-dark text-hover-primary fs-4 me-3 fw-semibold'
                      >
                        How to use Admin theme with Django Framework?
                      </a>

                      <span className='badge badge-light my-1'>PHP</span>
                    </div>

                    <span className='text-muted fw-semibold fs-6'>
                      By Keenthemes to save tons and more to time money projects are listed amazing
                      <br />
                      outstanding projects are listed
                    </span>
                  </div>
                </div>
              </div>

              <ul className='pagination'>
                <li className='page-item previous disabled'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    <i className='previous'></i>
                  </a>
                </li>
                <li className='page-item'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    1
                  </a>
                </li>
                <li className='page-item active'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    2
                  </a>
                </li>
                <li className='page-item'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    3
                  </a>
                </li>
                <li className='page-item'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    4
                  </a>
                </li>
                <li className='page-item'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    5
                  </a>
                </li>
                <li className='page-item'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    6
                  </a>
                </li>
                <li className='page-item next'>
                  <a href={PageLink.DASHBOARD} className='page-link'>
                    <i className='next'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex-column flex-lg-row-auto w-100 mw-lg-300px mw-xxl-350px'>
            <Channels />
            <Documentation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportTickets
