import React, {useState} from 'react'
import withAuthComponents from 'src/components/auth/with-auth-components'
import CreateAndUpdateDictionary from 'src/components/dictionary/CreateAndUpdateDictionary'
import TableList from 'src/components/dictionary/TableList'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Dictionary',
  },
]

const Dictionary = () => {
  const [titleModal, setTitleModal] = useState<string>('Add Dictionary')
  const [open, setOpen] = useState(false)

  return (
    <PageLayouts pageTitle='Dictionary' breadcrumbs={breadcrumbs}>
      <div className='card card-flush h-xl-100 mt-5'>
        {/* start:: filter */}
        {/* end:: filter */}

        {/* start:: table */}
        <TableList setTitleModal={setTitleModal} setOpen={setOpen} />
        {/* end:: table */}
      </div>

      {/* start:: modal dictionary */}
      <CreateAndUpdateDictionary open={open} titleModal={titleModal} setOpen={setOpen} />
      {/* end:: modal dictionary */}
    </PageLayouts>
  )
}

export default withAuthComponents(Dictionary)
