import {PlusVideoIcon} from 'src/common/icons'
import './addVideo.scss'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import React from 'react'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'

interface IProps {
  onClick: () => void
  data?: any
  handleDelete: () => void
  handleEdit: (e: number) => void
  index: number
}
const AddVideoArea = ({onClick, data, handleDelete, handleEdit, index}: IProps) => {
  return (
    <React.Fragment>
      {!data?.quiz_id ? (
        <div className='sapp-addVideo-area mb-6'>
          <ButtonIcon
            customButton='btn btn-light sapp-addVideo-btn'
            title='Upload'
            onClick={onClick}
          >
            <PlusVideoIcon />
          </ButtonIcon>
        </div>
      ) : (
        <div className=' sapp-addVideo-area video-selected mb-6 sapp-video-selected-container p-8'>
          <div className='d-flex justify-content-between w-100 mb-8 align-items-center'>
            <div className='sapp-text-primary fs-6 fw-bold'>Video {index + 1}</div>
            <div className='gap-4 d-flex'>
              {data?.upload && (
                <>
                  <ButtonPrimary
                    onClick={() => handleEdit(0)}
                    // className='sapp-btn-color'
                    // disabled={loadingDeleteTab}
                    title='Edit Timeline'
                  />
                  <ButtonPrimary
                    onClick={() => handleEdit(1)}
                    // className='sapp-btn-color'
                    // disabled={loadingDeleteTab}
                    title='Edit Questions'
                  />
                </>
              )}
              {/* <ButtonSecondary
                onClick={handleDelete}
                className='ms-2'
                // disabled={loadingDeleteTab}
                title='Delete'
              /> */}
              <ButtonIconOnly
                iconName='trash'
                onClick={handleDelete}
                iconType='outline'
                bg={'gray-200'}
                activeColor='danger'
              />
            </div>
          </div>
          <div className='sapp-video-uploaded-container'>
            <video width={'100%'} height={'100%'} controls>
              <source src={data?.sub_url} />
            </video>
          </div>
          {/* <div className='mt-4 align-self-start ms-4 gap-4 d-flex'>
            <ButtonPrimary
              onClick={() => handleEdit(0)}
              // className='sapp-btn-color'
              // disabled={loadingDeleteTab}
              title='Edit Menu'
            />
            <ButtonPrimary
              onClick={() => handleEdit(1)}
              // className='sapp-btn-color'
              // disabled={loadingDeleteTab}
              title='Edit Questions'
            />
          </div> */}
        </div>
      )}
    </React.Fragment>
  )
}
export default AddVideoArea
