import {zodResolver} from '@hookform/resolvers/zod'
import {uniqueId} from 'lodash'
import {useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useFieldArray, useForm} from 'react-hook-form'
import {KTIcon} from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import SappDrawer from 'src/components/base/SappDrawer'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import {VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED} from 'src/constants'
import {useConfirm} from 'src/hooks/use-confirm'
import {IFile} from 'src/type/question-bank'
import {mergeImageToEditor} from 'src/utils/upload'
import {z} from 'zod'
import {defaultQuestionValues, validationQuestionSchema} from '../shared/consts'
import {
  IInputQuestionProps,
  IQuestion,
  QUESTION_ASSIGNMENT_TYPE,
  QUESTION_RESPONSE_OPTION,
  QUESTION_TYPES,
} from '../shared/interfaces'
import LayoutQuestion from './LayoutQuestion'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {v4 as uuid} from 'uuid'

type IEssayQuestionProp = {
  open: boolean
  onClose: () => void
  type: string
  id?: string
  actionType?: 'edit' | 'duplicate'
}
interface IAdditionalType {
  id: string
  name: string
  type: 'TEXT' | 'FILE'
  description: string
  files?: IFile[]
}
interface IInputProps extends Omit<IInputQuestionProps, 'question'> {
  assignment_type?: string
  response_option?: string | null
  requirements?: IAdditionalType[]
  exhibits?: IAdditionalType[]
}

const defaultValues = {
  ...defaultQuestionValues,
  question: {},
  exhibits: [],
  requirements: [],
  assignment_type: QUESTION_ASSIGNMENT_TYPE.TEXT,
  response_option: QUESTION_RESPONSE_OPTION.WORD,
}
const EssayQuestion = ({open, onClose, type, id, actionType}: IEssayQuestionProp) => {
  const [editorKey, setEditorKey] = useState('question_essay' + uuid())
  const validationSchema = z.object({
    ...validationQuestionSchema,
    assignment_type: z.string().optional(),
    response_option: z.string().optional(),
    question_content: z.string().optional(),
    requirements: z
      .array(
        z.object({
          id: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
          type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
          name: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
          description: z.string().optional(),
          files: z.array(z.any().optional()).default([]),
        })
      )
      .min(1, 'Requirements must contain at least 1 element(s)'),
    exhibits: z
      .array(
        z
          .object({
            id: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
            type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
            name: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
            description: z.string().optional(),
            files: z.array(z.any().optional()).default([]),
          })
          .optional()
      )
      .default([]),
  })

  const useFormProp = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const {
    control,
    getValues,
    watch,
    formState: {errors},
  } = useFormProp
  const {
    append: appendRequirement,
    update: updateRequirement,
    remove: removeRequirement,
  } = useFieldArray({
    control,
    name: 'requirements',
  })
  const {
    append: appendExhibit,
    update: updateExhibit,
    remove: removeExhibit,
  } = useFieldArray({
    control,
    name: 'exhibits',
  })
  const requirements = watch('requirements')
  const exhibits = watch('exhibits')

  // Form AddNew
  const validationAddNewSchema = z
    .object({
      name: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED)
        .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
      type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
      description: z.string().optional(),
      files: z.array(z.any().optional()).default([]),
    })
    .superRefine((arg: any, ctx) => {
      if (arg.type === 'TEXT' && !arg.description) {
        ctx.addIssue({
          message: VALIDATION_FILED,
          code: 'custom',
          path: ['description'],
        })
      }
      if (arg.type === 'FILE' && arg.files && arg.files?.length <= 0) {
        ctx.addIssue({
          message: VALIDATION_FILED,
          code: 'custom',
          path: ['files'],
        })
      }
    })

  const useFormAddNew = useForm<{
    description: string
    name: string
    type: 'TEXT' | 'FILE'
    files?: any[]
  }>({
    resolver: zodResolver(validationAddNewSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      description: '',
      type: 'TEXT',
    },
  })
  const {
    watch: watchAddNew,
    control: controlAddNew,
    handleSubmit: handleSubmitAddNew,
    setValue: setValueAddNewForm,
    formState: {errors: errorsAddNewForm},
    clearErrors: clearErrorsAddNewForm,
    reset: resetAddNew,
  } = useFormAddNew

  const typeFormAddNew = watchAddNew('type')
  const filesFormAddNew = watchAddNew('files')
  const [defaultEditorQuestContent, setDefaultEditorQuestContent] = useState<any>()
  const setFilesFormAddNew = (files: any[]) => {    
    setValueAddNewForm(
      'files',
      files.map((e: {id: string; name: string; resource_id: string; url:string; resource?:any}) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name || e.resource?.name,
        type: 'attached',
        resource: {...e, name:  e.name || e.resource?.name}
      }))
    )
  }
  const [defaultEditor, setDefaultEditor] = useState<any>()

  const [formAddQuestion, setFormAddQuestion] = useState<{
    open: boolean
    type: 'requirement' | 'exhibit'
    isEdit?: boolean
  }>({open: false, type: 'requirement'})
  const {confirm, contextHolder} = useConfirm()
  const indexAddNewRef = useRef<number | undefined>()

  const handleAddNewOpen = (
    type: 'requirement' | 'exhibit',
    data?: {name: string; files?: any[]; type: 'TEXT' | 'FILE'; description: string},
    id?: number
  ) => {
    indexAddNewRef.current = id
    if (data) {
      setValueAddNewForm('name', data.name)
      setValueAddNewForm('type', data.type)
      setValueAddNewForm('description', data.description)
      setDefaultEditor(data.description)
      setValueAddNewForm('files', data.files)
    } else {
      setValueAddNewForm('name', '')
      setValueAddNewForm('type', 'TEXT')
      setValueAddNewForm('description', '')
      setValueAddNewForm('files', [])
      setDefaultEditor('')
    }
    setFormAddQuestion(() => ({open: true, type, isEdit: !!data}))
    clearErrorsAddNewForm('description')
    clearErrorsAddNewForm('files')
  }

  const handleAddNewClose = () => {
    setFormAddQuestion((data) => ({...data, open: false}))
    setValueAddNewForm('name', '')
    setValueAddNewForm('type', 'TEXT')
    setValueAddNewForm('description', '')
    setValueAddNewForm('files', [])
    resetAddNew(
      {
        name: '',
        description: '',
        type: 'TEXT',
      },
      {
        keepSubmitCount: false,
        keepIsSubmitted: false,
        keepErrors: false,
        keepValues: false,
        keepDirty: false,
        keepIsValid: false,
        keepTouched: false,
        keepDirtyValues: false,
        keepDefaultValues: false,
      }
    )
  }

  const onSubmitAddNew = (data: {
    name: string
    type: 'TEXT' | 'FILE'
    description: string
    files?: IFile[]
  }) => {
    if (data.type === 'TEXT') {
      data.files = []
    } else {
      data.description = ''
    }

    if (formAddQuestion.type === 'requirement') {
      if (indexAddNewRef.current !== undefined) {
        updateRequirement(indexAddNewRef.current, {
          ...getValues(`requirements.${indexAddNewRef.current}`),
          ...data,
        })
      } else {
        appendRequirement({...data, id: uniqueId('requirement_')})
      }
    }

    if (formAddQuestion.type === 'exhibit') {
      if (indexAddNewRef.current !== undefined) {
        updateExhibit(indexAddNewRef.current, {
          ...getValues(`exhibits.${indexAddNewRef.current}`),
          ...data,
        })
      } else {
        appendExhibit({...data, id: uniqueId('exhibit_')})
      }
    }
    handleAddNewClose()
  }

  const handleChangeAddNewType = () => {
    if (indexAddNewRef.current !== undefined) {
      if (formAddQuestion.type === 'requirement') {
        setValueAddNewForm(
          'description',
          getValues(`requirements.${indexAddNewRef.current}.description`)
        )
        setValueAddNewForm('files', getValues(`requirements.${indexAddNewRef.current}.files`))
      } else {
        setValueAddNewForm(
          'description',
          getValues(`exhibits.${indexAddNewRef.current}.description`)
        )
        setValueAddNewForm('files', getValues(`exhibits.${indexAddNewRef.current}.files`))
      }
    } else {
      setValueAddNewForm('description', '')
      setValueAddNewForm('files', [])
    }
  }

  const buildRequest = (data: IInputProps): {partialQuestion: Partial<IQuestion>} => {
    return {
      partialQuestion: {
        qType: QUESTION_TYPES.ESSAY,
        assignment_type: data.assignment_type,
        response_option:
          data.response_option !== QUESTION_RESPONSE_OPTION.NONE ? data.response_option : null,
        requirements: data.requirements,
        exhibits: data.exhibits,
      },
    }
  }

  const fetchData = async (data: IQuestion) => {
    if (!data) {
      return {}
    }
    let resultReq = []
    let resultEx = []
    for (let e of data?.requirements || []) {
      // const des = await mergeImageToEditor(
      //   e.description || '',
      //   data.requirements?.flatMap((e) => e.files || []) || []
      // )
      resultReq.push({
        name: e.name,
        type: e.description ? 'TEXT' : ('FILE' as 'TEXT' | 'FILE'),
        files: convertFileResponseToLocal(e.files),
        description: e.description || '',
        id: uniqueId('requirement_'),
      })
    }
    for (let e of data?.exhibits || []) {
      // const des = await mergeImageToEditor(
      //   e.description || '',
      //   data.exhibits?.flatMap((e) => e.files || []) || []
      // )
      resultEx.push({
        name: e.name,
        type: e.description ? 'TEXT' : ('FILE' as 'TEXT' | 'FILE'),
        files: convertFileResponseToLocal(e.files),
        description: e.description || '',
        id: uniqueId('exhibit_'),
      })
    }
    const result = {
      requirements: resultReq,
      exhibits: resultEx,
      assignment_type: data.assignment_type,
      response_option: data.response_option ? data.response_option : QUESTION_RESPONSE_OPTION.NONE,
    }
    return result
  }

  const convertFileResponseToLocal = (responseFile: any) => {
    if (!responseFile || !Array.isArray(responseFile)) {
      return
    }
    return responseFile.map((e: any) => ({
      resource_id: e.resource.id,
      id: e.resource.id,
      name: e.resource.name,
      type: 'attached',
      resource: {...e.resource}
    }))
  }

  const onClearForm = () => {
    setEditorKey('question_essay' + uuid())
  }
  return (
    <div>
      {contextHolder}
      <LayoutQuestion
        defaultValues={defaultValues}
        buildRequest={buildRequest}
        fetchData={fetchData}
        actionType={actionType}
        open={open}
        onClose={onClose}
        id={id}
        useForm={useFormProp}
        type={type}
        hasQuestion={false}
        setDefaultQuestEditor={setDefaultEditorQuestContent}
        onClearForm={onClearForm}
      >
        {/* start:: question */}

        <div>
          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>Assignment Type</span>
            </label>
            <div className='sapp-flex-1 sapp-question-radio-group'>
              <HookFormRadioGroup
                direction='horizontal'
                separator={false}
                name='assignment_type'
                control={control}
                justify='start'
                gap={15}
                labelClass='fw-semibold fs-6'
                options={[
                  {
                    label: 'Text',
                    value: QUESTION_ASSIGNMENT_TYPE.TEXT,
                  },
                  {
                    label: 'Text & File',
                    value: QUESTION_ASSIGNMENT_TYPE.ALL,
                  },
                ]}
              />
            </div>
          </div>

          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>Response Option</span>
            </label>
            <div className='sapp-flex-1 sapp-question-radio-group'>
              <HookFormRadioGroup
                direction='horizontal'
                labelClass='fw-semibold fs-6'
                separator={false}
                name='response_option'
                control={control}
                justify='start'
                gap={15}
                options={[
                  {
                    label: 'Word',
                    value: QUESTION_RESPONSE_OPTION.WORD,
                  },
                  {
                    label: 'Excel',
                    value: QUESTION_RESPONSE_OPTION.SHEET,
                  }
                ]}
              />
            </div>
          </div>
          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className=''>Nội dung câu hỏi</span>
            </label>
            <HookFormEditor
              height={500}
              placeholder=' '
              name='question_content'
              control={control}
              math={true}
              className='w-100 fs-6'
              defaultValue={defaultEditorQuestContent}
              editorKey={editorKey}
            />
          </div>
          {/* start:: requirements*/}
          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>Requirements</span>
            </label>
            {!!requirements?.length && (
              <div className='border rounded border-gray-300'>
                <div className='p-5'>
                  {requirements?.map((requirement, i) => (
                    <div key={requirement.id}>
                      <div className='d-flex flex-stack w-100'>
                        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
                          <div className='me-5'>
                            <span className='text-gray-700 fw-bold fs-6 sapp-text-truncate-1 text-break'>
                              {getValues(`requirements.${i}.name`)}
                            </span>
                          </div>

                          <div className='d-flex align-items-center'>
                            <span className='me-3'>
                              <ButtonIconOnly
                                iconName={'notepad-edit'}
                                activeColor='primary'
                                onClick={() => {
                                  setTimeout(() => {
                                    handleAddNewOpen('requirement', requirement, i)
                                  })
                                }}
                              />
                            </span>
                            <div className='m-0'>
                              <ButtonIconOnly
                                iconName={'trash'}
                                activeColor='danger'
                                onClick={() => {
                                  setTimeout(() => {
                                    confirm({
                                      okButtonCaption: 'Yes',
                                      cancelButtonCaption: 'No',
                                      body: 'Bạn có chắc chắn muốn xóa không?',
                                      onClick: () => removeRequirement(i),
                                    })
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {requirements?.length - 1 !== i && (
                        <div className='separator separator-dashed my-3'></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {errors.requirements && <ErrorMessage>{errors.requirements.message}</ErrorMessage>}
            <Row className='mt-5'>
              <Col md={12}>
                <ButtonIcon
                  title={'Add more requirement'}
                  className='h-45px d-flex justify-content-start align-items-center mt-xl-0 mt-4'
                  customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 w-100'
                  type='button'
                  onClick={(e) => {
                    e?.target?.blur()
                    setTimeout(() => {
                      handleAddNewOpen('requirement')
                    })
                  }}
                >
                  <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
                </ButtonIcon>
              </Col>
              <Col></Col>
            </Row>
          </div>
          {/* end:: requirements*/}

          {/* start:: exhibits*/}
          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span>Exhibit</span>
            </label>
            {!!exhibits?.length && (
              <div className='border rounded border-gray-300 mb-5'>
                <div className='p-5'>
                  {exhibits?.map((exhibit, i) => (
                    <div key={exhibit.id}>
                      <div className='d-flex flex-stack w-100'>
                        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
                          <div className='me-5'>
                            <span className='text-gray-700 fw-bold fs-6 sapp-text-truncate-1 text-break'>
                              {getValues(`exhibits.${i}.name`)}
                            </span>
                          </div>

                          <div className='d-flex align-items-center'>
                            <div className='me-3'>
                              <ButtonIconOnly
                                iconName={'notepad-edit'}
                                activeColor='primary'
                                onClick={() => {
                                  setTimeout(() => {
                                    handleAddNewOpen('exhibit', exhibit, i)
                                  })
                                }}
                              />
                            </div>
                            <div className='m-0'>
                              <ButtonIconOnly
                                iconName={'trash'}
                                activeColor='danger'
                                onClick={() => {
                                  setTimeout(() => {
                                    confirm({
                                      okButtonCaption: 'Yes',
                                      cancelButtonCaption: 'No',
                                      body: 'Bạn có chắc chắn muốn xóa không?',
                                      onClick: () => removeExhibit(i),
                                    })
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {exhibits?.length - 1 !== i && (
                        <div className='separator separator-dashed my-3'></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Row className='mb-5'>
              <Col md={12}>
                <ButtonIcon
                  title={'Add more exhibit'}
                  className='h-45px d-flex justify-content-start align-items-center mt-xl-0 mt-4 w-100'
                  customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0'
                  type='button'
                  onClick={(e) => {
                    e?.target?.blur()
                    setTimeout(() => {
                      handleAddNewOpen('exhibit')
                    })
                  }}
                >
                  <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
                </ButtonIcon>
              </Col>
              <Col></Col>
            </Row>
          </div>
          {/* end:: exhibits*/}
        </div>

        {/* end:: question*/}
      </LayoutQuestion>

      {/* end:: extend question*/}
      <div>
        <SappDrawer
          rootClassName='sapp-question_essay-drawer'
          open={formAddQuestion.open}
          title={`${formAddQuestion.isEdit ? 'Edit' : 'Add More'} ${
            formAddQuestion.type === 'requirement' ? 'Requirement' : 'Exhibit'
          }`}
          cancelButtonCaption={'Cancel'}
          okButtonCaption={'Save'}
          handleSubmit={handleSubmitAddNew(onSubmitAddNew)}
          handleClose={handleAddNewClose}
          width='50%'
          confirmOnclose
        >
          <div className='mb-8'>
            <HookFormTextField
              label={`${formAddQuestion.type === 'requirement' ? 'Requirement' : 'Exhibit'} Name`}
              labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
              required
              className='sapp-h-45px fs-6'
              control={controlAddNew}
              name={`name` as const}
              placeholder={`${
                formAddQuestion.type === 'requirement' ? 'Requirement' : 'Exhibit'
              } name`}
              guideline={[
                'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, giới hạn 1000 ký tự',
              ]}
            ></HookFormTextField>
          </div>
          <div className='mb-8'>
            <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
              <span className='required'>{`${
                formAddQuestion.type === 'requirement' ? 'Requirement' : 'Exhibit'
              } Type`}</span>
            </label>
            <div className='mb-10'>
              <HookFormRadioGroup
                direction='horizontal'
                separator={false}
                name={`type` as const}
                control={controlAddNew}
                justify='start'
                gap={15}
                onChange={handleChangeAddNewType}
                labelClass='fw-semibold fs-6'
                options={[
                  {
                    label: 'Text',
                    value: 'TEXT',
                  },
                  {
                    label: 'Files',
                    value: 'FILE',
                  },
                ]}
              />
            </div>
            <div>
              {typeFormAddNew === 'TEXT' && (
                <div>
                  <HookFormEditor
                    label='Description'
                    labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                    required
                    height={400}
                    name='description'
                    control={controlAddNew}
                    className='w-100 fs-6'
                    math={true}
                    guideline={[
                      'Cho phép nhập chữ hoa, thường, chữ số, ký tự đặc biệt, nhập text, tạo table  theo các format trong texteditor',
                      'Cho phép chèn và upload link, ảnh, video, file ',
                    ]}
                    defaultValue={defaultEditor}
                  />
                </div>
              )}
              {typeFormAddNew === 'FILE' && (
                <div>
                  <label className='d-flex align-items-center fs-6 fw-bold mb-5'>
                    <span className='required'>Attachments</span>
                  </label>
                  <UploadMulti
                    fileList={filesFormAddNew}
                    setFileList={setFilesFormAddNew}
                    error={errorsAddNewForm.files}
                    guideline={[
                      'Cho phép upload file PDF',
                      'Kích thước tập tin tải lên tối đa là 20mb.',
                    ]}
                    resourceLocation={RESOURCE_LOCATION.question}
                  />
                </div>
              )}
            </div>
          </div>
        </SappDrawer>
      </div>
      {/* end:: extend question*/}
    </div>
  )
}
export default EssayQuestion
