export const LANG_SIGNIN = {
  signIn: 'Sign In',
  SAPPAcademy: 'SAPP Academy Operations',
  signInGoogle: 'Sign in with Google',
  signInApple: 'Sign in with Apple',
  orWithEmail: 'Or with email',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot Password ?',
  notMember: 'Not a Member yet?',
  signUp: 'Sign up',
  english: 'English',
  japanese: 'Japanese',
  german: 'German',
  spanish: 'Spanish',
  french: 'French',
  terms: 'Terms',
  plans: 'Plans',
  contactUs: 'Contact Us',
}

export const LANG_SIDEBAR = {
  lms: 'LMS',
  dashboard: 'Dashboard',
  news: 'Tin tức',
  listNews: 'Danh sách',
  createNews: 'Tạo tin tức',
  courses: 'Courses',
  test: 'Test',
  caseStudy: 'Case Study',
  resourcesBanks: 'Kho học liệu',
  topicList: 'Item Set',
  topic: 'Item Set Detail',
  questionBank: 'Question Banks',
  dictionary: 'Dictionary',
  supportCenter: 'Trung tâm hỗ trợ',
  supportOverview: 'Báo cáo',
  supportTickets: 'Tickets',
  supportFAQs: 'FAQs',
  notifications: 'Notifications',
  sendNotifications: 'Gửi thông báo',
  listNotifications: 'Notification List',
  listGroups: 'Group List',
  staffs: 'Staffs',
  listStaffs: 'List Staffs',
  createStaffs: 'Create Staffs',
  students: 'Students',
  listStudents: 'List Students',
  createStudents: 'Create Student',
  teachers: 'Teachers',
  listTeacher: 'List Teachers',
  createTeacher: 'Create Teachers',
  admin: 'Admin',
  roles: 'Roles',
  permissions: 'Permissions',
  setting: 'Setting',
  pro: 'Pro',
  myProfile: 'My Profile',
  mode: 'Mode',
  light: 'Light',
  dark: 'Dark',
  system: 'System',
  signOut: 'Sign Out',
  importLog: 'Import Report',
  createStory: 'Case Study Detail',
  listStories: 'List Case Study',
  listCertificates: 'Certificate List',
  commingSooon: 'Comming soon',
  addItemSet: 'Add Item Set'
}

export const LANG_HEADER = {
  search: 'Search...',
  recentlySearched: 'Recently Searched:',
  quickLinks: 'Quick Links',
  pendingTasks: 'pending tasks',
  accounting: 'Accounting',
  eCommerce: 'eCommerce',
  administration: 'Administration',
  console: 'Console',
  projects: 'Projects',
  customers: 'Customers',
  latestCases: 'Latest cases',
  viewAll: 'View All',
  myApps: 'My Apps',
  payments: 'Payments',
  createInvoice: 'Create Invoice',
  createPayment: 'Create Payment',
  generateBill: 'Generate Bill',
  settings: 'Settings',
  typeMessage: 'Type a message',
  send: 'Send',
  you: 'You',
  contacts: 'Contacts',
  addContact: ' Add Contact',
  inviteContacts: 'Invite Contacts',
  aws: 'AWS',
  angularJS: 'AngularJS',
  atica: 'Atica',
  music: 'Music',
  codeigniter: 'Codeigniter',
  bootstrap: 'Bootstrap',
  gtm: 'GTM',
  disqus: 'Disqus',
  dribble: 'Dribble',
  playStore: 'Play Store',
  podcasts: 'Podcasts',
  figma: 'Figma',
  github: 'Github',
  gitlab: 'Gitlab',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
}

export const LANG_DASHBOARD = {
  organicSessions: 'Organic Sessions',
  quickActions: 'Quick Actions',
  newTicket: 'New Ticket',
  newCustomer: 'New Customer',
  newContact: 'New Contact',
  generateReports: 'Generate Reports',
  warephaseStats: 'Warephase stats',
  socialVisitors: 'social visitors',
  PDFReport: 'PDF Report',
  socialNetworks: 'Visits by Social Networks',
  dribbble: 'Dribbble',
  community: 'Community',
  linkedIn: ' Linked In',
  socialMedia: 'Social Media',
  slack: 'Slack',
  messanger: 'Messanger',
  youTube: 'YouTube',
  videoChannel: 'Video Channel',
  instagram: 'Instagram',
  socialNetwork: 'Social Network',
  stockReport: 'Stock Report',
  total: 'Total',
  itemsStock: 'Items in the Stock',
  category: 'Category',
  status: 'Status',
  viewStock: 'View Stock',
  item: 'Item',
  productID: 'Product ID',
  dateAdded: 'Date Added',
  price: 'Price',
  qty: 'Qty',
  countriesShare: 'countries share',
  visitsCountry: 'Visits by Country',
  visits: 'visits',
  unitedStates: 'United States',
  directLink: 'Direct link clicks',
  brasil: 'Brasil',
  allChannels: ' All Social Channels',
  turkey: 'Turkey',
  mailchimpCampaigns: 'Mailchimp Campaigns',
  france: 'France',
  impactRadius: 'Impact Radius visits',
  india: 'India',
  manySources: 'Many Sources',
  sweden: 'Sweden',
  socialVisits: 'Social Network Visits',
  facebook: 'Facebook',
  departments: 'Departments',
  performance: 'Performance & achievements',
}

export const LANG_NEWS = {
  searchNews: 'Search news...',
  submit: 'Submit',
  selected: 'Selected',
  deleteSelected: 'Delete Selected',
  export: 'Export',
  addNews: 'Add news',
  thumbnail: 'Thumbnail',
  selectPublishing: 'Select publishing date and time',
  productDetails: 'Product Details',
  guidelineProduct: 'Add product to a category.',
  createCategory: 'Create new category',
  tags: 'Tags',
  addTags: 'Add tags to a product.',
  weeklySales: 'Weekly Sales',
  notData: 'No data available. Sales data will begin capturing once product has been published.',
  productTemplate: 'Product Template',
  general: 'General',
  productName: 'Product Name',
  descriptionProductName: 'A product name is required and recommended to be unique.',
  description: 'Description',
  media: 'Media',
  dropFiles: 'Drop files here or click to upload.',
  upload: 'Upload up to 10 files',
  descriptionUpload: 'Set the product media gallery.',
  pricing: 'Pricing',
  basePrice: 'Base Price',
  descriptionPrice: 'Set the product price.',
  discountType: 'Discount Type',
  noDiscount: 'No Discount',
  percentage: 'Percentage %',
  fixedPrice: 'Fixed Price',
  discountPercentage: 'Set Discount Percentage',
  descriptionDiscountPercentage: 'Set a percentage discount to be applied on this product.',
  discountedPrice: 'Fixed Discounted Price',
  descriptionDiscountedPrice:
    'Set the discounted product price. The product will be reduced at the determined fixed price',
  taxClassName: 'Tax className',
  VATAmount: 'VAT Amount (%)',
  descriptionVATAmount: 'Set the product VAT about.',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',
}

export const LANG_COURSES = {
  addCourses: 'Create Course',
  code: 'CODE',
  courseName: 'COURSE NAME',
  subjects: 'SUBJECTS',
  action: 'ACTION',
}
export const LANG_CLASSES = {
  addClass: 'Create Class',
  importClass: 'Import Class',
  className: 'Class Name',
}

export const LANG_ENTRANCE_TEST = {
  addEntranceTest: 'Create Entrance Test',
  entranceTestName: 'Entrance Test Name',
  addQuestion: 'Add Question',
}

export const LANG_COURSE_CATEGORY = {
  categoryName: 'CATEGORY NAME',
  description: 'DESCRIPTION',
  action: 'ACTION',
}

export const LANG_RESOURCEBANK = {
  stt: 'STT',
  fileName: 'Tên file',
  subject: 'SUBJECT',
  section: 'SECTION',
  subsection: 'SUBSECTION',
  unit: 'UNIT',
  activities: 'ACTIVITIES',
  typeFile: 'LOẠI FILE',
  dayUpload: 'NGÀY UPLOAD',
}

export const LANG_SUPPORTCENTER = {
  helpYou: 'How Can We Help You?',
  askQuestion: 'Ask a question',
  createTicket: 'Create Ticket',
  pleaseCheck: 'If you need more info, please check',
  supportGuidelines: 'Support Guidelines.',
  placeHolerSubject: 'Enter your ticket subject',
  product: 'Product',
  assign: 'Assign',
  dueDate: 'Due Date',
  placeholderDescription: 'Type your ticket description',
  attachments: 'Attachments',
  allowNotifications: 'Allow Notifications by Phone or Email',
  phone: 'Phone',
}

export const BUTTON_TEXT = {
  SAVE: 'Save',
  SEARCH: 'Search',
}

export const LANG_PLACEHOLDER = {
  FULLNAME: 'Full Name',
}

export const LANG_CERTIFICATES = {
  addTemplate: 'Create Template',
  importCertificate: 'Import Certificate',
  CertificatesName: 'Certificate Name',
}
