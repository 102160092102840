import React, {Dispatch, SetStateAction} from 'react'
import SappModal from './SappModal'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'

interface IProps {
  openPreview: boolean
  setOpenPreview: Dispatch<SetStateAction<boolean>>
  avatarResize: string | undefined
  title?: string
}

const ModalPreviewImage = ({openPreview, setOpenPreview, avatarResize, title}: IProps) => {
  return (
    <SappModal
      cancelButtonCaption='Cancel'
      okButtonCaption='OK'
      open={openPreview}
      title={title ?? 'Preview Avatar'}
      handleClose={() => setOpenPreview(false)}
      centered
      classNameBody='d-flex justify-content-center w-100 h-100 sapp-p-body-content sapp-overflow-y'
      showFooter={false}
      contentClassName='sapp-modal-content'
      dialogClassName='sapp-modal-dialog'
      showClassHeightBody={false}
      classNameContent='sapp-p-body-content'
    >
      <div
        className='image-input image-input-outline image-input-placeholder w-100 h-100'
        style={{overflowY: 'hidden'}}
      >
        <img
          className='image-input-wrapper w-100 h-100 sapp-image-input-wrapper'
          src={avatarResize ?? avatar}
          alt='preview'
        />
      </div>
    </SappModal>
  )
}

export default ModalPreviewImage
