import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { PageLink } from 'src/constants'
import { KTIcon } from 'src/_metronic/helpers/components/KTIcon'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_STORY_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  searchTerm: string | null
  sortSortBy: string | null
  fromDate: Date | null
  toDate: Date | null
}

const StoryListToolbar = ({ searchTerm, sortSortBy, fromDate, toDate }: IProps) => {
  const { profileMe } = useUserContext()
  const allowRenderCreateStory = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_STORY_GR.CREATE_STORY) || role.code === CODE_ADMIN.SUPER_ADMIN);
  return (
    <div className='sapp-height-list--grouping text-end row justify-content-end'>
      {allowRenderCreateStory && (
         <ButtonIcon
         title='Create'
         className='sapp-height-min--content w-100 w-sm-auto'
         link={PageLink.CREATE_STORY}
       >
         <KTIcon iconName='plus' className='fs-2' />
       </ButtonIcon>
      )}
    </div>
  )
}

export { StoryListToolbar }

