import {Link} from 'react-router-dom'
import {KTIcon} from 'src/_metronic/helpers'

interface IProps {
  onClick?: (e?: any) => void
  type?: 'button' | 'submit' | 'reset' | undefined
  link?: string | undefined
  fs?: string
  activeColor?:
    | 'primary'
    | 'danger'
    | 'success'
    | 'info'
    | 'warning'
    | 'secondary'
    | 'dark'
    | 'light'
  iconName: string
  iconType?: 'duotone' | 'solid' | 'outline'
  active?: boolean
  bg?: string
  disabled?: boolean
  iconGrayColor?: string
  disableBackgoundHover?: boolean
}

const ButtonIconOnly = ({
  onClick,
  type = 'button',
  link,
  fs = '1',
  activeColor = 'primary',
  iconName,
  iconType = 'outline',
  active,
  bg = 'white',
  disabled,
  iconGrayColor='600',
  disableBackgoundHover=false
}: IProps) => {
  if (link) {
    return (
      <Link
        to={link}
        className={`${
          active ? 'active' : ''
        } btn btn-icon btn-icon-gray-500 btn-active-light btn-active-color-${activeColor} h-35px w-lg-40px h-lg-40px bg-${bg}`}
        onClick={onClick}
      >
        <KTIcon
          iconType={iconType}
          iconName={iconName}
          className={`fs-${fs} button-${activeColor}`}
        />
      </Link>
    )
  }
  return (
    <button
      disabled={disabled}
      className={`${
        active ? 'active' : ''
      } btn btn-icon btn-icon-gray-${iconGrayColor} ${!disableBackgoundHover && "btn-active-light"} btn-active-color-${activeColor} h-35px w-lg-40px h-lg-40px bg-${bg}`}
      onClick={onClick}
      type={type}
    >
      <KTIcon
        iconType={iconType}
        iconName={iconName}
        className={`fs-${fs} button-${activeColor}`}
      />
    </button>
  )
}

export default ButtonIconOnly
