import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import TableListCategory from 'src/components/courses/categories/TableListCategory'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs, Role } from 'src/type'
import { ICourseCategoies } from 'src/type/courses'
import { cleanParamsAPI, formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { KTIcon } from 'src/_metronic/helpers'
import { z } from 'zod'
import useChecked from 'src/hooks/use-checked'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_COURSE_CATEGORY_GR } from 'src/constants/permission'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.COURSES}`,
    title: 'Course Category',
  },
]

const fieldNames = ['name', 'fromDate', 'toDate']

const initialValues: any = {
  name: '',
  fromDate: '',
  toDate: '',
}

const CourseCategory = () => {
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [openCourse, setOpenCourse] = useState(false)
  const [dateField, setDateField] = useState<string>('updated_at')
  const [loading, setLoading] = useState(true)
  const { search } = useLocation()
  const navigate = useNavigate()
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    courseCategory?.course_categories
  )

  const { profileMe } = useUserContext()
  const allowRenderAddCategory = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_COURSE_CATEGORY_GR .CREATE_CATEGORY) || role.code === CODE_ADMIN.SUPER_ADMIN);

  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const [idItem, setIdItem] = useState('')

  const getParams = (name: string, fromDate: any, toDate: any, dateField: string) => ({
    name,
    fromDate: (fromDate),
    toDate: (toDate),
    dateField,
  })

  const validationSchema = z.object({
    name: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name') ?? '',
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  const fetchCourseCategory = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CoursesAPI.getCategory({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setCourseCategory(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    toggleCheckAll(false)

    setLoading(true)
    setDateField('')
    navigate(PageLink.COURSE_CATEGORY)

    fetchCourseCategory(1, 10)
  }

  const checkFromDateToDate = queryParams.name || queryParams.fromDate || queryParams.toDate

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'));
    const dateInfoToDate = getDateInfo(getValues('toDate'));
    const cleanedParams = cleanParamsAPI(
      getParams(getValues('name').trimStart().trimEnd() ?? '', getValues('fromDate') ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day) : '', getValues('toDate') ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day) : '', 'updated_at')
    )

    setLoading(true)

    fetchCourseCategory(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate);
  const dateQueryToDate = getDateInfo(queryParams.toDate);

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.fromDate ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day) : '',
      queryParams.toDate ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day) : '',
      checkFromDateToDate ? 'updated_at' : 'created_at'
    )
  )

  const handleOpenCourse = () => {
    setOpenCourse(true)
    setIdItem('')
  }

  return (
    <PageLayouts pageTitle='Course Category' breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='card-header border-0 pt-10 px-10'>
          <div className='container sapp-height-min--content'>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4 ps-0'>
                <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                  <HookFormTextField control={control} name='name' placeholder='Search' defaultValue={queryParams?.name} onSubmit={onSubmit}/>
                </div>
              </div>
              {/* end:: Search */}

              <div className='col-xl-3 col-sm-4'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  className='sapp-h-datimepicker'
                  defaultValue={queryParams?.fromDate}
                />
              </div>
              <div className='col-xl-3 col-sm-4 pe-0'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  className='sapp-h-datimepicker'
                  defaultValue={queryParams?.toDate}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='card-header border-0 pt-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={onSubmit}
                  resetClick={handleReset}
                  disabled={loading}
                />
              </div>
              <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
                {allowRenderAddCategory && (
                  <div className='card-toolbar justify-content-end d-flex my-0'>
                  {/* start:: button open modal */}
                  <ButtonIcon
                    title='Add Category'
                    className='sapp-height-min--content'
                    onClick={handleOpenCourse}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </ButtonIcon>
                  {/* end:: button open modal */}
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* start:: table */}
        <TableListCategory
          courseCategory={courseCategory}
          loading={loading}
          setLoading={setLoading}
          fetchCourseCategory={fetchCourseCategory}
          filterParams={filterParams}
          setOpenCourse={setOpenCourse}
          open={openCourse}
          idItem={idItem}
          setIdItem={setIdItem}
          queryParams={queryParams}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        {/* end:: table */}
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(CourseCategory)
