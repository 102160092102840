import React from 'react'
import { PageLink } from 'src/constants'

const Users = () => {
  return (
    <div className='card'>
      <div className='card-header card-header-stretch'>
        <div className='card-title'>
          <h3>Referred Users</h3>
        </div>

        <div className='card-toolbar'>
          <ul
            className='nav nav-stretch fs-5 fw-semibold nav-line-tabs border-transparent'
            role='tablist'
          >
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_tab_1'
                className='nav-link text-active-gray-800 me-4 active'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_referrals_1'
              >
                Month
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_tab_2'
                className='nav-link text-active-gray-800 me-4'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_referrals_2'
              >
                2022
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_tab_3'
                className='nav-link text-active-gray-800'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_referrals_3'
              >
                2021
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div id='kt_referred_users_tab_content' className='tab-content'>
        <div
          id='kt_referrals_1'
          className='card-body p-0 tab-pane fade show active'
          role='tabpanel'
        >
          <div className='table-responsive'>
            <table className='table align-middle gy-6'>
              <thead className='border-bottom border-gray-200 fs-6 fw-bold bg-lighten'>
                <tr>
                  <th className='min-w-125px ps-9'>Order ID</th>
                  <th className='min-w-125px px-0'>User</th>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Bonus</th>
                  <th className='min-w-125px ps-0'>Profit</th>
                </tr>
              </thead>

              <tbody className='fs-6 fw-semibold text-gray-600'>
                <tr>
                  <td className='ps-9'>678935899</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Marcus Harris
                    </a>
                  </td>
                  <td>Nov 24, 2020</td>
                  <td>26%</td>
                  <td className='text-success'>$1,200.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>578433345</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Maria Garcia
                    </a>
                  </td>
                  <td>Aug 10, 2020</td>
                  <td>35%</td>
                  <td className='text-success'>$2,400.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>678935899</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Robert Smith
                    </a>
                  </td>
                  <td>May 06, 2020</td>
                  <td>18%</td>
                  <td className='text-success'>$940.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>098669322</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Williams Brown
                    </a>
                  </td>
                  <td>Apr 30, 2020</td>
                  <td>43%</td>
                  <td className='text-success'>$200.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>245899092</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Paul Johnson
                    </a>
                  </td>
                  <td>Feb 29, 2020</td>
                  <td>21%</td>
                  <td className='text-success'>$380.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>505432578</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Sarah Jones
                    </a>
                  </td>
                  <td>Jan 08, 2020</td>
                  <td>47%</td>
                  <td className='text-success'>$2,050.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>256899235</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Juan Carlos
                    </a>
                  </td>
                  <td>Jan 02, 2020</td>
                  <td>35%</td>
                  <td className='text-success'>$820.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id='kt_referrals_2' className='card-body p-0 tab-pane fade' role='tabpanel'>
          <div className='table-responsive'>
            <table className='table align-middle gy-6'>
              <thead className='border-bottom border-gray-200 fs-6 fw-bold bg-lighten'>
                <tr>
                  <th className='min-w-125px ps-9'>Order ID</th>
                  <th className='min-w-125px px-0'>User</th>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Bonus</th>
                  <th className='min-w-125px ps-0'>Profit</th>
                </tr>
              </thead>

              <tbody className='fs-6 fw-semibold text-gray-600'>
                <tr>
                  <td className='ps-9'>256899235</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Juan Carlos
                    </a>
                  </td>
                  <td>Jan 02, 2020</td>
                  <td>35%</td>
                  <td className='text-success'>$820.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>245899092</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Paul Johnson
                    </a>
                  </td>
                  <td>Feb 29, 2020</td>
                  <td>21%</td>
                  <td className='text-success'>$380.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>505432578</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Sarah Jones
                    </a>
                  </td>
                  <td>Jan 08, 2020</td>
                  <td>47%</td>
                  <td className='text-success'>$2,050.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>678935899</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Robert Smith
                    </a>
                  </td>
                  <td>May 06, 2020</td>
                  <td>18%</td>
                  <td className='text-success'>$940.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>578433345</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Maria Garcia
                    </a>
                  </td>
                  <td>Aug 10, 2020</td>
                  <td>35%</td>
                  <td className='text-success'>$2,400.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>098669322</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Williams Brown
                    </a>
                  </td>
                  <td>Apr 30, 2020</td>
                  <td>43%</td>
                  <td className='text-success'>$200.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>678935899</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Marcus Harris
                    </a>
                  </td>
                  <td>Nov 24, 2020</td>
                  <td>26%</td>
                  <td className='text-success'>$1,200.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id='kt_referrals_3' className='card-body p-0 tab-pane fade' role='tabpanel'>
          <div className='table-responsive'>
            <table className='table align-middle gy-6'>
              <thead className='border-bottom border-gray-200 fs-6 fw-bold bg-lighten'>
                <tr>
                  <th className='min-w-125px ps-9'>Order ID</th>
                  <th className='min-w-125px px-0'>User</th>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Bonus</th>
                  <th className='min-w-125px ps-0'>Profit</th>
                </tr>
              </thead>

              <tbody className='fs-6 fw-semibold text-gray-600'>
                <tr>
                  <td className='ps-9'>578433345</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Maria Garcia
                    </a>
                  </td>
                  <td>Aug 10, 2020</td>
                  <td>35%</td>
                  <td className='text-success'>$2,400.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>678935899</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Robert Smith
                    </a>
                  </td>
                  <td>May 06, 2020</td>
                  <td>18%</td>
                  <td className='text-success'>$940.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>256899235</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Juan Carlos
                    </a>
                  </td>
                  <td>Jan 02, 2020</td>
                  <td>35%</td>
                  <td className='text-success'>$820.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>245899092</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Paul Johnson
                    </a>
                  </td>
                  <td>Feb 29, 2020</td>
                  <td>21%</td>
                  <td className='text-success'>$380.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>505432578</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Sarah Jones
                    </a>
                  </td>
                  <td>Jan 08, 2020</td>
                  <td>47%</td>
                  <td className='text-success'>$2,050.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>098669322</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Williams Brown
                    </a>
                  </td>
                  <td>Apr 30, 2020</td>
                  <td>43%</td>
                  <td className='text-success'>$200.00</td>
                </tr>
                <tr>
                  <td className='ps-9'>678935899</td>
                  <td className='ps-0'>
                    <a href={PageLink.AUTH_LOGIN} className='text-gray-600 text-hover-primary'>
                      Marcus Harris
                    </a>
                  </td>
                  <td>Nov 24, 2020</td>
                  <td>26%</td>
                  <td className='text-success'>$1,200.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users