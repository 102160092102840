import {FC} from 'react'

type Props = {
  status?: 'ACTIVE' | 'BLOCKED' | 'INACTIVE' | 'MERGED'
  className?: string
}

enum EBadge {
  INACTIVE = 'dark',
  ACTIVE = 'success',
  BLOCKED = 'danger',
}

const UserStatusCell: FC<Props> = ({status, className}) => (
  <div
    className={`badge badge-light-${
      status === 'ACTIVE' ? EBadge.ACTIVE : status === 'INACTIVE' ? EBadge.INACTIVE : EBadge.BLOCKED
    } justify-content-center ${className ? `${className}` : 'min-w-55px'}`}
  >
    {status === 'ACTIVE' ? 'Active' : status === 'INACTIVE' ? 'Inactive' : 'Blocked'}
  </div>
)

export default UserStatusCell
