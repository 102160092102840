import {zodResolver} from '@hookform/resolvers/zod'
import {Skeleton} from 'antd'
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import {CoursesAPI} from 'src/apis/courses'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {IQuestion} from 'src/components/question-bank/shared/interfaces'
import {
  PageLink,
  VALIDATE_FILED_MAX_LENGTH,
  VALIDATE_NUMBER,
  VALIDATE_PASS_POINT,
  VALIDATE_QUESTIONS,
  VALIDATION_FILED,
  VALIDATION_MIN_EQUAL,
} from 'src/constants'
import {useCourseContext} from 'src/context/CourseProvider'
import {useConfirm} from 'src/hooks/use-confirm'
import {z} from 'zod'
import {LIST_TYPE, TEST_TYPE} from '../shared/Interfaces'
import {
  ATTEMPT,
  GRADED_ASSIGNMENT,
  GRADING_PREFERENCE,
  PASS_POINT,
  TEST_MODE,
  TYPE_OF_TEST,
} from '../shared/consts'
import '../shared/styles/Course.scss'
import ChooseQuestionsModal from './ChooseQuestionsModal'
import './TestComponent.scss'
import QuizQuestionTable from './TestQuestionTable'
import SappLabel from 'src/components/base/label/SappLabel'
type IProps = {
  type: TEST_TYPE
  id: string
  testFor: string
  customOnSubmit?: any
  customOnCancel?: any
  forTab?: boolean
  oldData?: any
  buttonSaveTitle?: string
  editId?: string
  CancelSubmitBtnClass?: string
  editOnlyName?: boolean
}

interface IQuiz {
  name: string
  type: string
  testMode: string
  gradedAssignment: string
  time: {
    hours: string
    mins: string
  }
  gradingPreference: string
  passPoint: {
    type: string
    value: string
  }
  attempt: {
    type: string
    value: string
  }
  number_of_multiple_choice_questions: string
  number_of_essay_questions: string
  // emptyMultiple?: any
  emptyConstructed?: any
  quiz_type?: string
  get_point_questions_multi?: any
  get_point_questions_constructed?: any
}
const defaultValues = {
  name: '',
  type: TYPE_OF_TEST.multipleChoice.value,
  testMode: TEST_MODE.tutor.value,
  gradedAssignment: GRADED_ASSIGNMENT.no.value,
  time: {
    hours: '',
    mins: '',
  },
  gradingPreference: GRADING_PREFERENCE.each.value,
  passPoint: {
    type: PASS_POINT.auto.value,
    value: '',
  },
  attempt: {
    type: ATTEMPT.unlimited.value,
    value: '',
  },
  number_of_multiple_choice_questions: '',
  number_of_essay_questions: '',
}
/**
 *
 * @description Form quiz
 * @param {IProps} {
 *   type,
 *   id,
 *   testFor,
 *   customOnSubmit,
 *   forTab,
 *   customOnCancel,
 *   oldData,
 *   buttonSaveTitle = 'Save',
 *   editId,
 * }
 * @return {*}
 */
const TestComponent = ({
  type,
  id,
  testFor,
  customOnSubmit,
  forTab,
  customOnCancel,
  oldData,
  buttonSaveTitle = 'Save',
  editId,
  CancelSubmitBtnClass = 'justify-content-end d-flex',
  editOnlyName,
}: IProps) => {
  const navigate = useNavigate()
  const {confirm, contextHolder} = useConfirm()
  const params = useParams()
  const {getCourseDetail} = useCourseContext()
  const [openChooseQuestion, setOpenChooseQuestion] = useState<boolean | LIST_TYPE>(false)
  const [questionsMultiple, setQuestionsMultiple] = useState<IQuestion[]>([])
  const [questionsConstructed, setQuestionsConstructed] = useState<IQuestion[]>([])
  const [totalQuestionsMultiple, setTotalQuestionsMultiple] = useState<number>(0)
  const [totalQuestionsConstructed, setTotalQuestionsConstructed] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)
  const [skeleton, setSkeleton] = useState<boolean>(false)
  const [editAble, setEditAble] = useState<boolean>(true)
  const storeFormData = useRef<
    IQuiz & {multiple_choice_questions?: any[]; constructed_questions?: any[]}
  >({...defaultValues})

  const addQuestioned = useRef<{
    multiple: boolean
    constructed: boolean
  }>({multiple: false, constructed: false})

  const validationSchema = z
    .object({
      name: !forTab
        ? z
            .string({required_error: VALIDATION_FILED})
            .trim()
            .min(1, VALIDATION_FILED)
            .max(1000, VALIDATE_FILED_MAX_LENGTH('Test name', 1000))
        : z.string().max(1000, VALIDATE_FILED_MAX_LENGTH('Test name', 1000)).optional(),
      type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
      testMode: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
      gradedAssignment: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED),
      time: z.object({
        hours: z
          .string()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .refine(
            (val) => {
              if (
                !Number(val) &&
                !Number(getValues('time.mins')) &&
                getValues('testMode') === TEST_MODE.timed.value
              ) {
                return false
              }
              return true
            },
            {message: VALIDATION_FILED}
          )
          .optional(),
        mins: z
          .string()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .refine((val) => {
            if (
              !Number(val) &&
              !Number(getValues('time.hours')) &&
              getValues('testMode') === TEST_MODE.timed.value
            ) {
              return false
            }
            return true
          }, VALIDATION_FILED)
          .optional(),
      }),
      gradingPreference: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED),
      passPoint: z.object({
        type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
        value: z
          .string()
          .trim()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .superRefine((arg: any, ctx) => {
            if (getValues('passPoint.type') === PASS_POINT.manual.value) {
              if (!Number(arg) || Number(arg) > 100) {
                ctx.addIssue({
                  message: VALIDATE_PASS_POINT,
                  code: 'custom',
                })
              }
            }
            return true
          })
          .optional(),
      }),
      attempt: z.object({
        type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
        value: z
          .string()
          .trim()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .refine(
            (val) => {
              if (getValues('attempt.type') === ATTEMPT.limited.value && Number(val) < 1) {
                return false
              }
              return true
            },
            {message: VALIDATION_MIN_EQUAL(1)}
          )
          .optional(),
      }),
      number_of_multiple_choice_questions: z
        .string()
        .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
        .superRefine((arg: any, ctx) => {
          if (!Number(arg) && getValues('type') === TYPE_OF_TEST.mix.value) {
            ctx.addIssue({
              message: VALIDATE_QUESTIONS,
              code: 'custom',
            })
          }
          if (
            getValues('type') === TYPE_OF_TEST.mix.value &&
            questionsMultiple.length > 0 &&
            questionsMultiple.length !== (Number(arg) || 0)
          ) {
            ctx.addIssue({
              message: 'The number of questions and the number in the list are not the same',
              code: 'custom',
            })
          }
        })
        .optional(),
      number_of_essay_questions: z
        .string()
        .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
        .superRefine((arg: any, ctx) => {
          if (!Number(arg) && getValues('type') === TYPE_OF_TEST.mix.value) {
            ctx.addIssue({
              message: VALIDATE_QUESTIONS,
              code: 'custom',
            })
          }
          if (
            getValues('type') === TYPE_OF_TEST.mix.value &&
            questionsConstructed.length > 0 &&
            questionsConstructed.length !== (Number(arg) || 0)
          ) {
            ctx.addIssue({
              message: 'The number of questions and the number in the list are not the same',
              code: 'custom',
            })
          }
        })
        .optional(),
      quiz_type: z.string().optional(),
    })
    // .superRefine((arg: any, ctx) => {
    //   if (
    //     (getValues('type') === TYPE_OF_TEST.multipleChoice.value ||
    //       getValues('type') === TYPE_OF_TEST.mix.value) &&
    //     (!questionsMultiple || questionsMultiple.length <= 0)
    //   ) {
    //     ctx.addIssue({
    //       message: 'Multiple Question is required',
    //       code: 'custom',
    //       path: ['emptyMultiple'],
    //     })
    //   }
    //   if (
    //     (getValues('type') === TYPE_OF_TEST.constructed.value ||
    //       getValues('type') === TYPE_OF_TEST.mix.value) &&
    //     (!questionsConstructed || questionsConstructed.length <= 0)
    //   ) {
    //     ctx.addIssue({
    //       message: 'Constructed Question is required',
    //       code: 'custom',
    //       path: ['emptyConstructed'],
    //     })
    //   }
    // })

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    trigger,
    clearErrors,
    setError,
    formState: {errors, isSubmitted},
  } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const watchType = watch('type')
  const watchPassPoint = watch('passPoint')
  const watchAttempt = watch('attempt')
  const watchTestMode = watch('testMode')
  const watchNumberOfEssayQuestions = watch('number_of_essay_questions')
  const watchNumberOfMultipleChoiceQuestions = watch('number_of_multiple_choice_questions')
  const watchQuizType = watch('quiz_type')

  /**
   * @description Lấy data từ API set vào form
   * @param {*} data
   * @return {*}  {typeof defaultValues}
   */
  useLayoutEffect(() => {
    if (editId) {
      setSkeleton(true)
      ;(async () => {
        try {
          const response = await CoursesAPI.getQuizById(editId)
          if (!response.success) {
            throw new Error('Not found!')
          }
          setTotalQuestionsMultiple(response?.data?.multiple_choice_multiplier)
          setTotalQuestionsConstructed(response?.data?.essay_multiplier)
          const formData = convertResultToFormValues(response.data)
          Object.entries(formData)?.forEach(([key, value]) => {
            const customSetValue = setValue as any
            customSetValue(key, value)
            storeFormData.current[key as keyof IQuiz] = value
          })

          storeFormData.current['constructed_questions'] = response.data.constructed_questions
          storeFormData.current['multiple_choice_questions'] =
            response.data.multiple_choice_questions
          if (response.data.status === 'LOCKED') {
            setEditAble(false)
          } else {
            setEditAble(true)
          }
        } catch (error: any) {
          if (error?.response?.status === 422) {
            toast.error('Quiz not found!')
          }
          navigate(`${PageLink.COURSE_FULL}/${params.courseId}`)
          if (params?.course_id) {
            getCourseDetail(params?.course_id)
          }
        } finally {
          setSkeleton(false)
        }
      })()
    } else {
      setValue('quiz_type', type.toUpperCase())
    }
    return () => {
      setQuestionsMultiple([])
      setQuestionsConstructed([])
      reset(defaultValues)
    }
  }, [params, editId])
  useEffect(() => {
    if (oldData) {
      setTotalQuestionsMultiple(oldData?.multiple_choice_multiplier)
      setTotalQuestionsConstructed(oldData?.essay_multiplier)
      const formData = convertResultToFormValues(oldData)
      Object.entries(formData)?.forEach(([key, value]) => {
        const customSetValue = setValue as any
        customSetValue(key, value)
        storeFormData.current[key as keyof IQuiz] = value
      })
      storeFormData.current['constructed_questions'] = oldData.constructed_questions
      storeFormData.current['multiple_choice_questions'] = oldData.multiple_choice_questions
      if (oldData.status === 'LOCKED') {
        setEditAble(false)
      } else {
        setEditAble(true)
      }
    }
  }, [oldData])
  useEffect(() => {
    if (isSubmitted) {
      trigger('number_of_multiple_choice_questions')
      // trigger('emptyMultiple')
    }
  }, [questionsMultiple])

  useEffect(() => {
    if (isSubmitted) {
      trigger('number_of_essay_questions')
      trigger('emptyConstructed')
    }
  }, [questionsConstructed])
  /**
   * @description Chuyển đổi data lấy từ API thành form data
   * @param {*} data
   * @return {*}  {typeof defaultValues}
   */
  const convertResultToFormValues = (data: any): IQuiz => {
    setQuestionsMultiple(data.multiple_choice_questions || [])
    setQuestionsConstructed(data.constructed_questions || [])
    return {
      name: data.name,
      type: data.quiz_question_type,
      testMode: data.is_tutor ? TEST_MODE.tutor.value : TEST_MODE.timed.value,
      gradedAssignment: data.is_graded ? GRADED_ASSIGNMENT.yes.value : GRADED_ASSIGNMENT.no.value,
      time: {
        hours: Math.floor(data.quiz_timed / 60)?.toString(),
        mins: (data.quiz_timed % 60)?.toString(),
      },
      gradingPreference:
        data.grading_preference === 'AFTER_EACH_QUESTION'
          ? GRADING_PREFERENCE.each.value
          : GRADING_PREFERENCE.all.value,
      passPoint: {
        type: data.grading_method === 'AUTO' ? PASS_POINT.auto.value : PASS_POINT.manual.value,
        value: data.grading_method === 'AUTO' ? '' : data.required_percent_score?.toString(),
      },
      attempt: {
        type: data.is_limited ? ATTEMPT.limited.value : ATTEMPT.unlimited.value,
        value: data.is_limited ? data.limit_count?.toString() : '',
      },
      number_of_multiple_choice_questions: data.number_of_multiple_choice_questions?.toString(),
      number_of_essay_questions: data.number_of_essay_questions?.toString(),
      quiz_type: data.quiz_type,
    }
  }

  /**
   * @description Trở về màn course
   */
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có các thay đổi chưa lưu.', 'Bạn có muốn quay lại trang Course Content?'],
      onClick: () => navigate(PageLink.COURSE_FULL + '/' + params.courseId + '?page=1'),
    })
  }

  /**
   * @description Mở Drawer list question
   * @param {LIST_TYPE} type
   */
  const handleChooseQuestions = (type: LIST_TYPE) => {
    if (getValues('type') === TYPE_OF_TEST.mix.value) {
      if (type === 'multiple' && !Number(getValues('number_of_multiple_choice_questions'))) {
        setError('number_of_multiple_choice_questions', {message: VALIDATION_FILED})
        addQuestioned.current = {...addQuestioned.current, multiple: true}
        return
      }
      if (type === 'constructed' && !Number(getValues('number_of_essay_questions'))) {
        setError('number_of_essay_questions', {message: VALIDATION_FILED})
        addQuestioned.current = {...addQuestioned.current, constructed: true}
        return
      }
    }
    setOpenChooseQuestion(type)
  }

  /**
   * @description Lấy data message lỗi 100% của percent
   * @param {*} type
   */
  const messageErrorTotalPercent = (type: string) => {
    setError(type, {
      type: 'custom',
      message: 'Mark of all questions not equal 100%',
    })
  }

  /**
   * @description Lấy data message lỗi min 0 của percent
   * @param {*} type
   */
  const messageErrorMinPercent = (type: string) => {
    setError(type, {
      type: 'custom',
      message: 'Mark of all questions must be greater than 0',
    })
  }

  /**
   * @description Lấy tổng % của item child
   * @param {*} object
   * @return {*}  {number}
   */
  const getTotalChildrentPercent = (param: any) => {
    let totalNumber = 0
    param?.map((e: any) => {
      const getPercent = getValues(`question_percent_${e.id}`) || e?.setting_grade
      totalNumber = totalNumber + Number(getPercent)
    })
    return Math.round(totalNumber)
  }

  /**
   * @description Kiểm tra xem có phần tử nào có % của item child không lớn hơn 0 hay không
   * @param {Array} param - Mảng chứa các phần tử cần kiểm tra
   * @return {boolean} - Kết quả true/false
   */
  const hasChildPercentLessThanOrEqualToZero = (param: any[]): boolean => {
    return param?.some((e: any) => {
      return Number(getValues(`question_percent_${e.id}`)) <= 0
    })
  }

  /**
   * @description Tạo object của question
   * @param {*} any, array
   * @return {*} array
   */
  const settingObjectQuizQuestions = (questions: any, data: any) => {
    questions.forEach((question: any) => {
      data.push({
        question_id: question?.id || '',
        setting_grade: getValues(`question_percent_${question.id}`) 
          ? Number(getValues(`question_percent_${question.id}`))
          : Number(question?.setting_grade),
      })
    })
    return data
  }

  /**
   * @description Lấy data từ form để create và edit quiz
   * @param {IQuiz} data
   */
  const onSubmit = async (data: IQuiz) => {
    setLoading(true)

    try {
      // Check validate percent
      let flagPercent = false
      const getTotalMulti = Number(getValues(`get_percent_questions_multi`))
      const getTotalConstructed = Number(getValues(`get_percent_questions_constructed`))
      const getTotalChildMulti = getTotalChildrentPercent(questionsMultiple)
      const getTotalChildConstructed = getTotalChildrentPercent(questionsConstructed)
      const isZeroChildConstructed = hasChildPercentLessThanOrEqualToZero(questionsConstructed)
      const isConstructed = getTotalConstructed === getTotalChildConstructed
      const isTotalPercent = Math.round(getTotalMulti + getTotalConstructed) === 100

      // if (watchType === TYPE_OF_TEST.multipleChoice.value) {
      //   getTotalMulti !== getTotalChildMulti && (Math.abs(getTotalMulti - getTotalChildMulti) > 1 || Math.abs(getTotalChildMulti - getTotalMulti) > 1)
      //     ? messageErrorTotalPercent('get_percent_questions_multi')
      //     : (flagPercent = true)
      // } else if (watchType === TYPE_OF_TEST.constructed.value) {
      //   if ((getTotalConstructed !== getTotalChildConstructed) && (Math.abs(getTotalConstructed - getTotalChildConstructed) > 1 || Math.abs(getTotalConstructed - getTotalChildConstructed) > 1)) {
      //     messageErrorTotalPercent('get_percent_questions_constructed')
      //   } else if (isZeroChildConstructed) {
      //     messageErrorMinPercent('get_percent_questions_constructed')
      //   } else {
      //     flagPercent = true
      //   }
      // } else if (watchType === TYPE_OF_TEST.mix.value) {
      //   !isConstructed && messageErrorTotalPercent('get_percent_questions_constructed')
      //   getTotalMulti === 0 && messageErrorMinPercent('get_percent_questions_multi')
      //   getTotalConstructed === 0 ||
      //     (isZeroChildConstructed && messageErrorMinPercent('get_percent_questions_constructed'))

      //   if (!isTotalPercent) {
      //     messageErrorTotalPercent('get_percent_questions_multi')
      //     messageErrorTotalPercent('get_percent_questions_constructed')
      //   }

      //   if (
      //     isConstructed &&
      //     isTotalPercent &&
      //     getTotalMulti !== 0 &&
      //     getTotalConstructed !== 0 &&
      //     !isZeroChildConstructed
      //   ) {
      //     flagPercent = true
      //   }
      // }
      if (customOnSubmit && forTab) {
        const payload = {
          quiz_question_mode: 'ALL',
          status: 'PUBLISHED',
          is_published: true,
          is_public: true,
          is_graded: data.gradedAssignment === GRADED_ASSIGNMENT.yes.value ? true : false,

          grading_method: data.passPoint.type,
          grading_preference: data.gradingPreference,
          required_percent_score:
            data.passPoint.type === PASS_POINT.auto.value ? 50 : Number(data.passPoint.value),

          is_limited: data.attempt.type === ATTEMPT.limited.value ? true : false,
          ...(data.attempt.type === ATTEMPT.limited.value && {
            limit_count: Number(data.attempt.value) || 0,
          }),
          is_tutor: data.testMode === TEST_MODE.tutor.value ? true : false,
          ...(data.testMode === TEST_MODE.timed.value && {
            quiz_timed: Number(data.time.hours) * 60 + Number(data.time.mins),
          }),
          quiz_question_type: data.type,
          quiz_type: type?.toUpperCase(),
          quiz_questions: (() => {
            const dataQuestions: Array<any> = []
            if (data.type === TYPE_OF_TEST.mix.value) {
              settingObjectQuizQuestions(questionsConstructed, dataQuestions)
              settingObjectQuizQuestions(questionsMultiple, dataQuestions)
              return dataQuestions
            }
            if (data.type === TYPE_OF_TEST.constructed.value) {
              settingObjectQuizQuestions(questionsConstructed, dataQuestions)
              return dataQuestions
            }
            if (data.type === TYPE_OF_TEST.multipleChoice.value) {
              settingObjectQuizQuestions(questionsMultiple, dataQuestions)
              return dataQuestions
            }
            return []
          })(),
          course_id: params?.courseId,
          multiple_choice_multiplier: getTotalMulti,
          essay_multiplier: getTotalConstructed,
        }
        // if (flagPercent) {
          await customOnSubmit(payload)
        // }
      } else {
        const request = {
          name: data.name.trim(),
          quiz_question_mode: 'ALL',
          status: 'PUBLISHED',
          is_published: true,
          is_public: true,
          password: '',

          number_of_multiple_choice_questions:
            Number(data.number_of_multiple_choice_questions) || 0,
          number_of_essay_questions: Number(data.number_of_essay_questions) | 0,

          is_graded: data.gradedAssignment === GRADED_ASSIGNMENT.yes.value ? true : false,
          grading_method: data.passPoint.type,
          grading_preference: data.gradingPreference,
          required_percent_score:
            data.passPoint.type === PASS_POINT.auto.value ? 50 : Number(data.passPoint.value),

          due_date: '',
          is_limited: data.attempt.type === ATTEMPT.limited.value ? true : false,
          ...(data.attempt.type === ATTEMPT.limited.value && {
            limit_count: Number(data.attempt.value) || 0,
          }),

          is_tutor: data.testMode === TEST_MODE.tutor.value ? true : false,
          ...(data.testMode === TEST_MODE.timed.value && {
            quiz_timed: Number(data.time.hours) * 60 + Number(data.time.mins),
          }),

          quiz_question_type: data.type,
          quiz_type: type?.toUpperCase(),

          ...(Object.keys(TEST_TYPE)
            .filter((e) => e !== TEST_TYPE.MID_TERM_TEST && e !== TEST_TYPE.FINAL_TEST)
            .includes(getValues('quiz_type')?.toLocaleUpperCase() || '')
            ? {
                course_section_id: !editId ? id : undefined,
              }
            : {
                course_id: !editId ? id : undefined,
              }),
          quiz_questions: (() => {
            const dataQuestions: Array<any> = []
            if (data.type === TYPE_OF_TEST.mix.value) {
              settingObjectQuizQuestions(questionsConstructed, dataQuestions)
              settingObjectQuizQuestions(questionsMultiple, dataQuestions)
              return dataQuestions
            }
            if (data.type === TYPE_OF_TEST.constructed.value) {
              settingObjectQuizQuestions(questionsConstructed, dataQuestions)
              return dataQuestions
            }
            if (data.type === TYPE_OF_TEST.multipleChoice.value) {
              settingObjectQuizQuestions(questionsMultiple, dataQuestions)
              return dataQuestions
            }
            return []
          })(),
          quiz_for: !editId ? testFor.toUpperCase() : undefined,
          course_id: params?.courseId,
          multiple_choice_multiplier: getTotalMulti,
          essay_multiplier: getTotalConstructed,
        }

        let response: any = []

        // if (flagPercent) {
          if (editId) {
            response = await CoursesAPI.updateQuiz(editId, request)
          } else {
            response = await CoursesAPI.createQuiz(request)
          }

          if (response.success) {
            toast.success('Quiz saved successfully!')
            setQuestionsMultiple([])
            setQuestionsConstructed([])
            reset(defaultValues)
            navigate(PageLink.COURSE_FULL + '/' + params.courseId + '?page=1')
          }
        // }
      }
    } finally {
      setLoading(false)
    }
  }

  /**
   * @description Chuyển dữ liệu của form trừ name và default khi chuyển type
   * Nếu là edit và type đã chọn bằng type lấy về từ api thì hiện hiện thị dữ liệu vừa lấy
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const onTypeOfTestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValues: IQuiz
    if (editId && storeFormData.current && e.target.value === storeFormData.current.type) {
      newValues = storeFormData.current
      setQuestionsConstructed(storeFormData.current.constructed_questions || [])
      setQuestionsMultiple(storeFormData.current.multiple_choice_questions || [])
    } else {
      newValues = {...defaultValues}
      setQuestionsConstructed([])
      setQuestionsMultiple([])
    }
    setValue('testMode', newValues.testMode)
    setValue('gradedAssignment', newValues.gradedAssignment)
    setValue('time', newValues.time)
    setValue('gradingPreference', newValues.gradingPreference)
    setValue('passPoint', newValues.passPoint)
    setValue('attempt', newValues.attempt)
    setValue('number_of_multiple_choice_questions', newValues.number_of_multiple_choice_questions)
    setValue('number_of_essay_questions', newValues.number_of_essay_questions)
    clearErrors()
  }

  const getTitle = (): string => {
    if (watchQuizType === TEST_TYPE.MID_TERM_TEST) {
      return 'MidTerm Test'
    }
    if (watchQuizType === TEST_TYPE.FINAL_TEST) {
      return 'Final Test'
    }
    if (watchQuizType === TEST_TYPE.TOPIC_TEST) {
      return 'Part/ Topic Test'
    }
    if (watchQuizType === TEST_TYPE.CHAPTER_TEST) {
      return 'Chapter/ Module Test'
    }
    return ''
  }
  return (
    <div>
      {contextHolder}
      <div className=''>
        <div className=''>
          {!forTab && (
            <React.Fragment>
              <SappLabel required label={`${getTitle()} Name`}></SappLabel>
              {/* start:: name*/}
              <div className='mb-10'>
                <HookFormTextField
                  control={control}
                  name='name'
                  placeholder=''
                  className='w-100 sapp-h-45px fs-6'
                  disabled={false}
                  skeleton={skeleton}
                ></HookFormTextField>
              </div>
            </React.Fragment>
          )}
          {/* end:: name*/}
          {/* start:: Type of Test*/}
          <div className='mb-10'>
            <SappLabel required label={`Type of Test`}></SappLabel>
            {!skeleton ? (
              <HookFormRadioGroup
                labelClass='fw-semibold fs-6'
                direction='horizontal'
                separator={false}
                name='type'
                control={control}
                justify='start'
                // gap={20}
                disabled={!editAble || editOnlyName}
                options={Object.values(TYPE_OF_TEST ?? {}).map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                onChange={onTypeOfTestChange}
                itemWidth={'25%'}
              />
            ) : (
              <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
            )}
          </div>
          {watchType === TYPE_OF_TEST.mix.value && (
            <div className='mb-10'>
              <SappLabel required label={'Number of Questions'}></SappLabel>
              <Row className='align-items-start'>
                <Col>
                  <HookFormTextField
                    control={control}
                    name='number_of_multiple_choice_questions'
                    placeholder='Multiple Choice'
                    className='w-100 sapp-h-45px fs-6'
                    type='number'
                    disabled={!editAble || editOnlyName}
                    skeleton={skeleton}
                    onChange={(e) => {
                      if (!isSubmitted && addQuestioned.current.multiple) {
                        if (!Number(getValues('number_of_multiple_choice_questions'))) {
                          setError('number_of_multiple_choice_questions', {
                            message: VALIDATION_FILED,
                          })
                        } else {
                          clearErrors('number_of_multiple_choice_questions')
                        }
                      }
                    }}
                  ></HookFormTextField>
                </Col>
                <Col>
                  <HookFormTextField
                    control={control}
                    name='number_of_essay_questions'
                    placeholder='Constructed'
                    className='w-100 sapp-flex-1  sapp-h-45px fs-6'
                    type='number'
                    disabled={!editAble || editOnlyName}
                    skeleton={skeleton}
                    onChange={(e) => {
                      if (!isSubmitted && addQuestioned.current.constructed) {
                        if (!Number(getValues('number_of_essay_questions'))) {
                          setError('number_of_essay_questions', {message: VALIDATION_FILED})
                        } else {
                          clearErrors('number_of_essay_questions')
                        }
                      }
                    }}
                  ></HookFormTextField>
                </Col>
              </Row>
            </div>
          )}
          {/* end:: Type of Test*/}
          {/* <Row className='mb-10 row-gap-15'> */}
          {/* start:: Test Mode*/}
          {/* <Col> */}
          <div className='mb-10'>
            <SappLabel required label={'Test Mode'}></SappLabel>
            {!skeleton ? (
              <HookFormRadioGroup
                labelClass='fw-semibold fs-6'
                direction='horizontal'
                separator={false}
                name='testMode'
                control={control}
                justify='start'
                // gap={20}
                disabled={!editAble || editOnlyName}
                options={Object.values(TEST_MODE ?? {}).map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                onChange={() => {
                  setValue('time.hours', '')
                  setValue('time.mins', '')
                  clearErrors('time.hours')
                  clearErrors('time.mins')
                }}
                itemWidth={'25%'}
              />
            ) : (
              <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
            )}
          </div>
          {/* </Col> */}
                    {/* start:: Duration*/}
                    {watchTestMode === TEST_MODE.timed.value && (
            <div className='mb-10'>
              <SappLabel required label={'Duration'}></SappLabel>
              {!skeleton ? (
                <Row className='align-items-start'>
                  <Col>
                    <HookFormTextField
                      control={control}
                      name='time.hours'
                      className='w-100 sapp-h-45px fs-6'
                      labelClass='d-flex align-items-center fs-6 fw-bold form-label required'
                      type='number'
                      disabled={!editAble || editOnlyName}
                      onChange={() => {
                        if (isSubmitted) {
                          trigger('time.mins')
                        }
                      }}
                      skeleton={skeleton}
                      postFix={<div className='sapp-post-fix-text-field '>hours</div>}
                    ></HookFormTextField>
                  </Col>
                  <Col>
                    <HookFormTextField
                      control={control}
                      name='time.mins'
                      className='w-100 sapp-h-45px fs-6'
                      type='number'
                      disabled={!editAble || editOnlyName}
                      onChange={() => {
                        if (isSubmitted) {
                          trigger('time.hours')
                        }
                      }}
                      skeleton={skeleton}
                      postFix={<div className='sapp-post-fix-text-field '>mins</div>}
                    ></HookFormTextField>
                  </Col>
                </Row>
              ) : (
                <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
              )}
            </div>
          )}
          {/* end:: Duration*/}
          {/* end:: Test Mode*/}
          {/* start:: Graded Assignment*/}
          {/* <Col> */}
          <div className='mb-10'>
            <SappLabel required label={'Graded Assignment'}></SappLabel>
            {!skeleton ? (
              <HookFormRadioGroup
                labelClass='fw-semibold fs-6'
                direction='horizontal'
                separator={false}
                name='gradedAssignment'
                control={control}
                justify='start'
                // gap={20}
                disabled={!editAble || editOnlyName}
                options={Object.values(GRADED_ASSIGNMENT ?? {}).map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                itemWidth={'25%'}
              />
            ) : (
              <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
            )}
          </div>
          {/* </Col> */}
          {/* end:: Graded Assignment*/}
          {/* </Row> */}
          {/* start:: Grading Preference*/}

          <div className='mb-10'>
            <SappLabel required label={'Grading Preference'}></SappLabel>
            {!skeleton ? (
              <div>
                <HookFormRadioGroup
                  labelClass='fw-semibold fs-6'
                  direction='horizontal'
                  separator={false}
                  name='gradingPreference'
                  control={control}
                  // gap={15}
                  justify='start'
                  disabled={!editAble || editOnlyName}
                  options={Object.values(GRADING_PREFERENCE ?? {}).map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  itemWidth={'25%'}
                />
              </div>
            ) : (
              <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
            )}
          </div>
          {/* end:: Grading Preference*/}
          {/* start:: Pass Points*/}
          <div className='mb-6'>
            <SappLabel required label={'Pass Point'}></SappLabel>
            <div className='d-flex justify-content-between '>
              <div className='mb-4 w-50'>
                {!skeleton ? (
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='passPoint.type'
                      control={control}
                      options={Object.values(PASS_POINT ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      // gap={20}
                      justify='start'
                      disabled={!editAble || editOnlyName}
                      itemWidth={'50%'}
                    />
                  </div>
                ) : (
                  <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                )}
              </div>
              {watchPassPoint?.type === PASS_POINT.manual.value && (
                <div className='align-self-end sapp-w-49'>
                  <HookFormTextField
                    disabled={editOnlyName}
                    control={control}
                    name='passPoint.value'
                    placeholder=''
                    className='sapp-h-45px fs-6 '
                    postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                    type="number"
                  ></HookFormTextField>
                </div>
              )}
            </div>
          </div>
          {/* end:: Pass Points*/}
          {/* start:: Attempt*/}
          <div className='mb-6'>
            <div className='mb-1'>
              <SappLabel required label={'Attempt'}></SappLabel>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='mb-4 w-50'>
                {!skeleton ? (
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='attempt.type'
                      control={control}
                      options={Object.values(ATTEMPT ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      // gap={20}
                      justify='start'
                      disabled={!editAble || editOnlyName}
                      itemWidth={'50%'}
                    />
                  </div>
                ) : (
                  <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                )}
              </div>
              {watchAttempt?.type === ATTEMPT.limited.value && (
                <div className='align-self-end sapp-w-49 '>
                  <HookFormTextField
                    control={control}
                    name='attempt.value'
                    placeholder=''
                    className='sapp-h-45px fs-6'
                    disabled={!editAble || editOnlyName}
                    // skeleton={loadingFetchData}
                    type="number"
                  ></HookFormTextField>
                </div>
              )}
            </div>
          </div>
          {/* end:: Attempt*/}
          {/* {!forTab && ( */}

          {/* )} */}
        </div>

        {(watchType === TYPE_OF_TEST.multipleChoice.value ||
          watchType === TYPE_OF_TEST.mix.value) && (
          <div className='mb-10'>
            <SappLabel required label={'List Multiple Choice Questions'}></SappLabel>
            <QuizQuestionTable
              maxQuestionsNumber={
                watchType === TYPE_OF_TEST.mix.value
                  ? Number(watchNumberOfMultipleChoiceQuestions) || 0
                  : 'NO_MAX'
              }
              // error={errors['emptyMultiple'] as any}
              questions={questionsMultiple}
              setQuestions={setQuestionsMultiple}
              handleChooseQuestions={() => handleChooseQuestions('multiple')}
              confirm={confirm}
              disabled={!editAble || editOnlyName}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watchType={watchType}
              defaultType={'multi'}
              totalQuestionsMultiple={totalQuestionsMultiple}
              totalQuestionsConstructed={totalQuestionsConstructed}
            ></QuizQuestionTable>
          </div>
        )}
        {(watchType === TYPE_OF_TEST.constructed.value || watchType === TYPE_OF_TEST.mix.value) && (
          <div className='mb-10'>
            <SappLabel required label={'List Constructed Questions'}></SappLabel>
            <QuizQuestionTable
              maxQuestionsNumber={
                watchType === TYPE_OF_TEST.mix.value
                  ? Number(watchNumberOfEssayQuestions) || 0
                  : 'NO_MAX'
              }
              error={errors['emptyConstructed'] as any}
              questions={questionsConstructed}
              setQuestions={setQuestionsConstructed}
              handleChooseQuestions={() => handleChooseQuestions('constructed')}
              confirm={confirm}
              disabled={!editAble || editOnlyName}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watchType={watchType}
              defaultType={'constructed'}
              totalQuestionsMultiple={totalQuestionsMultiple}
              totalQuestionsConstructed={totalQuestionsConstructed}
            ></QuizQuestionTable>
          </div>
        )}
        <div className='card-footer'>
          <SAPPDialogButtonsCancelSubmit
            cancelClick={!customOnCancel ? handleCancel : customOnCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption={buttonSaveTitle}
            okOnClick={handleSubmit(onSubmit)}
            className={CancelSubmitBtnClass}
            loading={loading}
            disabled={false}
          />
        </div>
      </div>

      <ChooseQuestionsModal
        questions={openChooseQuestion === 'multiple' ? questionsMultiple : questionsConstructed}
        maxQuestionsNumber={
          watchType === TYPE_OF_TEST.mix.value
            ? openChooseQuestion === 'multiple'
              ? Number(watchNumberOfMultipleChoiceQuestions) || 0
              : Number(watchNumberOfEssayQuestions) || 0
            : 'NO_MAX'
        }
        setQuestions={
          openChooseQuestion === 'multiple' ? setQuestionsMultiple : setQuestionsConstructed
        }
        open={openChooseQuestion}
        setOpen={setOpenChooseQuestion}
        type={openChooseQuestion as LIST_TYPE | boolean}
        editing={oldData?.id || editId}
      ></ChooseQuestionsModal>
    </div>
  )
}

export default TestComponent
