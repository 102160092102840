/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect, useState} from 'react'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import TableList from 'src/components/courses/TableList'
import PageLayouts from 'src/components/layout/PageLayouts'
import {
  COURSE_STATUS,
  COURSE_TYPES,
  DEFAULT_SELECT_ALL,
  FILTER_SELECTALL_SORTBY,
  PageLink,
} from 'src/constants'
import {LANG_COURSES, LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs, Role} from 'src/type'
import {KTIcon} from 'src/_metronic/helpers'
import ModalChooseStructure from 'src/components/courses/ModalChooseStructure'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {Select} from 'antd'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import {replaceValueAll} from 'src/utils/string'
import {useLocation, useNavigate} from 'react-router-dom'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import {CoursesAPI} from 'src/apis/courses'
import {ICourseCategoies, ICourseList} from 'src/type/courses'
import useChecked from 'src/hooks/use-checked'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'

const {Option} = Select

const breadcrumbs: ITabs[] = [
  {
    link: '',
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Course List',
  },
]

const fieldNames = ['category', 'name', 'status', 'course_types', 'sortType', 'fromDate', 'toDate']
const initialValues: any = {
  category: '',
  name: '',
  status: '',
  course_types: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}

const Courses = () => {
  const [courseList, setCourseList] = useState<ICourseList>()
  const { profileMe } = useUserContext()
  const allowRenderCreateCourse = profileMe?.roles?.some((role: Role)  => role.permissions?.includes(TITLE_COURSE_GR.CREATE_COURSE) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const [openChooseStructure, setOpenChooseStructure] = useState(false)
  const navigate = useNavigate()
  const [dateField, setDateField] = useState<string>('updated_at')
  const [loading, setLoading] = useState(true)
  const {search} = useLocation()
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()

  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    course_category_ids: searchParams.get('course_category_ids'),
    status: searchParams.get('status'),
    course_types: searchParams.get('course_types'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const {checkedList, toggleCheck, toggleCheckAll, isCheckedAll} = useChecked<any>(
    courseList?.courses
  )

  const getParams = (
    name: string,
    course_category_ids: string,
    status: string,
    course_types: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name,
    course_category_ids,
    status,
    course_types,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  const validationSchema = z.object({
    name: z.string().optional(),
    course_category_ids: z.string().optional(),
    status: z.string().optional(),
    course_types: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const {control, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const fetchCourseList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CoursesAPI.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setCourseList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    setDateField('')
    navigate(PageLink.COURSES)

    fetchCourseList(1, 10)
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name'),
      course_category_ids: replaceValueAll(getValues('course_category_ids')),
      status: replaceValueAll(getValues('status')),
      course_types: replaceValueAll(getValues('course_types')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const checkFromDateToDate =
    queryParams.name ||
    queryParams.course_category_ids ||
    queryParams.status ||
    queryParams.course_types ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_ids')),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('course_types')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )

    setLoading(true)

    fetchCourseList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }

  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({page_index: 1, page_size: 100})
      setCourseCategory(res?.data)
    } catch (error) {}
  }

  useEffect(() => {
    fetchCourseCategory()
  }, [])

  const newCourseCategory = courseCategory?.course_categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }))

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.course_category_ids ?? '',
      queryParams.status ?? '',
      queryParams.course_types ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      checkFromDateToDate ? 'updated_at' : 'created_at'
    )
  )

  return (
    <PageLayouts pageTitle='Course List' breadcrumbs={breadcrumbs}>
      <div className='card'>
        {/* start:: filter */}
        <div className='px-10 border-0 pt-10'>
          <div>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4'>
                <div
                  className='card-title justify-content-center mb-0 mx-0'
                  // onSubmit={(e) => {
                  //   e.preventDefault()
                  //   onSubmit()
                  // }}
                >
                  <HookFormTextField
                    control={control}
                    name='name'
                    placeholder='Search'
                    defaultValue={queryParams?.name}
                    onSubmit={onSubmit}
                  />
                </div>
              </div>
              {/* end:: Search */}

              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='course_category_ids'
                  placeholder='Category'
                  defaultValue={queryParams.course_category_ids ?? ''}
                  showSearch
                >
                  {DEFAULT_SELECT_ALL?.concat(newCourseCategory ?? ([] as Array<any>)).map(
                    (category) => (
                      <Option key={category.value} value={category.value}>
                        {category.label}
                      </Option>
                    )
                  )}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='status'
                  placeholder='Status'
                  defaultValue={queryParams.status ?? ''}
                >
                  {COURSE_STATUS.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='course_types'
                  placeholder='Type'
                  defaultValue={queryParams.course_types ?? ''}
                >
                  {DEFAULT_SELECT_ALL.concat(COURSE_TYPES).map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>

              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort by'
                  control={control}
                  size='large'
                  defaultValue={queryParams?.sortType ?? ''}
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  className='sapp-h-datimepicker'
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  className='sapp-h-datimepicker'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  disabled={loading}
                />
              </div>

              <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
                <div className='card-toolbar justify-content-end d-flex my-0'>
                  {/* start:: button open modal */}
                  {allowRenderCreateCourse && (
                    <ButtonIcon
                    title={LANG_COURSES.addCourses}
                    className='sapp-height-min--content'
                    onClick={() => setOpenChooseStructure(true)}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </ButtonIcon>
                  )}
                  {/* end:: button open modal */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end:: filter */}

        {/* start:: table */}
        <TableList
          courseList={courseList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchCourseList={fetchCourseList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        {/* end:: table */}
      </div>
      <ModalChooseStructure open={openChooseStructure} setOpen={setOpenChooseStructure} />
    </PageLayouts>
  )
}

export default withAuthComponents(Courses)
