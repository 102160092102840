import {KTIcon} from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {useListView} from 'src/components/user-list/components/core/ListViewProvider'
import {LANG_NEWS} from 'src/constants/lang'

const NewsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()

  // function open modal add user
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <NewsListFilter /> */}

      {/* begin:: button export data */}
      {/* <ExportToExcel
        data={data}
        title={LANG_NEWS.export}
        fileName='news'
        headers={headers}
        columnWidths={columnWidths}
      >
        <KTIcon iconName='exit-up' className='fs-2' />
      </ExportToExcel> */}
      {/* end:: button export data */}

      {/* begin::Add user */}
      <ButtonIcon title={LANG_NEWS.addNews} onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
      </ButtonIcon>
      {/* end::Add user */}
    </div>
  )
}

export {NewsListToolbar}
