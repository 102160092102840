import { fetcher } from 'src/services/request';
import { IMeta, IResponseMeta } from 'src/type';

export interface IResponseLogData<T> {
  meta: IMeta,
  logs: T[],
}
interface IResponseLogMeta<T> extends IResponseMeta {
  data: IResponseLogData<T>
}

export class ImportLogAPI {
  static getImportLogs<T>({ page_index = 1, page_size, type, text, status }: { type: string; page_size?: number; page_index: number, text?: String, status?: String }): Promise<IResponseLogMeta<T>> {
    return fetcher(`import-logs`, {
      params: {
        page_size,
        page_index,
        object_type: type,
        text: (text ?? '').trim(),
        status: (status ?? '').trim()
      }
    })
  }
  static getImportLogsById({ id }: { id: string }): Promise<any> {
    return fetcher(`import-logs/${id}`)
  }
}