import {zodResolver} from '@hookform/resolvers/zod'
import {Skeleton} from 'antd'
import {useEffect, useLayoutEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {CaseStudyAPI} from 'src/apis/case-study'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {
  PageLink,
  VALIDATE_FILED_MAX_LENGTH,
  VALIDATE_NUMBER,
  VALIDATE_PASS_POINT,
  VALIDATION_FILED,
  VALIDATION_MIN_EQUAL,
} from 'src/constants'
import {useConfirm} from 'src/hooks/use-confirm'
import {IStory} from 'src/type/story'
import {mergeImageToEditor} from 'src/utils/upload'
import {z} from 'zod'
import {ATTEMPT, GRADING_PREFERENCE, PASS_POINT} from '../../shared/consts'
import ChooseStoriesDrawer from './ChooseStoriesDrawer'
import CaseStudyTable from './StoriesTable'

interface IFormCaseStudy {
  name: string
  description: string
  duration: {
    hours: string
    mins: string
  }
  gradingPreference: string
  passPoint: {
    type: string
    value: string
  }
  grading_method?: string
  attempt: {
    type: string
    value: string
  }
  emptyStory?: any
}
const defaultValues = {
  name: '',
  description: '',
  duration: {
    hours: '',
    mins: '',
  },
  gradingPreference: GRADING_PREFERENCE.each.value,
  passPoint: {
    type: PASS_POINT.auto.value,
    value: '',
  },
  attempt: {
    type: ATTEMPT.unlimited.value,
    value: '',
  },
}

type Props = {
  id?: string
  courseId: string
  courseSectionId: string
  editOnlyName?: boolean
}

const CaseStudyComponent = ({id, courseId, courseSectionId, editOnlyName}: Props) => {
  const {confirm, contextHolder} = useConfirm()
  const navigate = useNavigate()

  const [story, setStory] = useState<IStory>()
  const [openChooseQuestion, setOpenChooseQuestion] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [skeleton, setSkeleton] = useState<boolean>(false)
  const [defaultEditor, setDefaultEditor] = useState<any>()

  const validationSchema = z
    .object({
      name: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED)
        .max(1000, VALIDATE_FILED_MAX_LENGTH('Case Study name', 1000)),
      description: z.string().optional(),
      duration: z.object({
        hours: z
          .string()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .refine(
            (val) => {
              if (!Number(val) && !Number(getValues('duration.mins'))) {
                return false
              }
              return true
            },
            {message: VALIDATION_FILED}
          )
          .optional(),
        mins: z
          .string()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .refine(
            (val) => {
              if (!Number(val) && !Number(getValues('duration.hours'))) {
                return false
              }
              return true
            },
            {message: VALIDATION_FILED}
          )
          // .refine((val) => {
          //   if (val && Number(val) >= 60) {
          //     return false
          //   }
          //   return true
          // }, VALIDATE_TIME_MINUTE)
          .optional(),
      }),
      gradingPreference: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED),
      passPoint: z.object({
        type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
        value: z
          .string()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .superRefine((arg: any, ctx) => {
            if (getValues('passPoint.type') === PASS_POINT.manual.value) {
              if (!Number(arg) || Number(arg) > 100) {
                ctx.addIssue({
                  message: VALIDATE_PASS_POINT,
                  code: 'custom',
                })
              }
            }
            return true
          })
          .optional(),
      }),
      attempt: z.object({
        type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
        value: z
          .string()
          .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
          .refine(
            (val) => {
              if (getValues('attempt.type') === ATTEMPT.limited.value && Number(val) < 1) {
                return false
              }
              return true
            },
            {message: VALIDATION_MIN_EQUAL(1)}
          )
          .optional(),
      }),
    })
    .refine(
      () => {
        if (!story) {
          return false
        } else {
          return true
        }
      },
      {
        message: 'Case Study is required',
        path: ['emptyStory'],
      }
    )
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: {errors, isSubmitted},
    trigger,
  } = useForm<IFormCaseStudy>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const watchPassPoint = watch('passPoint')
  const watchAttempt = watch('attempt')

  useEffect(() => {
    if (isSubmitted) {
      trigger('emptyStory')
    }
  }, [story])

  useLayoutEffect(() => {
    ;(async () => {
      if (id) {
        setSkeleton(true)
        try {
          const response = await CaseStudyAPI.getById(id)
          if (response.success) {
            convertResultToFormValues(response.data)
          }
        } catch (error) {
          navigate(`${PageLink.COURSE_FULL}/${courseId}`)
        } finally {
          setSkeleton(false)
        }
      }
    })()
  }, [])

  const convertResultToFormValues = (response: any) => {
    const result: IFormCaseStudy = {
      name: response.name,
      description: response.description,
      duration: {
        hours: Math.floor(response.quiz_timed / 60).toString(),
        mins: (response.quiz_timed % 60).toString(),
      },
      gradingPreference: response.grading_preference,
      passPoint: {
        type: response.grading_method === 'AUTO' ? PASS_POINT.auto.value : PASS_POINT.manual.value,
        value:
          response.grading_method === 'AUTO' ? '' : response.required_percent_score?.toString(),
      },
      attempt: {
        type: response.is_limited ? ATTEMPT.limited.value : ATTEMPT.unlimited.value,
        value: response.is_limited ? response.limit_count.toString() : '',
      },
    }

    const customSetValue = setValue as any
    Object.entries(result || {}).forEach(async ([key, value]) => {
      if (key !== 'description') {
        customSetValue(key, value)
      } else {
        const dataDes = await mergeImageToEditor(value, [])
        setDefaultEditor(dataDes)
        setValue('description', dataDes)
      }
    })
    if (response.case_study_story) {
      setStory(response.case_study_story)
    }
  }

  const handleChooseQuestions = () => {
    setOpenChooseQuestion(true)
  }

  const onSubmit = async (data: IFormCaseStudy) => {
    const request: any = {
      name: data.name.trim(),
      description: data.description?.trim() || '',
      quiz_timed: Number(data.duration.hours) * 60 + Number(data.duration.mins),
      status: 'PUBLISHED',
      is_published: true,
      grading_preference: data.gradingPreference,
      required_percent_score:
        data.passPoint.type === PASS_POINT.auto.value ? 50 : Number(data.passPoint.value),
      grading_method: data.passPoint.type,
      is_limited: data.attempt.type === ATTEMPT.limited.value ? true : false,
      ...(data.attempt.type === ATTEMPT.limited.value && {
        limit_count: Number(data.attempt.value) || 0,
      }),
      case_study_story_id: story?.id!,
      course_section_id: courseSectionId,
      course_id: courseId
    }

    let response: any
    setLoading(true)
    try {
      if (id) {
        response = await CaseStudyAPI.update(id, {...request})
      } else {
        response = await CaseStudyAPI.create(request)
      }
      if (response.success) {
        toast.success('Case study saved successfully!')
        navigate(PageLink.COURSE_FULL + '/' + courseId + '?page=1')
      }
    } finally {
      setLoading(false)
    }
  }

  /**
   * @description Trở về màn course
   */
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có các thay đổi chưa lưu.', 'Bạn có muốn quay lại trang Course Content?'],
      onClick: () => navigate(PageLink.COURSE_FULL + '/' + courseId + '?page=1'),
    })
  }

  return (
    <div>
      {contextHolder}
      <div>
        <div className='mb-10'>
          {/*start:: Name */}
          <div className='mb-10'>
            <HookFormTextField
              control={control}
              name='name'
              placeholder=''
              className='w-100 sapp-h-45px fs-6'
              label='Case Study Name'
              required
              skeleton={skeleton}
            ></HookFormTextField>
          </div>
          {/*end:: Name */}

          {/*start:: Description */}
          <div className='mb-10'>
            <HookFormEditor
              control={control}
              name='description'
              placeholder=''
              className='w-100 fs-6'
              label='Describe'
              // labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
              skeleton={skeleton}
              math={true}
              defaultValue={defaultEditor}
              height={350}
              disabled={editOnlyName}
            ></HookFormEditor>
          </div>
          {/*end:: Description */}

          {/* start:: Duration*/}

          <div className='mb-10'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label required'>
              <span>Duration</span>
            </label>
            <Row className='align-items-start'>
              <Col>
                <HookFormTextField
                  control={control}
                  name='duration.hours'
                  placeholder=''
                  className='w-100 sapp-h-45px fs-6'
                  // labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                  skeleton={skeleton}
                  onChange={() => {
                    if (isSubmitted) {
                      trigger('duration.mins')
                    }
                  }}
                  type='number'
                  disabled={editOnlyName}
                  postFix={<div className='sapp-post-fix-text-field '>hours</div>}
                ></HookFormTextField>
              </Col>
              <Col>
                <HookFormTextField
                  control={control}
                  name='duration.mins'
                  placeholder=''
                  className='w-100 sapp-h-45px fs-6'
                  skeleton={skeleton}
                  onChange={() => {
                    if (isSubmitted) {
                      trigger('duration.hours')
                    }
                  }}
                  type='number'
                  disabled={editOnlyName}
                  postFix={<div className='sapp-post-fix-text-field '>mins</div>}
                ></HookFormTextField>
              </Col>
            </Row>
          </div>
          {/* end:: Duration*/}
          <div className='mb-10'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label required'>
              <span>Grading Preference</span>
            </label>
            <Row>
              <Col md={12}>
                <div className='mt-3'>
                  {!skeleton ? (
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='gradingPreference'
                      control={control}
                      // gap={15}
                      justify='start'
                      options={Object.values(GRADING_PREFERENCE ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      itemWidth={'25%'}
                      disabled={editOnlyName}
                    />
                  ) : (
                    <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {/* end:: Grading Preference*/}
          {/* start:: Pass Points*/}
          <div className='mb-6 w-100'>
            <label className='d-flex align-items-center fw-bold form-label required mb-1'>
              <span>Pass Points</span>
            </label>
            <div className='d-flex justify-content-between '>
              <div className='mb-4 w-50'>
                {!skeleton ? (
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='passPoint.type'
                      control={control}
                      options={Object.values(PASS_POINT ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      // gap={20}
                      justify='start'
                      // disabled={!editAble}
                      itemWidth={'50%'}
                      disabled={editOnlyName}
                    />
                  </div>
                ) : (
                  <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                )}
              </div>
              {watchPassPoint?.type === PASS_POINT.manual.value && (
                <div className='align-self-end sapp-w-49'>
                  <HookFormTextField
                    control={control}
                    name='passPoint.value'
                    placeholder=''
                    className='sapp-h-45px fs-6 '
                    postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                    disabled={editOnlyName}
                  ></HookFormTextField>
                </div>
              )}
            </div>
          </div>
          {/* end:: Pass Points*/}

          {/* start:: Attempt*/}
          <div className='mb-6 w-100'>
            <label className='d-flex align-items-center form-label required mb-1 fw-bold'>
              <span>Attempt</span>
            </label>
            <div className='d-flex justify-content-between'>
              <div className='mb-4 w-50'>
                {!skeleton ? (
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='attempt.type'
                      control={control}
                      options={Object.values(ATTEMPT ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      // gap={20}
                      justify='start'
                      // disabled={!editAble}
                      itemWidth={'50%'}
                      disabled={editOnlyName}
                    />
                  </div>
                ) : (
                  <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                )}
              </div>
              {watchAttempt?.type === ATTEMPT.limited.value && (
                <div className='align-self-end sapp-w-49 '>
                  <HookFormTextField
                    control={control}
                    name='attempt.value'
                    placeholder=''
                    className='sapp-h-45px fs-6'
                    disabled={editOnlyName}
                    // disabled={!editAble}
                    // skeleton={loadingFetchData}
                  ></HookFormTextField>
                </div>
              )}
            </div>
          </div>
          {/* end:: Attempt*/}

          <label className='d-flex align-items-center fs-6 fw-bold form-label required'>
            <span>Choose Case Study</span>
          </label>
          <CaseStudyTable
            error={errors?.['emptyStory'] as any}
            title='A new integrated case study is part of a range of innovations to the ACCA Qualification'
            story={story}
            setStory={setStory}
            handleChooseStories={() => handleChooseQuestions()}
            confirm={confirm}
            disabled={editOnlyName}
          ></CaseStudyTable>
        </div>
        <div className='card-footer'>
          <SAPPDialogButtonsCancelSubmit
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption={'Save'}
            okOnClick={handleSubmit(onSubmit)}
            loading={loading}
            className='d-flex justify-content-end align-items-center'
          />
        </div>
      </div>

      <ChooseStoriesDrawer
        story={story}
        setStory={setStory}
        open={openChooseQuestion}
        setOpen={setOpenChooseQuestion}
      ></ChooseStoriesDrawer>
    </div>
  )
}

export default CaseStudyComponent
