import {zodResolver} from '@hookform/resolvers/zod'
import {Select} from 'antd'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTIcon} from 'src/_metronic/helpers'
import {QuestionBankAPI} from 'src/apis/question-bank'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import TableList from 'src/components/question-bank/TableList'
import ListGrouping from 'src/components/user-management/UserListGrouping'
import {FILTER_SELECTALL_SORTBY, PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import useCourseFilter, {ISearchCourse} from 'src/hooks/use-course-filter'
import {ITabs, Role} from 'src/type'
import {ICourse} from 'src/type/courses'
import {ITopicList} from 'src/type/question-bank'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import {replaceValueAll} from 'src/utils/string'
import {z} from 'zod'
import {toast} from 'react-hot-toast'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'

const {Option} = Select

const breadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.topicList,
  },
]

const fieldNames = [
  'course',
  'text',
  'part',
  'chapter',
  'unit',
  'activity',
  'sortType',
  'fromDate',
  'toDate',
]
const initialValues: any = {
  course: '',
  text: '',
  part: '',
  chapter: '',
  unit: '',
  activity: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}

const TopicList = () => {
  const [topicList, setTopicList] = useState<ITopicList>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page_index = parseInt(queryParams.get('page_index') as string)
  const [loading, setLoading] = useState(true)
  const [dateField, setDateField] = useState<string>('created_at')
  const page_size = parseInt(queryParams.get('page_size') as any)
  const searchParams = new URLSearchParams(location.search)
  const [openBlocked, setOpenBlocked] = useState<boolean>(false)

  const {checkedList, toggleCheck, toggleCheckAll, isCheckedAll, listDataChecked} = useChecked<typeof initialValues>(
    topicList?.topics
  )

  const validationSchema = z.object({
    text: z.string().optional(),
    course: z.string().optional(),
    part: z.string().optional(),
    chapter: z.string().optional(),
    unit: z.string().optional(),
    activity: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const {control, handleSubmit, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const {
    listCourse,
    setListCourse,
    getListSort,
    debouncedGetListSort,
    clearChildren,
    getCourses,
    debouncedGetCourses,
    handleNextPage,
    handleNextListSort,
  } = useCourseFilter(setValue)

  useEffect(() => {
    ;(async () => {
      if (!queryParam.course) {
        // await getCourses()
        localStorage.removeItem('storedTopicListCourse')
      } else {
        try {
          const JsonListCourse = localStorage.getItem('storedTopicListCourse')
          if (JsonListCourse) {
            const storedListCourse = JSON.parse(JsonListCourse)
            setListCourse(storedListCourse)
            setValue('course', queryParam.course)
            setValue('part', queryParam.part)
            setValue('chapter', queryParam.chapter)
            setValue('unit', queryParam.unit)
            setValue('activity', queryParam.activity)
            return
          }
          await getCourses('storedTopicListCourse')
          localStorage.removeItem('storedTopicListCourse')
        } catch (error) {
          await getCourses('storedTopicListCourse')
          localStorage.removeItem('storedTopicListCourse')
        }
      }
    })()
  }, [])

  const fetchTopic = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await QuestionBankAPI.getTopic({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setTopicList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    setLoading(true)
    navigate(`${PageLink.TOPICS}`)
    setDateField('')
    clearChildren('course')
    toggleCheckAll(false)
    fetchTopic(1, 10)
  }

  const navigate = useNavigate()

  const getParams = (
    text: string,
    course: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string,
    course_section_id?: string
  ) => ({
    text,
    course,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
    course_section_id,
  })

  const queryParam = {
    text: searchParams.get('text'),
    course: searchParams.get('course'),
    part: searchParams.get('part'),
    chapter: searchParams.get('chapter'),
    unit: searchParams.get('unit'),
    activity: searchParams.get('activity'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      course: replaceValueAll(getValues('course')) || '',
      part: replaceValueAll(getValues('part')) || '',
      chapter: replaceValueAll(getValues('chapter')) || '',
      unit: replaceValueAll(getValues('unit')) || '',
      activity: replaceValueAll(getValues('activity')) || '',
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const prepareJson = Object.entries(listCourse || {}).reduce((previousValue, [key, value]) => {
      previousValue[key as keyof ISearchCourse] = {
        ...value,
        data: value.data.map((e: Partial<ICourse>) => ({
          id: e.id,
          name: e.name,
        })),
      }
      return previousValue
    }, {} as ISearchCourse)
    try {
      localStorage.setItem('storedTopicListCourse', JSON.stringify(prepareJson))
    } catch (error) {}

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text').trimStart().trimEnd(),
        replaceValueAll(getValues('course')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at',
        courseSectionIdValue || '',
      )
    )
    handleChangeParams(page_index || 1, page_size || 10)

    setLoading(true)
    fetchTopic(1, 10, cleanedParams)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate)
  const dateQueryToDate = getDateInfo(queryParam.toDate)

  const activityValue = getValues('activity')
  const unitValue = getValues('unit')
  const chapterValue = getValues('chapter')
  const partValue = getValues('part')

  let courseSectionIdValue = ''
  if (activityValue) {
    courseSectionIdValue = activityValue
  }
  else if (unitValue) {
    courseSectionIdValue = unitValue
  }
  else if (chapterValue) {
    courseSectionIdValue = chapterValue
  } else {
    courseSectionIdValue = partValue || ''
  }
  const cleanedParams = cleanParamsAPI(
    getParams(
      queryParam.text ?? '',
      queryParam.course ?? '',
      queryParam.sortType ?? '',
      queryParam.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParam.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      dateField,
      courseSectionIdValue ?? '',
    )
  )

  const deleteItemTopic = async (id: string) => {
    try {
      await QuestionBankAPI.deleteTopic(id)
    } catch (error: any) {
      const res = error?.response?.data?.error?.code
      if (res === '400|030022' || res === '400|030208') {
        toast.error('Test/Quiz/Case Study included the question(s).')
      }
    }
  }

  const blockTopics = async () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text').trimStart().trimEnd(),
        replaceValueAll(getValues('course')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at',
        courseSectionIdValue || '',
      )
    )
    setLoading(true)

    try {
      for (const id of checkedList) {
        await deleteItemTopic(id)
      }
    } catch (error) {
    } finally {
      toggleCheckAll(!listDataChecked)
      setOpenBlocked(false)
      setLoading(false)
      fetchTopic(1, 10, cleanedParams)
    }
  }
  const { profileMe } = useUserContext()
  const allowRenderCreateTopic: boolean = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) || role.code === CODE_ADMIN.SUPER_ADMIN) || false;
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.topicList} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-10 border-0 pt-10'>
          <div>
            <div className='row'>
              {/* begin:: filter */}
              {/* <div className='col-sm-6 px-0'> */}
              <div className='col-xl-3 col-sm-4'>
                <div className='card-title justify-content-center mb-0 mx-0'>
                  <HookFormTextField
                    control={control}
                    name='text'
                    placeholder='Search'
                    defaultValue={queryParam.text ?? ''}
                    className='sapp-h-45px'
                    onSubmit={onSubmit}
                  />
                </div>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='course'
                  placeholder='Course'
                  control={control}
                  size='large'
                  className='sapp-h-45px'
                  showSearch
                  handleNextPage={handleNextPage}
                  onSearch={async (e) => {
                    debouncedGetCourses(e)
                    return
                  }}
                  onFocus={async () => {
                    if (listCourse?.course?.data.length <= 0) {
                      await getCourses()
                      return
                    }
                  }}
                  onChange={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    getListSort({parentId: e, type: 'part', parentType: 'course', isSelect: true})
                  }}
                  loading={listCourse.course.loading}
                  allowClear
                  onClear={() => {
                    setListCourse(clearChildren('course'))
                  }}
                >
                  {listCourse.course.data?.map((e) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='part'
                  placeholder='Part'
                  control={control}
                  size='large'
                  showSearch
                  className='sapp-h-45px'
                  loading={listCourse.part.loading}
                  onSearch={async (e) => {
                    debouncedGetListSort({
                      type: 'part',
                      name: e,
                      parentId: listCourse.course.id,
                      parentType: 'course',
                    })
                    return
                  }}
                  onChange={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    getListSort({parentId: e, type: 'chapter', parentType: 'part', isSelect: true})
                  }}
                  handleNextPage={() => handleNextListSort('part', 'course')}
                  allowClear
                  onClear={() => {
                    setListCourse(clearChildren('part'))
                  }}
                >
                  {listCourse.part.data?.map((e) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <HookFormSelectAntd
                  name='chapter'
                  placeholder='Chapter'
                  control={control}
                  size='large'
                  className='sapp-h-45px'
                  showSearch
                  loading={listCourse.chapter.loading}
                  onSearch={async (e) => {
                    debouncedGetListSort({
                      type: 'chapter',
                      name: e,
                      parentId: listCourse.part.id,
                      parentType: 'part',
                    })
                    return
                  }}
                  onChange={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    getListSort({parentId: e, type: 'unit', parentType: 'chapter', isSelect: true})
                  }}
                  allowClear
                  onClear={() => {
                    setListCourse(clearChildren('chapter'))
                  }}
                  handleNextPage={() => handleNextListSort('chapter', 'part')}
                >
                  {listCourse.chapter.data?.map((e) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>

              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  name='unit'
                  placeholder='Unit'
                  control={control}
                  size='large'
                  className='sapp-h-45px'
                  showSearch
                  loading={listCourse.unit.loading}
                  onSearch={async (e) => {
                    debouncedGetListSort({
                      type: 'unit',
                      name: e,
                      parentId: listCourse.chapter.id,
                      parentType: 'chapter',
                    })
                    return
                  }}
                  onChange={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    getListSort({parentId: e, type: 'activity', parentType: 'unit', isSelect: true})
                  }}
                  allowClear
                  onClear={() => {
                    setListCourse(clearChildren('unit'))
                  }}
                  handleNextPage={() => handleNextListSort('unit', 'chapter')}
                >
                  {listCourse.unit.data?.map((e) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  name='activity'
                  placeholder='Activity'
                  control={control}
                  size='large'
                  className='sapp-h-45px'
                  showSearch
                  loading={listCourse.activity.loading}
                  onSearch={async (e) => {
                    debouncedGetListSort({
                      type: 'activity',
                      name: e,
                      parentId: listCourse.unit.id,
                      parentType: 'unit',
                    })
                    return
                  }}
                  allowClear
                  handleNextPage={() => handleNextListSort('activity', 'unit')}
                >
                  {listCourse.activity.data?.map((e) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>

              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort By'
                  control={control}
                  size='large'
                  defaultValue={queryParam.sortType ?? ''}
                  className='sapp-h-45px'
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParam.fromDate ?? ''}
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParam.toDate ?? ''}
                />
              </div>
              {/* end:: filter */}
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={handleSubmit(onSubmit)}
                  resetClick={handleResetFilter}
                  disabled={loading}
                  loading={loading}
                />
              </div>

              {checkedList.length > 0 ? (
                <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
                  <ListGrouping
                    okButtonCaption='Yes'
                    cancelButtonCaption='No'
                    body='Bạn có chắc chắn muốn xóa không?'
                    selected={checkedList}
                    blockUser={blockTopics}
                    openBlocked={openBlocked}
                    setOpenBlocked={setOpenBlocked}
                    title={'Delete Selected'}
                  />
                </div>
              ) : (
                <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
                  <div className='card-toolbar justify-content-end d-flex my-0'>
                    {allowRenderCreateTopic  && (
                       <ButtonIcon
                       title={LANG_SIDEBAR.addItemSet}
                       className='sapp-height-min--content'
                       link={`${PageLink.TOPIC + (location.search || '')}`}
                     >
                       <KTIcon iconName='plus' className='fs-2' />
                     </ButtonIcon>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <TableList
          topicList={topicList}
          setTopicList={setTopicList}
          cleanedParams={cleanedParams}
          loading={loading}
          setLoading={setLoading}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          fetchTopic={fetchTopic}
          queryParam={queryParam}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(TopicList)
