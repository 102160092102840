import {useEffect, useState} from 'react'
import {PageLink} from 'src/constants'
import ChangeEmail from './ChangeEmail'
import LoadingSignInMethod from './loading/LoadingSignInMethod'
import ResetPassword from './ResetPassword'

const SignInMethod = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <>
      {/* {loading ? (
        <LoadingSignInMethod />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Sign-in Method</h3>
            </div>
          </div>

          <div className='collapse show'>
            <div className='card-body border-top p-9'>
              <ChangeEmail />

              <div className='separator separator-dashed my-6'></div>
              <ResetPassword />
              <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
                <i className='ki-outline ki-shield-tick fs-2tx text-primary me-4'></i>

                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0 fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>Secure Your Account</h4>
                    <div className='fs-6 text-gray-700 pe-7'>
                      Two-factor authentication adds an extra layer of security to your account. To
                      log in, in addition you'll need to provide a 6 digit code
                    </div>
                  </div>

                  <a
                    href={PageLink.AUTH_LOGIN}
                    className='btn btn-primary px-6 align-self-center text-nowrap'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_two_factor_authentication'
                  >
                    Enable
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  )
}

export default SignInMethod
