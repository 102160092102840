import React from 'react'
import Earning from './Earning'
import Invoices from './Invoices'
import Statement from './Statement'

const StatementsProfile = () => {
  return (
    <>
      <div className='row g-xxl-9'>
        <Earning />

        <Invoices />
      </div>

      <Statement />
    </>
  )
}

export default StatementsProfile
