import React from 'react'
import {PageLink} from 'src/constants'

const Channels = () => {
  return (
    <div className='card-rounded bg-primary bg-opacity-5 p-10 mb-15'>
      <h2 className='text-dark fw-bold mb-11'>More Channels</h2>

      <div className='d-flex align-items-center mb-10'>
        <i className='ki-outline ki-calendar fs-1 text-primary me-5'></i>

        <div className='d-flex flex-column'>
          <h5 className='text-gray-800 fw-bold'>Project Briefing</h5>

          <div className='fw-semibold'>
            <span className='text-muted'>Check out our</span>

            <a href={PageLink.DASHBOARD} className='link-primary'>
              Support Policy
            </a>
          </div>
        </div>
      </div>

      <div className='d-flex align-items-center mb-10'>
        <i className='ki-outline ki-notification-status fs-1 text-primary me-5'></i>

        <div className='d-flex flex-column'>
          <h5 className='text-gray-800 fw-bold'>More to discuss?</h5>

          <div className='fw-semibold'>
            <span className='text-muted'>Email us to</span>

            <a href={PageLink.DASHBOARD} className='link-primary'>
              support@keenthemes.com
            </a>
          </div>
        </div>
      </div>

      <div className='d-flex align-items-center mb-10'>
        <i className='ki-outline ki-twitter fs-1 text-primary me-5'></i>

        <div className='d-flex flex-column'>
          <h5 className='text-gray-800 fw-bold'>Latest News</h5>

          <div className='fw-semibold'>
            <span className='text-muted'>Follow us at</span>

            <a href={PageLink.DASHBOARD} className='link-primary'>
              KeenThemes Twitter
            </a>
          </div>
        </div>
      </div>

      <div className='d-flex align-items-center'>
        <i className='ki-outline ki-github fs-1 text-primary me-5'></i>

        <div className='d-flex flex-column'>
          <h5 className='text-gray-800 fw-bold'>Github Access</h5>

          <div className='fw-semibold'>
            <span className='text-muted'>Our github repo</span>

            <a href={PageLink.DASHBOARD} className='link-primary'>
              KeenThemes Github
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Channels
