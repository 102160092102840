import { zodResolver } from '@hookform/resolvers/zod'
import { format, parseISO, sub } from 'date-fns'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { NotificationAPI } from 'src/apis/notifications'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import { PageLink, VALIDATION_FILED, VALIDATE_FILED_MAX_LENGTH } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { INotification } from 'src/type/notification'
import { mergeImageToEditor } from 'src/utils/upload'
import { z } from 'zod'
import AddGroupToNoti from './AddGroupToNoti'
import './CreateNotiComponent.scss'
import dayjs from 'dayjs'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import ErrorMessage from 'src/common/ErrorMessage'
import { isUndefined } from 'lodash'
import utc from 'dayjs/plugin/utc';
import { Skeleton } from 'antd'
dayjs.extend(utc);

interface IProps {
  id?: string
}

export const ACTION_STATUS = [
  {
    label: 'Hẹn giờ gửi',
    value: 'TIMER',
  },
  {
    label: 'Gửi ngay',
    value: 'IMMEDIATE',
  },
]


const validationSchema = z.object({
  title: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED).max(500, VALIDATE_FILED_MAX_LENGTH('Name', 500)),
  typeNoti: z.any(),
  typeEmail: z.any(),
  groups: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    )
    .refine((groups) => groups.length >= 1, {
      message: VALIDATION_FILED,
    })
    .default([]),

  action: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
  send_time: z.any(),
  content: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
  files: z.any(),
})

const CreateNotiComponent = ({ id }: IProps) => {
  // const EmailType = [
  //   {
  //     label: 'Notification',
  //     value: 'NOTIFICATION',
  //   },
  //   {
  //     label: 'Email',
  //     value: 'EMAIL',
  //   },
  // ]
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  // const [skeleton, setSkeleton] = useState<boolean>(false)
  const [openAddGroup, setOpenAddGroup] = useState(false)
  const [defaultContent, setDefaultContent] = useState<string>()
  const [disableNotification, setDisableNotification] = useState<boolean>(false)

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    setError
  } = useForm<INotification>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (id) {
      ; (async () => {
        setLoading(true)
        try {
          const { data } = await NotificationAPI.getNotificationById(id)
          if (data) {
            setValue('title', data?.title)
            setValue('typeNoti', ['ALL', 'NOTIFICATION'].includes(data?.type) as any)
            setValue('typeEmail', ['ALL', 'EMAIL'].includes(data?.type) as any)
            setValue(
              'groups',
              data.groups?.map((e) => ({ id: e.id, name: e.name }))
            )
            setValue('action', data?.action)
            setValue('send_time', sub(parseISO(data.send_time), { hours: 7 }))
            setValue('content', data.content)
            const content = await mergeImageToEditor(data.content, [])
            setDefaultContent(content)
            if (!data.files) {
              return
            }
            setValue(
              'files',
              data.files.map((e) => ({
                resource_id: e.resource.id,
                id: e.resource.id,
                name: e.resource.name,
                type: 'attached',
              }))
            )
            // Disable edit noti
            if (data?.status === 'SENT' || data?.status === 'CANCEL' || data?.status === 'RETRIEVE') {
              setDisableNotification(true)
            }
          }
        } catch (error) {
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [id])

  /**
   * Xử lý hành động hủy bỏ bằng cách hiển thị hộp thoại xác nhận và điều hướng trở lại nếu được xác nhận.
   */
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Có',
      cancelButtonCaption: 'Không',
      body: ['Bạn chắc chắn muốn thoát không?'],
      onClick: () => navigate(PageLink.NOTIFICATIONS),
    })
  }

  /**
   * Submit form bằng cách cập nhật hoặc tạo Notification và điều hướng đến trang Notification.
   * @param {INotification} data - Dữ liệu Notification.
   */
  const onSubmit = async (data: INotification) => {
    const isTypeEmail = getValues('typeEmail');
    const isTypeNoti = getValues('typeNoti');
    const isTimerAction = watch('action') === 'TIMER';
    const hasSendTime = !isUndefined(getValues('send_time'));

    if (isTypeEmail || isTypeNoti || (isTimerAction && hasSendTime)) {
      try {
        const submitData = {
          ...data,
          send_time: isTimerAction ? format(data.send_time, 'yyyy-MM-dd HH:mm:ss') : undefined,
          type: isTypeNoti && isTypeEmail ? 'ALL' : isTypeNoti ? 'NOTIFICATION' : isTypeEmail ? 'EMAIL' : '',
          files: data.type !== 'NOTIFICATION' ? data.files : undefined,
          groups: data.groups?.map((e) => e.id),
        }

        if (id) {
          await NotificationAPI.updateNotification(id, submitData)
        } else {
          await NotificationAPI.createNotification(submitData)
        }

        navigate(PageLink.NOTIFICATIONS)
      } catch (error) { }
    }

    if (isUndefined(getValues('typeEmail')) && isUndefined(getValues('typeNoti'))) {
      setError('errorType', { message: VALIDATION_FILED })
    }

    if (watch('action') === 'TIMER' && isUndefined(getValues('send_time'))) {
      setError('send_time', { message: VALIDATION_FILED })
    }
  }

  useEffect(() => {
    setError('errorType', { message: '' })
  }, [watch('typeEmail'), watch('typeNoti')])

  // Xóa msg error của group khi có thay đổi
  useEffect(() => {
    if (watch('groups')) {
      watch('groups').length > 0 && setError('groups', { message: '' })
    }
  }, [watch('groups')])

  const filesFormAddNew = watch('files')
  const watchGroups = watch('groups')

  /**
   * Xử lý sự kiện onchange cho input file, cập nhật trường 'files' trong form.
   * @param {any} files - Các file đã chọn.
   */
  const handleOnchangeFile = (files: any) => {
    setValue(
      'files',
      files.map((e: { id: string; name: string; resource_id: string }) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name,
        type: 'attached',
      }))
    )
  }

  const handlePopupRemove = (groupIdToRemove: string) => {
    confirm({
      okButtonCaption: 'Có',
      cancelButtonCaption: 'Không',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => removeGroupById(groupIdToRemove),
    })
  }

  /**
   * Xóa một group khỏi trường 'groups' bằng ID.
   * @param {string} groupIdToRemove - ID của group cần xóa.
   */
  const removeGroupById = (groupIdToRemove: string) => {
    const updatedGroups = getValues('groups').filter((g) => g.id !== groupIdToRemove)
    setValue('groups', updatedGroups)
  }

  /**
   * Đặt giá trị cho trường 'groups'.
   * @param {any} group - Dữ liệu groups.
   */
  const setGroups = (group: any) => {
    setValue('groups', group)
  }

  /**
   * Xác định xem một ngày có bị vô hiệu hóa hay không.
   * @param {dayjs.Dayjs} current - Ngày hiện tại.
   * @returns {boolean} - True nếu ngày bị vô hiệu hóa, ngược lại là false.
   */
  const disabledDate = (current: dayjs.Dayjs) => {
    const localCurrent = dayjs.utc(current).local(); // Chuyển đổi về múi giờ địa phương
    return localCurrent.isBefore(dayjs(), 'day'); // Kiểm tra nếu thời gian hiện tại đã qua
  };

  /**
   * Xác định xem một thời gian có bị vô hiệu hóa hay không.
   * @param {dayjs.Dayjs} current - Thời gian hiện tại.
   * @returns {Object} - Một đối tượng chỉ định giờ và phút bị vô hiệu hóa.
   */
  const disabledTime = (current: dayjs.Dayjs | null) => {
    let now = new Date()

    if (current && current.isSame(now, 'day')) {
      let hour = now.getHours()
      let minute = now.getMinutes()

      return {
        disabledHours: () => {
          let hours = Array.from({ length: hour }, (x, i) => i)
          if (minute > 30) {
            hours.push(hour)
          }
          return hours
        },
        disabledMinutes: (selectedHour: any) => {
          if (selectedHour === hour) {
            return Array.from({ length: minute + 1 }, (x, i) => i)
          }
          return []
        },
      }
    }
  }

  return (
    <div>
      {contextHolder}
      <div className='px-10'>
        <div>
          <SappLabel required label={'Title'}></SappLabel>
          <div className='sapp-mb-32px'>
            <HookFormTextField
              control={control}
              name='title'
              placeholder=''
              className='w-100 sapp-h-45px fs-6'
              disabled={disableNotification}
              skeleton={loading}
            ></HookFormTextField>
          </div>
        </div>
        <div className='sapp-mb-32px'>
          <SappLabel required label={`Type`} className={'mb-5'}></SappLabel>
          {/* <div className='d-flex gap-20'> */}
          <div className='d-flex'>
            <div className='w-250px'>
              <HookFormCheckBox
                name='typeNoti'
                control={control}
                title='Notification'
                classLabel='me-0'
                className='sapp-checkbox--icon'
                disabled={disableNotification}
                loading={loading}
              />
            </div>
            <HookFormCheckBox
              name='typeEmail'
              control={control}
              title='Email'
              classLabel='ms-0'
              className='sapp-checkbox--icon'
              disabled={disableNotification}
              loading={loading}
            />
          </div>
          {/* </div> */}
          {errors.errorType?.message && <ErrorMessage>{errors.errorType?.message}</ErrorMessage>}
        </div>
        <div className='sapp-mb-32px'>
          {loading ? <Skeleton.Button active block /> : <SappLabel required label={`Send to`}></SappLabel>}
          {
            loading ? (
              <Skeleton.Button active block className='sapp-h-45px' />
            ) : (
              <div
                onClick={(e) => {
                  const target = e.target as HTMLElement
                  if (!target.classList.contains('sapp-create-noti_group_icon') && !disableNotification) {
                    setOpenAddGroup(true)
                  }
                }}
                className='d-flex sapp-create-noti_group align-items-center'
              >
                <div className='sapp-create-noti_group_tags d-flex align-items-center'>
                  {watchGroups?.map((e) => {
                    return (
                      <div key={e.id} className='sapp-create-noti_group_items'>
                        <span className='sapp-create-noti_group_text'>{e.name}</span>
                        <div
                          onClick={(f) => {
                            if (!disableNotification) {
                              f.stopPropagation()
                              handlePopupRemove(e.id)
                            }
                          }}
                          className='d-flex'
                        >
                          <KTIcon
                            iconType='outline'
                            iconName='cross'
                            className={`fs-2 sapp-create-noti_group_icon`}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='ms-auto'>
                  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
                    <path
                      fill='#7E8299'
                      d='M11.89 17.24a1 1 0 0 1-.71-.29l-6-6a1.001 1.001 0 1 1 1.41-1.42l5.3 5.3 5.29-5.3a1 1 0 1 1 1.41 1.42l-6 6a1 1 0 0 1-.7.29Z'
                    />
                  </svg>
                </div>
              </div>
            )
          }
          {errors?.groups?.message && <ErrorMessage>{errors?.groups?.message}</ErrorMessage>}
        </div>
        <div className='sapp-mb-32px'>
          <SappLabel required label={`Action`}></SappLabel>
          <div className='w-100'>
            <Row>
              <Col className='align-self-center'>
                {/* <div className='d-flex gap-20'> */}
                <HookFormRadioGroup
                  control={control}
                  name='action'
                  options={ACTION_STATUS}
                  direction='horizontal'
                  separator={false}
                  justify='start'
                  itemWidth={'250px'}
                  className='sapp-checkbox--icon'
                  disabled={disableNotification}
                  loading={loading}
                />
              </Col>
              {
                watch('action') === 'TIMER' && (
                  <Col>
                    <HookFormDateTime
                      control={control}
                      name='send_time'
                      showTime={{ minuteStep: 30, minDate: new Date() }}
                      format='DD/MM/YYYY HH:mm'
                      showNow={false}
                      disabledDate={disabledDate}
                      disabledTime={disabledTime}
                      disabled={disableNotification}
                    />
                  </Col>
                )
              }
            </Row>
          </div>
        </div>
        <div className='sapp-mb-32px'>
          <HookFormEditor
            label='Nội dung'
            required
            control={control}
            name={'content'}
            height={600}
            defaultValue={defaultContent}
            disabled={disableNotification}
            uploadVideoImage={false}
            skeleton={loading}
          />
        </div>
        {(watch('typeEmail')) && (
          <div className='d-flex flex-column sapp-mb-32px'>
            <UploadMulti
              fileList={filesFormAddNew}
              setFileList={handleOnchangeFile}
              error={errors.files}
              resourceLocation={RESOURCE_LOCATION.mail}
              label='Attachment'
              guideline={[
                'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx. Kích thước tối đa 20MB.',
              ]}
              disabled={disableNotification}
            />
          </div>
        )}
      </div>

      <div className='d-flex gap-4 justify-content-end sapp-mb-32px px-10'>
        <ButtonSecondary title='Cancel' onClick={handleCancel} className='' />
        <ButtonPrimary loading={loading} title='Save' onClick={handleSubmit(onSubmit)} disabled={disableNotification} />
      </div>

      <AddGroupToNoti
        open={openAddGroup}
        setOpen={setOpenAddGroup}
        group={watchGroups}
        setGroup={setGroups}
      />
    </div>
  )
}
export default CreateNotiComponent
