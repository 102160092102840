import React, { useEffect, useMemo, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import { QuestionBankAPI } from 'src/apis/question-bank'
import NotData from 'src/components/NotData'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import { IQuestion } from 'src/components/question-bank/shared/interfaces'
import QuestTionPreview from 'preview-question-bank'
import { htmlToRaw } from 'src/utils'
import { normalizeLikeParam } from 'src/utils/string'
import ModalPreviewFile from 'src/components/question-bank/preview-question/ModalPreviewFile'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'

type Props = {
  questions: IQuestion[]
  setQuestions: (value: React.SetStateAction<IQuestion[]>) => void
  confirm: (option: any) => void
  handleChooseQuestions: () => void
  error: FieldError | undefined
  disabled?: boolean
  maxQuestionsNumber?: any
  categoryState: any
  isLocked: boolean
  isOnUsed: boolean
  isEdit: boolean
  index: number
  section: any
  sectionDisable?: boolean
  previousCategoryId: any
  setOpenPopup: any
}

const headers = [
  {
    label: 'List Questions',
    key: 'list_questions',
    className: 'fs-7 lh-1 fw-bold',
  },
  {
    label: 'Type',
    key: 'type',
    className: 'fs-7 sapp-w-180 lh-1 fw-bold',
  },
  {
    label: 'Action',
    key: 'actions',
    className: 'd-flex justify-content-end fs-7 lh-1 fw-bold ',
  },
]

const QuestionList = ({
  questions,
  setQuestions,
  confirm,
  handleChooseQuestions,
  error,
  previousCategoryId,
  setOpenPopup,
  maxQuestionsNumber,
  disabled,
  categoryState,
  isLocked,
  isOnUsed,
  isEdit,
  index,
  section,
  sectionDisable,
}: Props) => {
  const [filter, setFilter] = useState({
    search: '',
    currentPage: 1,
    pageSize: 10,
  })
  const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([])
  const [loadPreview, setLoadPreview] = useState(false)
  const [viewFile, setViewFile] = useState({ status: false, url: undefined })
  const [openPreview, setOpenPreview] = useState<{ value: boolean; data: any }>({
    value: false,
    data: undefined,
  })
  const [sectionStates, setSectionStates] = useState<Array<boolean>>(new Array(section).fill(false));
  const handleClosePreview = () => {
    setOpenPreview({ value: false, data: undefined })
  }
  useEffect(() => {
    if (!filter || typeof filter.search !== 'string' || filter.search.trim() === '') {
      setFilteredQuestions(questions);
    } else {
      const filtered =
        questions?.filter((q) =>
          normalizeLikeParam(htmlToRaw(q.question_content))
            .toLowerCase()
            ?.includes(normalizeLikeParam(filter.search.toLowerCase()))
        ) || [];
      setFilteredQuestions(filtered);
    }
  }, [questions, filter]);
  const [currentQuestions, setCurrentQuestions] = useState([]);

  useEffect(() => {
    if (filteredQuestions && filteredQuestions.length > 0) {
      const startIndex = (filter.currentPage - 1) * filter.pageSize;
      const endIndex = filter.currentPage * filter.pageSize;

      const slicedQuestions = filteredQuestions.slice(startIndex, endIndex);

      setCurrentQuestions(slicedQuestions as any);
    } else {
      setCurrentQuestions(questions as any)
    }
    setCurrentQuestions(questions as any)
  }, [filteredQuestions, filter.currentPage, filter.pageSize, questions]);

  const handleChangePage = (page: number, pageSize: number) => {
    setFilter({ ...filter, currentPage: page, pageSize })
  }

  const handleSearchChange = (e: any) => {
    const newValue = e.target.value;
    setSearchParams(newValue);
    if (!newValue.trim()) {
      setFilter({ ...filter, currentPage: 1, search: '' });
    }
  };
  const initialValues: any = {
    text: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const handleSearchActive = () => {
    setFilter({ ...filter, currentPage: 1, search: searchParams });
  };
  return (
    <div className='card card-bordered'>
      <div className='card-body'>
        <div
          className={`d-flex justify-content-between align-items-start gap-5 mb-5
          `}
        >
          <div className='d-flex'>
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search Questions'}
                smallSearch={true}
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearchActive()
                }}
                className='sapp-btn-active-search sapp-font-import-student ms-4'
              />
            </div>
          </div>
          {!isLocked && !isOnUsed && isEdit && !sectionDisable && (
            <ButtonIcon
              title='Add Question'
              className='sapp-height-min--content w-auto '
              onClick={() => {
                if (previousCategoryId !== categoryState && previousCategoryId !== '' && questions.length > 0) {
                  setOpenPopup(true);
                  return;
                }
                const newSectionStates = [...sectionStates];
                newSectionStates[index] = !newSectionStates[index];
                setSectionStates(newSectionStates);
                handleChooseQuestions();
              }}
              disabled={(questions && questions?.length > 0 && maxQuestionsNumber !== 'NO_MAX' && questions?.length === maxQuestionsNumber) || disabled}
            >
              <KTIcon iconName='plus' className='fs-2' />
            </ButtonIcon>
          )}

        </div>
        <table className='table align-middle fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
              <th className='w-40px' style={{ lineHeight: '12px' }}>
                #
              </th>
              {headers?.map((column) => (
                <th key={column.label} className={`${column.className} fs-7 fw-bold`}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {
              currentQuestions?.length === 0 ?
                (
                  <tr>
                    <td colSpan={7}>
                      <NotData />
                    </td>
                  </tr>
                ) : (
                  <>
                    {
                      currentQuestions?.map((question: any, i) => (
                        <tr key={question.id}>
                          <td>{i + 1 + (filter.currentPage - 1) * filter.pageSize}</td>
                          <td>
                            <div className='sapp-text-truncate-1 text-wrap text-break'>
                              {htmlToRaw(question.question_content)}
                            </div>
                          </td>
                          <td>{question.qType}</td>
                          <td className='w-50px'>
                            <div className='d-flex justify-content-between align-items-center gap-3'>
                              <ButtonIconOnly
                                iconName={'eye'}
                                activeColor='primary'
                                bg='gray-100'
                                onClick={async () => {
                                  setLoadPreview(true)
                                  const res = await QuestionBankAPI.getQuestionById(question?.id)
                                  if (res.data.question_topic_id) {
                                    const { data: topic } = await QuestionBankAPI.getTopicById(
                                      res.data.question_topic_id
                                    )
                                    setOpenPreview({
                                      value: true,
                                      data: { questions: res.data, topic: topic.description },
                                    })
                                  } else {
                                    setOpenPreview({
                                      value: true,
                                      data: { questions: res.data, topic: '' },
                                    })
                                  }
                                  setLoadPreview(false)
                                }}
                              />
                              {!isLocked && !isOnUsed && isEdit && !sectionDisable && (
                                <ButtonIconOnly
                                  bg='gray-100'
                                  iconName={'trash'}
                                  activeColor='danger'
                                  disabled={disabled}
                                  onClick={() => {
                                    setTimeout(() => {
                                      confirm({
                                        okButtonCaption: 'Yes',
                                        cancelButtonCaption: 'No',
                                        body: 'Bạn có chắc chắn muốn xóa không?',
                                        onClick: () => {
                                          const arr = [...filteredQuestions]
                                          const newArr = arr?.filter((f) => f.id !== question.id)
                                          if (arr.length === 1) {
                                            setQuestions([])
                                          }
                                          if (newArr.length === 0 && filter.currentPage > 0) {
                                            handleChangePage(filter.currentPage, filter.pageSize)
                                          } else {
                                            setQuestions(newArr)
                                          }
                                        },
                                      })
                                    })
                                  }}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </>
                )
            }
          </tbody>
        </table>
        <PagiantionSAPP
          currentPage={filter.currentPage}
          handlePaginationChange={(page: number, pageSize: number) => {
            handleChangePage(page, pageSize)
          }}
          pageSize={filter.pageSize}
          totalItems={filteredQuestions?.length}
        />
        <QuestTionPreview
          data={openPreview?.data?.questions}
          type={openPreview?.data?.questions?.qType}
          topicDescription={openPreview?.data?.topic?.description}
          topic={openPreview?.data?.topic}
          handleClose={handleClosePreview}
          setOpenViewFile={setViewFile}
          open={openPreview.value}
        />
      </div>
      <ModalPreviewFile open={viewFile.status} setOpen={setViewFile} url={viewFile.url} />
    </div>
  )
}

export default QuestionList
