interface IProps {
  title: string
}

const PermissionInfo = ({title}: IProps) => {
  return (
    <div className='d-flex align-items-center py-2'>
      <span className='bullet bg-primary me-3'></span>
      <span className='text-capitalize sapp-color-gray-role'>{title}</span>
    </div>
  )
}

export default PermissionInfo
