import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {usersColumns} from './columns/_columns'
import {KTCardBody} from 'src/_metronic/helpers'
import {NewsListPagination} from 'src/components/news-list/components/pagination/NewsListPagination'
import {User} from 'src/components/user-list/components/core/_models'
import {useQueryResponseData} from 'src/components/news-list/components/core/QueryResponseProvider'
import NotData from 'src/components/NotData'
import {Skeleton} from 'antd'

const NewsTable = () => {
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<User>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {loading ? (
                <>
                  {headers.map((header) => (
                    <tr key={header.id}>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                      <td>
                        <Skeleton.Input size='large' active />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {rows.length > 0 ? (
                    rows.map((row: Row<User>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <NotData />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <NewsListPagination />
      </KTCardBody>
    </>
  )
}

export {NewsTable}
