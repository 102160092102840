import React, {useState} from 'react'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import {PageLink} from 'src/constants'
import ModalNewCard from './ModalNewCard'

const PaymentMethods = () => {
  const [openCard, setOpenCard] = useState<boolean>(false)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header card-header-stretch pb-0'>
        <div className='card-title'>
          <h3 className='m-0'>Payment Methods</h3>
        </div>

        <div className='card-toolbar m-0'>
          <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_billing_creditcard_tab'
                className='nav-link fs-5 fw-bold me-5 active'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_billing_creditcard'
              >
                Credit / Debit Card
              </a>
            </li>

            <li className='nav-item' role='presentation'>
              <a
                id='kt_billing_paypal_tab'
                className='nav-link fs-5 fw-bold'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_billing_paypal'
              >
                Paypal
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div id='kt_billing_payment_tab_content' className='card-body tab-content'>
        <div id='kt_billing_creditcard' className='tab-pane fade show active' role='tabpanel'>
          <h3 className='mb-5'>My Cards</h3>

          <div className='row gx-9 gy-6'>
            <div className='col-xl-6' data-kt-billing-element='card'>
              <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                <div className='d-flex flex-column py-2'>
                  <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                    Marcus Morris
                    <span className='badge badge-light-success fs-7 ms-2'>Primary</span>
                  </div>

                  <div className='d-flex align-items-center'>
                    <img src='assets/media/svg/card-logos/visa.svg' alt='' className='me-4' />

                    <div>
                      <div className='fs-4 fw-bold'>Visa **** 1679</div>
                      <div className='fs-6 fw-semibold text-gray-400'>Card expires at 09/24</div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center py-2'>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    data-kt-billing-action='card-delete'
                  >
                    <span className='indicator-label'>Delete</span>

                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary'
                    onClick={() => setOpenCard(true)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <div className='col-xl-6' data-kt-billing-element='card'>
              <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                <div className='d-flex flex-column py-2'>
                  <div className='d-flex align-items-center fs-4 fw-bold mb-5'>Jacob Holder</div>

                  <div className='d-flex align-items-center'>
                    <img
                      src='assets/media/svg/card-logos/american-express.svg'
                      alt=''
                      className='me-4'
                    />

                    <div>
                      <div className='fs-4 fw-bold'>Mastercard **** 2040</div>
                      <div className='fs-6 fw-semibold text-gray-400'>Card expires at 10/22</div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center py-2'>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    data-kt-billing-action='card-delete'
                  >
                    <span className='indicator-label'>Delete</span>

                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary'
                    onClick={() => setOpenCard(true)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <div className='col-xl-6' data-kt-billing-element='card'>
              <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                <div className='d-flex flex-column py-2'>
                  <div className='d-flex align-items-center fs-4 fw-bold mb-5'>Jhon Larson</div>

                  <div className='d-flex align-items-center'>
                    <img src='assets/media/svg/card-logos/mastercard.svg' alt='' className='me-4' />

                    <div>
                      <div className='fs-4 fw-bold'>Mastercard **** 1290</div>
                      <div className='fs-6 fw-semibold text-gray-400'>Card expires at 03/23</div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center py-2'>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    data-kt-billing-action='card-delete'
                  >
                    <span className='indicator-label'>Delete</span>

                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary'
                    onClick={() => setOpenCard(true)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='notice d-flex bg-secondary rounded border-primary border border-dashed h-lg-100 p-6'>
                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0 fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>Important Note!</h4>
                    <div className='fs-6 text-gray-700 pe-7'>
                      Please carefully read
                      <a href={PageLink.DASHBOARD} className='fw-bold me-1'>
                        {' '}
                        Product Terms
                      </a>
                      adding
                      <br />
                      your new payment card
                    </div>
                  </div>

                  <div
                    className='btn btn-primary px-6 align-self-center text-nowrap'
                    onClick={() => setOpenCard(true)}
                  >
                    Add Card
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id='kt_billing_paypal'
          className='tab-pane fade'
          role='tabpanel'
          aria-labelledby='kt_billing_paypal_tab'
        >
          <h3 className='mb-5'>My Paypal</h3>

          <div className='text-gray-600 fs-6 fw-semibold mb-5'>
            To use PayPal as your payment method, you will need to make pre-payments each month
            before your bill is due.
          </div>

          <form className='form'>
            <div className='mb-7 mw-350px'>
              <SAPPSelect
                size='large'
                options={[
                  {
                    label: 'Role',
                    value: 'Role',
                  },
                  {
                    label: 'One',
                    value: 'One',
                  },
                ]}
              />
            </div>
            <button type='submit' className='btn btn-primary'>
              Pay with Paypal
            </button>
          </form>
        </div>
        <ModalNewCard openCard={openCard} setOpenCard={setOpenCard} />
      </div>
    </div>
  )
}

export default PaymentMethods
