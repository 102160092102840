import React from 'react'

const Videos = () => {
  return (
    <div className='col-md-6'>
      <div className='card card-md-stretch ms-xl-3'>
        <div className='card-body pp-10 p-lg-15'>
          <div className='d-flex flex-stack mb-7'>
            <h1 className='fw-bold text-dark'>Videos</h1>

            <div className='d-flex align-items-center'>
              <a
                href='https://www.youtube.com/c/KeenThemesTuts/videos'
                // target='_blank'
                className='text-primary fw-bold me-1'
              >
                All Videos
              </a>

              <i className='ki-outline ki-arrow-right fs-2 text-primary'></i>
            </div>
          </div>

          <div className='mb-3'>
            <iframe
              className='embed-responsive-item card-rounded h-275px w-100'
              src='https://www.youtube.com/embed/TWdDZYNqlg4'
              title='video'
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Videos
