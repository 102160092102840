import {zodResolver} from '@hookform/resolvers/zod'
import React, {useMemo} from 'react'
import {useForm} from 'react-hook-form'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink, VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import {ITabs} from 'src/type'
import {toast} from 'react-hot-toast'
import {useLocation} from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'

interface ICheckBox {
  read: boolean
  write: boolean
  create: boolean
}

interface IInputProps {
  selectAll: string | boolean
  user: ICheckBox
  content: ICheckBox
  financial: ICheckBox
  reporting: ICheckBox
  payroll: ICheckBox
  disputes: ICheckBox
  controls: ICheckBox
  database: ICheckBox
  repository: ICheckBox
}

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Account',
  },
]

const ApplyRoles = () => {
  const location = useLocation()
  // check condition pageTitle
  const isPageTitle = location.pathname === PageLink.ADD_ROLES ? true : false

  // show text title
  const pageTitle = useMemo(() => {
    if (!location.pathname) return

    if (isPageTitle) {
      return 'Add Roles'
    }
    return 'Update Roles'
  }, [location.pathname, isPageTitle])

  //validate for input
  const validationSchema = z.object({
    roleName: z.string({required_error: VALIDATION_FILED}).min(1),
    user: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    content: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    financial: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    reporting: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    payroll: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    disputes: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    controls: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    database: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
    repository: z.object({
      read: z.boolean().optional(),
      write: z.boolean().optional(),
      create: z.boolean().optional(),
    }),
  })

  const {handleSubmit, control, setValue, watch, register} = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  // function select all
  const handleSelectAll = (checked: boolean) => {
    setValue('selectAll', checked)
    setValue('user.read', checked)
    setValue('user.write', checked)
    setValue('user.create', checked)
    setValue('content.read', checked)
    setValue('content.write', checked)
    setValue('content.create', checked)
    setValue('financial.read', checked)
    setValue('financial.write', checked)
    setValue('financial.create', checked)
    setValue('reporting.read', checked)
    setValue('reporting.write', checked)
    setValue('reporting.create', checked)
    setValue('payroll.read', checked)
    setValue('payroll.write', checked)
    setValue('payroll.create', checked)
    setValue('disputes.read', checked)
    setValue('disputes.write', checked)
    setValue('disputes.create', checked)
    setValue('controls.read', checked)
    setValue('controls.write', checked)
    setValue('controls.create', checked)
    setValue('database.read', checked)
    setValue('database.write', checked)
    setValue('database.create', checked)
    setValue('repository.read', checked)
    setValue('repository.write', checked)
    setValue('repository.create', checked)
  }

  // function change checkbox
  const handleCheckboxChange = (checkboxName: string) => {
    const selectAllChecked = watch('selectAll')
    const checkboxChecked = watch(checkboxName as any)

    if (!checkboxChecked && selectAllChecked) {
      setValue('selectAll', false)
    }

    if (checkboxChecked && !selectAllChecked) {
      const allCheckboxesChecked =
        watch('user.read') &&
        watch('user.write') &&
        watch('user.create') &&
        watch('content.write') &&
        watch('content.write') &&
        watch('content.write') &&
        watch('financial.write') &&
        watch('financial.write') &&
        watch('financial.write') &&
        watch('reporting.write') &&
        watch('reporting.write') &&
        watch('reporting.write') &&
        watch('payroll.write') &&
        watch('payroll.write') &&
        watch('payroll.write') &&
        watch('disputes.write') &&
        watch('disputes.write') &&
        watch('disputes.write') &&
        watch('controls.write') &&
        watch('controls.write') &&
        watch('controls.write') &&
        watch('database.write') &&
        watch('database.write') &&
        watch('database.write') &&
        watch('repository.write') &&
        watch('repository.write') &&
        watch('repository.write')

      if (allCheckboxesChecked) {
        setValue('selectAll', true)
      }
    }
  }

  // function submit form
  const onSubmit = (data: any) => {
    toast.success('Success!')
  }

  return (
    <PageLayouts pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      <div className='card mb-5 mb-xl-10'>
        {/* start:: header */}
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>{pageTitle}</h3>
          </div>
        </div>
        {/* end:: header */}

        <div id='kt_account_settings_profile_details' className='collapse show'>
          <form className='form'>
            <div className='card-body border-top p-9'>
              <div className=' mb-6'>
                {/* start:: textfield roleName */}
                <div className='col-lg-12 fv-row'>
                  <HookFormTextField
                    name='roleName'
                    control={control}
                    placeholder='Role name'
                    label='Role name'
                  />
                </div>
                {/* end:: textfield roleName */}

                <div className='fv-row mt-10'>
                  {/* start:: label */}
                  <label className='fs-5 fw-bold form-label mb-2'>Role Permissions</label>
                  {/* end:: label */}

                  <div className='table-responsive'>
                    {/* start:: table */}
                    <table className='table align-middle table-row-dashed fs-6 gy-5'>
                      <tbody className='text-gray-600 fw-semibold'>
                        {/* start:: body table */}
                        <tr>
                          <td className='text-gray-800'>
                            Administrator Access
                            <span
                              className='ms-1'
                              data-bs-toggle='tooltip'
                              title='Allows a full access to the system'
                            >
                              <i className='ki-outline ki-information-5 text-gray-500 fs-6'></i>
                            </span>
                          </td>
                          <td>
                            <label className='form-check form-check-sm form-check-custom  me-9'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                {...register('selectAll')}
                                onChange={(e) => handleSelectAll(e.target.checked as any)}
                              />
                              <span className='form-check-label'>Select all</span>
                            </label>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>User Management</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='user.read'
                                  control={control}
                                  checked={watch('user.read') || watch('selectAll')}
                                  onChange={() => handleCheckboxChange('user.read')}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='user.write'
                                  control={control}
                                  checked={watch('selectAll') || watch('user.write')}
                                  onChange={() => handleCheckboxChange('user.write')}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='user.create'
                                  control={control}
                                  checked={watch('selectAll') || watch('user.create')}
                                  onChange={() => handleCheckboxChange('user.create')}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Content Management</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='content.read'
                                  checked={watch('selectAll') || watch('content.read')}
                                  onChange={() => handleCheckboxChange('content.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='content.write'
                                  checked={watch('selectAll') || watch('content.write')}
                                  onChange={() => handleCheckboxChange('content.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='content.create'
                                  checked={watch('selectAll') || watch('content.create')}
                                  onChange={() => handleCheckboxChange('content.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Financial Management</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='financial.read'
                                  control={control}
                                  checked={watch('selectAll') || watch('financial.read')}
                                  onChange={() => handleCheckboxChange('financial.read')}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='financial.write'
                                  control={control}
                                  checked={watch('selectAll') || watch('financial.write')}
                                  onChange={() => handleCheckboxChange('financial.write')}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>
                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='financial.create'
                                  control={control}
                                  checked={watch('selectAll') || watch('financial.create')}
                                  onChange={() => handleCheckboxChange('financial.create')}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Reporting</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='reporting.read'
                                  checked={watch('selectAll') || watch('reporting.read')}
                                  onChange={() => handleCheckboxChange('reporting.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='reporting.write'
                                  checked={watch('selectAll') || watch('reporting.write')}
                                  onChange={() => handleCheckboxChange('reporting.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='reporting.create'
                                  checked={watch('selectAll') || watch('reporting.create')}
                                  onChange={() => handleCheckboxChange('reporting.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Payroll</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='payroll.read'
                                  checked={watch('selectAll') || watch('payroll.read')}
                                  onChange={() => handleCheckboxChange('payroll.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='payroll.write'
                                  checked={watch('selectAll') || watch('payroll.write')}
                                  onChange={() => handleCheckboxChange('payroll.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='payroll.create'
                                  checked={watch('selectAll') || watch('payroll.create')}
                                  onChange={() => handleCheckboxChange('payroll.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Disputes Management</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='disputes.read'
                                  checked={watch('selectAll') || watch('disputes.read')}
                                  onChange={() => handleCheckboxChange('disputes.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='disputes.write'
                                  checked={watch('selectAll') || watch('disputes.write')}
                                  onChange={() => handleCheckboxChange('disputes.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='disputes.create'
                                  checked={watch('selectAll') || watch('disputes.create')}
                                  onChange={() => handleCheckboxChange('disputes.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>API Controls</td>
                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='controls.read'
                                  checked={watch('selectAll') || watch('controls.read')}
                                  onChange={() => handleCheckboxChange('controls.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='controls.write'
                                  checked={watch('selectAll') || watch('controls.write')}
                                  onChange={() => handleCheckboxChange('controls.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='controls.create'
                                  checked={watch('selectAll') || watch('controls.create')}
                                  onChange={() => handleCheckboxChange('controls.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Database Management</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='database.read'
                                  checked={watch('selectAll') || watch('database.read')}
                                  onChange={() => handleCheckboxChange('database.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='database.write'
                                  checked={watch('selectAll') || watch('database.write')}
                                  onChange={() => handleCheckboxChange('database.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='database.create'
                                  checked={watch('selectAll') || watch('database.create')}
                                  onChange={() => handleCheckboxChange('database.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='text-gray-800'>Repository Management</td>

                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='repository.read'
                                  checked={watch('selectAll') || watch('repository.read')}
                                  onChange={() => handleCheckboxChange('repository.read')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>

                              <label className='form-check form-check-custom  me-5 me-lg-20'>
                                <HookFormCheckBox
                                  name='repository.write'
                                  checked={watch('selectAll') || watch('repository.write')}
                                  onChange={() => handleCheckboxChange('repository.write')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Write</span>
                              </label>

                              <label className='form-check form-check-custom '>
                                <HookFormCheckBox
                                  name='repository.create'
                                  checked={watch('selectAll') || watch('repository.create')}
                                  onChange={() => handleCheckboxChange('repository.create')}
                                  control={control}
                                  defaultValue={false}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        {/* end:: body table */}
                      </tbody>
                    </table>
                    {/* end:: table */}
                  </div>
                </div>
              </div>
            </div>

            {/* start:: dialog button */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary title='Discard' className='btn-active-light-primary me-2' />
              <ButtonPrimary title='Save Changes' onClick={handleSubmit(onSubmit)} />
            </div>
            {/* end:: dialog button */}
          </form>
        </div>
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ApplyRoles)
