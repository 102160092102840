import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type'

const ButtonPrimary = ({
  title,
  onClick,
  className = '',
  link,
  loading,
  type = 'button',
  bg,
  disabled,
  classNameTitle
}: IButtonProps) => {
  if (link)
    return (
      <Link to={link} className={`${className} btn btn-sm btn-${bg ?? 'primary'} align-self-center ${classNameTitle ?? ''}`}>
        {title}
      </Link>
    )

  return (
    <button disabled={disabled || loading} type={type} className={`${className} btn btn-${bg ?? 'primary'}`} onClick={onClick}>
      {loading ? (
        <span className='indicator-progress d-block'>
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        <span className={`${classNameTitle ?? ''} indicator-label`}>{title}</span>
      )}
    </button>
  )
}

export default ButtonPrimary
