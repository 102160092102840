import {NewsListSearchComponent} from './NewsListSearchComponent'
import {NewsListToolbar} from './NewsListToolbar'

const NewsListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <div className='container'>
        <div className='row'>
          {/* start:: search component */}
          <div className='col-sm-3'>
            <NewsListSearchComponent />
          </div>
          {/* end:: search component */}

          {/* start:: filter section */}
          <div className='col-sm-6'>
            {/* <FilterSection /> */}
          </div>
          {/* end:: filter section */}

          {/* start:: check list group or list toolbar */}
          <div className='col-sm-3'>
            <div className='card-toolbar justify-content-center'>
              <NewsListToolbar />
            </div>
          </div>
          {/* end:: check list group or list toolbar */}
        </div>
      </div>
    </div>
  )
}

export {NewsListHeader}
