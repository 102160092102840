import PageLayouts from 'src/components/layout/PageLayouts'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  PageLink,
  STATUS_FORM,
  VALIDATION_FILED,
  MESSAGE_VALIDATION_USERNAME,
  VALIDATE_PHONE,
  VALIDATE_PASSWORD,
  GUIDELINE_USERNAME,
  GUIDELINE_PHONE,
  GUIDELINE_PASSWORD,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_USERNAME,
  GUIDELINE_FULLNAME,
  GUIDELINE_EMAIL,
  GENDER_FORM,
} from 'src/constants'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {EStatusUser, IError, ITabs} from 'src/type'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import withAuthComponents from 'src/components/auth/with-auth-components'
import {phoneRegExp, sizeInBytes, usernamePattern, validatePassword} from 'src/utils'
import {createStaffs, StaffAPI, uploadAvatarStaff} from 'src/apis/staffs'
import {toast} from 'react-hot-toast'
import {IStaffs} from 'src/type/staffs.'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import {debounce, isEmpty, isUndefined} from 'lodash'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {Select} from 'antd'
import {useEffect, useMemo, useState} from 'react'
import {BUTTON_TEXT, LANG_PLACEHOLDER} from 'src/constants/lang'
import {RolesAPI} from 'src/apis/roles'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import {useNavigate, useParams} from 'react-router-dom'
import {IStudentDetail} from 'src/type/students'
import {useConfirm} from 'src/hooks/use-confirm'
const {Option} = Select

export interface IUpdateStaff {
  full_name: string
  email: string
  phone: number
  status: string
  roles: Array<any>
  avatar: any
  sex: string
}

const CreateAndUpdateStaff = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [roles, setRoles] = useState<any>()
  const [staffDetail, setStaffDetail] = useState<IStudentDetail>()
  const {id} = useParams()
  const navigate = useNavigate()
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [loading, setLoading] = useState(false)

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.STAFFS}`,
      title: 'List Staffs',
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: `${id ? 'Update Staff' : 'Create Staff'}`,
    },
  ]

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get( page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.meta?.total_pages
    const pageIndex = roles?.meta?.page_index as number
    const pageSize = roles?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const results = roles?.concat(res?.data?.roles) 
      setRoles({
        meta: res?.data?.meta,
        roles: results,
      })
    }
  }

  const getRoles = async ({params}: any) => {
    const res: any = await fetchRoles(1, 20, params)
    setRoles(res?.data?.roles)
  }

  useEffect(() => {
    getRoles({})
  }, [])

  const debounceSearchMentor = debounce((e) => {
    getRoles({params: {name: e}})
  }, 500)

  const DEFAULT_SCHEMA = {
    full_name: z
      .string({required_error: VALIDATION_FILED})
      .min(3, {message: VALIDATE_MIN_FULLNAME})
      .max(100, {message: VALIDATE_MAX_FULLNAME}),
    username: z
      .string({required_error: VALIDATION_FILED})
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, {message: VALIDATE_MIN_USERNAME})
      .max(40, {message: 'Username Must Be Shorter Than Or Equal To 40 Characters'}),
    email: z.string({required_error: VALIDATION_FILED}).email(),
    phone: z
      .string({required_error: VALIDATION_FILED})
      .regex(new RegExp(phoneRegExp), {message: VALIDATE_PHONE}),
    avatar: z.any(),
    status: z.string().optional(),
    role: z.any(),
    sex: z.string({ required_error: VALIDATION_FILED }).optional(),
  }

  const schemaCreate = z
    .object(
      Object.assign({}, DEFAULT_SCHEMA, {
        password: z.string({required_error: VALIDATION_FILED}).regex(new RegExp(validatePassword), {
          message: VALIDATE_PASSWORD,
        }),
        confirmPassword: z.string({required_error: VALIDATION_FILED}).min(1),
      })
    )
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ['confirmPassword'],
    })

  const schemaUpdate = z.object(DEFAULT_SCHEMA)

  const validation = useMemo(
    () => (id ? schemaUpdate : schemaCreate),
    [id, schemaCreate, schemaUpdate]
  )

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
  } = useForm<IStaffs>({
    resolver: zodResolver(validation),
    mode: 'onChange',
  })

  const onSubmitCreate = async (data: IStaffs) => {
    const {email, full_name, password, phone, username, avatar, sex} = data
    setLoading(true)

    try {
      const res = await createStaffs({
        email: email.toLowerCase(),
        full_name: full_name,
        password: password,
        phone: phone,
        username: username.toLowerCase(),
        status: EStatusUser.ACTIVE,
        roles: data.role?.map((role) => ({id: role})) || [],
        sex: sex
      })
      const dataStaffs = res?.data
      toast.success('Create Successfully!')
      setUploadSuccess(true)

      if (isUndefined(avatar)) {
        navigate(PageLink.STAFFS)
      }

      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({staffId: dataStaffs?.id, avatar: avatar}).then(() =>
          navigate(PageLink.STAFFS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, {message: errorMessage})
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmitUpdate = async (data: IStaffs) => {
    const {email, full_name, phone, status, avatar, sex} = data
    setLoading(true)

    try {
      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({staffId: staffDetail?.id, avatar: avatar})
      }
  
      const paramsUpdate: IUpdateStaff = {
        full_name,
        email: email.toLowerCase(),
        phone,
        status,
        roles: data.role?.map((role) => ({id: role})),
        avatar: null,
        sex
      }
  
      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }
  
      StaffAPI.update({
        id: staffDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Successfully!')
          navigate(PageLink.STAFFS)
        })
        .catch((err) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message
  
            setError(e.property, {message: errorMessage})
          }, {})
        })
    } catch (error) {}
    finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<IStaffs> = async (data) => {
    // if (Object.keys(errors).length === 0) {
    // Prevent form submission
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (id) {
      onSubmitUpdate(data)
      return
    }
    onSubmitCreate(data)
    // }
  }

  const optionRole = roles?.map((role: any) => ({name: role?.name, value: role?.id}))

  const getStaffDetail = async () => {
    const res = await StaffAPI.detail(id)
    setStaffDetail(res?.data)
  }

  useEffect(() => {
    if (!id) return

    getStaffDetail()
  }, [id])

  useEffect(() => {
    if (!id) {
      reset()
      setUploadSuccess(true)
    } else {
      setValue('full_name', staffDetail?.detail?.full_name ?? '')
      setValue('username', staffDetail?.username ?? '')
      setValue('email', staffDetail?.detail?.email ?? '')
      setValue('phone', staffDetail?.detail?.phone ?? '')
      setValue('status', staffDetail?.status ?? '')
      setValue('role', staffDetail?.roles?.map((role) => role?.id) ?? [])
      setValue('sex', staffDetail?.detail?.sex ?? '')
    }
  }, [
    id,
    reset,
    setValue,
    staffDetail?.detail?.email,
    staffDetail?.detail?.full_name,
    staffDetail?.detail?.phone,
    staffDetail?.roles,
    staffDetail?.status,
    staffDetail?.username,
  ])

  const {confirm, contextHolder} = useConfirm()

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.STAFFS),
    })
  }

  const hanleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  return (
    <PageLayouts pageTitle={`${id ? 'Update Staff' : 'Create Staff'}`} breadcrumbs={breadcrumbs}>
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Avatar</h2>
              </div>
            </div>

            <div className='card-body text-center pt-0'>
              <SAPPHookUploadFile
                name='avatar'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={
                  staffDetail?.detail?.avatar?.['150x150'] ??
                  staffDetail?.detail?.avatar?.ORIGIN ??
                  avatarDefault
                }
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                uploadSuccess={uploadSuccess}
                setShowAvatarDefault={setShowAvatarDefault}
                removeAvatar={avatarDefault}
              />
            </div>
          </div>

          {id && (
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Status</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <HookFormSelectAntd
                  size='large'
                  name='status'
                  control={control}
                  dropdownStyle={{minWidth: 'fit-content'}}
                  placeholder='Status'
                  filterOption={true}
                  defaultValue={STATUS_FORM[0].value}
                  className='fs-6'
                >
                  {STATUS_FORM.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush pb-4 pt-8'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        placeholder={LANG_PLACEHOLDER.FULLNAME}
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        placeholder='Username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        placeholder='Email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='password'
                          placeholder='Password'
                          label='Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          placeholder='Confirm Password'
                          label='Confirm Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        placeholder='Phone Number'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>
                    <div>
                        <HookFormSelectMultiple
                          name='role'
                          control={control}
                          placeholder='Role'
                          selectOptions={optionRole}
                          label='Role'
                          defaultValue={[]}
                          onSearch={(e: any) => {
                            if (e === undefined) {
                              return
                            }
                            debounceSearchMentor(e)
                          }}
                          handleNextPage={(e: any) => handlNextPageRole({params: {name: e}})}
                        />
                    </div>

                    <div className={`fv-row mt-10`}>
                      <HookFormSelectAntd
                        size='large'
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        label='Gender'
                        defaultValue={GENDER_FORM?.[0]?.value}
                      >
                        {GENDER_FORM.map((gender) => (
                          <Option key={gender.label} value={gender.value}>
                            {gender.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SAPPDialogButtonsCancelSubmit
            className='d-flex justify-content-end'
            cancelButtonCaption='Cancel'
            okButtonCaption={BUTTON_TEXT.SAVE}
            okOnClick={hanleSubmitForm}
            cancelClick={hanleCancel}
            loading={loading}
          />
        </div>
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(CreateAndUpdateStaff)
