import React from 'react'

const Logs = () => {
  return (
    <div className='card pt-4'>
      <div className='card-header border-0'>
        <div className='card-title'>
          <h2>Logs</h2>
        </div>

        <div className='card-toolbar'>
          <button type='button' className='btn btn-sm btn-secondary'>
            <i className='ki-outline ki-cloud-download fs-3'></i>Download Report
          </button>
        </div>
      </div>

      <div className='card-body py-0'>
        <div className='table-responsive'>
          <table
            className='table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5'
            id='kt_table_customers_logs'
          >
            <tbody>
              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-success'>200 OK</div>
                </td>

                <td>POST /v1/invoices/in_4742_6758/payment</td>

                <td className='pe-0 text-end min-w-200px'>21 Feb 2023, 11:05 am</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-danger'>500 ERR</div>
                </td>

                <td>POST /v1/invoice/in_8155_6786/invalid</td>

                <td className='pe-0 text-end min-w-200px'>25 Oct 2023, 10:10 pm</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-danger'>500 ERR</div>
                </td>

                <td>POST /v1/invoice/in_7933_7443/invalid</td>

                <td className='pe-0 text-end min-w-200px'>10 Nov 2023, 6:05 pm</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-success'>200 OK</div>
                </td>

                <td>POST /v1/invoices/in_4742_6758/payment</td>

                <td className='pe-0 text-end min-w-200px'>05 May 2023, 8:43 pm</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-danger'>500 ERR</div>
                </td>

                <td>POST /v1/invoice/in_8155_6786/invalid</td>

                <td className='pe-0 text-end min-w-200px'>10 Nov 2023, 9:23 pm</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-warning'>404 WRN</div>
                </td>

                <td>POST /v1/customer/c_6439e2646c29c/not_found</td>

                <td className='pe-0 text-end min-w-200px'>10 Mar 2023, 5:30 pm</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-warning'>404 WRN</div>
                </td>

                <td>POST /v1/customer/c_6439e2646c29b/not_found</td>

                <td className='pe-0 text-end min-w-200px'>20 Jun 2023, 6:43 am</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-success'>200 OK</div>
                </td>

                <td>POST /v1/invoices/in_4566_1132/payment</td>

                <td className='pe-0 text-end min-w-200px'>10 Mar 2023, 6:43 am</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-danger'>500 ERR</div>
                </td>

                <td>POST /v1/invoice/in_7933_7443/invalid</td>

                <td className='pe-0 text-end min-w-200px'>10 Mar 2023, 5:20 pm</td>
              </tr>

              <tr>
                <td className='min-w-70px'>
                  <div className='badge badge-light-success'>200 OK</div>
                </td>

                <td>POST /v1/invoices/in_4742_6758/payment</td>

                <td className='pe-0 text-end min-w-200px'>25 Jul 2023, 9:23 pm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Logs
