import MenuItem from './MenuItem'

interface IProps {
  menuLMS?: Array<any> | undefined
  menuAdmin?: Array<any> | undefined
  activeAdmin?: boolean
}

const MenuRight = ({menuLMS, activeAdmin, menuAdmin}: IProps) => {
  return (
    <div className='app-sidebar-secondary overflow-y-auto'>
      <div className='menu menu-sub-indention menu-rounded menu-column menu-active-bg-primary menu-title-gray-700 menu-icon-gray-700 menu-state-white menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6'>
        <div className='px-4 px-lg-6'>
          <div className='menu-item'>
            <div className='menu-content'>
              <span className='menu-section fs-5 fw-bolder ps-1 py-1'>
                {activeAdmin ? 'Admin' : 'LMS'}
              </span>
            </div>
          </div>
          {activeAdmin
            ? menuAdmin?.map((item) => <MenuItem key={item.id} item={item} />)
            : menuLMS?.map((item) => <MenuItem key={item.id} item={item} />)}
        </div>
      </div>
    </div>
  )
}

export default MenuRight
