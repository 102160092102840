import React from 'react'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import {BUTTON_TEXT, LANG_COURSES} from 'src/constants/lang'
import SAPPCheckbox from './base/checkbox/SAPPCheckbox'
import SappTable from './base/SappTable'
import Search from './base/search'
import SAPPSelect from './base/select/SAPPSelect'

// define headers
const headers = [
  {
    label: LANG_COURSES.courseName,
    className: 'min-w-150px',
  },
  {
    label: 'Category',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Type',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Date',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Exam',
    className: 'min-w-100px pe-5',
  },
]

const MOCKDATA = ['name', 'Category', 'Type', 'Status', 'Date', 'Exam', 'Action']

const ClassUser = () => {
  return (
    <div className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-sm-4 ps-xl-0 pe-md-3 ps-0 pe-0'>
              <div className='card-title justify-content-center mb-0 mx-0'>
                <Search placeholder='Search code class' value={''} onChange={() => {}} showSearch />
              </div>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 px-0 pe-md-3'>
              <SAPPSelect
                size='large'
                value={''}
                onChange={() => {}}
                options={[
                  {
                    label: 'Category',
                    value: '',
                  },
                ]}
                placeholder='Category'
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 ps-md-0'>
              <SAPPSelect
                size='large'
                value={''}
                onChange={() => {}}
                options={[
                  {
                    label: 'Exam',
                    value: '',
                  },
                ]}
                placeholder='Exam'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 col-lg-9 col-xl-4 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                okClick={() => {}}
                resetClick={() => {}}
                titleReset='Reset'
                titleSubmit={BUTTON_TEXT.SEARCH}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card-body py-4'>
        <SappTable
          headers={headers}
          loading={false}
          data={[{}]}
          isCheckedAll={false}
          onChange={() => {}}
        >
          {MOCKDATA.map((data) => (
            <tr key={data}>
              <td>
                <SAPPCheckbox checked={false} onChange={() => {}} />
              </td>

              <td className='text-start'>#XGY-356</td>

              <td className='text-start'>02 Apr, 2023</td>

              <td className='text-start'>$1,230</td>

              <td className='text-start'>
                <span className='badge py-3 px-4 fs-7 badge-light-primary'>In Stock</span>
              </td>

              <td className='text-start'>
                <span className='text-dark fw-bold'>58 PCS 58 PCS 58 PCS 58 PCS</span>
              </td>

              <td className='text-start'>
                <span className='text-dark fw-bold'>58 PCS</span>
              </td>
            </tr>
          ))}
        </SappTable>
      </div>
    </div>
  )
}

export default ClassUser
