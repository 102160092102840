import React from 'react'

interface IProps {
  code: string;
}

const CodeUser = ({ code }: IProps) => {
  return (
    <div>{code}</div>
  )
}

export default CodeUser