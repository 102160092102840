import {Select} from 'antd'
import React from 'react'
import "./sappselect.scss"
import { DownOutlined } from '@ant-design/icons'

interface IProps {
  onChange?: (val: any) => void
  className?: string
  size: 'large' | 'middle' | 'small'
  value?: string | null
  options: Array<{label: string; value: string | number}> | undefined
  placeholder?: any
  showSearch?: boolean
  filterOption?: boolean
  suffixIcon?:any
  dropdownStyle?: React.CSSProperties | undefined
}

const SAPPSelect = ({
  onChange,
  className,
  size,
  value,
  options,
  placeholder,
  showSearch = false,
  suffixIcon = <DownOutlined rev={null} />,
  dropdownStyle,
}: IProps) => {
  return (
    <Select
      onChange={onChange}
      className={`${className ?? ''} w-100 sapp-hook-form-select min-w-90px`}
      value={value}
      placeholder={placeholder}
      size={size}
      showSearch={showSearch}
      dropdownStyle={{
        minWidth: '90px',
        ...(dropdownStyle && dropdownStyle),
      }}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={options}
      suffixIcon={suffixIcon}
    />
  )
}

export default SAPPSelect
