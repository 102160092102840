import {FC} from 'react'

type Props = {
  status?: 'DRAFT' | 'PUBLIC' | 'BLOCKED' | 'ENDED' | string | undefined
  className?: string
}

const ClassStatusCell: FC<Props> = ({status, className}) => (
  <div
    className={`sapp-class-icon sapp-class-icon-${status} f-flex justify-content-center ${className ? `${className}` : 'min-w-44px'}`}
  >
    {status === 'public' ? 'Public' : status === 'blocked' ? 'Blocked' : status === 'ended' ? 'Ended' : status}
  </div>
)

export default ClassStatusCell
