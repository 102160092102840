import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import UsersList from 'src/components/user-list/UserList'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.STUDENTS}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'List Students',
  },
]

const ListStudents = () => {
  return (
    <PageLayouts pageTitle='List Students' breadcrumbs={breadcrumbs}>
      <div className='card'>
        <UsersList />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListStudents)
