import React, {Dispatch, SetStateAction} from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ExportExcel from 'src/components/base/export/ExportExcel'
import {KTIcon} from 'src/_metronic/helpers'
import {useUserContext} from 'src/context/UserProvider'
import {CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR} from 'src/constants/permission'
import {Role} from 'src/type'

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  onClick: () => void
  titleImport?: string
  titleExport?: string
  titleAdd: string
  link?: string
  onClickAdd?: () => void
  loading: boolean
  notImport?: boolean
  location?: any
}

const SAPPActionButton = ({
  link,
  onClick,
  setOpen,
  titleAdd,
  titleExport,
  titleImport,
  onClickAdd,
  loading,
  notImport,
  location,
}: IProps) => {
  const {profileMe} = useUserContext()
  const allowRenderExport = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.GET_EXPORT_STAFF) ||
      role.permissions?.includes(TITLE_GR.GET_EXPORT_DATA_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderImport = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.GET_IMPPORT_STAFF) ||
      role.permissions?.includes(TITLE_GR.IMPORT_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderCreate = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.CREATE_STAFF) ||
      role.permissions?.includes(TITLE_GR.CREATE_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <div className='row justify-content-xl-end mt-xl-0 mt-lg-3'>
      <div className='col-xl-2 col-md-4 col-lg-3 ps-md-3 ps-lg-0 me-md-0 me-xl-3 me-0 px-xl-0 px-0 pe-md-3'>
        {location ? (
          allowRenderImport ? (
            <ButtonIcon
              title={titleImport ?? 'Import'}
              className='w-100 mx-xl-3 mb-xl-0 mb-2 sapp-height-min--content btn btn-primary btn-sapp-filter me-3'
              onClick={() => setOpen(true)}
            >
              <KTIcon iconName='file-up' className='fs-3 pe-3' iconType='outline' />
            </ButtonIcon>
          ) : null
        ) : (
          <ButtonIcon
            title={titleImport ?? 'Import'}
            className='w-100 mx-xl-3 mb-xl-0 mb-2 sapp-height-min--content btn btn-primary btn-sapp-filter me-3'
            onClick={() => setOpen(true)}
          >
            <KTIcon iconName='file-up' className='fs-3 pe-3' iconType='outline' />
          </ButtonIcon>
        )}
      </div>
      <div className='col-xl-2 col-md-4 col-lg-3 me-md-0 me-xl-3 me-0 ms-xl-3 px-0 pe-xl-0 pe-md-3'>
        {location ? (
          allowRenderExport ? (
            <ExportExcel
              title={titleExport ?? 'Export'}
              onClick={onClick}
              className='mb-xl-0 mb-2'
              loading={loading}
            />
          ) : null
        ) : (
          <ExportExcel
            title={titleExport ?? 'Export'}
            onClick={onClick}
            className='mb-xl-0 mb-2'
            loading={loading}
          />
        )}
      </div>
      <div className='col-xl-2 col-md-4 col-lg-3 px-0'>
        {location ? (
          allowRenderCreate ? (
            <ButtonIcon
              title={titleAdd}
              className='w-100 sapp-height-min--content'
              link={link}
              onClick={onClickAdd}
            >
              <KTIcon iconName='plus' className='fs-3 pe-3' />
            </ButtonIcon>
          ) : null
        ) : (
          <ButtonIcon
            title={titleAdd}
            className='w-100 sapp-height-min--content'
            link={link}
            onClick={onClickAdd}
          >
            <KTIcon iconName='plus' className='fs-3 pe-3' />
          </ButtonIcon>
        )}
      </div>
    </div>
  )
}
export default SAPPActionButton
