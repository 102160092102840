import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import './CreatedContent.scss'
import {ReactNode} from 'react'
import {useConfirm} from 'src/hooks/use-confirm'
interface IProps {
  name?: string
  actionDelete?: any
  actionEdit?: any
  children?: ReactNode
  classNameContainer?: string
  containerClick?: any
  iconGrayColor?: any
  disableHoverColor?: boolean
  disableHoverButtonBg?: boolean
  customConfirm?: boolean
}
const CreatedContent = ({
  name,
  actionDelete,
  actionEdit,
  children,
  classNameContainer = ' p-4 mb-8 text-gray-600',
  containerClick,
  iconGrayColor = '800',
  disableHoverColor = false,
  disableHoverButtonBg = false,
  customConfirm = false,
}: IProps) => {
  const {confirm, contextHolder} = useConfirm()

  const handleDelete = () => {
    if (!customConfirm) {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: ['Bạn có chắc chắn muốn xóa không?'],
        onClick: () => {
          actionDelete()
        },
      })
    } else {
      actionDelete()
    }
  }
  return (
    <>
      {contextHolder}
      <div
        onClick={containerClick}
        className={`card card-body card-bordered  ${
          !disableHoverColor && 'card-hover-color-primary'
        } border-gray-300  d-flex justify-content-between flex-row align-items-center sapp-created-container ${classNameContainer}`}
      >
        {!children ? <div className='sapp-created-content-name'>{name}</div> : children}
        <div className='d-flex'>
          {actionEdit && (
            <ButtonIconOnly
              iconName='notepad-edit'
              onClick={actionEdit}
              iconType='outline'
              bg={'sapp-custom-bg-transparent'}
              iconGrayColor={iconGrayColor}
              disableBackgoundHover={disableHoverButtonBg}
            />
          )}
          {actionDelete && (
            <ButtonIconOnly
              iconName='trash'
              onClick={handleDelete}
              iconType='outline'
              bg={'sapp-custom-bg-transparent'}
              activeColor='danger'
              iconGrayColor={iconGrayColor}
              disableBackgoundHover={disableHoverButtonBg}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default CreatedContent
