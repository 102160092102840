import React, { Dispatch, SetStateAction } from 'react'
import {useLocation} from 'react-router-dom'
import {PageLink} from 'src/constants'
import OverviewProfile from './overview-profile'
import SettingProfile from './setting-profile'
import SecurityProfile from './security-profile'
import ActivityProfile from './activity-profile'
import BillingProfile from './billing-profile'
import StatementsProfile from './stament-profile'
import ReferralsProfile from './refferals-profile'
import LogsProfile from './logs-profile'
import {IStudentDetail} from 'src/type/students'

interface IProps {
  userDetail: IStudentDetail | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setProfile: Dispatch<SetStateAction<any>>
}

const RenderProfile = ({userDetail, loading, setProfile}: IProps) => {
  const location = useLocation()

  const renderProfile = () => {
    switch (location.pathname) {
      case PageLink.PROFILE_OVERVIEW:
        return <OverviewProfile userDetail={userDetail} loading={loading} setProfile={setProfile} />
      case PageLink.PROFILE_SETTING:
        return <SettingProfile userDetail={userDetail} setProfile={setProfile} />
      case PageLink.PROFILE_SECURITY:
        return <SecurityProfile />
      case PageLink.PROFILE_ACTIVITY:
        return <ActivityProfile />
      case PageLink.PROFILE_BILLING:
        return <BillingProfile />
      case PageLink.PROFILE_STATEMENTS:
        return <StatementsProfile />
      case PageLink.PROFILE_REFERRALS:
        return <ReferralsProfile />
      case PageLink.PROFILE_LOGS:
        return <LogsProfile />
      default:
        return <OverviewProfile userDetail={userDetail} loading={loading} setProfile={setProfile} />
    }
  }
  return <>{renderProfile()}</>
}

export default RenderProfile
