import React from 'react'
import CertificatesTemplate from 'src/components/certificate/CertificatesTemplate'

type Props = {}

const CertificatesTemplatePage = (props: Props) => {
  return (
    <div className='bg-white'>
      <CertificatesTemplate></CertificatesTemplate>
    </div>
  )
}

export default CertificatesTemplatePage
