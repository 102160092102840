import {PropsWithChildren, createContext, useContext, useState} from 'react'
import {QuestionBankAPI} from 'src/apis/question-bank'
import {IQuestion} from '../interfaces'

export type listType = 'multiple' | 'constructed' | 'all'

interface TState {
  [key: string]: {
    listQuestion: IQuestion[]
    pageIndex: number
    pageSize: number
    totalRecords: number
    loading: boolean
  }
}
type Context = {
  state: TState
  topicId?: string
  refreshListQuestion: (types: listType[]) => void
  getListQuestions: (
    type: listType,
    page_index?: number,
    page_size?: number,
    topic_id?: string
  ) => void
  setListQuestionLoading: (listType: listType[], loading: boolean) => void
}

const initContext: Context = {
  topicId: undefined,
  state: {
    all: {
      listQuestion: [],
      pageIndex: 1,
      pageSize: 10,
      totalRecords: 0,
      loading: false, // khởi tạo giá trị loading
    },
    multiple: {
      listQuestion: [],
      pageIndex: 1,
      pageSize: 10,
      totalRecords: 0,
      loading: false, // khởi tạo giá trị loading
    },
    constructed: {
      listQuestion: [],
      pageIndex: 1,
      pageSize: 10,
      totalRecords: 0,
      loading: false, // khởi tạo giá trị loading
    },
  },
  refreshListQuestion: () => {},
  getListQuestions: () => {},
  setListQuestionLoading: () => {},
}

const QuestionProviderContext = createContext<Context>(initContext)

export function QuestionProviderProvider(props: PropsWithChildren<{}>) {
  const [state, setState] = useState(initContext.state)
  const [topicId, setTopicId] = useState<string | undefined>(initContext.topicId)

  const fetchQuestions = async (
    type: listType,
    topic_id?: string,
    page?: {page_index?: number; page_size?: number}
  ) => {
    setState((prevState) => ({
      // cập nhật trạng thái loading
      ...prevState,
      [type]: {
        ...prevState[type],
        loading: true,
      },
    }))
    try {
      const response = await QuestionBankAPI.getQuestions(
        {
          params: {
            topic_id,
            page_index: page?.page_index ?? state[type].pageIndex,
            page_size: page?.page_size ?? state[type].pageSize,
          },
        },
        type
      )
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            listQuestion: response.data.questions ?? [],
            totalRecords: response.data.meta.total_records,
            loading: false, // cập nhật trạng thái loading
          },
        }))
        if (topic_id !== null) {
          setTopicId(topic_id)
        }
      }
    } catch (error) {
    } finally {
      setState((prevState) => ({
        // cập nhật trạng thái loading
        ...prevState,
        [type]: {
          ...prevState[type],
          loading: false,
        },
      }))
    }
  }

  const refreshListQuestion = (types: listType[]) => {
    types?.forEach((type) => {
      fetchQuestions(type, topicId)
    })
  }

  const getListQuestions = (
    type: listType = 'all',
    page_index?: number,
    page_size?: number,
    topic_id?: string
  ) => {
    const pi = page_index || 1
    const ps = page_size || 10
    setState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        pageIndex: pi,
        pageSize: ps,
      },
    }))
    fetchQuestions(type, topic_id, {page_index, page_size})
  }
  const setListQuestionLoading = (listType: listType[], loading: boolean) => {
    setState((prevState) => {
      return Object.entries(prevState ?? {}).reduce((current, [type, value]) => {
        return {...current, [type]: {...value, loading: loading}}
      }, prevState)
    })
  }

  return (
    <QuestionProviderContext.Provider
      value={{
        state,
        refreshListQuestion,
        getListQuestions,
        setListQuestionLoading,
      }}
      {...props}
    />
  )
}

export function useQuestionProviderContext(): Context {
  const context = useContext(QuestionProviderContext)

  if (!context) {
    throw new Error('Error!')
  }

  return context
}
