import React from 'react'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import SAPPTextFiled from './SAPPTextFiled'
import { Skeleton } from 'antd'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'

interface IProps {
  name: string
  control: Control<any>
  defaultValue?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: 'number' | 'password' | 'email' | 'text'
  placeholder?: string
  className?: string
  disabled?: boolean
  label?: string
  labelClass?: string
  value?: string | number
  onChangeType?: () => void
  passwordVisible?: boolean
  showIconPassword?: boolean
  skeleton?: boolean
  required?: boolean
  maxLength?: number
  guideline?: Array<string> | undefined
  groupText?: any,
  postFix?: any
  preIcon?: any
  minNumber?: number
  requiredZero?: boolean
  onSubmit?: any
}

const HookFormTextField = ({
  value,
  name,
  control,
  defaultValue,
  onChange,
  type,
  placeholder,
  className = '',
  disabled,
  label,
  labelClass,
  onChangeType,
  passwordVisible,
  showIconPassword,
  skeleton,
  required,
  maxLength,
  guideline,
  groupText,
  postFix,
  preIcon,
  minNumber,
  requiredZero,
  onSubmit
}: IProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
          {onSubmit ? <form onSubmit={(e) => {
            e.preventDefault()
             onSubmit()
          }} className='w-100'>
            {!skeleton ? (
              <>
                {label && <SappLabel label={label} required={required} />}
                <SAPPTextFiled
                  groupText={groupText}
                  type={type}
                  value={field.value ?? ''}
                  defaultValue={field.value ? undefined : defaultValue}
                  onChange={(value) => {
                    field.onChange(value)
                    onChange && onChange(value)
                  }}
                  className={`${className} ${error ? 'is-invalid' : 'sapp-form-control'}`}
                  placeholder={placeholder}
                  disabled={disabled}
                  label={label}
                  labelClass={labelClass}
                  onChangeType={onChangeType}
                  passwordVisible={passwordVisible}
                  showIconPassword={showIconPassword}
                  error={error}
                  required={required}
                  maxLength={maxLength}
                  guideline={guideline}
                  field={field}
                  postFix={postFix}
                  preIcon={preIcon}
                  minNumber={minNumber}
                  requiredZero={requiredZero}
                />{' '}
                <div>
                  <>
                    <GuidelineField guideline={guideline} />
                    <ErrorMessage>{error?.message ?? ''}</ErrorMessage>
                  </>
                </div>
              </>
            ) : (
              <>
                <Skeleton.Button active size='small' className='mb-1' />
                <Skeleton.Button active block size='large' className='sapp-h-45px' />
              </>
            )}
          </form>:<div className='w-100'>
            {!skeleton ? (
              <>
                {label && <SappLabel label={label} required={required} />}
                <SAPPTextFiled
                  groupText={groupText}
                  type={type}
                  value={field.value ?? ''}
                  defaultValue={field.value ? undefined : defaultValue}
                  onChange={(value) => {
                    field.onChange(value)
                    onChange && onChange(value)
                  }}
                  className={`${className} ${error ? 'is-invalid' : 'sapp-form-control'}`}
                  placeholder={placeholder}
                  disabled={disabled}
                  label={label}
                  labelClass={labelClass}
                  onChangeType={onChangeType}
                  passwordVisible={passwordVisible}
                  showIconPassword={showIconPassword}
                  error={error}
                  required={required}
                  maxLength={maxLength}
                  guideline={guideline}
                  field={field}
                  postFix={postFix}
                  preIcon={preIcon}
                  minNumber={minNumber}
                  requiredZero={requiredZero}
                />{' '}
                <div>
                  <>
                    <GuidelineField guideline={guideline} />
                    <ErrorMessage>{error?.message ?? ''}</ErrorMessage>
                  </>
                </div>
              </>
            ) : (
              <>
                <Skeleton.Button active size='small' className='mb-1' />
                <Skeleton.Button active block size='large' className='sapp-h-45px' />
              </>
            )}
          </div>}
          </>
          
        )
      }}
    />
  )
}

export default HookFormTextField
