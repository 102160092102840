import {fetcher, fetchFormData} from 'src/services/request'
import {IResponse} from 'src/type'
import {IStory, IStories, IStoryList} from 'src/type/story'

export class StoryAPI {
  static detail(id: string | undefined, page_index: number, page_size: number): Promise<IResponse<any>> {
    return fetcher(`/story/${id}?page_index=${page_index}&page_size=${page_size}`)
  }

  static update(id: string, data: IStory): Promise<IResponse<IStories>> {
    return fetcher(`/story/${id}`, {method: 'PUT', data})
  }

  static getStory = (query: string) => {
    return fetcher(`/story?${query}`)
  }
  static getStoryById = (query: string, page_index: number, page_size: number, params?: Object) => {
    return fetcher(`/story/${query}?page_index=${page_index}&page_size=${page_size}`, {
      method: 'GET',
      params,
    })
  }

  static createStories(data: IStory): Promise<IResponse<IStories>> {
    return fetcher(`/story`, {method: 'POST', data})
  }
  static deleteStory = async (id: string) => {
    return fetcher(`/story/${id}`, {
      method: 'DELETE',
    })
  }
  static updateTopics(id: string, data: any): Promise<IResponse<IStories>> {
    return fetcher(`/story/${id}/topics`, {method: 'PUT', data})
  }
  static uploadFile = ({
    userId,
    avatar,
    id_front,
    id_back,
  }: {
    userId: string | undefined
    avatar?: File
    id_front?: File
    id_back?: File
  }) => {
    const formData = new FormData()
    if (userId) {
      formData.append('userId', userId)
    }

    if (avatar) {
      formData.append('avatar', avatar)
    }
    if (id_front) {
      formData.append('id_front', id_front)
    }
    if (id_back) {
      formData.append('id_back', id_back)
    }

    return fetchFormData({url: `/users/detail/upload`, formData})
  }
  static getAPIStory = ({
    queryString,
    page_index,
    page_size,
  }: {
    queryString?: string
    page_index: number
    page_size: number
  }): Promise<IResponse<IStoryList>> => {
    if (queryString) {
      return fetcher(`/story?${queryString}`, {})
    } else
      return fetcher(`/story?page_index=${page_index}&page_size=${page_size}`, {
        // params: params,
      })
  }
}
