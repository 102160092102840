import {Spin} from 'antd'
import {debounce} from 'lodash'
import PreviewActivity from 'preview-activity'
import {IActivity, IQuestion, ITab} from 'preview-activity/dist/shared/interfaces'
import {
  AddWordPreview,
  DragNDropPreview,
  EssayQuestionPreview,
  MatchingPreview,
  MultiQuestionPreview,
  SelectWord,
  TrueFalseQuestionPreview,
} from 'preview-question-bank'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CoursesAPI} from 'src/apis/courses'
import {QuestionBankAPI} from 'src/apis/question-bank'
import './PreviewActivity.scss'
type IProps = {
  previewActivity: {
    id: string
    open: boolean
  }
  setPreviewActivity: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          open: boolean
        }
      | undefined
    >
  >
}
const PreviewActivityModal = ({previewActivity, setPreviewActivity}: IProps) => {
  const [activity, setActivity] = useState<IActivity>()
  const [tabs, setTabs] = useState<ITab[]>()
  const [activeTab, setActiveTab] = useState<ITab>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      handleSetLoading(true)
      try {
        const responseActivity = await CoursesAPI.getActivityDetail(previewActivity.id)
        if (responseActivity.success) {
          setActivity(responseActivity.data)
          const responseTabs = await CoursesAPI.getTabByActivity(responseActivity.data.id)
          if (responseTabs.success) {
            setTabs(responseTabs.data)
            handleSetActiveTab(responseTabs.data?.[0])
          }
        }
      } finally {
        handleSetLoading(false)
      }
    })()
  }, [])

  const handleSetActiveTab = async (tab: ITab | undefined) => {
    if (tab?.id) {
      try {
        handleSetLoading(true)
        const response = await CoursesAPI.getTabDetail(tab?.id)
        if (response.success) {
          const data = response.data
          setActiveTab(data)
        }
      } finally {
        handleSetLoading(false)
      }
    }
  }
  const handleSetActiveQuiz = async (id?: string) => {
    if (id) {
      handleSetLoading(true)
      try {
        const question = await QuestionBankAPI.getQuestionById(id)
        if (question.data) {
          return question.data as IQuestion
        } else {
          return undefined
        }
      } catch (error) {
      } finally {
        handleSetLoading(false)
      }
    } else {
      return undefined
    }
  }

  const handleClosePreview = () => {
    setPreviewActivity({
      id: '',
      open: false,
    })
  }

  const debouncedSetLoading = debounce((loading) => {
    setLoading(loading)
  }, 300)

  // Sử dụng hàm debounce trong hàm handleSetLoading
  const handleSetLoading = (loading: boolean) => {
    // Nếu loading là true, gọi setLoading ngay lập tức
    if (loading) {
      setLoading(loading)
    } else {
      // Nếu loading từ true chuyển sang false, hàm debounce sẽ chờ 300ms trước khi gọi setLoading
      debouncedSetLoading(loading)
    }
  }

  return (
    <>
      {activity && (
        <div>
          <Modal
            show={previewActivity.open}
            onHide={handleClosePreview}
            dialogClassName='sapp-mw-1144px modal-dialog-centered sapp-preview-activity_modal'
          >
            <Spin spinning={loading} size='large'>
              <div className='sapp-preview-activity'>
                <PreviewActivity
                  tabs={tabs}
                  activeTab={activeTab}
                  handleSetActiveTab={handleSetActiveTab}
                  handleSetActiveQuiz={handleSetActiveQuiz}
                  activity={activity}
                  AddWordPreview={AddWordPreview}
                  DragNDropPreview={DragNDropPreview}
                  EssayQuestionPreview={EssayQuestionPreview}
                  MatchingPreview={MatchingPreview}
                  MultiQuestionPreview={MultiQuestionPreview}
                  SelectWord={SelectWord}
                  TrueFalseQuestionPreview={TrueFalseQuestionPreview}
                ></PreviewActivity>
              </div>
            </Spin>
          </Modal>
        </div>
      )}
    </>
  )
}

export default PreviewActivityModal
