import {UsersTable} from './components/table/UsersTable'
import {useQueryResponse} from './components/core/QueryResponseProvider'
import {useEffect, useState} from 'react'
import {UsersListToolbar} from './components/header/UserListToolbar'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import UserListGrouping from '../user-management/UserListGrouping'
import {toast} from 'react-hot-toast'
import {UsersAPI, getAPIUser} from 'src/apis/user'
import {useMutation} from 'react-query'
import {BUTTON_TEXT} from 'src/constants/lang'
import {useLocation, useNavigate} from 'react-router-dom'
import {cleanParamsAPI, formatDate} from 'src/utils'
import {FILTER_CLASS, FILTER_EXAM, FILTER_SELECTALL_SORTBY, GENDER, STATUS} from 'src/constants'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import HookFormTextField from '../base/textfield/HookFormTextField'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import {Select} from 'antd'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import {replaceValueAll} from 'src/utils/string'
import useChecked from 'src/hooks/use-checked'
import {useUserContext} from 'src/context/UserProvider'
import {CODE_ADMIN, TITLE_GR} from 'src/constants/permission'
import {Role} from 'src/type'

const {Option} = Select

const fieldNames = [
  'gender',
  'text',
  'status',
  'class',
  'examType',
  'examList',
  'sortType',
  'fromDate',
  'toDate',
]
const initialValues: any = {
  gender: '',
  text: '',
  status: '',
  classs: '',
  examType: '',
  examList: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}

const UsersList = () => {
  const [openBlocked, setOpenBlocked] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const {refetch, isLoading} = useQueryResponse()
  const [loading, setLoading] = useState(true)
  const [studentList, setStudentList] = useState<any>({
    metadata: {},
    users: [{}],
  })
  const {profileMe} = useUserContext()
  const queryParams = {
    text: searchParams.get('text') ?? '',
    gender: searchParams.get('gender'),
    status: searchParams.get('status'),
    classs: searchParams.get('class'),
    examType: searchParams.get('examType'),
    examList: searchParams.get('examList'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const validationSchema = z.object({
    text: z.string().optional(),
    gender: z.string().optional(),
    status: z.string().optional(),
    classs: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const {control, getValues, reset, setValue, watch} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      gender: replaceValueAll(getValues('gender')),
      status: replaceValueAll(getValues('status')),
      examType: replaceValueAll(getValues('examType')),
      examList: replaceValueAll(getValues('examList')),
      classs: replaceValueAll(getValues('class')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const filterParams = cleanParamsAPI({
    text: queryParams.text.trimStart().trimEnd() ?? '',
    gender: queryParams.gender ?? '',
    status: queryParams.status ?? '',
    examType: queryParams.examType ?? '',
    examList: queryParams.examList ?? '',
    class: queryParams.classs ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
  })

  const getParams = (
    text: string,
    gender: string,
    status: string,
    classs?: string,
    examType?: string,
    examList?: string,
    sortType?: string,
    fromDate?: any,
    toDate?: any,
    quizType?: string,
    dateField?: string
  ) => {
    let textSearch: string = ''
    let fromDateField: string
    let toDateField: string

    switch (examType) {
      case 'CFA':
        textSearch = 'text'
        fromDateField = 'cfa_from_date'
        toDateField = 'cfa_to_date'
        break
      case 'ACCA':
        textSearch = 'text'
        fromDateField = 'acca_from_date'
        toDateField = 'acca_to_date'
        break
      case 'CMA':
        textSearch = 'cma_season'
        fromDateField = 'fromDate'
        toDateField = 'toDate'
        break
      default:
        textSearch = 'text'
        fromDateField = 'fromDate'
        toDateField = 'toDate'
    }
    return {
      [textSearch]: text,
      gender,
      status,
      classs,
      examType,
      examList,
      sortType,
      [fromDateField]: fromDate,
      [toDateField]: toDate,
      quizType,
      dateField,
    }
  }

  const fetchStudentList = async (currentPage: number, pageSize: number, params?: any) => {
    try {
      const res = await getAPIUser({
        page_index: currentPage,
        page_size: pageSize,
        params: {
          ...params,
          quizType: 'ENTRANCE_TEST',
          dateField: 'created_at',
        },
      })
      setStudentList(res?.data)
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }
  const onSubmit = () => {
    const fromDate = getValues('fromDate') ? new Date(getValues('fromDate')) : null
    const toDate = getValues('toDate') ? new Date(getValues('toDate')) : null

    const fromDateISO = fromDate
      ? new Date(
          Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0, 0)
        ).toISOString()
      : ''
    const toDateISO = toDate
      ? new Date(
          Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999)
        ).toISOString()
      : ''
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('gender')),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('class')),
        replaceValueAll(getValues('examType')),
        replaceValueAll(getValues('examList')),
        replaceValueAll(getValues('sortType')),
        fromDateISO,
        toDateISO,
        replaceValueAll(getValues('status'))
      )
    )
    toggleCheckAll(false)
    handleChangeParams(1, queryParams.page_size || 10)
    fetchStudentList(1, queryParams.page_size || 10, cleanedParams)
  }

  const onReset = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    fetchStudentList(1, 10)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const {checkedList, toggleCheck, toggleCheckAll, isCheckedAll} = useChecked<any>(
    studentList.users
  )

  const blockUser = useMutation(() => UsersAPI.blocked(checkedList), {
    onSuccess: () => {
      toast.success('Block Successfully!')
      setOpenBlocked(false)
      cancel(true)
      toggleCheckAll(false)
    },
  })
  return (
    <>
      <div className='px-10 border-0 pt-10'>
        <div className=''>
          <div className='row'>
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search'
                  defaultValue={queryParams?.text}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            <div className='col-xl-3 col-sm-4'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='gender'
                placeholder='Gender'
                defaultValue={queryParams.gender ?? ''}
                className='sapp-fs-select fs-6'
              >
                {GENDER.map((gender) => (
                  <Option key={gender.label} value={gender.value}>
                    {gender.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='status'
                placeholder='Status'
                defaultValue={queryParams.status ?? ''}
                className='sapp-fs-select fs-6'
              >
                {STATUS.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='class'
                placeholder='Class'
                defaultValue={queryParams.classs ?? ''}
                className='sapp-fs-select fs-6'
                disabled
              >
                {FILTER_CLASS.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-5'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='examType'
                placeholder='Type of Exams'
                defaultValue={queryParams.examType ?? ''}
                className='sapp-fs-select fs-6'
              >
                {FILTER_EXAM.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-5'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='examList'
                placeholder='List of Exams'
                defaultValue={queryParams.examList ?? ''}
                className='sapp-fs-select fs-6'
              >
                {STATUS.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-5'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
                className='sapp-fs-select fs-6'
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-5'>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
                className='sapp-h-datimepicker'
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-5'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
                className='sapp-h-datimepicker'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 col-lg-9 col-xl-4 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                okClick={onSubmit}
                resetClick={onReset}
                titleSubmit={BUTTON_TEXT.SEARCH}
                disabled={isLoading}
                loading={isLoading}
              />
            </div>

            {checkedList.length > 0 ? (
              <div className='col-xl-8 col-sm-8 col-lg-9 px-xl-3 pe-xl-0'>
                <UserListGrouping
                  selected={checkedList}
                  blockUser={async () => await blockUser.mutateAsync()}
                  openBlocked={openBlocked}
                  setOpenBlocked={setOpenBlocked}
                />
              </div>
            ) : (
              <div className='col-xl-8 col-sm-8 col-lg-9 px-xl-3'>
                <UsersListToolbar
                  searchTerm={watch('text')}
                  sortGender={watch('gender')}
                  sortSortBy={watch('sortType')}
                  sortStatus={watch('status')}
                  sortTypeofExam={watch('examType')}
                  sortListofExam={watch('examList')}
                  fromDate={watch('fromDate')}
                  toDate={watch('toDate')}
                  location={location}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <UsersTable
        studentList={studentList}
        loading={loading}
        setLoading={setLoading}
        filterParams={filterParams}
        getParams={getParams}
        handleChangeParams={handleChangeParams}
        fetchStudentList={fetchStudentList}
        queryParams={queryParams}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
      />
    </>
  )
}

export default UsersList
