import React from 'react'
// import SecuritySummary from './SecuritySummary'
// import RecentAlert from './RecentAlert'
import LoginSessions from './LoginSessions'
// import LicenseUsage from './LicenseUsage'

const SecurityProfile = () => {
  return (
    <>
      {/* <div className='row g-xxl-9'>
        <SecuritySummary />
        <RecentAlert />
      </div> */}

      <LoginSessions />

      {/* <LicenseUsage /> */}
    </>
  )
}

export default SecurityProfile
