import React, { Dispatch, SetStateAction, useState } from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SAPPTextFiled from 'src/components/base/textfield/SAPPTextFiled'
import { useConfirm } from 'src/hooks/use-confirm'

const UserOTP = ({
  setOpen,
  showInputOtp,
  onSubmitChangeEmail,
  resetEmail,
  setIsActive,
  otp,
  setOtp
}: {
  setOpen: Dispatch<SetStateAction<boolean>>
  showInputOtp: boolean | undefined
  onSubmitChangeEmail: () => Promise<void>
  resetEmail: () => void
  setIsActive: Dispatch<SetStateAction<boolean>>
  otp?: string[] | undefined
  setOtp?: any
}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClose:() => {
        resetEmail()
        setIsActive(false)
        setOpen(false)
        setOtp(['','','','','',''])} ,
      onClick: () => {
        resetEmail()
        setIsActive(false)
        setOpen(false)
        setOtp(['','','','','',''])
      },
    })
  }

  const handleInputChange = (index: number, e: any) => {
    const value = e.target.value;

    // Update the OTP array
    const newOtp = [...otp as string[]];
    newOtp[index] = value;
    setOtp(newOtp);

    // Check if all OTP digits are filled
    const isComplete = newOtp.every((digit) => digit.length === 1);

    // Enable/disable the button accordingly
    setButtonDisabled(!isComplete);

    // Move to the next input if the current one is filled
    if (value.length === 1 && index < (otp?.length as number) - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handlePaste = (index: number, e: any) => {
    const pasted = e.clipboardData.getData('text/plain').split(' ').slice(0, 6);

    // Update the OTP array
    const newOtp = [...otp as any];
    newOtp.splice(index, pasted.length, ...pasted);
    setOtp(newOtp);

    // Enable/disable the button accordingly
    setButtonDisabled(!newOtp.every((digit) => digit.length === 1));
  };

  const isAllEmpty = (arr: string[] | undefined) => arr?.every((value) => value !== '');

  return (
    <>
      {contextHolder}
      <div className='px-lg-17'>
        <label className='d-flex align-items-center fs-6 fw-bold mb-3'>
          <span className={'required'}>OTP</span>
        </label>
        <div className='d-flex fv-row fv-plugins-icon-container pb-10'>
          {otp?.map((digit, index) => (
            <SAPPTextFiled
              key={index}
              id={`otp-input-${index}`}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(index, e)}
              onPaste={(e: any) => handlePaste(index, e)}
              className={`${index === 5 ? '' : 'me-3'} text-center`}
              disabled={!showInputOtp}
            />
          ))}
        </div>

        {/* start:: dialog buton cancel submit */}
        <div className='modal-footer flex-center'>
          <SAPPDialogButtonsCancelSubmit
            cancelButtonCaption='Cancel'
            okButtonCaption='Save'
            okOnClick={onSubmitChangeEmail}
            cancelClick={handleCancel}
            disabled={isButtonDisabled && !isAllEmpty(otp)}
            className='m-0'
          />
        </div>

        {/* end:: dialog buton cancel submit */}
      </div>
    </>
  )
}
export default UserOTP
