import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import PopupConfirm from 'src/common/PopupConfirm'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import ActionCell from 'src/components/base/action/ActionCell'
import { ClassesApi } from 'src/apis/classes'
import { IClassStudent } from 'src/type/classes'
import { IConfirm } from 'src/hooks/use-confirm'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  id: any
  listUsers?: any
  setUser?: any
  action?: any
  classId: string | undefined
  getClassStudents: (page_index?: number | undefined, page_size?: number | undefined) => void | any
  user: IClassStudent
  confirm: (option: IConfirm) => void
  isPassed?: boolean
  isEnded?: boolean
  normalClassConnections: number
}

export const StudentActions = ({
  id,
  action,
  classId,
  getClassStudents,
  user,
  confirm,
  isPassed,
  isEnded,
  normalClassConnections,
}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const { profileMe } = useUserContext()
  const allowRenderDeleteStudent = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const allowRenderEditDuration = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_CLASS_GR.EDIT_DURATION) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const allowRenderEditCourseContentClass= profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const deleteItem = async () => {
    try {
      const res = await ClassesApi.deleteStudentInClass({
        user: [id],
        classId,
      })
      if (res) {
        toast.success('Delete Successfully!')
        getClassStudents()
      }
    } catch (error) {
      toast.error('Delete Failed!')
    } finally {
      setOpenModalDelete(false)
    }
  }

  const allowFoundation = async () => {
    try {
      const res = await ClassesApi.allowFoundation([user?.user_id], user?.class_id)
      if (res.success) {
        toast.success('Update Successfully!')
        getClassStudents()
      }
    } catch (error) {
      toast.error('Failed!')
    }
  }

  const handleAllowFoundation = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn cho học viên pass foundation không?'],
      onClick: allowFoundation,
    })
  }

  return (
    <>
      {' '}
      {user?.user?.status !== 'MERGED' && (
        <ActionCell customWidth='w-200px'>
          <>
            {normalClassConnections > 0 && !isPassed && !isEnded && (
              <div className='menu-item px-3' onClick={handleAllowFoundation}>
                <div className='menu-link px-3'>Allow Pass Foundation</div>
              </div>
            )}
            {allowRenderEditCourseContentClass && (
              <div
              className='menu-item px-3'
              onClick={() => {
                action('course', id)
              }}
            >
              <div className='menu-link px-3'>Edit Course Content</div>
            </div>
            )}
            {!isEnded && (
              <>
                {user?.started_at && user?.finished_at && allowRenderEditDuration &&
                  <div
                    className='menu-item px-3'
                    onClick={() => {
                      action('duration', id, user)
                    }}
                  >
                    <div className='menu-link px-3'>Update Duration</div>
                  </div>
                }
                {allowRenderDeleteStudent && (
                  <div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                )}
              </>
            )}
          </>
        </ActionCell>
      )}
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteItem}
        body='Bạn có chắc chắn muốn xóa không?'
      />
    </>
  )
}
