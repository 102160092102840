import {Select} from 'antd'
import {format} from 'date-fns'
import {debounce} from 'lodash'
import {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {ResourcesAPI} from 'src/apis/resources'
import {FORMAT_DATETIME} from 'src/constants'
import useChecked from 'src/hooks/use-checked'
import useCourseFilter from 'src/hooks/use-course-filter'
import {IMeta} from 'src/type'
import {displayBytes, formatISOFromDate, formatISOToDate, getDateInfo} from 'src/utils'
import SappTable from '../../SappTable'
import SAPPCheckbox from '../../checkbox/SAPPCheckbox'
import HookFormDateTime from '../../datetime/HookFormDateTime'
import PagiantionSAPP from '../../pagination/PagiantionSAPP'
import SAPPRadio from '../../radiobutton/SAPPRadio'
import HookFormSelectAntd from '../../select/HookFormSelectAntd'
import HookFormTextField from '../../textfield/HookFormTextField'
import {IResource, UPLOAD_TYPE} from './UploadFileInterface'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import {replaceValueAll} from 'src/utils/string'
const {Option} = Select
const headers = [
  {
    label: 'File',
    key: 'file',
    className: 'min-w-250px',
  },
  {
    label: 'Size',
    key: 'size',
    className: 'min-w-150px mw-200px w-200px',
  },
  {
    label: 'Created At',
    key: 'created_at',
    className: 'min-w-150px mw-200px w-150px',
  },
]

type Props = {
  setResource: (e: {
    listDataChecked: IResource[] | IResource
    unCheckedListData?: IResource[]
  }) => void
  isMultiple?: boolean
  fileChecked?: any
  fileType: keyof typeof UPLOAD_TYPE
  getDefaultChecked?: (resources: IResource[]) => IResource[]
}

interface ISearchForm {
  page_index?: number
  page_size?: number
  search_key?: string
  course_id?: string
  course_section_id?:string
  part?:string
  chapter?: string
  unit?: string
  activity?: string
  sortType?: string
  fromDate?: Date | string | null
  toDate?: Date | string | null
  suffix_types?: string
}

export const FILTER_RESOURCE_SORT_BY = [
  {
    label: 'Document',
    value: 'Document',
  },
  {
    label: 'Image',
    value: 'Image',
  },
  {
    label: 'Video',
    value: 'Video',
  },
]

// const children = {
//   course: ['part', 'chapter', 'unit', 'activity'],
//   part: ['chapter', 'unit', 'activity'],
//   chapter: ['unit', 'activity'],
//   unit: ['activity'],
// }
/**
 * @description Tab chọn recourse của upload file
 *
 * @param {Props} {setResource, isMultiple, fileChecked, fileType}
 * @return {*}
 */
const Resources = ({setResource, isMultiple, fileChecked, getDefaultChecked, fileType}: Props) => {
  const [loading, setLoading] = useState(false)
  const [resources, setResources] = useState<IResource[]>([])
  const [meta, setMeta] = useState<IMeta>()
  const {
    checkedList,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
    listDataChecked,
    setDefaultChecked,
    listDataUnChecked,
  } = useChecked<IResource>(resources)

  const searchValues = useRef<ISearchForm>({page_index: 1, page_size: 10})

  const {control, setValue, reset, getValues} = useForm<ISearchForm>({
    defaultValues: {
      sortType:
        FILTER_RESOURCE_SORT_BY.find((e) => e.label.toUpperCase() === fileType)?.value || '',
    },
  })

  useEffect(() => {
    getResources(searchValues.current)
    getCourses()
  }, [])

  useLayoutEffect(() => {
    if (listDataChecked) {
      setResource({listDataChecked, unCheckedListData: listDataUnChecked})
    } else {
      setResource({listDataChecked: listDataChecked?.[0]})
    }
  }, [listDataChecked, listDataUnChecked])

  useEffect(() => {
    if (fileChecked) {
      setDefaultChecked(fileChecked)
    }
  }, [fileChecked])
  /**
   * @description Lấy resource cho table list
   *
   * @param {{
   *     search_key?: string
   *     currenPage?: number
   *     size?: number
   *     fromDate?: string
   *     toDate?: string
   *     dateField?: string
   *     course_section_id?: string
   *     resource_type : string
   *   }} params
   */
  const getResources = async (params: ISearchForm) => {
    try {
      setLoading(true)
      const response = await ResourcesAPI.getList({
        ...params,
        ...(fileType !== 'ALL'
          ? {suffix_types: UPLOAD_TYPE[fileType].suffixType}
          : {
              suffix_types:
                UPLOAD_TYPE[params.suffix_types?.toLocaleUpperCase() || 'ALL']?.suffixType ||
                undefined,
            }),
          resource_type:'FILE' 
      })
      if (response?.data) {
        setResources(response.data.resources)
        if (getDefaultChecked) {
          // Tạo một object có key là các id của listDataChecked
          const listDataCheckedObject = listDataChecked.reduce((obj, item) => {
            if (item.id) {
              obj[item.id] = true
            }
            return obj
          }, {} as {[key: string]: boolean})

          const listDataUnCheckedObject = listDataUnChecked.reduce((obj, item) => {
            if (item.id) {
              obj[item.id] = true
            }
            return obj
          }, {} as {[key: string]: boolean})
          // Lọc ra những item trong getDefaultChecked(response.data.resources) có id không tồn tại trong object trên
          const filteredGetDefaultChecked = getDefaultChecked(response.data.resources).filter(
            (item) => {
              return !listDataCheckedObject[item.id]
            }
          )
          // Nối hai mảng lại thành defaultChecked
          const defaultChecked = listDataChecked
            .concat(filteredGetDefaultChecked)
            .filter((item) => {
              return !listDataUnCheckedObject[item.id]
            })
          setDefaultChecked(defaultChecked)
        }
        setMeta(response.data.meta)
      }
    } finally {
      setLoading(false)
    }
  }
  /**
   * @description filer table recourse khi có phân trang, search, filter
   *
   * @param {{
   *     search_key?: string
   *     page_index?: number
   *     page_size?: number
   *     fromDate?: string
   *     toDate?: string
   *     dateField?: string
   *     course_section_id?: string
   *   }} {
   *     search_key,
   *     page_index,
   *     page_size,
   *     fromDate,
   *     toDate,
   *     dateField = 'created_at',
   *     course_section_id,
   *   }
   */
  const handleFilter = ({
    search_key,
    course_id,
    course_section_id,
    chapter,
    unit,
    activity,
    sortType,
    page_index,
    page_size,
    fromDate,
    toDate,
    dateField = 'created_at',
    suffix_types,
    resource_type = 'FILE'
  }: {
    search_key?: string
    course_id?: string
    course_section_id?: string
    chapter?: string
    unit?: string
    activity?: string
    sortType?: string
    page_index?: number
    page_size?: number
    fromDate?: Date | string | null
    toDate?: Date | string | null
    dateField?: string
    suffix_types?: string
    resource_type?:string
  }) => {
    searchValues.current = {
      ...searchValues.current,
      page_index: 1,
      ...(search_key !== undefined ? {search_key} : {search_key: ""}),
      ...(course_id !== undefined && {course_id}),
      ...(course_section_id !== undefined && {course_section_id}),
      ...(chapter !== undefined && {chapter}),
      ...(unit !== undefined && {unit}),
      ...(activity !== undefined && {activity}),
      ...(sortType !== undefined && {sortType}),
      ...(page_index !== undefined && {page_index}),
      ...(page_size !== undefined && {page_size}),
      ...(fromDate !== undefined ? {fromDate} : {fromDate: undefined}),
      ...(toDate !== undefined ? {toDate} : {toDate : undefined}),
      ...(dateField !== undefined && {dateField}),
      ...(suffix_types !== undefined && {suffix_types}),
      ...(resource_type !== undefined && {resource_type}),
    }
    getResources(searchValues.current)
  }
  // const debouncedHandleFilter = debounce(handleFilter, 500)
  const fieldNames = [
    'search_key',
    'course',
    'part',
    'chapter',
    'unit',
    'activity',
    'fromDate',
    'toDate',
  ]
  
  const refreshSearch = () => {
    const filter = [
      'activity',
      'chapter',
      'course',
      'courseCategory',
      'part',
      'unit'
    ]
    let newListCourse = {...listCourse};
    filter.forEach((field: string) => {
      newListCourse = {
        ...newListCourse,
        [field]: {
          loading: false,
          data: [] ,
          id: undefined,
          metadata: undefined,
          search: '',
        },
      }
    })
    setListCourse(newListCourse)
    getCourses()
  }
  const initialValues: any = {
    search_key: '',
    course: '',
    course_section_id: '',
    chapter: '',
    unit: '',
    activity: '',
    fromDate: '',
    toDate: '',
  }
  const {
    listCourse,
    setListCourse,
    getListSort,
    debouncedGetListSort,
    clearChildren,
    getCourses,
    debouncedGetCourses,
    handleNextPage,
  } = useCourseFilter(setValue, handleFilter)
  const onSubmit = () => {
    const nameValue = getValues('search_key');
    const trimmedName = nameValue ? nameValue.trimStart().trimEnd() : '';
    const courses = getValues('course_id') || '';
    const sortType = getValues('sortType') || '';
    const fromDateValue = getDateInfo(getValues('fromDate') as unknown as Date) || '';
    const toDateValue = getDateInfo(getValues('toDate') as unknown as Date) || '';
    const formattedFromDate = !isNaN(fromDateValue?.day)
      ? formatISOFromDate(fromDateValue.year, fromDateValue.month, fromDateValue.day)
      : '';
    const formattedToDate = !isNaN(toDateValue?.day)
      ? formatISOToDate(toDateValue.year, toDateValue.month, toDateValue.day)
      : '';
    // Kiểm tra giá trị của chapter, unit, và activity
    const partValue = getValues('part');
    const chapterValue = getValues('chapter');
    const unitValue = getValues('unit');
    const activityValue = getValues('activity');
    let courseSectionIdValue = '';
    
    if (activityValue) {
      courseSectionIdValue = activityValue;
    } else if (unitValue) {
      courseSectionIdValue = unitValue;
    } else if (chapterValue) {
      courseSectionIdValue = chapterValue;
    } else if (partValue) {
      courseSectionIdValue = partValue;
    }

    const requestData: ISearchForm = {
      search_key: trimmedName,
      course_id: courses,
      course_section_id: courseSectionIdValue,
      sortType: sortType,
      fromDate: getValues('fromDate') ? formattedFromDate : "",
      toDate: getValues('toDate') ? formattedToDate : "",
      suffix_types: sortType
    };

    // Kiểm tra giá trị trước khi gọi getResources
    const validFields = Object.entries(requestData).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        acc[key as keyof ISearchForm] = value;
      }
      return acc;
    }, {} as ISearchForm);
    handleFilter(validFields);
    setLoading(true);
};

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)

    fieldNames.forEach((fieldName: any) => {
      setValue(fieldName, initialValues[fieldName])
    })
    searchValues.current = {
      page_index: 1,
      page_size: 10,
    }
    refreshSearch()
    handleFilter(searchValues.current)
    setLoading(true)
  }
  return (
    <Container fluid>
      <Row className='row-gap-3'>
        {/* begin:: filter */}
        <Col xl={4} sm={4}>
          <div className='card-title justify-content-center mb-0 mx-0'>
            <HookFormTextField
              control={control}
              name='search_key'
              placeholder='Search'
              defaultValue={''}
              className='sapp-h-45px'
              onSubmit={onSubmit}
            />
          </div>
        </Col>
        <Col xl={2} sm={4}>
          <HookFormSelectAntd
            name='course_id'
            placeholder='Course'
            control={control}
            size='large'
            className='sapp-h-45px'
            showSearch
            handleNextPage={handleNextPage}
            onChange={(e: any) => {
              if (e === undefined) {
                return
              }
              getListSort({parentId: e, type: 'part', parentType: 'course', isSelect: true})
            }}
            onSearch={async (e:any) => {
              debouncedGetCourses(e)
              return
            }}
            loading={listCourse.course.loading}
            allowClear
            onClear={() => {
              setListCourse(clearChildren('course'))
            }}
          >
            {listCourse.course.data?.map((e) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              )
            })}
          </HookFormSelectAntd>
        </Col>
        <Col xl={2} sm={4}>
          <HookFormSelectAntd
            name='part'
            placeholder='Part'
            control={control}
            size='large'
            showSearch
            className='sapp-h-45px'
            loading={listCourse.part.loading}
            handleNextPage={handleNextPage}
            onChange={(e: any) => {
              console.log(e)
              if (e === undefined) {
                return
              }
              getListSort({parentId: e, type: 'chapter', parentType: 'part', isSelect: true})
            }}
            allowClear
            onClear={() => {
              setListCourse(clearChildren('part'))
            }}
            onSearch={async (e) => {
              debouncedGetListSort({
                type: 'part',
                name: e,
                parentId: listCourse.course.id,
                parentType: 'course',
              })
              return
            }}
          >
            {listCourse.part.data?.map((e) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              )
            })}
          </HookFormSelectAntd>
        </Col>
        <Col xl={2} sm={4}>
          <HookFormSelectAntd
            name='chapter'
            placeholder='Chapter'
            control={control}
            size='large'
            className='sapp-h-45px'
            showSearch
            loading={listCourse.chapter.loading}
            handleNextPage={handleNextPage}
            onChange={(e: any) => {
              if (e === undefined) {
                return
              }
              getListSort({parentId: e, type: 'unit', parentType: 'chapter', isSelect: true})
            }}
            allowClear
            onClear={() => {
              setListCourse(clearChildren('chapter'))
            }}
            onSearch={async (e) => {
              debouncedGetListSort({
                type: 'chapter',
                name: e,
                parentId: listCourse.part.id,
                parentType: 'part',
              })
              return
            }}
          >
            {listCourse.chapter.data?.map((e) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              )
            })}
          </HookFormSelectAntd>
        </Col>

        <Col xl={2} sm={4}>
          <HookFormSelectAntd
            name='unit'
            placeholder='Unit'
            control={control}
            size='large'
            className='sapp-h-45px'
            showSearch
            loading={listCourse.unit.loading}
            handleNextPage={handleNextPage}
            onChange={(e: any) => {
              if (e === undefined) {
                return
              }
              getListSort({parentId: e, type: 'activity', parentType: 'unit', isSelect: true})
            }}
            onSearch={async (e) => {
              debouncedGetListSort({
                type: 'activity',
                name: e,
                parentId: listCourse.part.id,
                parentType: 'unit',
              })
              return
            }}
            allowClear
            onClear={() => {
              setListCourse(clearChildren('unit'))
            }}
          >
            {listCourse.unit.data?.map((e) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              )
            })}
          </HookFormSelectAntd>
        </Col>
        <Col xl={2} sm={4}>
          <HookFormSelectAntd
            name='activity'
            placeholder='Activity'
            control={control}
            size='large'
            className='sapp-h-45px'
            showSearch
            loading={listCourse.activity.loading}
            handleNextPage={handleNextPage}
            onChange={(e: any) => {
              setListCourse((c) => ({...c, activity: {...c.activity, id: e}}))
            }}
            onSearch={async (e) => {
              debouncedGetListSort({
                type: 'activity',
                name: e,
                parentId: listCourse.part.id,
                parentType: 'unit',
              })
              return
            }}
            allowClear
          >
            {listCourse.activity.data?.map((e) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              )
            })}
          </HookFormSelectAntd>
        </Col>
        <Col xl={2} sm={4}>
          <HookFormSelectAntd
            name='sortType'
            placeholder='Type'
            control={control}
            size='large'
            handleNextPage={handleNextPage}
            className='sapp-h-45px'
            {...(!fileType || fileType === 'ALL'
              ? {allowClear: true, onClear: () => handleFilter({suffix_types: 'ALL'})}
              : {})}
          >
            {FILTER_RESOURCE_SORT_BY.map((status) => (
              <Option
                disabled={status.label.toUpperCase() !== fileType && fileType !== 'ALL'}
                key={status.label}
                value={status.value}
              >
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>
        </Col>
        <Col xl={2} sm={4}>
          <HookFormDateTime control={control} name='fromDate' placeholder='From date' />
        </Col>
        <Col xl={2} sm={4}>
          <HookFormDateTime control={control} name='toDate' placeholder='To date' />
        </Col>
        {/* end:: filter */}
        <div className='card-header border-0 pt-6 px-3'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-xl-4 col-lg-7 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Row>
      <SappTable
        headers={headers}
        loading={false}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        data={resources}
        hasCheckAll={isMultiple ? true : false}
      >
        <>
          {resources?.map((resource, i) => {
            const isChecked = checkedList.includes(resource.id)
            return (
              <tr key={resource.id}>
                <td>
                  {isMultiple ? (
                    <SAPPCheckbox
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(resource.id)
                      }}
                    />
                  ) : (
                    <SAPPRadio
                      checked={isChecked}
                      onChange={() => {
                        toggleCheckAll(false)
                        toggleCheck(resource.id)
                      }}
                    />
                  )}
                </td>
                <td>
                  <div className='sapp-text-truncate-1'>{resource.name}</div>
                </td>
                <td>{displayBytes(resource?.size ? Number(resource.size) : 0)}</td>
                <td>
                  {resource?.created_at
                    ? `${format(new Date(resource.created_at), FORMAT_DATETIME)}`
                    : '-'}
                </td>
              </tr>
            )
          })}
        </>
      </SappTable>
      <PagiantionSAPP
        currentPage={meta?.page_index || 1}
        pageSize={meta?.page_size}
        totalItems={meta?.total_records}
        handlePaginationChange={(currenPage: any, size: any) => {
          handleFilter({page_index: currenPage, page_size: size})
        }}
      />
    </Container>
  )
}

export default Resources
