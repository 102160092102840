import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { StaffAPI } from 'src/apis/staffs'
import { UsersAPI } from 'src/apis/user'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { DURATION_EMAIL, PageLink, VALIDATION_FILED } from 'src/constants'
import { useUserContext } from 'src/context/UserProvider'
import { secondsToMinutesAndSeconds } from 'src/utils'
import { z } from 'zod'
import UserOTP from './UserOTP'
import { useConfirm } from 'src/hooks/use-confirm'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  id: string
  showInputOtp: boolean
  duration: number
  isActive: boolean
  setDuration: Dispatch<SetStateAction<number>>
  setIsActive: Dispatch<SetStateAction<boolean>>
  onSubmitSendOTP: any
  cancel?: any
  cancelUser?: any
}

interface IInputProps {
  email: string
}

const ModalEditEmail = ({
  open,
  setOpen,
  id,
  duration,
  isActive,
  setDuration,
  setIsActive,
  showInputOtp,
  onSubmitSendOTP,
  cancel,
  cancelUser,
}: IProps) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const { emailEditStaff, emailEditUser } = useUserContext()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const { sendEmailOTP, sendEmailOTPUser } = useUserContext()

  // Validate for input
  const validationSchema = z.object({
    email: z.string({ required_error: VALIDATION_FILED }).email(),
  })

  // Using validate for input
  const { control, handleSubmit, watch, reset } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const { confirm, contextHolder } = useConfirm()
  const [durationResend, setDurationResend] = useState(15);

  useEffect(() => {
    if(!open) return 
    let interval: any;

    if (durationResend > 0 && open && isActive) {
      interval = setInterval(() => {
        setDurationResend((prev) => {
          if (prev - 1 === 0) {
            clearInterval(interval);
          }
          return prev - 1;
        });
      }, 1000);
    }

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [durationResend, open, isActive]);

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => setOpen(false),
    })
    setOtp(['', '', '', '', '', ''])
    reset()
    setIsActive(false)
  }

  useEffect(() => {
    let interval: any
    if (isActive && duration > 0) {
      setIsActive(true)
      interval = setInterval(() => {
        setDuration((prev) => {
          if (prev - 1 === 0) {
            clearInterval(interval)
            setIsActive(false)
          }
          return prev - 1
        })
      }, 1000)
    }
  }, [isActive])

  const onSubmitChangeEmail = async () => {
    if (location.pathname === PageLink.STAFFS) {
      await StaffAPI.changeEmail(
        id,
        emailEditStaff,
        otp.join('')
      ).then((res) => {
        toast.success(res?.data?.message)
        setOpen(false)
        cancel(true)
      })
    } else {
      await UsersAPI.changeEmail(
        id,
        emailEditUser,
        otp.join('')
      ).then((res) => {
        toast.success(res?.data?.message)
        setOpen(false)
        location.pathname === PageLink.STUDENTS ? cancelUser(true) : cancel(true)
      })
    }
  }

  const onSubmit = (data: IInputProps) => {
    onSubmitSendOTP(data.email)
    setEmail(data.email)
  }

  const onClickResendOTP = (email: string) => {
    if (location.pathname === PageLink.STAFFS) {
      sendEmailOTP(id, email)
    } else {
      sendEmailOTPUser(id, email)
    }
    setDurationResend(15)
    duration === 0 && setDuration(DURATION_EMAIL)
  }

  return (
    <>
      {contextHolder}
      <Modal dialogClassName='modal-dialog mw-500px' centered show={open}>
        {/* start:: header modal */}
        <div className='modal-header'>
          <h2 className='mb-0'>Edit email</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCancel}>
            <i className='ki-outline ki-cross fs-1' />
          </div>
        </div>
        {/* end:: header modal */}

        {/* start:: body modal */}
        <div className='modal-body pt-10 py-0 px-lg-17'>
          {/* start:: textfield description */}
          <div className='d-flex flex-column mb-5 fv-row fv-plugins-icon-container'>
            <HookFormTextField
              control={control}
              name='email'
              label='Email'
              placeholder='Email'
              required
            />
          </div>
          <div className='d-flex mb-5 fv-row fv-plugins-icon-container align-items-center'>
            <div className='col-5 me-3'>
              <ButtonPrimary
                title='Send OTP'
                className='w-100'
                onClick={handleSubmit(onSubmit)}
                disabled={isActive || isEmpty(watch('email'))}
              />
            </div>
            <div className='col-5'>
              <ButtonSecondary
                title={`Resend OTP`}
                className='w-100'
                onClick={() => onClickResendOTP(email)}
                disabled={durationResend > 0}
              />
            </div>
            <div className='col-2'>
              <div className='mx-5'> {isActive ? secondsToMinutesAndSeconds(duration) : ''}</div>
            </div>
          </div>

          {/* end:: body modal */}
        </div>
        <UserOTP
          setOpen={setOpen}
          onSubmitChangeEmail={onSubmitChangeEmail}
          resetEmail={reset}
          setIsActive={setIsActive} showInputOtp={showInputOtp}
          otp={otp}
          setOtp={setOtp}
        />
      </Modal>
    </>
  )
}

export default React.memo(ModalEditEmail)
