import {PageLink} from 'src/constants'
import ModalImportTeacher from '../teacher-edit-modal/ModalImportTeacher'
import {useEffect, useState} from 'react'
import {UserExportHelper} from 'src/helper/export'
import SAPPActionButton from 'src/common/SAPPActionButton'

interface IProps {
  searchTerm: string | null
  sortGender: string | null
  sortStatus: string | null
  fromDate: Date | null
  toDate: Date | null
  location?: any
}

const TeachersListToolbar = ({searchTerm, sortGender, sortStatus, fromDate, toDate, location}: IProps) => {
  const [openImport, setOpenImport] = useState(false)
  const [loading, setLoading] = useState(false)

  const exportTeacher = async () => {
    setLoading(true)
    try {
      await UserExportHelper.exportToXlsxTeacher(sortGender, searchTerm, sortStatus,fromDate,toDate)
    } catch (error) {
      
    }finally{
      setLoading(false)
    }
  }
  const [isLocation, setIsLocation] = useState(false)
  useEffect(() => {
    setIsLocation(location.pathname === PageLink.TEACHERS);
  }, [location.pathname]);

  return (
    <div className='sapp-height-list--grouping'>
      <SAPPActionButton
        link={PageLink.CREATE_TEACHERS}
        onClick={exportTeacher}
        setOpen={setOpenImport}
        titleAdd='Create'
        loading={loading}
        location={isLocation}
      />
      <ModalImportTeacher open={openImport} setOpen={setOpenImport} />
    </div>
  )
}

export {TeachersListToolbar}
