import React from 'react'
import facebook from "src/_metronic/assets/media/svg/brand-logos/facebook-4.svg";
import instagram from "src/_metronic/assets/media/svg/brand-logos/instagram-2-1.svg";
import github from "src/_metronic/assets/media/svg/brand-logos/github.svg";
import behance from "src/_metronic/assets/media/svg/brand-logos/behance.svg";
import pinterest from "src/_metronic/assets/media/svg/brand-logos/pinterest-p.svg";
import twitter from "src/_metronic/assets/media/svg/brand-logos/twitter.svg";
import dribble from "src/_metronic/assets/media/svg/brand-logos/dribbble-icon-1.svg";
import { PageLink } from 'src/constants';

const SocialCard = () => {
    return (
        <div className='card mb-4 bg-light text-center'>
            <div className='card-body py-12'>
                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img
                        src={facebook}
                        className='h-30px my-2'
                        alt=''
                    />
                </a>

                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img
                        src={instagram}
                        className='h-30px my-2'
                        alt=''
                    />
                </a>

                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img src={github} className='h-30px my-2' alt='' />
                </a>

                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img src={behance} className='h-30px my-2' alt='' />
                </a>

                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img
                        src={pinterest}
                        className='h-30px my-2'
                        alt=''
                    />
                </a>

                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img src={twitter} className='h-30px my-2' alt='' />
                </a>

                <a href={PageLink.AUTH_LOGIN} className='mx-4'>
                    <img
                        src={dribble}
                        className='h-30px my-2'
                        alt=''
                    />
                </a>
            </div>
        </div>
    )
}

export default SocialCard