import {uniqueId} from 'lodash'
import {useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {QuestionBankAPI} from 'src/apis/question-bank'
import {UseCheckedResult} from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import ActiveQuestForm from '../choose-question-type/ActiveQuestForm'
import PreviewQuestion from '../preview-question/PreviewQuestion'
import {IQuestion, QUESTION_TYPES} from '../shared/interfaces'
import {useQuestionProviderContext} from '../shared/providers/QuestionProvider'
import TableListQuestion from './TableListQuestion'

export interface IListQuestionProps {}

const ListQuestion = ({
  useCheckedMultiple,
  useCheckedConstructed,
}: {
  useCheckedMultiple: UseCheckedResult<IQuestion>
  useCheckedConstructed: UseCheckedResult<IQuestion>
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [openQuestForm, setOpenQuestForm] = useState<boolean>(false)
  const [activeQuestForm, setActiveQuestForm] = useState<QUESTION_TYPES | null>(null)
  const [id, setId] = useState<string>()
  const [actionType, setActionType] = useState<'edit' | 'duplicate'>()
  const [openPreview, setOpenPreview] = useState<{value: boolean; data: any}>({
    value: false,
    data: undefined,
  })
  const {confirm, contextHolder} = useConfirm()
  const {
    state: {multiple, constructed},
    getListQuestions,
    refreshListQuestion,
  } = useQuestionProviderContext()

  useEffect(() => {
    getListQuestions('multiple', 1, 10, params.id)
    getListQuestions('constructed', 1, 10, params.id)
    const queryParams = new URLSearchParams(location.search)
    const questionQType = queryParams.get('questionQType')
    const questionId = queryParams.get('questionId')
    if (questionId && questionQType) {
      queryParams.delete('questionQType')
      navigate(location.pathname)
      setTimeout(() => {
        handleOpenFormEdit(questionId, questionQType as QUESTION_TYPES, 'edit')
      }, 500)
    }
  }, [])

  useEffect(() => {
    if (multiple.listQuestion.length === 0 && multiple?.pageIndex !== 1) {
      getListQuestions('multiple', multiple.pageIndex - 1, multiple.pageSize, params.id)
    }
    if (constructed.listQuestion.length === 0 && multiple?.pageIndex !== 1) {
      getListQuestions('constructed', constructed.pageIndex - 1, constructed.pageSize, params.id)
    }
  }, [multiple.totalRecords, constructed.totalRecords])

  const handleOpenFormEdit = (
    qId?: string,
    qType?: QUESTION_TYPES,
    setActionTypeParam: 'edit' | 'duplicate' = 'edit'
  ) => {
    if (!qId || !qType) {
      return
    }
    setActiveQuestForm(qType)
    setOpenQuestForm(true)
    setId(qId)
    setActionType(setActionTypeParam)
  }
  const handleOpenPreview = async (qId: string) => {
    try {
      const response = await QuestionBankAPI.getQuestionById(qId)
      if (response.success) {
        setOpenPreview({value: true, data: response.data})
      }
    } catch (error) {}
  }

  /**
   * @description Đóng popup question preview
   *
   */
  const handleClosePreview = () => {
    setOpenPreview({value: false, data: undefined})
  }

  const handleDeleteQuestion = (qId?: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn xóa không?',
      onClick: async () => {
        if (!qId) {
          return
        }
        const toastId = uniqueId('delete_question')
        // toast.loading('Deleting question...', {id: toastId})
        try {
          await QuestionBankAPI.deleteQuestion(qId)
          toast.success('Question deleted successfully!', {id: toastId})
          refreshListQuestion(['multiple', 'constructed'])
        } catch (error: any) {
          const res = error?.response?.data?.error?.code
          if (res === '400|030022' || res === '400|030208') {
            toast.error('Test/Quiz/Case Study included the question(s).')
          }
        } finally {
          toast.remove(toastId)
        }
      },
    })
  }

  return (
    <div>
      {contextHolder}
      <div className=''>
        {!!multiple?.listQuestion?.length && (
          <TableListQuestion
            listType='multiple'
            topicId={params.id}
            getListQuestions={getListQuestions}
            title='Câu hỏi trắc nghiệm'
            questionState={multiple}
            handleOpenFormEdit={handleOpenFormEdit}
            handleOpenPreview={handleOpenPreview}
            handleDeleteQuestion={handleDeleteQuestion}
            useChecked={useCheckedMultiple}
          ></TableListQuestion>
        )}

        {!!constructed?.listQuestion?.length && (
          <TableListQuestion
            listType='constructed'
            getListQuestions={getListQuestions}
            topicId={params.id}
            title='Câu hỏi tự luận'
            questionState={constructed}
            handleOpenFormEdit={handleOpenFormEdit}
            handleOpenPreview={handleOpenPreview}
            handleDeleteQuestion={handleDeleteQuestion}
            useChecked={useCheckedConstructed}
          ></TableListQuestion>
        )}
      </div>

      <ActiveQuestForm
        id={id}
        actionType={actionType}
        activeQuestForm={activeQuestForm}
        setActiveQuestForm={setActiveQuestForm}
        openQuestForm={openQuestForm}
        setOpenQuestForm={setOpenQuestForm}
      ></ActiveQuestForm>

      <PreviewQuestion
        open={openPreview.value}
        onClose={handleClosePreview}
        data={openPreview.data}
      />
    </div>
  )
}
export default ListQuestion
