import React, { useEffect, useState } from 'react'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import './MenuTabsSection.scss'

interface Tab {
  id: string
  title: string
  content: React.ReactNode
}

interface MenuTabsProps {
  tabs: Tab[]
  typeofSection?: any
  SectionName?: any
  confirm: (option: any) => void
  toggleLabelCallback: () => void
  onDeleteSection: (id: any) => void
  setEditAble: (editAble: any) => void
  index: number
  isEdit: boolean
  id?: any
  isLocked?: boolean
  isOnUsed: boolean
  setAddSection:any
  setCategorySate:any
  categoryState:any
  isQuestion?:boolean
  setPopup?:any
  section: any
  setValue: any
  setPreviousCategoryId?: any
  postionIndex?: number
}

const MenuTabsSection: React.FC<MenuTabsProps> = ({
  typeofSection,
  SectionName,
  tabs,
  confirm,
  toggleLabelCallback,
  onDeleteSection,
  setEditAble,
  isEdit,
  index,
  id,
  isLocked,
  isOnUsed,
  setAddSection,
  setCategorySate,
  categoryState,
  isQuestion,
  setPopup,
  section,
  setValue,
  setPreviousCategoryId,
  postionIndex,
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id)
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)
  const [isRotated, setIsRotated] = useState<boolean>(!id)
  const [expanded, setExpanded] = useState<boolean>(false)
  const changeTab = (tabId: string) => {
    setActiveTab(tabId)
  }
  const toggleSectionDisable = () => {
    if (((expanded && index !== id) || !expanded) && isAccordionOpen) {
      setEditAble(index)
      setCategorySate(categoryState)
      setPreviousCategoryId(categoryState)

    }
    if (!isAccordionOpen) {
      toggleAccordion()
      setCategorySate(categoryState)
      setEditAble(index)
      setIsRotated(!isAccordionOpen)
      setPreviousCategoryId(categoryState)
    }
  }
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen)
    setIsRotated(!isAccordionOpen)
    setExpanded(true)
    toggleLabelCallback()
  }

  const accordionStyle = {
    height: isAccordionOpen ? 'auto' : '88px',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
  }

  const clearValueSection = () => {
    Object.entries(section).map((e) => {
      setValue(`section.${postionIndex}.${e[0]}`, '')
    })
  }

  return (
    <div>
      <div className=''>
        <div className=''>
          <div className='d-flex  justify-content-between my-2'>
            <div className='d-flex'>
              {isAccordionOpen || !id ? (
                tabs.map((tab) => (
                  <button
                    key={tab.id}
                    className={`${activeTab === tab.id
                        ? 'bg-blue-500 text-[#141414]'
                        : 'text-gray-700 hover:text-gray-600 dark:hover:text-gray-300'
                      } py-2 px-4 focus:outline-none`}
                    style={{
                      borderBottom: activeTab === tab.id ? '1px solid #FFB880' : 'none',
                      borderTop: '0',
                      borderLeft: '0',
                      borderRight: '0',
                      background: 'none',
                      fontSize: '1.25rem',
                      fontFamily: 'Arial, sans-serif',
                      fontWeight: 'bold',
                    }}
                    onClick={() => changeTab(tab.id)}
                  >
                    {tab.title}
                  </button>
                ))
              ) : (
                <div>
                  <div className=' px-2 sapp-fs-16 fw-bold'>{typeofSection}</div>
                  <div className=' px-2 fw-normal sapp-color-text sapp-fs-14  '>{SectionName}</div>
                </div>
              )}
            </div>
            <div className='d-flex gap-2 justify-content-center align-items-center'>
              {isEdit && !isLocked && !isOnUsed && (
                <div className='d-flex gap-2 justify-content-center'>
                  <div>
                    <ButtonIconOnly
                      iconName={'notepad-edit'}
                      activeColor='dark'
                      bg='gray-100'
                      onClick={toggleSectionDisable}
                    />
                  </div>
                </div>
              )}
              {id && !isLocked && !isOnUsed && (
                <div>
                  <ButtonIconOnly
                    bg='gray-100'
                    iconName={'trash'}
                    activeColor='dark'
                    onClick={() => {
                      setTimeout(() => {
                        confirm({
                          okButtonCaption: 'Yes',
                          cancelButtonCaption: 'No',
                          body: 'Bạn có chắc chắn muốn xóa không?',
                          onClick: () => {
                            onDeleteSection(id)
                            setAddSection(true)
                            setEditAble(false)
                            clearValueSection()
                          },
                        })
                      })
                    }}
                  />
                </div>
              )}
              {isEdit && (
                <div
                  style={{
                    padding: !isRotated ? 0 : '',
                    transform: !isRotated ? 'rotate( 90deg)' : '',
                    cursor: 'pointer',
                  }}
                >
                  <ButtonIconOnly
                    iconName={'down'}
                    activeColor='dark'
                    bg='gray-100'
                    onClick={toggleAccordion}
                  />
                </div>
              )}


            </div>
          </div>
          {(isAccordionOpen || !id) && (
            <div className='p-2'>
              {tabs.map((tab) => (
                <div key={tab.id} className={`${activeTab !== tab.id ? 'hidden' : 'block'}`}>
                  {activeTab === tab.id && tab.content}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MenuTabsSection
