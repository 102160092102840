import {FC} from 'react'
import { PageLink } from 'src/constants'
import bgNotFound from "src/_metronic/assets/media/auth/bg7.jpg";
import notFound from "src/_metronic/assets/media/auth/404-error.png";
import notFoundDark from "src/_metronic/assets/media/auth/404-error-dark.png";

const NotFound: FC = () => {
  return (
    <>
      <div
        className='d-flex flex-column flex-root'
        style={{
          backgroundImage: `url(${bgNotFound})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
        }}
      >
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          <div className='d-flex flex-column flex-center text-center p-10'>
            <div className='card card-flush  w-lg-650px py-5'>
              <div className='card-body py-15 py-lg-20'>
                <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
                <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
                <div className='mb-3'>
                  <img
                    src={notFound}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                  <img
                    src={notFoundDark}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                  />
                </div>
                <div className='mb-0'>
                  <a className='btn btn-sm btn-primary' href={PageLink.AUTH_LOGIN}>
                    Return Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound