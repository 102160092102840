import React from 'react'
import {Col} from 'react-bootstrap'
import ButtonIcon from '../base/button/ButtonIcon'
import {PageLink} from 'src/constants'
import {KTIcon} from 'src/_metronic/helpers/components/KTIcon'

interface IProps {
  // index: string,
  participantName: string
  email: string
  phone: string
  university: string
  type: string
  major: string
  language: string
  testscore: string | number
  scoreoftest: string | number
  scoremajor: string | number
  typeofmajor: string
  ratio_score:string | number
  anotherScore: string | number
  manageScore: string
  studyPlan: string
  id: string
}

const ParticipantDetail = ({
  participantName,
  email,
  phone,
  university,
  type,
  major,
  language,
  testscore,
  scoreoftest,
  ratio_score,
  scoremajor,
  typeofmajor,
  anotherScore,
  manageScore,
  studyPlan,
  id,
}: IProps) => {
  return (
    <>
      <div className='flex flex-row w-full items-start'>
        <div className='bg-white flex justify-between gap-[270px] w-full items-start pb-6'>
          <div className='flex gap-5 w-full h-[714px] items-start'>
            <div className='row pb-4 '>
              <Col className='text-gray-400 fw-normal  sapp-fs-16px col-5'>Name</Col>
              <Col className='font-medium leading-[24px] fw-semibold sapp-fs-16px col-7'>
                {participantName}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className='text-gray-400 fw-normal sapp-fs-16px col-5'>Email</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {email}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className='text-gray-400 fw-normal sapp-fs-16px col-5'>Phone</Col>
              <Col className='font-medium leading-[24px] fw-semibold sapp-fs-16px col-7'>
                {phone}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className=' text-gray-400 fw-normal sapp-fs-16px col-5'>Trường đại học</Col>
              <Col className=' font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {university}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className=' text-gray-400 fw-normal sapp-fs-16px col-5'>Hệ</Col>
              <Col className=' font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {type}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className='text-gray-400 fw-normal sapp-fs-16px col-5'>Chuyên ngành</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {major}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className='text-gray-400 fw-normal sapp-fs-16px col-5'>Năng lực ngoại ngữ</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {language}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className='text-gray-400 fw-normal  sapp-fs-16px col-5'>Điểm bài thi</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {ratio_score}
              </Col>
            </div>
            <div className='row pt-4'>
              <Col className='text-gray-400 fw-normal mt-1 sapp-fs-16px col-5'>
                Chi tiết bài thi
              </Col>
              <Col className='flex flex-col items-center justify-start sapp-fs-16px h-[34px] col-7'>
                <ButtonIcon
                  title={'Test Result'}
                  className='inline-flex sapp-color-parti align-items-center text-center sapp-fs-16px  '
                  customButton='justify-center align-items-center cursor-pointer fw-base text-center '
                  link={`${PageLink.ENTRANCE_TEST_RESULT_SCORE_DETAIL}/${id}`}
                >
                  <KTIcon iconName='notepad' className='fs-2 sapp-color-percent ' />
                </ButtonIcon>
              </Col>
            </div>
            <div className='row py-4'>
              <Col className=' text-gray-400 fw-normal sapp-fs-16px col-5'>Điểm chuyên ngành</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {scoremajor}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className=' text-gray-400 fw-normal sapp-fs-16px col-5'>Phân loại điểm chuyên ngành</Col>
              <Col className=' font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {typeofmajor}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className=' text-gray-400 fw-normal sapp-fs-16px col-5'>Điểm khác</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {anotherScore}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className=' text-gray-400 fw-normal sapp-fs-16px col-5'>
                Phân loại điểm khác
              </Col>
              <Col className=' font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {manageScore}
              </Col>
            </div>
            <div className='row py-4'>
              <Col className='text-gray-400 fw-normal  sapp-fs-16px col-5'>Kế hoạch học tập</Col>
              <Col className='font-medium leading-[24px] sapp-fs-16px fw-semibold col-7'>
                {studyPlan}
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantDetail
