import ActionCell from 'src/components/base/action/ActionCell'
import {QUESTION_TYPES} from '../shared/interfaces'
import toast from 'react-hot-toast'
import {useUserContext} from 'src/context/UserProvider'
import {Role} from 'src/type'
import {CODE_ADMIN, TITLE_TOPIC_GR} from 'src/constants/permission'

type Props = {
  handleOpenFormEdit: (
    qId?: string,
    qType?: QUESTION_TYPES,
    setActionTypeParam?: 'edit' | 'duplicate'
  ) => void
  handleDeleteQuestion: (qId?: string) => void
  handleOpenPreview: (qId: string) => void
  id?: string
  qType?: QUESTION_TYPES
}

const QuestionActionsCell = ({
  handleOpenFormEdit,
  handleOpenPreview,
  handleDeleteQuestion,
  id,
  qType,
}: Props) => {
  const {profileMe} = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderPreview = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDelete = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      <ActionCell customWidth='w-150px'>
        <div className='menu-item px-3'>
          {allowRenderEdit && (
            <div className='menu-link px-3' onClick={() => handleOpenFormEdit(id ?? '', qType)}>
              Edit
            </div>
          )}
        </div>
        <div className='menu-item px-3'>
          {allowRenderPreview && (
            <div
              className='menu-link px-3'
              onClick={() => {
                if (!id) {
                  toast.error('Question not found!')
                  return
                }
                handleOpenPreview(id)
              }}
            >
              Preview
            </div>
          )}
        </div>
        <div className='menu-item px-3'>
          {allowRenderDelete && (
            <div className='menu-link px-3' onClick={() => handleDeleteQuestion(id)}>
              Delete
            </div>
          )}
        </div>
      </ActionCell>
    </>
  )
}

export default QuestionActionsCell
