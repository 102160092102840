import React, {Dispatch, SetStateAction, useEffect} from 'react'
import ProfileDetail from './ProfileDetail'
// import TopSelling from './TopSelling'
// import Application from './Application'
// import ProductDelivery from './ProductDelivery'
// import StockReport from './StockReport'
import {IStudentDetail} from 'src/type/students'

interface IProps {
  userDetail: IStudentDetail | undefined
  loading: boolean
  setProfile: Dispatch<SetStateAction<any>>
}

const OverviewProfile = ({userDetail, loading, setProfile}: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ProfileDetail userDetail={userDetail} loading={loading} setProfile={setProfile} />
      <div className='row gy-5 g-xl-10'>
        {/* <TopSelling /> */}
        {/* <Application /> */}
      </div>
      {/* <div className='row gy-5 g-xl-10'> */}
      {/* <ProductDelivery /> */}
      {/* <StockReport /> */}
      {/* </div> */}
    </>
  )
}

export default OverviewProfile
