import {UploadFile} from 'antd/es/upload'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import {ResourcesAPI} from 'src/apis/resources'

import {
  RESOURCE_LOCATION,
  UPLOAD_TYPE,
} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'

interface IModalUploadProps {
  fileType: keyof typeof UPLOAD_TYPE
  isMultiple?: boolean
  resourceLocation: RESOURCE_LOCATION
  tab: 'UPLOAD_FILE' | 'SETTING'
  uploadFile: UploadFile[] | undefined
  setUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any>[] | undefined>>
  parentId?: string
}

export const initCompleteModal = {
  open: false,
  errorRows: 0,
  totalRows: 0,
  fileName: '',
}
const ResourceUploadFile = ({
  uploadFile,
  setUploadFile,
  resourceLocation,
  parentId,
}: IModalUploadProps) => {
  const handleSetUploadFiles = (files: any) => {
    setUploadFile(files)
  }
  const handleRemoveAdditional = async (id: string) => {
    try {
      await ResourcesAPI.delete(id)
    } catch (error) {}
  }

  const customValidate = (file: UploadFile<any>, fileList: UploadFile<any>[]) => {
    const isVideo = file.originFileObj?.type.startsWith('video/')
    const uploadFileHasVideo = (uploadFile || []).find((e: any) => e.suffix_type === 'VIDEO')
    const multiVideo = fileList.filter((e) => e.originFileObj?.type?.startsWith('video/'))
    if (multiVideo?.[0]?.uid && multiVideo?.[0]?.uid === file.uid && !uploadFileHasVideo) {
      return false
    }
    if (!!isVideo && (!!(multiVideo?.length > 1) || !!uploadFileHasVideo)) {
      toast.error('Chỉ upload 1 video 1 lần!', {id: 'upload_toast_video'})
      return true
    }
    return false
  }

  return (
    <div>
      <div>
        <div className='mb-10'>
          <div className='w-100'>
            <UploadMulti
              fileList={uploadFile}
              setFileList={handleSetUploadFiles}
              fileType={'ALL_RESOURCE'}
              resourceLocation={resourceLocation}
              buttonTitle='Attach files'
              onlyTab={'UPLOAD_FILE'}
              customValidate={customValidate}
              title='Upload files'
              parentId={parentId}
              maxCount={10}
              guideline={[
                'Định dạng cho phép jpg, .jpeg, .png, .gif, .webp, .mp4, .pdf, .docx, .doc, .xls, .xlsx, .csv, .txt, .ppt, .pptx',
                'Kích thước tối đa của file là 20MB, video là 20GB',
              ]}
              handleRemoveAdditional={handleRemoveAdditional}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResourceUploadFile
