import {Control, UseFormWatch} from 'react-hook-form'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import CertificatesTemplateIcons, {
  ICertificatesTemplateIcons,
} from '../../CertificatesTemplateIcons'
import './TaskbarCheckbox.scss'
type Props = {
  name: string
  control: Control<any>
  icon: ICertificatesTemplateIcons
  watch: UseFormWatch<any>
}

const TaskbarCheckbox = ({name, control, icon, watch}: Props) => {
  return (
    <div className={`sapp-certificate-taskbar_checkbox ${watch(name)}`}>
      <HookFormCheckBox name={name} control={control}></HookFormCheckBox>
      <CertificatesTemplateIcons icon={icon} isOpacity={!watch(name)}></CertificatesTemplateIcons>
    </div>
  )
}

export default TaskbarCheckbox
