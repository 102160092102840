import { zodResolver } from '@hookform/resolvers/zod'
import { useLayoutEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import { PageLink, VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED } from 'src/constants'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { IResponse } from 'src/type'
import { ITopic, ITopicObject } from 'src/type/question-bank'
import { z } from 'zod'
import ChooseQuestionType from '../choose-question-type/ChooseQuestionType'
import ImportQuestion from '../import-question/ImportQuestion'
import { IQuestion } from '../shared/interfaces'
import { useQuestionProviderContext } from '../shared/providers/QuestionProvider'
import './FormTopic.scss'
import ListQuestion from './ListQuestion'
import ListGrouping from 'src/components/base/list-grouping/ListGrouping'
import { uniqueId } from 'lodash'
import { htmlToRaw } from 'src/utils'

// import { useStore } from 'video-react';
export interface IListQuestionProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface IUploadFiles {
  file_name: string
  type: string
  resource_id: string
}

const defaultValues = {
  name: '',
  description: '',
}
const FormTopic = () => {
  const params = useParams()
  const location = useLocation()

  const {
    state: { multiple, constructed },
    refreshListQuestion
  } = useQuestionProviderContext()

  const hookCheckedMultiple = useChecked<IQuestion>(multiple.listQuestion || [])
  const hookCheckedConstructed = useChecked<IQuestion>(constructed.listQuestion || [])

  const {
    checkedList: checkedListMultiple,
    listDataChecked: listDataCheckedMultiple,
    toggleCheckAll: toggleCheckAllMultiple,
  } = useMemo(() => hookCheckedMultiple, [hookCheckedMultiple])
  const {
    checkedList: checkedListConstructed,
    listDataChecked: listDataCheckedConstructed,
    toggleCheckAll: toggleCheckAllConstructed,
  } = useMemo(() => hookCheckedConstructed, [hookCheckedConstructed])

  const checkedList = useMemo(() => {
    return [...checkedListMultiple, ...checkedListConstructed]
  }, [checkedListMultiple, checkedListConstructed])

  const listDataChecked = useMemo(() => {
    return [...listDataCheckedMultiple, ...listDataCheckedConstructed]
  }, [listDataCheckedMultiple, listDataCheckedConstructed])

  const [openImportQuestionModal, setOpenImportQuestionModal] = useState<boolean>(false)
  const [openChooseQuestionTypeModal, setOpenChooseQuestionTypeModal] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()
  const [defaultEditor, setDefaultEditor] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadFiles, setUploadFiles] = useState<IUploadFiles[]>([])

  const [loadingData, setLoadingData] = useState(true)
  const navigate = useNavigate()
  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    description: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    files: z.array(z.any().optional()).default([]),
  })

  const useFormProp = useForm<ITopic>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  const { handleSubmit, control, setValue } = useFormProp

  const handleSetUploadFiles = (files: any) => {
    setUploadFiles(
      files.map((e: { id: string; name: string; resource_id: string; url: string }) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name,
        type: 'attached',
        url: e.url
      }))
    )
  }

  const loadData = async () => {
    if (params.id) {
      try {
        setLoading(true)
        const { data } = await QuestionBankAPI.getTopicById(params.id)
        if (data) {
          const dataDes = data.description
          setDefaultEditor(dataDes)
          setValue('name', data.name)
          setValue('description', dataDes)
          setUploadFiles(() =>
            data.files?.map((e: any) => ({
              resource_id: e.resource?.id,
              id: e.resource?.id,
              name: e.resource?.name,
              type: 'attached',
              url: e.resource?.url
            }))
          )
        } else {
          toast.error('Topic not found!')
          setTimeout(() => {
            navigate(`${PageLink.TOPICS}`)
          })
        }
      } catch (error: any) {
        toast.error(error.message || 'Topic not found!')
        setTimeout(() => {
          navigate(`${PageLink.TOPICS}`)
        })
      } finally {
        setLoading(false)
      }
    }
    setLoadingData(false)
  }

  useLayoutEffect(() => {
    loadData()
  }, [params.id])

  const onSubmit = async (topic: ITopic, type?: 'add' | 'import') => {
    const saveTopic: ITopic = {
      name: (topic.name ?? '').trim(),
      description: (topic.description ?? '').trim(),
    }
    try {
      let response: IResponse<ITopicObject>
      const requestParams = {
        ...saveTopic,
        display_type: 'VERTICAL',
        files: uploadFiles,
      }
      if (params.id) {
        response = await QuestionBankAPI.editTopic({
          ...requestParams,
          id: params.id,
        })
      } else {
        response = await QuestionBankAPI.createTopic(requestParams)
      }

      if (response) {
        params.id ? loadData() : navigate(`${PageLink.TOPIC}/${response?.data?.id}${location.search || ''}`, { replace: true })
      }

      toast.success('Topic saved successfully!')
      if (params.id) {
        return
      }
      if (type === 'import') {
        setOpenImportQuestionModal(true)
      } else {
        setOpenChooseQuestionTypeModal(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(`${PageLink.TOPICS + (location.search || '')}`),
    })
  }

  const handleDeleteQuestions = async() => {
    if (!checkedList || checkedList.length <= 0) {
      return
    }
    setLoading(true)
    const toastId = uniqueId('delete_question')
    try {
      await QuestionBankAPI.deleteBulkQuestion(checkedList)
      toast.success('Questions deleted successfully!', { id: toastId })
      refreshListQuestion(['multiple', 'constructed'])
      toggleCheckAllConstructed(false)
      toggleCheckAllMultiple(false)
    } catch (error: any) {
      const res = error?.response?.data?.error?.code
      if (res === '400|030022' || res === '400|030208') {
        toast.error('Test/Quiz/Case Study included the question(s).')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {contextHolder}
      <div className='card h-xl-100'>
        <div className='card-body border-0 d-block p-10 pb-0'>
          <div className='topic_name card-title d-flex flex-wrap mb-7 pb-1 w-100'>
            <HookFormTextField
              label='Item Set Name'
              required
              control={control}
              name='name'
              placeholder='Item Set name'
              className='w-100 sapp-h-48px fs-6'
              skeleton={loading}
            ></HookFormTextField>
          </div>
          <div className='topic_description d-flex flex-wrap mb-7 pb-1'>
            <HookFormEditor
              label='Description'
              placeholder='Description'
              height={508}
              name='description'
              control={control}
              className='w-100 fs-6'
              math={true}
              defaultValue={defaultEditor}
              skeleton={loading}
            />
          </div>

          <div className='topic_description w-100'>
            <UploadMulti
              fileList={uploadFiles}
              setFileList={handleSetUploadFiles}
              label='Resources'
              guideline={[
                'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx. Kích thước tối đa 20MB.',
              ]}
              resourceLocation={RESOURCE_LOCATION.topic}
              loading={loadingData}
            />
          </div>

        </div>
        <div className='d-flex border-0 justify-content-end card-footer p-10'>
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption='Save'
            okOnClick={() => {
              handleSubmit((data: any) => {
                setLoading(true)
                setTimeout(() => {
                  onSubmit(data)
                })
              })()
            }}
            className='justify-content-between d-flex'
            // classNameCancel='px-7 py-5 fs-4 lh-1 fw-bold me-3'
            // classNameSubmit='px-8 py-5 fs-4 lh-1 fw-bold'
            loading={loading}
          />
        </div>
      </div>
      {params.id && (
        <div className='card sapp-question-list mt-xl-8 mt-2 card mt-xl-5 mt-2 px-10 py-8'>
          <div className='card-toolbar justify-content-between d-flex align-items-center'>
            <div className='d-flex align-items-center justify-content-between'>
              <h3 className='card-title m-0 text-nowrap fw-bold me-5'>
                Questions List ({(multiple?.totalRecords || 0) + (constructed?.totalRecords || 0)})
              </h3>
            </div>
            {/* start:: button open modal */}
            <div className='d-flex flex-wrap justify-content-end gap-5'>
              {checkedList.length > 0 ?
                (<ListGrouping
                  selected={checkedList}
                  okClick={handleDeleteQuestions}
                  title='Delete Selected'
                  body='Bạn có chắc chắn muốn xóa không?'
                  okButtonCaption='Yes'
                />)
                :
                (<ButtonIcon
                  title='Create Question'
                  className='height-min--content w-xl-auto mt-xl-0 mt-2 text-nowrap px-6 py-3'
                  onClick={() => setOpenChooseQuestionTypeModal(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                </ButtonIcon>)}
            </div>
            {/* end:: button open modal */}
          </div>

          <ListQuestion
            useCheckedMultiple={hookCheckedMultiple}
            useCheckedConstructed={hookCheckedConstructed}
          ></ListQuestion>
        </div>
      )}

      <ImportQuestion
        open={openImportQuestionModal}
        setOpen={setOpenImportQuestionModal}
      ></ImportQuestion>
      <ChooseQuestionType
        open={openChooseQuestionTypeModal}
        setOpen={setOpenChooseQuestionTypeModal}
      ></ChooseQuestionType>
    </div>
  )
}

export default FormTopic
