import React from 'react'
import { useLocation } from 'react-router-dom'
import { PageLink } from 'src/constants'
import SupportOverview from './support-overview'
import SupportTickets from './support-tickets'
import SupportFAQ from './support-faq'

const RenderSupport = () => {
    const location = useLocation()

    const renderSupport = () => {
        switch (location.pathname) {
            case PageLink.SUPPORT_OVERVIEW:
                return <SupportOverview />
            case PageLink.SUPPORT_TICKETS:
                return <SupportTickets />
            case PageLink.SUPPORT_FAQS:
                return <SupportFAQ />
            default:
                return <SupportOverview />
        }
    }
    return (
        <>
            {renderSupport()}
        </>
    )
}

export default RenderSupport