import { ReactNode, useEffect, useState } from 'react'
import { PageDataProvider } from 'src/_metronic/layout/core'
import Header from './header'
import Footer from './footer'
import { ThemeModeProvider } from 'src/_metronic/layout/theme-mode/ThemeModeProvider'
import { Helmet } from 'react-helmet'
import Notifications from './Notifications'
import { ITabs } from 'src/type'
import { useLocation } from 'react-router-dom'
import { reInitMenu } from 'src/_metronic/helpers'
import BackTopPage from './backtop'
import Sidebar from './sidebar/index'

interface IProps {
  children: ReactNode
  pageTitle: string | undefined
  breadcrumbs: ITabs[]
  showSideBar?: boolean
  titleTab?: string
  backgroudColor?: string
  showFooter?: boolean
  removePadding?:boolean
  classNameLayout?: string
}

const PageLayouts = ({ children, pageTitle, breadcrumbs, showSideBar = true, titleTab, backgroudColor='sapp-custom-background-layout',removePadding,showFooter = true, classNameLayout }: IProps) => {
  const location = useLocation()
  const [openSideBar, setOpenSideBar] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)

  // function open side bar
  const handleOpenSideBar = () => {
    setOpenSideBar(true)
    document.body.setAttribute('data-kt-app-sidebar-secondary-collapse', 'on')
    window.localStorage.setItem('sidebar', 'true')
  }

  // function close side bar
  const handleCloseSideBar = () => {
    setOpenSideBar(false)
    document.body.removeAttribute('data-kt-app-sidebar-secondary-collapse')
    window.localStorage.removeItem('sidebar')
  }

  // call init menu when key of location change
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  // useEffect(() => {
  //   if (location.pathname === PageLink.TOPICS) {
  //     window.localStorage.setItem('sidebar', 'true')
  //     setOpenSideBar(true)
  //   }
  // }, [location.pathname])

  useEffect(() => {
    if (window.localStorage.getItem('sidebar')) {
      document.body.setAttribute('data-kt-app-sidebar-secondary-collapse', 'on')
      setOpenSideBar(true)
    } else {
      document.body.removeAttribute('data-kt-app-sidebar-secondary-collapse')
      setOpenSideBar(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!showSideBar) {
      document.body.setAttribute('data-kt-app-sidebar-secondary-collapse', 'on')
    }
  }, [showSideBar])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        {/* Start config title and meta */}
        <Helmet>
          <title>{pageTitle || titleTab || ''} - SAPP Academy</title>
          <meta
            name='description'
            content='Hệ thống Nền tảng Học và Thi trực tuyến được SAPP Academy xây dựng nhằm mục đích cung cấp trải nghiệm học tập hiện đại, cá nhân hóa, giúp học viên tối ưu kết quả học tập ACCA, CFA'
          />
          <meta name='keywords' content='sapp, lms, acca, ACCA, CFA, Big4, 3P, SAPP Academy' />
          <meta property='og:type' content='website' />
          <meta
            property='og:title'
            content='Hệ thống Quản lý học và thi ACCA, CFA trực tuyến SAPP Academy'
          />
          <meta property='og:site_name' content='lms.sapp.edu.vn' />
          <meta property='og:image' content='https://lms.sapp.edu.vn/images/sapp.svg' />
          <meta
            property='twitter:title'
            content='Hệ thống Quản lý học và thi ACCA, CFA trực tuyến SAPP Academy'
          />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:site' content='@lms.sapp.edu.vn' />
          <meta name='twitter:image' content='https://lms.sapp.edu.vn/images/sapp.svg' />
          <meta name='twitter:image:src' content='https://lms.sapp.edu.vn/images/sapp.svg' />
          <meta
            name='twitter:description'
            content='Hệ thống Quản lý học và thi ACCA, CFA trực tuyến SAPP Academy'
          />
          <meta content='Hệ thống Nền tảng Học và Thi trực tuyến được SAPP Academy xây dựng nhằm mục đích cung cấp trải nghiệm học tập hiện đại, cá nhân hóa, giúp học viên tối ưu kết quả học tập ACCA, CFA' />
        </Helmet>
        {/* End config title and meta */}
        <div className='d-flex flex-column flex-root app-root sapp-min-h-100vh' id='kt_app_root'>
          <div
            className={`app-page flex-column flex-column-fluid ${backgroudColor}`}
            id='kt_app_page'
          >
            {/* Start header */}
            {
              pageTitle && (
                <Header
                  pageTitle={pageTitle}
                  breadcrumbs={breadcrumbs}
                  setOpenNotifications={setOpenNotifications}
                />
              )
            }

            {/* End Header */}

            <div className={`${!removePadding ? 'app-wrapper' :'mt-8'} flex-column flex-row-fluid ${classNameLayout ?? ''}`} id='kt_app_wrapper'>
              {showSideBar && (
                <div
                  id='kt_app_sidebar'
                  className='app-sidebar'
                  data-kt-drawer='true'
                  data-kt-drawer-name='app-sidebar'
                  data-kt-drawer-activate='{default: true, lg: false}'
                  data-kt-drawer-overlay='true'
                  data-kt-drawer-width='auto'
                  data-kt-drawer-direction='start'
                // data-kt-drawer-toggle='#kt_app_sidebar_mobile_toggle'
                >
                  {/* Start SideBarRight */}
                  <Sidebar />
                  {/* End SideBarRight */}
                  {/* Start button open and close sidebar */}
                  <button
                    className={`${openSideBar ? 'active' : ''
                      } app-sidebar-secondary-toggle btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-color-primary position-absolute translate-middle z-index-1 start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-4`}
                    onClick={!openSideBar ? handleOpenSideBar : handleCloseSideBar}
                  >
                    <i className='ki-outline ki-arrow-left fs-2 rotate-180'></i>
                  </button>
                  {/* End button open and close sidebar */}
                </div>
              )}
              {/* Start main of layout */}
              <div
                className={`app-main flex-column flex-row-fluid ${backgroudColor}`}
                id='kt_app_main'
              >
                <div className='d-flex flex-column flex-column-fluid'>
                  <div id='kt_app_toolbar' className='app-toolbar mb-5 mb-lg-0'>
                    <div
                      id='kt_app_toolbar_container'
                      className='app-container container-xxl d-flex flex-stack flex-wrap'
                    ></div>
                  </div>

                  <div id='kt_app_content' className='app-content flex-column-fluid'>
                    <div id='kt_app_content_container' className='app-container container-xxl'>
                      {children}
                    </div>
                  </div>
                </div>
                {/* End main of layout */}

                {/* Start footer */}
                { showFooter && <Footer />}
                {/* End footer */}
              </div>
            </div>
          </div>
        </div>
        {/* Start notifications */}
        <Notifications
          openNotifications={openNotifications}
          setOpenNotifications={setOpenNotifications}
        />
        {/* End notifications */}

        {/* Start button backtop */}
        <BackTopPage />
        {/* End button backtop */}
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export default PageLayouts
