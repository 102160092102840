import React from 'react'
import { PageLink } from 'src/constants'

const BillingHistory = () => {
    return (
        <div className='card'>
            <div className='card-header card-header-stretch border-bottom border-gray-200'>
                <div className='card-title'>
                    <h3 className='fw-bold m-0'>Billing History</h3>
                </div>

                <div className='card-toolbar m-0'>
                    <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
                        <li className='nav-item' role='presentation'>
                            <a
                                id='kt_billing_6months_tab'
                                className='nav-link fs-5 fw-semibold me-3 active'
                                data-bs-toggle='tab'
                                role='tab'
                                href='#kt_billing_months'
                            >
                                Month
                            </a>
                        </li>

                        <li className='nav-item' role='presentation'>
                            <a
                                id='kt_billing_1year_tab'
                                className='nav-link fs-5 fw-semibold me-3'
                                data-bs-toggle='tab'
                                role='tab'
                                href='#kt_billing_year'
                            >
                                Year
                            </a>
                        </li>

                        <li className='nav-item' role='presentation'>
                            <a
                                id='kt_billing_alltime_tab'
                                className='nav-link fs-5 fw-semibold'
                                data-bs-toggle='tab'
                                role='tab'
                                href='#kt_billing_all'
                            >
                                All Time
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='tab-content'>
                <div
                    id='kt_billing_months'
                    className='card-body p-0 tab-pane fade show active'
                    role='tabpanel'
                    aria-labelledby='kt_billing_months'
                >
                    <div className='table-responsive'>
                        <table className='table table-row-dashed align-middle gy-4 gs-9'>
                            <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-opacity-75'>
                                <tr>
                                    <td className='min-w-150px'>Date</td>
                                    <td className='min-w-250px'>Description</td>
                                    <td className='min-w-150px'>Amount</td>
                                    <td className='min-w-150px'>Invoice</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                    <td>Nov 01, 2020</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for Ocrober 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Oct 08, 2020</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for September 2023</a>
                                    </td>
                                    <td>$98.03</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Aug 24, 2020</td>
                                    <td>Paypal</td>
                                    <td>$35.07</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Aug 01, 2020</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for July 2023</a>
                                    </td>
                                    <td>$142.80</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jul 01, 2020</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for June 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jun 17, 2020</td>
                                    <td>Paypal</td>
                                    <td>$523.09</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jun 01, 2020</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for May 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    id='kt_billing_year'
                    className='card-body p-0 tab-pane fade'
                    role='tabpanel'
                    aria-labelledby='kt_billing_year'
                >
                    <div className='table-responsive'>
                        <table className='table table-row-dashed align-middle gy-4 gs-9'>
                            <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                                <tr>
                                    <td className='min-w-150px'>Date</td>
                                    <td className='min-w-250px'>Description</td>
                                    <td className='min-w-150px'>Amount</td>
                                    <td className='min-w-150px'>Invoice</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                    <td>Dec 01, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Billing for Ocrober 2023</a>
                                    </td>
                                    <td>$250.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Oct 08, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Statements for September 2023</a>
                                    </td>
                                    <td>$98.03</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Aug 24, 2021</td>
                                    <td>Paypal</td>
                                    <td>$35.07</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Aug 01, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for July 2023</a>
                                    </td>
                                    <td>$142.80</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jul 01, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Statements for June 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jun 17, 2021</td>
                                    <td>Paypal</td>
                                    <td>$23.09</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    id='kt_billing_all'
                    className='card-body p-0 tab-pane fade'
                    role='tabpanel'
                    aria-labelledby='kt_billing_all'
                >
                    <div className='table-responsive'>
                        <table className='table table-row-dashed align-middle gy-4 gs-9'>
                            <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                                <tr>
                                    <td className='min-w-150px'>Date</td>
                                    <td className='min-w-250px'>Description</td>
                                    <td className='min-w-150px'>Amount</td>
                                    <td className='min-w-150px'>Invoice</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                    <td>Nov 01, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Billing for Ocrober 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Aug 10, 2021</td>
                                    <td>Paypal</td>
                                    <td>$35.07</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Aug 01, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for July 2023</a>
                                    </td>
                                    <td>$142.80</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jul 20, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Statements for June 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jun 17, 2021</td>
                                    <td>Paypal</td>
                                    <td>$23.09</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Jun 01, 2021</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD}>Invoice for May 2023</a>
                                    </td>
                                    <td>$123.79</td>
                                    <td>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            PDF
                                        </a>
                                    </td>
                                    <td className='text-right'>
                                        <a href={PageLink.DASHBOARD} className='btn btn-sm btn-light btn-active-light-primary'>
                                            View
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingHistory