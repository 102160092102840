import {debounce} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {FieldError, useForm} from 'react-hook-form'
import {KTIcon} from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import NotData from 'src/components/NotData'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import {IQuestion} from 'src/components/question-bank/shared/interfaces'
import {cleanParamsAPI, htmlToRaw} from 'src/utils'
import {normalizeLikeParam, replaceValueAll} from 'src/utils/string'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {Control} from 'react-hook-form'
import {TYPE_OF_TEST} from '../shared/consts'
import {QuestionBankAPI} from 'src/apis/question-bank'
import QuestTionPreview from 'preview-question-bank'
import {Spin} from 'antd'
import ModalPreviewFile from 'src/components/question-bank/preview-question/ModalPreviewFile'
import { useLocation } from 'react-router-dom'

type Props = {
  questions: IQuestion[]
  setQuestions: (value: React.SetStateAction<IQuestion[]>) => void
  confirm: (option: any) => void
  handleChooseQuestions: () => void
  error?: FieldError | undefined
  maxQuestionsNumber?: number | 'NO_MAX'
  disabled?: boolean
  control: Control<any>
  setValue: any
  getValues: any
  watchType: any
  defaultType: any
  totalQuestionsMultiple: number
  totalQuestionsConstructed: number
}

const headers = [
  {
    label: 'List Questions',
    key: 'list_questions',
    className: 'fs-7 lh-1 fw-bold',
  },
  {
    label: 'Mark of each question',
    key: 'mark_of_each_question',
    className: 'fs-7 sapp-w-180 lh-1 fw-bold',
  },
  {
    label: 'Action',
    key: 'actions',
    className: 'd-flex justify-content-end fs-7 lh-1 fw-bold ',
  },
]

const TestQuestionTable = ({
  questions,
  setQuestions,
  confirm,
  handleChooseQuestions,
  error,
  maxQuestionsNumber,
  disabled,
  control,
  setValue,
  getValues,
  watchType,
  defaultType,
  totalQuestionsMultiple,
  totalQuestionsConstructed
}: Props) => {
  const [filter, setFilter] = useState({
    search: '',
    currentPage: 1,
    pageSize: 10,
  })
  const [loading, setLoading] = useState(true)
  const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([])
  const [percentQuestionsMulti, setPercentQuestionsMulti] = useState<any>()
  const [checkTrue, setCheckTrue] = useState<boolean>(false)
  const [openPreview, setOpenPreview] = useState<{value: boolean; data: any}>({
    value: false,
    data: undefined,
  })
  const [loadPreview, setLoadPreview] = useState(false)
  const [viewFile, setViewFile] = useState({status: false, url: undefined})
  const handleClosePreview = () => {
    setOpenPreview({value: false, data: undefined})
  }

  const calculateScoreChildNoValue = (total: number) => {
    questions?.map((e) => {
      setValue(`question_percent_${e.id}`, Math.round((1 / questions.length) * total * 10) / 10)
    })
  }

  const calculateScoreChildValue = (total: number) => {
    questions?.map((e: any) => {
      setValue(
        `question_percent_${e.id}`,
        e?.setting_grade || Math.round((1 / questions.length) * total * 10) / 10
      )
    })
  }

  const setValueTotal = (total: number) => {
    setValue(`get_percent_questions_${defaultType}`, total)
  }

  const setValueTotalMix = (name: string, total: number) => {
    const totalValue = Math.round(
      questions.reduce(
        (acc: any, question: any) =>
          acc + (isNaN(Number(question.setting_grade)) ? 0 : Number(question.setting_grade)),
        0
      )
    )
    setValue(`get_percent_questions_${name}`, totalValue > 0 ? total : 0)
  }

  const currentQuestions = useMemo(() => {
    return filteredQuestions.slice(
      (filter.currentPage - 1) * filter.pageSize,
      filter.currentPage * filter.pageSize
    )
  }, [filteredQuestions, filter.currentPage, filter.pageSize])

  useEffect(() => {
    if (currentQuestions.length === 0) {
      setFilter((prev) => {
        const obj = {...prev}
        if (obj.currentPage > 1) {
          obj.currentPage = obj.currentPage - 1
        }
        return obj
      })
    }
  }, [currentQuestions])

  useEffect(() => {
    if (defaultType === 'multi' && watchType !== TYPE_OF_TEST.mix.value) {
      calculateScoreChildValue(100)
      setValueTotal(100)
    } else if (defaultType === 'constructed' && watchType !== TYPE_OF_TEST.mix.value) {
      calculateScoreChildValue(100)
      setValueTotal(100)
    }

    setTimeout(() => {
      setCheckTrue(true)
    }, 500)
  }, [])

  // Set value of percent
  useEffect(() => {
    if (checkTrue) {
      if (defaultType === 'multi' && watchType !== TYPE_OF_TEST.mix.value) {
        calculateScoreChildNoValue(100)
        setValueTotal(100)
      } else if (defaultType === 'constructed' && watchType !== TYPE_OF_TEST.mix.value) {
        calculateScoreChildNoValue(100)
        setValueTotal(100)
      } else {
        if (defaultType === 'multi') {
          const getTotalPercent = Number(getValues(`get_percent_questions_multi`))
          calculateScoreChildNoValue(getTotalPercent)
        } else if (defaultType === 'constructed') {
          const totalConstructedPercent = Number(getValues(`get_percent_questions_constructed`))
          calculateScoreChildNoValue(totalConstructedPercent)
        }
      }
    }
  }, [currentQuestions, watchType, percentQuestionsMulti])

  useEffect(() => {
    if (!checkTrue && watchType === TYPE_OF_TEST.mix.value) {
      if (defaultType === 'multi') {
        setValueTotalMix('multi', totalQuestionsMultiple)
        const getTotalPercent = Number(getValues(`get_percent_questions_multi`))
        calculateScoreChildValue(getTotalPercent)
      } else {
        setValueTotalMix('constructed', totalQuestionsConstructed)
        const totalConstructedPercent = Number(getValues(`get_percent_questions_constructed`))
        calculateScoreChildValue(totalConstructedPercent)
      }
    }
  }, [watchType, questions])

  useEffect(() => {
    if (checkTrue && watchType === TYPE_OF_TEST.mix.value) {
      if (defaultType === 'multi') {
        setValueTotalMix('multi', totalQuestionsMultiple)
      } else {
        setValueTotalMix('constructed', totalQuestionsConstructed)
      }
    }
  }, [watchType])

  const handleChangePage = (page: number, pageSize: number) => {
    setFilter({...filter, currentPage: page, pageSize})
  }

  const onSubmit = () => {
    const nameValue = getValues('search_name')
    setFilter({ ...filter, currentPage: 1, search: nameValue })
    setLoading(true)
  }

  useEffect(() => {
    if (filter?.search) {
      const filtered =
        questions?.filter((q) =>
          normalizeLikeParam(htmlToRaw(q.question_content))
            .toLowerCase()
            ?.includes(normalizeLikeParam(filter.search))
        ) || []
      setFilteredQuestions(filtered)
    } else {
      setFilteredQuestions(questions)
    }
  }, [questions, filter])
  return (
    <Spin size='large' spinning={loadPreview}>
      <div className='card card-bordered'>
        <div className='card-body'>
          <div
            className={`d-flex justify-content-between align-items-start gap-5 mb-5
          `}
          >
            <div className='d-flex'>
              
              <div className='me-5'>
                <HookFormTextField
                  control={control}
                  name={`search_name`}
                  placeholder='Câu hỏi'
                  className='sapp-h-45px w-200px bg-gray-100 sapp-search-custom lh-1'
                  preIcon={
                    <i
                      className={`ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y
                       ms-5
                      `}
                    ></i>
                  }
                />
              </div>
              <div className="me-5">
                <ButtonIcon
                  title='Search'
                  className='sapp-height-min--content w-auto '
                  onClick={onSubmit}
                >
                  <KTIcon iconName='' className='fs-2' />
                </ButtonIcon>
              </div>
              <div className='me-5'>
                <HookFormTextField
                  control={control}
                  name={`get_percent_questions_${defaultType}`}
                  placeholder='100%'
                  type='number'
                  className='sapp-h-45px w-200px sapp-input-percent-total fs-6 lh-1 sapp-table-class-field'
                  disabled={watchType !== TYPE_OF_TEST.mix.value ? true : false}
                  onChange={(e) => {
                    setValue(e.target.value)
                    setPercentQuestionsMulti(e.target.value)
                  }}
                  postFix={<div className='px-2'>%</div>}
                />
              </div>
            </div>
            <ButtonIcon
              title='Add Question'
              className='sapp-height-min--content w-auto '
              onClick={() => handleChooseQuestions()}
              disabled={
                (questions &&
                  questions.length > 0 &&
                  maxQuestionsNumber !== 'NO_MAX' &&
                  questions.length === maxQuestionsNumber) ||
                disabled
              }
            >
              <KTIcon iconName='plus' className='fs-2' />
            </ButtonIcon>
          </div>
          <table className='table align-middle fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
                <th className='w-40px' style={{lineHeight: '12px'}}>
                  #
                </th>
                {headers?.map((column) => (
                  <th key={column.label} className={`${column.className} fs-7 fw-bold`}>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(() => {
                if (!currentQuestions?.length) {
                  return (
                    <tr>
                      <td colSpan={7}>
                        <NotData />
                      </td>
                    </tr>
                  )
                }
                return currentQuestions?.map((question: any, i) => {
                  return (
                    <tr key={question.id}>
                      <td>{i + 1 + (filter.currentPage - 1) * filter.pageSize}</td>
                      <td>
                        <div className='sapp-text-truncate-1 text-wrap text-break'>
                          {htmlToRaw(question.question_content)}
                        </div>
                      </td>
                      <td>
                        <HookFormTextField
                          required
                          control={control}
                          name={`question_percent_${question.id}`}
                          placeholder={' '}
                          type='number'
                          defaultValue={question?.setting_grade}
                          className='w-100 sapp-input-percent-child fs-6 lh-1 sapp-table-class-field'
                          postFix={<div className='px-2'>%</div>}
                          disabled={defaultType === 'multi' ? true : false}
                        ></HookFormTextField>
                      </td>
                      <td className='w-50px'>
                        <div className='d-flex justify-content-between align-items-center gap-3'>
                          <ButtonIconOnly
                            iconName={'eye'}
                            activeColor='primary'
                            bg='gray-100'
                            onClick={async () => {
                              setLoadPreview(true)
                              const res = await QuestionBankAPI.getQuestionById(question?.id)
                              if (res.data.question_topic_id) {
                                const {data: topic} = await QuestionBankAPI.getTopicById(
                                  res.data.question_topic_id
                                )
                                setOpenPreview({
                                  value: true,
                                  data: {questions: res.data, topic: topic.description},
                                })
                              } else {
                                setOpenPreview({
                                  value: true,
                                  data: {questions: res.data, topic: ''},
                                })
                              }
                              setLoadPreview(false)
                              // if (!question?.question_topic?.id) {
                              //   toast.error('Question not found!')
                              //   return
                              // }
                              // const link = document.createElement('a')
                              // link.href = `${PageLink.TOPIC}/${question?.question_topic?.id}?questionId=${question.id}&questionQType=${question.qType}`
                              // link.target = '_blank'
                              // link.style.display = 'none'
                              // document.body.appendChild(link)
                              // link.click()
                              // document.body.removeChild(link)
                            }}
                          />
                          <ButtonIconOnly
                            bg='gray-100'
                            iconName={'trash'}
                            activeColor='danger'
                            disabled={disabled}
                            onClick={() => {
                              setTimeout(() => {
                                confirm({
                                  okButtonCaption: 'Yes',
                                  cancelButtonCaption: 'No',
                                  body: 'Bạn có chắc chắn muốn xóa không?',
                                  onClick: () => {
                                    const arr = [...filteredQuestions]
                                    const newArr = arr?.filter((f) => f.id !== question.id)
                                    if (newArr.length === 0 && filter.currentPage > 0) {
                                      handleChangePage(filter.currentPage - 1, filter.pageSize)
                                    } else setQuestions(newArr)
                                  },
                                })
                              })
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })
              })()}
            </tbody>
          </table>
          <PagiantionSAPP
            currentPage={filter.currentPage}
            handlePaginationChange={(page: number, pageSize: number) => {
              handleChangePage(page, pageSize)
            }}
            pageSize={filter.pageSize}
            totalItems={filteredQuestions.length}
          />
        </div>
        {/* <PreviewQuestion
        open={openPreview.value}
        onClose={handleClosePreview}
        data={openPreview.data}
      /> */}
        <QuestTionPreview
          data={openPreview?.data?.questions}
          type={openPreview?.data?.questions?.qType}
          topicDescription={openPreview?.data?.topic?.description}
          topic={openPreview?.data?.topic}
          handleClose={handleClosePreview}
          setOpenViewFile={setViewFile}
          open={openPreview.value}
        />
      </div>
      <ModalPreviewFile open={viewFile.status} setOpen={setViewFile} url={viewFile.url} />
    </Spin>
  )
}

export default TestQuestionTable
