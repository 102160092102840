import { StaffTable } from './components/table/StaffTable'
import { ListViewProvider } from './components/core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './components/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
} from './components/core/QueryResponseProvider'
import { useEffect, useMemo, useState } from 'react'
import { StaffListToolbar } from './components/header/StaffListToolbar'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import UserListGrouping from '../user-management/UserListGrouping'
import { StaffAPI } from 'src/apis/staffs'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { BUTTON_TEXT } from 'src/constants/lang'
import { RolesAPI } from 'src/apis/roles'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import {
  DEFAULT_SELECT_ALL,
  FILTER_SELECTALL_SORTBY,
  GENDER,
  PageLink,
  pageSizeOptions,
  STATUS,
} from 'src/constants'
import HookFormTextField from '../base/textfield/HookFormTextField'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import { Select } from 'antd'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import { replaceValueAll } from 'src/utils/string'
import useChecked from 'src/hooks/use-checked'
import { usersColumns } from './components/table/columns/_columns'
import { useTable } from 'react-table'
import { debounce } from 'lodash'
const { Option } = Select

const fieldNames = ['text', 'status', 'role', 'sortType', 'fromDate', 'toDate']
const initialValues: any = { role: '', text: '', status: '', sortType: '', fromDate: '', toDate: '' }

const StaffList = () => {
  const [openBlocked, setOpenBlocked] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { rows } = useTable({ columns, data })
  const [roles, setRoles] = useState<any>([])
  const { updateState } = useQueryRequest()
  const { refetch, isLoading } = useQueryResponse()
  const navigate = useNavigate()
  const rolesNew = roles?.map((role: any) => ({ label: role?.name, value: role?.code }))
  const rolesList = DEFAULT_SELECT_ALL.concat(rolesNew)

  const queryParams = {
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    textSearch: searchParams.get('text'),
    sortRole: searchParams.get('role'),
    sortStatus: searchParams.get('status'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    gender: searchParams.get('gender'),
  }

  const [currentPage, setCurrentPage] = useState(queryParams.page_index || 1)
  const [pageSize, setPageSize] = useState(queryParams.page_size || pageSizeOptions[0].value)

  const validationSchema = z.object({
    text: z.string().optional(),
    role: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    gender: z.any()
  })

  // Using validate for input
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const handleReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    navigate(PageLink.STAFFS)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      role: replaceValueAll(getValues('role')),
      status: replaceValueAll(getValues('status')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      gender: replaceValueAll(getValues('gender')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'));
    const dateInfoToDate = getDateInfo(getValues('toDate'));

    updateState({
      page_index: 1,
      text: getValues('text'),
      status: replaceValueAll(getValues('status')),
      sortType: replaceValueAll(getValues('sortType')),
      role_code: replaceValueAll(getValues('role')),
      fromDate: getValues('fromDate') ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day) : '',
      toDate: getValues('toDate') ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day) : '',
      dateField: 'updated_at',
      gender: replaceValueAll(getValues('gender'))
    })
    toggleCheckAll(false)
    handleChangeParams(1, queryParams.page_size || 10)
    setCurrentPage(1)
  }

  const handleResetAll = () => {
    handleReset()
    updateState({
      page_index: 1,
      page_size: 10,
      text: '',
      status: '',
      sortType: '',
      role_code: '',
      fromDate: null,
      toDate: null,
      dateField: '',
      gender: ''
    })
    toggleCheckAll(false)
    setCurrentPage(1)
    setPageSize(10)
  }

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    rows.map((row) => {
      return row.original
    })
  )

  const blockUser = useMutation(() => StaffAPI.blocked(checkedList), {
    onSuccess: () => {
      toggleCheckAll(false)
      toast.success('Block Successfully!')
      cancel(true)
      setCurrentPage(1)
      setOpenBlocked(false)
    },
  })

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) { }
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.meta?.total_pages
    const pageIndex = roles?.meta?.page_index as number
    const pageSize = roles?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const results = roles?.concat(res?.data?.roles)
      setRoles({
        meta: res?.data?.meta,
        roles: results,
      })
    }
  }

  const getRoles = async ({ params }: any) => {
    const res: any = await fetchRoles(1, 20, params)
    setRoles(res?.data?.roles)
  }

  const debounceSearchMentor = debounce((e) => {
    getRoles({ params: { name: e } })
  }, 500)

  return (
    <div className='card'>
      <div className='px-10 border-0 pt-10'>
        <div className='row'>
          <div className='col-xl-3 col-sm-4'>
            <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
              <HookFormTextField
                control={control}
                name='text'
                placeholder='Search'
                defaultValue={queryParams.textSearch ?? ''}
                onSubmit={onSubmit}
              />
            </div>
          </div>
          <div className='col-xl-3 col-sm-4'>
            <HookFormSelectAntd
              control={control}
              name='status'
              placeholder='Status'
              size='large'
              defaultValue={queryParams.sortStatus ?? ''}
              className='sapp-fs-select fs-6'
            >
              {STATUS.map((gender) => (
                <Option key={gender.value} value={gender.value}>
                  {gender.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>

          <div className='col-xl-3 col-sm-4'>
            <HookFormSelectAntd
              control={control}
              name='role'
              placeholder='Role'
              size='large'
              showSearch
              defaultValue={queryParams.sortRole ?? ''}
              className='sapp-fs-select fs-6'
              onFocus={async () => {
                if (!rolesNew || rolesNew?.length <= 0) {
                  await getRoles({})
                  return
                }
              }}
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchMentor(e)
              }}
              handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
            >
              {rolesNew && rolesList.map((gender) => (
                <Option key={gender.value} value={gender.value}>
                  {gender.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>

          <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
            <HookFormSelectAntd
              control={control}
              name='sortType'
              placeholder='Sort by'
              size='large'
              showSearch
              defaultValue={queryParams.sortType ?? ''}
              className='sapp-fs-select fs-6'
            >
              {FILTER_SELECTALL_SORTBY.map((gender) => (
                <Option key={gender.value} value={gender.value}>
                  {gender.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormSelectAntd
              control={control}
              name='gender'
              placeholder='Gender'
              size='large'
              defaultValue={queryParams.gender ?? ''}
              className='sapp-fs-select'
            >
              {GENDER.map((gender) => (
                <Option key={gender.value} value={gender.value}>
                  {gender.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams.fromDate ?? ''}
              className='sapp-h-datimepicker'
            />
          </div>
          <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams.toDate ?? ''}
              className='sapp-h-datimepicker'
            />
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 col-lg-9 col-xl-4 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit={BUTTON_TEXT.SEARCH}
                okClick={onSubmit}
                resetClick={handleResetAll}
                disabled={isLoading}
                loading={isLoading}
              />
            </div>

            {checkedList.length > 0 ? (
              <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
                <UserListGrouping
                  selected={checkedList}
                  blockUser={async () => await blockUser.mutateAsync()}
                  openBlocked={openBlocked}
                  setOpenBlocked={setOpenBlocked}
                />
              </div>
            ) : (
              <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3'>
                <StaffListToolbar
                  valueStatus={watch('status')}
                  valueSortBy={watch('sortType')}
                  searchTerm={watch('text')}
                  sortRole={watch('role')}
                  fromDate={watch('fromDate')}
                  toDate={watch('toDate')}
                  gender={watch('gender')}
                  location={location}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <StaffTable
        currentPage={currentPage}
        handleChangeParams={handleChangeParams}
        pageSize={pageSize}
        textSearch={queryParams.textSearch}
        sortRole={queryParams.sortRole}
        sortStatus={queryParams.sortStatus}
        sortType={queryParams.sortType}
        fromDate={queryParams.fromDate}
        toDate={queryParams.toDate}
        gender={queryParams.gender}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
      />
    </div>
  )
}

const StaffListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StaffList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { StaffListWrapper }
