/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs } from 'src/type'
import { useUserContext } from 'src/context/UserProvider'
import dashboard from "src/_metronic/assets/images/dashboard/dashboard.png";
import "./dashboard.scss";

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.dashboard,
  },
]

const Dashboard = () => {
  const { profileMe } = useUserContext()

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.dashboard} breadcrumbs={breadcrumbs} showFooter={false}>
      <div className='bg-white sapp-dashboard position-relative sapp-shadow card'>
        <div className='card sapp-dashboard justify-content-center'>
          <div className='d-flex justify-content-center sapp-text-coming'>
            <div className='sapp-text-gray'>
              Hi,</div>
            <div className='fw-bold sapp-text-gray-800 ms-2'>{profileMe?.detail?.full_name}</div>
            <div className='sapp-text-gray'>. Welcome!</div>
          </div>
          <div className='d-flex justify-content-center sapp-dashboard--body'>We are launching this site very soon. Please come back later.</div>
        </div>
        <img src={dashboard} className='position-absolute bottom-0 w-100'/>
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(Dashboard)
