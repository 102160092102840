export interface IButtonProps {
  title: string | undefined
  onClick?: any
  className?: string
  link?: string
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  bg?: 'danger' | 'primary' | 'secondary'
  disable?: boolean
  classNameTitle?: string
}

export interface ITabs {
  link: string
  title: string
}

export enum EEntranceLevel {
  IIA = 'IIA',
  IA = 'IA',
  IB = 'IB',
  IIB = 'IIB',
}

export enum EErrorCourse {
  CODE = 'code',
  COURSE_LEVELS = 'course_levels',
  NAME = 'name',
  CONNECTIONS = 'course_connections'
}

export interface IError {
  errors: Array<any>
  property: EUserManagement.EMAIL | EUserManagement.PHONE | EUserManagement.USERNAME
}

export interface IErrorCourse {
  errors: Array<any>
  property: EErrorCourse.CODE | EErrorCourse.COURSE_LEVELS | EErrorCourse.NAME | EErrorCourse.CONNECTIONS
}

export enum EUserManagement {
  EMAIL = 'email',
  PHONE = 'phone',
  USERNAME = 'username',
}

export interface IResponse<T> {
  status: number
  success: boolean
  data: T
}

export interface IMeta {
  total_pages: number
  total_records: number
  page_index: number
  page_size: number
}
export interface IResponseMeta {
  success: boolean
  data: {
    meta: IMeta
  }
}

export interface IForgotPassword {
  actor_id: string
  actor_type: 'STAFF'
  code: string
  created_at: Date
  deleted_at: Date
  duration: number
  expired_at: Date
  id: string
  status: string
  type: string
  updated_at: Date
}

export interface IResizeAvatar {
  '40x40'?: string | null
  '50x50'?: string| null
  '150x150'?: string| null
  '160x160'?: string| null
  ORIGIN?: string| null
}

export interface IResizeIdentityCard {
  '280x180'?: string | null
  '320x190'?: string| null
  '370x220'?: string| null
  ORIGIN?: string| null
}

export enum EStatusUser{
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED'
}
export interface Role {
  permissions: string[] | string;
  code: string;
}
