import {FC} from 'react'
import {User} from 'src/components/user-list/components/core/_models'
import {useListView} from 'src/components/user-list/components/core/ListViewProvider'
import {useQueryResponse} from 'src/components/user-list/components/core/QueryResponseProvider'
import {ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE, VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import avatar from 'src/_metronic/assets/media/avatars/300-1.jpg'
import { LANG_PLACEHOLDER } from 'src/constants/lang'

type Props = {
  isUserLoading: boolean
  user: User
}

interface IInputProps {
  fullname: string
  email: string
  type: string
}

const NewsEditModalForm: FC<Props> = ({user}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  // function cacel
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // validate for input
  const validationSchema = z.object({
    fullname: z.string({required_error: VALIDATION_FILED}).min(1),
    email: z.string({required_error: VALIDATION_FILED}).email(),
    type: z.string(),
    banner: z.any(),
  })

  const {handleSubmit, control, setValue, setError} = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const onSubmit = (data: IInputProps) => {
    console.log(data)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div className='d-flex flex-column scroll-y me-n7 pe-7'>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
            {/* end::Label */}

            {/* start:: image */}
            <SAPPHookUploadFile
              name='banner'
              control={control}
              setValue={setValue}
              setError={setError}
              imagePreview={avatar}
              accept={ACCEPT_UPLOAD_MIME_TYPE}
              maxFileSize={DEFAULT_MAX_FILE_SIZE}
              removeAvatar=''
            />
            {/* end:: image */}
          </div>
          {/* start:: textfield fullname */}
          <div className='fv-row mb-7'>
            <HookFormTextField
              name='fullname'
              control={control}
              placeholder={LANG_PLACEHOLDER.FULLNAME}
              label={LANG_PLACEHOLDER.FULLNAME}
            />
          </div>
          {/* end:: textfield fullname */}

          {/* start:: textfield email */}
          <div className='fv-row mb-7'>
            <HookFormTextField name='email' control={control} placeholder='Email' label='Email' />
          </div>
          {/* end:: textfield email */}

          {/* start:: radio type */}
          <HookFormRadioGroup
            name='type'
            control={control}
            options={[
              {
                label: 'Administrator',
                value: 'Administrator',
              },
              {
                label: 'Developer',
                value: 'Developer',
              },
              {
                label: 'Analyst',
                value: 'Analyst',
              },
              {
                label: 'Support',
                value: 'Support',
              },
              {
                label: 'Trial',
                value: 'Trial',
              },
            ]}
          />
          {/* end:: radio type */}
        </div>

        {/* start:: dialog button cancel submit */}
        <SAPPDialogButtonsCancelSubmit
          cancelButtonCaption='Discard'
          okButtonCaption='Submit'
          className='pt-15'
          okOnClick={handleSubmit(onSubmit)}
          cancelClick={() => cancel()}
        />
        {/* end:: dialog button cancel submit */}
      </form>
    </>
  )
}

export {NewsEditModalForm}
