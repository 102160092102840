import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import { DEFAULT_SELECT_ALL, FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_CLASSES, LANG_SIDEBAR } from 'src/constants/lang'
import { IMeta, ITabs, Role } from 'src/type'
import { z } from 'zod'
import TableClasses from './tableClasses'
import { replaceValueAll } from 'src/utils/string'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import useChecked from 'src/hooks/use-checked'
import { CoursesAPI } from 'src/apis/courses'
import { ICourseCategoies } from 'src/type/courses'
import { ClassesApi } from 'src/apis/classes'
import {
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_STATUS_FILTER,
  OPTIONS_OF_TYPE_CLASS_LIST,
  OPTIONS_SUBJECTS,
} from 'src/constants/classes'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import ModalImportClasses from 'src/components/classes/ModalImportClasses'
import { TITLE_OPTIONS_CLASS } from 'src/constants/classes'
import moment from 'moment'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { debounce } from 'lodash'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: TITLE_OPTIONS_CLASS.listClasses,
  },
]
const fieldNames = [
  'class_name',
  'class_code',
  'course_category_id',
  'course_level_id',
  'area_id',
  'status',
  'class_type',
  'subject',
]

const initialValues: any = {
  class_name: '',
  class_code: '',
  course_category_id: '',
  course_level_id: '',
  area_id: '',
  status: '',
  class_type: '',
  subject: '',
}

interface IClassCode {
  meta: IMeta
  class_codes: Array<{code: string, id: string}>
}

const Classes = () => {
  const { profileMe } = useUserContext()
  const allowRenderImportClass = profileMe?.roles?.some((role: Role)  => role.permissions?.includes(TITLE_CLASS_GR.IMPORT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const allowRenderCreateClass = profileMe?.roles?.some((role: Role)  => role.permissions?.includes(TITLE_CLASS_GR.CREATE_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const [classList, setClassList] = useState<any>()
  const [openImportClass, setOpenImportClass] = useState<boolean>(false)
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [areaClasses, setAreaClasses] = useState<any[]>([])
  // state để dùng class code
  const [classCodes, setClassCodes] = useState<IClassCode>()
  const [showCode, setShowCode] = useState(false)

  const searchParams = new URLSearchParams(search)
  const queryParams = {
    class_name: searchParams.get('class_name') ?? '',
    class_code: searchParams.get('class_code') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    status: searchParams.get('status') as string,
    area_id: searchParams.get('area_id'),
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    instruction_mode: searchParams.get('instruction_mode') ?? '',
    class_type: searchParams.get('class_type') ?? '',
    subject: searchParams.get('subject') ?? '',
  }
  const validationSchema = z.object({
    class_name: z.string().optional(),
    course_category_id: z.string().optional(),
    subject: z.string().optional(),
    area_id: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    instruction_mode: z.string().optional(),
    class_type: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    class_code: z.string().optional()
  })
  const { control, getValues, reset, setValue, watch, setError } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      class_name: getValues('class_name'),
      instruction_mode: replaceValueAll(getValues('instruction_mode')),
      class_type: replaceValueAll(getValues('class_type')),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      subject: replaceValueAll(getValues('subject')),
      area_id: replaceValueAll(getValues('area_id')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      status: replaceValueAll(getValues('status')),
      class_code: getValues('class_code'),
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    class_name: queryParams.class_name.trimStart().trimEnd() ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    area_id: queryParams.area_id ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
    status: queryParams.status,
    instruction_mode: queryParams.instruction_mode,
    class_type: queryParams.class_type,
    subject: queryParams.subject,
    class_code: queryParams.class_code,
  })
  const getParams = (
    class_name: string,
    class_code: string,
    course_category_id: string,
    area_id: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    status?: string,
    instruction_mode?: string,
    class_type?: string,
    subject?: string,
    dateField?: string
  ) => ({
    class_name,
    class_code,
    course_category_id,
    area_id,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    status,
    instruction_mode,
    class_type,
    subject,
    dateField,
  })

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    classList?.classes
  )

  const fetchClassList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await ClassesApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setClassList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('class_name').trimStart().trimEnd() ?? '',
        getValues('class_code'),
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('area_id')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('instruction_mode')),
        replaceValueAll(getValues('class_type')),
        replaceValueAll(getValues('subject')),
        'updated_at'
      )
    )

    setLoading(true)

    fetchClassList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
    setShowCode(true)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    navigate(PageLink.CLASSES)

    fetchClassList(1, 10)
  }
  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 100 })
        setCourseCategory(res?.data)
      } catch (error) { }
    }

    const fetchAreaClasses = async ({ page_index, page_size }: any) => {
      try {
        const res = await ClassesApi.getAreaClasses({ page_index: page_index, page_size: page_size })
        setAreaClasses(res?.data?.areas)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCourseCategory()
    fetchAreaClasses({ page_index: 1, page_size: 100 })
  }, [])
  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )

  const newAreaClasses = useMemo(() => {
    if (areaClasses.length > 0)
      return areaClasses?.map((level) => ({ label: level.name, value: level.id }))
    else return []
  }, [areaClasses])

  useEffect(() => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')

    if (fromDate && toDate && moment(toDate).isBefore(fromDate)) {
      setValue('toDate', moment(fromDate).endOf('day').toDate())
    }

    if (toDate && fromDate && moment(fromDate).isAfter(toDate)) {
      setValue('fromDate', moment(toDate).startOf('day').toDate())
    }
  }, [watch('fromDate'), watch('toDate')])

  const disabledFromDate = (current: any) => {
    const toDate = watch('toDate');
    if (toDate) {
      return current > moment(toDate).endOf('day');
    }
    return false;
  };

  const disabledToDate = (toDate: any) => {
    const fromDate = watch('fromDate')
    if (fromDate) {
      return toDate < moment(fromDate).startOf('day')
    }
    return false
  }

  // goi API class code
  const fetchClassCodes = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await ClassesApi.getCodes(page_index, page_size, params)
      return res
    } catch (error) { }
  }

  // gọi tiếp codes nếu kéo hết scroll
  const handlNextPageCodes = async (params: Object) => {
    const totalPages = classCodes?.meta?.total_pages
    const pageIndex = classCodes?.meta?.page_index as number
    const pageSize = classCodes?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchClassCodes(pageIndex + 1, pageSize, params)
      const results = classCodes.class_codes.concat(res?.data?.class_codes)
      setClassCodes({
        meta: res?.data?.meta,
        class_codes: results,
      })
    }
  }

  const getCodes = async ({ params }: any) => {
    const resMentor = await fetchClassCodes(1, 20, params)
    setClassCodes(resMentor?.data)
  }

  // call search codes
  const debounceSearchCodes = debounce((e) => {
    getCodes({ params: { search_key: e } })
  }, 500)

  const newCourseCodes = classCodes?.class_codes?.map((group: {code: string, id: string}) => ({ label: group.code, value: group.id }))

  // gọi lại API show full class code
  useEffect(() => {
    if (queryParams.class_code && !showCode) {
      getCodes({ is_get_all: true })
    }
  }, [queryParams.class_code])

  return (
    <PageLayouts pageTitle={TITLE_OPTIONS_CLASS.listClasses} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='card-header border-0 pt-10'>
          <div className=''>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4'>
                <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                  <HookFormTextField
                    control={control}
                    name='class_name'
                    placeholder='Search by name and code'
                    defaultValue={queryParams?.class_name}
                    onSubmit={onSubmit}
                  />
                </div>
              </div>
              {/* end:: Search */}

              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='class_code'
                  placeholder='Code'
                  defaultValue={queryParams.class_code ?? ''}
                  showSearch
                  onFocus={async () => {
                    if (!newCourseCodes) {
                      await getCodes({})
                      return
                    }
                  }}
                  onSearch={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    debounceSearchCodes(e)
                  }}
                  handleNextPage={(e: any) => handlNextPageCodes({ params: { search_key: e } })}
                >
                  {newCourseCodes && newCourseCodes?.map(
                    (course_category_ids: {label: string, value: string}) => (
                      <Option key={course_category_ids.value} value={course_category_ids.label}>
                        {course_category_ids.label}
                      </Option>
                    )
                  )}
                </HookFormSelectAntd>
              </div>


              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='course_category_id'
                  placeholder='Program'
                  defaultValue={queryParams.course_category_id ?? ''}
                  showSearch
                >
                  {DEFAULT_SELECT_ALL?.concat(newCourseCategory ?? ([] as Array<any>)).map(
                    (course_category_ids) => (
                      <Option key={course_category_ids.value} value={course_category_ids.value}>
                        {course_category_ids.label}
                      </Option>
                    )
                  )}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='subject'
                  placeholder='Subject'
                  defaultValue={queryParams.subject ?? ''}
                  showSearch
                >
                  {DEFAULT_SELECT_ALL?.concat(OPTIONS_SUBJECTS ?? ([] as Array<any>)).map(
                    (course_category_ids) => (
                      <Option key={course_category_ids.value} value={course_category_ids.value}>
                        {course_category_ids.label}
                      </Option>
                    )
                  )}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='status'
                  placeholder='Status'
                  defaultValue={queryParams.status ?? ''}
                >
                  {DEFAULT_SELECT_ALL?.concat(OPTIONS_OF_STATUS_FILTER)?.map((level) => (
                    <Option key={level.label} value={level.value}>
                      {level.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='area_id'
                  placeholder='Area'
                  defaultValue={queryParams.area_id ?? ''}
                >
                  {DEFAULT_SELECT_ALL?.concat(newAreaClasses ?? ([] as Array<any>)).map((area) => (
                    <Option key={area.label} value={area.value}>
                      {area.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='instruction_mode'
                  placeholder='Construction Mode'
                  defaultValue={queryParams.instruction_mode ?? ''}
                >
                  {DEFAULT_SELECT_ALL?.concat(OPTIONS_OF_CONTRUCTION_MODE)?.map((area) => (
                    <Option key={area.label} value={area.value}>
                      {area.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='class_type'
                  placeholder='Type'
                  defaultValue={queryParams.class_type ?? ''}
                >
                  {DEFAULT_SELECT_ALL?.concat(OPTIONS_OF_TYPE_CLASS_LIST)?.map((type) => (
                    <Option key={type.label} value={type.value}>
                      {type.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort by'
                  control={control}
                  size='large'
                  defaultValue={queryParams?.sortType ?? ''}
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  className='sapp-h-datimepicker'
                  disabledDate={disabledFromDate}
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  className='sapp-h-datimepicker'
                  disabledDate={disabledToDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-xl-4 col-lg-6 px-xl-3 px-md-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  titleSubmit='Search'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  disabled={false}
                />
              </div>
              <div className='col-sm-6 col-xl-8 col-lg-6 px-xl-3 px-md-0 px-0 pe-xl-0'>
                <div className='card-toolbar justify-content-end d-flex my-0 gap-5'>
                  {allowRenderImportClass && (
                    <ButtonIcon
                      title={LANG_CLASSES.importClass}
                      className='btn btn-sapp-filter'
                      onClick={() => {
                        setOpenImportClass(true)
                      }}
                    >
                      <KTIcon iconName='file-up' className='fs-3 pe-3' iconType='outline' />
                    </ButtonIcon>
                  )}
                  {/* start:: button open modal */}
                  {allowRenderCreateClass && (
                    <ButtonIcon
                      title={LANG_CLASSES.addClass}
                      className='sapp-height-min--content'
                      link={`${PageLink.CREATE_CLASSES}`}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                    </ButtonIcon>
                  )}
                  {/* end:: button open modal */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableClasses
          classList={classList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchClassList={fetchClassList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        <ModalImportClasses
          open={openImportClass}
          setOpen={setOpenImportClass}
        ></ModalImportClasses>
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(Classes)
