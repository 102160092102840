import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {LANG_COURSES} from 'src/constants/lang'
import {ETypeFoundation, ICourseCategoies, ICourseList} from 'src/type/courses'
import LoadingTable from 'src/common/LoadingTable'
import {CoursesAPI} from 'src/apis/courses'
import {
  COURSE_TYPES_CLASS,
  DEFAULT_SELECT_ALL,
  FILTER_SELECTALL_SORTBY,
} from 'src/constants'
import {useForm} from 'react-hook-form'
import {Select} from 'antd'
import {cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo} from 'src/utils'
import SappTable from 'src/components/base/SappTable'
import CourseType from 'src/components/courses/CourseType'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {replaceValueAll} from 'src/utils/string'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'

const {Option} = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  selectCourse: any
  courseList: ICourseList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchCourseList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  typeCourse: string
}

// define headers
const headers = [
  {
    label: LANG_COURSES.code,
    className: 'min-w-150px',
  },
  {
    label: LANG_COURSES.courseName,
    className: 'min-w-500px',
  },
  {
    label: 'Category',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Type',
    className: 'min-w-100px pe-5',
  },
]

const TableCourses = ({
  selectCourse,
  courseList,
  loading,
  setLoading,
  fetchCourseList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  typeCourse,
}: IProps) => {
  const initialValues: any = {
    course_category_ids: '',
    name: '',
    status: ['PUBLISH', 'LOCK'],
    sortType: '',
    fromDate: '',
    toDate: '',
  }
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const validationSchema = z.object({
    name: z.string().optional(),
    course_category_ids: z.string().optional(),
    status: z.any().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })
  const fieldNames = [
    'course_category_ids',
    'name',
    'status',
    'sortType',
    'fromDate',
    'toDate',
  ]

  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const cleanedParams = {
          status: ['PUBLISH', 'LOCK'],
        }
        const res = await CoursesAPI.getCategory({
          page_index: 1,
          page_size: 100,
          params: cleanedParams,
        })
        setCourseCategory(res?.data)
      } catch (error) {}
    }
    fetchCourseCategory()
  }, [])
  // Using validate for input
  const {
    control: controlStatus,
    setValue: setValueStatus,
  } = useForm<any>({
    mode: 'onChange',
  })
  const {control, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  useEffect(() => {
    courseList?.courses
      ?.map((course) => ({label: course.status.toLocaleLowerCase(), value: course.status}))
      ?.forEach((option, index) => {
        setValueStatus(`status${index}`, option.value)
      })
  }, [setValueStatus, courseList?.courses])
  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    getListCourse({page_index, page_size})
    // setPage_index(page_index)
    // setPage_size(page_size)
  }
  const getListCourse = ({page_index, page_size}: {page_index: number; page_size: number}) => {
    const dateInfoFromDate = searchParams.fromDate
    const dateInfoToDate = searchParams.toDate

    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams.name,
        searchParams.course_category_ids,
        searchParams.status,
        typeCourse === 'TRIAL' ? [ETypeFoundation.TRIAL] : typeCourse === 'LESSON' ? [ETypeFoundation.NORMAL] : [ETypeFoundation.PRACTICE],
        searchParams.sortType,
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    fetchCourseList(page_index || 1, page_size || 10, cleanedParams)
  }
  useEffect(() => {
    setLoading(true)
    getListCourse({page_index: 1, page_size: 10})
  }, [])
  const newCourseCategory = courseCategory?.course_categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }))
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    const cleanedParams = {
      status: ['PUBLISH', 'LOCK'],
      course_types: typeCourse === 'TRIAL' ? [ETypeFoundation.TRIAL] : typeCourse === 'LESSON' ? [ETypeFoundation.NORMAL] : [ETypeFoundation.PRACTICE],
    }
    setLoading(true)
    setSearchParams(initialValues)
    // setDateField('')
    fetchCourseList(1, 10, cleanedParams)
  }
  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))    
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_ids')),
        replaceValueAll(getValues('status')),
        typeCourse === 'TRIAL' ? [ETypeFoundation.TRIAL] : typeCourse === 'LESSON' ? [ETypeFoundation.NORMAL] : [ETypeFoundation.PRACTICE],
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setLoading(true)
    setSearchParams(cleanedParams)
    fetchCourseList(1, queryParams.page_size || 10, cleanedParams)
  }

  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='name'
                  placeholder='Search'
                  defaultValue={queryParams?.name}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}

            <div className='col-xl-3 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='course_category_ids'
                placeholder='Category'
                defaultValue={queryParams.course_category_ids ?? ''}
                showSearch
              >
                {DEFAULT_SELECT_ALL?.concat(newCourseCategory ?? ([] as Array<any>)).map(
                  (category) => (
                    <Option key={category.value} value={category.value}>
                      {category.label}
                    </Option>
                  )
                )}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 d-none'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='status'
                placeholder='Status'
                defaultValue={['PUBLISH', 'LOCK']}
              >
                <Option key={'Publish'} value={['PUBLISH', 'LOCK']}>
                  Publish
                </Option>
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 d-none'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='course_types'
                placeholder='Type'
                defaultValue={queryParams.course_types[0] ?? ''}
              >
                {DEFAULT_SELECT_ALL.concat(COURSE_TYPES_CLASS).map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>

            <div className='col-xl-3 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-3 '>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
                className='sapp-h-datimepicker'
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-3 '>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
                className='sapp-h-datimepicker'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={courseList?.courses}
        isCheckedAll={false}
        hasCheckAll={false}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {Array(5).map((header, i) => (
              <LoadingTable key={header} headers={Array(5)} />
            ))}
          </>
        ) : (
          <>
            {courseList?.courses?.map((course, index) => {
              const isChecked = selectCourse[0]?.id.includes(course.id)

              return (
                <tr key={course.id}>
                  <td>
                    <SAPPRadio
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(course)
                      }}
                    />
                  </td>
                  <td className='text-start'>{course?.code ?? '-'}</td>
                  <td className='text-start sapp-text-primary fs-6 fw-semibold'>{course?.name}</td>
                  <td className='text-start'>{course?.course_categories?.[0]?.name ?? '-'}</td>
                  <td className='text-start'>
                    <CourseType type={course.course_type} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={courseList?.metadata?.page_index || 1}
        pageSize={courseList?.metadata?.page_size || 10}
        totalItems={courseList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableCourses
