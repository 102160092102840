import React, {useEffect} from 'react'
import './Tab.scss'
import TabContent from './tab/tabContent'
interface IProps {
  data: {
    tabId: string
    value?: any
    created?: boolean
    edit?: boolean
    creating?: boolean
  }[]
  setTabActive: React.Dispatch<React.SetStateAction<string>>
  tabActive: string
  setData: any
  handleAddTab: any
  closeModal: any
  course_section_id: string
  edit?: boolean
  onlyEdit?:boolean
}
const Tab = ({
  data,
  setTabActive,
  tabActive,
  setData,
  handleAddTab,
  closeModal,
  course_section_id,
  edit,
  onlyEdit
}: IProps) => {
  useEffect(() => {
    if (!edit) {
      handleAddTab()
    } else {
      setTabActive(data[0]?.tabId)
    }
  }, [edit])
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        {data.map((e, i) => {
          return (
            <TabContent
              closeModal={closeModal}
              course_section_id={course_section_id}
              data={data}
              e={e}
              i={i}
              setData={setData}
              setTabActive={setTabActive}
              tabActive={tabActive}
              key={e.tabId}
              onlyEdit={onlyEdit}
            />
          )
        })}
      </div>
    </>
  )
}
export default Tab
