import { useQuery } from 'react-query'
import { QUERIES, isNotEmpty } from 'src/_metronic/helpers'
import { useListView } from 'src/components/user-list/components/core/ListViewProvider'
import { NewsEditModalForm } from './NewsEditModalForm'
import { getUserById } from 'src/components/notifications/components/core/_requests'

const NewsEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <NewsEditModalForm isUserLoading={isLoading} user={{ id: undefined }} />
  }

  if (!isLoading && !error && user) {
    return <NewsEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export { NewsEditModalFormWrapper }
