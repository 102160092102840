import {Select} from 'antd'
import {Controller, UseFormReturn} from 'react-hook-form'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import {BUTTON_TEXT} from 'src/constants/lang'
import Search from '../search'
import HookFormSelectAntd from '../select/HookFormSelectAntd'
import {useState} from 'react'
import HookFormTextField from '../textfield/HookFormTextField'

type Props = {
  form: UseFormReturn<any>
  getImportLogs: ({
    text,
    status,
    page_index,
    page_size,
  }: {
    text?: string | undefined
    status?: string | undefined
    page_index: number
    page_size: number
  }) => Promise<void>
  meta?: {
    total_pages: number
    total_records: number
    page_index: number
    page_size: number
  }
  textSearch: string
  status: string
}

export enum JOB_STATUS {
  'PROCESSING' = 'PROCESSING',
  'FAILED' = 'FAILED',
  'SUCCESSFUL' = 'SUCCESSFUL',
  'PENDING' = 'PENDING',
}

const jobStatus = [
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Successful',
    value: 'SUCCESSFUL',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
]

const FilterHeader = ({form, meta, getImportLogs, status, textSearch}: Props) => {
  const {control, getValues, reset, setValue} = form
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    setTimeout(() => {
      getImportLogs({
        page_index: 1,
        page_size: meta?.page_size ?? 10,
        text: getValues('text'),
        status: getValues('status'),
      }).finally(() => setLoading(false))
    })
  }

  const onReset = () => {
    setLoading(true)
    setTimeout(() => {
      reset()
      setValue('text', '')
      setValue('status', '')

      getImportLogs({
        page_index: 1,
        page_size: 10,
        text: '',
        status: '',
      }).finally(() => setLoading(false))
    })
  }

  return (
    <>
      <div className='px-10 pt-10 w-100'>
        <div className='row'>
          <div className='col-xl-3 col-sm-4 sapp-height-min--content'>
            <div className='card-title justify-content-center mb-0'>
              <HookFormTextField
                name='text'
                control={control}
                defaultValue={textSearch ?? ''}
                onSubmit={onSubmit}
                placeholder='Search'
                preIcon={
                  <i
                    className={`ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ${'ms-5'}`}
                  ></i>
                }
              />
            </div>
          </div>
          <div className='col-xl-3 col-sm-4 sapp-height-min--content'>
            <HookFormSelectAntd
              size='large'
              name={'status'}
              control={control}
              dropdownStyle={{minWidth: 'fit-content'}}
              placeholder={'Status'}
              showSearch
              defaultValue={status ?? ''}
            >
              {jobStatus.map((option) => {
                return (
                  <Select.Option
                    defaultValue={option.value}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </Select.Option>
                )
              })}
            </HookFormSelectAntd>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 col-lg-9 col-xl-4 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit={BUTTON_TEXT.SEARCH}
                okClick={onSubmit}
                resetClick={onReset}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterHeader
