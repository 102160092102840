import {useParams} from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import {TITLE_OPTIONS_ENTRANCE} from 'src/constants/entrancetest'
import PageLayoutsEntranceTest from 'src/components/layout/EntranceTestLayout'
import EditTestInfoComp from './CreateTestInfoComp'


const CreateTestInfo = () => {
  const params = useParams()
  return (
    <PageLayoutsEntranceTest
      pageTitle={TITLE_OPTIONS_ENTRANCE.createEntranceTest}
      breadcrumbs={[]}
      showSideBar={false}
      removePadding={true}
      showFooter={false}
      backgroudColor='custom-background-color'
    >
      <div className=''>
          <div className=' bg-white'>
            <EditTestInfoComp
              ids={params.id || ''}
              editId={params.editId}
            ></EditTestInfoComp>
          </div>
      </div>
    </PageLayoutsEntranceTest>
  )
}

export default withAuthComponents(CreateTestInfo)
