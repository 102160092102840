import {Dispatch, SetStateAction, useEffect} from 'react'
import {useQueryResponse} from 'src/components/user-list/components/core/QueryResponseProvider'
import {KTCardBody} from 'src/_metronic/helpers'
import LoadingTable from 'src/common/LoadingTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import {DENIED_PERMISSIONS, MOCKUP_HEADER, STATUS_FORM, STUDENT_PROFILE} from 'src/constants'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import CodeUser from 'src/components/user-management/CodeUser'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import UserCell from 'src/components/user-management/UserCell'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import {useForm} from 'react-hook-form'
import {useConfirm} from 'src/hooks/use-confirm'
import {toast} from 'react-hot-toast'
import {UsersAPI} from 'src/apis/user'
import {EStatusUser} from 'src/type'
import {Select} from 'antd'
import {UserActionsCell} from './columns/UserActionsCell'
import {useLocation} from 'react-router-dom'
const {Option} = Select
interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  studentList: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchStudentList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

const UsersTable = ({
  studentList,
  loading,
  setLoading,
  filterParams,
  fetchStudentList,
  handleChangeParams,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  const {isLoading, refetch} = useQueryResponse()
  const {confirm, contextHolder} = useConfirm()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)

  // TODO: reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  useEffect(() => {
    fetchStudentList(page_index || 1, page_size || 10, filterParams)
  }, [])

  // Using validate for input
  const {control, watch, setValue} = useForm<any>({
    mode: 'onChange',
  })

  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateUserStatus({id, status})
      .then(() => toast.success('Update Successfully!'))
      .catch((error) => {
        if (error?.response?.data?.error?.code === '403|000000') {
          toast.error(DENIED_PERMISSIONS);
          fetchStudentList(page_index || 1, page_size || 10, filterParams)
        }
      });
  };

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }

  useEffect(() => {
    studentList?.users
      ?.map((staff: any) => ({
        label: staff?.status?.toLocaleLowerCase(),
        value: staff?.status,
      }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, studentList?.users])

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchStudentList(page_index || 1, page_size || 10, filterParams)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }
  return (
    <KTCardBody className='py-4'>
      {contextHolder}
      <SappTable
        headers={[
          {label: 'code'},
          {label: 'user'},
          {label: 'username'},
          {label: 'phone'},
          {label: 'status'},
          {label: 'date'},
        ]}
        loading={isLoading}
        data={Array.isArray(studentList?.users) ? studentList.users : []}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll)
        }}
      >
        {isLoading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {studentList?.users?.map((user: any, index: any) => {
              const isChecked = checkedList.includes(user?.id)

              return (
                <tr key={user?.id ?? index}>
                  <td className='min-w-50px'>
                    <SAPPCheckbox
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(user?.id)
                      }}
                    />
                  </td>
                  <td className='min-w-125px'>
                    <CodeUser code={user?.key} />
                  </td>
                  <td className='min-w-275px'>
                    <UserInfoCell
                      user={user}
                      linkViewProfile={`${STUDENT_PROFILE}/${user?.id}/overview`}
                    />
                  </td>
                  <td className='min-w-150px'>
                    <UserCell data={user?.username} />
                  </td>
                  <td className='min-w-175px'>
                    <UserCell data={user?.user_contacts?.[0]?.phone} />
                  </td>
                  <td className='min-w-175px'>
                    <HookFormSelectAntd
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{maxWidth: 200}}
                      placeholder='Status'
                      filterOption={true}
                      onChange={(selectedValue: unknown) =>
                        handleChange(`status${index}`, selectedValue as string, user?.id)
                      }
                      disabled={user?.status === 'MERGED'}
                    >
                      {STATUS_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='min-w-250px'>
                    <DatetimeColumn updated_at={user?.updated_at} created_at={user?.created_at} />
                  </td>
                  <td className='text-center sapp-absolute-column'>
                    <UserActionsCell id={user?.id} status={user?.status} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={studentList?.metadata?.page_index || 1}
        pageSize={studentList?.metadata?.page_size || 10}
        totalItems={studentList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export {UsersTable}
