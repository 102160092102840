import { Dispatch, SetStateAction } from 'react'
import {IMetadata} from './question-bank'

export interface ICategories {
  code: string
  created_at: Date
  deleted_at: Date
  description: string
  id: string
  name: string
  updated_at: Date
}

export interface ICourse {
  code: string
  course_categories: ICategories[]
  course_difficulty: number
  course_image: string | null
  course_type: 'NORMAL_COURSE' | 'TRIAL_COURSE' | 'FOUNDATION_COURSE' | 'PRACTICE_COURSE'
  created_at: Date
  created_by: any
  created_from: any
  deleted_at: Date
  description: string
  duration: number
  id: string
  name: string
  pass_point: number
  published_at: Date
  status: 'DRAFT' | 'PUBLISH' | 'LOCK'
  template: number
  updated_at: Date
  setting: {
    activity_points: number
    final_test_points: number
    mid_term_points: number
  }
}

export interface ICourseList {
  courses: ICourse[]
  metadata: IMetadata | undefined
}

export interface ICourseCategory {
  code: string
  created_at: Date
  deleted_at: Date
  description: string
  id: string
  name: string
  updated_at: Date
}
export interface ICourseCategoies {
  course_categories: ICourseCategory[]
  metadata: IMetadata
}
export interface ICoursePart {
  name?: string
  code?: string
  description?: string
  course_id?: string
  parent_id?: string
  position?: number
}
export interface ICourseSession {
  code?: string
  name?: string
  description?: string
  outcomes?: string[]
  course_section_id?: string
  position?: number
}
export interface ICourseLearnOutCome {
  id?: string
  name?: string
  description?: string
  outcomes?: {uuid?: string; id?: string; description: string}[]
  course_section_id?: string
  remove_outcome_ids?: (string | undefined)[]
}
export interface ICourseLearnOutComeUpdate {
  name?: string
  description?: string
  outcomes?: string[]
  course_section?: string
  id: string
}

export interface ICourseInfo {
  course_info: File
  code: string
  name: string
  course_category_ids: string
  duration: number
  course_type: string
  levelIA: string
  levelIIA: string
  levelIB: string
  levelIIB: string
  connectIA: string
  connectIIA: string
  connectIB: string
  connectIIB: string
  activity_points: number
  mid_term_points: number
  final_test_points: number
  pass_point: number
  description: string
  total_point?: number
  course_difficulty: number
  topic_points: number
  status: any
  course_tags: Array<string>
  progress: number
  chapter_points: number
  course_image: any
  course_connect_ia: boolean
  course_connect_ib: boolean
  course_connect_iia: boolean
  course_connect_iib: boolean
}

export interface ICourseLevel {
  code: string
  description: string
  id: string
  name: string
}

export enum ECourseLevel {
  IA = 'IA',
  IB = 'IB',
  IIA = 'IIA',
  IIB = 'IIB',
}

export interface ICourseTags {
  created_at: Date
  deleted_at: Date | null
  id: string
  name: string
  updated_at: Date
}
export interface ICourseActivity {
  name?: string
  code?: string
  description?: string
  parent_id?: string
  course_learning_outcome_id?: string
  course_outcome_ids?: string[]
  duration?: number
  is_graded?: boolean
  score?: number
  resource_ids?: []
  display_icon: string
  course_id:string
}
export interface ICourseActivityEdit {
  id: string
  data: ICourseActivity
}
export interface ICourseTab {
  id?: string
  course_section_id: string
  tab_name: string,
  course_id?: string
}

export enum ETypeFoundation {
  FOUNDATION = 'FOUNDATION_COURSE',
  TRIAL = 'TRIAL_COURSE',
  PRACTICE = 'PRACTICE_COURSE',
  NORMAL = 'NORMAL_COURSE',
}

export enum EStatusCourse {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISH',
  LOCK = 'LOCK',
  BLOCK = 'BLOCK',
}

export interface IChapterChildrenProps {
  activity_type: string
  code: string
  course_id: string
  course_learning_outcome: any
  course_outcomes: Array<any>
  course_section_type: any
  created_at: Date
  deleted_at: Date
  description: string
  display_icon: string
  duration: number
  id: string
  is_graded: boolean
  is_peer_review: boolean
  is_public: boolean
  name: string
  parent: string
  parent_id: string
  position: string
  status: string
  updated_at: Date
}

export interface IChapterProps {
  children: IChapterChildrenProps[]
  activity_type: string
  code: string
  course_id: string
  course_learning_outcome: any
  course_outcomes: Array<any>
  course_section_type: any
  created_at: Date
  deleted_at: Date
  description: string
  display_icon: string
  duration: number
  id: string
  is_graded: boolean
  is_peer_review: boolean
  is_public: boolean
  name: string
  parent: string
  parent_id: string
  position: string
  status: string
  updated_at: Date
}

export interface ICourseSection {
  course_id: string
  course_section_type: 'PART' | 'CHAPTER' | 'UNIT' | 'ACTIVITY'
  created_at: any
  id: string
  name: string
  updated_at: Date
}

export interface ICourseSectionList {
  sections: ICourseSection[]
  meta: IMetadata
}

export interface ICourseLink {
  open: boolean
  setOpen: Dispatch<SetStateAction<any>>
  position: number
  body: string
  title: string
  parentId?: string | undefined
}

export interface IInputCourseLink {
  courseId: string
  course_section: Array<{course_section_ids: boolean}>
  partId?: string
  chapterId?: string
  unitId?: string
}
