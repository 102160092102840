import { PageLink } from 'src/constants'
import { UserExportHelper } from 'src/helper/export'
import { useEffect, useState } from 'react'
import ModalImportStaff from '../ModalImportStaff'
import SAPPActionButton from 'src/common/SAPPActionButton'

interface IProps {
  valueStatus: string | null
  valueSortBy: string | null
  searchTerm: string
  sortRole: string | null
  fromDate: Date | null
  toDate: Date | null
  gender: string | null
  location?: any
}

const StaffListToolbar = ({ searchTerm, valueSortBy, valueStatus, sortRole, fromDate, toDate, gender, location }: IProps) => {
  const [openUpload, setOpenUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLocation, setIsLocation] = useState(false)
  useEffect(() => {
    setIsLocation(location.pathname === PageLink.STAFFS);
  }, [location.pathname]);
  const exportStaff = async () => {
    setLoading(true)
    try {
      await UserExportHelper.exportToXlsxStaff(valueStatus, valueSortBy, searchTerm, sortRole, fromDate, toDate, gender)
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SAPPActionButton
        link={PageLink.CREATE_STAFF}
        onClick={exportStaff}
        setOpen={setOpenUpload}
        titleAdd='Create'
        loading={loading}
        location={isLocation}
      />
      <ModalImportStaff open={openUpload} setOpen={setOpenUpload} />
    </>
  )
}

export { StaffListToolbar }
