import PageLayouts from 'src/components/layout/PageLayouts'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {z} from 'zod'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import avatar from 'src/_metronic/assets/media/avatars/300-1.jpg'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  PageLink,
  VALIDATION_FILED,
} from 'src/constants'
import {ITabs} from 'src/type'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {KTIcon} from 'src/_metronic/helpers'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelect from 'src/components/base/select/HookFormSelect'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import withAuthComponents from 'src/components/auth/with-auth-components'
import {LANG_DASHBOARD, LANG_NEWS, LANG_SIDEBAR} from 'src/constants/lang'
import {useEffect, useState} from 'react'
import LoadingCreateNews from './loading/LoadingCreateNews'

interface IInputProps {
  banner: File | undefined | string
  type: string
  title: string
  excerpt: string
  urlPDF: string
  description: string
}

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.createNews,
  },
]

const CreateNews = () => {
  const [loading, setLoading] = useState(false)

  // validate for input
  const schema = z
    .object({
      banner: z.any(),
      product_name: z.string().min(1),
      description: z.string().optional(),
      price: z.string().min(1),
      tax: z.string(),
      amount: z.string(),
      status: z.string(),
      categories: z.string(),
      template: z.string(),
    })
    .refine((val) => val.banner, {message: VALIDATION_FILED, path: ['banner']})

  const {control, handleSubmit, setValue, setError} = useForm<IInputProps>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  // function submit form
  const onSubmit = (data: IInputProps) => {
    console.log(data)
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <PageLayouts pageTitle='Tạo tin tức' breadcrumbs={breadcrumbs}>
      {loading ? (
        <LoadingCreateNews />
      ) : (
        <div className='form d-flex flex-column flex-lg-row'>
          <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{LANG_NEWS.thumbnail}</h2>
                </div>
              </div>
              {/* start:: avatar */}
              <div className='card-body text-center pt-0'>
                <SAPPHookUploadFile
                  name='banner'
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  imagePreview={avatar}
                  accept={ACCEPT_UPLOAD_MIME_TYPE}
                  maxFileSize={DEFAULT_MAX_FILE_SIZE}
                  removeAvatar=''
                />
              </div>
              {/* end:: avatar */}
            </div>

            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{LANG_DASHBOARD.status}</h2>
                </div>

                <div className='card-toolbar'>
                  <div
                    className='rounded-circle bg-success w-15px h-15px'
                    id='kt_ecommerce_add_product_status'
                  ></div>
                </div>
              </div>

              <div className='card-body pt-0'>
                {/* start:: select */}
                <HookFormSelect
                  control={control}
                  name='status'
                >
                  <option value='published'>Published</option>
                  <option value='draft'>Draft</option>
                  <option value='scheduled'>Scheduled</option>
                  <option value='inactive'>Inactive</option>
                </HookFormSelect>
                {/* end:: select  */}

                <div className='d-none mt-10'>
                  <label className='form-label'>{LANG_NEWS.selectPublishing}</label>
                  <input
                    className='form-control'
                    id='kt_ecommerce_add_product_status_datepicker'
                    placeholder='Pick date & time'
                  />
                </div>
              </div>
            </div>

            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{LANG_NEWS.productDetails}</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                {/* start:: select categories */}
                <HookFormSelect
                  control={control}
                  name='categories'
                  label='Categories'
                >
                  <option value='Computers'>Computers</option>
                  <option value='Watches'>Watches</option>
                  <option value='Headphones'>Headphones</option>
                  <option value='Footwear'>Footwear</option>
                  <option value='Cameras'>Cameras</option>
                  <option value='Shirts'>Shirts</option>
                  <option value='Household'>Household</option>
                  <option value='Handbags'>Handbags</option>
                  <option value='Wines'>Wines</option>
                  <option value='Sandals'>Sandals</option>
                </HookFormSelect>
                {/* end:: select categories */}

                <ButtonIcon title={LANG_NEWS.createCategory} className='btn-sm mx-auto my-10'>
                  <KTIcon iconName='plus' className='fs-2' />
                </ButtonIcon>

                <label className='form-label d-block'>{LANG_NEWS.tags}</label>

                <input
                  id='kt_ecommerce_add_product_tags'
                  name='kt_ecommerce_add_product_tags'
                  className='form-control mb-2'
                />

                <div className='text-muted fs-7'>{LANG_NEWS.addTags}</div>
              </div>
            </div>

            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{LANG_NEWS.weeklySales}</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <span className='text-muted'>{LANG_NEWS.notData}</span>
              </div>
            </div>

            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{LANG_NEWS.productTemplate}</h2>
                </div>
              </div>

              {/* start:: select template */}
              <div className='card-body pt-0'>
                <HookFormSelect
                  control={control}
                  name='template'
                  label='Select a product template'
                >
                  <option value='default'>Default template</option>
                  <option value='electronics'>Electronics</option>
                  <option value='office'>Office stationary</option>
                  <option value='fashion'>Fashion</option>
                </HookFormSelect>
              </div>
              {/* end:: select template */}
            </div>
          </div>

          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='tab-content'>
              <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>{LANG_NEWS.general}</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='required form-label'>{LANG_NEWS.productName}</label>
                        {/* start:: textfield product_name */}
                        <HookFormTextField
                          control={control}
                          name='product_name'
                          placeholder={LANG_NEWS.productName}
                        />
                        {/* end:: textfield product_name */}

                        <div className='text-muted fs-7'>{LANG_NEWS.descriptionProductName}</div>
                      </div>
                      <div>
                        {/* start:: editor */}
                        <label className='form-label'>{LANG_NEWS.description}</label>
                        <HookFormEditor name='description' control={control} />
                        {/* end:: editor */}
                      </div>
                    </div>
                  </div>

                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>{LANG_NEWS.media}</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='fv-row mb-2'>
                        <div className='dropzone' id='kt_ecommerce_add_product_media'>
                          <div className='dz-message needsclick'>
                            <i className='ki-outline ki-file-up text-primary fs-3x'></i>

                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                {LANG_NEWS.dropFiles}
                              </h3>
                              <span className='fs-7 fw-semibold text-gray-400'>
                                {LANG_NEWS.upload}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='text-muted fs-7'>{LANG_NEWS.descriptionUpload}</div>
                    </div>
                  </div>

                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>{LANG_NEWS.pricing}</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='required form-label'>{LANG_NEWS.basePrice}</label>
                        <HookFormTextField
                          control={control}
                          name='price'
                          placeholder='Product price'
                        />

                        <div className='text-muted fs-7'>{LANG_NEWS.descriptionPrice}</div>
                      </div>

                      <div className='fv-row mb-10'>
                        <label className='fs-6 fw-bold mb-3'>
                          {LANG_NEWS.discountType}
                          <span
                            className='ms-1'
                            title='Select a discount type that will be applied to this product'
                          >
                            <i className='ki-outline ki-information-5 text-gray-500 fs-6'></i>
                          </span>
                        </label>

                        <div className='row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9'>
                          <div className='col'>
                            <label className='btn btn-outline btn-outline-dashed btn-active-secondary active d-flex text-start p-6'>
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='discount_option'
                                />
                              </span>

                              <span className='ms-5'>
                                <span className='fs-4 fw-bold text-gray-800 d-block'>
                                  {LANG_NEWS.noDiscount}
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col'>
                            <label className='btn btn-outline btn-outline-dashed btn-active-secondary d-flex text-start p-6'>
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='discount_option'
                                />
                              </span>

                              <span className='ms-5'>
                                <span className='fs-4 fw-bold text-gray-800 d-block'>
                                  {LANG_NEWS.percentage}
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col'>
                            <label className='btn btn-outline btn-outline-dashed btn-active-secondary d-flex text-start p-6'>
                              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='discount_option'
                                />
                              </span>

                              <span className='ms-5'>
                                <span className='fs-4 fw-bold text-gray-800 d-block'>
                                  {LANG_NEWS.fixedPrice}
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        className='d-none mb-10 fv-row'
                        id='kt_ecommerce_add_product_discount_percentage'
                      >
                        <label className='form-label'>{LANG_NEWS.discountPercentage}</label>

                        <div className='d-flex flex-column text-center mb-5'>
                          <div className='d-flex align-items-start justify-content-center mb-7'>
                            <span
                              className='fw-bold fs-3x'
                              id='kt_ecommerce_add_product_discount_label'
                            >
                              0
                            </span>
                            <span className='fw-bold fs-4 mt-1 ms-2'>%</span>
                          </div>
                          <div
                            id='kt_ecommerce_add_product_discount_slider'
                            className='noUi-sm'
                          ></div>
                        </div>

                        <div className='text-muted fs-7'>
                          {LANG_NEWS.descriptionDiscountPercentage}
                        </div>
                      </div>

                      <div
                        className='d-none mb-10 fv-row'
                        id='kt_ecommerce_add_product_discount_fixed'
                      >
                        <label className='form-label'>{LANG_NEWS.discountedPrice}</label>

                        <input
                          type='text'
                          name='dicsounted_price'
                          className='form-control mb-2'
                          placeholder='Discounted price'
                        />

                        <div className='text-muted fs-7'>
                          {LANG_NEWS.descriptionDiscountedPrice}
                        </div>
                      </div>

                      <div className='d-flex flex-wrap gap-5'>
                        <div className='fv-row w-100 flex-md-root'>
                          <label className='required form-label'>{LANG_NEWS.taxClassName}</label>
                          <HookFormSelect
                            control={control}
                            name='tax'
                          >
                            <option value='0'>Tax Free</option>
                            <option value='1'>Taxable Goods</option>
                            <option value='2'>Downloadable Product</option>
                          </HookFormSelect>
                        </div>

                        <div className='fv-row w-100 flex-md-root'>
                          <label className='form-label'>{LANG_NEWS.VATAmount}</label>

                          <HookFormTextField control={control} name='amount' />

                          <div className='text-muted fs-7'>{LANG_NEWS.descriptionVATAmount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SAPPDialogButtonsCancelSubmit
              className='d-flex justify-content-end'
              cancelButtonCaption={LANG_NEWS.cancel}
              okButtonCaption={LANG_NEWS.saveChanges}
              okOnClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      )}
    </PageLayouts>
  )
}

export default withAuthComponents(CreateNews)
