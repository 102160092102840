import {Popover} from 'antd'
import clsx from 'clsx'
import { useState } from 'react'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ThemeModeComponent} from 'src/_metronic/assets/ts/layout'
import {KTIcon} from 'src/_metronic/helpers'
import {ThemeModeType, useThemeMode} from './ThemeModeProvider'

type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const ThemeModeSwitcher = ({
  menuPlacement = 'right-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <>
      <Popover
        visible={isPopoverVisible}
        onVisibleChange={handlePopoverVisibleChange}
        placement='rightTop'
        arrow={false}
        content={
          <div className='sapp-menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-150px'>
            <div className='menu-item px-3 my-0'>
              <div
                className={clsx('menu-link px-3 py-2', {active: menuMode === 'light'})}
                onClick={() => switchMode('light')}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='night-day' className='fs-1' />
                </span>
                <span className='menu-title'>{LANG_SIDEBAR.light}</span>
              </div>
            </div>

            <div className='menu-item px-3 my-0'>
              <div
                className={clsx('menu-link px-3 py-2', {active: menuMode === 'dark'})}
                onClick={() => switchMode('dark')}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='moon' className='fs-1' />
                </span>
                <span className='menu-title'>{LANG_SIDEBAR.dark}</span>
              </div>
            </div>

            <div className='menu-item px-3 my-0'>
              <div
                className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}
                onClick={() => switchMode('system')}
              >
                <span className='menu-icon' data-kt-element='icon'>
                  <KTIcon iconName='screen' className='fs-1' />
                </span>
                <span className='menu-title'>{LANG_SIDEBAR.system}</span>
              </div>
            </div>
          </div>
        }
      >
        <div className='menu-item px-5'>
          {calculatedMode === 'dark' && (
            <div className='menu-link px-5'>
              <span className='menu-title position-relative'>
                {LANG_SIDEBAR.mode}
                <span className='ms-5 position-absolute translate-middle-y top-50 end-0'>
                  <i className='ki-outline ki-night-day theme-light-show fs-2'></i>
                  <i className='ki-outline ki-moon theme-dark-show fs-2'></i>
                </span>
              </span>
            </div>
          )}

          {calculatedMode === 'light' && (
            <div className={`${isPopoverVisible ? 'sapp-bg-active-popover' : '' } menu-link px-5`}>
              <span className={`${isPopoverVisible ? 'sapp-text-active-popover' : '' } menu-title position-relative`}>
                {LANG_SIDEBAR.mode}
                <span className='ms-5 position-absolute translate-middle-y top-50 end-0'>
                  <i className='ki-outline ki-night-day theme-light-show fs-2'></i>
                  <i className='ki-outline ki-moon theme-dark-show fs-2'></i>
                </span>
              </span>
            </div>
          )}
        </div>
      </Popover>
    </>
  )
}

export {ThemeModeSwitcher}
