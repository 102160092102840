import React from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import SAPPSelect from 'src/components/base/select/SAPPSelect'

const Invoices = () => {
  return (
    <div className='col-xxl-4'>
      <div className='card card-xxl-stretch mb-5 mb-xxl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='text-gray-800'>Invoices</h3>
          </div>
        </div>
        <div className='card-body'>
          <span className='fs-5 fw-semibold text-gray-600 pb-6 d-block'>
            Download apart from order of the good awesome invoice topics
          </span>

          <div className='d-flex align-self-center'>
            <div className='flex-grow-1 me-3'>
              <SAPPSelect
                size='middle'
                options={[
                  {
                    label: 'Role',
                    value: 'Role',
                  },
                  {
                    label: 'One',
                    value: 'One',
                  },
                ]}
              />
            </div>
            <ButtonIcon className='btn btn-icon flex-shrink-0'>
              <i className='ki-outline ki-arrow-down fs-1'></i>
            </ButtonIcon>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invoices
