import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  DENIED_PERMISSIONS,
  DURATION_EMAIL,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  MY_PROFILE,
  STAFF_PROFILE,
  STATUS_FORM,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FILED,
} from 'src/constants'
import {z} from 'zod'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import {phoneRegExp, sizeInBytes, usernamePattern} from 'src/utils'
import {debounce, isUndefined} from 'lodash'
import {StaffAPI, uploadAvatarStaff} from 'src/apis/staffs'
import {toast} from 'react-hot-toast'
import {IError, Role} from 'src/type'
import {IStudentDetail} from 'src/type/students'
import {IRoles} from 'src/type/staffs.'
import {RolesAPI} from 'src/apis/roles'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {Select} from 'antd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import {useNavigate, useParams} from 'react-router-dom'
import {useUserContext} from 'src/context/UserProvider'
import ModalEditEmail from '../ModalEditEmail'
import {IUpdateStaff} from 'src/pages/staffs/CreateAndUpdateStaff'
import {LANG_PLACEHOLDER} from 'src/constants/lang'
import {useConfirm} from 'src/hooks/use-confirm'
import PopupConfirm from 'src/common/PopupConfirm'
import {CODE_ADMIN, TITLE_STAFF_GR} from 'src/constants/permission'
const {Option} = Select

interface IInputProps {
  full_name: string
  username: string
  email: string
  role: Array<any>
  phone: number
  avatar: File
  status: string
  sex: string
}

interface IProps {
  staffDetail: IStudentDetail | undefined
  setStaffDetail: Dispatch<SetStateAction<any>>
}

const EditStaffProfile = ({staffDetail, setStaffDetail}: IProps) => {
  const navigate = useNavigate()
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const {confirm, contextHolder} = useConfirm()

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(`${STAFF_PROFILE}/${id}/overview`),
    })
  }

  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [roles, setRoles] = useState<any>([])

  const validationSchema = z.object({
    full_name: z
      .string({required_error: VALIDATION_FILED})
      .min(3, {message: VALIDATE_MIN_FULLNAME})
      .max(100, {message: VALIDATE_MAX_FULLNAME}),
    email: z.string({required_error: VALIDATION_FILED}).email(),
    role: z.any(),
    phone: z
      .string({required_error: VALIDATION_FILED})
      .regex(new RegExp(phoneRegExp), {message: VALIDATE_PHONE}),
    status: z.string(),
    avatar: z.any(),
    username: z
      .string({required_error: VALIDATION_FILED})
      .regex(new RegExp(usernamePattern), {
        message:
          'Username must have at least 6 characters without Vietnamese and space between words',
      })
      .min(6, {message: VALIDATE_MIN_USERNAME})
      .max(40, {message: 'Username Must Be Shorter Than Or Equal To 40 Characters'}),
    sex: z.string({required_error: VALIDATION_FILED}).optional(),
  })

  const {handleSubmit, control, setValue, setError} = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const onSubmit = async (data: IInputProps) => {
    setOpenPopupConfirm(false)
    const {email, full_name, phone, status, avatar, sex} = data

    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return
    setLoadingSubmit(true)

    try {
      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({staffId: staffDetail?.id, avatar: avatar})
      }

      const paramsUpdate: IUpdateStaff = {
        full_name,
        email: email.toLowerCase(),
        phone,
        status,
        roles: data.role?.map((role) => ({id: role})),
        avatar: null,
        sex,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      await StaffAPI.update({
        id: staffDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Successfully!')
          navigate(`${STAFF_PROFILE}/${staffDetail?.id}/${MY_PROFILE.OVERVIEW}`)
        })
        .catch((err) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, {message: errorMessage})
          }, {})
          if (err?.response?.data?.error?.code === '403|000000') {
            toast.error(DENIED_PERMISSIONS)
          }
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.meta?.total_pages
    const pageIndex = roles?.meta?.page_index as number
    const pageSize = roles?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const results = roles?.concat(res?.data?.roles)
      setRoles({
        meta: res?.data?.meta,
        roles: results,
      })
    }
  }

  const getRoles = async ({params}: any) => {
    const res: any = await fetchRoles(1, 20, params)
    setRoles(res?.data?.roles)
  }

  useEffect(() => {
    getRoles({})
  }, [])

  const debounceSearchMentor = debounce((e) => {
    getRoles({params: {name: e}})
  }, 500)

  const optionRoles = roles?.map((role: any) => ({name: role?.name, value: role?.id}))

  useEffect(() => {
    if (!staffDetail?.id) return

    setValue('email', staffDetail?.detail?.email ?? '')
    setValue('full_name', staffDetail?.detail?.full_name ?? '')
    setValue('phone', staffDetail?.detail?.phone ?? '')
    setValue('status', staffDetail?.status ?? '')
    setValue('username', staffDetail?.username ?? '')
    setValue('role', staffDetail?.roles?.map((role) => role?.name) ?? [])
    setValue('sex', staffDetail?.detail?.sex ?? '')
  }, [
    setValue,
    staffDetail?.detail?.email,
    staffDetail?.detail?.full_name,
    staffDetail?.detail?.phone,
    staffDetail?.id,
    staffDetail?.roles,
    staffDetail?.status,
    staffDetail?.username,
  ])

  const [showInputOtp, setShowInputOTP] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const {sendEmailOTP} = useUserContext()
  const {id} = useParams()
  const [openEditEmail, setOpenEditEmail] = useState(false)

  const onSubmitSendOTP = (email: string) => {
    if (isActive) return

    sendEmailOTP(id, email).then(() => {
      setDuration(DURATION_EMAIL)
      setShowInputOTP(true)
      setIsActive(true)
    })
  }
  const {profileMe} = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderEditEmail = profileMe?.roles?.some(
    (role: Role) =>
    hasPermission(role, TITLE_STAFF_GR.CHANGE_EMAIL_STAFF) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STAFF_GR.EDIT_STAFF) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
        </div>

        <div id='kt_account_settings_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <SAPPHookUploadFile
                    name='avatar'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={
                      staffDetail?.detail?.avatar?.['150x150'] ??
                      staffDetail?.detail?.avatar?.ORIGIN ??
                      avatar
                    }
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowAvatarDefault={setShowAvatarDefault}
                    removeAvatar={avatar}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                  {LANG_PLACEHOLDER.FULLNAME}
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='full_name'
                    control={control}
                    placeholder={LANG_PLACEHOLDER.FULLNAME}
                    defaultValue={staffDetail?.detail?.full_name}
                    required
                    guideline={GUIDELINE_FULLNAME}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                  Username
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='username'
                    control={control}
                    placeholder='Username'
                    defaultValue={staffDetail?.username}
                    disabled
                    required
                    guideline={GUIDELINE_USERNAME}
                  />
                </div>
              </div>

              <div className='row mb-md-6 mb-0'>
                <label className='col-xl-3 col-lg-3 col-form-label required fw-semibold fs-6'>
                  Email
                </label>

                <div className='col-xl-8 col-lg-7 col-md-10 fv-row'>
                  <HookFormTextField
                    name='email'
                    control={control}
                    placeholder='Email'
                    defaultValue={staffDetail?.detail?.email}
                    required
                    disabled
                    guideline={GUIDELINE_EMAIL}
                  />
                </div>
                {allowRenderEditEmail && (
                  <div
                    className='col-xl-1 col-lg-2 col-md-2 d-flex justify-content-end mt-md-0 mt-3'
                    onClick={() => setOpenEditEmail(true)}
                  >
                    <ButtonPrimary title='Edit' className='sapp-p-button--custom' />
                  </div>
                )}
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Phone</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='phone'
                    control={control}
                    placeholder='Phone Number'
                    defaultValue={staffDetail?.detail?.phone}
                    required
                    disabled
                    guideline={GUIDELINE_PHONE}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Status</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    name='status'
                    control={control}
                    size='large'
                    defaultValue={staffDetail?.status}
                    className='fs-6'
                  >
                    {STATUS_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Role</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectMultiple
                    name='role'
                    control={control}
                    placeholder='Role'
                    selectOptions={optionRoles}
                    onSearch={(e: any) => {
                      if (e === undefined) {
                        return
                      }
                      debounceSearchMentor(e)
                    }}
                    handleNextPage={(e: any) => handlNextPageRole({params: {name: e}})}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Gender</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    size='large'
                    name='sex'
                    control={control}
                    dropdownStyle={{minWidth: 'fit-content'}}
                    placeholder='Please select'
                    defaultValue={GENDER_FORM?.[0]?.value}
                    required
                  >
                    {GENDER_FORM.map((gender) => (
                      <Option key={gender.label} value={gender.value}>
                        {gender.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>
            </div>
            {allowRenderEdit && (
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <ButtonSecondary
                  title='Discard'
                  className='btn-active-light-primary me-2'
                  onClick={hanleCancel}
                />
                <ButtonPrimary
                  title='Save Changes'
                  onClick={() => setOpenPopupConfirm(true)}
                  loading={loadingSubmit}
                />
              </div>
            )}
          </div>
        </div>
        <ModalEditEmail
          duration={duration}
          open={openEditEmail}
          setDuration={setDuration}
          setOpen={setOpenEditEmail}
          showInputOtp={showInputOtp}
          isActive={isActive}
          onSubmitSendOTP={onSubmitSendOTP}
          setIsActive={setIsActive}
          setUserDetail={setStaffDetail}
        />
      </div>
      <PopupConfirm
        open={openPopupConfirm}
        setOpen={setOpenPopupConfirm}
        onClick={handleSubmit(onSubmit)}
        body='Bạn có chắc chắn muốn lưu không?'
        okButtonCaption='Yes, save'
      />
    </>
  )
}

export default EditStaffProfile
