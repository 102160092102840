import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreatedContent from '../created-content/CreatedContent'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import AddTabQuiz from '../tabQuiz/addTabQuiz'
import AddTabVideo from '../tabVideo/addTabVideo'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {TYPE_DOCUMENT, VALIDATE_FILED_MAX, VALIDATION_FILED} from 'src/constants'
import {CoursesAPI} from 'src/apis/courses'
import {Spin} from 'antd'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import TabText from '../tabText/tabText'
import {useConfirm} from 'src/hooks/use-confirm'
import toast from 'react-hot-toast'
import {PlusVideoIcon} from 'src/common/icons'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {KTIcon} from 'src/_metronic/helpers'
import { useCourseContext } from 'src/context/CourseProvider'
// import {IProps} from '../Tabs'

interface IProps {
  data: {
    tabId: string
    value?: any
    edit?: boolean
    creating?: boolean
  }[] //all tab
  setTabActive: React.Dispatch<React.SetStateAction<string>>
  tabActive: string
  setData: any
  closeModal: any
  course_section_id: string
  e: {
    tabId: string
    value?: any
    edit?: boolean
    creating?: boolean
  } //tab
  i: number
  onlyEdit?: boolean
}
const TabContent = ({
  data,
  setTabActive,
  tabActive,
  setData,
  course_section_id,
  e,
  i,
  onlyEdit,
  closeModal
}: IProps) => {
  const [createDoc, setCreateDoc] = useState<{
    type?: string
    tabIndex?: number
  }>()
  const [editDoc, setEditDoc] = useState<{
    type?: string
    tabIndex?: number
    indexItem?: number
    DocId?: string
    dataVideo?: any
  }>()
  const [loadingAddTab, setLoadingAddTab] = useState(false)
  const {idCourse} = useCourseContext()
  const [loadingDeleteTab, setLoadingDeleteTab] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const elementRef = useRef<any>(null)
  const validationSchema = z.object({
    [e.tabId]: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const {confirm, contextHolder} = useConfirm()
  const {control, setValue, handleSubmit, unregister} = useFormProp
  async function getDetailTab() {
    try {
      setLoading(true)
      const res = await CoursesAPI.getTabDetail(e.tabId)
      setValue(e.tabId, res.data.name)
      setData((prev: any) => {
        const data = [...prev]
        data[i].value.tab_name = res.data.name
        for (let j in res.data.course_tab_documents) {
          if (!data[i].value.document) {
            data[i].value.document = []
          }
          data[i].value.document[j] = {...res.data.course_tab_documents[j]}
        }

        // handleClose()
        setLoading(false)
        return data
      })
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    if (!e.creating && tabActive === e.tabId) {
      getDetailTab()
    }
  }, [tabActive])
  // scroll den vi tri ad new doc
  useEffect(() => {
    if (createDoc?.tabIndex !== undefined && elementRef?.current) {
      elementRef.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'})
    }
  }, [createDoc, elementRef?.current])
  const handleClose = (cb?: any) => {
    let body = ['Bạn có chắc chắn muốn huỷ không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        setCreateDoc({})
        // setValue('editor', undefined)
        // unregister('editor')
        typeof cb === 'function' && cb()
        setEditDoc({})
      },
    })
  }
  const handleOpenAdd = (e: any, tabIndex: number) => {
    if (e === 1) {
      // setOpenAddTabText({status: true, tabIndex: tabIndex})
      setCreateDoc({type: TYPE_DOCUMENT.TEXT, tabIndex})
    } else if (e === 2) {
      setCreateDoc({type: TYPE_DOCUMENT.QUIZ, tabIndex})
    } else if (e === 3) {
      setCreateDoc({type: TYPE_DOCUMENT.VIDEO, tabIndex})
    }
  }
  const handleOpenEdit = async (e: any, tabIndex: number, indexItem: number) => {
    if (e === TYPE_DOCUMENT.TEXT) {
      // setValue('editor', data[tabIndex].value.document[indexItem].text_editor_content)
      setEditDoc({
        type: e,
        tabIndex,
        indexItem,
        DocId: data[tabIndex].value.document[indexItem].id,
      })
    }
    if (e === TYPE_DOCUMENT.QUIZ) {
      // setValue('editor', data[tabIndex].value.document[indexItem].payload.content)
      setEditDoc({
        type: e,
        tabIndex,
        indexItem,
        DocId: data[tabIndex].value.document[indexItem].id,
      })
    }
    if (e === TYPE_DOCUMENT.VIDEO) {
      const res = await CoursesAPI.getDocumentDetail(data[tabIndex].value.document[indexItem].id)
      setEditDoc({
        type: e,
        tabIndex,
        indexItem,
        DocId: data[tabIndex].value.document[indexItem].id,
        dataVideo: res.data,
      })
    }
  }
  const handleDeleteDoc = async (index: number, tabIndex: number) => {
    let body = ['Bạn có chắc chắn muốn xoá Doc này không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        try {
          const docId = data[tabIndex].value.document[index].id
          await CoursesAPI.deleteDocumentTab(docId)
          const data2 = [...data]
          data2[tabIndex].value.document.splice(index, 1)
          data2[tabIndex] = {
            ...data2[tabIndex],
            value: {...data2[tabIndex].value, document: data2[tabIndex].value.document},
          }
          setData(data2)
        } catch (err) {
          console.log(err)
        }
      },
    })
  }
  const onSubmit = async (dataValue: any, type?: any) => {
    try {
      setLoadingSubmit(true)
      const newDoc = [...data]
      let body: any
      if (type === TYPE_DOCUMENT.QUIZ) {
        body = {
          type: createDoc?.type,
          payload: dataValue,
        }
        // newDoc[createDoc?.tabIndex || 0].value.document.push(body)
      } else if (type === TYPE_DOCUMENT.TEXT) {
        body = {
          type: createDoc?.type,
          payload: {content: dataValue.editor, attachments: []},
        }
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        const listQuiz_ids = []
        for (let e of dataValue) {
          if (e.quiz_id && e.quiz_id !== null) {
            listQuiz_ids.push(e.quiz_id)
          }
        }
        body = {
          type: createDoc?.type,
          payload: {
            video_quiz_ids: listQuiz_ids,
          },
        }
      }
      await CoursesAPI.addDocumentTab({
        ...body,
        course_id: idCourse,
        id: newDoc[createDoc?.tabIndex || 0].tabId,
      })
      // if (!newDoc[createDoc?.tabIndex || 0].value.document) {
      //   newDoc[createDoc?.tabIndex || 0].value.document = []
      // }
      // // body.DocId = res.data.id
      // newDoc[createDoc?.tabIndex || 0].value.document.push(res.data)
      // setData((prev: any) => {
      //   setCreateDoc({})
      //   // setValue('editor', undefined)
      //   // unregister('editor')
      //   // typeof cb === 'function' && cb()
      //   setEditDoc({})
      //   return newDoc
      // })
      await getDetailTab()
      setCreateDoc({})
      setEditDoc({})
      toast.success('Create Doc Success!')
    } catch (err: any) {
      if (err?.response?.status === 413) {
        toast.error('Request entity too large!')
      }
    } finally {
      setLoadingSubmit(false)
    }
  }
  const onEdit = async (dataValue: any, type?: any) => {
    try {
      setLoadingSubmit(true)
      let body: any
      if (type === TYPE_DOCUMENT.QUIZ) {
        body = {
          type: type,
          payload: dataValue,
        }
        // newDoc[createDoc?.tabIndex || 0].value.document.push(body)
      } else if (type === TYPE_DOCUMENT.TEXT) {
        body = {
          type: type,
          payload: {
            content: dataValue.editor,
            attachments: [],
          },
        }
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        const listQuiz_ids = []
        for (let e of dataValue) {
          if (e.quiz_id && e.quiz_id !== null) {
            listQuiz_ids.push(e.quiz_id)
          }
        }
        body = {
          type: type,
          payload: {
            video_quiz_ids: listQuiz_ids,
          },
        }
      }
      await CoursesAPI.editDocumentTab({
        ...body,
        id: editDoc?.DocId,
      })
      // setData((prev: any) => {
      //   const data = [...prev]
      //   data[editDoc?.tabIndex || 0].value.document[editDoc?.indexItem || 0] = res.data
      //   setCreateDoc({})
      //   // setValue('editor', undefined)
      //   // unregister('editor')
      //   setEditDoc({})
      //   return data
      // })
      await getDetailTab()
      setCreateDoc({})
      setEditDoc({})
      toast.success('Edit Doc Success!')
    } catch (err: any) {
      if (err?.response?.status === 413) {
        toast.error('Request entity too large!')
      }
    } finally {
      setLoadingSubmit(false)
    }
  }
  const submitTab = async (dataValue: any, i: number, edit?: boolean) => {
    setLoadingAddTab(true)
    try {
      if (!edit) {
        const res = await CoursesAPI.addTab({
          course_section_id: course_section_id,
          tab_name: dataValue[tabActive],
          course_id: idCourse
        })
        setData((prev: any) => {
          const data = [...prev]
          data[i].tabId = res.data.id
          data[i].creating = false
          setTabActive(res.data.id)
          setValue(res.data.id, dataValue[tabActive])
          unregister(tabActive)
          return data
        })
      } else {
        const res = await CoursesAPI.editTab({
          id: data[i].tabId,
          course_section_id: course_section_id,
          tab_name: dataValue[tabActive],
        })
        setData((prev: any) => {
          const data = [...prev]
          data[i].edit = false
          setValue(res.data.id, dataValue[tabActive])
          return data
        })
      }
    } finally {
      setLoadingAddTab(false)
    }
  }
  const handleDeleteTab = async (tabId: string, close: boolean = false) => {
    let body = [`Bạn có chắc chắn muốn ${close ? 'đóng' : 'xóa'} tab không?`]
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        setLoadingDeleteTab(true)
        try {
          if (!e.creating) {
            await CoursesAPI.deleteTab(tabId)
          }
          unregister(tabId)
          const index = data.findIndex((e) => e.tabId === tabId)
          const arr = [...data]
          arr.splice(index, 1)
          setData((prev: any) => {
            return arr
          })
          setTabActive(data[index - 1]?.tabId || '')
        } catch (err) {
          console.log(err)
        } finally {
          setLoadingDeleteTab(false)
        }
      },
    })
  }
  function titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(' ')
  }
  const handleGenEdit = (
    idx: number,
    i: number,
    doc: any,
    e: {
      tabId: string
      value?: any
      edit?: boolean
    },
    type?: string,
    indexItem?: number,
    tabIndex?: number
  ) => {
    if (indexItem === idx && tabIndex === i) {
      if (type === TYPE_DOCUMENT.TEXT) {
        return (
          <TabText
            onSubmit={onEdit}
            data={data[tabIndex].value.document[indexItem].text_editor_content}
            edit
            handleClose={handleClose}
            key={idx}
            loading={loadingSubmit}
          />
        )
      } else if (type === TYPE_DOCUMENT.QUIZ) {
        return (
          <AddTabQuiz
            tabId={e.tabId}
            doc={doc}
            setData={setData}
            handleCancel={handleClose}
            onSubmit={(e: any) => onEdit(e, TYPE_DOCUMENT.QUIZ)}
            edit
            key={idx}
          />
        )
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        return (
          <AddTabVideo
            key={idx}
            handleClose={handleClose}
            onSubmit={onEdit}
            data={editDoc?.dataVideo}
            loading={loading}
          />
        )
      }
    } else {
      return (
        <CreatedContent
          classNameContainer='sapp-h-48px mb-8 text-gray-600'
          key={idx}
          {...(!onlyEdit && {
            actionDelete:
              !editDoc?.type && !createDoc?.type
                ? () => {
                    handleDeleteDoc(idx, i)
                  }
                : undefined,
            actionEdit:
              !editDoc?.type && !createDoc?.type
                ? () => {
                    handleOpenEdit(doc.type, i, idx)
                  }
                : undefined,
          })}
          iconGrayColor={'500'}
          name={titleCase(doc.type)}
          disableHoverButtonBg={true}
          customConfirm
        />
      )
    }
  }
  return (
    <div
      key={e.tabId}
      className={`tab-pane fade ${tabActive === e.tabId && 'show active'} sapp-tab-pane-content`}
      id={`kt_tab_pane_${i}`}
      role='tabpanel'
    >
      {contextHolder}
      <Spin spinning={loading}>
        <div
          className={`${
            e.value?.document?.length > 0 && 'sapp-inputname-wrapper'
          } pt-6 pb-10 sapp-tab-content-wrapper`}
        >
          {e.creating || e.edit ? (
            <HookFormTextField
              name={e.tabId}
              control={control}
              label='Tab Name'
              required
              className='sapp-input-tab'
              placeholder='Tab Name'
              onChange={(e) => {
                setData((prev: any) => {
                  const data = [...prev]
                  data[i].value.tab_name = e.target.value
                  return data
                })
              }}
            />
          ) : (
            <div>
              <label className='d-flex align-items-center fs-6 form-label required fw-bold'>
                <span>Tab Name</span>
              </label>
              {/* <span>Tab Name</span> */}
              <CreatedContent
                name={e.value.tab_name}
                actionEdit={() => {
                  setData((prev: any) => {
                    const data = [...prev]
                    data[i].edit = true
                    return data
                  })
                }}
                iconGrayColor={'500'}
                {...(!onlyEdit && {actionDelete: () => handleDeleteTab(tabActive)})}
                classNameContainer='text-gray-600 sapp-custom-card'
                disableHoverButtonBg={true}
                customConfirm
              />
            </div>
          )}
          <>
            {e.creating ? (
              <div className='d-flex justify-content-end pt-4'>
                <div className='d-flex'>
                  <ButtonSecondary
                    onClick={() => {
                      handleDeleteTab(tabActive, true)
                    }}
                    // className='sapp-btn-color'
                    title='Cancel'
                    disabled={loadingAddTab}
                  />
                  <ButtonPrimary
                    title='Save'
                    onClick={handleSubmit((e) => submitTab(e, i))}
                    loading={loadingAddTab}
                  />
                </div>
              </div>
            ) : e.edit ? (
              <div className='d-flex justify-content-end pt-4'>
                <div className='d-flex'>
                  <ButtonSecondary
                    onClick={() => {
                      setData((prev: any) => {
                        const data = [...prev]
                        data[i].edit = false
                        return data
                      })
                    }}
                    // className='sapp-btn-color'
                    title='Cancel'
                    disabled={loadingAddTab}
                  />
                  <ButtonPrimary
                    title='Save'
                    onClick={handleSubmit((e) => submitTab(e, i, true))}
                    loading={loadingAddTab}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        </div>
        <div className='d-flex flex-column'>
          {e.value?.document?.length > 0 && (
            <>
              <label className='d-flex align-items-center fs-6 form-label required fw-bold mt-10 ps-8'>
                <span>{`List Documents`}</span>
              </label>
              <div className='sapp-tab-content-wrapper pb-0'>
                {e.value?.document?.map((el: any, idx: number) => {
                  return handleGenEdit(
                    idx,
                    i,
                    el,
                    e,
                    editDoc?.type,
                    editDoc?.indexItem,
                    editDoc?.tabIndex
                  )
                })}
              </div>
            </>
          )}
          {createDoc?.type === TYPE_DOCUMENT.TEXT ? (
            <div
              className='sapp-doc-input-wrapper pt-10 sapp-tab-content-wrapper'
              ref={elementRef}
              style={{minHeight: '600px'}}
            >
              <TabText onSubmit={onSubmit} handleClose={handleClose} loading={loadingSubmit} />
            </div>
          ) : createDoc?.type === TYPE_DOCUMENT.QUIZ ? (
            <div className='sapp-doc-input-wrapper pt-8 sapp-tab-content-wrapper' ref={elementRef}>
              <AddTabQuiz
                tabId={e.tabId}
                setData={setData}
                handleCancel={handleClose}
                onSubmit={(e: any) => onSubmit(e, TYPE_DOCUMENT.QUIZ)}
              />
            </div>
          ) : createDoc?.type === TYPE_DOCUMENT.VIDEO ? (
            <div className='sapp-doc-input-wrapper pt-8 sapp-tab-content-wrapper' ref={elementRef}>
              <AddTabVideo handleClose={handleClose} onSubmit={onSubmit} />
            </div>
          ) : (
            <></>
          )}
          {!editDoc?.type && !createDoc?.type && !e.creating && !onlyEdit && (
            <div className='sapp-tab-content-wrapper pb-0'>
              <SAPPSelect
                onChange={(e) => {
                  handleOpenAdd(e, i)
                }}
                options={[
                  {label: 'Text', value: 1},
                  {label: 'Quiz', value: 2},
                  {label: 'Video', value: 3},
                  //   {label: 'Past Exam analysis', value: 4},
                ]}
                size='large'
                placeholder={
                  <div className='d-flex align-items-center gap-2 sapp-item-column'>
                    <PlusVideoIcon /> Add Document
                  </div>
                }
                // value={'+ Add Document'}
                className='sapp-h-48px'
                suffixIcon={null}
                // className
              />
            </div>
          )}
        </div>
        {!editDoc?.type && !createDoc?.type && !e.creating && (
          <div className='ps-7 mt-8'>
            <ButtonIcon
              title={'Course Content'}
              className='btn btn-sapp-icon'
              customButton='btn-light'
              // link={linkCancel}
              onClick={() => closeModal()}
            >
              <KTIcon iconName='arrow-left' iconType='solid' className='fs-1' />
            </ButtonIcon>
          </div>
        )}
      </Spin>
    </div>
  )
}
export default TabContent
