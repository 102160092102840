import { FC, useMemo } from "react"
import { ID } from "src/_metronic/helpers"
import SAPPCheckbox from "src/components/base/checkbox/SAPPCheckbox"
import { useListView } from "../../core/ListViewProvider"

type Props = {
  id: ID
}

const StaffSelectionCell: FC<Props> = ({ id }) => {
  const { selected, onSelect } = useListView()
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  
  return (
      <SAPPCheckbox
        checkTarget="#kt_table_users .form-check-input"
        ktCheck={isSelected}
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
  )
}

export { StaffSelectionCell }
