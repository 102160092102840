import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  ExamIcon,
  ExamIconActive,
  LinkIcon,
  LinkIconActive,
  QuizIcon,
  QuizIconActive,
  TextIcon,
  TextIconActive,
  VideoIcon,
  VideoIconActive,
} from 'src/common/icons'
import { TYPE_COURSE, TYPE_DOCUMENT } from 'src/constants'
import { convertTime } from 'src/utils/string'
import './node-renderer-default.css'
import classnames from './utils/classnames'
import { isDescendant } from './utils/tree-data-utils'

class NodeRendererDefault extends Component {
  constructor(props) {
    super(props)
    this.state = {hovereddNode: ''}
  }
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      clickedNode,
      ...otherProps
    } = this.props
    const nodeTitle = title || node.name
    // const nodeSubtitle = subtitle || node.subtitle
    const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null
    let handle
    if (canDrag) {
      if (typeof node.children === 'function' && node.expanded) {
        // Show a loading symbol on the handle when the children are expanded
        //  and yet still defined by a function (a callback to fetch the children)
        handle = (
          <div className='rst__loadingHandle'>
            <div className='rst__loadingCircle'>
              {[...new Array(12)].map((_, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classnames('rst__loadingCirclePoint', rowDirectionClass)}
                />
              ))}
            </div>
          </div>
        )
      } else {
        // Show the handle used to initiate a drag-and-drop
        handle = connectDragSource(<div className='rst__moveHandle' />, {
          dropEffect: 'copy',
        })
      }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
    const isLandingPadActive = !didDrop && isDragging
    let buttonStyle = {left: -1 * scaffoldBlockPxWidth}
    const checkClickedNode = () => {
      if (this.state.hovereddNode === node?.id) {
        return true
      }
      return false
    }
    if (rowDirection === 'rtl') {
      buttonStyle = {right: -1 * scaffoldBlockPxWidth}
    }
    const nodeContent = connectDragPreview(
      <div
        className={classnames(
          'rst__row',
          isLandingPadActive && 'rst__rowLandingPad',
          isLandingPadActive && !canDrop && 'rst__rowCancelPad',
          isSearchMatch && 'rst__rowSearchMatch',
          isSearchFocus && 'rst__rowSearchFocus',
          rowDirectionClass,
          className
        )}
        style={{
          opacity: isDraggedDescendant ? 0.5 : 1,
          ...style,
        }}
      >
        {/* {handle} */}

        <div
          className={classnames(
            'rst__rowContents',
            !canDrag && 'rst__rowContentsDragDisabled',
            rowDirectionClass
          )}
          onMouseEnter={() => {
            this.setState({hovereddNode: node.id})
          }}
          onMouseLeave={() => this.setState({hovereddNode: ''})}
        >
          <div style={{display: 'flex', columnGap: '12px', maxWidth: '90%', alignItems: 'center'}}>
            {node.children.length > 0 ? (
              !node.expanded ? (
                <div className='sapp-tree-expand-item d-flex'>
                  <CaretRightOutlined
                    rev=''
                    onClick={() =>
                      toggleChildrenVisibility({
                        node,
                        path,
                        treeIndex,
                      })
                    }
                    style={{
                      fontSize: '15px',
                      color: '#141414',
                    }}
                  />
                </div>
              ) : (
                <div className='sapp-tree-expand-item d-flex'>
                  <CaretDownOutlined
                    rev=''
                    onClick={() =>
                      toggleChildrenVisibility({
                        node,
                        path,
                        treeIndex,
                      })
                    }
                    style={{
                      fontSize: '15px',
                      color: '#141414',
                    }}
                  />
                </div>
              )
            ) : node.course_section_type === TYPE_COURSE.ACTIVITY ? (
              node.display_icon === TYPE_DOCUMENT.VIDEO ? (
                !checkClickedNode() ? (
                  <div className='sapp-tree-expand-item'>
                    <VideoIcon />
                  </div>
                ) : (
                  <div className='sapp-tree-expand-item'>
                    <VideoIconActive />
                  </div>
                )
              ) : node.display_icon === TYPE_DOCUMENT.TEXT ? (
                !checkClickedNode() ? (
                  <div className='sapp-tree-expand-item'>
                    <TextIcon />
                  </div>
                ) : (
                  <div className='sapp-tree-expand-item'>
                    <TextIconActive />
                  </div>
                )
              ) : node.display_icon === TYPE_DOCUMENT.QUIZ ? (
                !checkClickedNode() ? (
                  <div className='sapp-tree-expand-item'>
                    <QuizIcon />
                  </div>
                ) : (
                  <div className='sapp-tree-expand-item'>
                    <QuizIconActive />
                  </div>
                )
              ) : node.display_icon === TYPE_DOCUMENT.PAST_EXAM_ANALYSIS ? (
                !checkClickedNode() ? (
                  <div className='sapp-tree-expand-item'>
                    <ExamIcon />
                  </div>
                ) : (
                  <div className='sapp-tree-expand-item'>
                    <ExamIconActive />
                  </div>
                )
              ) : null
            ) : null}
            <div className='sapp-tree-item-left'>
              {/* {node.course_section_type === TYPE_COURSE.MID_TERM_TEST ||
              node.course_section_type === TYPE_COURSE.FINAL_TEST ? ( */}
              <div>
                <div>{nodeTitle}</div>
                {node?.quiz?.quiz_timed && (
                  <div className='sapp-time-tree'>{convertTime(node.quiz.quiz_timed * 60)}</div>
                )}
              </div>
              {node?.is_linked_section &&
              !node?.is_linked_section_child &&
              (!checkClickedNode() ? (
                <LinkIcon />
              ) : (
                <LinkIconActive />
              ))
              // <img width={12} height={12} src={link} alt='link' className='ms-1' />
            }
              {/* ) : (
                nodeTitle
              )} */}
            </div>
            
          </div>
          <div className='rst__rowToolbar'>
            {buttons.map((btn, index) => (
              <div
                key={index} // eslint-disable-line react/no-array-index-key
                // className={styles.toolbarButton}
              >
                {btn}
              </div>
            ))}
          </div>
        </div>
      </div>
    )

    return (
      <div style={{height: '100%'}} {...otherProps}>
        {node.course_section_type === TYPE_COURSE.ACTIVITY ? (
          // node.display_icon === TYPE_DOCUMENT.VIDEO ? (
          //   checkClickedNode() ? (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <VideoIcon />
          //     </div>
          //   ) : (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <VideoIconActive />
          //     </div>
          //   )
          // ) : node.display_icon === TYPE_DOCUMENT.TEXT ? (
          //   checkClickedNode() ? (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <TextIcon />
          //     </div>
          //   ) : (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <TextIconActive />
          //     </div>
          //   )
          // ) : node.display_icon === TYPE_DOCUMENT.QUIZ ? (
          //   checkClickedNode() ? (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <QuizIcon />
          //     </div>
          //   ) : (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <QuizIconActive />
          //     </div>
          //   )
          // ) : node.display_icon === TYPE_DOCUMENT.PAST_EXAM_ANALYSIS ? (
          //   checkClickedNode() ? (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <ExamIcon />
          //     </div>
          //   ) : (
          //     <div className='rst__icon' style={buttonStyle}>
          //       <ExamIconActive />
          //     </div>
          //   )
          // ) : null
          <></>
        ) : null}
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
              {node.expanded && !isDragging && (
                <div
                  style={{width: scaffoldBlockPxWidth}}
                  className={classnames('rst__lineChildren', rowDirectionClass)}
                />
              )}
            </div>
          )}

        <div className={classnames('rst__rowWrapper', rowDirectionClass)}>
          {/* Set the row preview to be used during drag and drop */}
          {canDrag ? connectDragSource(nodeContent, {dropEffect: ''}) : nodeContent}
        </div>
      </div>
    )
  }
}

NodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: '',
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: 'ltr',
}

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
}

export default NodeRendererDefault
