import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {TYPE_DOCUMENT, VALIDATION_FILED} from 'src/constants'
import {useForm} from 'react-hook-form'
import { useLayoutEffect, useState } from 'react'

interface IProps {
    onSubmit:any
    handleClose:any
    // onEdit:any
    edit?:boolean
    data?:any
    loading?:boolean
}
const TabText = ({onSubmit, handleClose, edit, data, loading}:IProps) => {
  const validationSchema = z.object({
    editor: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const [defaultEditor, setDefaultEditor] = useState<any>()
  const {control, setValue, handleSubmit, unregister} = useFormProp
  useLayoutEffect(()=>{
    if(edit && data){
        setValue('editor', data)
        setDefaultEditor(data)
    }
  },[edit, data])
  return (
    <div className='pb-6 d-flex flex-column gap-4'>
      <HookFormEditor math name={'editor'} control={control} defaultValue={defaultEditor}/>
      <div className='d-flex justify-content-end'>
        <ButtonSecondary title='Cancel' className='btn me-5' onClick={()=>handleClose(()=>unregister('editor'))} />
        <ButtonPrimary
          onClick={handleSubmit((e) => onSubmit(e, TYPE_DOCUMENT.TEXT))}
          // className='btn btn-active-primary'
          title='Save Document'
          loading={loading}
        />
      </div>
    </div>
  )
}
export default TabText
