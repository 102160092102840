import React from 'react'

interface IProps {
  required?: boolean
  label: string
  className?: string
  isLabel?: boolean
}

const SappLabel = ({label, required, className, isLabel = false}: IProps) => {
  return (
    <label className={`text-gray-800 ${isLabel ? 'sapp-label fw-bold' : 'sapp-fs-14 fw-bold sapp-mb-10px sapp-line--height'} ${className ?? ''}`}>
      <span className={`${required ? 'required' : ''}`}>{label}</span>
    </label>
  )
}

export default SappLabel
