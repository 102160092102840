import { FC, useEffect } from 'react'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import { ID, KTIcon } from 'src/_metronic/helpers'
import { useListView } from 'src/components/user-list/components/core/ListViewProvider'

type Props = {
  id: ID
}

const NewsActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  return (
    <>
      <div
        className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </div>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <div className='menu-link px-3' onClick={openEditModal}>
            Edit
          </div>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <div
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </div>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { NewsActionsCell }
