import {fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {IResource, IResourceList, ISearchResources} from 'src/type/resource'

export class ResourceAPI {
  static getResource(params: ISearchResources): Promise<IResponse<IResourceList>> {
    return fetcher(`/resources`, {
      params: {...params, parent_id: params.parent_id === null ? 'null' : params.parent_id},
    })
  }

  static getTotalSize(): Promise<any> {
    return fetcher('resources/total-size')
  }

  static deleteResource(resourceIds: string[]): Promise<any> {
    return fetcher('resources/bulk-delete', {
      method: 'POST',
      data: {
        resourceIds: resourceIds,
      },
    })
  }
  static deleteResourceById(resourceId: string): Promise<any> {
    return fetcher(`resources/${resourceId}`, {
      method: 'DELETE',
    })
  }

  static createFolder({
    name,
    parent_id,
    location,
  }: {
    name: string
    parent_id?: string
    location?: string
  }): Promise<any> {
    return fetcher('resources', {
      method: 'POST',
      data: {
        name: name,
        resource_type: 'FOLDER',
        suffix_type: 'FOLDER',
        status: 'CREATED',
        ...(parent_id === 'null' ? {} : {parent_id}),
        location,
      },
    })
  }

  static updateResource(
    name: string | undefined,
    resource_id: string,
    parent_id?: string | null,
    location?: string,
    exist_type?: 'BOTH' | 'OVERRIDE'
  ): Promise<any> {
    return fetcher('resources', {
      method: 'PATCH',
      data: {
        ...(name ? {name: name} : {}),
        resource_id: resource_id,
        ...(parent_id === 'null' ? {} : {parent_id}),
        location,
        exist_type: exist_type || undefined,
      },
    })
  }

  static getResourceDetail(id: string): Promise<IResponse<IResource>> {
    return fetcher(`resources/${id}`)
  }

  static getResourceParents(id: string): Promise<
    IResponse<
      {
        id: string
        name: string
        parent_id: string
      }[]
    >
  > {
    return fetcher(`resources/${id}/parents`)
  }
}
