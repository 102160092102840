import React from 'react'
import { PageLink } from 'src/constants'

const VideoTutorials = () => {
  return (
    <>
      <div className='mb-17'>
        <div className='d-flex flex-stack mb-5'>
          <h3 className='text-dark'>Video Tutorials</h3>

          <a href={PageLink.DASHBOARD} className='fs-6 fw-semibold link-primary'>
            View All Videos
          </a>
        </div>

        <div className='separator separator-dashed mb-9'></div>

        <div className='row g-10'>
          <div className='col-md-4'>
            <div className='card-xl-stretch me-md-6'>
              <a
                className='d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5'
                //  style="background-image:url('assets/media/stock/600x400/img-73.jpg')"
                data-fslightbox='lightbox-video-tutorials'
                href='https://www.youtube.com/embed/btornGtLwIo'
              >
                <img
                  src='assets/media/svg/misc/video-play.svg'
                  className='position-absolute top-50 start-50 translate-middle'
                  alt=''
                />
              </a>

              <div className='m-0'>
                <a
                  href={PageLink.AUTH_LOGIN}
                  className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'
                >
                  Admin Panel - How To Started the Dashboard Tutorial
                </a>

                <div className='fw-semibold fs-5 text-gray-600 text-dark my-4'>
                  We’ve been focused on making a the from also not been afraid to and step away
                  been focused create eye
                </div>

                <div className='fs-6 fw-bold'>
                  <a
                    href={PageLink.AUTH_LOGIN}
                    className='text-gray-700 text-hover-primary'
                  >
                    Jane Miller
                  </a>

                  <span className='text-muted'>on Mar 21 2021</span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='card-xl-stretch mx-md-3'>
              <a
                className='d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5'
                //  style="background-image:url('assets/media/stock/600x400/img-74.jpg')"
                data-fslightbox='lightbox-video-tutorials'
                href='https://www.youtube.com/embed/btornGtLwIo'
              >
                <img
                  src='assets/media/svg/misc/video-play.svg'
                  className='position-absolute top-50 start-50 translate-middle'
                  alt=''
                />
              </a>

              <div className='m-0'>
                <a
                  href={PageLink.AUTH_LOGIN}
                  className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'
                >
                  Admin Panel - How To Started the Dashboard Tutorial
                </a>

                <div className='fw-semibold fs-5 text-gray-600 text-dark my-4'>
                  We’ve been focused on making the from v4 to v5 but we have also not been afraid
                  to step away been focused
                </div>

                <div className='fs-6 fw-bold'>
                  <a
                    href={PageLink.AUTH_LOGIN}
                    className='text-gray-700 text-hover-primary'
                  >
                    Cris Morgan
                  </a>

                  <span className='text-muted'>on Apr 14 2021</span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='card-xl-stretch ms-md-6'>
              <a
                className='d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-175px mb-5'
                // style="background-image:url('assets/media/stock/600x400/img-47.jpg')"
                data-fslightbox='lightbox-video-tutorials'
                href='https://www.youtube.com/embed/TWdDZYNqlg4'
              >
                <img
                  src='assets/media/svg/misc/video-play.svg'
                  className='position-absolute top-50 start-50 translate-middle'
                  alt=''
                />
              </a>

              <div className='m-0'>
                <a
                  href={PageLink.AUTH_LOGIN}
                  className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'
                >
                  Admin Panel - How To Started the Dashboard Tutorial
                </a>

                <div className='fw-semibold fs-5 text-gray-600 text-dark my-4'>
                  We’ve been focused on making the from v4 to v5 but we’ve also not been afraid to
                  step away been focused
                </div>

                <div className='fs-6 fw-bold'>
                  <a
                    href={PageLink.AUTH_LOGIN}
                    className='text-gray-700 text-hover-primary'
                  >
                    Carles Nilson
                  </a>

                  <span className='text-muted'>on May 14 2021</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoTutorials