import {ReactNode} from 'react'
import ButtonDropdown from 'src/components/base/button/ButtonDropdown'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import './SAPPDialogButtonsCancelDropdownSubmit.scss'

interface IProps {
  okOnClick?: () => void
  className?: string
  cancelButtonCaption: string
  okButtonCaption: string
  cancelClick?: () => void
  loading?: boolean
  children: ReactNode
  type?: 'submit' | 'reset' | 'button'
  cancelButton?: ({
    cancelButtonCaption,
    cancelClick,
    loading,
  }: {
    cancelButtonCaption: string
    cancelClick?: () => void
    loading?: boolean
  }) => ReactNode
}

const SAPPDialogButtonsCancelDropdownSubmit = ({
  loading,
  okOnClick,
  cancelClick,
  className,
  cancelButtonCaption,
  okButtonCaption,
  children,
  type = 'button',
  cancelButton,
}: IProps) => {
  return (
    <div className={`text-center ${className ?? ''}`}>
      {!cancelButton ? (
        <ButtonSecondary
          disabled={loading}
          title={cancelButtonCaption}
          onClick={cancelClick}
          type='button'
        />
      ) : (
        <>
          {cancelButton({
            cancelButtonCaption,
            cancelClick,
            loading,
          })}
        </>
      )}

      <ButtonDropdown
        type={type}
        title={okButtonCaption}
        className=''
        onClick={okOnClick}
        loading={loading}
      >
        {children}
      </ButtonDropdown>
    </div>
  )
}

export default SAPPDialogButtonsCancelDropdownSubmit
