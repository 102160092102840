import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTIcon} from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import {TYPE_COURSE} from 'src/constants'
import {useCourseContext} from 'src/context/CourseProvider'
import LinkSection from '../course-link/LinkSection'
import CreateLearningOutCome from '../modals/CreateLearningOutCome'
import CreateSection from '../modals/CreateSection'
import TreeCourse from '../tree/Tree'
import CreateActivity from '../modals/CreateActivity'
import ChooseTypeOfTestModal, {COURSE_TEST_TYPES} from '../../test/ChooseTypeOfTestModal'
import CreateTabs from '../modals/CreateTabs'
import {EmptyCourseInfo} from 'src/common/icons'
import {EStatusCourse, ETypeFoundation} from 'src/type/courses'
import PreviewActivityModal from '../../shared/previews/PreviewActivityModal'
import { Skeleton } from 'antd'
import './CourseContent.scss'

const CourseContent = () => {
  const {courseDetail, getCourseDetail, loading, loadingDelete, course, rawCourseData} =
    useCourseContext()
  const [openChooseTypeOfTestModal, setOpenChooseTypeOfTestModal] = useState<{
    type: string
    open: boolean
    id?: string
  }>({
    type: COURSE_TEST_TYPES.COURSE,
    open: false,
  })
  const {id} = useParams()

  const [open, setOpen] = useState<{
    status: boolean
    data: any
  }>({
    status: false,
    data: undefined,
  })
  const [openCreateSection, setOpenCreateSection] = useState<{
    status: boolean
    data?: any
    type?: string
    id?: string
    path?: any
    treeIndex?: any
  }>({
    status: false,
  })
  const [openCreateLearning, setOpenCreateLearning] = useState<{
    status: boolean
    data?: any
    type?: string
    id?: string
    onlyEditName?: boolean
  }>({status: false})
  const [openCreateTab, setOpenCreateTab] = useState<{
    status: boolean
    course_section_id: string
    edit?: boolean
    path?: any
    nodeData?: any
    onlyEdit?: boolean
  }>({status: false, course_section_id: ''})
  const [openCreateActivity, setOpenCreateActivity] = useState<{
    status: boolean
    data?: any
    id?: string
    lo?: any
    selfId?: string
    path?: any
    onlyEditName?: boolean
  }>({status: false})
  const [previewActivity, setPreviewActivity] = useState<{
    id: string
    open: boolean
  }>()

  const [treeData, setTreeData] = useState<any>()
  const [clickedNode, setClickedNode] = useState<any>()

  useEffect(() => {
    if (!id) return

    getCourseDetail(id)
  }, [id])

  const mockData = new Array(5).fill(0)

  // check xem type của course là practice hay không?
  const typePractice = course?.course_type === ETypeFoundation.PRACTICE

  return (
    <div className='sapp-course-content-tree'>
      {loading || loadingDelete ?
        <>
          <div className='sapp-course-content-tree-first'>
            {mockData.map((number: number) => (
              <Skeleton.Input size='large' active />
            ))}
          </div>
          <div className='sapp-course-content-tree-second'>
            {mockData.map((number: number) => (
              <Skeleton.Input size='large' active />
            ))}
          </div>
        </>
        :
        <>
          {courseDetail && courseDetail.length > 0 ? (
            <TreeCourse
              rawCourseData={rawCourseData}
              template={courseDetail?.template}
              data={courseDetail}
              id={courseDetail.id}
              openCreateLearning={openCreateLearning}
              openCreateSection={openCreateLearning}
              setOpenCreateLearning={setOpenCreateLearning}
              setOpenCreateSection={setOpenCreateSection}
              setOpenCreateTab={setOpenCreateTab}
              setTreeData={setTreeData}
              treeData={treeData}
              setOpenCreateActivity={setOpenCreateActivity}
              setOpenPreviewActivity={setPreviewActivity}
              statusLock={course?.status === EStatusCourse.LOCK}
              clickedNode={clickedNode}
              setClickedNode={setClickedNode}
              typePractice={typePractice}
            />
          ) : (
            <div className='sapp-blank-content'>
              <EmptyCourseInfo />
              <div className='sapp-blank-content-title'>
                Nội dung khóa học chưa được tạo, hãy tạo ngay!
              </div>
            </div>
          )}
          <div className='mt-10'>
            <div className='d-flex justify-content-between w-100'>
              <ButtonIcon
                customButton='border border-1 sapp-btn-color '
                title='Course List'
                link='/courses'
              >
                <KTIcon iconName='arrow-left' iconType='solid' className='fs-1' />
              </ButtonIcon>
              {course?.status !== EStatusCourse.LOCK && (
                <div className='d-flex gap-4'>
                  {
                    typePractice && (
                      <ButtonPrimary
                        title='Link Section'
                        onClick={(e: any) => {
                          e.target.blur()
                          setOpen({ status: true, data: undefined })
                        }}
                        className='bg-light-warning btn-color-warning'
                      />
                    )
                  }
                  <ButtonPrimary
                    title='New Test'
                    onClick={() =>
                      setOpenChooseTypeOfTestModal({
                        type: COURSE_TEST_TYPES.COURSE,
                        open: true,
                      })
                    }
                    className='bg-light-warning btn-color-warning'
                  />
                  <ButtonPrimary
                    title='New Section'
                    onClick={() =>
                      setOpenCreateSection({
                        status: true,
                        data: undefined,
                        type:
                          // courseDetail?.template === 4 ?
                          TYPE_COURSE.SECTION,
                        // : TYPE_COURSE.PARTIALCHAPTER,
                        id: id,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <LinkSection
            open={open.status}
            setOpen={setOpen}
            position={courseDetail?.length}
            body='Create a new section by linking section from other course.'
            title='Link Section'
          />
          {/* <CreateSection open={openCreateSection.status} setOpen={setOpenCreateSection} data={openCreateSection.data} type={'section'}/>÷ */}
          <CreateSection
            open={openCreateSection.status}
            setOpen={setOpenCreateSection}
            data={openCreateSection.data}
            type={openCreateSection.type}
            id={openCreateSection.id}
            path={openCreateSection.path}
            treeData={treeData}
            treeIndex={openCreateSection.treeIndex}
            setData={setTreeData}
            positionPart={treeData?.length}
            positionSubsection={clickedNode?.children?.length}
          />
          <CreateLearningOutCome
            open={openCreateLearning.status}
            setOpen={setOpenCreateLearning}
            course_section_id={openCreateLearning.id || ''}
            type={openCreateLearning.type || ''}
            onlyEditName={openCreateLearning.onlyEditName}
          />
        </>
      }
      {/* <CreateTabs open={openCreateTab} setOpen={setOpenCreateTab} /> */}
      <CreateActivity
        open={openCreateActivity.status}
        setOpen={setOpenCreateActivity}
        id={openCreateActivity.id}
        lo={openCreateActivity.lo}
        selfId={openCreateActivity.selfId}
        setData={setTreeData}
        treeData={treeData}
        path={openCreateActivity.path}
        data={openCreateActivity.data}
        position={clickedNode?.children?.length}
        onlyEditName={openCreateActivity.onlyEditName}
      />
      {previewActivity?.id && (
        <PreviewActivityModal
          previewActivity={previewActivity}
          setPreviewActivity={setPreviewActivity}
        ></PreviewActivityModal>
      )}
      <ChooseTypeOfTestModal
        open={openChooseTypeOfTestModal}
        setOpen={setOpenChooseTypeOfTestModal}
      ></ChooseTypeOfTestModal>
      <CreateTabs
        open={openCreateTab.status}
        setOpen={setOpenCreateTab}
        course_section_id={openCreateTab.course_section_id}
        edit={openCreateTab.edit}
        setTreeData={setTreeData}
        path={openCreateTab.path}
        treeData={treeData}
        nodeData={openCreateTab.nodeData}
        onlyEdit={openCreateTab.onlyEdit}
      />
      {/* <CreateActivity open={openCreateActivity.status} setOpen={setOpenCreateActivity}  id={openCreateActivity.id} lo={openCreateActivity.lo}/> */}
    </div>
  )
}
export default CourseContent
