import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import { IStudents } from 'src/type/students'
import { Link } from 'react-router-dom'
import { STUDENT_PROFILE } from 'src/constants'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import useChecked from 'src/hooks/use-checked'
import UserListGrouping from 'src/components/user-management/UserListGrouping'
import { useConfirm } from 'src/hooks/use-confirm'
import { StudentActions } from './StudentActions'
import { ClassesApi } from 'src/apis/classes'
import { useParams } from 'react-router-dom'
import ModalImportClassStudent from '../../ModalImportClassStudent'
import EditStudentsInClass from './EditStudentsInClass'
import { format } from 'date-fns'
import ModalProcessing from '../../modalProcessing'
import { toast } from 'react-hot-toast'
import UserStatusCell from './UserStatusCell'
import UpdateUserDuration from './UpdateUserDuration'
import { cleanParamsAPI } from 'src/utils'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import SappTable from 'src/components/base/SappTable'
import LoadingTable from 'src/common/LoadingTable'
import { round } from 'lodash'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { Role } from 'src/type'

const headers = [
  {
    label: 'Id',
    key: 'id',
    className: 'sapp-flex-1 fs-7 lh-1 fw-bold min-w-70px',
  },
  {
    label: 'Student',
    key: 'student',
    className: 'fs-7 lh-1 fw-bold min-w-200px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'fs-7 lh-1 fw-bold min-w-250px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 lh-1 fw-bold w-150px',
  },
  {
    label: 'Duration',
    key: 'duration',
    className: 'fs-7 lh-1 fw-bold w-200px',
  },
  {
    label: 'Progress',
    key: 'progress',
    className: 'fs-7 lh-1 fw-bold w-100px',
  },
]

interface IProps {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  classDetail: any | undefined
  setClassDetail: Dispatch<SetStateAction<any>>
}

const ListStudent = ({ loading, setLoading, classDetail }: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [user, setUser] = useState<any>()
  const [userSearch, setUserSearch] = useState<any>()
  const [openBlocked, setOpenBlocked] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const { contextHolder, confirm } = useConfirm()
  const { id } = useParams()
  const [loadingStudent, setLoadingStudent] = useState(false)
  const { profileMe } = useUserContext()
  const allowRenderImportStudent = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_CLASS_GR.IMPORT_STUDENT_IN_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const allowRenderAddStudent = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const [openProcess, setOpenProcess] = useState<{
    status: boolean
    type?: string
    id?: any
    studentId?: string
  }>({ status: false })
  const [courseId, setCourseId] = useState()
  const [exceptedSections, setExceptedSections] = useState([])
  const [isEnded, setIsEnded] = useState<boolean>(false)
  const [openUpdateDuration, setOpenUpdateDuration] = useState<{
    status: boolean
    studentId?: string
    data?: any
  }>({
    status: false,
  })
  const [totalUser, setTotalUser] = useState<number>(0)
  const [normalClassConnections, setNormalClassConnections] = useState<number>(0)
  const [shouldRender, setShouldRender] = useState(false);

  const getParams = (text: string) => ({
    text,
  })

  const initialValues: any = {
    text: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const getClassStudents = async (page_index?: number, page_size?: number, params?: Object) => {
    setLoadingStudent(true)
    setTimeout(async () => {
      try {
        const response = await ClassesApi.getClassStudent({
          class_id: id,
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: params,
        });

        if (response) {
          const getListUser = response?.data?.students.map((item: any) => {
            return item.user;
          });

          setUser(response?.data);
          setUserSearch(getListUser);
          setTotalUser(response?.data?.meta?.total_records);
          setNormalClassConnections(response?.data?.normal_class_connections?.length || 0);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingStudent(false);
      }
    }); // Delay of 150 milliseconds
  }

  const deleteClassStudents = async (id?: string | number, data?: Object) => {
    setLoading(true)
    try {
      const res = await ClassesApi.deleteStudentInClass({
        user: data,
        classId: id,
      })
      if (res) {
        toast.success('Delete Successfully!')
        getClassStudents()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassStudents(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    getClassStudents()
      .then(() => {
        setShouldRender(true);
      })
      .catch((error) => {
        console.error('API call failed:', error);
      });

    setCourseId(classDetail?.course_id)
    setExceptedSections(classDetail?.excepted_course_section)
    setIsEnded(classDetail?.status === 'ENDED' ? true : false)
  }, [])

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<IStudents>(userSearch)

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleSearchChange = (e: any) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassStudents(1, user?.meta?.page_size || 10, cleanedParams)
  }

  const blockUser = () => {
    setOpenBlocked(false)
    toggleCheckAll(!listDataChecked)
    deleteClassStudents(id, checkedList)
  }

  const Action = (e: string, studentId: string, data: any) => {
    if (e === 'course') {
      setOpenProcess({ status: true, type: 'course-content', id: courseId, studentId: studentId })
    }
    if (e === 'duration') {
      setOpenUpdateDuration({ status: true, studentId: studentId, data: data })
      return
    }
  }
  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10 flex sapp-min-h-100vh'>
        <div className='py-8 px-10 '>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${totalUser ? ' mb-5' : ''
              }`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search Student'}
                smallSearch={true}
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearchActive()
                }}
                className='sapp-btn-active-search sapp-font-import-student ms-4'
                loading={loading}
              />
            </div>
            <>
              {shouldRender && !isEnded && checkedList.length > 0 ? (
                <div className='sapp-selected-user'>
                  <UserListGrouping
                    okButtonCaption='Yes'
                    cancelButtonCaption='No'
                    body='Bạn có chắc chắn muốn xóa không?'
                    selected={checkedList}
                    blockUser={blockUser}
                    openBlocked={openBlocked}
                    setOpenBlocked={setOpenBlocked}
                    title={'Delete Selected'}
                    loading={loading}
                  />
                </div>
              ) : (
                <>
                  {!isEnded && shouldRender && (
                    <div className='d-flex justify-content-between'>
                      <ModalImportClassStudent
                        open={openUpload}
                        setOpen={setOpenUpload}
                        id={id}
                        getClassStudents={getClassStudents}
                      />
                      {allowRenderImportStudent && (
                        <ButtonIcon
                          title={'Import Student'}
                          className='w-100 mx-xl-5 btn btn-primary btn-sapp-filter me-3 px-3 py-3 sapp-font-import-student sapp-color-import-student'
                          onClick={() => setOpenUpload(true)}
                        >
                          <KTIcon
                            iconName='file-up'
                            className='fs-3 pe-3 sapp-color-import-student'
                            iconType='outline'
                          />
                        </ButtonIcon>
                      )}
                      {allowRenderAddStudent && (
                        <ButtonIcon
                          title='Add Student'
                          className='w-xl-auto text-nowrap px-3 py-3 sapp-font-import-student'
                          onClick={handleOnClick}
                        >
                          <KTIcon iconName='plus' className='fs-2' />
                        </ButtonIcon>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          </div>
          <h5 className='fw-bold fs-6 mt-8'>
            Danh sách học viên ({totalUser || 0})
            {(totalUser || 0) > Number(classDetail?.capacity) && (
              <span className='ms-5 text-danger'>
                * Số học viên đã vượt capacity của lớp {totalUser}/{Number(classDetail?.capacity)}
              </span>
            )}
          </h5>
          <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
            <div className='container'>
              <div className='row px-4 py-8 sapp-overflow-y-layout'>
                <SappTable
                  headers={headers}
                  loading={loadingStudent}
                  data={user?.students}
                  isCheckedAll={isCheckedAll}
                  disabled={isEnded}
                  onChange={() => {
                    toggleCheckAll(!isCheckedAll, true)
                  }}
                >
                  {loading ? (
                    <>
                      {headers.map((header, i) => (
                        <LoadingTable key={header.label} headers={headers} />
                      ))}
                    </>
                  ) : (
                    <>
                      {user?.students?.map((item: any, i: number) => {
                        const isChecked = checkedList.includes(item?.user_id)
                        return (
                          <tr className='border-0' key={item?.user_id ?? i}>
                            <td>
                              <SAPPCheckbox
                                checkTarget='#kt_table_users .form-check-input'
                                checked={isChecked}
                                ktCheck={isChecked}
                                onChange={() => {
                                  toggleCheck(item?.user_id!)
                                }}
                                disabled={isEnded}
                              />
                            </td>
                            <td className='fs-6 lh-1 fw-semibold sapp-item-column'>
                              {item?.user?.key}
                            </td>
                            <td>
                              <Link
                                className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                                to={`${STUDENT_PROFILE}/${item?.user_id}/overview`}
                              >
                                {item?.user?.detail?.full_name}
                              </Link>
                              <UserStatusCell status={item?.type} />
                            </td>
                            <td>{item?.user?.user_contacts?.[0]?.email}</td>
                            <td>{item?.user?.user_contacts?.[0]?.phone}</td>
                            <td>
                              {item?.flexible_duration &&
                                item?.started_at === null &&
                                item?.finished_at === null ? (
                                <>
                                  {`${item?.flexible_duration} ${item?.flexible_duration > 1 ? 'days' : 'day'
                                    }`}
                                </>
                              ) : (
                                <>
                                  <span>
                                    {(() => {
                                      if (item?.started_at === null) {
                                        return ''
                                      }
                                      const date = new Date(item?.started_at)
                                      return `${format(date, 'dd/MM/yyyy')}`
                                    })() ?? ''}
                                  </span>
                                  <span> - </span>
                                  <span>
                                    {(() => {
                                      if (item?.finished_at === null) {
                                        return ''
                                      }
                                      const date = new Date(item?.finished_at)
                                      return `${format(date, 'dd/MM/yyyy')}`
                                    })() ?? ''}
                                  </span>
                                </>
                              )}
                            </td>
                            {item?.is_passed ? (
                              <td
                                onClick={() => {
                                  setOpenProcess({
                                    status: true,
                                    id: id,
                                    studentId: item?.user_id,
                                    type: 'process',
                                  })
                                }}
                                className='sapp-color-percent cursor-pointer'
                              >
                                {`${round(
                                  (item?.learning_progress?.total_course_sections_completed /
                                    item?.learning_progress?.total_course_sections) *
                                  100,
                                  2,
                                ) || '0'
                                  }%`}
                              </td>
                            ) : (
                              <td>Foundation</td>
                            )}
                            <td className='text-end'>
                              <StudentActions
                                id={item?.user_id}
                                listUsers={user?.students}
                                setUser={setUser}
                                action={Action}
                                classId={id}
                                getClassStudents={getClassStudents}
                                user={item}
                                confirm={confirm}
                                isPassed={item?.is_passed}
                                isEnded={isEnded}
                                normalClassConnections={normalClassConnections}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                </SappTable>
                <PagiantionSAPP
                  currentPage={user?.meta?.page_index || 1}
                  pageSize={user?.meta?.page_size || 10}
                  totalItems={totalUser}
                  handlePaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
          <EditStudentsInClass
            open={open}
            setOpen={setOpen}
            type='STUDENT'
            getClassStudents={getClassStudents}
          />
          <UpdateUserDuration
            open={openUpdateDuration.status}
            setOpen={setOpenUpdateDuration}
            data={openUpdateDuration?.data}
            studentId={openUpdateDuration?.studentId}
            getClassStudents={() => getClassStudents()}
          />
          <ModalProcessing
            open={openProcess.status}
            setOpen={setOpenProcess}
            type={openProcess.type}
            title={openProcess.type === 'process' ? 'Learning Progress' : 'Edit Course Content'}
            id={openProcess.id}
            exceptedSections={exceptedSections}
            refetch={() => getClassStudents()}
            studentId={openProcess.studentId}
            classId={id}
            isEnded={isEnded}
          />
        </div>
      </div>
    </>
  )
}

export default ListStudent
