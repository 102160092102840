import { format } from 'date-fns'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import {FORMAT_DATETIME, MY_PROFILE, TEACHER_PROFILE} from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import {IStudentDetail} from 'src/type/students'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const TeacherProfileDetail = ({teacherDetail, loading, setTeacherDetail}: IProps) => {
  const {id} = useParams()
  const {getStudentDetail} = useUserContext()


  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id).then((res) => setTeacherDetail(res?.data))
  }, [id])

  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)

  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Profile Details</h3>
            </div>

            <Link
              to={`${TEACHER_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
              className='btn btn-sm btn-primary align-self-center'
            >
              Edit Profile
            </Link>
          </div>

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={teacherDetail?.key} />
            <ItemProfile title={LANG_PLACEHOLDER.FULLNAME} body={teacherDetail?.detail?.full_name} />
            <ItemProfile title='Username' body={teacherDetail?.username} />
            <ItemProfile title='Email' body={teacherDetail?.detail?.email ?? userContact?.email} />
            <ItemProfile title='Phone Number' body={teacherDetail?.detail?.phone ?? userContact?.phone} />
            <ItemProfile title='Status' status={teacherDetail?.status} />
            <ItemProfile title='Gender' body={teacherDetail?.detail?.sex=== 'MALE' ? 'Male':teacherDetail?.detail?.sex === 'FEMALE'?'Female':teacherDetail?.detail?.sex === 'OTHERS'? 'Others' : '-'} />
            <ItemProfile title='Updated At' body={(teacherDetail?.updated_at ? format(new Date(teacherDetail?.updated_at), FORMAT_DATETIME) : '')} />
          </div>
        </div>
      )}
    </>
  )
}

export default TeacherProfileDetail
