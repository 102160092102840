import {zodResolver} from '@hookform/resolvers/zod'
import {Fragment, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTIcon} from 'src/_metronic/helpers'
import {ResourceAPI} from 'src/apis/resource-bank'
import {ResourcesAPI} from 'src/apis/resources'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import {BUTTON_TEXT} from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import {IResource, IResourceList, ISearchResources, defaultSearchResources} from 'src/type/resource'
import {truncateString} from 'src/utils/string'
import {z} from 'zod'
import ButtonIcon from '../base/button/ButtonIcon'
import ListGrouping from '../base/list-grouping/ListGrouping'
import HookFormTextField from '../base/textfield/HookFormTextField'
import CardResource from './CardResource'
import ResourceVideoSetting from './ResourceUploadFile/ResourceVideoSetting'
import TableResource from './TableResource'
import {IResponse} from 'preview-activity/dist/shared/interfaces'
import VideoSetting from '../courses/course-detail/create-tabs/tabVideo/videoSetting'
import {useUserContext} from 'src/context/UserProvider'
import {CODE_ADMIN, TITLE_RESOURCES_GR} from 'src/constants/permission'
import {Role} from 'src/type'

function FolderList() {
  // TODO: state của list resource
  const [resourceList, setResourceList] = useState<IResourceList>()
  // TODO: state để lấy tổng items và tổng size của file
  const [totalSize, setTotalSize] = useState<any>()
  // TODO: state này để  set trạng thái loading
  const [loading, setLoading] = useState(false)
  // TODO: dùng để lấy số item đã được check ở checkbox
  const {checkedList, toggleCheck, toggleCheckAll, isCheckedAll, listDataChecked} = useChecked<any>(
    resourceList?.resources
  )
  const [openVideoSetting, setOpenVideoSetting] = useState<{
    status: boolean
    resource_id?: string
    queryPrams?: ISearchResources
    fileBreadcrumb?: {
      name: string
      id: string
      parent_id: string
    }[]
    isEdit?: boolean
  }>({
    status: false,
  })

  // TODO: state dùng để đóng mở trạng thái tạo folder mới
  const [newFolder, setNewFolder] = useState(false)
  const navigate = useNavigate()
  const [resourceId, setResourceId] = useState('')
  const {profileMe} = useUserContext()
  const allowRenderCreateResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.CREATE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDownloadResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.DOWNLOAD_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderRemoveResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.REMOVE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const [fileBreadcrumb, setFileBreadcrumb] = useState<
    {name: string; id: string; parent_id: string}[]
  >([])

  // TODO: state này để mở popup confirm
  const {confirm, contextHolder} = useConfirm()

  const {search} = useLocation()

  // TODO: hàm này để gọi API để lấy danh sách resource
  const gotoPage = async (params: ISearchResources) => {
    const queryParams: {[key: string]: any} = handleGetQueryParams()

    const filteredParams = Object.keys(defaultSearchResources).reduce((previousValue, key) => {
      if (params[key as keyof ISearchResources] === undefined) {
        previousValue[key] = queryParams?.[key]?.toString().trim() || undefined
      } else {
        previousValue[key] = params?.[key as keyof ISearchResources]?.toString().trim()
      }
      return previousValue
    }, {} as {[key: string]: any})

    if (params.page_index === 1) {
      filteredParams.page_index = 1
    }
    handleChangeParams({
      ...filteredParams,
      load_time: Date.now().toString(),
    })
  }

  const fetchGetResource = async () => {
    setLoading(true)
    const params = handleGetQueryParams()
    const {name, load_time, ...other} = params
    try {
      const response = await getResource({...other})
      if (response.success) {
        setResourceList(response?.data)
        await getLocation(params.parent_id || 'null')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getResource = async (params: ISearchResources): Promise<IResponse<IResourceList>> => {
    try {
      const response = await ResourceAPI.getResource({...params, dateField: 'updated_at'})
      if (
        response.data?.meta?.total_pages &&
        params.page_index &&
        params.page_index > 1 &&
        params.page_index > response.data.meta.total_pages &&
        !response.data?.resources?.[0]
      ) {
        return await getResource({...params, page_index: response.data.meta.total_pages || 1})
      }
      return response
    } catch (error) {
      throw error
    }
  }

  const getLocation = async (parent_id?: string) => {
    if (parent_id && parent_id !== 'null') {
      try {
        const response = await ResourceAPI.getResourceParents(parent_id)
        if (response.success) {
          setFileBreadcrumb(response.data?.reverse())
        }
      } catch (error) {}
    } else {
      setFileBreadcrumb([])
    }
  }

  // TODO: hàm này để lấy tổng size
  const fetchGetTotalSize = async () => {
    await ResourceAPI.getTotalSize().then((res) => setTotalSize(res?.data))
  }

  const deleteResource = async (id: string[]) => {
    try {
      const response = await ResourceAPI.deleteResource(id).finally(() => {
        gotoPage({})
      })
      if (response?.[0]) {
        response.forEach((e: {error: string; id: string; name: string}) => {
          toast.error('Resource: ' + truncateString(e.name, 20) + " can't delete!")
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      toggleCheckAll(false)
    }
  }

  const downloadResources = async (resource: IResource[]) => {
    let hasFolder = false
    const files = resource?.reduce((previousValue, current) => {
      if (current.resource_type === 'FILE') {
        const file = {
          name: current.name || '',
          file_key: current.file_key || '',
        }
        previousValue.push(file)
      } else {
        hasFolder = true
      }
      return previousValue
    }, [] as {name: string; file_key: string}[])

    if (!files?.[0]) {
      toast.error('You have not selected any files!')
    }
    if (hasFolder) {
      toast.error('Dạng folder sẽ không được download!')
    }
    try {
      ResourcesAPI.downloadFile({files})
      toggleCheckAll(false)
    } catch (error: any) {
      console.log(error)
    }
  }

  // Validate for input
  const validationSchema = z.object({
    search_key: z.string().optional(),
  })

  // Using validate for input
  const {control, reset, getValues, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  // TODO: hàm này dùng để đóng folder
  const handleCloseCreateFolder = () => {
    setNewFolder(false)
  }

  useEffect(() => {
    setValue('search_key', '')
    fetchGetTotalSize()
    gotoPage({})
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const search_key = searchParams.get('search_key') as string
    setValue('search_key', search_key || '')
    fetchGetResource()
  }, [search])

  const handleChangeParams = (params: ISearchResources) => {
    let url = new URL(window.location.href)
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        url.searchParams.delete(key)
      } else {
        url.searchParams.set(key, value)
      }
    })
    if (url.search) {
      navigate(url.search)
    } else {
      navigate('')
    }
  }
  const handleGetQueryParams = () => {
    const searchParams = new URLSearchParams(search)

    const queryParams = Object.keys(defaultSearchResources).reduce((previousValue, key) => {
      const value = searchParams.get(key)
      if (
        (value === undefined || value === null) &&
        key !== 'page_index' &&
        key !== 'page_size' &&
        key !== 'parent_id'
      ) {
        return previousValue
      }
      if (key === 'page_index') {
        previousValue[key] = parseInt(value as string) || 1
      } else if (key === 'page_size') {
        previousValue[key] = parseInt(value as string) || 10
      } else if (key === 'parent_id') {
        ;(previousValue[key as keyof ISearchResources] as any) = value || 'null'
      } else {
        ;(previousValue[key as keyof ISearchResources] as any) = value
      }

      return previousValue
    }, {} as ISearchResources)

    return queryParams
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    gotoPage({page_index, page_size})
  }

  const onSubmit = async () => {
    await gotoPage({search_key: getValues('search_key')?.trim() || '', page_index: 1})
  }

  const onReset = async () => {
    reset()
    setValue('search_key', '')
    gotoPage(defaultSearchResources)
    toggleCheckAll(false)
  }

  const handleUploadFile = () => {
    setOpenVideoSetting({
      status: true,
      queryPrams: handleGetQueryParams(),
      fileBreadcrumb,
      isEdit: false,
    })
  }
  const filesChecked = useMemo(() => {
    return listDataChecked.reduce(function (accumulator, currentValue) {
      // Kiểm tra nếu phần tử hiện tại có resource_type là 'FILE'
      if (currentValue.resource_type === 'FILE') {
        // Thêm id của phần tử hiện tại vào mảng tích lũy
        accumulator.push(currentValue.id)
      }
      // Trả về mảng tích lũy cho lần lặp tiếp theo
      return accumulator
    }, [])
  }, [listDataChecked])

  const handleEditResourceVideo = (resource: IResource) => {
    setOpenVideoSetting({
      status: true,
      resource_id: resource.id,
      queryPrams: handleGetQueryParams(),
      isEdit: true,
    })
  }
  return (
    <>
      {contextHolder}
      <div>
        <CardResource totalSize={totalSize} />
        <div className='card card-flush'>
          <div className='card-header pt-10'>
            <div>
              <div className='d-flex align-items-center position-relative my-0'>
                <HookFormTextField
                  name='search_key'
                  placeholder='Search'
                  control={control}
                  className='sapp-min-width-285'
                  defaultValue={''}
                />
              </div>
            </div>

            <div>
              <div className='d-flex justify-content-end'>
                {checkedList?.length > 0 ? (
                  <>
                    {allowRenderRemoveResources && (
                      <div className='me-3'>
                        <ListGrouping
                          selected={checkedList}
                          okClick={() => deleteResource(checkedList)}
                          title='Delete'
                          body='Are you sure you want to delete selected files or folders?'
                          okButtonCaption='Yes, delete!'
                        />
                      </div>
                    )}
                    {allowRenderDownloadResources && (
                      <div className='me-3'>
                        {filesChecked?.[0] && (
                          <ListGrouping
                            showSelected={false}
                            type='primary'
                            selected={filesChecked}
                            okClick={() => downloadResources(listDataChecked)}
                            title='Download'
                            body='Are you sure you want to delete selected files or folders?'
                            okButtonCaption='Yes, delete!'
                            isConfirm={false}
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {allowRenderCreateResources && (
                      <div>
                        <ButtonIcon
                          className='btn btn-flex btn-light-primary me-3'
                          title='New Folder'
                          onClick={() => {
                            setNewFolder(true)
                            setResourceId('')
                          }}
                        >
                          <KTIcon
                            iconName='add-folder'
                            iconType='outline'
                            className='fs-2'
                          ></KTIcon>
                        </ButtonIcon>
                        <ButtonIcon
                          className='btn btn-flex btn-primary'
                          title='Upload Files'
                          onClick={handleUploadFile}
                        >
                          <KTIcon iconName='folder-up' iconType='outline' className='fs-2'></KTIcon>
                        </ButtonIcon>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='card-header border-0 pt-5 px-10'>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-4 col-lg-9 col-xl-4 px-xl-3 px-md-0'>
                  <SAPPFIlterButton
                    titleReset='Reset'
                    okClick={onSubmit}
                    resetClick={onReset}
                    titleSubmit={BUTTON_TEXT.SEARCH}
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card-body p-10'>
            <div className='d-flex flex-stack'>
              <div className='badge badge-lg badge-light-primary'>
                <div className='d-flex align-items-center flex-wrap'>
                  <KTIcon
                    iconName='abstract-32'
                    iconType='outline'
                    className='fs-2 text-primary me-3'
                  ></KTIcon>
                  <div
                    className='sapp-cursor-pointer'
                    onClick={() => {
                      gotoPage(defaultSearchResources)
                    }}
                  >
                    SAPP
                  </div>

                  {fileBreadcrumb?.map((e) => {
                    return (
                      <Fragment key={e.id}>
                        <KTIcon
                          iconName='right'
                          iconType='outline'
                          className='fs-2 text-primary mx-1'
                        ></KTIcon>
                        <div
                          className='sapp-cursor-pointer'
                          onClick={() => {
                            gotoPage({parent_id: e.id, name: e.name})
                          }}
                        >
                          {truncateString(e.name || '', 30)}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
              <div className='badge badge-lg badge-primary'>
                <span id='kt_file_manager_items_counter'>
                  {resourceList?.meta?.total_records} items
                </span>
              </div>
            </div>

            <TableResource
              loading={loading}
              resourceList={resourceList}
              isCheckedAll={isCheckedAll}
              toggleCheckAll={toggleCheckAll}
              newFolder={newFolder}
              handleCloseCreateFolder={handleCloseCreateFolder}
              checkedList={checkedList}
              toggleCheck={toggleCheck}
              confirm={confirm}
              deleteResource={deleteResource}
              handlePaginationChange={handlePaginationChange}
              setLoading={setLoading}
              setNewFolder={setNewFolder}
              goToPage={gotoPage}
              resourceId={resourceId}
              setResourceId={setResourceId}
              handleEditResourceVideo={handleEditResourceVideo}
              fileBreadcrumb={fileBreadcrumb}
            />
          </div>
        </div>
      </div>
      {openVideoSetting.isEdit ? (
        <VideoSetting
          open={openVideoSetting.status}
          setOpen={setOpenVideoSetting}
          resource_id={openVideoSetting.resource_id}
        ></VideoSetting>
      ) : (
        <ResourceVideoSetting
          open={openVideoSetting}
          setOpen={setOpenVideoSetting}
          gotoPage={gotoPage}
        ></ResourceVideoSetting>
      )}
    </>
  )
}

export default FolderList
