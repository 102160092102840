import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {DictionariesAPI} from 'src/apis/dictionary'
import {KTIcon} from 'src/_metronic/helpers'
import {PlayCircleOutlined} from '@ant-design/icons'
import {isEmpty} from 'lodash'
import NotData from 'src/components/NotData'
import {Skeleton} from 'antd'

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  setTitleModal: Dispatch<SetStateAction<string>>
}

const headers = [
  {
    label: 'Thuật ngữ',
    className: 'min-w-30px',
  },
  {
    label: 'Dịch nghĩa',
    className: 'text-start pe-3 min-w-100px',
  },
  {
    label: 'Phiên âm',
    className: 'text-start pe-3 min-w-150px',
  },
  {
    label: 'Diễn giải',
    className: 'text-start pe-3 min-w-100px',
  },
  {
    label: 'Phát âm',
    className: 'text-start pe-3 min-w-100px',
  },
  {
    label: 'Action',
    className: 'text-start pe-0 min-w-75px',
  },
]

const TableList = ({setTitleModal, setOpen}: IProps) => {
  const [dictionaries, setDictionaries] = useState<any>()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)

  // const handlePaginationChange = (page: number, pageSize: number) => {
  //   setCurrentPage(page)
  //   setPageSize(pageSize)
  // }

  // function API get dictionaries
  const getDictionaries = useCallback(() => {
    DictionariesAPI.getDictionary(currentPage, pageSize)
      // TODO: write interface not using any
      .then((res: any) => {
        setDictionaries(res)
        setLoading(true)
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [currentPage, pageSize])

  // call API get dictionary when change currentPage, pageSize, loading
  useEffect(() => {
    getDictionaries()
  }, [currentPage, getDictionaries, pageSize, loading])

  // function play audio
  const playSound = (sound: string) => {
    if (!sound) return
    var audio = new Audio(sound)
    audio.play()
  }

  return (
    <div className='card-body'>
      <div className='table-responsive'>
        {/* start:: table */}
        <table className='table align-middle table-row-dashed fs-6 gy-3'>
          <thead>
            {/* start:: header table */}
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              {loading ? (
                <>
                  {headers.map((header) => (
                    <th key={header.label} className={header.className}>
                      <Skeleton.Input size='large' active />
                    </th>
                  ))}
                </>
              ) : (
                <>
                  {headers.map((header) => (
                    <th key={header.label} className={header.className}>
                      {header.label}
                    </th>
                  ))}
                </>
              )}
            </tr>
            {/* end:: header table */}
          </thead>

          {/* start:: body table */}
          <tbody className='fw-bold text-gray-600'>
            {loading ? (
              <>
                {headers.map((header) => (
                  <tr key={header.label}>
                    <td>
                      <Skeleton.Input size='large' active />
                    </td>
                    <td>
                      <Skeleton.Input size='large' active />
                    </td>
                    <td>
                      <Skeleton.Input size='large' active />
                    </td>
                    <td>
                      <Skeleton.Input size='large' active />
                    </td>
                    <td>
                      <Skeleton.Input size='large' active />
                    </td>
                    <td>
                      <Skeleton.Input size='large' active />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                {dictionaries?.data?.map((dictionary: any) => (
                  <tr key={dictionary.id}>
                    <td>{dictionary.word}</td>
                    <td className='text-start'>{dictionary.mean}</td>
                    <td className='text-start'>{dictionary.pronunciation}</td>
                    <td className='text-start'>{dictionary.description}</td>
                    <td className='text-start'>
                      {dictionary.audioUrl ? (
                        <div onClick={() => playSound(dictionary.audioUrl)}>
                          <PlayCircleOutlined rev={""} />
                        </div>
                      ) : (
                        <>No file</>
                      )}
                    </td>
                    <td className='text-start' data-order='58'>
                      <div
                        className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        Actions
                        <KTIcon iconName='down' className='fs-5 m-0' />
                      </div>
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        data-kt-menu='true'
                      >
                        <div className='menu-item px-3'>
                          <div
                            className='menu-link px-3'
                            data-bs-toggle='modal'
                            data-bs-target='#add_dictionary'
                            onClick={() => {
                              setTitleModal('Edit Dictionary')
                              setOpen(true)
                            }}
                          >
                            Edit
                          </div>
                        </div>
                        <div className='menu-item px-3'>
                          <div className='menu-link px-3' data-kt-users-table-filter='delete_row'>
                            Delete
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
          {/* end:: body table */}
        </table>
        {/* end:: table */}

        {/* start:: show message when dictionary empty */}
        {isEmpty(dictionaries?.data) && !loading && <NotData />}
        {/* end:: show message when dictionary empty */}
      </div>

      {/* start:: show pagination */}
      {/* {!isEmpty(dictionaries?.data) && (
        <SappPagination
          className='justify-content-end mt-3'
          currentPage={currentPage}
          handlePaginationChange={handlePaginationChange}
          pageSize={pageSize}
          totalItems={dictionaries?.paging?.totalItems}
        />
      )} */}
      {/* end:: show pagination */}
    </div>
  )
}

export default TableList
