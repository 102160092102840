import React, { Dispatch, SetStateAction, useState} from 'react'
import {KTIcon} from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import {htmlToRaw} from 'src/utils'
import {ITopic} from 'src/type/question-bank'
import ImportTopic from './import-topic/ImportTopic'
import {PageLink} from 'src/constants'
import {Link} from 'react-router-dom'
import {TopicActions} from './TopicActions'
import './TopicList.scss'
import NotData from '../NotData'
import {StoryAPI} from 'src/apis/story'
import {toast} from 'react-hot-toast'
import {debounce} from 'lodash'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { LANG_SIDEBAR } from 'src/constants/lang'

const headers = [
  {
    label: 'Item Set Name',
    key: 'topic_name',
    className: 'min-w-150px',
  },
  {
    label: 'Topic Description',
    key: 'topic_description',
    className: 'min-w-250px',
  },
  {
    label: 'Questions',
    key: 'questions',
    className: 'min-w-50px text-center',
  },
  {
    label: 'Action',
    key: 'action',
    className: 'text-end w-35px',
  },
]

type Props = {
  title?: string
  topic?: any
  setTopic: React.Dispatch<React.SetStateAction<ITopic[] | undefined>>
  confirm: (option: any) => void
  handleChooseQuestions?: () => void
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  caseStudyId: string
}

const TableTopic = ({title, topic, setTopic, currentPage, pageSize, setCurrentPage, setPageSize, caseStudyId}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { profileMe } = useUserContext()
  const allowRenderAddTopic = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) || role.code === CODE_ADMIN.SUPER_ADMIN);

  const getTopicDetail = async (params: Object) => {
    try {
      const {data} = await StoryAPI.getStoryById(caseStudyId, 1, pageSize, params) 
      setTopic(data)
    } catch (error) {
      toast.error('Topics not found!')
    }
  }

  const updateTopics = async (data: any, update: boolean) => {
    try {
      const res = await StoryAPI.updateTopics(caseStudyId, data)
      await getTopicDetail({})
      toast.success(update ? 'Update Successfully' : 'Delete Successfully!')
    } catch (error) {
    }
  }

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleSearchChange = debounce((e: any) => {
    const cleanedParams = {topic_name: e.target.value.toLowerCase()}
    getTopicDetail({topic_name: e.target.value.toLowerCase().trim()})
  }, 500)

  return (
    <div>
      <div className='mb-5'>
        <div className='p-10'>
          <div className={`d-flex justify-content-between align-items-center gap-5 sapp-mb-24px`}>
            <div className='sapp-flex-1'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search Topic'}
                smallSearch={true}
              ></Search>
            </div>
            {allowRenderAddTopic && (
               <ButtonIcon
               title={LANG_SIDEBAR.addItemSet}
               className='w-xl-auto text-nowrap px-3 py-3'
               onClick={handleOnClick}
             >
               <KTIcon iconName='plus' className='fs-2' />
             </ButtonIcon>
            )}
          </div>
          <h5 className='fw-bold fs-6 sapp-mb-10px'>
            {title} ({topic?.metadata?.total_records || 0})
          </h5>
          <div className='card card-flush border-gray-300 h-xl-100 mt-3'>
            <div className='container'>
              <div className='row p-5'>
                <div className='table-responsive'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed fs-6 gy-4 dataTable no-footer'>
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
                          <th className='w-40px'>#</th>
                          {headers?.map((column) => (
                            <th key={column.label} className={column.className}>
                              {column.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {(() => {
                          if (!topic?.metadata?.total_records) {
                            return (
                              <tr>
                                <td colSpan={7}>
                                  <NotData />
                                </td>
                              </tr>
                            )
                          }
                          return topic?.data?.topics?.map((item: any, i: any) => {
                            const totalQuestions =
                              parseInt(item?.num_of_multiple_choice_questions ?? 0) +
                              parseInt(item?.num_of_constructed_questions ?? 0)
                            return (
                              <tr className='border-0' key={item?.question_topic_id}>
                                <td className='lh-1 fw-semibold sapp-item-column'>{(currentPage - 1) * pageSize + i + 1}</td>
                                <td>
                                  <Link
                                    className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                                    to={`${PageLink.TOPIC}/${item?.question_topic_id}`}
                                  >
                                    {item?.question_topic?.name}
                                  </Link>
                                </td>
                                <td>
                                  <div className='text-truncate mw-500px sapp-item-column'>
                                    {htmlToRaw(item?.question_topic.description)}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-truncate mw-500px sapp-item-column d-flex justify-content-center'>
                                    {totalQuestions}
                                  </div>
                                </td>
                                <td>
                                  <TopicActions
                                    id={item?.question_topic_id}
                                    updateTopics={updateTopics}
                                  />
                                </td>
                              </tr>
                            )
                          })
                        })()}
                      </tbody>
                    </table>
                  </div>
                </div>
                <PagiantionSAPP
                  currentPage={currentPage || 1}
                  // handlePaginationChange={handlePaginationChange}
                  pageSize={pageSize || 10}
                  totalItems={topic?.metadata?.total_records}
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                />
              </div>
            </div>
          </div>
          <ImportTopic
            open={open}
            type='multiple'
            setOpen={setOpen}
            updateTopics={updateTopics}
            caseStudyId={caseStudyId}
          ></ImportTopic>
        </div>
      </div>
    </div>
  )
}

export default TableTopic
