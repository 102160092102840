import React from 'react'
import Users from './Users'

const ReferralsProfile = () => {
  return (
    <>
      {/* <Program /> */}
      <Users />
    </>
  )
}

export default ReferralsProfile
