import clsx from 'clsx'
import { FC } from 'react'
import { User } from 'src/components/user-list/components/core/_models'
import { PageLink } from 'src/constants'
import avatar from 'src/_metronic/assets/media/avatars/300-1.jpg'

type Props = {
  user: User
}

const NewsInfoCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href={PageLink.AUTH_LOGIN}>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={avatar} alt={user.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.initials?.state}`,
              `text-${user.initials?.state}`
            )}
          >
            {user.initials?.label}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href={PageLink.AUTH_LOGIN} className='text-gray-800 text-hover-primary mb-1'>
        {user.name}
      </a>
      <span>{user.email}</span>
    </div>
  </div>
)

export { NewsInfoCell }
