import React from 'react'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'

const Earning = () => {
  return (
    <div className='col-xxl-8'>
      <div className='card card-xxl-stretch mb-5 mb-xxl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>Earnings</h3>
          </div>
        </div>
        <div className='card-body pb-0'>
          <span className='fs-5 fw-semibold text-gray-600 pb-5 d-block'>
            Last 30 day earnings calculated. Apart from arranging the order of topics.
          </span>

          <div className='d-flex flex-wrap justify-content-between pb-6'>
            <div className='d-flex flex-wrap'>
              <div className='border border-dashed border-gray-300 w-125px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span
                    data-kt-countup='true'
                    data-kt-countup-value='6,840'
                    data-kt-countup-prefix='$'
                  >
                    0
                  </span>
                </span>
                <span className='fs-6 fw-semibold text-gray-400 d-block lh-1 pt-2'>
                  Net Earnings
                </span>
              </div>

              <div className='border border-dashed border-gray-300 w-125px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span className='' data-kt-countup='true' data-kt-countup-value='80'>
                    0
                  </span>
                  %
                </span>
                <span className='fs-6 fw-semibold text-gray-400 d-block lh-1 pt-2'>Change</span>
              </div>

              <div className='border border-dashed border-gray-300 w-125px rounded my-3 p-4 me-6'>
                <span className='fs-2x fw-bold text-gray-800 lh-1'>
                  <span
                    data-kt-countup='true'
                    data-kt-countup-value='1,240'
                    data-kt-countup-prefix='$'
                  >
                    0
                  </span>
                </span>
                <span className='fs-6 fw-semibold text-gray-400 d-block lh-1 pt-2'>Fees</span>
              </div>
            </div>
            <ButtonPrimary className='btn btn-primary px-6 flex-shrink-0 align-self-center' title='Withdraw Earnings'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Earning
