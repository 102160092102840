import React, {Dispatch, SetStateAction, useEffect} from 'react'
import {LANG_COURSES} from 'src/constants/lang'
import SAPPCheckbox from '../base/checkbox/SAPPCheckbox'
import SappTable from '../base/SappTable'
import {EStatusCourse, ICourseList} from 'src/type/courses'
import DatetimeColumn from '../base/DatetimeColumn'
import CourseAction from './CourseAction'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import LoadingTable from 'src/common/LoadingTable'
import CourseType from './CourseType'
import {CoursesAPI} from 'src/apis/courses'
import {COURSE_STATUS_FORM, DENIED_PERMISSIONS, MOCKUP_HEADER, PageLink} from 'src/constants'
import {useForm} from 'react-hook-form'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import {Select} from 'antd'
import {useConfirm} from 'src/hooks/use-confirm'
import {useLocation, useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import toast from 'react-hot-toast'
import {KTCardBody} from 'src/_metronic/helpers'
const {Option} = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  courseList: ICourseList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchCourseList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: LANG_COURSES.courseName,
    className: 'min-w-175px',
  },
  {
    label: 'Category',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Type',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Date',
    className: 'min-w-250px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

const TableList = ({
  courseList,
  loading,
  setLoading,
  filterParams,
  fetchCourseList,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  const {confirm, contextHolder} = useConfirm()
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  // TODO: check xem có tồn tại params ở trên router không?
  const showQueryParams =
    queryParams.name ||
    queryParams.course_category_ids ||
    queryParams.status ||
    queryParams.course_types ||
    queryParams.fromDate ||
    queryParams.toDate ||
    queryParams.sortType

  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.course_category_ids ?? '',
      queryParams.status ?? '',
      queryParams.course_types ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )


  // Using validate for input
  const {control, watch, setValue} = useForm<any>({
    mode: 'onChange',
  })

  const publishCategory = async (id: string) => {
    try {
      const res = await CoursesAPI.pulishCategory(id)
      if (res.success) {
        toast.success('Update Successfully!')
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '403|000000') {
        toast.error(DENIED_PERMISSIONS)
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
        // Xử lý các trường hợp lỗi khác nếu cần
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchCourseList(page_index || 1, page_size || 10, filterParams)
  }, [])

  useEffect(() => {
    courseList?.courses
      ?.map((course) => ({label: course.status.toLocaleLowerCase(), value: course.status}))
      ?.forEach((option, index) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, courseList?.courses])

  const lockCategory = async (id: string) => {
    try {
    const res = await CoursesAPI.lockCategory(id)
      if (res.success) {
        toast.success('Update Successfully!')
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '403|000000') {
        toast.error(DENIED_PERMISSIONS)
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
      }
    }
  }

  const draftCategory = async (id: string) => {
    try {
    const res = await CoursesAPI.draftCategory(id)
      if (res.success) {
        toast.success('Update Successfully!')
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '403|000000') {
        toast.error(DENIED_PERMISSIONS)
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
        // Xử lý các trường hợp lỗi khác nếu cần
      }
    }
  }

  const blockCategory = async (id: string) => {
    try {
    const res = await CoursesAPI.blockCategory(id)
      if (res.success) {
        toast.success('Update Successfully!')
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '403|000000') {
        toast.error(DENIED_PERMISSIONS)
        fetchCourseList(page_index || 1, page_size || 10, filterParams)
      }
    }
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick:
        value === EStatusCourse.DRAFT
          ? () => draftCategory(id)
          : value === EStatusCourse.PUBLISH
          ? () => publishCategory(id)
          : value === EStatusCourse.LOCK
          ? () => lockCategory(id)
          : () => blockCategory(id),
      onClose: () => {
        setLoading(true)
        fetchCourseList(1, page_size || 10, filterParams)
      },
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: queryParams.name ?? '',
      course_category_ids: queryParams?.course_category_ids ?? '',
      status: queryParams.status ?? '',
      course_types: queryParams.course_types ?? '',
      sortType: queryParams.sortType ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    console.log(filterParamsPagination)
    fetchCourseList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={courseList?.courses}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((header, i) => (
              <LoadingTable key={header} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {courseList?.courses?.map((course, index) => {
              const isChecked = checkedList.includes(course.id)

              return (
                <tr key={course.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(course.id)
                      }}
                    />
                  </td>
                  <td className='text-start'>
                    <Link
                      className='text-gray-800 text-hover-primary'
                      to={`${PageLink.COURSE_FULL}/${course?.id}`}
                    >
                      {course?.name}
                    </Link>
                  </td>
                  <td className='text-start'>{course?.course_categories?.[0]?.name ?? '-'}</td>
                  <td className='text-start'>
                    <CourseType type={course.course_type} />
                  </td>
                  <td className='text-start'>
                    <HookFormSelectAntd
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{maxWidth: 100}}
                      placeholder='Status'
                      filterOption={true}
                      onChange={(selectedValue: unknown) =>
                        handleChange(`status${index}`, selectedValue as string, course.id)
                      }
                      disabled={watch(`status${index}`) === EStatusCourse.LOCK}
                    >
                      {COURSE_STATUS_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='text-start'>
                    <DatetimeColumn
                      created_at={course?.created_at}
                      updated_at={course?.updated_at}
                    />
                  </td>
                  <td className='text-start'>
                    <CourseAction id={course?.id} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={courseList?.metadata?.page_index || 1}
        pageSize={courseList?.metadata?.page_size || 10}
        totalItems={courseList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableList
