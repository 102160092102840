import {zodResolver} from '@hookform/resolvers/zod'
import {uniqueId} from 'lodash'
import {Col, Row} from 'react-bootstrap'
import {useFieldArray, useForm} from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {VALIDATION_FILED} from 'src/constants'
import {getUppercaseByNumber} from 'src/utils'
import {z} from 'zod'

import {Select} from 'antd'
import ErrorMessage from 'src/common/ErrorMessage'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {defaultQuestionValues, validationQuestionSchema} from '../shared/consts'
import {
  ANSWER_CORRECT_TYPE,
  IInputQuestionProps,
  IQuestion,
  QUESTION_TYPES,
} from '../shared/interfaces'
import {inValidAnswers, validateAnswers} from '../shared/utils'
import LayoutQuestion from './LayoutQuestion'
import SappLabel from 'src/components/base/label/SappLabel'

type ITrueFalseQuestionProp = {
  open: boolean
  onClose: () => void
  type: string
  id?: string
  actionType?: 'edit' | 'duplicate'
}

interface IAnswer {
  answer: string
  is_correct: ANSWER_CORRECT_TYPE
  answerId: string
}
interface IInputProps extends IInputQuestionProps {
  answers?: IAnswer[]
}
const defaultValues = {
  ...defaultQuestionValues,
  answers: [
    {answer: '', answerId: uniqueId('answer_'), is_correct: ANSWER_CORRECT_TYPE.F},
    {answer: '', answerId: uniqueId('answer_'), is_correct: ANSWER_CORRECT_TYPE.F},
  ],
}

const TrueFalseQuestion = ({open, onClose, type, id, actionType}: ITrueFalseQuestionProp) => {
  const validationSchema = z
    .object({
      ...validationQuestionSchema,
      answers: z.array(
        z.object({
          answer: z
            .string({required_error: VALIDATION_FILED})
            .trim()
            .min(1, VALIDATION_FILED)
            .max(1000),
          answerId: z.string(),
          is_correct: z.string().optional(),
        })
      ),
    })
    .superRefine((arg: any & {answers: IAnswer}, ctx) => {
      const isInValidAnswers = inValidAnswers<IAnswer>(arg.answers, 'TRUE_FALSE')
      if (isInValidAnswers) {
        ctx.addIssue(isInValidAnswers)
      }
    })

  const useFormProp = useForm<IInputProps & {customAnswersError: string}>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {
    control,
    formState: {errors},
    setError,
    clearErrors,
    getValues,
  } = useFormProp

  const {fields} = useFieldArray({
    control,
    name: 'answers',
  })

  const buildRequest = (data: IInputProps): {partialQuestion: Partial<IQuestion>} => {
    return {
      partialQuestion: {
        qType: QUESTION_TYPES.TRUE_FALSE,
        answers: data.answers?.map((e, i) => ({
          is_correct: e.is_correct === 'T' ? true : false,
          answer: (e.answer ?? '').trim(),
          answer_position: i + 1,
        })),
      },
    }
  }

  const fetchData = (data: IQuestion): Partial<IInputProps> => {
    if (!data) {
      return {}
    }
    return {
      answers: data.answers?.map((e) => ({
        is_correct: e.is_correct ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F,
        answer: e.answer,
        answerId: uniqueId('answer_'),
      })),
    }
  }

  return (
    <LayoutQuestion
      defaultValues={defaultValues}
      actionType={actionType}
      fetchData={fetchData}
      buildRequest={buildRequest}
      open={open}
      onClose={onClose}
      useForm={useFormProp}
      type={type}
      id={id}
    >
      {/* start:: answer */}
      <div>
        <Row>
          <Col md={9}>
            <SappLabel label='Danh Sách Đáp Án' required />
          </Col>
        </Row>
        <div>
          {fields?.map((field, index) => (
            <div key={field.answerId}>
              <Row>
                <Col md={9} className='mb-5'>
                  <div className='d-flex align-items-center'>
                    <div className='w-100'>
                      <HookFormTextField
                        groupText={getUppercaseByNumber(index + 1)}
                        control={control}
                        name={`answers.${index}.answer` as const}
                        placeholder={'Đáp án ' + (index + 1)}
                        className='sapp-h-45px fs-6'
                        // guideline={[
                        //   'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự.',
                        // ]}
                      ></HookFormTextField>
                    </div>
                  </div>
                </Col>
                <Col md={3} className='mb-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='sapp-flex-1'>
                      <HookFormSelectAntd
                        size='large'
                        name={`answers.${index}.is_correct` as const}
                        control={control}
                        dropdownStyle={{minWidth: 'fit-content'}}
                        placeholder={'Correct'}
                        customError={!!errors?.customAnswersError}
                        className='sapp-h-45px fs-6'
                        onChange={() => {
                          validateAnswers({
                            setError,
                            clearErrors,
                            answers: getValues('answers'),
                            path: 'customAnswersError',
                            checkEvery: 'TRUE_FALSE',
                          })
                        }}
                      >
                        <Select.Option value='T'>Đúng</Select.Option>
                        <Select.Option value='F'>Sai</Select.Option>
                      </HookFormSelectAntd>
                    </div>
                  </div>
                  {fields.length - 1 === index && (
                    <ErrorMessage>{errors?.customAnswersError?.message}</ErrorMessage>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
      {/* end:: answer*/}
    </LayoutQuestion>
  )
}
export default TrueFalseQuestion
