import React, {Dispatch, SetStateAction} from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import {Modal} from 'react-bootstrap'
interface IProps {
  openCard: boolean
  setOpenCard: Dispatch<SetStateAction<boolean>>
}

const ModalNewCard = ({openCard, setOpenCard}: IProps) => {
  return (
    <Modal dialogClassName='modal-dialog modal-dialog-centered mw-650px' show={openCard}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2 className='mb-0'>Add New Card</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setOpenCard(false)}
          >
            <i className='ki-outline ki-cross fs-1'></i>
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
          <form id='kt_modal_new_card_form' className='form' action='#'>
            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-3'>
                <span className='required'>Name On Card</span>
                <span
                  className='ms-1'
                  data-bs-toggle='tooltip'
                  title="Specify a card holder's name"
                >
                  <i className='ki-outline ki-information-5 text-gray-500 fs-6'></i>
                </span>
              </label>

              <input
                type='text'
                className='form-control'
                placeholder=''
                name='card_name'
                value='Max Doe'
              />
            </div>

            <div className='d-flex flex-column mb-7 fv-row'>
              <label className='required fs-6 fw-bold form-label mb-3'>Card Number</label>

              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter card number'
                  name='card_number'
                  value='4111 1111 1111 1111'
                />

                <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                  <img src='assets/media/svg/card-logos/visa.svg' alt='' className='h-25px' />
                  <img src='assets/media/svg/card-logos/mastercard.svg' alt='' className='h-25px' />
                  <img
                    src='assets/media/svg/card-logos/american-express.svg'
                    alt=''
                    className='h-25px'
                  />
                </div>
              </div>
            </div>

            <div className='row mb-10'>
              <div className='col-md-8 fv-row'>
                <label className='required fs-6 fw-bold form-label mb-3'>Expiration Date</label>

                <div className='row fv-row'>
                  <div className='col-6'>
                    <SAPPSelect
                      size='middle'
                      options={[
                        {
                          label: 'Role',
                          value: 'Role',
                        },
                        {
                          label: 'One',
                          value: 'One',
                        },
                      ]}
                    />
                  </div>

                  <div className='col-6'>
                    <SAPPSelect
                      size='middle'
                      options={[
                        {
                          label: 'Role',
                          value: 'Role',
                        },
                        {
                          label: 'One',
                          value: 'One',
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-4 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-3'>
                  <span className='required'>CVV</span>
                  <span className='ms-1' data-bs-toggle='tooltip' title='Enter a card CVV code'>
                    <i className='ki-outline ki-information-5 text-gray-500 fs-6'></i>
                  </span>
                </label>

                <div className='position-relative'>
                  <input type='text' className='form-control' placeholder='CVV' name='card_cvv' />

                  <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                    <i className='ki-outline ki-credit-cart fs-2hx'></i>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack'>
              <div className='me-5'>
                <label className='fs-6 fw-semibold form-label'>
                  Save Card for further billing?
                </label>
                <div className='fs-7 fw-semibold text-muted'>
                  If you need more info, please check budget planning
                </div>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='1' />
                <span className='form-check-label fw-semibold text-muted'>Save Card</span>
              </label>
            </div>

            <SAPPDialogButtonsCancelSubmit
              cancelClick={() => setOpenCard(false)}
              cancelButtonCaption='Discard'
              okButtonCaption='Submit'
              okOnClick={() => {}}
              className='pt-15'
            />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default ModalNewCard
