import React from 'react'
import {PageLink} from 'src/constants'

const Documentation = () => {
  return (
    <div className='card-rounded bg-primary bg-opacity-5 p-10 mb-15'>
      <h1 className='fw-bold text-dark mb-9'>Documentation</h1>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Angular Admin
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          React Admin
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Vue Dashboard
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Niche Theme
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Dashboard Admin
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Dorsey Front-end
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          CRM Admin
        </a>
      </div>

      <div className='d-flex align-items-center mb-6'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Admin Dashbaord
        </a>
      </div>

      <div className='d-flex align-items-center'>
        <i className='ki-outline ki-right fs-2 ms-n1 me-3'></i>

        <a
          href={PageLink.DASHBOARD}
          className='fw-semibold text-gray-800 text-hover-primary fs-5 m-0'
        >
          Intranet Admin
        </a>
      </div>
    </div>
  )
}

export default Documentation
