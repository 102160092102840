import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { PageLink } from 'src/constants';
import { getActToken } from 'src/utils';

const withUnAuthComponents = (Component: ComponentType) => {
    const AuthRoute = () => {
        const isAuth = getActToken();
        if (isAuth) {
            return <Navigate to={PageLink.PROFILE_OVERVIEW} />
        } else {
            return <Component />;
        }
    };

    return AuthRoute;
};

export default withUnAuthComponents;