import {fetcher, fetchFormPutData} from 'src/services/request'
import {IResponse} from 'src/type'
import {
  ICourseActivity,
  ICourseActivityEdit,
  ICourseLearnOutCome,
  ICourseList,
  ICoursePart,
  ICourseSectionList,
  ICourseSession,
  ICourseTab,
} from 'src/type/courses'

interface ICreateCategory {
  name: string
  description: string
}

export class CoursesAPI {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<ICourseList>> {
    return fetcher(`courses?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static courseSectionList({
    page_index,
    page_size,
    courseId,
    type,
    params
  }: {
    page_index: number
    page_size: number
    courseId: string | null
    type: 'PART' | 'CHAPTER' | 'UNIT' | 'ACTIVITY',
    params?: Object
  }): Promise<IResponse<ICourseSectionList>> {
    return fetcher(
      `/course-sections/short/list?page_index=${page_index}&page_size=${page_size}&courseId=${courseId}&type=${type}`, {
        params: params
      }
    )
  }

  static courseLinkList({
    page_index,
    page_size,
    type,
    params,
  }: {
    page_index: number
    page_size: number
    type: 'PART' | 'CHAPTER' | 'UNIT' | 'ACTIVITY'
    params?: Object
  }): Promise<IResponse<ICourseSectionList>> {
    return fetcher(
      `/course-sections/short/list?page_index=${page_index}&page_size=${page_size}&type=${type}`,
      {
        params: params,
      }
    )
  }

  static unlinkCourse = ({
    child_id,
    course_id,
    course_section_parent_id,
  }: {
    child_id?: string  | null | undefined
    course_id?: string | null | undefined
    course_section_parent_id?: string | null
  }) => {
    return fetcher(`course-sections/remove-link`, {
      method: 'PUT',
      data: {
        course_id: course_id,
        course_section_parent_id: course_section_parent_id,
        child_id: child_id,
      },
    })
  }

  static delete = (id: string) => {
    return fetcher(`courses/${id}`, {
      method: 'DELETE',
    })
  }

  static getCategory({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<any> {
    return fetcher(`course_categories?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static pulishCategory = (id: string) => {
    return fetcher(`courses/${id}`, {
      method: 'PUT',
      data: {
        status: 'PUBLISH',
      },
    })
  }

  static linkCourseSections = ({
    course_id,
    children,
    course_section_parent_id,
  }: {
    course_id: string | null | undefined
    children: Array<{id: string; position: string}> | any
    course_section_parent_id?: string | null | undefined
  }) => {
    return fetcher('/course-sections/add-links', {
      method: 'POST',
      data: {
        course_id: course_id,
        children: children,
        course_section_parent_id: course_section_parent_id,
      },
    })
  }

  static lockCategory = (id: string) => {
    return fetcher(`courses/${id}`, {
      method: 'PUT',
      data: {
        status: 'LOCK',
      },
    })
  }

  static draftCategory = (id: string) => {
    return fetcher(`courses/${id}`, {
      method: 'PUT',
      data: {
        status: 'DRAFT',
      },
    })
  }

  static blockCategory = (id: string) => {
    return fetcher(`courses/${id}`, {
      method: 'PUT',
      data: {
        status: 'BLOCK',
      },
    })
  }

  static getCoursesById({id}: {id: string}): Promise<IResponse<any>> {
    return fetcher(`/courses/${id}/sections`)
  }

  static getCoursesByIdInClass({id}: {id: any}): Promise<IResponse<any>> {
    return fetcher(`/courses/${id}`)
  }

  static createCoursePart(data: ICoursePart): Promise<IResponse<any>> {
    return fetcher(`/course-sections/part`, {method: 'POST', data})
  }

  static createCourseChapter(data: ICoursePart): Promise<IResponse<any>> {
    return fetcher(`/course-sections/chapter`, {method: 'POST', data})
  }
  static createCoursePartialChapter(data: ICoursePart): Promise<IResponse<any>> {
    return fetcher(`/course-sections/partial/chapter`, {method: 'POST', data})
  }
  static createCourseUnit(data: ICoursePart): Promise<IResponse<any>> {
    return fetcher(`/course-sections/unit`, {method: 'POST', data})
  }
  static createLearningOutcome(data: ICourseLearnOutCome): Promise<IResponse<any>> {
    return fetcher('/course_learning_outcomes', {method: 'POST', data})
  }

  static updateSections(data: ICourseSession): Promise<IResponse<any>> {
    return fetcher('/course-sections', {method: 'PUT', data})
  }

  static deleteSection(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/${id}`, {method: 'DELETE'})
  }

  static updateLearnOutcome({id, ...data}: ICourseLearnOutCome): Promise<IResponse<any>> {
    return fetcher(`/course_learning_outcomes/${id}`, {method: 'PUT', data})
  }
  static getLearnOutcome(id: string): Promise<IResponse<any>> {
    return fetcher(`/course_learning_outcomes/${id}`, {method: 'GET'})
  }
  static deleteLearnOutcome(id: string): Promise<IResponse<any>> {
    return fetcher(`/course_learning_outcomes/${id}`, {method: 'DELETE'})
  }
  static deleteForceLearnOutcome(id: string): Promise<IResponse<any>> {
    return fetcher(`/course_learning_outcomes/${id}/force`, {method: 'DELETE'})
  }
  static getCourseOutcomes(id: string): Promise<IResponse<any>> {
    return fetcher(`/course_learning_outcomes/${id}`, {method: 'GET'})
  }
  static getOutCome(id: string): Promise<IResponse<any>> {
    return fetcher(`/course_outcomes/${id}`, {method: 'GET'})
  }
  static courseOutcomesConnections(id: string): Promise<IResponse<any>> {
    return fetcher(`/course_outcomes/${id}/connections`, {method: 'GET'})
  }
  static getTabByActivity(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/activity/${id}/tabs`, {method: 'GET'})
  }
  static addTab(data: ICourseTab): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab`, {method: 'POST', data})
  }
  static editTab({id, ...data}: ICourseTab): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/${id}`, {method: 'PUT', data})
  }
  static getTabDetail(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/${id}`, {method: 'GET'})
  }
  static deleteTab(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/${id}`, {method: 'DELETE'})
  }
  static addDocumentTab({id, ...data}: any): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/${id}/document`, {method: 'POST', data})
  }
  static editDocumentTab({id, ...data}: any): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/document/${id}`, {method: 'PUT', data})
  }
  static deleteDocumentTab(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/document/${id}`, {method: 'DELETE'})
  }
  static getDocumentDetail(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/tab/document/${id}`, {method: 'GET'})
  }
  static createCourseInfo({
    name,
    code,
    course_difficulty,
    pass_point,
    course_tags,
    description,
    status,
    course_type,
    progress,
    activity_points,
    chapter_points,
    topic_points,
    mid_term_points,
    final_test_points,
    template,
    duration,
    course_category_ids,
    course_levels,
    course_connect_ia,
    course_connect_ib,
    course_connect_iia,
    course_connect_iib,
  }: any): Promise<any> {
    const data = {
      name,
      code,
      course_difficulty,
      pass_point,
      course_tags,
      description,
      status,
      course_type,
      setting: {
        progress,
        activity_points,
        chapter_points,
        topic_points,
        mid_term_points,
        final_test_points,
      },
      template,
      duration,
      course_category_ids,
      course_levels,
      course_connect_ia,
      course_connect_ib,
      course_connect_iia,
      course_connect_iib,
    }

    return fetcher('/courses', {
      method: 'POST',
      data,
    })
  }

  static updateCourseInfo({
    id,
    name,
    code,
    course_difficulty,
    pass_point,
    course_tags,
    description,
    status,
    course_type,
    progress,
    activity_points,
    chapter_points,
    topic_points,
    mid_term_points,
    final_test_points,
    template,
    duration,
    course_category_ids,
    course_levels,
    course_connect_ia,
    course_connect_ib,
    course_connect_iia,
    course_connect_iib,
  }: any): Promise<any> {
    const data = {
      name,
      code,
      course_difficulty,
      pass_point,
      course_tags,
      description,
      status,
      course_type,
      setting: {
        progress,
        activity_points,
        chapter_points,
        topic_points,
        mid_term_points,
        final_test_points,
      },
      template,
      duration,
      course_category_ids,
      course_levels,
      course_connect_ia,
      course_connect_ib,
      course_connect_iia,
      course_connect_iib,
    }

    return fetcher(`courses/${id}`, {
      method: 'PUT',
      data,
    })
  }

  static uploadImageCourse = ({
    course_id,
    course_image,
  }: {
    course_id: string | undefined
    course_image: File
  }) => {
    const formData = new FormData()
    if (course_id) {
      formData.append('course_id', course_id)
    }
    formData.append('course_image', course_image)
    return fetchFormPutData({url: `/courses/image`, formData})
  }

  static getTagCategory(): Promise<any> {
    return fetcher(`course_tags?page_index=1&page_size=500`)
  }

  static getCourseDetail(id: string | undefined): Promise<any> {
    return fetcher(`courses/${id}`)
  }

  static getCourseLevel(): Promise<any> {
    return fetcher('course_levels')
  }

  static getCourseFoundations(level: string): Promise<any> {
    return fetcher(`courses/foundations?level_code=${level}`)
  }

  static removeImageCourse(id: string | undefined): Promise<any> {
    return fetcher(`/courses/${id}/remove-image`, {
      method: 'PUT',
    })
  }
  static createCourseAcitivy(data: ICourseActivity): Promise<IResponse<any>> {
    return fetcher(`/course-sections/activity`, {method: 'POST', data})
  }
  static getActivityDetail(id: string): Promise<IResponse<any>> {
    return fetcher(`/course-sections/activity/${id}`, {method: 'GET'})
  }
  static editActivity({id, data}: ICourseActivityEdit): Promise<IResponse<any>> {
    return fetcher(`/course-sections/activity/${id}`, {method: 'PUT', data})
  }
  static createCategory = (data: ICreateCategory) => {
    return fetcher('course_categories', {
      method: 'POST',
      data: data,
    })
  }

  static updateCategory = (id: string, data: ICreateCategory) => {
    return fetcher(`course_categories/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static deleteCategory = (id: string) => {
    return fetcher(`course_categories/${id}`, {
      method: 'DELETE',
    })
  }

  static getCategoryDetail = (id: string) => {
    return fetcher(`course_categories/${id}`)
  }

  static createQuiz = (data: any) => {
    return fetcher('quiz', {
      method: 'POST',
      data: data,
    })
  }
  static getQuizById = (id: string) => {
    return fetcher(`quiz/${id}`, {
      method: 'GET',
    })
  }
  static deleteQuiz = (id: string) => {
    return fetcher(`quiz/${id}`, {
      method: 'DELETE',
    })
  }
  static updateQuiz = (id: string, data: any) => {
    return fetcher(`quiz/${id}`, {
      method: 'PUT',
      data: data,
    })
  }
  // RESOURCE
  static getCourseResource = (
    course_id: string,
    params: {page_index: number; page_size: number; search_key?: string}
  ) => {
    return fetcher(`courses/${course_id}/resources`, {
      method: 'GET',
      params: {...params, attachment_type: 'attached'},
    })
  }
  static addCourseResource = (
    course_id: string,
    attachments: {
      resource_id: string
      type: string
      dom_id: string
      note: string
    }[]
  ) => {
    return fetcher(`courses/${course_id}/add-resource`, {
      method: 'POST',
      data: {attachments},
    })
  }
  static deleteCourseResource = (course_id: string, attachment_ids: string) => {
    return fetcher(`courses/${course_id}/resource`, {
      method: 'DELETE',
      params: {
        attachment_ids,
      },
    })
  }

  static getListSort = (params: {
    type?: string
    name?: string
    parentId?: string
    courseId?: string
    page_index: number
    page_size: number
  }) => {
    return fetcher(`course-sections/short/list`, {
      method: 'GET',
      params,
    })
  }

  static getSectionDetail(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/course-sections/${id}`)
  }

  static getSectionPreview(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`course-sections/preview/${id}`)
  }
  //End RESOURCE

  // Certificate
  static addCertificate({id, data}: any): Promise<IResponse<any>> {
    return fetcher(`/courses/${id}/certificates`, {method: 'POST', data})
  }
  // End certificate
}
