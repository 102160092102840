import React from 'react'
import PopularTickets from './PopularTickets'
import FAQ from './FAQ'
import Tutorials from './Tutorials'
import Videos from './Videos'
import Documentations from './Documentations'

const SupportOverview = () => {
  return (
    <>
      <div className='row gy-0 mb-6 mb-xl-12'>
        <PopularTickets />
        <FAQ />
      </div>
      <div className='row gy-0 mb-6 mb-xl-12'>
        <Tutorials />
        <Videos />
      </div>
      <Documentations />
    </>
  )
}

export default SupportOverview