import { Dispatch, SetStateAction, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import { MOCKUP_HEADER, PageLink } from 'src/constants'
import { ITopicList } from 'src/type/question-bank'
import SAPPCheckbox from '../base/checkbox/SAPPCheckbox'
import DatetimeColumn from '../base/DatetimeColumn'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import { QuestionAction } from './QuestionAction'
import { cleanParamsAPI, formatDate, formatISOFromDate, formatISOToDate, getDateInfo, htmlToRaw } from 'src/utils'
import SappTable from '../base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'
import { Role } from 'src/type'

const headers = [
  {
    label: 'Item Set Name',
    key: 'topic_name',
    className: 'min-w-250px',
  },
  {
    label: 'Topic Description',
    key: 'topic_description',
    className: 'min-w-250px',
  },
  {
    label: 'Multiple Question',
    key: 'multiple_question',
    className: 'min-w-200px text-nowrap text-center',
  },
  {
    label: 'Constructed Question',
    key: 'constructed_question',
    className: 'min-w-200px text-nowrap text-center',
  },
  {
    label: 'Date',
    key: 'update_at',
    className: 'min-w-250px',
  },
  {
    label: '',
    key: 'action',
    className: 'text-end w-35px',
  },
]

interface IProps {
  topicList: ITopicList | undefined
  setTopicList: Dispatch<SetStateAction<any>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  fetchTopic: (page_index: number, page_size: number, object?: Object) => void
  queryParam: any
  getParams: any
}

const TableList = ({
  topicList,
  setTopicList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  fetchTopic,
  queryParam,
  getParams
}: IProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const showQueryParams = queryParam.text || queryParam.course || queryParam.part || queryParam.chapter || queryParam.unit || queryParam.activity || queryParam.sortType || queryParam.fromDate || queryParam.toDate

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate);
  const dateQueryToDate = getDateInfo(queryParam.toDate);

  //TODO: dùng để change params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParam.text ?? '',
      queryParam.course ?? '',
      queryParam.sortType ?? '',
      queryParam.fromDate ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day) : '',
      queryParam.toDate ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day) : '',
      showQueryParams ? 'updated_at' : 'created_at',
      queryParam.course_section_id ?? '',
    )
  )

  // call API getTopic
  useEffect(() => {
    setLoading(true)
    fetchTopic(queryParam.page_index || 1, queryParam?.page_size || 10, cleanedParams)
  }, [])

  //TODO: đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      text: queryParam.text ?? '',
      course: queryParam.course ?? '',
      part: queryParam.part ?? '',
      chapter: queryParam.chapter ?? '',
      unit: queryParam.unit ?? '',
      activity: queryParam.activity ?? '',
      sortType: queryParam.sortType ?? '',
      fromDate: formatDate(queryParam.fromDate) ?? '',
      toDate: formatDate(queryParam.toDate) ?? '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchTopic(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }
  const { profileMe } = useUserContext()
  const allowRenderView = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC) || role.code === CODE_ADMIN.SUPER_ADMIN);

  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={topicList?.topics}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {topicList?.topics?.map((topic, i) => {
              const isChecked = checkedList && checkedList.includes(topic.id)
              return (
                <tr key={topic?.id ?? i}>
                  <td>
                    <SAPPCheckbox
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(topic.id)
                      }}
                    />
                  </td>
                  <td>
                    {allowRenderView && (
                      <Link
                        className='text-gray-800 text-hover-primary sapp-text-truncate-2'
                        to={`${PageLink.TOPIC}/${topic?.id}${location.search || ''}`}
                      >
                        {topic.name}
                      </Link>
                    )}
                  </td>
                  <td>
                    <div className='mw-500px sapp-text-truncate-2'>{htmlToRaw(topic.description)}</div>
                  </td>
                  <td className='text-center'>{topic.num_of_multiple_choice_questions}</td>
                  <td className='text-center'>{topic.num_of_constructed_questions}</td>
                  <td>
                    <DatetimeColumn created_at={topic?.created_at} updated_at={topic?.updated_at} />
                  </td>
                  <td className='text-end sapp-absolute-column'>
                    <QuestionAction
                      id={topic.id}
                      setTopicList={setTopicList}
                      cleanedParams={cleanedParams}
                      setLoading={setLoading}
                      currentPage={queryParam?.page_index || 1}
                      pageSize={queryParam?.page_size || 10}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      {/* start:: show pagination */}
      <PagiantionSAPP
        currentPage={topicList?.meta?.page_index || 1}
        pageSize={topicList?.meta?.page_size || 10}
        totalItems={topicList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      {/* end:: show pagination */}
    </KTCardBody>
  )
}

export default TableList
