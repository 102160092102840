import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Select, Tooltip } from 'antd'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClassesApi } from 'src/apis/classes'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import ClassesAction from 'src/components/classes/ClassAction'
import { DENIED_PERMISSIONS, HEADER_CLASS_LIST, PageLink } from 'src/constants'
import {
  OPTIONS_OF_DURATION,
  OPTIONS_OF_STATUS,
  OPTIONS_OF_STATUS_FILTER,
} from 'src/constants/classes'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusCourse } from 'src/type/courses'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import BlockClass from './blockClass/blockClass'
import UpdateDuration from './updateDuration/updateDuration'
import { CLASS_PROFILE, CLASS_PROFILE_URL } from 'src/constants/classes'
import { format } from 'date-fns'
import ModalProcessing from 'src/components/classes/modalProcessing'
import SappTooltip, { SappTooltipArray } from 'src/common/SappTooltip'
import { KTCardBody } from 'src/_metronic/helpers'
import { isEmpty } from 'lodash'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  classList: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchClassList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Class',
    className: 'min-w-275px pe-5',
  },
  {
    label: 'Code',
    className: 'min-w-275px pe-5',
  },
  {
    label: 'Class Foundation',
    className: 'min-w-300px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Course',
    className: 'min-w-275px pe-5',
  },
  {
    label: 'Program',
    className: 'min-w-125px pe-5',
  },
  {
    label: 'Subjects',
    className: 'min-w-125px pe-5',
  },
  {
    label: 'Exam',
    className: 'min-w-175px pe-5',
  },
  {
    label: 'Duration',
    className: 'min-w-200px pe-5',
  },
  // {
  //   label: 'Opening',
  //   className: 'min-w-150px pe-5',
  // },
  // {
  //   label: 'Courses',
  //   className: 'min-w-250px pe-5',
  // },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]


const TableClasses = ({
  classList,
  loading,
  setLoading,
  filterParams,
  fetchClassList,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const [openBlock, setOpenBlock] = useState<{ status: boolean; data?: any }>({ status: false })
  const [openUpdateDuration, setOpenUpdateDuration] = useState<{ status: boolean; data?: any }>({
    status: false,
  })
  const [openEditCourseContent, setOpenEditCourseContent] = useState<{
    status: boolean
    type?: string
    data?: any
  }>({ status: false })
  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  // TODO: check xem có tồn tại params ở trên router không?
  const showQueryParams =
    queryParams.class_name ||
    queryParams.course_category_id ||
    queryParams.area_id ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate ||
    queryParams.status ||
    queryParams.instruction_mode ||
    queryParams.class_type ||
    queryParams.subject ||
    queryParams.class_code

  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.class_name.trimStart().trimEnd() ?? '',
      queryParams.class_code ?? '',
      queryParams.course_category_id ?? '',
      queryParams.area_id ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      queryParams.status ?? '',
      queryParams.instruction_mode ?? '',
      queryParams.class_type ?? '',
      queryParams.subject ?? '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )
  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  //const publishCategory = async (id: string) => {
  // const res = await CoursesAPI.pulishCategory(id)
  // if (res.success) {
  //   toast.success('Update Successfully!')
  //   fetchCourseList(page_index || 1, page_size || 10, filterParams)
  // }
  //}

  useEffect(() => {
    setLoading(true)
    fetchClassList(page_index || 1, page_size || 10, filterParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    classList?.classes
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, classList?.classes])

  //const lockCategory = async (id: string) => {
  // const res = await CoursesAPI.lockCategory(id)
  // if (res.success) {
  //   toast.success('Update Successfully!')
  //   fetchCourseList(page_index || 1, page_size || 10, filterParams)
  // }
  //}

  //const draftCategory = async (id: string) => {
  // const res = await CoursesAPI.draftCategory(id)
  // if (res.success) {
  //   toast.success('Update Successfully!')
  //   fetchCourseList(page_index || 1, page_size || 10, filterParams)
  // }
  //}

  //const blockCategory = async (id: string) => {
  // const res = await CoursesAPI.blockCategory(id)
  // if (res.success) {
  //   toast.success('Update Successfully!')
  //   fetchCourseList(page_index || 1, page_size || 10, filterParams)
  // }
  //}

  const hanleChangeStatus = (value: string, data: any) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        try {
          const payload = {
            id: data.id,
            status: value,
          }
          await ClassesApi.updateClass(payload)
          toast.success('update classes status succeded')
          fetchClassList(page_index || 1, page_size || 10, filterParams)
        } catch (err: any) {
          if (err?.response?.data?.error?.code === '403|000000') {
            toast.error(DENIED_PERMISSIONS);
            fetchClassList(page_index || 1, page_size || 10, filterParams)
          } else {
            // Xử lý các trường hợp lỗi khác nếu cần
            console.error('Error:', err);
          }
        }
      },

      onClose: () => {
        setLoading(true)
        fetchClassList(page_index || 1, page_size || 10, filterParams)
      },
    })
  }

  const handleChange = (value: string, id: string, data: object) => {
    if (value === 'BLOCKED') {
      setOpenBlock({ status: true, data: id })
      return
    }
    hanleChangeStatus(value, data)
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      class_code: queryParams?.class_code ?? '',
      class_name: queryParams.class_name ?? '',
      course_category_id: queryParams?.course_category_id ?? '',
      status: queryParams.status ?? '',
      area_id: queryParams.area_id ?? '',
      sortType: queryParams.sortType ?? '',
      instruction_mode: queryParams.instruction_mode ?? '',
      class_type: queryParams.class_type ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
      subject: queryParams.subject,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchClassList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  const Action = (type: string, data: any) => {
    if (type === 'edit') {
      navigate(`${CLASS_PROFILE}/${data.id}/${CLASS_PROFILE_URL.SETTING}`)
    }
    if (type === 'duration') {
      setOpenUpdateDuration({ status: true, data: data })
      return
    }
    if (type === 'content') {
      setOpenEditCourseContent({ status: true, type: 'course-content', data: data })
    }
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={classList?.classes}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_CLASS_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_CLASS_LIST} />
            ))}
          </>
        ) : (
          <>
            {classList?.classes?.map((e: any, index: number) => {
              const isDisabled = e?.is_student_studying && e?.status === 'PUBLIC'
              //const isChecked = checkedList.includes(e.id)
              const exams = e?.acca_examination?.season || e?.cfa_examination?.season || e?.cma_examination?.season
              return (
                <tr key={e.id}>
                  <td>
                    {index + 1 + (classList?.meta?.page_index - 1) * classList?.meta?.page_size}
                  </td>
                  <td className='text-start'>
                    <SappTooltip title={e?.name} link={`${CLASS_PROFILE}/${e?.id}/${CLASS_PROFILE_URL.OVERVIEW}`} />
                  </td>
                  <td className='text-start'>
                    {e.code ?? '--'}
                  </td>
                  <td className='text-start'>
                    {!isEmpty(e?.normal_class_connections) ? e?.normal_class_connections?.map((el: any) => {
                      return (
                        <SappTooltipArray title={el.foundation_class?.name} key={el.foundation_class?.id} link={`/classes/detail/${el?.foundation_class?.id}/overview`} />
                      )
                    }) : '--'}
                  </td>
                  <td className='text-start'>
                    {watch(`status${index}`) === 'ENDED' ? (
                      <HookFormSelectAntd
                        size='large'
                        name={`status${index}`}
                        control={control}
                        dropdownStyle={{ maxWidth: 100 }}
                        placeholder='Status'
                        filterOption={true}
                        onChange={(selectedValue: unknown) =>
                          handleChange(selectedValue as string, e.id, e)
                        }
                        disabled={watch(`status${index}`) === 'ENDED'}
                      >
                        {OPTIONS_OF_STATUS_FILTER.map((status) => (
                          <Option key={status.label} value={status.value}>
                            {status.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    ) : (
                      <HookFormSelectAntd
                        size='large'
                        name={`status${index}`}
                        control={control}
                        dropdownStyle={{ maxWidth: 100 }}
                        placeholder='Status'
                        filterOption={true}
                        onChange={(selectedValue: unknown) =>
                          handleChange(selectedValue as string, e.id, e)
                        }
                        disabled={watch(`status${index}`) === EStatusCourse.LOCK || isDisabled}
                      >
                        {OPTIONS_OF_STATUS.map((status) => (
                          <Option key={status.label} value={status.value}>
                            {status.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    )}
                  </td>
                  <td>{e?.course?.name || '--'}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {e?.course?.course_categories[0]?.name ?? '--'}
                    </div>
                  </td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {e?.course?.course_difficulty ?? '--'}
                    </div>
                  </td>
                  <td className='text-start'>
                    {exams ? format(new Date(exams), 'dd/MM/yyyy') : '--'}
                  </td>
                  <td className='text-start'>
                    {e?.duration_type !== OPTIONS_OF_DURATION.valueFlexible ? (
                      <div>
                        {(() => {
                          if (e?.started_at && e?.finished_at) {
                            const fromDate = new Date(e?.started_at)
                            const toDate = new Date(e?.finished_at)

                            return `${format(fromDate, 'dd/MM/yyyy')} - ${format(
                              toDate,
                              'dd/MM/yyyy'
                            )}`
                          } else {
                            return '--'
                          }
                        })()}
                      </div>
                    ) : (
                      <div>{e?.flexible_days ? `${e?.flexible_days} days` : '--'}</div>
                    )}
                  </td>
                  {/* <td className='text-start'>
                    <div>
                      {' '}
                      {(() => {
                        if (e?.opening_at) {
                          const fromDate = new Date(e?.opening_at)
                          // const toDate = new Date(e?.finished_at)

                          return `${format(fromDate, 'dd/MM/yyyy')}`
                        } else {
                          return '--'
                        }
                      })()}
                    </div>
                  </td> */}
                  {/* <td className='text-start'>
                    <SappTooltip title={e?.course?.name} link={`${PageLink.COURSE_FULL}/${e?.course_id}`} />
                  </td> */}
                  {watch(`status${index}`) === 'ENDED' ? (
                    <></>
                  ) : (
                    <td className='text-end sapp-absolute-column'>
                      <ClassesAction Action={Action} data={e} />
                    </td>
                  )}

                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <BlockClass
        open={openBlock.status}
        setOpen={setOpenBlock}
        data={openBlock.data}
        fetchClasses={() => fetchClassList(page_index || 1, page_size || 10, filterParams)}
      />
      <UpdateDuration
        open={openUpdateDuration.status}
        setOpen={setOpenUpdateDuration}
        data={openUpdateDuration.data}
        fetchClasses={() => fetchClassList(page_index || 1, page_size || 10, filterParams)}
      />
      <ModalProcessing
        open={openEditCourseContent.status}
        setOpen={setOpenEditCourseContent}
        type={openEditCourseContent.type}
        title={
          openEditCourseContent.type === 'process' ? 'Learning Process' : 'Edit Course Content'
        }
        id={openEditCourseContent?.data?.course_id}
        exceptedSections={openEditCourseContent?.data?.excepted_course_section || []}
        classId={openEditCourseContent?.data?.id}
        refetch={() => fetchClassList(page_index || 1, page_size || 10, filterParamsPagination)}
      />

      <PagiantionSAPP
        currentPage={classList?.meta?.page_index || 1}
        pageSize={classList?.meta?.page_size || 10}
        totalItems={classList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableClasses
