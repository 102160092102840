import React from 'react'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'LMS'
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'Account'
    }
  ]

const Setting = () => {
  return (
    <PageLayouts pageTitle='Setting' breadcrumbs={breadcrumbs}>

    </PageLayouts>
  )
}

export default withAuthComponents(Setting)