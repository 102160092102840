import React, {useEffect, useState} from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {KTIcon} from 'src/_metronic/helpers'
import SappDrawer from 'src/components/base/SappDrawer'
import {CertificatesApi} from 'src/apis/certificates'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import {HEADER_CLASS_LIST} from 'src/constants'
import LoadingTable from 'src/common/LoadingTable'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import {toast} from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import {CoursesAPI} from 'src/apis/courses'
import { useConfirm } from 'src/hooks/use-confirm'

const CourseCertificate = () => {
  const [openModelUpdate, setOpenModalUpload] = useState<boolean>(false)
  const [certificateList, setCertificateList] = useState<any>()
  const [certificateId, setCertificateId] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()
  const { contextHolder } = useConfirm()
  const [certificateTmp, setCertificateTmp] = useState<string>('')
  const [courseCategoryId, setCourseCategoryId] = useState<string>('')
  const [certificateInfo, setCertificateInfo] = useState<any>([])

  const fetchCertificateList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CertificatesApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setCertificateList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getCertificateDetail = async (id: string) => {
    if (id) {
      const {data} = await CertificatesApi.detail(id)
      setCertificateInfo(data)
    }
  }

  const getCourseInfo = async (id: any) => {
    try {
      const res = await CoursesAPI.getCoursesByIdInClass({id: id})
      setCourseCategoryId(res?.data?.course_categories[0]?.id)
      setCertificateId(res?.data?.certificate_id ?? '')
      setCertificateTmp(res?.data?.certificate_id ?? '')
      if (res?.data?.certificate_id) {
        getCertificateDetail(res?.data?.certificate_id)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const queryParam = {
    courseCategoryId: courseCategoryId,
    status: 'PUBLISHED',
  }

  const onSubmit = async () => {
    setLoading(true)

    if (certificateId !== '') {
      try {
        const request = {
          certificate_id: certificateId
        }

        const response = await CoursesAPI.addCertificate({id: id, data: request})
        if (response) {
          toast.success('Saved successfully!')
          navigate('/courses')
        }
      } catch (error) {
        toast.error('Save failed!')
      } finally {
        setLoading(false)
      }
    } else {
      toast.error('You have not selected a certificate!')
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpenModalUpload(false)
    setCertificateTmp(certificateId)
  }

  const handleSubmit = async () => {
    setCertificateId(certificateTmp)
    setOpenModalUpload(false)
    await getCertificateDetail(certificateTmp)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchCertificateList(page_index, page_size, queryParam)
  }

  useEffect(() => {
    setLoading(true)
    getCourseInfo(id)
  }, [])

  return (
    <div>
      {contextHolder}
        <div className='sapp-certificate-content position-relative overflow-auto d-flex alight-items-center px-5 pb-5 pt-15 sapp-certificate-content bg-white' style={{height: 'calc(100vh - 294px)'}}>
        {certificateInfo?.setting ?
          <div className='position-relative m-auto sapp-w-fit-content'>
            {certificateInfo?.setting?.map((e: any, i: number) => {
              if (e.type === 'TEXT') {
                return (
                  <div
                  key={e?.id}
                  className={`sapp-opacity-bg-border sapp-no-white-space position-absolute ${e?.tag}`}
                  style = {
                    {
                      fontFamily: e?.fontFamily,
                      fontSize: e?.fontSize,
                      color: e?.color,
                      fontWeight: e?.isBold ? '700' : '',
                      fontStyle: e?.isItalic ? 'italic' : 'normal',
                      textDecoration: e?.isThrough ? 'line-through' : e?.isUndefined ? 'underline' : '',
                      textTransform: e?.isUpperCase ? 'uppercase' : 'none',
                      textAlign: e?.textAlign,
                      lineHeight: e?.lineHeight,
                      width: e?.position?.width ,
                      height: e?.position?.height,
                      top: e?.position?.top,
                      left: e?.position?.left
                    }
                  }
                >
                  {e?.value}
                </div>
                )
              }

              if (e.type === 'IMAGE-DEFAULT') {
                return (
                  <>
                    <div
                      className='sapp-font-change-image sapp-color-import-student d-flex align-items-center justify-content-end mb-3 cursor-pointer position-absolute sapp-course-change'
                      onClick={() => {
                        setOpenModalUpload(true)
                        fetchCertificateList(1, 10, queryParam)
                      }}
                    >
                      <span>Choose Template</span>
                      <KTIcon iconName='add-files' iconType='outline' className='fs-1 text-inverse-light ms-3' />
                    </div>
                    <div style={{position: 'relative'}} key={i}>
                      <img
                        src={e?.imageUrl}
                        alt={`Image-${i}`}
                        loading='lazy'
                        className='sapp-max-image-default object-fit-contain'
                      />
                    </div>
                  </>
                )
              }
            })}
          </div>
        :
          <div className='position-absolute rst__icon start-50'>
            <ButtonIcon
              title={'Choose Template'}
              className='w-fit-content d-flex align-items-center text-start sapp-input-add-certificate fw-bold px-3 py-4 sapp-color-import-student bg-light btn btn-primary'
              customButton='btn-sm bg-white'
              onClick={() => {
                setOpenModalUpload(true)
                fetchCertificateList(1, 10, queryParam)
              }}
            >
              <KTIcon iconName='tablet-book' iconType='outline' className='fs-2 text-inverse-light' />
            </ButtonIcon>
          </div>
        }
      </div>
      <div className='mt-10'>
        <SAPPDialogButtonsCancelSubmit
          type='button'
          cancelButtonCaption='Course List'
          okButtonCaption={`Finish`}
          okOnClick={() => {
            onSubmit()
          }}
          className='justify-content-between d-flex'
          loading={loading}
          buttonIcon={true}
          cancelButton={({cancelButtonCaption}) => {
            return (
              <ButtonIcon
                customButton='border border-0 sapp-btn-color'
                title={cancelButtonCaption}
                link='/courses'
              >
                <KTIcon iconName='arrow-left' iconType='solid' className='fs-3 pe-1' />
              </ButtonIcon>
            )
          }}
        />
      </div>
      <SappDrawer
        title='Choose Certificate Template'
        cancelButtonCaption={'Cancel'}
        width='52%'
        okButtonCaption={'Confirm'}
        handleSubmit={handleSubmit}
        dialogClassName='modal-dialog sapp-custom-table-certificate'
        open={!!openModelUpdate}
        handleClose={handleClose}
        confirmOnclose
        classNameBody=''
      >
        <div>
          {loading ? (
              <>
                {HEADER_CLASS_LIST.map((header, i) => (
                  <LoadingTable key={header} headers={HEADER_CLASS_LIST} />
                ))}
              </>
          ) : (
            <div className='sapp-table-certificate'>
              {certificateList?.topics?.map((data: any, index: number) => {
                //const isChecked = checkedList.includes(e.id)
                return (
                  <div
                    key={index}
                    className='cursor-pointer sapp-max-w-283'
                    onClick={() => {
                      setCertificateTmp(data?.id)
                    }}
                  >
                    <div className={`position-relative sapp-item-certificate ${certificateTmp === data?.id ? 'enable': 'disable'}`}>
                      <span className='sapp-custom-dot position-absolute'></span>
                      <div className='sapp-image-certificate'>
                        {data?.setting?.map((e: any, i: number) => {
                          if (e.type === 'TEXT') {
                            return (
                              <div
                                key={e?.id}
                                className={`sapp-opacity-bg-border sapp-no-white-space position-absolute ${e?.tag}`}
                                style = {
                                  {
                                    fontFamily: e?.fontFamily,
                                    fontSize: e?.fontSize,
                                    color: e?.color,
                                    fontWeight: e?.isBold ? '700' : '',
                                    fontStyle: e?.isItalic ? 'italic' : 'normal',
                                    textDecoration: e?.isThrough ? 'line-through' : e?.isUndefined ? 'underline' : '',
                                    textTransform: e?.isUpperCase ? 'uppercase' : 'none',
                                    textAlign: e?.textAlign,
                                    lineHeight: e?.lineHeight,
                                    width: e?.position?.width ,
                                    height: e?.position?.height,
                                    top: e?.position?.top,
                                    left: e?.position?.left
                                  }
                                }
                              >
                                {e?.value}
                              </div>
                            )
                          }

                          if (e.type === 'IMAGE-DEFAULT') {
                            return (
                              <img
                                key={e?.id}
                                src={e?.imageUrl}
                                alt={`Image-${i}`}
                                className='object-fit-contain sapp-w-fit-content sapp-h-fit-content'
                              />
                            )
                          }
                        })}
                      </div>
                    </div>
                    <div className='sapp-text-truncate-2 fs-5 fw-semibold pt-3 sapp-text-primary'>
                      {data?.name}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
      </div>
      <PagiantionSAPP
        currentPage={certificateList?.meta?.page_index || 1}
        pageSize={certificateList?.meta?.page_size || 10}
        totalItems={certificateList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      </SappDrawer>
    </div>
  )
}

export default CourseCertificate
