import ButtonIcon from 'src/components/base/button/ButtonIcon'
import CreatedContent from '../../created-content/CreatedContent'
import {KTIcon} from 'src/_metronic/helpers'
import {convertTime} from 'src/utils/string'
import {htmlToRaw} from 'src/utils'
import {z} from 'zod'
import {VALIDATION_FILED} from 'src/constants'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useState} from 'react'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {debounce} from 'lodash'

interface IProps {
  setOpenQuestionSelect: any
  listTimeLine: any[]
  tab: number
  actionDelete: any
  currentTime: any
  setListTimeLine?: any
  actionEdit?: any
}
const TabVideoQuestion = ({
  setOpenQuestionSelect,
  listTimeLine,
  tab,
  actionDelete,
  currentTime,
  setListTimeLine,
  actionEdit,
}: IProps) => {
  const validationSchema = z.object({
    name: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const {control, setValue, reset, handleSubmit} = useFormProp
  const [creatingNewMenu, setCreatingNewMenu] = useState(false)
  const [editMenu, setEditMenu] = useState<any>()
  // add new item by tab
  const actionAddNew = (e: any) => {
    if (tab === 1) {
      setOpenQuestionSelect({state: true})
    } else {
      setCreatingNewMenu(true)
    }
    e.target.blur()
  }
  //cancel add new menu name
  const handleCancel = () => {
    setCreatingNewMenu(false)
    reset()
  }
  // handle add new menu item
  const handleAddNewMenu = (e: string) => {
    setListTimeLine((prev: any) => {
      const oldList = [...prev]
      oldList.push({
        menuName: e,
        time: Math.floor(currentTime),
      })
      handleCancel()
      return oldList
    })
    reset()
  }
  //handle click edit icon seprate by tab active
  const handleEdit = (i: number) => {
    if (tab === 1) {
      actionEdit(i)
    } else {
      setEditMenu(i)
      setValue('name', listTimeLine[i].menuName)
    }
  }
  //handle edit menu time line
  const handleEditMenuTimeLine = (index: number, name: string) => {
    const oldListMenuTimeLine = [...listTimeLine]
    oldListMenuTimeLine[index].menuName = name
    setListTimeLine(oldListMenuTimeLine)
    setEditMenu(undefined)
    reset()
  }
  //debounce edit and create
  const debouncedHandleCreate = debounce(handleAddNewMenu, 1000)
  const debouncHandleEdit = debounce(handleEditMenuTimeLine, 1000)

  return (
    <div className='w-100 p-8 h-100'>
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <div className='sapp-questionTab-title'>
          {`${tab === 1 ? 'Questions' : 'Timeline'} (${listTimeLine?.length})`}{' '}
        </div>
        <ButtonIcon
          customButton='btn-primary d-flex justify-content-center'
          title={`Add ${tab === 1 ? 'Question' : 'Timeline'}`}
          onClick={(e) => actionAddNew(e)}
        >
          <KTIcon iconName='plus' iconType='solid' className='fs-1' />
        </ButtonIcon>
      </div>
      <div className='sapp-list-question-added-container'>
        <div className='w-100'>
          {creatingNewMenu && tab === 0 && (
            <CreatedContent
              actionDelete={() => {
                // actionDelete(i)
                handleCancel()
              }}
              iconGrayColor={'600'}
              classNameContainer='p-4 mb-5 text-gray-600'
            >
              <div className='d-flex flex-column flex-grow-1'>
                {/* <form > */}
                  <HookFormTextField
                    className='sapp-input-menuVideo'
                    placeholder='Describe this sequence here...'
                    name='name'
                    control={control}
                    onChange={(e) => {
                      debouncedHandleCreate(e.target.value)
                    }}
                    onSubmit={handleSubmit((e) => handleAddNewMenu(e.name))}
                  />
                  <div className='sapp-text-time mt-2'>{convertTime(currentTime)}</div>
                {/* </form> */}
              </div>
            </CreatedContent>
          )}
          {listTimeLine?.length > 0 &&
            listTimeLine?.map((e, i) => {
              if (editMenu === i && tab === 0) {
                return (
                  <CreatedContent
                    actionDelete={() => {
                      actionDelete(i)
                      setEditMenu(undefined)
                      reset()
                    }}
                    iconGrayColor={'600'}
                    classNameContainer='p-4 mb-5 text-gray-600'
                  >
                    <div className='d-flex flex-column flex-grow-1'>
                      {/* <form > */}
                        <HookFormTextField
                          className='sapp-input-menuVideo'
                          placeholder='Describe this sequence here...'
                          name='name'
                          control={control}
                          onChange={(e) => {
                            debouncHandleEdit(i, e.target.value)
                          }}
                          onSubmit={handleSubmit((e) => handleEditMenuTimeLine(i, e.name))}
                        />
                        <div className='sapp-text-time mt-2'>{convertTime(e.time)}</div>
                      {/* </form> */}
                    </div>
                  </CreatedContent>
                )
              }
              return (
                <CreatedContent
                  actionDelete={() => {
                    actionDelete(i)
                  }}
                  key={i}
                  actionEdit={() => {
                    handleEdit(i)
                  }}
                  iconGrayColor={'600'}
                  classNameContainer='p-4 mb-5 text-gray-600'
                >
                  <div style={{width: 'calc(100% - 80px)'}}>
                    <div className='sapp-text-truncate-1 text-wrap sapp-question-name border-light border-bottom mb-4'>
                      {htmlToRaw(e.menuName)}
                    </div>
                    <div className='sapp-text-time'>{convertTime(e.time)}</div>
                  </div>
                </CreatedContent>
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default TabVideoQuestion
