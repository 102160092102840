import React, { ReactNode } from 'react'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom';
import { TooltipPlacement } from 'antd/es/tooltip';
interface IProps {
    title: string;
    link?: string | undefined
    placement?: TooltipPlacement | undefined
    max_length?: number
    children?: ReactNode
}

const SappTooltip = ({ title, link, placement, max_length, children }: IProps) => {
    const isLongTitle = max_length ? title?.length > max_length : title?.length > 40;

    const renderTooltipContent = () => (
        <div className='menu-item fs-7'>
            <div className='menu-link p-0 text-gray-600'>
                {title}
                {children}
            </div>
        </div>
    );

    return (
        <>
            {isLongTitle ? (
                <Tooltip arrow title={renderTooltipContent()} color='#ffffff' placement={placement ?? 'top'}>
                    <Link className={`text-gray-800 text-hover-primary sapp-text-truncate-1`} to={link ?? ''}>
                        {title}
                        {children}
                    </Link>
                </Tooltip>
            ) : (
                <Link className={`text-gray-800 text-hover-primary sapp-text-truncate-1`} to={link ?? ''}>
                    {title}
                    {children}
                </Link>
            )}
        </>
    );
};

export const SappTooltipArray = ({ title, key, link }: { title: string, key: string, link?: string | undefined }) => {
    if (link) {
        return (
            <Tooltip title={title} color='white' key={key}>
                <Link className='sapp-text-truncate-1 text-gray-600' to={link}>{title}</Link>
            </Tooltip>
        )
    }
    
    return (
        <Tooltip title={title} color='white' key={key} >
            <div className='sapp-text-truncate-1'>{title}</div>
        </Tooltip>
    )
}

export default SappTooltip
