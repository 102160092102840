import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import CourseDetail from 'src/components/courses/course-detail'
import { PageLink, STAFF_PROFILE, STUDENT_PROFILE, TEACHER_PROFILE } from 'src/constants'
import NotFound from 'src/pages/404/NotFound'
import ChangePassword from 'src/pages/change-password'
import ChangePasswordSuccess from 'src/pages/change-password/ChangePasswordSuccess'
import Courses from 'src/pages/courses'
import CourseCategory from 'src/pages/courses/categories'
import Test from 'src/pages/courses/test'
import Dashboard from 'src/pages/dashboard'
import Dictionary from 'src/pages/dictionary'
import FogotPassword from 'src/pages/fogot-password'
import VerifyCode from 'src/pages/fogot-password/VerifyCode'
import ImportLog from 'src/pages/import-log/Index'
import ImportLogDetail from 'src/pages/import-log/detail'
import Login from 'src/pages/login'
import MyProfile from 'src/pages/my-profile'
import CreateNews from 'src/pages/news/CreateNews'
import NewsList from 'src/pages/news/ListNews'
import ListGroupReceiver from 'src/pages/group-receiver/ListGroupReceiver'
import ListNotification from 'src/pages/notifications/ListNotification'
import SendNotification from 'src/pages/notifications/SendNotification'
import Permissions from 'src/pages/permissions'
import Setting from 'src/pages/setting'
import StaffProfile from 'src/pages/staff-profile'
import CreateAndUpdateStaff from 'src/pages/staffs/CreateAndUpdateStaff'
import ListStaff from 'src/pages/staffs/ListStaff'
import StudentProfile from 'src/pages/student-profile'
import CreateAndUpdateStudent from 'src/pages/students/CreateAndUpdateStudent'
import ListStudents from 'src/pages/students/ListStudents'
import SupportCenter from 'src/pages/support-center'
import TeacherProfile from 'src/pages/teacher-profile'
import CreateAndUpdateTeacher from 'src/pages/teachers/CreateAndUpdateTeacher'
import Topics from 'src/pages/topics'
import Topic from 'src/pages/topics/Topic'
import ListTeachers from 'src/pages/teachers'
import ListStory from 'src/pages/story/ListStory'
import CreateStory from 'src/pages/story/CreateStory'
import CaseStudy from 'src/pages/courses/case-study/Index'
import Folders from 'src/pages/file-manager/Folders'
import PreviewCourseDetail from 'src/pages/preview-course-detail'
import Classes from 'src/pages/classes'
import CreateClassInfo from 'src/pages/classes/create/CreateClassInfo'
import ClassDetail from 'src/pages/classes/detail/ClassDetail'
import ImportLogStudents from 'src/pages/import-logs-class/ImportLogStudents'
import ImportStudentDetail from 'src/pages/import-logs-class/ImportStudentDetail'
import CertificatePage from 'src/pages/certificate'
import CertificatesTemplatePage from 'src/pages/certificate/CertificatesTemplatePage'
import EntranceTestList from 'src/pages/entrance-test'
/*import CreateGroup from "src/pages/group-receiver/CreateGroup";*/
import ParticipantList from 'src/pages/entrance-test/participantList'
import CreateTestInfo from 'src/pages/entrance-test/create/CreateTestInfo'
import CreateAndUpdateGroup from 'src/pages/group-receiver/CreateAndUpdateGroup'
import RolesList from 'src/pages/roles'
import ApplyRoles from 'src/pages/roles/apply-roles/ApplyRoles'
import RoleDetail from 'src/pages/roles/detail/RoleDetail'
import QuizResultTable from 'src/pages/entrance-test/result/test-result/QuizResultTable'
import TestResult from 'src/pages/entrance-test/result/[id]'
import Explanation from 'src/pages/entrance-test/result/test-result/explanation'
import { CODE_ADMIN, TITLE_GR, TITLE_TOPIC_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'

const AppRouter = () => {
  const useAuthorization = (permissions: string | string[]) => {
    const { profileMe } = useUserContext()
    const permissionArray = Array.isArray(permissions) ? permissions : [permissions]

    const hasPermission =
      profileMe &&
      profileMe?.roles?.some((role: Role) => {
        return (
          permissionArray.some((permission: string) => role.permissions?.includes(permission)) ||
          role.code === CODE_ADMIN.SUPER_ADMIN
        )
      })
    return hasPermission
  }


  const ProtectedRoute = ({
    permission,
    element,
    ...rest
  }: {
    permission: string[] | string
    element: React.ReactNode
  }) => {
    const hasPermission = useAuthorization(permission)
    return hasPermission ? <>{element}</> : <Navigate to={PageLink.DASHBOARD} />
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to={PageLink.AUTH_LOGIN} />} />
        <Route path={PageLink.AUTH_FORGOTPASSWORD} element={<FogotPassword />} />
        <Route path={PageLink.ENTER_OTP} element={<VerifyCode />} />
        <Route path={PageLink.AUTH_CHAGEPASSWORD} element={<ChangePassword />} />
        <Route path={PageLink.AUTH_CHANGE_PASSWORD_SUCCESS} element={<ChangePasswordSuccess />} />
        <Route path={PageLink.AUTH_LOGIN} element={<Login />} />
        <Route path={`${PageLink.PROFILE}/*`} element={<MyProfile />} />
        <Route path={`${STAFF_PROFILE}/:id/*`} element={<StaffProfile />} />
        <Route
          path={`${STUDENT_PROFILE}/:id/*`}
          element={
            <ProtectedRoute permission={
              [TITLE_GR.VIEW_USER_GR,
                TITLE_GR.REMOVE_USER_GR,
                TITLE_GR.EDIT_USER_GR,
                TITLE_GR.GET_DEVICES_USER_GR,
                TITLE_GR.REMOVE_DEVICES_USER_GR,
                TITLE_GR.MAKE_CONTACT_DEFAULT_GR,
                TITLE_GR.GET_ACTIVITIES_USER_GR,
                TITLE_GR.CREATE_USER_GR,
              ]} element={<StudentProfile />} />
          }
        />
        <Route
          path={`${TEACHER_PROFILE}/:id/*`}
          element={
            <ProtectedRoute permission={ [TITLE_GR.VIEW_USER_GR,
              TITLE_GR.REMOVE_USER_GR,
              TITLE_GR.EDIT_USER_GR,
              TITLE_GR.GET_DEVICES_USER_GR,
              TITLE_GR.REMOVE_DEVICES_USER_GR,
              TITLE_GR.MAKE_CONTACT_DEFAULT_GR,
              TITLE_GR.GET_ACTIVITIES_USER_GR,
              TITLE_GR.CREATE_USER_GR,
            ]} element={<TeacherProfile />} />
          }
        />
        <Route path={`${PageLink.SUPPORT_CENTER}/*`} element={<SupportCenter />} />
        <Route path={PageLink.ROLES} element={<RolesList />} />
        <Route path={PageLink.ADD_ROLES} element={<ApplyRoles />} />
        <Route path={`${PageLink.UPDATE_ROLES}/:id`} element={<ApplyRoles />} />
        <Route path={`${PageLink.ROLE_DETAIL}/:id`} element={<RoleDetail />} />
        {/* <Route path={PageLink.PERMISSIONS} element={<Permissions />} /> */}
        <Route path={PageLink.CREATE_STAFF} element={<CreateAndUpdateStaff />} />
        <Route path={`${PageLink.UPDATE_STAFF}/:id`} element={<CreateAndUpdateStaff />} />
        <Route path={PageLink.STAFFS} element={<ListStaff />} />
        <Route path={PageLink.NEWS} element={<NewsList />} />
        <Route path={PageLink.CREATE_NEWS} element={<CreateNews />} />
        <Route path={`${PageLink.NOTIFICATIONS}/*`} element={<ListNotification />} />
        <Route path={`${PageLink.LIST_GROUP}/*`} element={<ListGroupReceiver />} />
        <Route path={PageLink.SEND_NOTIFICATIONS} element={<SendNotification />} />
        <Route path={`${PageLink.SEND_NOTIFICATIONS}/:id`} element={<SendNotification />} />
        <Route
          path={`${PageLink.STUDENTS}/*`}
          element={
            <ProtectedRoute
              permission={[
                TITLE_GR.VIEW_USER_GR,
                TITLE_GR.EDIT_USER_GR,
                TITLE_GR.REMOVE_USER_GR,
                TITLE_GR.IMPORT_USER_GR,
                TITLE_GR.CREATE_USER_GR,
                TITLE_GR.GET_DEVICES_USER_GR,
                TITLE_GR.REMOVE_DEVICES_USER_GR,
                TITLE_GR.MAKE_CONTACT_DEFAULT_GR,
                TITLE_GR.GET_ACTIVITIES_USER_GR,
                TITLE_GR.PUT_CHANGE_EMAIL_USER_GR,
                TITLE_GR.PUT_RESET_PASSWORD_USER_GR,
                TITLE_GR.GET_EXPORT_DATA_USER_GR,
              ]}
              element={<ListStudents />}
            />
          }
        />
        <Route
          path={PageLink.CREATE_STUDENTS}
          element={
            <ProtectedRoute
              permission={TITLE_GR.CREATE_USER_GR}
              element={<CreateAndUpdateStudent />}
            />
          }
        />
        <Route
          path={`${PageLink.UPDATE_STUDENT}/:id`}
          element={
            <ProtectedRoute
              permission={TITLE_GR.CREATE_USER_GR && TITLE_GR.EDIT_USER_GR}
              element={<CreateAndUpdateStudent />}
            />
          }
        />
        <Route path={PageLink.DASHBOARD} element={<Dashboard />} />
        <Route path={PageLink.ENTRANCE_TEST_LIST} element={<EntranceTestList />} />
        <Route path={PageLink.ENTRANCE_CREATE_TEST_LIST} element={<CreateTestInfo />} />
        <Route path={`${PageLink.ENTRANCE_EDIT_TEST_LIST}/:id`} element={<CreateTestInfo />} />
        <Route
          path={`${PageLink.ENTRANCE_TEST_RESULT_SCORE_DETAIL}/:id`}
          element={<TestResult />}
        />
        <Route path={`${PageLink.ENTRANCE_QUIZ_RESULT_TABLE}/:id`} element={<QuizResultTable />} />
        <Route path={`${PageLink.ENTRANCE_QUIZ_EXPLANATION}/:id`} element={<Explanation />} />
        <Route path={PageLink.PARTICIPANTS_LIST} element={<ParticipantList />} />
        <Route path={`${PageLink.COURSES}/*`} element={<Courses />} />
        <Route path={`${PageLink.CLASSES}/*`} element={<Classes />} />
        <Route path={`${PageLink.CREATE_CLASSES}`} element={<CreateClassInfo />} />
        <Route path={`${PageLink.CLASS_DETAIL}/:id/*`} element={<ClassDetail />} />
        <Route path={`${PageLink.COURSE_CATEGORY}/*`} element={<CourseCategory />} />
        <Route path={`${PageLink.TEST}/:type/:testFor/:courseId/:id`} element={<Test />} />
        <Route path={`${PageLink.TEST_EDIT}/:type/:courseId/:editId`} element={<Test />} />
        <Route path={`${PageLink.CASE_STUDY}/:courseId/:courseSectionId`} element={<CaseStudy />} />
        <Route
          path={`${PageLink.CASE_STUDY}/:courseId/:courseSectionId/:id`}
          element={<CaseStudy />}
        />
        {/* <Route path={`${PageLink.COURSE_FULL}/undefined`} element={<CreateAndUpdateCourseInfo />} /> */}
        {/* <Route path={`${PageLink.COURSE_FULL}/:id`} element={<CreateAndUpdateCourseInfo />} /> */}
        <Route path={PageLink.TOPICS} element={<ProtectedRoute
          permission={[TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC,
            TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC,
            TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC,
            TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC,]
          }
          element={<Topics />}
        />
        } />
        <Route path={`${PageLink.TOPIC}/:id`} element={<Topic />} />
        <Route path={`${PageLink.TOPIC}`} element={<Topic />} />
        <Route path={PageLink.SETTING} element={<Setting />} />
        <Route path={PageLink.DICTIONARY} element={<Dictionary />} />
        <Route
          path={PageLink.TEACHERS}
          element={
            <ProtectedRoute
              permission={[TITLE_GR.VIEW_USER_GR,
              TITLE_GR.EDIT_USER_GR,
              TITLE_GR.REMOVE_USER_GR,
              TITLE_GR.IMPORT_USER_GR,
              TITLE_GR.CREATE_USER_GR,
              TITLE_GR.GET_DEVICES_USER_GR,
              TITLE_GR.REMOVE_DEVICES_USER_GR,
              TITLE_GR.MAKE_CONTACT_DEFAULT_GR,
              TITLE_GR.GET_ACTIVITIES_USER_GR,
              TITLE_GR.PUT_CHANGE_EMAIL_USER_GR,
              TITLE_GR.PUT_RESET_PASSWORD_USER_GR,
              TITLE_GR.GET_EXPORT_DATA_USER_GR,]}
              element={<ListTeachers />}
            />
          }
        />
        <Route
          path={PageLink.CREATE_TEACHERS}
          element={
            <ProtectedRoute
              permission={TITLE_GR.CREATE_USER_GR}
              element={<CreateAndUpdateTeacher />}
            />
          }
        />
        <Route
          path={`${PageLink.UPDATE_TEACHERS}/:id`}
          element={
            <ProtectedRoute
              permission={TITLE_GR.CREATE_USER_GR && TITLE_GR.EDIT_USER_GR}
              element={<CreateAndUpdateTeacher />}
            />
          }
        />
        <Route path={`${PageLink.IMPORT_LOG}/:type`} element={<ImportLog />} />
        <Route path={`${PageLink.IMPORT_LOG}/detail/:type/:id`} element={<ImportLogDetail />} />
        <Route path={`${PageLink.COURSE_FULL}/:id`} element={<CourseDetail />} />
        <Route path={PageLink.STORY} element={<ListStory />} />
        <Route path={PageLink.CREATE_STORY} element={<CreateStory />} />
        <Route path={`${PageLink.STORY}/:id`} element={<CreateStory />} />
        <Route path={PageLink.FOLDER_LIST} element={<Folders />} />
        <Route path={`${PageLink.PREVIEW_PART}/:id`} element={<PreviewCourseDetail />} />
        <Route
          path={`${PageLink.PREVIEW_UNIT}/:id/unit/:unitId`}
          element={<PreviewCourseDetail />}
        />
        <Route path={PageLink.RESOURCE_MANAGER} element={<Folders />} />
        <Route path={PageLink.CERTIFICATES} element={<CertificatePage />} />
        <Route path={PageLink.CREATE_TEMPLATE} element={<CertificatesTemplatePage />} />
        <Route path={`${PageLink.CREATE_TEMPLATE}/:id`} element={<CertificatesTemplatePage />} />
        <Route path={`/logs/:type`} element={<ImportLogStudents />} />
        <Route path={`/logs/detail/:type/:id`} element={<ImportStudentDetail />} />
        <Route path={`${PageLink.CREATE_GROUP}`} element={<CreateAndUpdateGroup />} />
        <Route path={`${PageLink.UPDATE_GROUP}/:id`} element={<CreateAndUpdateGroup />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
