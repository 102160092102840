import React, {Dispatch, SetStateAction, useEffect, useMemo} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import {BREAKPOINT, DENIED_MESSAGES, FORMAT_DATETIME, MY_PROFILE, STUDENT_PROFILE} from 'src/constants'
import {IStudentDetail} from 'src/type/students'
import {format} from 'date-fns'
import {useUserContext} from 'src/context/UserProvider'
import cmndFront from 'src/_metronic/assets/media/auth/cmndmattruoc.png'
import cmndBack from 'src/_metronic/assets/media/auth/cmndmatsau.png'
import useWindowWidth from 'src/hooks/use-width'
import {LANG_PLACEHOLDER} from 'src/constants/lang'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import toast from 'react-hot-toast'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  studentDetail: IStudentDetail | undefined
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const StudentProfileDetail = ({studentDetail, loading, setStudentDetail}: IProps) => {
  const {id} = useParams()
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some((role: Role) => role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN);
  const allowRenderMakeDefault = profileMe?.roles?.some((role: Role)  => role.permissions?.includes(TITLE_GR.MAKE_CONTACT_DEFAULT_GR) || role.code === CODE_ADMIN.SUPER_ADMIN);

  const {getStudentDetail, makeUseContactDefault} = useUserContext()

  const widthWindow = useWindowWidth()
  let identity_card_front = null
  let identity_card_back = null

  if (widthWindow > BREAKPOINT.xs) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['280x180']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['280x180']
  }

  if (widthWindow > BREAKPOINT.md) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['320x190']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['320x190']
  }

  if (widthWindow > BREAKPOINT.xl) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['370x220']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['370x220']
  }

  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id)
      .then((res) => {
        setStudentDetail(res?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.code === '403|000000') {
          toast.error(DENIED_MESSAGES);
        }
      });
  }, [id])

  /**
   * Sắp xếp mảng người dùng theo thời gian tạo và is_default.
   *
   * @param {Array} users - Mảng người dùng cần sắp xếp.
   * @returns {Array} - Mảng đã sắp xếp theo is_default và created_at.
   */
  const sortByCreatedAtAndDefault = (users: Array<any>) => {
    const sortedUsers = [...users]

    sortedUsers.sort((a, b) => {
      if (a.is_default && !b.is_default) return -1
      if (!a.is_default && b.is_default) return 1

      const dateA = new Date(a.created_at)
      const dateB = new Date(b.created_at)

      return dateB.getTime() - dateA.getTime()
    })

    return sortedUsers
  }
 
  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header'>
              <div className='card-title m-0'>
                <h3 className='fw-bold m-0'>Overview</h3>
              </div>
              {studentDetail?.status !== 'MERGED' && allowRender && (
                <Link
                  to={`${STUDENT_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
                  className='btn btn-sm btn-primary align-self-center'
                >
                  Edit Profile
                </Link>
              )}
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-xl-3 col-lg-12 fw-semibold text-muted'>CMND/CCCD</label>
                <div className='col-xl-4 col-md-6 col-lg-12'>
                  <span className='fw-semibold text-gray-800 fs-6'>
                    <div className='image-input image-input-outline image-input-placeholder'>
                      <div
                        className={`image-input-wrapper sapp-w-front--face sapp-h-front--face`}
                        style={{
                          backgroundImage: `url(${
                            identity_card_front ??
                            studentDetail?.detail?.identity_card_image_front?.ORIGIN ??
                            cmndFront
                          })`,
                        }}
                      ></div>

                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                      >
                        <i className='ki-outline ki-cross fs-2'></i>
                      </span>
                    </div>
                  </span>
                </div>
                <div className='col-xl-4 col-md-6 col-lg-12'>
                  <span className='fw-semibold text-gray-800 fs-6'>
                    <div className='image-input image-input-outline image-input-placeholder'>
                      <div
                        className={`image-input-wrapper sapp-w-front--face sapp-h-front--face`}
                        style={{
                          backgroundImage: `url(${
                            identity_card_back ??
                            studentDetail?.detail?.identity_card_image_back?.ORIGIN ??
                            cmndBack
                          })`,
                        }}
                      ></div>

                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                      >
                        <i className='ki-outline ki-cross fs-2'></i>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <ItemProfile title='Code' body={studentDetail?.key} />
              {/* <ItemProfile title='Examination Code' body={studentDetail?.code} /> */}
              <ItemProfile
                title={LANG_PLACEHOLDER.FULLNAME}
                body={studentDetail?.detail?.full_name}
              />
              <ItemProfile title='Username' body={studentDetail?.username} />
              <ItemProfile title='Status' status={studentDetail?.status} />
              <ItemProfile title='Major' body={studentDetail?.detail?.major} />
              <ItemProfile title='University' body={studentDetail?.detail?.university} />
              <ItemProfile
                title='Gender'
                body={
                  studentDetail?.detail?.sex === 'MALE'
                    ? 'Male'
                    : studentDetail?.detail?.sex === 'FEMALE'
                    ? 'Female'
                    : studentDetail?.detail?.sex === 'OTHERS'
                    ? 'Others'
                    : '-'
                }
              />
              <ItemProfile
                title='Learning Purpose'
                body={studentDetail?.detail?.learning_purpose}
              />
              <ItemProfile title='Contact Detail' body={studentDetail?.detail?.contact_detail} />
              <ItemProfile title='Special Note' body={studentDetail?.detail?.special_note} />
              <ItemProfile title='Classification' body={studentDetail?.detail?.classification} />
              <ItemProfile title='Dob' body={studentDetail?.detail?.dob} />
              <ItemProfile title='Note' body={studentDetail?.detail?.note} />
              <ItemProfile
                title='Updated At'
                body={
                  studentDetail?.updated_at
                    ? format(new Date(studentDetail?.updated_at), FORMAT_DATETIME)
                    : ''
                }
              />
              <ItemProfile title='Is Staff?' body={studentDetail?.is_sapp_operator ? 'Yes' : 'No'} />
            </div>
          </div>

          {sortByCreatedAtAndDefault(studentDetail?.user_contacts || [])?.map((e, i) => {
            return (
              <div className='card mb-5 mb-xl-10' key={e.id}>
                <div className='card-header'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bold m-0 '>Profile {i + 1}</h3>
                    {e.is_default && (
                      <div className='badge badge-light-success justify-content-center min-w-55px ms-5'>
                        Default
                      </div>
                    )}
                  </div>

                  {!e.is_default && allowRenderMakeDefault && (
                    <ButtonSecondary
                      className='btn btn-sm btn-bg-light btn-active-color-primary align-self-center'
                      title={'Make This Default'}
                      onClick={async () => {
                        try {
                          await makeUseContactDefault({
                            userId: studentDetail?.id || '',
                            userContactId: e.id,
                          })
                          getStudentDetail(id).then((res) =>
                            setStudentDetail({
                              ...res?.data,
                              user_contacts: res?.data.user_contacts,
                            })
                          )
                          toast.success('Cập nhật Profile mặc định thành công!')
                        } catch (error) {}
                      }}
                    ></ButtonSecondary>
                  )}
                </div>

                <div className='card-body p-9'>
                  <div className='d-flex'>
                    <div className='sapp-flex-1 justify-content-between'>
                      {e.email && <ItemProfile title='Email' body={e.email} />}
                      {e.phone && <ItemProfile title='Phone Number' body={e.phone} />}
                    </div>
                    <div className=''></div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default StudentProfileDetail
