import React, {Dispatch, SetStateAction} from 'react'
import {VALIDATION_FILED} from 'src/constants'
import SAPPTextFiled from 'src/components/base/textfield/SAPPTextFiled'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import {KTIcon} from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import {Modal} from 'react-bootstrap'

interface IProps {
  description: string
  mean: string
  pronunciation: string
  word: string
}

const CreateAndUpdateDictionary = ({
  titleModal,
  open,
  setOpen,
}: {
  titleModal: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) => {
  // validate for input
  const validationSchema = z.object({
    description: z.string({required_error: VALIDATION_FILED}).min(1),
    mean: z.string({required_error: VALIDATION_FILED}).min(1),
    pronunciation: z.string({required_error: VALIDATION_FILED}).min(1),
    word: z.string({required_error: VALIDATION_FILED}).min(1),
  })

  const {control, handleSubmit, reset} = useForm<IProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  // function when submit form
  const onSubmit = (data: IProps) => {
    reset()
    console.log(data)
  }

  return (
    <Modal dialogClassName='modal-dialog modal-dialog-centered mw-750px' show={open}>
      <div className='modal-content rounded'>
        {/* start:: header modal */}
        <div className='modal-header'>
          <h2 className='mb-0'>{titleModal}</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setOpen(false)}
          >
            <i className='ki-outline ki-cross fs-1'/>
          </div>
        </div>
        {/* end:: header modal */}

        {/* start:: body modal */}
        <div className='modal-body scroll-y px-10 px-lg-15 pt-10 pb-15'>
          <form className='form'>
            {/* start:: textfield description */}
            <div className='d-flex flex-column mb-8 fv-row'>
              <HookFormTextField
                name='description'
                control={control}
                label='Thuật ngữ'
                placeholder='Thuật ngữ'
              />
            </div>
            {/* end:: textfield description */}

            {/* start:: textfield description */}
            <div className='d-flex flex-column mb-8 fv-row'>
              <HookFormTextField
                name='mean'
                control={control}
                label='Dịch nghĩa'
                placeholder='Dịch nghĩa'
              />
            </div>
            {/* end:: textfield mean */}

            {/* start:: textfield pronunciation */}
            <div className='d-flex flex-column mb-8 fv-row'>
              <HookFormTextField
                name='pronunciation'
                control={control}
                label='Phiên âm'
                placeholder='Phiên âm'
              />
            </div>
            {/* end:: textfield pronunciation */}

            {/* start:: upload audio */}
            <div className='row g-9 mb-8'>
              <div className='col-md-9 fv-row'>
                <label className='required fs-6 fw-bold mb-3'>Phát âm</label>
                <SAPPTextFiled disabled placeholder='URL' />
              </div>

              <div className='col-md-3 fv-row'>
                <p className='fs-6 fw-semibold mb-2' style={{visibility: 'hidden'}}>
                  Upload
                </p>
                <ButtonIcon title='Upload' className='mx-3 w-120px'>
                  <KTIcon iconName='plus' className='fs-2' />
                </ButtonIcon>
              </div>
            </div>
            {/* end:: upload audio */}

            {/* start:: textfield word */}
            <div className='d-flex flex-column mb-8 fv-row'>
              <HookFormTextArea
                name='word'
                control={control}
                label='Diễn giải'
                placeholder='Diễn giải'
              />
            </div>
            {/* end:: textfield word */}

            {/* start:: dialog buton cancel submit */}
            <SAPPDialogButtonsCancelSubmit
              cancelButtonCaption='Cancel'
              okButtonCaption='Submit'
              okOnClick={handleSubmit(onSubmit)}
              cancelClick={() => setOpen(false)}
            />
            {/* end:: dialog buton cancel submit */}
          </form>
        </div>
        {/* end:: body modal */}
      </div>
    </Modal>
  )
}

export default CreateAndUpdateDictionary
