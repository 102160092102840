import PageLayouts from 'src/components/layout/PageLayouts'
import {Col, Row} from 'react-bootstrap'
import {useLayoutEffect, useEffect, useState, useRef} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useConfirm} from 'src/hooks/use-confirm'
import {ITabs} from 'src/type'
import {PageLink, VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {IClass} from 'src/type/classes'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import {Select} from 'antd'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {KTIcon} from 'src/_metronic/helpers'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import AddCourseToClass from 'src/components/classes/add-course/AddCourseToClass'
import {ClassesApi} from 'src/apis/classes'
import {getAPIUser} from 'src/apis/user'
import {
  TITLE_OPTIONS_CLASS,
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_TYPE_CLASS,
  OPTIONS_OF_DURATION,
  categoryCourseType,
  OPTIONS_OF_STATUS
} from 'src/constants/classes'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import ListUser from 'src/components/classes/create/ListUser'
import ErrorMessage from 'src/common/ErrorMessage'
import AddExam from 'src/components/classes/add-exam/AddExam'
import {toast} from 'react-hot-toast'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import {debounce, isEmpty} from 'lodash'
import {format} from 'date-fns'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'

const defaultValues = {
  name: '',
  code: '',
  status: '',
  area_id: '',
  instruction_mode: '',
  type: '',
  description: '',
}

const CreateClassInfo = () => {
  const {Option} = Select
  const navigate = useNavigate()
  const location = useLocation()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const [checkedListCourse, setCheckedListCourse] = useState<any>([])
  const [openAddCourse, setOpenAddCourse] = useState<boolean>(false)
  const [typeCourse, setTypeCourse] = useState<string>('')
  const [selectedRadio, setSelectedRadio] = useState<string>(`${OPTIONS_OF_DURATION.valueFixed}`)
  const [areaClasses, setAreaClasses] = useState<any[]>([])
  const [mentorClasses, setMentorClasses] = useState<any>()
  const [lecturerClasses, setLecturerClasses] = useState<any>()
  const [user, setUser] = useState<any>([])
  const listUserRef = useRef<any>(null);
  const [openAddExam, setOpenAddExam] = useState<{status: boolean; type: string}>({
    status: false,
    type: '',
  })
  const [checkedListExam, setCheckedListExam] = useState<any>([])
  const [validateCourse, setValidateCourse] = useState<boolean>(false)
  const [classId, setClassId] = useState<string>('')

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.CLASSES}`,
      title: `${TITLE_OPTIONS_CLASS.listClasses}`,
    },
    {
      link: '',
      title: `${classId ? TITLE_OPTIONS_CLASS.updateClass : TITLE_OPTIONS_CLASS.createClass}`,
    },
  ]

  const [typeInstruction, setTypeInstruction] = useState('')

  const validationSchema = {
    name: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    code: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Code', 1000)),
    status: z
      .string({required_error: VALIDATION_FILED})
      .optional(),
    // area_id: z
    //   .string({required_error: VALIDATION_FILED})
    //   .trim()
    //   .min(1, VALIDATION_FILED),
    instruction_mode: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    type: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    capacity: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    lecture_id: z
      .string({required_error: VALIDATION_FILED})
      .optional(),
    mentor_id: z
      .string({required_error: VALIDATION_FILED})
      .optional(),
    duration_type: z
      .string({required_error: VALIDATION_FILED})
      .trim()
      .min(1, VALIDATION_FILED),
    description: z.string().optional(),
    ...(selectedRadio === 'FIXED' && {
      duration_at: z.object({
        fromDate: z.date(),
        toDate: z.date()
      }).optional().refine((data) => data?.fromDate  && data?.toDate , VALIDATION_FILED)
    }),
    ...(selectedRadio === 'FLEXIBLE' && {
      flexible_days: z
        .string({required_error: VALIDATION_FILED})
        .trim()
        .min(1, VALIDATION_FILED),
    }),
  }
  
  const schemaOnline = z
    .object(
      Object.assign({}, validationSchema, {
        area_id: z.any(),
      })
    )

  const schemaOffline = z
    .object(
      Object.assign({}, validationSchema, {
        area_id: z
          .string({ required_error: VALIDATION_FILED })
          .trim()
          .min(1, VALIDATION_FILED),
      })
    )

  const validateRequiredArea = typeInstruction !== 'ONLINE'

  const useFormProp = useForm<IClass>({
    resolver: zodResolver(validateRequiredArea ? schemaOffline : schemaOnline),
    mode: 'onSubmit',
    defaultValues,
  })

  const {
    handleSubmit,
    getValues,
    control,
    setError,
    setValue
  } = useFormProp

  const onSubmit = async (data: IClass) => {
    setLoading(true)

    let request: IClass = {
      name: data.name,
      code: data.code,
      status: data.status || 'DRAFT',
      area_id: data.area_id || undefined,
      lecture_id: data.lecture_id || null,
      instruction_mode: data.instruction_mode,
      type: data.type,
      capacity: Number(data.capacity),
      mentor_id: data.mentor_id || null,
      duration_type: data.duration_type,
      excepted_course_section: [],
      description: data?.description ?? '',
      course_id: null,
    }

    if (data.duration_type === 'FLEXIBLE' && data.flexible_days) {
      const getThisDay = new Date()
      const startAt = getThisDay.toISOString()
      const openAt = getThisDay.toISOString()
      const finishAt = new Date(getThisDay.setDate(getThisDay.getDate() + data.flexible_days)).toISOString()
      const flexibleDays = {
        flexible_days: Number(data.flexible_days),
        opening_at: openAt,
        started_at: startAt,
        finished_at: finishAt
      }
      Object.assign(request, flexibleDays)
    } else {
      const objectDateFixed = {
        opening_at: getValues('opening_at') !== null ? getValues('opening_at') : data?.duration_at.fromDate,
        started_at: data?.duration_at.fromDate, 
        finished_at: data?.duration_at.toDate,
        flexible_days: 1,
      }
      Object.assign(request, objectDateFixed)
    }

    if (checkedListCourse[0]?.id !== undefined) {
      Object.assign(request, {course_id: checkedListCourse[0]?.id})
    }

    let objectListExam = {}
    if (checkedListExam[0]?.type === 'CFA') {
      objectListExam = {cfa_examination_id: `${checkedListExam[0]?.id}`}
    } else if (checkedListExam[0]?.type === 'ACCA') {
      objectListExam = {acca_examination_id: `${checkedListExam[0]?.id}`}
    } else if (checkedListExam[0]?.type === 'CMA'){
      objectListExam = {cma_examination_id: `${checkedListExam[0]?.id}`}
    } else {
      objectListExam = {
        cfa_examination_id: null,
        acca_examination_id: null,
        cma_examination_id: null
      }
    }
    Object.assign(request, objectListExam)

    try {
      if (classId) {
        Object.assign(request, {id: classId})
        const res = await ClassesApi.updateClass(request)
        if (res) {
          toast.success('Class updated successfully!')
        }
      } else {
        const res = await ClassesApi.createClass({data: {...request}})
        if (res) {
          toast.success('Class saved successfully!')
          setClassId(res?.data?.id || '')
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '422|000405') {
        setError('code', {
          type: 'custom',
          message: 'Code does exist!',
        })
        toast.error('Class save failed!')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCheckedListExam([])
  }, [checkedListCourse])
  useEffect(() => {
    if (classId && listUserRef?.current) {
      listUserRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [classId]);

  const handleChangeType = () => {
    setCheckedListCourse([])
  }

  const fetchAreaClasses = async ({page_index, page_size}: any) => {
    try {
      const res = await ClassesApi.getAreaClasses({page_index: page_index, page_size: page_size})
      setAreaClasses(res?.data?.areas)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLectureClasses = async ({page_index, page_size, type, params}: any) => {
    try {
      const res = await getAPIUser({page_index: page_index, page_size: page_size, type: type, params: params})
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMentorClasses = async ({page_index, page_size, params}: any) => {
    try {
      const res = await ClassesApi.getAPIStaffs({page_index: page_index, page_size: page_size, params: params})
      return res
    } catch (error) {
      console.log(error)
    }
  }  

  const handlNextPageLecture = async ({params}: any) => {
    const totalPages = lecturerClasses?.metadata?.total_pages
    const pageIndex = lecturerClasses?.metadata?.page_index
    const pageSize = lecturerClasses?.metadata?.page_size
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchLectureClasses({page_index: pageIndex + 1, page_size: pageSize, type: 'TEACHER', params: params})
      const results = lecturerClasses.users.concat(res?.data?.users) 
      setLecturerClasses({
        metadata: res?.data?.metadata,
        users: results,
      })
    }
  }

  const handlNextPageMentor = async ({params}: any) => {
    const totalPages = mentorClasses?.metadata?.total_pages
    const pageIndex = mentorClasses?.metadata?.page_index
    const pageSize = mentorClasses?.metadata?.page_size
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchMentorClasses({page_index: pageIndex + 1, page_size: pageSize, params: params})
      const results = mentorClasses.staffs.concat(res?.data?.staffs) 
      setMentorClasses({
        metadata: res?.data?.metadata,
        staffs: results,
      })
    }
  }

  const getLectureClasses = async ({params}: any) => {
    const res = await fetchLectureClasses({page_index: 1, page_size: 20, type: 'TEACHER', params: params})
    if (res) {
      setLecturerClasses(res?.data)
    }
  }

  const getMentorClasses = async ({params}: any) => {
    const res = await fetchMentorClasses({page_index: 1, page_size: 20, params: params})
    if (res) {
      setMentorClasses(res?.data)
    }
  }

  const debounceSearchLecturer = debounce((e) => {
    getLectureClasses({params: {text: e}})
  }, 500)

  const debounceSearchMentor = debounce((e) => {
    getMentorClasses({params: {text: e}})
  }, 500)

  useLayoutEffect(() => {
    fetchAreaClasses({page_index: 1, page_size: 20})
    getLectureClasses({params: ''})
    getMentorClasses({params: ''})
  }, [])

  const handleChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.currentTarget.value)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(`${PageLink.CLASSES + (location.search || '')}`),
    })
  }

  useEffect(() => {
    if(classId) {
      setValue('status', 'DRAFT')
    }
  }, [classId])

  return (
    <PageLayouts
      pageTitle={classId ? TITLE_OPTIONS_CLASS.updateClass : TITLE_OPTIONS_CLASS.createClass}
      breadcrumbs={breadcrumbs}
    >
      <div>
        {contextHolder}
        <div className='card h-xl-100'>
          <div className='card-body border-0 d-block p-10 pb-0'>
            <div className='class_name card-title d-flex flex-wrap mb-8 w-100'>
              <HookFormTextField
                label={TITLE_OPTIONS_CLASS.name}
                required
                control={control}
                name='name'
                placeholder=' '
                className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
              ></HookFormTextField>
            </div>
            <Row>
              <Col md={classId ? 6 : 12} className='mb-8'>
                <HookFormTextField
                  label={TITLE_OPTIONS_CLASS.code}
                  required
                  control={control}
                  name='code'
                  placeholder=' '
                  className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                ></HookFormTextField>
              </Col>
              {
                classId && (
                  <Col md={6} className='mb-8'>
                  <HookFormSelectAntd
                    required
                    size='large'
                    name='status'
                    control={control}
                    placeholder=' '
                    className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    label={TITLE_OPTIONS_CLASS.status}
                  >
                    {OPTIONS_OF_STATUS?.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </Col>
                )
              }
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                  <HookFormSelectAntd
                    required
                    size='large'
                    name='instruction_mode'
                    control={control}
                    placeholder=' '
                    className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    label={TITLE_OPTIONS_CLASS.constructionMode}
                    onChange={(e: unknown) => setTypeInstruction(e as any)}
                  >
                    {OPTIONS_OF_CONTRUCTION_MODE?.map((item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </Col>
                <Col md={6} className='mb-8'>
                  <HookFormSelectAntd
                    required={validateRequiredArea}
                    size='large'
                    name='area_id'
                    control={control}
                    placeholder=' '
                    className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    label={TITLE_OPTIONS_CLASS.area}
                    allowClear
                  >
                    {areaClasses?.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                        {item.name}
                        </Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </Col>
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                <HookFormSelectAntd
                  required
                  size='large'
                  name='type'
                  control={control}
                  placeholder=' '
                  className='sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                  onChange={() => {
                    handleChangeType()
                  }}
                  label={TITLE_OPTIONS_CLASS.type}
                >
                  {OPTIONS_OF_TYPE_CLASS?.map((item) => {
                    return (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </Col>
              <Col md={6} className='mb-8'>
                <HookFormTextField
                  label={TITLE_OPTIONS_CLASS.maximumStudents}
                  required
                  control={control}
                  name='capacity'
                  placeholder=' '
                  type='number'
                  className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                  minNumber={1}
                  requiredZero={true}
                ></HookFormTextField>
              </Col>
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                <HookFormSelectAntd
                  size='large'
                  name='lecture_id'
                  control={control}
                  placeholder=' '
                  className='sapp-h-48px fs-6 fs-6 lh-1 sapp-table-class-field'
                  handleNextPage={(e: any) => handlNextPageLecture({params: {text: e}})}
                  showSearch
                  onSearch={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    debounceSearchLecturer(e)
                    return
                  }}
                  label={TITLE_OPTIONS_CLASS.lecturer}
                >
                  {lecturerClasses?.users?.map((item: any) => {
                    return (
                      <Option key={item.id} value={item.id} userName={item?.detail?.full_name} email={item?.detail?.email} className={'p-4'}>
                        <div className='d-flex align-items-center sapp-bg-white'>
                          <img
                            className='w-40px h-40px me-3 mb-0 rounded-circle'
                            src={item.detail.avatar?.['40x40'] ?? avatar}
                            alt=''
                          />
                          <span>
                            <span className='d-block p-0 m-0'>{item?.detail?.full_name}</span>
                            <span className='d-block p-0 m-0 text-gray-500 fs-7'>
                              {item?.detail?.email || item?.user_contacts?.[0]?.email}
                            </span>
                          </span>
                        </div>
                      </Option>
                      )
                  })}
                </HookFormSelectAntd>
              </Col>
              <Col md={6} className='mb-8'>
                <HookFormSelectAntd
                  size='large'
                  name='mentor_id'
                  control={control}
                  placeholder=' '
                  className='sapp-h-48px fs-6 fs-6 lh-1 sapp-table-class-field'
                  handleNextPage={(e: any) => handlNextPageMentor({params: {text: e}})}
                  showSearch
                  onSearch={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    debounceSearchMentor(e)
                    return
                  }}
                  label={TITLE_OPTIONS_CLASS.mentor}
                >          
                  {mentorClasses?.staffs?.map((item: any) => {
                    return (
                      <Option key={item.id} value={item.id} userName={item?.detail?.full_name} email={item?.detail?.email} className={'p-4'}>
                        <div className='d-flex align-items-center sapp-bg-white'>
                          <img
                            className='w-40px h-40px me-3 mb-0 rounded-circle'
                            src={item.detail.avatar?.['40x40'] ?? avatar}
                            alt=''
                          />
                          <span>
                            <span className='d-block p-0 m-0'>{item?.detail?.full_name}</span>
                            <span className='d-block p-0 m-0 text-gray-500 fs-7'>{item.detail.email}</span>
                          </span>
                        </div>
                      </Option>
                      )
                  })}
                </HookFormSelectAntd>
              </Col>
            </Row>
            <Row>
              <Col md={6} className='mb-8'>
                <label className='sapp-fs-14 fw-bold form-label'>
                  <span className='required'>{TITLE_OPTIONS_CLASS.duration}</span>
                </label>
                <HookFormRadioGroup
                  direction='horizontal'
                  separator={false}
                  name='duration_type'
                  control={control}
                  justify='start'
                  gap={32}
                  labelClass='fw-semibold fs-6 sapp-table-class-field'
                  onChange={handleChangeDuration}
                  defaultValue={`${OPTIONS_OF_DURATION.valueFixed}`}
                  options={[
                  {
                    label: `${OPTIONS_OF_DURATION.labelFixed}`,
                    value: `${OPTIONS_OF_DURATION.valueFixed}`,
                  },
                  {
                    label: `${OPTIONS_OF_DURATION.labelFlexible}`,
                    value: `${OPTIONS_OF_DURATION.valueFlexible}`,
                  },
                ]}
                />
              </Col>
            </Row>
            <Row>
              {selectedRadio === `${OPTIONS_OF_DURATION.valueFixed}` && (
                <>
                  <Col md={6} className='mb-8'>
                    <HookFormDateTime
                      control={control}
                      name='opening_at'
                      placeholder='Opening Date'
                      className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    />
                  </Col>
                  <Col md={6} className='mb-8'>
                    <RangeDateTimePicker
                      control={control}
                      name='duration_at'
                      allowClear={false}
                      className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                    />
                  </Col>
                </>
              )}
              {selectedRadio === `${OPTIONS_OF_DURATION.valueFlexible}` && (
                <Col md={6} className='mb-8'>
                  <div className='position-relative'>
                    <HookFormTextField
                      required
                      control={control}
                      name='flexible_days'
                      placeholder=' '
                      type='number'
                      className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
                      postFix={<div className='px-2'>days</div>}
                      minNumber={1}
                      requiredZero={true}
                    ></HookFormTextField>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              {/* start:: course*/}
              {
                !isEmpty(getValues('type')) && (
                  <Col md={12} className='mb-8'>
                    <label className='sapp-fs-14 fw-bold form-label'>
                      <span className={getValues('type') !== 'TRIAL' && user?.length > 0 ? 'required' : ''}>{TITLE_OPTIONS_CLASS.course}</span>
                    </label>
                    <div className='position-relative'>
                      <ButtonIcon
                        title={`${checkedListCourse[0]?.id !== undefined ? ' ' : 'Add Course'}`}
                        className='sapp-h-48px d-flex justify-content-start align-items-center w-100'
                        customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 position-relative'
                        type='button'
                        onClick={() => {
                          setOpenAddCourse(true)
                          setTypeCourse(getValues('type'))
                        }}
                      >
                        {checkedListCourse[0]?.id !== undefined ?
                          <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>{checkedListCourse[0]?.name}</div>
                          :
                          <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                        }
                        <KTIcon
                          iconName='notepad-edit'
                          className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${checkedListCourse[0]?.id !== undefined ? 'first' : ''}`}
                          iconType='outline'
                        />
                      </ButtonIcon>
                      {checkedListCourse[0]?.id !== undefined &&
                        <div className='sapp-icon-last'>
                          <ButtonIconOnly
                            iconName='trash'
                            onClick={handleChangeType}
                            iconType='outline'
                            bg={'sapp-custom-bg-transparent'}
                            activeColor='danger'
                            iconGrayColor={'500'}
                          />
                        </div>
                      }
                    </div>
                    <>
                      {checkedListCourse[0]?.id === undefined && validateCourse && getValues('type') !== 'TRIAL' && user?.length > 0 &&
                        <ErrorMessage>Course must contain at least 1 element</ErrorMessage>
                      }
                    </>
                  </Col>
                )
              }
              {/* end:: course*/}
            </Row>
            {checkedListCourse[0]?.id !== undefined && categoryCourseType.some((e: any) => e.includes(checkedListCourse[0]?.course_categories[0]?.name)) &&
              <Row>
                {/* start:: exam*/}
                <Col md={12} className='mb-8'>
                  <label className='sapp-fs-14 fw-bold form-label'>
                    <span className={getValues('type') !== 'TRIAL' && user?.length > 0 ? 'required' : ''}>Exam</span>
                  </label>
                  <div className='position-relative'>
                    <ButtonIcon
                      title={`${checkedListExam[0]?.id !== undefined ? ' ' : 'Exam'}`}
                      className='sapp-h-48px d-flex justify-content-start align-items-center w-100'
                      customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0 position-relative'
                      type='button'
                      onClick={() => {
                        setOpenAddExam({status: true, type: ''})
                      }}
                    >
                      {checkedListExam[0]?.id !== undefined ?
                        <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                          {(() => {
                            const date = new Date(checkedListExam[0]?.season || null)
                            return `${format(date, 'dd/MM/yyyy')}`
                          })() ?? '-'}
                        </div>
                      :
                        <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                      }
                      <div className={`sapp-icon-last ${checkedListExam[0]?.id !== undefined ? 'first' : ''}`}>
                        <KTIcon iconName='notepad-edit' className='fs-1 text-gray-500 ps-5 pe-1' iconType='outline' />
                      </div>
                    </ButtonIcon>
                    {checkedListExam[0]?.id !== undefined  &&
                      <div className='sapp-icon-last'> 
                        <ButtonIconOnly
                          iconName='trash'
                          onClick={() => {setCheckedListExam([])}}
                          iconType='outline'
                          bg={'sapp-custom-bg-transparent'}
                          activeColor='danger'
                          iconGrayColor={'500'}
                        />
                      </div>
                    }
                  </div>
                </Col>
                {/* end:: exam*/}
              </Row>
            }
            <Row>
              <Col md={12} className='mb-8'>
                <label className='sapp-fs-14 fw-bold form-label'>
                  <span>{TITLE_OPTIONS_CLASS.describe}</span>
                </label>
                <HookFormEditor
                  height={300}
                  placeholder=' '
                  name='description'
                  control={control}
                  math={true}
                  className='w-100 fs-6'
                />
              </Col>
            </Row>
          </div>
          <div className='d-flex border-0 justify-content-end card-footer pt-0 pb-10'>
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Save'
              okOnClick={() => {
                setValidateCourse(true)
                handleSubmit((data: any) => {
                  if (checkedListCourse[0]?.id || user?.length === 0 || (getValues('type') === 'TRIAL')) {
                    onSubmit(data)
                  }
                })()
              }}
              className='justify-content-between d-flex'
              loading={loading}
            />
          </div>
        </div>
        <AddCourseToClass
          open={openAddCourse}
          setOpen={setOpenAddCourse}
          checkedList={checkedListCourse}
          setCheckedList={setCheckedListCourse}
          typeCourse={typeCourse}
        />
        <AddExam
          open={openAddExam.status}
          setOpen={setOpenAddExam}
          courseId={checkedListCourse[0]?.id}
          course_category_id={checkedListCourse?.[0]?.course_categories?.[0]?.id}
          course_category_type={checkedListCourse?.[0]?.course_categories?.[0]?.name}
          checkedList={checkedListExam}
          setCheckedList={setCheckedListExam}
        />
      </div>
      {/* List user sẽ xuất hiện sau khi thêm class thành công*/}
      {classId &&
        <div className='card mt-2 mt-xl-8' ref={listUserRef}>
          <ListUser
            title='Danh sách học viên'
            getValues={getValues}
            classId={classId}
            setUserStudent={setUser}
          />
        </div>
      }
    </PageLayouts>
  )
}

export default CreateClassInfo
