import information from 'src/_metronic/assets/icons/information.svg'
import './WorkingGuide.scss'
type Props = {
  note: string | string[]
}

const WorkingGuide = ({note}: Props) => {
  return (
    <div className='card border-0 shadow-none sapp-working-guide mb-8'>
      <div className='card-body'>
        <div className='d-flex align-items-center mb-4'>
          <img width={20} height={20} src={information} alt='information' />
          <div className='ms-3 fw-bold fs-6'>Hướng dẫn tạo câu hỏi</div>
        </div>
        <div className='fs-6 sapp-working-guide_text'>
          {Array.isArray(note) ? (
            note.map((e, i) => (
              <span className='d-block lh-base' key={i}>
                {e}
              </span>
            ))
          ) : (
            <span className='lh-base'>{note}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkingGuide
