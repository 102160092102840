import {Select, Skeleton} from 'antd'
import {ReactNode, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Col, Dropdown, Modal, Row} from 'react-bootstrap'
import SAPPDialogButtonsCancelDropdownSubmit from 'src/common/SAPPDialogButtonsCancelDropdownSubmit'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormRadioImageGroup from 'src/components/base/radiobutton/HookFormRadioImageGroup'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {UseFormReturn} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import {useParams} from 'react-router-dom'
import oneColumnDropHovered from 'src/_metronic/assets/images/question/one-column-d.svg'
import oneColumn from 'src/_metronic/assets/images/question/one-column.svg'
import twoColumnDropHovered from 'src/_metronic/assets/images/question/two-column-d.svg'
import twoColumn from 'src/_metronic/assets/images/question/two-column.svg'
import {CommonAPI} from 'src/apis'
import {QuestionBankAPI} from 'src/apis/question-bank'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormTag from 'src/components/base/tag/HookFormTag'
import {useConfirm} from 'src/hooks/use-confirm'
import useCourseFilter from 'src/hooks/use-course-filter'
import {mergeImageToEditor} from 'src/utils/upload'
import {v4 as uuid} from 'uuid'
import PreviewQuestion from '../preview-question/PreviewQuestion'
import {IInputQuestionProps, IQuestion, QUESTION_LEVELS} from '../shared/interfaces'
import {useQuestionProviderContext} from '../shared/providers/QuestionProvider'
import WorkingGuide from '../shared/working-guide/Index'
import './LayoutQuestion.scss'
import { debounce, isUndefined } from 'lodash'

type ILayoutQuestionProp = {
  note?: string | string[]
  open: boolean
  onClose: () => void
  type: string
  useForm: UseFormReturn<any>
  children?: ReactNode
  hasQuestion?: boolean
  buildRequest?: (data: IInputQuestionProps) => {
    partialQuestion: Partial<IQuestion>
    listPayload?: any[]
  }
  buildRequestAsync?: (
    data: IInputQuestionProps
  ) => Promise<{partialQuestion: Partial<IQuestion>; listPayload?: any[]}>
  id?: string
  actionType?: 'edit' | 'duplicate'
  fetchData?: (data: IQuestion) => any
  defaultValues: Partial<IInputQuestionProps>
  setDefaultQuestEditor?: any
  onClearForm?: () => void
}
const {Option} = Select

export const defaultBelongSelect = [
  {
    name: 'course',
    placeHolder: 'Course',
    option: [],
  },
  {
    name: 'part',
    placeHolder: 'Part',
    option: [],
  },
  {
    name: 'chapter',
    placeHolder: 'Chapter',
    option: [],
  },
  {
    name: 'unit',
    placeHolder: 'Unit',
    option: [],
  },
  {
    name: 'activity',
    placeHolder: 'Activity',
    option: [],
  },
]

const LayoutQuestion = ({
  note,
  open,
  onClose,
  type,
  children,
  useForm,
  hasQuestion = true,
  buildRequest,
  buildRequestAsync,
  id,
  actionType,
  fetchData,
  defaultValues,
  setDefaultQuestEditor,
  onClearForm,
}: ILayoutQuestionProp) => {
  const params = useParams()
  const {confirm, contextHolder} = useConfirm()
  const {handleSubmit, control, reset, setValue, getValues, watch } = useForm

  const {
    listCourse,
    setListCourse,
    getListSort,
    debouncedGetListSort,
    clearChildren,
    getCourses,
    debouncedGetCourses,
    handleNextPage,
    handleNextListSort,
  } = useCourseFilter(setValue)

  const [defaultEditor1, setDefaultEditor1] = useState<any>()
  const [defaultEditorSolution, setDefaultEditorSolution] = useState<any>()
  const [openPreview, setOpenPreview] = useState<{value: boolean; data: any}>({
    value: false,
    data: undefined,
  })
  const modalContentRef = useRef<HTMLDivElement>(null)
  const [loadingFetchData, setLoadingFetchData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [editorKey, setEditorKey] = useState<{
    question: string
    solution: string
  }>({
    question: 'question_' + uuid(),
    solution: 'solution' + uuid(),
  })
  const [qId, setQId] = useState<string | undefined>(id)
  const {
    state: {multiple, constructed},
    refreshListQuestion,
  } = useQuestionProviderContext()

  const [belongToQuiz, setBelongToQuiz] = useState<any>()

  const fetchQuiz = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await QuestionBankAPI.getBelongToQuiz(page_index, page_size, params)
      return res
    } catch (error) { }
  }

  const [questionDetail, setquestionDetail] = useState<any>()

  useLayoutEffect(() => {
    if (id) {
      setQId(id)
      ;(async () => {
        setLoadingFetchData(true)
        try {
          const {data} = await QuestionBankAPI.getQuestionById(id)     
          setquestionDetail(data)     
          if (!data) {
            throw new Error('No data found')
          }
          let defaultData: Partial<IInputQuestionProps> = {
            level: data.level,
            course: data.question_filter?.course_id || '',
            part: data.question_filter?.part_id || '',
            chapter: data.question_filter?.chapter_id || '',
            unit: data.question_filter?.unit_id || '',
            activity: data.question_filter?.activity_id || '',
            quiz_id: data.question_filter?.quiz_id || '',
            category: data.question_category?.map((e) => ({
              id: e.id,
              name: e.name,
            })),
            display_type: data.display_type as 'VERTICAL' | 'HORIZONTAL' | undefined,
            question_content: data.question_content,
            solution: data.solution,
            hint: data.hint,
            tags: data.tags,
          }

          // Fill data in Belong To when first load to edit
          getCourses()
          getListSort({
            parentId: data.question_filter?.course_id || '',
            type: 'part',
            parentType: 'course',
            isSelect: true,
          })
          getListSort({
            parentId: data.question_filter?.part_id || '',
            type: 'chapter',
            parentType: 'part',
            isSelect: true,
          })

          if(data.question_filter?.chapter_id) {
            getListSort({
              parentId: data.question_filter?.chapter_id || '',
              type: 'unit',
              parentType: 'chapter',
              isSelect: true,
            })
          }
          
          if(data.question_filter?.unit_id) {
            getListSort({
              parentId: data.question_filter?.unit_id || '',
              type: 'activity',
              parentType: 'unit',
              isSelect: true,
            })
          }

          if (fetchData) {
            const fetchresult = await fetchData(data)
            defaultData = {...defaultData, ...fetchresult}
          }
          defaultData.question_content = await mergeImageToEditor(
            defaultData.question_content || '',
            data.files || []
          )
          defaultData.solution = await mergeImageToEditor(
            defaultData.solution || '',
            data.files || []
          )
          Object.entries(defaultData || {}).forEach(([key, value]: [string, any]) => {
            setValue(key, value)
          })
          setDefaultEditor1(defaultData.question_content)
          setDefaultQuestEditor && setDefaultQuestEditor(defaultData.question_content)
          setDefaultEditorSolution(defaultData.solution)
        } catch (error: any) {
          handleClose()
        } finally {
          setLoadingFetchData(false)
        }
      })()
    }
  }, [id])

  const handleClose = () => {
    onClose()
    reset()
  }
  const handleClosePreview = () => {
    setOpenPreview({value: false, data: undefined})
  }
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Nếu bạn điều hướng khỏi popup này mà không lưu trước thì câu hỏi sẽ bị mất.'],
      onClick: handleClose,
    })
  }
  const onSubmit = async (data: IInputQuestionProps, submitType?: 'new' | 'preview' | 'close') => {
    if (submitType !== 'preview') {
      setLoading(true)
    }

    let request: IQuestion = {
      assignment_type: 'TEXT',
      level: data.level,
      display_type: data.display_type,
      question_content: (data.question_content ?? '').trim(),
      solution: (data.solution ?? '').trim(),
      hint: (data.hint ?? '').trim(),
      tags: data.tags?.map((e) => {
        if (e.id) {
          return {id: e.id}
        } else {
          return {name: e.name}
        }
      }),
      question_filter: {
        course_id: data.course,
        part_id: data.part,
        chapter_id: data.chapter || undefined,
        unit_id:data.unit || undefined,
        activity_id:data.activity || undefined,
        quiz_id: data.quiz_id || undefined
      },
      question_category_id: data.category?.map((e) => e.id || '') || [],
      question_topic_id: params.id,
    }

    let additionalRequest
    if (buildRequest) {
      additionalRequest = buildRequest(data)
    }
    if (buildRequestAsync) {
      const requestAsync = await buildRequestAsync(data)
      additionalRequest = {...additionalRequest, ...requestAsync}
    }    
    request = {...request, ...(additionalRequest?.partialQuestion || {})}

    if (submitType === 'preview') {      
      setOpenPreview({value: true, data: request})
      return
    }

    try {
      if (qId && actionType !== 'duplicate') {
        await QuestionBankAPI.updateQuestion({
          id: qId,
          data: {...request},
        })
      } else {
        await QuestionBankAPI.createQuestion({
          data: {...request},
        })
      }

      toast.success('Question saved successfully!', {id: 'question_save'})

      switch (submitType) {
        case 'new':
          setTimeout(() => {
            reset(defaultValues, {
              keepErrors: false,
              keepIsSubmitted: false,
              keepDefaultValues: true,
              keepValues: false,
              keepDirty: false,
              keepDirtyValues: false,
              keepTouched: false,
              keepSubmitCount: false,
              keepIsValid: true,
            })
            setQId(undefined)
            setDefaultEditorSolution('')
            setDefaultQuestEditor && setDefaultQuestEditor('')
            setDefaultEditor1('')
            setEditorKey({
              question: 'question_' + uuid(),
              solution: 'solution' + uuid(),
            })
            onClearForm && onClearForm()
            modalContentRef.current?.scrollTo({top: 0, behavior: 'smooth'})
          }, 300)
          break
        case 'close':
          setTimeout(() => {
            handleClose()
          }, 300)
          break
        default:
          break
      }
      refreshListQuestion(['multiple', 'constructed'])
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '400|030108') {
        toast.error('This Question Is Used!')
      }
    } finally {
      setLoading(false)
    }
  }

  // Xử lý chuyển sang trang kế tiếp của danh sách câu hỏi
  const handlNextPageQuiz = async (params: Object) => {
    const totalPages = belongToQuiz?.meta?.total_pages
    const pageIndex = belongToQuiz?.meta?.page_index as number
    const pageSize = belongToQuiz?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchQuiz(pageIndex + 1, pageSize, params)
      const results = belongToQuiz.quizzes.concat(res?.data?.groups)
      setBelongToQuiz({
        meta: res?.data?.meta,
        quizzes: results,
      })
    }
  }

  // Lấy danh sách câu hỏi
  const getQuiz = async ({ params }: { params: Object }) => {
    const resMentor = await fetchQuiz(1, 20, params)
    setBelongToQuiz(resMentor?.data)
  }

  // Tạo hàm debounce để tìm kiếm câu hỏi
  const debounceSearchQuiz = debounce((e) => {
    getQuiz({ params: { name: e, courseId: watch('course') } })
  }, 500)

  // Tạo mảng chứa danh sách câu hỏi và bài kiểm tra
  const belongToQuizAndTest = belongToQuiz?.quizzes && belongToQuiz?.quizzes?.map((quizzes: any) => ({ label: quizzes?.name, value: quizzes?.id }))

  // useEffect để xử lý khi giá trị của 'course' thay đổi
  useEffect(() => {
    if (watch('course') === undefined) {
      setBelongToQuiz(undefined)
      setValue('quiz_id', null)
    }
  }, [watch('course')])

  // useEffect để lấy danh sách câu hỏi khi 'course' thay đổi
  useEffect(() => {
    if (!isUndefined(watch('course'))) {
      getQuiz({ params: { courseId: watch('course') } })
    }
  }, [watch('course')])


  // useEffect để đặt giá trị 'quiz' thành null khi 'id' không tồn tại
  useEffect(() => {
    if (!id) {
      setValue('quiz_id', null)
    }
  }, [watch('course')])

  useEffect(() => {
    if (id) {
      if (questionDetail?.question_filter?.course_id !== watch('course')) {
        setValue('quiz_id', null);
      } 
    }
  }, [watch('course'), id])

  const [quizId, setQuizId] = useState(false)

  useEffect(() => {
    if(quizId) {
      setValue('quiz_id', null)
    }
  }, [quizId])

  return (
    <div>
      <Modal
        scrollable={true}
        dialogClassName='modal-dialog  m-0 '
        fullscreen
        show={open}
        onHide={handleCancel}
        enforceFocus={false}
      >
        <div className='modal-content rounded-0 scroll-y overflow-auto' ref={modalContentRef}>
          {/* start:: body modal */}
          <div className='modal-body p-0 overflow-visible border-0'>
            <div className='card border-0'>
              <div>
                {/* start:: header modal */}
                <div className='d-flex justify-content-between align-items-center position-relative w-100'>
                  <h2 className='card-header text-capitalize sapp-question_custom-container d-flex justify-content-between align-items-center w-100'>
                    {qId ? (actionType === 'edit' ? 'Edit ' : 'Duplicate ') : 'Create '} {type}{' '}
                    Question
                  </h2>
                  <div
                    className='justify-content-end text-hover-primary position-absolute top-50 end-0 translate-middle sapp-cursor-pointer'
                    onClick={handleCancel}
                  >
                    <i className='ki-outline ki-cross fs-1' />
                  </div>
                </div>
                {/* end:: header modal */}
              </div>
              <div className='card-body sapp-question_custom-container w-100 mb-2'>
                <div className='position-relative w-100'>
                  <form className='w-100'>
                    {/* start:: level */}
                    <div className='sapp-mb-32px'>
                      <SappLabel label='Question Level' required/>
                      {!loadingFetchData ? (
                        <HookFormRadioGroup
                          direction='horizontal'
                          separator={false}
                          name='level'
                          control={control}
                          justify='start'
                          gap={16}
                          labelClass='fw-semibold fs-6'
                          options={[
                            {
                              label: 'Fundamental',
                              value: QUESTION_LEVELS.FUNDAMENTAL,
                            },
                            {
                              label: 'Advanced',
                              value: QUESTION_LEVELS.ADVANCED,
                            },
                          ]}
                        />
                      ) : (
                        <Skeleton.Input active size='small' className='w-100'></Skeleton.Input>
                      )}
                    </div>
                    {/* end:: level */}

                    {/* start:: Question template*/}
                    <div className='sapp-mb-32px'>
                      <SappLabel label='Question Layout' required/>
                      <div className='d-flex flex-wrap'>
                        <div className='me-3'>
                          <div className='mr-4'>
                            <HookFormRadioImageGroup
                              direction='horizontal'
                              separator={false}
                              name='display_type'
                              control={control}
                              skeleton={loadingFetchData}
                              labelClass='fw-semibold fs-6'
                              options={[
                                {
                                  value: 'HORIZONTAL',
                                  label: {
                                    normal: oneColumn,
                                    selected: oneColumnDropHovered,
                                    text: 'One column',
                                  },
                                },
                                {
                                  value: 'VERTICAL',
                                  label: {
                                    normal: twoColumn,
                                    selected: twoColumnDropHovered,
                                    text: 'Two columns',
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end:: Question template*/}

                    {/* start:: Category belong to*/}
                    <Row>
                      <Col md={12} className='sapp-mb-32px'>
                        <div>
                          <HookFormTag
                            name='category'
                            placeholder=' '
                            control={control}
                            // className='sapp-h-45px'
                            skeleton={loadingFetchData}
                            isCanAddNew={false}
                            fetch={async ({
                              text,
                              page_index,
                              page_size,
                            }: {
                              text: string
                              page_index: string
                              page_size: string
                            }) => {
                              return await CommonAPI.getTags({
                                apiEndpoint: 'question-categories',
                                params: {name: text, page_index, page_size},
                              })
                            }}
                            dataName='data'
                            label='Question Category'
                            required
                          ></HookFormTag>
                        </div>
                      </Col>
                      <Col md={12} className='sapp-mb-32px'>
                        <div>
                          <SappLabel label='Belong To' required />
                          <Row className='gap-3'>
                            <Col md={2}>
                              <HookFormSelectAntd
                                name='course'
                                placeholder='Course'
                                control={control}
                                size='large'
                                className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                                showSearch
                                handleNextPage={handleNextPage}
                                onFocus={async () => {
                                  if (listCourse?.course?.data.length <= 0) {
                                    await getCourses()
                                    return
                                  }
                                }}
                                onSearch={async (e) => {
                                  debouncedGetCourses(e)
                                  return
                                }}
                                onChange={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  getListSort({
                                    parentId: e,
                                    type: 'part',
                                    parentType: 'course',
                                    isSelect: true,
                                  })
                                }}
                                loading={listCourse.course.loading}
                                allowClear
                                onClear={() => {
                                  setListCourse(clearChildren('course', true))
                                }}
                              >
                                {listCourse.course.data
                                  ?.map((e, index) => {
                                    return (
                                      <Option key={e.id + index} value={e.id}>
                                        {e.name}
                                      </Option>
                                    )
                                  })}
                              </HookFormSelectAntd>
                            </Col>
                            <Col md={2}>
                              <HookFormSelectAntd
                                name='part'
                                placeholder='Section'
                                control={control}
                                size='large'
                                showSearch
                                className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                                loading={listCourse.part.loading}
                                onSearch={async (e) => {
                                  debouncedGetListSort({
                                    type: 'part',
                                    name: e,
                                    parentId: listCourse.course.id,
                                    parentType: 'course',
                                  })
                                  return
                                }}
                                onChange={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  getListSort({
                                    parentId: e,
                                    type: 'chapter',
                                    parentType: 'part',
                                    isSelect: true,
                                  })
                                }}
                                handleNextPage={() => handleNextListSort('part', 'course')}
                                allowClear
                                onClear={() => {
                                  setListCourse(clearChildren('part', true, getValues('course')))
                                }}
                              >
                                {listCourse.part.data?.map((e, index) => {
                                  return (
                                    <Option key={e.id + index} value={e.id}>
                                      {e.name}
                                    </Option>
                                  )
                                })}
                              </HookFormSelectAntd>
                            </Col>
                            <Col md={2}>
                              <HookFormSelectAntd
                                name='chapter'
                                placeholder='Subsection'
                                control={control}
                                size='large'
                                className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                                showSearch
                                loading={listCourse.chapter.loading}
                                onSearch={async (e) => {
                                  debouncedGetListSort({
                                    type: 'chapter',
                                    name: e,
                                    parentId: listCourse.part.id,
                                    parentType: 'part',
                                  })
                                  return
                                }}
                                onChange={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  getListSort({
                                    parentId: e,
                                    type: 'unit',
                                    parentType: 'chapter',
                                    isSelect: true,
                                  })
                                }}
                                allowClear
                                onClear={() => {
                                  setListCourse(clearChildren('chapter', true, getValues('part')))
                                }}
                                handleNextPage={() => handleNextListSort('chapter', 'part')}
                              >
                                {listCourse.chapter.data
                                  ?.map((e, index) => {
                                    return (
                                      <Option key={e.id + index} value={e.id}>
                                        {e.name}
                                      </Option>
                                    )
                                  })}
                              </HookFormSelectAntd>
                            </Col>
                            <Col md={2}>
                              <HookFormSelectAntd
                                name='unit'
                                placeholder='Unit'
                                control={control}
                                size='large'
                                className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                                showSearch
                                loading={listCourse.unit.loading}
                                onSearch={async (e) => {
                                  debouncedGetListSort({
                                    type: 'unit',
                                    name: e,
                                    parentId: listCourse.chapter.id,
                                    parentType: 'chapter',
                                  })
                                  return
                                }}
                                onChange={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  getListSort({
                                    parentId: e,
                                    type: 'activity',
                                    parentType: 'unit',
                                    isSelect: true,
                                  })
                                }}
                                allowClear
                                onClear={() => {
                                  setListCourse(clearChildren('unit', true, getValues('chapter')))
                                }}
                                handleNextPage={() => handleNextListSort('unit', 'chapter')}
                              >
                                {listCourse.unit.data
                                  ?.map((e, index) => {
                                    return (
                                      <Option key={e.id + index} value={e.id}>
                                        {e.name}
                                      </Option>
                                    )
                                  })}
                              </HookFormSelectAntd>
                            </Col>
                            <Col md={2}>
                              <HookFormSelectAntd
                                name='activity'
                                placeholder='Activity'
                                control={control}
                                size='large'
                                className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                                showSearch
                                loading={listCourse.activity.loading}
                                onSearch={async (e) => {
                                  debouncedGetListSort({
                                    type: 'activity',
                                    name: e,
                                    parentId: listCourse.unit.id,
                                    parentType: 'unit',
                                  })
                                  return
                                }}
                                onChange={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  getListSort({
                                    parentId: e,
                                    type: 'unit',
                                    parentType: 'activity',
                                    isSelect: true,
                                  })
                                }}
                                allowClear
                                onClear={() => {
                                  setListCourse(clearChildren('activity', true, getValues('unit')))
                                }}
                                handleNextPage={() => handleNextListSort('activity', 'unit')}
                              >
                                {listCourse.activity.data
                                  ?.map((e, index) => {
                                    return (
                                      <Option key={e.id + index} value={e.id}>
                                        {e.name}
                                      </Option>
                                    )
                                  })}
                              </HookFormSelectAntd>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {/* end:: Category belong to*/}

                    <Row>
                    {
                      watch('course') && (
                        <Col md={12} className='sapp-mb-32px'>
                        <div>
                          <SappLabel label='Belong To Quiz/Test' />
                          <Row className='gap-3'>
                            <Col md={2}>
                            <HookFormSelectAntd
                                name='quiz_id'
                                placeholder='Quiz/Test'
                                control={control}
                                size='large'
                                className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                                loading={loading}
                                onSearch={(e: any) => {
                                  if (e === undefined) {
                                    return
                                  }
                                  if (!isUndefined(watch('course'))) {
                                    debounceSearchQuiz(e)
                                  }
                                }}
                                onClear={() => setQuizId(true)}
                                handleNextPage={(e: any) => handlNextPageQuiz({ params: { name: e } })}
                                showSearch
                                allowClear
                              // onFocus={async () => {
                              //   if (watch('course')) {
                              //     await getMentorClasses({courseId: watch('course')})
                              //     return
                              //   }
                              // }}
                              >
                                {belongToQuizAndTest && belongToQuizAndTest
                                  ?.map((e: any, index: any) => {
                                    return (
                                      <Option key={e.id + index} value={e.value}>
                                        {e.label}
                                      </Option>
                                    )
                                  })}
                              </HookFormSelectAntd>
                            </Col>
                          </Row>
                        </div>
                    </Col>
                      )
                    }
                    </Row>

                    {/* start:: note */}
                    {note && <WorkingGuide note={note}></WorkingGuide>}
                    {/* end:: note */}

                    {/* start:: question */}
                    {hasQuestion && (
                      <div className='d-flex flex-wrap sapp-mb-32px'>
                        <HookFormEditor
                          editorKey={editorKey.question}
                          label='Nội Dung Câu Hỏi'
                          labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                          required
                          placeholder=' '
                          name='question_content'
                          control={control}
                          math={true}
                          className='w-100 fs-6'
                          defaultValue={defaultEditor1}
                        />
                      </div>
                    )}
                    {/* end:: question*/}

                    {/* start:: feedback*/}
                    {!loadingFetchData ? (
                      <div className='mb-3'>{children}</div>
                    ) : (
                      <Skeleton active className='w-100 mb-8'></Skeleton>
                    )}
                    {/* end:: feedback*/}

                    {/* start:: explain*/}
                    <div className='sapp-mb-32px'>
                      <div className='d-flex flex-wrap'>
                        <SappLabel label='Solution' required/>
                        <HookFormEditor
                          editorKey={editorKey.solution}
                          placeholder=' '
                          skeleton={loadingFetchData}
                          height={400}
                          name='solution'
                          control={control}
                          className='w-100 fs-6'
                          math={true}
                          // guideline={[
                          //   'Cho phép nhập chữ hoa, thường, chữ số, ký tự đặc biệt, nhập text, tạo table  theo các format trong texteditor',
                          //   'Cho phép chèn và upload link, ảnh, video, file',
                          // ]}
                          defaultValue={defaultEditorSolution}
                        />
                      </div>
                    </div>
                    {/* end:: explain*/}

                    {/* start:: hint*/}
                    <div className='d-flex flex-wrap sapp-mb-32px'>
                      <HookFormTextField
                        label='Gợi ý'
                        labelClass='d-flex align-items-center fs-6 fw-bold mb-5'
                        control={control}
                        name='hint'
                        placeholder=' '
                        className='w-100 sapp-h-45px fs-6'
                        skeleton={loadingFetchData}
                        // guideline={[
                        //   'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự.',
                        // ]}
                      ></HookFormTextField>
                    </div>
                    {/* end:: hint*/}

                    {/* start:: tags*/}
                    <div className='d-flex flex-wrap'>
                      <SappLabel label='Tag' />
                      <HookFormTag
                        name='tags'
                        placeholder=' '
                        control={control}
                        skeleton={loadingFetchData}
                        fetch={async ({
                          text,
                          page_index,
                          page_size,
                        }: {
                          text: string
                          page_index: string
                          page_size: string
                        }) => {
                          return await CommonAPI.getTags({
                            apiEndpoint: 'question-tags',
                            params: {name: text, page_index, page_size},
                          })
                        }}
                        dataName='question_tags'
                      ></HookFormTag>
                    </div>
                    {/* end:: tag*/}
                    {contextHolder}
                  </form>
                </div>
              </div>
              <div className='sapp-question_custom-container w-100 mb-8 px-8'>
                <SAPPDialogButtonsCancelDropdownSubmit
                  className='d-flex justify-content-end'
                  cancelButtonCaption='Cancel'
                  okButtonCaption='Save'
                  cancelClick={handleCancel}
                  loading={loading || multiple.loading || constructed.loading || loadingFetchData}
                  type='button'
                  okOnClick={handleSubmit((data: any) => onSubmit(data, 'close'))}
                >
                  <Dropdown.Item
                    as='button'
                    onClick={handleSubmit((data: any) => onSubmit(data, 'new'))}
                  >
                    Save & New
                  </Dropdown.Item>
                  <Dropdown.Item
                    as='button'
                    onClick={handleSubmit((data: any) => onSubmit(data, 'preview'))}
                  >
                    Preview
                  </Dropdown.Item>
                </SAPPDialogButtonsCancelDropdownSubmit>
              </div>
            </div>
          </div>
          {/* end:: body modal */}
        </div>
      </Modal>
      <PreviewQuestion
        open={openPreview.value}
        onClose={handleClosePreview}
        data={openPreview.data}
      />
    </div>
  )
}

export default LayoutQuestion
