import {fetcher, getBaseUrl} from 'src/services/request'
import {IResponse} from 'src/type'
import {IETest} from 'src/type/entrancetest'

export class EntranceApi {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`quiz?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
  static editStatusEntranceTest(data: any, id: string): Promise<IResponse<any>> {
    return fetcher(`quiz/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static getAPIStaffs({
    page_index,
    page_size,
  }: {
    page_index: number
    page_size: number
  }): Promise<IResponse<any>> {
    return fetcher(`/staff?page_index=${page_index}&page_size=${page_size}`)
  }
  static getCategory({
    page_index,
    page_size,
  }: {
    page_index: number
    page_size: number
  }): Promise<IResponse<any>> {
    return fetcher(`/course_categories?page_index=${page_index}&page_size=${page_size}`)
  }
  static getQuestionCategory({
    page_index,
    page_size,
  }: {
    page_index: number
    page_size: number
  }): Promise<IResponse<any>> {
    return fetcher(`/question-categories?page_index=${page_index}&page_size=${page_size}`)
  }
  static createEntranceTest({data}: {data: IETest}): Promise<IResponse<IETest>> {
    return fetcher(getBaseUrl() + '/quiz', {method: 'POST', data: data})
  }
  static updateEntranceTest(id: string, {data}: {data: IETest}): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/${id}`, {method: 'PUT', data: data})
  }
  static addSectionEntranceTest({data}: {data: any}): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + '/quiz/section/new', {method: 'POST', data: data})
  }
  static editEntranceTest(id: string): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/${id}`, {method: 'GET'})
  }
  static getListSection(id: string): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/${id}/section/list`, {method: 'GET'})
  }
  static getListSettingSection(id: string): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/${id}/category-setting/list`, {method: 'GET'})
  }
  static DeleteSection(id: string): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/section/${id}`, {method: 'DELETE'})
  }
  static EditSection(id: string, {data}: {data: any}): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/section/${id}`, {method: 'PUT', data: data})
  }
  static getDataChart(id: string | any): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz-attempts/chart-data/${id}`, {method: 'GET'})
  }
  static getDataTable({
    page_index,
    page_size,
    id,
  }: {
    page_index: number
    page_size: number
    id: any
  }): Promise<IResponse<any>> {
    return fetcher(
      `/quiz-attempts/chart-data/${id}?page_index=${page_index}&page_size=${page_size}`,
      {}
    )
  }
  static getDataResultQuizTable({
    page_index,
    page_size,
    id,
  }: {
    page_index: number
    page_size: number
    id: any
  }): Promise<IResponse<any>> {
    return fetcher(`/quiz-attempts/table/${id}?page_index=${page_index}&page_size=${page_size}`, {})
  }
  static getDataExplanation({id}: {id: string}): Promise<IResponse<any>> {
    return fetcher(`/quiz-attempts/answers/${id}`)
  }
  static UpdateSection({id, params}: {id?: string; params?: Object}): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/section/new`, {
      method: 'POST',
      params: params,
    })
  }
  static UpdateSettingSection({id, data}: {id: string; data?: Object}): Promise<IResponse<any>> {
    return fetcher(getBaseUrl() + `/quiz/category-setting/${id}`, {method: 'PUT', data: data})
  }

  static updateClass(data: any): Promise<IResponse<any>> {
    return fetcher(`/entrancetest`, {method: 'PUT', data})
  }
  static getAPI({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/quiz/participants?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static detail(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/entrancetest/${id}`)
  }

  static getClassStudent({
    class_id,
    page_index,
    page_size,
    params,
  }: {
    class_id?: string | undefined
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(
      `/classes/${class_id}/students?page_index=${page_index}&page_size=${page_size}`,
      {method: 'GET', params: params}
    )
  }

  static addStudentInClass(data: any): Promise<IResponse<any>> {
    return fetcher(`classes/students`, {
      method: 'POST',
      data,
    })
  }

  static deleteStudentInClass(data: any): Promise<IResponse<any>> {
    return fetcher(`classes/student`, {
      method: 'DELETE',
      data,
    })
  }

  static allowFoundation(user: string[], classId: string | undefined): Promise<any> {
    return fetcher('classes/student-pass-foundation', {
      method: 'PATCH',
      data: {
        user: user,
        classId: classId,
      },
    })
  }
  static getStudentCourseContent(id: string): Promise<IResponse<any>> {
    return fetcher(`courses/${id}/sections/compact`, {method: 'GET'})
  }
  static getExceptedSectionStudent({
    id,
    student_id,
  }: {
    id: string
    student_id: string
  }): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/students/${student_id}`)
  }
  static editCourseContentClass(data: any, id: string): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/course-content`, {method: 'PUT', data: data})
  }
  static editCourseContentStudent(
    data: any,
    id: string,
    student_id: string
  ): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/students/${student_id}/course-content`, {
      method: 'PUT',
      data: data,
    })
  }
  static getListExam({
    id,
    page_index,
    page_size,
    params,
  }: {
    id?: string | undefined
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/examination/${id}?page_index=${page_index}&page_size=${page_size}`, {
      method: 'GET',
      params: params,
    })
  }

  static updateUserDuration(data: any): Promise<IResponse<any>> {
    return fetcher(`/classes/duration`, {method: 'PUT', data})
  }

  static getCourseDetail({
    id,
    params,
  }: {
    id?: string | number | undefined
    params?: Object
  }): Promise<any> {
    return fetcher(`classes/${id}/user/course/detail`, {method: 'GET', params: params})
  }

  static detailAttempts(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/quiz/attempts/${id}`)
  }
}
