import React from 'react'
import {ResourcesAPI} from 'src/apis/resources'

type Props = {
  resource_id: string
  text: string
}

/**
 * @description Component tích hợp chứ năng download
 *
 * @param {Props} {resource_id, name}
 * @return {*}
 */
const DownloadCell = ({resource_id, text}: Props) => {
  const download = async () => {
    const resource = await ResourcesAPI.getUrl(resource_id)
    ResourcesAPI.downloadFile({
      files: [
        {
          name: resource.data.name,
          file_key: resource.data.file_key,
        },
      ],
    })
  }

  return (
    <div className='sapp-cursor-pointer text-gray-800 sapp-fs-14' onClick={download}>
      {text}
    </div>
  )
}

export default DownloadCell
