import React, {useState} from 'react'
import ClassStatusCell from './ClassStatusCell'
import ModalPreviewImage from 'src/components/base/ModalPreviewImage'
import ModalPlayVideo from 'src/components/form/editor/modalVideo'
import {RESOURCE_STATUS} from 'src/type/resource'

interface IProps {
  title: string
  body?: string | undefined | number | null
  status?: 'DRAFT' | 'PUBLIC' | 'BLOCKED' | 'ENDED' | undefined
  className?: string
  capitalize?: boolean
}

const ItemClassProfile = ({title, body, status, className, capitalize}: IProps) => {
  const [openPreview, setOpenPreview] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [openVideo, setOpenVideo] = useState<{
    status: boolean
    src?: string
    resourceStatus?: RESOURCE_STATUS
  }>({
    status: false,
  })

  const previewImage = (e: any) => {
    if (e.target.currentSrc !== undefined && e.target.localName === 'img') {
      setOpenPreview(true)
      setImagePreview(e.target.currentSrc)
    }

    if (e.target.currentSrc !== undefined && e.target.localName === 'video') {
      const element = e.target
      if (element) {
        const content = element.querySelector('video source')?.getAttribute('token')
        const status = element?.querySelector('source')?.getAttribute('resource_status')
        setOpenVideo({status: true, src: content, resourceStatus: status})
        return
      }
    }
  }

  return (
    <>
    <div className={`row ${className ?? ''}`}>
      <label className='col-lg-3 fw-semibold text-muted'>{title}</label>

      {body && (
        <div className='col-lg-9'>
          <div
            onClick={(e) => previewImage(e)}
            className={`fw-semibold text-gray-800 fs-6 ${capitalize ? 'text-capitalize' : ''}`}
            dangerouslySetInnerHTML={{
              __html: String(body),
            }}
          >
          </div>
        </div>
      )}

      {status && (
        <div className='col-lg-8'>
          <ClassStatusCell status={status} />
        </div>
      )}
    </div>
    <ModalPreviewImage
      openPreview={openPreview}
      setOpenPreview={setOpenPreview}
      avatarResize={imagePreview}
      title={'Image Preview'}
    />
    <ModalPlayVideo
      open={openVideo.status}
      src={openVideo.src}
      resourceStatus={openVideo.resourceStatus}
      setOpen={setOpenVideo}
    />
    </>
  )
}

export default ItemClassProfile
