import React, {Dispatch, SetStateAction} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {MY_PROFILE} from 'src/constants'
import {IStudentDetail} from 'src/type/students'
// import ActivityProfile from '../my-profile/activity-profile'
// import BillingProfile from '../my-profile/billing-profile'
// import LogsProfile from '../my-profile/logs-profile'
import ReferralsProfile from '../my-profile/refferals-profile'
// import SecurityProfile from '../my-profile/security-profile'
// import StatementsProfile from '../my-profile/stament-profile'
import OverviewStudentProfile from './student-overview'
import SettingStudent from './student-setting'
// import CourseList from '../my-profile/CourseList'
import ClassUser from '../ClassUser'
import SecurityStudent from './student-security'

interface IProps {
  studentDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const RenderStudentProfile = ({studentDetail, setLoading, loading,setStudentDetail}: IProps) => {
  const location = useLocation()
  const {id} = useParams()

  const renderProfile = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case MY_PROFILE.OVERVIEW:
        return <OverviewStudentProfile studentDetail={studentDetail} loading={loading} setStudentDetail={setStudentDetail}/>
      case MY_PROFILE.SETTING:
        return <SettingStudent studentDetail={studentDetail} setLoading={setLoading} loading={loading} setStudentDetail={setStudentDetail}/>
      case MY_PROFILE.CLASS:
        return <ClassUser />
        case MY_PROFILE.SECURITY:
        return <SecurityStudent studentDetail={studentDetail} setLoading={setLoading} loading={loading} setStudentDetail={setStudentDetail} />
      // case MY_PROFILE.ACTIVITY:
      //   return <ActivityProfile />
      // case MY_PROFILE.BILLING:
      //   return <BillingProfile />
      // case MY_PROFILE.STATEMENTS:
      //   return <StatementsProfile />
      case MY_PROFILE.CERTIFICATE:
        return <ReferralsProfile />
      // case MY_PROFILE.LOGS:
      //   return <LogsProfile />
      // case MY_PROFILE.COURSE:
      //   return <CourseList />
      default:
        return <OverviewStudentProfile studentDetail={studentDetail} loading={loading}  setStudentDetail={setStudentDetail}/>
    }
  }
  return <>{renderProfile()}</>
}

export default RenderStudentProfile
