interface IProps {
    text:string,
    color: 'primary'|'secondary'
}

const RoleBadge = ({text, color}:IProps) => {
    return (<div className={`bg-${color==='primary' ? 'light-primary' :'light'} d-flex justify-content-center w-70px`}>
        <div className={`text-${color==='primary'?'primary':'light-inverse'} fs-7 fw-bold align-items-center`}>{text}</div>
    </div>)
}
export default RoleBadge