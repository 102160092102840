import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import SappLabel from '../base/label/SappLabel'
import HookFormTextField from '../base/textfield/HookFormTextField'
import CardMember from './CardMember'
import AddUserToGroup from './AddMember'
import toast from 'react-hot-toast'
import ButtonSecondary from '../base/button/ButtonSecondary'
import ButtonPrimary from '../base/button/ButtonPrimary'
import { z } from 'zod'
import { VALIDATE_GROUP_NAME, VALIDATION_FILED } from 'src/constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { NotificationAPI } from 'src/apis/notifications'
import { isEmpty } from 'lodash'
import ErrorMessage from 'src/common/ErrorMessage'
import { INotificationGroup } from 'src/type/notification'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import Search from '../base/search'
import { getUserGroup } from 'src/apis/user'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import {useCourseContext} from 'src/context/CourseProvider'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {KTCardBody, KTIcon} from 'src/_metronic/helpers'

interface IInputProps {
  name: string
  users: any
  text?: string
}

const CreateGroupComponent = () => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const [openAddUser, setOpenAddUser] = useState(false)
  const [user, setUser] = useState<any>([])
  const {setId} = useCourseContext()
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams() // hook để lấy và cập nhật tab
  const [groupsDetail, setGroupDetail] = useState<INotificationGroup>()
  const [userDetail, setUserDetail] = useState<any>()
  // Validate for input
  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FILED }).min(1, { message: VALIDATION_FILED }).max(1000, {message: VALIDATE_GROUP_NAME}),
    users: z.any(),
    text: z.any()
  })

  const [newStep, setNewStep] = useState<any>([
    {
      title: 'Group Settings',
      status: 'current',
      link: ``,
    },
    {
      title: 'Add Member',
      status: '',
      link: '',
    },
  ])

  useEffect(() => {
    if (id) {
      setId(id as string)
      // set default page bằng tham số page
      if (searchParams.has('page')) {
        const index = Number(searchParams.get('page'))
        setNewStep((step: any[]) => {
          const _new = step.map((e, idx) => {
            if (index > idx) {
              return {...e, status: 'active'}
            }
            if (index === idx) {
              return {...e, status: 'current'}
            }
            if (index < idx) {
              return {...e, status: ''}
            }
          })
          return _new
        })
        searchParams.delete('page')
        setSearchParams(searchParams)
        navigate('.', {replace: true})
      }
    }
  }, [id])

  const handleGotoStep = (index: number) => {
    const _new = newStep.map((e: any, idx: number) => {
      if (index > idx) {
        return {...e, status: 'active'}
      }
      if (index === idx) {
        return {...e, status: 'current'}
      }
      if (index < idx) {
        return {...e, status: ''}
      }
    })
    getValues('name') ? setNewStep(_new) : setError('name', { message: VALIDATION_FILED })
  }

  // const params = useParams()
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    getValues
  } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn chắc chắn muốn thoát không?'],
      onClick: () => navigate('/list-group'),
    })
  }

  const onSubmit = async (data: IInputProps) => {
    if (user?.length > 0 || userDetail?.meta?.total_records > 0) {
      setLoading(true)
      if (id) {
        const res = await NotificationAPI.updateNotificationGroup(
          id,
          data.name,
          userDetail?.group_users?.map((user: any) => user.user_id)
        )
        if (res?.success) {
          navigate('/list-group')
        }
      } else {
        try {
          const res = await NotificationAPI.addNotificationGroup(
            data.name,
            user?.map((user: any) => user.id)
          )
          if (res?.success) {
            navigate('/list-group')
          }
        } catch (error) { }
        finally {
          setLoading(false)
        }
      }
    } else {
      setError('users', { message: VALIDATION_FILED })
    }
  }

  const handleDelete = async (userId: string) => {
    try {
      if(id) {
        const res = await NotificationAPI.deleteUserGroup([userId], groupsDetail?.id)
        if(res?.success) {
          fetchUserGroup(1, 10, getValues('text'))
        }
      }
      const arrayFiltered = user.filter((item: any) => !item.id.includes(userId))
      setUser(arrayFiltered)
    } catch (error) {
      toast.error('Delete Failed!')
    }
  }

  const handleDeleteUserGroup = async (id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có muốn xoá thành viên này khỏi group ?'],
      onClick: () => handleDelete(id),
    })
  }
  
  useEffect(() => {
    if (user?.length !== 0 || userDetail?.meta?.total_records !== 0) {
      setError('users', { message: '' })
    }
  }, [user?.length, userDetail?.meta?.total_records])

  async function fetchUserGroup(page_index: number, page_size: number, text?: string) {
    setLoading(true)
    try {
      await getUserGroup(id, page_index || 1, page_size || 10, text).then((res) => setUserDetail(res?.data))
    } catch (error) {}
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      NotificationAPI.detailNotificationGroup(id).then((res) => {
        setGroupDetail(res?.data)
      })
      fetchUserGroup(1, 10)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setValue('name', groupsDetail?.name ?? '')
    }
  }, [id, groupsDetail?.name])

  const [filteredData, setFilteredData] = useState([...user])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchName, setSearchName] = useState('')
  const [searchNameDraft, setSearchNameDraft] = useState('');

  // Xử lý khi currentPage, pageSize, hoặc user thay đổi
  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = user.slice(startIndex, endIndex);
    setFilteredData(paginatedData);
  }, [currentPage, pageSize, user]);

  // Xử lý khi giá trị của input tìm kiếm thay đổi
  useEffect(() => {
    const filtered = user.filter((item: any) =>
      item?.detail?.full_name?.toLowerCase().includes(searchName?.toLowerCase())
      || item?.username?.toLowerCase().includes(searchName?.toLowerCase())
    );

    const filteredVietnamese = filtered.filter((item: any) => {
      const searchTermLower = searchName?.toLowerCase();
      const usernameLower = item?.detail?.full_name?.toLowerCase() || item?.username?.toLowerCase()

      // Check if the username or fullname includes the search term (case-insensitive)
      if (usernameLower.includes(searchTermLower)) {
        return true;
      }

      // Check if the username or fullname matches the search term without accents
      return usernameLower.normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(searchTermLower.normalize("NFD").replace(/[\u0300-\u036f]/g, ''));
    });

    setFilteredData(filteredVietnamese.slice(0, pageSize)); // Reset trang về 1 khi thay đổi dữ liệu tìm kiếm
    setCurrentPage(1);
  }, [searchName, user, pageSize]);

  const handleChangeParams = async (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage);
    setPageSize(pageSize);
    id && await fetchUserGroup(currentPage, pageSize, getValues('text'))
  };

  // const handleConfirmSubmit = () => {
  //   confirm({
  //     okButtonCaption: 'Yes',
  //     cancelButtonCaption: 'No',
  //     body: ['Bạn có chắc chắn muốn chọn không?'],
  //     onClick: handleSubmit(onSubmit)
  //   })
  // }

  const totalUser = filteredData.length < 10 ? filteredData.length : user.length || 0

  const onSubmitUser = async () => {
    await fetchUserGroup(currentPage, pageSize, getValues('text'))
  }

  return (
    <div className='mw-900px mx-auto px-2'>
      <div className='d-flex justify-content-center'>
        <div className='sapp-proress-bar-wrapper sapp-mw-520px w-100'>
          <Processbar
            step={newStep}
            setNewStep={setNewStep}
            isNext={getValues('name') ? true : false}
          />
        </div>
      </div>
      <div className='pb-0'>
        {contextHolder}
        {newStep[0].status === 'current' && (
          <div>
            <div>
              <HookFormTextField
                control={control}
                name='name'
                placeholder=''
                className='w-100 sapp-h-45px fs-6'
                label='Group Name'
                required
                skeleton={loading}
              //   disabled={!editAble}
              />
            </div>
            <div className='d-flex gap-4 justify-content-end py-10 bg-white'>
              <ButtonSecondary title='Cancel' onClick={handleCancel} className='me-0 sapp-fs-14 sapp-line-height-16 sapp-py-14-5'/>
              <div onClick={() => handleGotoStep(1)}>
                <ButtonPrimary title='Save & Next' loading={loading} className='sapp-fs-14 sapp-line-height-16 sapp-py-14-5'/>
              </div>
            </div>
          </div>
        )}
        {newStep[1].status === 'current' &&
          <>
            <div className='d-flex flex-column'>
              <SappLabel required label={`Member List (${ userDetail?.meta?.total_records || totalUser || 0})`}></SappLabel>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                      {
                        id ? (
                          <>
                            <HookFormTextField
                              control={control}
                              name='text'
                              placeholder='Search member'
                              className='w-100 sapp-h-40px fs-6'
                            //   disabled={!editAble}
                            ></HookFormTextField>
                            <ButtonPrimary
                              title='Search'
                              className='ms-4 sapp-height-40px d-flex align-items-center sapp-fs--default'
                              onClick={handleSubmit(onSubmitUser)}/>
                          </>
                        ) : (
                          <>
                            <Search
                              showSearch={true}
                              placeholder={'Search member'}
                              smallSearch={true}
                              value={searchNameDraft}
                              onChange={(e) => setSearchNameDraft(e.target.value)}
                              className={'sapp-height-40px'}
                            ></Search>
                            <ButtonPrimary
                              title='Search'
                              className='ms-4 sapp-height-40px d-flex align-items-center sapp-fs--default'
                              onClick={() => setSearchName(searchNameDraft)}
                            />
                          </>
                        )
                      }
                    </div>
                    <ButtonIcon
                      title={'Add Member'}
                      className='sapp-height-min--content sapp-height-40px d-flex align-items-center px-4 sapp-fs--default'
                      customButton='btn btn-primary btn-active-primary'
                      onClick={() => setOpenAddUser(true)} 
                    >
                      <KTIcon iconName='plus' className='fs-4' />
                    </ButtonIcon>
                  </div>
                  {/* {user?.map((e: any) => ( */}
                  <div className='mb-0'>
                    <div className='card-body pt-10 pb-0 px-0'>
                      <CardMember data={id ? userDetail?.group_users : filteredData} action={handleDeleteUserGroup} currentPage={currentPage} pageSize={pageSize} loading={loading}/>
                    </div>
                    <PagiantionSAPP
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalItems={id ? userDetail?.meta?.total_records : user.length}
                      handleChangeParams={handleChangeParams}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
              {!isEmpty(errors?.users?.message) && (
                <ErrorMessage>{errors?.users?.message as string}</ErrorMessage>
              )}
              <div className='d-flex gap-4 justify-content-end py-10 bg-white'>
                <ButtonPrimary title='Finish' onClick={handleSubmit(onSubmit)} loading={loading} className='sapp-fs-14 sapp-line-height-16 sapp-py-14-5'/>
              </div>
            </div>
          </>
        }
        <AddUserToGroup open={openAddUser} setOpen={setOpenAddUser} user={id ? userDetail?.group_users : user} setUser={id ? setUserDetail : setUser} fetchUserGroup={fetchUserGroup}/>
      </div>
    </div>
  )
}
export default CreateGroupComponent
