interface IProps {
  value: number
  height?: string // in px
}

const Processing = ({value, height='8px'}: IProps) => {
  return (
    <div>
      <div className={`bg-light w-100 h-${height} border-0 rounded-pill position-relative`}>
        <div
          className={`bg-primary h-${height} border-0 rounded-pill position-absolute top-0 start-0`}
          style={{width: `${value}%`}}
        ></div>
      </div>
    </div>
  )
}
export default Processing
