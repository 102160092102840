import {useLocation, useParams} from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import {TEST_TYPE} from 'src/components/courses/shared/Interfaces'
import TestComponent from 'src/components/courses/test/TestComponent'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.COURSES}`,
    title: LANG_SIDEBAR.courses,
  },
  {
    link: `${PageLink.TEST}`,
    title: LANG_SIDEBAR.test,
  },
]

const Test = () => {
  const params = useParams()
  const {state} = useLocation()  
  return (
    <PageLayouts
      // pageTitle='Test'
      breadcrumbs={breadcrumbs}
      // showSideBar={false}
      backgroudColor='bg-white'
      pageTitle=''
      showSideBar={false}
      removePadding={true}
    >
      {((params.type && params.id && params.testFor) || params.editId) && (
        <>
          <div className='sapp-text-primary fs-3 fw-bold  pb-8 border-gray-300 border-bottom mb-8'>
            {`${params?.editId ? 'Edit' : 'Create'}`}{' '}
            {(() => {
              if (params.type?.toUpperCase() === TEST_TYPE.MID_TERM_TEST) {
                return 'MidTerm Test'
              }
              if (params.type?.toUpperCase() === TEST_TYPE.FINAL_TEST) {
                return 'Final Test'
              }
              if (params.type?.toUpperCase() === TEST_TYPE.TOPIC_TEST) {
                return 'Part/ Topic Test'
              }
              if (params.type?.toUpperCase() === TEST_TYPE.CHAPTER_TEST) {
                return 'Chapter/ Module Test'
              }
              return ''
            })()}
          </div>
          <TestComponent
            type={params.type as TEST_TYPE}
            id={params.id || ''}
            testFor={params.testFor || ''}
            editId={params.editId}
            editOnlyName={state?.onlyEditName}
          ></TestComponent>
        </>
      )}
    </PageLayouts>
  )
}

export default withAuthComponents(Test)
