import React from 'react'
import {LANG_HEADER} from 'src/constants/lang'

const PopOverPayments = () => {
  return (
    <div className='menu menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
      <div className='menu-item px-3'>
        <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>Payments</div>
      </div>

      <div className='menu-item px-3'>
        <a href='/' className='menu-link px-3'>
          {LANG_HEADER.createInvoice}
        </a>
      </div>

      <div className='menu-item px-3'>
        <a href='/' className='menu-link flex-stack px-3'>
          {LANG_HEADER.createPayment}
          <span
            className='ms-2'
            data-bs-toggle='tooltip'
            title='Specify a target name for future usage and reference'
          >
            <i className='ki-outline ki-information fs-6'></i>
          </span>
        </a>
      </div>

      <div className='menu-item px-3'>
        <a href='/' className='menu-link px-3'>
          {LANG_HEADER.generateBill}
        </a>
      </div>

      <div className='menu-item px-3 my-1'>
        <a href='/' className='menu-link px-3'>
          {LANG_HEADER.settings}
        </a>
      </div>
    </div>
  )
}

export default PopOverPayments
