import {Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState} from 'react'
import {CoursesAPI} from 'src/apis/courses'
import {TYPE_COURSE} from 'src/constants'
import { TreeHelper } from 'src/helper/tree'

type Context = {
  // setCourseDetail: () => void
  courseDetail: any
  getCourseDetail: (id: string) => void
  loading: boolean
  setId: React.Dispatch<React.SetStateAction<string>>
  idCourse: string
  setLoadingDelete: React.Dispatch<React.SetStateAction<boolean>>
  loadingDelete: boolean
  getCourse: (id: string) => void
  course: any
  rawCourseData: any
  setCourse: Dispatch<SetStateAction<any>>
}
const initContext: Context = {
  courseDetail: [],
  getCourseDetail: () => {},
  loading: false,
  setId: () => {},
  idCourse: '',
  setLoadingDelete: () => {},
  loadingDelete: false,
  getCourse: () => {},
  course: [],
  setCourse: () => {},
  rawCourseData: [],
}

const CourseContext = createContext<Context>(initContext)
type TreeRow = {
  id: string
  parent_id: string | null
  children?: unknown[]
}

export function CourseProvider(props: PropsWithChildren<{}>) {
  const [courseDetail, setCoursesDetail] = useState<any>()
  const [rawCourseData, setRawCourseData] = useState<any>()
  const [course, setCourse] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [idCourse, setId] = useState<string>('')
  const handleConvertData = (data: any) => {
    const newData = [...data]
    for (let e of newData) {
      // if (e.course_section_type === TYPE_COURSE.PART) {
      //   if (e?.course_section_quiz_instances?.length) {
      //     const quiz_children = e?.course_section_quiz_instances?.map((record: any) => {
      //       const quiz = record?.quiz
      //       const course_section_type = quiz?.type
      //       return {
      //         ...quiz,
      //         children: [],
      //         course_section_type,
      //         course_section_id: e.id,
      //       }
      //     })
      //     e.children = [...e.children, ...quiz_children]
      //   }
      // }
      for (let el of e?.children) {
        if (el?.course_learning_outcome) {
          el.children = [
            {
              ...el?.course_learning_outcome,
              children: [],
              course_section_type: TYPE_COURSE.LEARNINGOUTCOME,
            },
            ...el.children,
          ]
        }
        // if (
        //   el?.course_section_quiz_instances?.length &&
        //   el?.course_section_type !== TYPE_COURSE.FINAL_TEST &&
        //   el?.course_section_type !== TYPE_COURSE.MID_TERM_TEST
        // ) {
        //   const quiz_children = el?.course_section_quiz_instances?.map((record: any) => {
        //     const quiz = record?.quiz
        //     const course_section_type = quiz?.type === 'STORY' ? TYPE_COURSE.CASE_STUDY : quiz?.type
        //     return {
        //       ...quiz,
        //       children: [],
        //       course_section_type,
        //       course_section_id: el.id,
        //     }
        //   })
        //   el.children = [...el.children, ...quiz_children]
        // }
      }
    }
    return newData
  }

  function convertListToTree(list: TreeRow[]) {
    const map: any = {}
    const roots = []

    for (let i = 0; i < list.length; i += 1) {
      map[list[i].id] = i // initialize the map
      list[i].children = [] // initialize the children
    }

    for (let i = 0; i < list.length; i += 1) {
      const node = list[i]
      if (node.parent_id !== null) {
        // if you have dangling branches check that map[node.parentId] exists
        list?.[map[node.parent_id]]?.children?.push(node)
      } else {
        roots.push(node)
      }
    }
    
    return roots
  }

  const getCourseDetail = async (id: string) => {
    setLoading(true)
    try {
      const res = await CoursesAPI.getCoursesById({id})
      setRawCourseData(res.data)
      setCoursesDetail(() => {
        setLoading(false)
        return [...handleConvertData(TreeHelper.convertFromArray(res.data, {convert_original: true}))]
      })
    } catch (err) {
      setLoading(false)
    }
  }

  // TODO: gọi API course detail
  const getCourse = async (id: string) => {
    try {
      const res = await CoursesAPI.getCourseDetail(id)
      setCourse(res?.data)
    } catch (error) {}
  }

  return (
    <CourseContext.Provider
      value={{
        rawCourseData,
        courseDetail,
        getCourseDetail,
        loading,
        setId,
        idCourse,
        setLoadingDelete,
        loadingDelete,
        course,
        getCourse,
        setCourse
      }}
      {...props}
    />
  )
}
export function useCourseContext(): Context {
  const context = useContext(CourseContext)

  if (!context) {
    throw new Error('Error!')
  }

  return context
}
