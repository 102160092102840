import {PageLink, VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import {useNavigate} from 'react-router-dom'
import withUnAuthComponents from 'src/components/auth/with-unauth-components'
import {useUserContext} from 'src/context/UserProvider'
import {toast} from 'react-hot-toast'
import {LANG_SIGNIN} from 'src/constants/lang'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import './login.scss'
import sapp from 'src/_metronic/assets/media/logos/sapp-logo-default.svg'

interface IInputProps {
  username: string
  password: string
}

const Login = () => {
  const {loginUsername} = useUserContext()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  // Validate for input
  const validationSchema = z.object({
    username: z.string({required_error: VALIDATION_FILED}).min(1, {message: VALIDATION_FILED}),
    password: z.string({required_error: VALIDATION_FILED}).min(8, {message: VALIDATION_FILED}),
  })

  // Using validate for input
  const {control, handleSubmit} = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  // Call API when submit
  const onSubmit = (data: IInputProps) => {
    setLoading(true)
    const {username, password} = data

    loginUsername(username, password)
      .then(() => {
        navigate(window.localStorage.getItem('historyUrl') as string || PageLink.DASHBOARD)
        toast.success('Login SuccessFull!')
      })
      .finally(() => setLoading(false))
  }

  const [passwordVisible, setPasswordVisible] = useState(false)
  const toggleChangeType = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <div className='d-flex flex-column flex-root sapp-height-layout--login justify-content-center'>
      <div className='d-flex justify-content-center'>
        <img className='sapp-logo w-125px object-fit-contain' src={sapp} alt='sapp' />
      </div>
      <div className='d-flex flex-column  flex-lg-row justify-content-center'>
        <div className='d-flex  flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20 sapp-p--layout'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 box-shadow--custom'>
            <div className='d-flex flex-center flex-column'>
              {/* Start Form Login */}
              <form className='form w-100' onSubmit={handleSubmit(onSubmit)}>
                <div className='text-center mb-11'>
                  <h1 className='text-dark fw-bolder mb-3 text-left'>{LANG_SIGNIN.signIn}</h1>
                  <div className='text-gray-500 fw-semibold fs-6 text-left'>
                    {LANG_SIGNIN.SAPPAcademy}
                  </div>
                </div>

                {/* Start TextField Email */}
                <div className='fv-row mb-8'>
                  <HookFormTextField control={control} name='username' placeholder='Username' className='sapp-h-50px'/>
                </div>
                {/* End TextField Email */}

                {/* Start TextField Password */}
                <div className='fv-row mb-3'>
                  <HookFormTextField
                    control={control}
                    name='password'
                    placeholder={LANG_SIGNIN.password}
                    type={!passwordVisible ? 'password' : 'text'}
                    onChangeType={toggleChangeType}
                    passwordVisible={passwordVisible}
                    showIconPassword
                    className='sapp-h-50px'
                  />
                </div>
                {/* End TextField Password */}

                {/* Start Button Forgot Password */}
                <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                  <div></div>
                  <Link
                    to={PageLink.AUTH_FORGOTPASSWORD}
                    className='link-primary sapp-link-primary'
                  >
                    {LANG_SIGNIN.forgotPassword}
                  </Link>
                </div>
                {/* End Button Forgot Password */}

                {/* Start Button Login */}
                <div className='d-grid mb-10 sapp-h-50px'>
                  <ButtonPrimary title='Sign In' loading={loading} type='submit' />
                </div>
                {/* End Button Login */}
              </form>
              {/* End Form Login */}
            </div>

            {/* Start Change Language */}
            <div className='d-flex justify-content-end flex-stack px-lg-10'>
              <div className='d-flex fw-semibold text-primary fs-base gap-5'>
                <a className='sapp-link-primary' href={PageLink.AUTH_LOGIN}>
                  {LANG_SIGNIN.terms}
                </a>
                <a className='sapp-link-primary' href={PageLink.AUTH_LOGIN}>
                  {LANG_SIGNIN.contactUs}
                </a>
              </div>
            </div>
            {/* End Change Language */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withUnAuthComponents(Login)
