export const TrueFalseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='134'
      height='103'
      viewBox='0 0 127 95'
      fill='none'
    >
      <g clip-path='url(#clip0_10990_7944)'>
        <path
          d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
          fill='white'
          stroke='#99A1B7'
          strokeMiterlimit='10'
        />
        <path
          d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
          fill='#DBDFE9'
          stroke='#99A1B7'
          strokeMiterlimit='10'
        />
        <path
          d='M48.6492 35.0781H93.1217'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          d='M48.6492 41.3418H106.484'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M49.4847 64.3086H93.9573'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M49.4847 72.6606H99.8034'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M49.4847 68.4844H107.529'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          d='M38.2096 48.6496H20.4623C19.2096 48.6496 18.3744 47.8145 18.3744 46.5617V28.8145C18.3744 27.5617 19.2096 26.7266 20.4623 26.7266H38.2096C39.4623 26.7266 40.2975 27.5617 40.2975 28.8145V46.5617C40.2975 47.6057 39.2535 48.6496 38.2096 48.6496Z'
          fill='#DBDFE9'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M38.2096 79.968H20.4623C19.2096 79.968 18.3744 79.1328 18.3744 77.8801V60.1328C18.3744 58.8801 19.2096 58.0449 20.4623 58.0449H38.2096C39.4623 58.0449 40.2975 58.8801 40.2975 60.1328V77.8801C40.2975 78.924 39.2535 79.968 38.2096 79.968Z'
          stroke='#99A1B7'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          d='M23.8026 36.9578L28.1872 41.3424L34.6598 34.6611'
          stroke='#99A1B7'
          strokeWidth='0.75'
          strokeMiterlimit='10'
        />
        <path
          d='M25.0549 63.6816L29.2308 67.8575L33.1978 63.8904L34.2418 64.9344L30.2747 68.9014L34.4505 73.0772L33.4066 74.1212L29.2308 69.9454L25.2637 73.9124L24.2198 72.8685L28.1868 68.9014L24.011 64.7256L25.0549 63.6816Z'
          fill='#DBDFE9'
        />
      </g>
      <defs>
        <clipPath id='clip0_10990_7944'>
          <rect width='126.319' height='103' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const TrueFalseIconActive = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='134'
      height='103'
      viewBox='0 0 127 95'
      fill='none'
    >
      <g clip-path='url(#clip0_10990_7930)'>
        <path
          d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
          fill='white'
          stroke='#FFB800'
          strokeMiterlimit='10'
        />
        <path
          d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
          fill='#FFF4D8'
          stroke='#FFB800'
          strokeMiterlimit='10'
        />
        <path
          d='M48.6492 35.0781H93.1217'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          d='M48.6492 41.3418H106.484'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M49.4847 64.3086H93.9573'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M49.4847 72.6606H99.8034'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M49.4847 68.4844H107.529'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          d='M38.2096 48.6496H20.4623C19.2096 48.6496 18.3744 47.8145 18.3744 46.5617V28.8145C18.3744 27.5617 19.2096 26.7266 20.4623 26.7266H38.2096C39.4623 26.7266 40.2975 27.5617 40.2975 28.8145V46.5617C40.2975 47.6057 39.2535 48.6496 38.2096 48.6496Z'
          fill='#FFF4D8'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          opacity='0.5'
          d='M38.2096 79.968H20.4623C19.2096 79.968 18.3744 79.1328 18.3744 77.8801V60.1328C18.3744 58.8801 19.2096 58.0449 20.4623 58.0449H38.2096C39.4623 58.0449 40.2975 58.8801 40.2975 60.1328V77.8801C40.2975 78.924 39.2535 79.968 38.2096 79.968Z'
          stroke='#FFB800'
          strokeWidth='0.5'
          strokeMiterlimit='10'
        />
        <path
          d='M23.8026 36.9578L28.1872 41.3424L34.6598 34.6611'
          stroke='#FFB800'
          strokeWidth='0.75'
          strokeMiterlimit='10'
        />
        <path
          d='M25.0549 63.6816L29.2308 67.8575L33.1978 63.8904L34.2418 64.9344L30.2747 68.9014L34.4505 73.0772L33.4066 74.1212L29.2308 69.9454L25.2637 73.9124L24.2198 72.8685L28.1868 68.9014L24.011 64.7256L25.0549 63.6816Z'
          fill='#FFF4D8'
        />
      </g>
      <defs>
        <clipPath id='clip0_10990_7930'>
          <rect width='126.319' height='103' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const OnceChoiceIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7877)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M27.1428 41.342C31.7553 41.342 35.4944 37.6028 35.4944 32.9903C35.4944 28.3778 31.7553 24.6387 27.1428 24.6387C22.5303 24.6387 18.7911 28.3778 18.7911 32.9903C18.7911 37.6028 22.5303 41.342 27.1428 41.342Z'
        fill='white'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M27.1428 62.2209C31.7553 62.2209 35.4944 58.4817 35.4944 53.8692C35.4944 49.2567 31.7553 45.5176 27.1428 45.5176C22.5303 45.5176 18.7911 49.2567 18.7911 53.8692C18.7911 58.4817 22.5303 62.2209 27.1428 62.2209Z'
        fill='white'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M27.1429 58.045C29.4491 58.045 31.3187 56.1754 31.3187 53.8692C31.3187 51.5629 29.4491 49.6934 27.1429 49.6934C24.8366 49.6934 22.967 51.5629 22.967 53.8692C22.967 56.1754 24.8366 58.045 27.1429 58.045Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M27.1428 83.0998C31.7553 83.0998 35.4944 79.3606 35.4944 74.7481C35.4944 70.1356 31.7553 66.3965 27.1428 66.3965C22.5303 66.3965 18.7911 70.1356 18.7911 74.7481C18.7911 79.3606 22.5303 83.0998 27.1428 83.0998Z'
        fill='white'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M43.8461 30.9023H91.868'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M43.8461 37.166H106.483'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 72.6606H92.9121'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 78.9243H107.527'
        stroke='#99A1B7'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
      <path d='M44.8901 49.6934H92.9121' stroke='#99A1B7' strokeWidth='0.5' strokeMiterlimit='10' />
      <path d='M44.8901 58.0449H99.1758' stroke='#99A1B7' strokeWidth='0.5' strokeMiterlimit='10' />
      <path d='M44.8901 53.8691H107.527' stroke='#99A1B7' strokeWidth='0.5' strokeMiterlimit='10' />
    </g>
    <defs>
      <clipPath id='clip0_10990_7877'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const OnceChoiceIconActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7862)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M27.1428 41.342C31.7553 41.342 35.4944 37.6028 35.4944 32.9903C35.4944 28.3778 31.7553 24.6387 27.1428 24.6387C22.5303 24.6387 18.7911 28.3778 18.7911 32.9903C18.7911 37.6028 22.5303 41.342 27.1428 41.342Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M27.1428 62.2209C31.7553 62.2209 35.4944 58.4817 35.4944 53.8692C35.4944 49.2567 31.7553 45.5176 27.1428 45.5176C22.5303 45.5176 18.7911 49.2567 18.7911 53.8692C18.7911 58.4817 22.5303 62.2209 27.1428 62.2209Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M27.1429 58.045C29.4491 58.045 31.3187 56.1754 31.3187 53.8692C31.3187 51.5629 29.4491 49.6934 27.1429 49.6934C24.8366 49.6934 22.967 51.5629 22.967 53.8692C22.967 56.1754 24.8366 58.045 27.1429 58.045Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M27.1428 83.0998C31.7553 83.0998 35.4944 79.3606 35.4944 74.7481C35.4944 70.1356 31.7553 66.3965 27.1428 66.3965C22.5303 66.3965 18.7911 70.1356 18.7911 74.7481C18.7911 79.3606 22.5303 83.0998 27.1428 83.0998Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M43.8461 30.9023H91.868'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M43.8461 37.166H106.483'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 72.6606H92.9121'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 78.9243H107.527'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 49.6934H92.9121'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 58.0449H99.1758'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 53.8691H107.527'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7862'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const MultipleChoiceIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7846)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M43.8461 30.9023H91.868'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M43.8461 37.166H106.483'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 72.6606H92.9121'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 78.9243H107.527'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 49.6934H92.9121'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 58.0449H99.1758'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 53.8691H107.527'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M30.9019 41.3419H20.4623C19.2096 41.3419 18.3744 40.5068 18.3744 39.254V28.8145C18.3744 27.5617 19.2096 26.7266 20.4623 26.7266H30.9019C32.1546 26.7266 32.9898 27.5617 32.9898 28.8145V39.254C32.9898 40.298 31.9458 41.3419 30.9019 41.3419Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M30.9019 62.2209H20.4623C19.2096 62.2209 18.3744 61.3857 18.3744 60.1329V49.6934C18.3744 48.4406 19.2096 47.6055 20.4623 47.6055H30.9019C32.1546 47.6055 32.9898 48.4406 32.9898 49.6934V60.1329C32.9898 61.1769 31.9458 62.2209 30.9019 62.2209Z'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M30.9019 83.0998H20.4623C19.2096 83.0998 18.3744 82.2646 18.3744 81.0118V70.5723C18.3744 69.3195 19.2096 68.4844 20.4623 68.4844H30.9019C32.1546 68.4844 32.9898 69.3195 32.9898 70.5723V81.0118C32.9898 82.0558 31.9458 83.0998 30.9019 83.0998Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M20.4624 32.9903L24.6382 37.1661L30.902 30.9023'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M20.4624 74.7486L24.6382 78.9244L30.902 72.6606'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7846'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const MultipleChoiceActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7830)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M43.8461 30.9023H91.868'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M43.8461 37.166H106.483'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 72.6606H92.9121'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M44.8901 78.9243H107.527'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 49.6934H92.9121'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 58.0449H99.1758'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M44.8901 53.8691H107.527'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M30.9019 41.3419H20.4623C19.2096 41.3419 18.3744 40.5068 18.3744 39.254V28.8145C18.3744 27.5617 19.2096 26.7266 20.4623 26.7266H30.9019C32.1546 26.7266 32.9898 27.5617 32.9898 28.8145V39.254C32.9898 40.298 31.9458 41.3419 30.9019 41.3419Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        opacity='0.5'
        d='M30.9019 62.2209H20.4623C19.2096 62.2209 18.3744 61.3857 18.3744 60.1329V49.6934C18.3744 48.4406 19.2096 47.6055 20.4623 47.6055H30.9019C32.1546 47.6055 32.9898 48.4406 32.9898 49.6934V60.1329C32.9898 61.1769 31.9458 62.2209 30.9019 62.2209Z'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M30.9019 83.0998H20.4623C19.2096 83.0998 18.3744 82.2646 18.3744 81.0118V70.5723C18.3744 69.3195 19.2096 68.4844 20.4623 68.4844H30.9019C32.1546 68.4844 32.9898 69.3195 32.9898 70.5723V81.0118C32.9898 82.0558 31.9458 83.0998 30.9019 83.0998Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M20.4624 32.9903L24.6382 37.1661L30.902 30.9023'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M20.4624 74.7486L24.6382 78.9244L30.902 72.6606'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7830'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const MatchingIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7815)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M59.0888 32.1557C59.0888 29.859 60.9679 27.9799 63.2646 27.9799V24.2217H19.4185V38.8371H63.2646V36.3316C60.9679 36.3316 59.0888 34.4524 59.0888 32.1557Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M71.1995 24.2217V27.9799C68.9028 27.9799 67.0237 29.859 67.0237 32.1557C67.0237 34.4524 68.9028 36.3316 71.1995 36.3316V38.8371H115.046V24.2217H71.1995Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M59.0888 53.0347C59.0888 50.7379 60.9679 48.8588 63.2646 48.8588V45.1006H19.4185V59.716H63.2646V57.2105C60.9679 57.2105 59.0888 55.3314 59.0888 53.0347Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M63.0562 45.1006V48.8588C60.7595 48.8588 58.8804 50.7379 58.8804 53.0347C58.8804 55.3314 60.7595 57.2105 63.0562 57.2105V59.716H106.902V45.1006H63.0562Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M59.0888 73.9141C59.0888 71.6173 60.9679 69.7382 63.2646 69.7382V65.98H19.4185V80.5954H63.2646V78.0899C60.9679 78.0899 59.0888 76.2108 59.0888 73.9141Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M63.0562 65.98V69.7382C60.7595 69.7382 58.8804 71.6173 58.8804 73.9141C58.8804 76.2108 60.7595 78.0899 63.0562 78.0899V80.5954H106.902V65.98H63.0562Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M98.7591 39.6682C98.1327 39.6682 97.7151 40.0858 97.7151 40.7122V43.2177C97.7151 43.4265 97.5063 43.6353 97.2975 43.6353C97.0887 43.6353 96.88 43.4265 96.88 43.2177V39.6682C96.88 39.0419 96.4624 38.6243 95.836 38.6243C95.2096 38.6243 94.792 39.0419 94.792 39.6682V43.2177C94.792 43.4265 94.5833 43.6353 94.3745 43.6353C94.1657 43.6353 93.9569 43.4265 93.9569 43.2177V38.6243C93.9569 37.9979 93.5393 37.5803 92.9129 37.5803C92.2865 37.5803 91.869 37.9979 91.869 38.6243V43.2177C91.869 43.4265 91.6602 43.6353 91.4514 43.6353C91.2426 43.6353 91.0338 43.4265 91.0338 43.2177V33.6133C91.0338 32.9869 90.6162 32.5693 89.9898 32.5693C89.3635 32.5693 88.9459 32.9869 88.9459 33.6133V46.3496C88.9459 46.5583 88.9459 46.7671 88.7371 46.7671C88.5283 46.7671 88.3195 46.7671 88.3195 46.7671L84.7701 45.0968C84.5613 44.888 84.1437 44.888 83.9349 44.888C83.5173 44.888 83.0997 45.3056 83.0997 45.7232C83.0997 45.932 83.0997 46.1408 83.3085 46.3496L89.7811 52.8221C91.0338 54.0748 92.7041 54.91 94.5832 54.91C97.7151 54.91 100.221 52.4045 100.221 49.2726V40.7122C99.5942 40.0858 99.1767 39.6682 98.7591 39.6682Z'
        fill='white'
      />
      <path
        d='M98.7595 38.626C98.3419 38.626 97.9243 38.8347 97.7155 39.0435C97.5068 38.2084 96.6716 37.7908 95.8364 37.7908C95.4188 37.7908 95.0013 37.9996 94.7925 38.2084C94.5837 37.3732 93.7485 36.9556 92.9133 36.9556C92.4958 36.9556 92.287 36.9556 91.8694 37.1644V33.8238C91.8694 32.7798 91.0342 31.7358 89.7815 31.7358C88.7375 31.7358 87.6936 32.571 87.6936 33.8238V45.7249L84.9793 44.2633C84.5617 44.0545 84.1441 43.8457 83.7265 43.8457C82.6826 43.8457 81.8474 44.6809 81.8474 45.7249C81.8474 46.1424 82.0562 46.7688 82.4738 47.1864L88.9463 53.6589C90.4078 55.1205 92.287 55.9556 94.5837 55.9556C98.1331 55.9556 101.056 53.0325 101.056 49.4831V40.9227C100.639 39.6699 99.8035 38.626 98.7595 38.626ZM99.5947 49.2743C99.5947 52.4062 97.0892 54.9117 93.9573 54.9117C92.0782 54.9117 90.4079 54.2853 89.1551 52.8238L82.6826 46.3512C82.4738 46.1424 82.4738 45.9336 82.4738 45.7249C82.4738 45.3073 82.8914 44.8897 83.309 44.8897C83.5177 44.8897 83.9353 44.8897 84.1441 45.0985L87.6936 46.7688C87.9024 46.7688 88.1111 46.7688 88.1111 46.7688C88.3199 46.7688 88.3199 46.56 88.3199 46.3512V33.615C88.3199 32.9886 88.7375 32.571 89.3639 32.571C89.9903 32.571 90.4078 32.9886 90.4078 33.615V43.2194C90.4078 43.4281 90.6166 43.6369 90.8254 43.6369C91.0342 43.6369 91.243 43.4281 91.243 43.2194V38.626C91.243 37.9996 91.6606 37.582 92.287 37.582C92.9133 37.582 93.3309 37.9996 93.3309 38.626V43.2194C93.3309 43.4281 93.5397 43.6369 93.7485 43.6369C93.9573 43.6369 94.1661 43.4281 94.1661 43.2194V39.6699C94.1661 39.0435 94.5837 38.626 95.21 38.626C95.8364 38.626 96.254 39.0435 96.254 39.6699V43.2194C96.254 43.4281 96.4628 43.6369 96.6716 43.6369C96.8804 43.6369 97.0892 43.4281 97.0892 43.2194V40.7139C97.0892 40.0875 97.5068 39.6699 98.1331 39.6699C98.7595 39.6699 99.1771 40.0875 99.1771 40.7139L99.5947 49.2743Z'
        fill='#78829D'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7815'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const MatchingIconActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7800)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M59.0888 32.1557C59.0888 29.859 60.9679 27.9799 63.2646 27.9799V24.2217H19.4185V38.8371H63.2646V36.3316C60.9679 36.3316 59.0888 34.4524 59.0888 32.1557Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M71.1995 24.2217V27.9799C68.9028 27.9799 67.0237 29.859 67.0237 32.1557C67.0237 34.4524 68.9028 36.3316 71.1995 36.3316V38.8371H115.046V24.2217H71.1995Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M59.0888 53.0347C59.0888 50.7379 60.9679 48.8588 63.2646 48.8588V45.1006H19.4185V59.716H63.2646V57.2105C60.9679 57.2105 59.0888 55.3314 59.0888 53.0347Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M63.0562 45.1006V48.8588C60.7595 48.8588 58.8804 50.7379 58.8804 53.0347C58.8804 55.3314 60.7595 57.2105 63.0562 57.2105V59.716H106.902V45.1006H63.0562Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M59.0888 73.9141C59.0888 71.6173 60.9679 69.7382 63.2646 69.7382V65.98H19.4185V80.5954H63.2646V78.0899C60.9679 78.0899 59.0888 76.2108 59.0888 73.9141Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M63.0562 65.98V69.7382C60.7595 69.7382 58.8804 71.6173 58.8804 73.9141C58.8804 76.2108 60.7595 78.0899 63.0562 78.0899V80.5954H106.902V65.98H63.0562Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M98.7591 39.6682C98.1327 39.6682 97.7151 40.0858 97.7151 40.7122V43.2177C97.7151 43.4265 97.5063 43.6353 97.2975 43.6353C97.0887 43.6353 96.88 43.4265 96.88 43.2177V39.6682C96.88 39.0419 96.4624 38.6243 95.836 38.6243C95.2096 38.6243 94.792 39.0419 94.792 39.6682V43.2177C94.792 43.4265 94.5833 43.6353 94.3745 43.6353C94.1657 43.6353 93.9569 43.4265 93.9569 43.2177V38.6243C93.9569 37.9979 93.5393 37.5803 92.9129 37.5803C92.2865 37.5803 91.869 37.9979 91.869 38.6243V43.2177C91.869 43.4265 91.6602 43.6353 91.4514 43.6353C91.2426 43.6353 91.0338 43.4265 91.0338 43.2177V33.6133C91.0338 32.9869 90.6162 32.5693 89.9898 32.5693C89.3635 32.5693 88.9459 32.9869 88.9459 33.6133V46.3496C88.9459 46.5583 88.9459 46.7671 88.7371 46.7671C88.5283 46.7671 88.3195 46.7671 88.3195 46.7671L84.7701 45.0968C84.5613 44.888 84.1437 44.888 83.9349 44.888C83.5173 44.888 83.0997 45.3056 83.0997 45.7232C83.0997 45.932 83.0997 46.1408 83.3085 46.3496L89.7811 52.8221C91.0338 54.0748 92.7041 54.91 94.5832 54.91C97.7151 54.91 100.221 52.4045 100.221 49.2726V40.7122C99.5942 40.0858 99.1767 39.6682 98.7591 39.6682Z'
        fill='white'
      />
      <path
        d='M98.7595 38.626C98.3419 38.626 97.9243 38.8347 97.7155 39.0435C97.5068 38.2084 96.6716 37.7908 95.8364 37.7908C95.4188 37.7908 95.0013 37.9996 94.7925 38.2084C94.5837 37.3732 93.7485 36.9556 92.9133 36.9556C92.4958 36.9556 92.287 36.9556 91.8694 37.1644V33.8238C91.8694 32.7798 91.0342 31.7358 89.7815 31.7358C88.7375 31.7358 87.6936 32.571 87.6936 33.8238V45.7249L84.9793 44.2633C84.5617 44.0545 84.1441 43.8457 83.7265 43.8457C82.6826 43.8457 81.8474 44.6809 81.8474 45.7249C81.8474 46.1424 82.0562 46.7688 82.4738 47.1864L88.9463 53.6589C90.4078 55.1205 92.287 55.9556 94.5837 55.9556C98.1331 55.9556 101.056 53.0325 101.056 49.4831V40.9227C100.639 39.6699 99.8035 38.626 98.7595 38.626ZM99.5947 49.2743C99.5947 52.4062 97.0892 54.9117 93.9573 54.9117C92.0782 54.9117 90.4079 54.2853 89.1551 52.8238L82.6826 46.3512C82.4738 46.1424 82.4738 45.9336 82.4738 45.7249C82.4738 45.3073 82.8914 44.8897 83.309 44.8897C83.5177 44.8897 83.9353 44.8897 84.1441 45.0985L87.6936 46.7688C87.9024 46.7688 88.1111 46.7688 88.1111 46.7688C88.3199 46.7688 88.3199 46.56 88.3199 46.3512V33.615C88.3199 32.9886 88.7375 32.571 89.3639 32.571C89.9903 32.571 90.4078 32.9886 90.4078 33.615V43.2194C90.4078 43.4281 90.6166 43.6369 90.8254 43.6369C91.0342 43.6369 91.243 43.4281 91.243 43.2194V38.626C91.243 37.9996 91.6606 37.582 92.287 37.582C92.9133 37.582 93.3309 37.9996 93.3309 38.626V43.2194C93.3309 43.4281 93.5397 43.6369 93.7485 43.6369C93.9573 43.6369 94.1661 43.4281 94.1661 43.2194V39.6699C94.1661 39.0435 94.5837 38.626 95.21 38.626C95.8364 38.626 96.254 39.0435 96.254 39.6699V43.2194C96.254 43.4281 96.4628 43.6369 96.6716 43.6369C96.8804 43.6369 97.0892 43.4281 97.0892 43.2194V40.7139C97.0892 40.0875 97.5068 39.6699 98.1331 39.6699C98.7595 39.6699 99.1771 40.0875 99.1771 40.7139L99.5947 49.2743Z'
        fill='#78829D'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7800'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const DragNDropIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7781)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M65.7692 44.4727H37.5824C36.3297 44.4727 35.4945 43.6375 35.4945 42.3848V33.4068C35.4945 32.154 36.3297 31.3188 37.5824 31.3188H65.7692C67.022 31.3188 67.8571 32.154 67.8571 33.4068V42.3848C67.8571 43.4287 67.022 44.4727 65.7692 44.4727Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M53.869 76.4185H25.6822C24.4294 76.4185 23.5942 75.5833 23.5942 74.3306V65.3526C23.5942 64.0998 24.4294 63.2646 25.6822 63.2646H53.869C55.1217 63.2646 55.9569 64.0998 55.9569 65.3526V74.3306C55.9569 75.3745 55.1217 76.4185 53.869 76.4185Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M59.9244 67.023H31.7376C30.4848 67.023 29.6497 66.1878 29.6497 64.9351V55.9571C29.6497 54.7043 30.4848 53.8691 31.7376 53.8691H59.9244C61.1771 53.8691 62.0123 54.7043 62.0123 55.9571V64.9351C62.0123 65.979 60.9683 67.023 59.9244 67.023Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M35.4967 60.3433H55.9582'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M101.891 76.4185H73.7041C72.4514 76.4185 71.6162 75.5833 71.6162 74.3306V65.3526C71.6162 64.0998 72.4514 63.2646 73.7041 63.2646H101.891C103.144 63.2646 103.979 64.0998 103.979 65.3526V74.3306C103.979 75.3745 103.144 76.4185 101.891 76.4185Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M77.6716 69.7388H98.1332'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M62.6593 68.0979C62.033 68.0979 61.6154 68.5155 61.6154 69.1419V71.6474C61.6154 71.8562 61.4066 72.065 61.1978 72.065C60.989 72.065 60.7802 71.8562 60.7802 71.6474V68.0979C60.7802 67.4715 60.3626 67.054 59.7363 67.054C59.1099 67.054 58.6923 67.4715 58.6923 68.0979V71.6474C58.6923 71.8562 58.4835 72.065 58.2747 72.065C58.0659 72.065 57.8571 71.8562 57.8571 71.6474V67.054C57.8571 66.4276 57.4396 66.01 56.8132 66.01C56.1868 66.01 55.7692 66.4276 55.7692 67.054V71.6474C55.7692 71.8562 55.5604 72.065 55.3516 72.065C55.1429 72.065 54.9341 71.8562 54.9341 71.6474V62.043C54.9341 61.4166 54.5165 60.999 53.8901 60.999C53.2637 60.999 52.8462 61.4166 52.8462 62.043V74.7792C52.8462 74.988 52.8461 75.1968 52.6374 75.1968C52.4286 75.1968 52.2198 75.1968 52.2198 75.1968L48.6703 73.5265C48.4615 73.3177 48.044 73.3177 47.8352 73.3177C47.4176 73.3177 47 73.7353 47 74.1529C47 74.3617 47 74.5705 47.2088 74.7792L53.6813 81.2518C54.9341 82.5045 56.6044 83.3397 58.4835 83.3397C61.6154 83.3397 64.1209 80.8342 64.1209 77.7023V69.1419C63.4945 68.5155 63.0769 68.0979 62.6593 68.0979Z'
        fill='white'
      />
      <path
        d='M63.0566 67.4399C62.639 67.4399 62.2215 67.6487 62.0127 67.8575C61.8039 67.0223 60.9687 66.6047 60.1335 66.6047C59.716 66.6047 59.2984 66.8135 59.0896 67.0223C58.8808 66.1872 58.0456 65.7696 57.2105 65.7696C56.7929 65.7696 56.5841 65.7696 56.1665 65.9784V62.6377C56.1665 61.5938 55.3313 60.5498 54.0786 60.5498C53.0346 60.5498 51.9907 61.385 51.9907 62.6377V74.5388L49.2764 73.0773C48.8588 72.8685 48.4412 72.6597 48.0237 72.6597C46.9797 72.6597 46.1445 73.4949 46.1445 74.5388C46.1445 74.9564 46.3533 75.5828 46.7709 76.0004L53.2434 82.4729C54.705 83.9344 56.5841 84.7696 58.8808 84.7696C62.4302 84.7696 65.3533 81.8465 65.3533 78.2971V69.7366C64.9357 68.2751 64.1006 67.4399 63.0566 67.4399ZM63.8918 78.0883C63.8918 81.2201 61.3863 83.7256 58.2544 83.7256C56.3753 83.7256 54.705 83.0992 53.4522 81.6377L46.9797 75.1652C46.7709 74.9564 46.7709 74.7476 46.7709 74.5388C46.7709 74.1212 47.1885 73.7036 47.6061 73.7036C47.8149 73.7036 48.2324 73.7037 48.4412 73.9124L51.9907 75.5828C52.1995 75.5828 52.4083 75.5828 52.4083 75.5828C52.6171 75.5828 52.6171 75.374 52.6171 75.1652V62.4289C52.6171 61.8026 53.0346 61.385 53.661 61.385C54.2874 61.385 54.705 61.8026 54.705 62.4289V72.0333C54.705 72.2421 54.9138 72.4509 55.1226 72.4509C55.3313 72.4509 55.5401 72.2421 55.5401 72.0333V67.4399C55.5401 66.8135 55.9577 66.396 56.5841 66.396C57.2105 66.396 57.628 66.8135 57.628 67.4399V72.0333C57.628 72.2421 57.8368 72.4509 58.0456 72.4509C58.2544 72.4509 58.4632 72.2421 58.4632 72.0333V68.4839C58.4632 67.8575 58.8808 67.4399 59.5072 67.4399C60.1335 67.4399 60.5511 67.8575 60.5511 68.4839V72.0333C60.5511 72.2421 60.7599 72.4509 60.9687 72.4509C61.1775 72.4509 61.3863 72.2421 61.3863 72.0333V69.5278C61.3863 68.9015 61.8039 68.4839 62.4302 68.4839C63.0566 68.4839 63.4742 68.9015 63.4742 69.5278L63.8918 78.0883Z'
        fill='#78829D'
      />
      <path
        d='M19.2101 25.6826H107.111'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M19.2101 37.793H29.6496'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M72.4518 37.793H107.111'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7781'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const DragNDropIconActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7763)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M65.7692 44.4727H37.5824C36.3297 44.4727 35.4945 43.6375 35.4945 42.3848V33.4068C35.4945 32.154 36.3297 31.3188 37.5824 31.3188H65.7692C67.022 31.3188 67.8571 32.154 67.8571 33.4068V42.3848C67.8571 43.4287 67.022 44.4727 65.7692 44.4727Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M53.869 76.4185H25.6822C24.4294 76.4185 23.5942 75.5833 23.5942 74.3306V65.3526C23.5942 64.0998 24.4294 63.2646 25.6822 63.2646H53.869C55.1217 63.2646 55.9569 64.0998 55.9569 65.3526V74.3306C55.9569 75.3745 55.1217 76.4185 53.869 76.4185Z'
        fill='white'
        stroke='#FFDC80'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M59.9244 67.023H31.7376C30.4848 67.023 29.6497 66.1878 29.6497 64.9351V55.9571C29.6497 54.7043 30.4848 53.8691 31.7376 53.8691H59.9244C61.1771 53.8691 62.0123 54.7043 62.0123 55.9571V64.9351C62.0123 65.979 60.9683 67.023 59.9244 67.023Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M35.4967 60.3433H55.9582'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M101.891 76.4185H73.7041C72.4514 76.4185 71.6162 75.5833 71.6162 74.3306V65.3526C71.6162 64.0998 72.4514 63.2646 73.7041 63.2646H101.891C103.144 63.2646 103.979 64.0998 103.979 65.3526V74.3306C103.979 75.3745 103.144 76.4185 101.891 76.4185Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M77.6716 69.7388H98.1332'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M62.6593 68.0979C62.033 68.0979 61.6154 68.5155 61.6154 69.1419V71.6474C61.6154 71.8562 61.4066 72.065 61.1978 72.065C60.989 72.065 60.7802 71.8562 60.7802 71.6474V68.0979C60.7802 67.4715 60.3626 67.054 59.7363 67.054C59.1099 67.054 58.6923 67.4715 58.6923 68.0979V71.6474C58.6923 71.8562 58.4835 72.065 58.2747 72.065C58.0659 72.065 57.8571 71.8562 57.8571 71.6474V67.054C57.8571 66.4276 57.4396 66.01 56.8132 66.01C56.1868 66.01 55.7692 66.4276 55.7692 67.054V71.6474C55.7692 71.8562 55.5604 72.065 55.3516 72.065C55.1429 72.065 54.9341 71.8562 54.9341 71.6474V62.043C54.9341 61.4166 54.5165 60.999 53.8901 60.999C53.2637 60.999 52.8462 61.4166 52.8462 62.043V74.7792C52.8462 74.988 52.8461 75.1968 52.6374 75.1968C52.4286 75.1968 52.2198 75.1968 52.2198 75.1968L48.6703 73.5265C48.4615 73.3177 48.044 73.3177 47.8352 73.3177C47.4176 73.3177 47 73.7353 47 74.1529C47 74.3617 47 74.5705 47.2088 74.7792L53.6813 81.2518C54.9341 82.5045 56.6044 83.3397 58.4835 83.3397C61.6154 83.3397 64.1209 80.8342 64.1209 77.7023V69.1419C63.4945 68.5155 63.0769 68.0979 62.6593 68.0979Z'
        fill='white'
      />
      <path
        d='M63.0566 67.4399C62.639 67.4399 62.2215 67.6487 62.0127 67.8575C61.8039 67.0223 60.9687 66.6047 60.1335 66.6047C59.716 66.6047 59.2984 66.8135 59.0896 67.0223C58.8808 66.1872 58.0456 65.7696 57.2105 65.7696C56.7929 65.7696 56.5841 65.7696 56.1665 65.9784V62.6377C56.1665 61.5938 55.3313 60.5498 54.0786 60.5498C53.0346 60.5498 51.9907 61.385 51.9907 62.6377V74.5388L49.2764 73.0773C48.8588 72.8685 48.4412 72.6597 48.0237 72.6597C46.9797 72.6597 46.1445 73.4949 46.1445 74.5388C46.1445 74.9564 46.3533 75.5828 46.7709 76.0004L53.2434 82.4729C54.705 83.9344 56.5841 84.7696 58.8808 84.7696C62.4302 84.7696 65.3533 81.8465 65.3533 78.2971V69.7366C64.9357 68.2751 64.1006 67.4399 63.0566 67.4399ZM63.8918 78.0883C63.8918 81.2201 61.3863 83.7256 58.2544 83.7256C56.3753 83.7256 54.705 83.0992 53.4522 81.6377L46.9797 75.1652C46.7709 74.9564 46.7709 74.7476 46.7709 74.5388C46.7709 74.1212 47.1885 73.7036 47.6061 73.7036C47.8149 73.7036 48.2324 73.7037 48.4412 73.9124L51.9907 75.5828C52.1995 75.5828 52.4083 75.5828 52.4083 75.5828C52.6171 75.5828 52.6171 75.374 52.6171 75.1652V62.4289C52.6171 61.8026 53.0346 61.385 53.661 61.385C54.2874 61.385 54.705 61.8026 54.705 62.4289V72.0333C54.705 72.2421 54.9138 72.4509 55.1226 72.4509C55.3313 72.4509 55.5401 72.2421 55.5401 72.0333V67.4399C55.5401 66.8135 55.9577 66.396 56.5841 66.396C57.2105 66.396 57.628 66.8135 57.628 67.4399V72.0333C57.628 72.2421 57.8368 72.4509 58.0456 72.4509C58.2544 72.4509 58.4632 72.2421 58.4632 72.0333V68.4839C58.4632 67.8575 58.8808 67.4399 59.5072 67.4399C60.1335 67.4399 60.5511 67.8575 60.5511 68.4839V72.0333C60.5511 72.2421 60.7599 72.4509 60.9687 72.4509C61.1775 72.4509 61.3863 72.2421 61.3863 72.0333V69.5278C61.3863 68.9015 61.8039 68.4839 62.4302 68.4839C63.0566 68.4839 63.4742 68.9015 63.4742 69.5278L63.8918 78.0883Z'
        fill='#78829D'
      />
      <path
        d='M19.2101 25.6826H107.111'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M19.2101 37.793H29.6496'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M72.4518 37.793H107.111'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7763'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const FillTextIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7751)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M65.7692 52.8243H37.5824C36.3297 52.8243 35.4945 51.9891 35.4945 50.7363V41.7583C35.4945 40.5056 36.3297 39.6704 37.5824 39.6704H65.7692C67.022 39.6704 67.8571 40.5056 67.8571 41.7583V50.7363C67.8571 51.7803 67.022 52.8243 65.7692 52.8243Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M19.2101 29.8584H107.111'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M19.2101 46.1445H29.6496'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M72.4518 46.1445H107.111'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M60.5495 62.4312H88.7363C89.989 62.4312 90.8242 63.2663 90.8242 64.5191V73.4971C90.8242 74.7498 89.989 75.585 88.7363 75.585H60.5495C59.2967 75.585 58.4615 74.7498 58.4615 73.4971V64.5191C58.4615 63.4751 59.2967 62.4312 60.5495 62.4312Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M107.111 69.1118H96.6711'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M53.8694 69.1118H19.2101'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7751'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const FillTextIconActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7740)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M65.7692 52.8243H37.5824C36.3297 52.8243 35.4945 51.9891 35.4945 50.7363V41.7583C35.4945 40.5056 36.3297 39.6704 37.5824 39.6704H65.7692C67.022 39.6704 67.8571 40.5056 67.8571 41.7583V50.7363C67.8571 51.7803 67.022 52.8243 65.7692 52.8243Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M19.2101 29.8584H107.111'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M19.2101 46.1445H29.6496'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M72.4518 46.1445H107.111'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M60.5495 62.4312H88.7363C89.989 62.4312 90.8242 63.2663 90.8242 64.5191V73.4971C90.8242 74.7498 89.989 75.585 88.7363 75.585H60.5495C59.2967 75.585 58.4615 74.7498 58.4615 73.4971V64.5191C58.4615 63.4751 59.2967 62.4312 60.5495 62.4312Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M107.111 69.1118H96.6711'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M53.8694 69.1118H19.2101'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7740'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const EssayIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7726)'>
      <path
        d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#DBDFE9'
        stroke='#99A1B7'
        strokeMiterlimit='10'
      />
      <path
        d='M105.023 74.959H21.298C20.0452 74.959 19.2101 74.1239 19.2101 72.8711V26.937C19.2101 25.6843 20.0452 24.8491 21.298 24.8491H105.232C106.485 24.8491 107.32 25.6843 107.32 26.937V72.8711C107.111 74.1239 106.276 74.959 105.023 74.959Z'
        fill='white'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6056 34.2446H97.0891'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6056 43.2192H81.6385'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6056 51.9917H72.6605'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6056 60.9663H69.1111'
        stroke='#99A1B7'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M89.266 41.9895L78.3409 52.9146L82.6224 57.196L93.5475 46.2709L89.266 41.9895Z'
        fill='#DBDFE9'
      />
      <path
        d='M93.5398 40.5064C92.7046 39.88 91.6606 39.88 90.8255 40.5064L90.1991 41.1328L94.5837 45.5174L95.2101 44.891C95.8365 44.2646 96.0452 43.0119 95.2101 42.1767L93.5398 40.5064Z'
        fill='#DBDFE9'
      />
      <path d='M75.792 59.717L81.6381 57.8378L77.6711 54.0796L75.792 59.717Z' fill='#DBDFE9' />
      <path
        d='M94.3753 39.6715C93.1225 38.4187 91.2434 38.4187 89.9906 39.6715L88.9467 40.7155L77.2544 52.4078L74.3313 60.5506C74.3313 60.7594 74.3313 60.9682 74.5401 61.177C74.7489 61.3858 74.7489 61.3858 74.9577 61.3858H75.1665L83.1005 58.6715L96.0456 45.7264C97.2983 44.4737 97.2983 42.5946 96.0456 41.3418L94.3753 39.6715ZM75.7928 59.7155L77.672 54.0781L81.4302 57.8363L75.7928 59.7155ZM82.6829 57.21L78.2983 52.8253L89.1555 41.9682L93.5401 46.3528L82.6829 57.21ZM95.2104 44.8913L94.584 45.5176L90.1994 41.133L90.8258 40.5067C91.4522 39.8803 92.7049 39.8803 93.5401 40.5067L95.2104 42.177C95.8368 43.0122 95.8368 44.0561 95.2104 44.8913Z'
        fill='#99A1B7'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7726'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const EssayIconActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <g clip-path='url(#clip0_10990_7713)'>
      <path
        d='M119.638 94.5835H6.89069C3.34124 94.5835 0.626953 91.8692 0.626953 88.3197V6.89118C0.626953 3.34173 3.34124 0.627441 6.89069 0.627441H119.638C123.187 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
        fill='white'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M125.902 12.111H0.626953V6.89118C0.626953 3.34173 3.34124 0.627441 6.89069 0.627441H119.638C123.187 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
        fill='#FFF4D8'
        stroke='#FFB800'
        strokeMiterlimit='10'
      />
      <path
        d='M105.023 74.959H21.2978C20.045 74.959 19.2098 74.1239 19.2098 72.8711V26.937C19.2098 25.6843 20.045 24.8491 21.2978 24.8491H105.232C106.485 24.8491 107.32 25.6843 107.32 26.937V72.8711C107.111 74.1239 106.276 74.959 105.023 74.959Z'
        fill='white'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6053 34.2446H97.0889'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6053 43.2192H81.6383'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6053 51.9917H72.6603'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M28.6053 60.9663H69.1108'
        stroke='#FFB800'
        stroke-width='0.5'
        strokeMiterlimit='10'
      />
      <path
        d='M89.2658 41.9895L78.3407 52.9146L82.6222 57.196L93.5472 46.2709L89.2658 41.9895Z'
        fill='#FFF4D8'
      />
      <path
        d='M93.5395 40.5064C92.7043 39.88 91.6604 39.88 90.8252 40.5064L90.1989 41.1328L94.5835 45.5174L95.2098 44.891C95.8362 44.2646 96.045 43.0119 95.2098 42.1767L93.5395 40.5064Z'
        fill='#FFF4D8'
      />
      <path d='M75.7917 59.717L81.6379 57.8378L77.6709 54.0796L75.7917 59.717Z' fill='#FFF4D8' />
      <path
        d='M94.375 39.6715C93.1223 38.4187 91.2431 38.4187 89.9904 39.6715L88.9464 40.7155L77.2541 52.4078L74.3311 60.5506C74.3311 60.7594 74.3311 60.9682 74.5399 61.177C74.7486 61.3858 74.7486 61.3858 74.9574 61.3858H75.1662L83.1003 58.6715L96.0453 45.7264C97.2981 44.4737 97.2981 42.5946 96.0453 41.3418L94.375 39.6715ZM75.7926 59.7155L77.6717 54.0781L81.43 57.8363L75.7926 59.7155ZM82.6827 57.21L78.2981 52.8253L89.1552 41.9682L93.5398 46.3528L82.6827 57.21ZM95.2102 44.8913L94.5838 45.5176L90.1992 41.133L90.8256 40.5067C91.4519 39.8803 92.7047 39.8803 93.5398 40.5067L95.2102 42.177C95.8365 43.0122 95.8365 44.0561 95.2102 44.8913Z'
        fill='#FFB800'
      />
    </g>
    <defs>
      <clipPath id='clip0_10990_7713'>
        <rect width='126.319' height='103' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const ChooseWordIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <path
      d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
      fill='white'
      stroke='#99A1B7'
      strokeMiterlimit='10'
    />
    <path
      d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
      fill='#DBDFE9'
      stroke='#99A1B7'
      strokeMiterlimit='10'
    />
    <path
      d='M104.706 37.0005H21.0851C19.8339 37.0005 18.9998 36.7005 18.9998 36.2505V19.7505C18.9998 19.3005 19.8339 19.0005 21.0851 19.0005H104.914C106.166 19.0005 107 19.3005 107 19.7505V36.2505C106.791 36.7005 105.957 37.0005 104.706 37.0005Z'
      fill='white'
      stroke='#99A1B7'
      stroke-width='0.5'
      strokeMiterlimit='10'
    />
    <path
      d='M104.706 86.0005H21.0851C19.8339 86.0005 18.9998 85.3172 18.9998 84.2922V46.7088C18.9998 45.6838 19.8339 45.0005 21.0851 45.0005H104.914C106.166 45.0005 107 45.6838 107 46.7088V84.2922C106.791 85.3172 105.957 86.0005 104.706 86.0005Z'
      fill='#DBDFE9'
      stroke='#99A1B7'
      stroke-width='0.5'
      strokeMiterlimit='10'
    />
    <path
      d='M100.914 64.0005H24.8955C23.758 64.0005 22.9998 63.7338 22.9998 63.3338V48.6672C22.9998 48.2672 23.758 48.0005 24.8955 48.0005H101.104C102.241 48.0005 103 48.2672 103 48.6672V63.3338C102.81 63.7338 102.052 64.0005 100.914 64.0005Z'
      fill='#DBDFE9'
    />
    <path d='M36.9998 53.0005H85.0217' stroke='white' stroke-width='0.5' strokeMiterlimit='10' />
    <path
      d='M22.9998 28.0005H75.9998'
      stroke='#99A1B7'
      stroke-width='0.5'
      strokeMiterlimit='10'
      strokeDasharray='5 5'
    />
    <path d='M36.9998 72.0005H85.0217' stroke='#99A1B7' stroke-width='0.5' strokeMiterlimit='10' />
    <path d='M36.9998 59.0005H99.6371' stroke='white' stroke-width='0.5' strokeMiterlimit='10' />
    <path d='M36.9998 78.0005H99.6371' stroke='#99A1B7' stroke-width='0.5' strokeMiterlimit='10' />
  </svg>
)
export const ChooseWordIconActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='134' height='103' viewBox='0 0 127 95' fill='none'>
    <path
      d='M119.638 94.5835H6.89093C3.34148 94.5835 0.627197 91.8692 0.627197 88.3197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V88.3197C125.902 91.6604 122.979 94.5835 119.638 94.5835Z'
      fill='white'
      stroke='#FFB800'
      strokeMiterlimit='10'
    />
    <path
      d='M125.902 12.111H0.627197V6.89118C0.627197 3.34173 3.34148 0.627441 6.89093 0.627441H119.638C123.188 0.627441 125.902 3.34173 125.902 6.89118V12.111Z'
      fill='#FFF4D8'
      stroke='#FFB800'
      strokeMiterlimit='10'
    />
    <path
      d='M104.706 37.0005H21.0851C19.8339 37.0005 18.9998 36.7005 18.9998 36.2505V19.7505C18.9998 19.3005 19.8339 19.0005 21.0851 19.0005H104.914C106.166 19.0005 107 19.3005 107 19.7505V36.2505C106.791 36.7005 105.957 37.0005 104.706 37.0005Z'
      fill='white'
      stroke='#FFB800'
      stroke-width='0.5'
      strokeMiterlimit='10'
    />
    <path
      d='M104.706 86.0005H21.0851C19.8339 86.0005 18.9998 85.3172 18.9998 84.2922V46.7088C18.9998 45.6838 19.8339 45.0005 21.0851 45.0005H104.914C106.166 45.0005 107 45.6838 107 46.7088V84.2922C106.791 85.3172 105.957 86.0005 104.706 86.0005Z'
      fill='#FFF4D8'
      stroke='#FFB800'
      stroke-width='0.5'
      strokeMiterlimit='10'
    />
    <path
      d='M100.914 64.0005H24.8955C23.758 64.0005 22.9998 63.7338 22.9998 63.3338V48.6672C22.9998 48.2672 23.758 48.0005 24.8955 48.0005H101.104C102.241 48.0005 103 48.2672 103 48.6672V63.3338C102.81 63.7338 102.052 64.0005 100.914 64.0005Z'
      fill='#FFB800'
      fillOpacity='0.38'
    />
    <path d='M36.9998 53.0005H85.0217' stroke='white' stroke-width='0.5' strokeMiterlimit='10' />
    <path
      d='M22.9998 28.0005H75.9998'
      stroke='#FFB800'
      stroke-width='0.5'
      strokeMiterlimit='10'
      strokeDasharray='5 5'
    />
    <path d='M36.9998 72.0005H85.0217' stroke='#FFB800' stroke-width='0.5' strokeMiterlimit='10' />
    <path d='M36.9998 59.0005H99.6371' stroke='white' stroke-width='0.5' strokeMiterlimit='10' />
    <path d='M36.9998 78.0005H99.6371' stroke='#FFB800' stroke-width='0.5' strokeMiterlimit='10' />
  </svg>
)
