import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import { LANG_COURSES } from 'src/constants/lang'
import {format} from 'date-fns'

const headers = [
  {
    label: LANG_COURSES.code,
    className: 'min-w-100px',
  },
  {
    label: "Kỳ Thi",
    className: 'min-w-500px',
  },
  {
    label: 'Note',
    className: 'min-w-350px pe-5',
  },
]
interface IProps {
  examList: any
  loading: boolean
  checkedList: any
  toggleCheck: any
  handlePaginationChange:any
}
// define headers

const TableExamACCA = ({
  examList,
  loading,
  checkedList,
  toggleCheck,
  handlePaginationChange
}: IProps) => {
  let getExams = examList?.exams ?? []
  if (typeof getExams === 'string') {
    getExams = [] 
  }
  return (
    <>
      <SappTable
        headers={headers}
        loading={loading}
        data={examList?.exams}
        isCheckedAll={false}
        hasCheckAll={false}
        onChange={() => {
          
        }}
      >
        {loading ? (
          <>
            {/* {headers.map((header, i) => ( */}
              
              <LoadingTable headers={[...headers,1]} />
            {/* ))} */}
          </>
        ) : (
          <>
            {getExams.map((e: any, index: number) => {
              const isChecked = checkedList[0]?.id?.includes(e.id)

              return (
                <tr key={e.id}>
                  <td>
                    <SAPPRadio
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(e)
                      }}
                    />
                  </td>
                  <td className='text-start'>{e?.code ?? '-'}</td>
                  <td className='text-start'>
                    <span className='sapp-table-title-des'>
                      {(() => {
                        if (e?.season === null) {
                          return '-'
                        }
                        const date = new Date(e?.season)
                        return `${format(date, 'dd/MM/yyyy')}`
                      })() ?? '-'}
                    </span>
                  </td>
                  <td className='text-start sapp-text-truncate-2'>{e?.description ?? '-'}</td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={examList?.metadata?.page_index || 1}
        pageSize={examList?.metadata?.page_size || 10}
        totalItems={examList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </>
  )
}
export default TableExamACCA
