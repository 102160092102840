import {fetcher} from 'src/services/request'

export class AuthAPI {
  static loginByUsername({username, password}: {username: string; password: string}) {
    return fetcher('/auth/login', {
      method: 'POST',
      data: {
        username,
        password,
      },
    })
  }

  static logout() {
    return fetcher('/auth/logout', {
      method: 'POST',
    })
  }

}
