import React, {Dispatch, SetStateAction} from 'react'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import {Modal} from 'react-bootstrap'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
interface IProps {
  setOpenAddress: Dispatch<SetStateAction<boolean>>
  openAddress: boolean
}

const ModalAddAddress = ({openAddress, setOpenAddress}: IProps) => {
  return (
    <Modal dialogClassName='modal-dialog modal-dialog-centered mw-650px' show={openAddress}>
      <div className='modal-content'>
        <form className='form' action='#' id='kt_modal_new_address_form'>
          <div className='modal-header' id='kt_modal_new_address_header'>
            <h2>Add New Address</h2>

            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setOpenAddress(false)}
            >
              <i className='ki-outline ki-cross fs-1'></i>
            </div>
          </div>

          <div className='modal-body py-10 px-lg-17'>
            <div
              className='scroll-y me-n7 pe-7'
              id='kt_modal_new_address_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_new_address_header'
              data-kt-scroll-wrappers='#kt_modal_new_address_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                <i className='ki-outline ki-information fs-2tx text-warning me-4'></i>

                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>Warning</h4>
                    <div className='fs-6 text-gray-700'>
                      Updating address may affter to your
                      <a href='/'> Tax Location</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-md-6 fv-row'>
                  <label className='required fs-5 fw-semibold mb-2'>First name</label>

                  <input type='text' className='form-control' placeholder='' name='first-name' />
                </div>

                <div className='col-md-6 fv-row'>
                  <label className='required fs-5 fw-semibold mb-2'>Last name</label>

                  <input type='text' className='form-control' placeholder='' name='last-name' />
                </div>
              </div>

              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Country</span>
                  <span
                    className='ms-1'
                    data-bs-toggle='tooltip'
                    title='Your payment statements may very based on selected country'
                  >
                    <i className='ki-outline ki-information-5 text-gray-500 fs-6'></i>
                  </span>
                </label>

                <SAPPSelect
                  size='large'
                  options={[
                    {
                      label: 'Role',
                      value: 'Role',
                    },
                    {
                      label: 'One',
                      value: 'One',
                    },
                  ]}
                />
              </div>

              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='required fs-5 fw-semibold mb-2'>Address Line 1</label>

                <input className='form-control' placeholder='' name='address1' />
              </div>

              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='required fs-5 fw-semibold mb-2'>Address Line 2</label>

                <input className='form-control' placeholder='' name='address2' />
              </div>

              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='fs-5 fw-semibold mb-2'>Town</label>

                <input className='form-control' placeholder='' name='city' />
              </div>

              <div className='row g-9 mb-5'>
                <div className='col-md-6 fv-row'>
                  <label className='fs-5 fw-semibold mb-2'>State / Province</label>

                  <input className='form-control' placeholder='' name='state' />
                </div>

                <div className='col-md-6 fv-row'>
                  <label className='fs-5 fw-semibold mb-2'>Post Code</label>

                  <input className='form-control' placeholder='' name='postcode' />
                </div>
              </div>

              <div className='fv-row mb-5'>
                <div className='d-flex flex-stack'>
                  <div className='me-5'>
                    <label className='fs-5 fw-semibold'>Use as a billing adderess?</label>

                    <div className='fs-7 fw-semibold text-muted'>
                      If you need more info, please check budget planning
                    </div>
                  </div>

                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input className='form-check-input' name='billing' type='checkbox' value='1' />

                    <span className='form-check-label fw-semibold text-muted'>Yes</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <SAPPDialogButtonsCancelSubmit
            className='mb-10'
            cancelButtonCaption='Discard'
            okButtonCaption='Submit'
            okOnClick={() => {}}
            cancelClick={() => setOpenAddress(false)}
          />
        </form>
      </div>
    </Modal>
  )
}

export default ModalAddAddress
