// @ts-nocheck
import { Column } from 'react-table'
import { NewsInfoCell } from './NewsInfoCell'
import { NewsLastLoginCell } from './NewsLastLoginCell'
import { NewsTwoStepsCell } from './NewsTwoStepsCell'
import { NewsActionsCell } from './NewsActionsCell'
import { NewsSelectionCell } from './NewsSelectionCell'
import { NewsCustomHeader } from './NewsCustomHeader'
import { NewsSelectionHeader } from './NewsSelectionHeader'
import { User } from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <NewsSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <NewsSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <NewsCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <NewsInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <NewsCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    accessor: 'role',
  },
  {
    Header: (props) => (
      <NewsCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({ ...props }) => <NewsLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <NewsCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({ ...props }) => <NewsTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
  {
    Header: (props) => (
      <NewsCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
    ),
    accessor: 'joined_day',
  },
  {
    Header: (props) => (
      <NewsCustomHeader tableProps={props} title='Actions' className='text-start min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <NewsActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
