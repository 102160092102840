import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {EntranceApi} from 'src/apis/entrance-test'
import QuizResult from 'entrance-test-result-package'

const TestResult = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [dataChart, setDataChart] = useState<[]>([])
  const [dataTable, setDataTable] = useState<any>()
  const handleClose = () => {
    navigate(-1)
  }
  const fetchDataChart = async (id: any) => {
    try {
      if (id) {
        const response = await EntranceApi.getDataChart(id)
        setDataChart(response.data.chart_data)
        setDataTable(response.data)
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchDataChart(id)
  }, [id])
  return (
    <div>
      <div className='position-absolute end-0 top-0 p-3 '>
        <i className='ki-outline ki-cross fs-1' onClick={handleClose} style={{cursor: 'pointer'}} />
      </div>
      <QuizResult
        dataChart={dataChart}
        dataTable={dataTable}
        onClick={() => navigate(`/entrance-test/list-entrance-test/quiz-result/table-quiz/${id}`)}
      />
    </div>
  )
}

export default TestResult
