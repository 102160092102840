import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {ClassesApi} from 'src/apis/classes'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import { VALIDATE_NUMBER, VALIDATION_MIN_EQUAL } from 'src/constants'
import {z} from 'zod'
import {useParams} from 'react-router-dom'

interface IProps {
  open: boolean
  setOpen: any
  data: any
  studentId: any
  getClassStudents: any
}

const UpdateUserDuration = ({data, open, setOpen, studentId, getClassStudents}: IProps) => {
  const {id} = useParams()
  const handleClose = () => {
    reset()
    setOpen({status: false})
  }
  useEffect(() => {
    if (data) {
      setValue('flexible_days', data?.flexible_days?.toString() || "1")
      if(data.started_at && data.finished_at){
        setValue('duration', {fromDate: dayjs(data.started_at)?.toDate(), toDate: dayjs(data.finished_at)?.toDate()})
      }
      else {
        setValue('duration', {fromDate:dayjs(new Date()), toDate: dayjs(new Date())})
      }
      // setValue('duration.toDate', data.finished_at)
    }
  }, [data])
  const onSubmit = async (dataValue: any) => {    
    try {
      const fromDate = new Date(dataValue.duration.fromDate)
      const toDate = new Date(dataValue.duration.toDate)
      const payload = {
        class_id: id,
        user_id: studentId,
        started_at: fromDate.toISOString(),
        finished_at: toDate.toISOString()
      }
      await ClassesApi.updateUserDuration(payload)
      toast.success('Update duration successful')
      getClassStudents()
      handleClose()
    } catch (err) {
      console.log(err)
    }
  }
  const validationSchema = z.object({
    flexible_days: z.string()
    .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
    .refine(
      (val: any) => {
        if(Number(val) < 1){
          return false
        }
        return true
      },
      {message: VALIDATION_MIN_EQUAL(1)}
    ),
    duration: z.object({
      fromDate:z.date().optional(),
      toDate: z.date().optional()
    }).optional()
  })
  const {control, setValue, reset, handleSubmit} = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  return (
    <SappModal
      title='Update Duration'
      open={open}
      handleClose={handleClose}
      confirmOnclose
      handleSubmit={handleSubmit((e) => {
        console.log(e)
      })}
      centered={true}
      showFooter={false}
      customFooter={
        <div className='d-flex justify-content-end align-items-center gap-5 pe-3'>
          <SAPPDialogButtonsCancelSubmit
            cancelClick={() => {
              handleClose()
            }}
            cancelButtonCaption={'Cancel'}
            okButtonCaption={'Save'}
            okOnClick={handleSubmit(onSubmit)}
            className='d-flex m-0'
            loading={false}
            disabled={false}
          />
        </div>
      }
      classNameFooter='border-0'
    >
      <RangeDateTimePicker control={control} name='duration' allowClear={false}/>
    </SappModal>
  )
}
export default UpdateUserDuration
