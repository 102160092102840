import React, { Dispatch, SetStateAction, useEffect } from 'react'
// import StockReport from 'src/components/dashboard/StockReport'
// import Application from 'src/components/my-profile/overview-profile/Application'
// import ProductDelivery from 'src/components/my-profile/overview-profile/ProductDelivery'
// import TopSelling from 'src/components/my-profile/overview-profile/TopSelling'
import {IStudentDetail} from 'src/type/students'
import TeacherProfileDetail from './TeacherProfileDetail'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const OverviewTeacherProfile = ({teacherDetail, loading,setTeacherDetail}: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <TeacherProfileDetail teacherDetail={teacherDetail} loading={loading} setTeacherDetail={setTeacherDetail}/>
      <div className='row gy-5 g-xl-10'>
        {/* <TopSelling /> */}
        {/* <Application /> */}
      </div>
      {/* <div className='row gy-5 g-xl-10'> */}
        {/* <ProductDelivery /> */}
        {/* <StockReport /> */}
      {/* </div> */}
    </>
  )
}

export default OverviewTeacherProfile
