import React from 'react'
import LoginSessions from 'src/components/my-profile/security-profile/LoginSessions'
import Logs from './Logs'

const LogsProfile = () => {
  return (
    <>
      <LoginSessions />
      <Logs />
    </>
  )
}

export default LogsProfile
