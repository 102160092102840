import {getAPIUserExport} from 'src/apis/user'
import {IStudentDetail} from 'src/type/students'
import * as XLSX from 'xlsx'
import {fileExtension, fileType} from 'src/utils'
import FileSaver from 'file-saver'
import {getAPIStaffsExport} from 'src/apis/staffs'

export class UserExportHelper {
  private static wscols: Array<{wch: number}> = [
    {wch: 5},
    {wch: 15},
    {wch: 15},
    {wch: 30},
    {wch: 30},
    {wch: 15},
    {wch: 15},
    {wch: 15},
    {wch: 20},
  ]

  private static header = [
    'stt',
    'key',
    'examination_code',
    'full_name',
    'username',
    'email',
    'phone',
    'class',
    'status',
  ]

  private static heading = [
    {
      stt: 'STT',
      key: 'Code',
      examination_code: 'Examination code',
      full_name: 'Full Name',
      username: 'User Name',
      email: 'Email',
      phone: 'Phone',
      class: 'Class',
      status: 'Status',
    },
  ]

  private static headerTeacher = ['stt', 'key', 'full_name', 'username', 'email', 'phone', 'status']

  private static headingTeacher = [
    {
      stt: 'STT',
      key: 'Code',
      full_name: 'Full Name',
      username: 'User Name',
      email: 'Email',
      phone: 'Phone',
      status: 'Status',
    },
  ]

  private static headerStaff = [
    'stt',
    'key',
    'full_name',
    'username',
    'email',
    'phone',
    'role',
    'status',
  ]

  private static headingStaff = [
    {
      stt: 'STT',
      key: 'Code',
      full_name: 'Full Name',
      username: 'User Name',
      email: 'Email',
      phone: 'Phone',
      role: 'Role',
      status: 'Status',
    },
  ]

  private static defaultPageSize = 300

  private static getWsName() {
    return 'students'
  }

  private static getWsTeacher() {
    return 'teachers'
  }

  private static getWsStaff() {
    return 'staffs'
  }

  private static transformData(data: IStudentDetail, index: number) {
    return {
      full_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      stt: index + 1,
      username: data?.username,
      class: data?.class,
      status: data?.status,
      key: data?.key,
      examination_code: data?.code,
    }
  }

  private static transformDataTeacher(data: IStudentDetail, index: number) {
    return {
      full_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      stt: index + 1,
      username: data?.username,
      status: data?.status,
      key: data?.key,
    }
  }

  private static transformDataStaff(data: IStudentDetail, index: number) {
    return {
      full_name: data?.detail?.full_name,
      email: data?.user_contacts?.[0]?.email,
      phone: data?.user_contacts?.[0]?.phone,
      stt: index + 1,
      username: data?.username,
      status: data?.status,
      role: data?.roles?.map((role) => role?.name).join(', '),
      key: data?.key,
    }
  }

  static async exportToXlsx(
    gender: string | null,
    status: string | null,
    text: string | null,
    sortType: string | null,
    fromDate: string | null,
    toDate: string | null
  ) {
    const ws = XLSX.utils.json_to_sheet(this.heading, {
      header: this.header,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheet(ws, 1, gender, status, text, sortType, fromDate, toDate)
    const wb = {Sheets: {data: ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, this.getWsName() + fileExtension)
  }

  static async exportToXlsxTeacher(
    gender: string | null,
    text: string | null,
    status: string | null,
    fromDate: Date | null,
    toDate: Date | null
  ) {
    const ws = XLSX.utils.json_to_sheet(this.headingTeacher, {
      header: this.headerTeacher,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheetTeacher(ws, 1, gender, text, status, fromDate, toDate)
    const wb = {Sheets: {data: ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, this.getWsTeacher() + fileExtension)
  }

  static async exportToXlsxStaff(
    status: string | null,
    sortType: string | null,
    text: string | null,
    sortRole: string | null,
    fromDate: Date | null,
    toDate: Date | null,
    gender: string | null
  ) {
    const ws = XLSX.utils.json_to_sheet(this.headingStaff, {
      header: this.headerStaff,
      skipHeader: true,
    })
    ws['!cols'] = this.wscols
    await this.addDataToWorksheetStaffs(
      ws,
      1,
      status,
      sortType,
      text,
      sortRole,
      fromDate,
      toDate,
      gender
    )
    const wb = {Sheets: {data: ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, this.getWsStaff() + fileExtension)
  }

  private static async addDataToWorksheet(
    ws: XLSX.WorkSheet,
    page_index: number,
    gender: string | null,
    status: string | null,
    text: string | null,
    sortType: string | null,
    fromDate: string | null,
    toDate: string | null
  ) {
    const params = {
      gender: gender,
      text: text,
      status: status,
      sortType: sortType,
      fromDate: fromDate,
      toDate: toDate,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== null && value !== '')
    )

    const res = await getAPIUserExport({
      page_index: page_index,
      page_size: this.defaultPageSize,
      type: 'STUDENT',
      params: cleanedParams,
    })
    const wsData = res?.data?.users?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformData(data, startIndex + index)
    })

    if (wsData.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.header,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheet(
          ws,
          page_index + 1,
          gender,
          status,
          text,
          sortType,
          fromDate,
          toDate
        )
      }
    } else return
  }

  private static async addDataToWorksheetTeacher(
    ws: XLSX.WorkSheet,
    page_index: number,
    gender: string | null,
    text: string | null,
    status: string | null,
    fromDate: Date | null,
    toDate: Date | null
  ) {
    const params = {
      gender: gender,
      text: text,
      status: status,
      fromDate: fromDate,
      toDate: toDate,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== null && value !== '')
    )

    const res = await getAPIUserExport({
      page_index: page_index,
      page_size: this.defaultPageSize,
      type: 'TEACHER',
      params: cleanedParams,
    })
    const wsData = res?.data?.users?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformDataTeacher(data, startIndex + index)
    })

    if (wsData.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.headerTeacher,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheetTeacher(
          ws,
          page_index + 1,
          gender,
          text,
          status,
          fromDate,
          toDate
        )
      }
    } else return
  }

  private static async addDataToWorksheetStaffs(
    ws: XLSX.WorkSheet,
    page_index: number,
    status: string | null,
    sortType: string | null,
    text: string | null,
    sortRole: string | null,
    fromDate: Date | null,
    toDate: Date | null,
    gender: string | null
  ) {
    const params = {
      status: status,
      sortType: sortType,
      text: text,
      sortRole: sortRole,
      fromDate: fromDate,
      toDate: toDate,
      gender: gender,
    }

    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '' && value !== null)
    )
    const res = await getAPIStaffsExport(page_index, this.defaultPageSize, cleanedParams)
    const wsData = res?.data?.staffs?.map((data: any, index: number) => {
      const startIndex = (page_index - 1) * this.defaultPageSize
      return this.transformDataStaff(data, startIndex + index)
    })

    if (wsData.length) {
      XLSX.utils.sheet_add_json(ws, wsData, {
        header: this.headerStaff,
        skipHeader: true,
        origin: -1,
      })
      if (res?.data?.metadata?.total_records > this.defaultPageSize) {
        await this.addDataToWorksheetStaffs(
          ws,
          page_index + 1,
          status,
          sortType,
          text,
          sortRole,
          fromDate,
          toDate,
          gender
        )
      }
    } else return
  }
}
