import React from 'react'
import {
  Control,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {
  ATTEMPT,
  GRADED_ASSIGNMENT,
  GRADING_PREFERENCE,
  PASS_POINT,
  TEST_MODE,
} from 'src/components/courses/shared/consts'
type IProps = {
  id: string
  oldData?: any
  control: Control<any, any>
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  watch: UseFormWatch<any>
  trigger: UseFormTrigger<any>
  editAble: boolean
  isSubmitted: any
}

const SettingTab = ({
  control,
  watch,
  trigger,
  editAble,
  isSubmitted,
}: IProps) => {
  const watchPassPoint = watch('passPoint')
  const watchAttempt = watch('attempt')
  const watchTestMode = watch('testMode')

  // useEffect(() => {
  //   if (oldData) {
  //     Object.entries(oldData || {}).forEach(([key, value]: [any, any]) => {
  //       setValue(key, value)
  //     })
  //   }
  // }, [oldData])
  return (
    <div className='w-100 p-8 h-100'>
      <div className='sapp-questionTab-title mb-8 min-h-45px d-flex align-items-center'>{`Settings`}</div>
      <div className='w-100 mb-6'>
        <div className='card-body'>
          <React.Fragment>
            <label className='d-flex align-items-center form-label required '>
              <span>Quiz Name</span>
            </label>
            {/* start:: name*/}
            <div className='mb-10'>
              <HookFormTextField
                control={control}
                name='name'
                placeholder='Quiz Name'
                className='w-100 sapp-h-45px fs-6'
                disabled={!editAble}
                // skeleton={loadingFetchData}
              ></HookFormTextField>
            </div>
          </React.Fragment>
          {/* end:: name*/}
          <div className='sapp-list-question-added-container'>
            <div className='mb-10 '>
              {/* start:: Test Mode*/}
              <div className='mb-10'>
                <div>
                  <label className='d-flex align-items-center fw-bold form-label required'>
                    <span>Test Mode</span>
                  </label>
                  <HookFormRadioGroup
                    labelClass='fw-semibold fs-6'
                    direction='horizontal'
                    separator={false}
                    name='testMode'
                    control={control}
                    justify='start'
                    // gap={20}
                    options={Object.values(TEST_MODE ?? {}).map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    onChange={() => {
                      // setValue('time.hours', '')
                      // setValue('time.mins', '')
                      // clearErrors('time.hours')
                      // clearErrors('time.mins')
                    }}
                    disabled={true}
                    itemWidth={'25%'}
                  />
                </div>
              </div>
              {/* end:: Test Mode*/}
              {/* start:: Graded Assignment*/}
              <div>
                <div>
                  <label className='d-flex align-items-center fw-bold form-label required'>
                    <span>Graded Assignment</span>
                  </label>
                  <HookFormRadioGroup
                    labelClass='fw-semibold fs-6'
                    direction='horizontal'
                    separator={false}
                    name='gradedAssignment'
                    control={control}
                    justify='start'
                    // gap={20}
                    options={Object.values(GRADED_ASSIGNMENT ?? {}).map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    // onChange={(e) => {
                    //   if (e.target.value === GRADED_ASSIGNMENT.no.value) {
                    //     setValue('gradingPreference', GRADING_PREFERENCE.each.value)
                    //     setValue('passPoint', {
                    //       type: PASS_POINT.auto.value,
                    //       value: '',
                    //     })
                    //   }
                    // }}
                    disabled={!editAble}
                    itemWidth={'25%'}
                  />
                </div>
              </div>
              {/* end:: Graded Assignment*/}
            </div>
            {/* start:: Time of Test*/}
            {watchTestMode === TEST_MODE.timed.value && (
              <div className='mb-10'>
                <label className='d-flex align-items-center fw-bold form-label required'>
                  <span>Duration</span>
                </label>
                <div className='justify-content-between d-flex gap-4'>
                  <div className='sapp-w-49'>
                    <HookFormTextField
                      control={control}
                      name='time.hours'
                      placeholder=''
                      type='number'
                      className='w-100 sapp-h-45px fs-6'
                      // onChange={() => trigger('time.mins')}
                      disabled={!editAble}
                      onChange={() => {
                        if (isSubmitted) {
                          trigger('time.mins')
                        }
                      }}
                      postFix={<div className='sapp-post-fix-text-field '>hours</div>} // skeleton={loadingFetchData}
                    ></HookFormTextField>
                  </div>
                  <div className='sapp-w-49'>
                    <HookFormTextField
                      control={control}
                      type='number'
                      name='time.mins'
                      placeholder=''
                      className='w-100 sapp-h-45px fs-6'
                      // onChange={() => trigger('time.hours')}
                      onChange={() => {
                        if (isSubmitted) {
                          trigger('time.hours')
                        }
                      }}
                      postFix={<div className='sapp-post-fix-text-field '>mins</div>} // skeleton={loadingFetchData}
                    ></HookFormTextField>
                  </div>
                </div>
              </div>
            )}
            {/* end:: Time of Test*/}
            {/* start:: Grading Preference*/}

            <div className='mb-10'>
              <label className='d-flex align-items-center fw-bold form-label required'>
                <span>Grading Preference</span>
              </label>
              <div>
                <div>
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='gradingPreference'
                      control={control}
                      justify='start'
                      // gap={20}
                      options={Object.values(GRADING_PREFERENCE ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      disabled={true}
                      itemWidth={'50%'}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* end:: Grading Preference*/}
            {/* start:: Pass Points*/}
            <div className='mb-6'>
              <label className='d-flex align-items-center fw-bold form-label required mb-1'>
                <span>Pass Point</span>
              </label>
              <div className='d-flex justify-content-between'>
                <div className='mb-4 w-50'>
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='passPoint.type'
                      control={control}
                      options={Object.values(PASS_POINT ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      // gap={4}
                      disabled={!editAble}
                      itemWidth={'50%'}
                    />
                  </div>
                </div>
                {watchPassPoint?.type === PASS_POINT.manual.value && (
                  <div className='align-self-end sapp-w-49'>
                    <HookFormTextField
                      control={control}
                      name='passPoint.value'
                      placeholder=''
                      className='sapp-h-45px fs-6 '
                      postFix={<div className='ps-3 pe-3 text-gray-700'>%</div>}
                      // skeleton={loadingFetchData}
                    ></HookFormTextField>
                  </div>
                )}
              </div>
            </div>
            {/* end:: Pass Points*/}
            {/* start:: Attempt*/}
            <div className='mb-6'>
              <label className='d-flex align-items-center fw-bold form-label required mb-1'>
                <span>Attempt</span>
              </label>
              <div className='d-flex justify-content-between'>
                <div className='mb-4 w-50'>
                  <div className='mt-3'>
                    <HookFormRadioGroup
                      labelClass='fw-semibold fs-6'
                      direction='horizontal'
                      separator={false}
                      name='attempt.type'
                      control={control}
                      options={Object.values(ATTEMPT ?? {}).map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      // gap={4}
                      disabled={!editAble}
                      itemWidth={'50%'}
                    />
                  </div>
                </div>
                {watchAttempt?.type === ATTEMPT.limited.value && (
                  <div className='align-self-end sapp-w-49'>
                    <HookFormTextField
                      control={control}
                      name='attempt.value'
                      placeholder=''
                      className='sapp-h-45px fs-6'
                      disabled={!editAble}
                      // skeleton={loadingFetchData}
                    ></HookFormTextField>
                  </div>
                )}
              </div>
            </div>
            {/* end:: Attempt*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingTab
