import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import { User } from '../staff-list/components/core/_models'

type Props = {
  title?: string
  tableProps: PropsWithChildren<HeaderProps<User>>
  className?: string
}
const UserCustomHeader: FC<Props> = ({ title, tableProps, className}) => {
  return <th {...tableProps.column.getHeaderProps()} className={`${className??''}`}>{title}</th>
}

export default UserCustomHeader
