import React, { useEffect } from 'react'
import { Control, FieldError } from 'react-hook-form'
import { Col } from 'react-bootstrap'
import { Select } from 'antd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { LIST_SECTION_OPTION, OPTIONS_OF_CREATE_SECTION } from 'src/constants/entrancetest'

type Props = {
  error?: FieldError | undefined
  control: Control<any>
  index: number
  postionIndex?: number
  categorySections?: []
  section: any
  setValue: any
  getValue: any
  onChangeCategory: any
}

const { Option } = Select
const InformationTab = ({
  control,
  index,
  section,
  postionIndex,
  categorySections,
  setValue,
  getValue,
  onChangeCategory,
}: Props) => {
  const sectionDisable = index !== postionIndex
  useEffect(() => {
    if (section?.id) {
      Object.entries(section).map((e) => {
        setValue(`section.${index}.${e[0]}`, e[1])
      })
    }
  }, [section, sectionDisable])
  return (
    <div className='w-full'>
      <HookFormTextField
        label={LIST_SECTION_OPTION.name}
        required
        control={control}
        name={`section.${index}.name`}
        placeholder=' '
        disabled={sectionDisable}
        className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field'
      />
      <div className='mt-4 d-flex w-100 mb-8 gap-5'>
        <div className='sapp-flex-1'>
          <label className='sapp-fs-14 fw-bold form-label'>
            <span className='required'>{LIST_SECTION_OPTION.question_category_id}</span>
          </label>
          <HookFormSelectAntd
            required
            size='large'
            name={`section.${index}.question_category_id`}
            control={control}
            defaultValue={section?.question_category?.id}
            placeholder=' '
            disabled={sectionDisable}
            className='sapp-h-48px fs-6 lh-1 sapp-table-class-field w-full'
            onChange={(value) => onChangeCategory(value)}
          >
            {categorySections?.map((item: any) => (
              <Option key={item.id} value={item.id} >
                {item.name}
              </Option>
            ))}
          </HookFormSelectAntd>
        </div>
        {/* </Col> */}
        {/* <Col md={6} className="mb-8"> */}
        <div className='sapp-flex-1'>
          <HookFormTextField
            label={LIST_SECTION_OPTION.pass_point}
            required
            control={control}
            name={`section.${index}.pass_point`}
            placeholder=' '
            type='number'
            disabled={sectionDisable}
            className='w-100 sapp-h-48px fs-6 lh-1 sapp-table-class-field w-full'
            postFix={<div className='px-4'>%</div>}
            defaultValue={70}
          />
        </div>
        {/* </Col> */}
      </div>
      <HookFormEditor
        label={LIST_SECTION_OPTION.pass_note}
        required
        height={300}
        defaultValue={getValue(`section.${index}.pass_note`) || section.pass_note}
        placeholder=''
        name={`section.${index}.pass_note`}
        control={control}
        math={true}
        disabled={sectionDisable}
        className='w-100 fs-6'
      />
      <Col className='mt-8'>
        <HookFormEditor
          label={LIST_SECTION_OPTION.failure_note}
          required
          height={300}
          defaultValue={getValue(`section.${index}.failure_note`) || section.failure_note}
          placeholder=' '
          name={`section.${index}.failure_note`}
          control={control}
          math={true}
          disabled={sectionDisable}
          className='w-100 fs-6'
        />
      </Col>
    </div>
  )
}

export default InformationTab
