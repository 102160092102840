import {FC, useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import {useMutation, useQueryClient} from 'react-query'
import {resetPassUser, UsersAPI} from 'src/apis/user'
import PopupConfirm from 'src/common/PopupConfirm'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import ModalResetPassword from 'src/components/user-list/components/user-edit-modal/ModalResetPassword'
import UserAction from 'src/components/user-management/UserAction'
import {DENIED_PERMISSIONS, DURATION_EMAIL, PageLink, TEACHER_PROFILE} from 'src/constants'
import {useUserContext} from 'src/context/UserProvider'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {CODE_ADMIN, TITLE_GR} from 'src/constants/permission'
import {Role} from 'src/type'

type Props = {
  id: any
  status: 'BLOCKED'
}

const TeacherActionsCell: FC<Props> = ({id, status}) => {
  const [openReset, setOpenReset] = useState(false)
  const [openBlocked, setOpenBlocked] = useState(false)
  const {clearSelected} = useListView()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const [openModalShowPass, setOpenModalShowPass] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [showInputOtp, setShowInputOTP] = useState(false)
  const [duration, setDuration] = useState(30)
  const [isActive, setIsActive] = useState(false)
  const queryClient = useQueryClient()
  const {query, refetch} = useQueryResponse()
  const {sendEmailOTPUser} = useUserContext()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const blockUser = useMutation(() => UsersAPI.blocked([id]), {
    onSuccess: () => {
      queryClient.invalidateQueries([`teachers-${query}`])
      clearSelected()
      toast.success('Blocked Successfully!')
      setOpenBlocked(false)
      cancel(true)
    },
    onError: () => {
      toast.error('Blocked Failed!')
    },
  })

  const handleShowPassword = async () => {
    await resetPassUser(id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setOpenReset(false)
        setOpenModalShowPass(true)
        setNewPassword(res?.data?.password)
      })
      .catch((err) =>{
        if (err?.response?.data?.error?.code === '403|000000') {
          toast.error(DENIED_PERMISSIONS);
        } else  toast.error('Reset password failed!')
      })
  }

  const onSubmitSendOTP = async (email: string) => {
    if (isActive) return

    sendEmailOTPUser(id, email).then((res: any) => {
      setDuration(DURATION_EMAIL)
      setShowInputOTP(true)
      setIsActive(true)
    })
  }
  return (
    <>
      <UserAction
        setOpenBlocked={setOpenBlocked}
        setOpenReset={setOpenReset}
        status={status}
        linkProfile={`${TEACHER_PROFILE}/${id}/overview`}
        setOpenEditEmail={setOpenEditEmail}
        linkEdit={`${PageLink.UPDATE_TEACHERS}/${id}`}
      />
      <PopupConfirm
        open={openBlocked}
        setOpen={setOpenBlocked}
        onClick={async () => await blockUser.mutateAsync()}
      />
      <PopupConfirm
        open={openReset}
        setOpen={setOpenReset}
        onClick={handleShowPassword}
        body='Bạn có chắc chắn muốn đổi mật khẩu không?'
        okButtonCaption='Yes, reset!'
      />
      <ModalResetPassword
        open={openModalShowPass}
        setOpen={setOpenModalShowPass}
        newPassword={newPassword}
        content='Mật khẩu mới của giáo viên là:'
      />
      <ModalEditEmail
        open={openEditEmail}
        setOpen={setOpenEditEmail}
        id={id}
        showInputOtp={showInputOtp}
        duration={duration}
        isActive={isActive}
        setDuration={setDuration}
        setIsActive={setIsActive}
        onSubmitSendOTP={onSubmitSendOTP}
        cancel={cancel}
      />
    </>
  )
}

export {TeacherActionsCell}
