import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  DENIED_PERMISSIONS,
  DURATION_EMAIL,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  STATUS_FORM,
  TEACHER_PROFILE,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FILED,
} from 'src/constants'
import { z } from 'zod'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import { phoneRegExp, sizeInBytes, usernamePattern } from 'src/utils'
import { isUndefined } from 'lodash'
import { toast } from 'react-hot-toast'
import { IError } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { uploadFile, UsersAPI } from 'src/apis/user'
import { useUserContext } from 'src/context/UserProvider'
import ModalEditEmail from 'src/components/staff-profile/ModalEditEmail'
import { IUpdateTeacher } from 'src/pages/teachers/CreateAndUpdateTeacher'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import PopupConfirm from 'src/common/PopupConfirm'
const { Option } = Select

interface IInputProps {
  full_name: string
  email: string
  username: string
  status: string
  phone: number
  password: string
  confirmPassword: string
  avatar: File
  sex: string
}

interface IProps {
  teacherDetail: IStudentDetail | undefined
  setTeacherDetail: Dispatch<SetStateAction<any>>
  loading: boolean
}

const EditProfileTeacher = ({ teacherDetail, setTeacherDetail }: IProps) => {
  const navigate = useNavigate()

  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [showInputOtp, setShowInputOTP] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const { sendEmailOTPUser } = useUserContext()
  const { id } = useParams()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(`${TEACHER_PROFILE}/${id}/overview`),
    })
  }

  const onSubmitSendOTP = (email: string) => {
    if (isActive) return

    sendEmailOTPUser(id, email).then(() => {
      setDuration(DURATION_EMAIL)
      setShowInputOTP(true)
      setIsActive(true)
    })
  }

  const schema = z.object({
    full_name: z.string({ required_error: VALIDATION_FILED }).min(3, { message: VALIDATE_MIN_FULLNAME }).max(100, { message: VALIDATE_MAX_FULLNAME }),
    email: z.string({ required_error: VALIDATION_FILED }).email(),
    status: z.string(),
    phone: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    avatar: z.any(),
    sex: z.string(),
    username: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(usernamePattern), {
        message:
          'Username must have at least 6 characters without Vietnamese and space between words',
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
  })

  const { control, handleSubmit, setValue, setError } = useForm<IInputProps>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const onSubmit = async (data: IInputProps) => {
    const { email, full_name, phone, username, status, sex, avatar } = data

    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)) return

    if (!isUndefined(avatar)) {
      await uploadFile({ userId: teacherDetail?.id, avatar: avatar })
    }

    setLoadingSubmit(true)
    try {
      const paramsUpdate: IUpdateTeacher = {
        email: email.toLowerCase(),
        full_name: full_name,
        phone: phone,
        username: username,
        status: status,
        sex: sex || null,
        avatar: null,
      }
  
      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }
  
     await UsersAPI.update({
        id: teacherDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update SuccessFully!')
          navigate(`${TEACHER_PROFILE}/${teacherDetail?.id}/overview`)
        })
        .catch((err: any) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message
  
            setError(e.property, { message: errorMessage })
          }, {})
          if (err?.response?.data?.error?.code === '403|000000') {
            toast.error(DENIED_PERMISSIONS);
          }
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  useEffect(() => {
    if (!teacherDetail?.id) return

    setValue('email', userContact?.email ?? '')
    setValue('full_name', teacherDetail?.detail?.full_name ?? '')
    setValue('phone', userContact?.phone ?? '')
    setValue('status', teacherDetail?.status ?? '')
    setValue('username', teacherDetail?.username ?? '')
    setValue('sex', teacherDetail?.detail?.sex ?? '')
  }, [
    setValue,
    userContact?.email,
    teacherDetail?.detail?.full_name,
    userContact?.phone,
    teacherDetail?.detail?.sex,
    teacherDetail?.id,
    teacherDetail?.status,
    teacherDetail?.username,
  ])
  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
        </div>

        <div className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Avatar</label>
                <div className='col-lg-9'>
                  <SAPPHookUploadFile
                    name='avatar'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={teacherDetail?.detail?.avatar?.['150x150'] ?? teacherDetail?.detail?.avatar?.ORIGIN ?? avatar}
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowAvatarDefault={setShowAvatarDefault}
                    removeAvatar={avatar}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                  {LANG_PLACEHOLDER.FULLNAME}
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='full_name'
                    control={control}
                    placeholder={LANG_PLACEHOLDER.FULLNAME}
                    defaultValue={teacherDetail?.detail?.full_name}
                    required
                    guideline={GUIDELINE_FULLNAME}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                  Username
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='username'
                    control={control}
                    placeholder='Username'
                    defaultValue={teacherDetail?.username}
                    disabled
                    required
                    guideline={GUIDELINE_USERNAME}
                  />
                </div>
              </div>

              <div className='row mb-md-6 mb-0'>
                <label className='col-xl-3 col-lg-3 col-form-label required fw-semibold fs-6'>
                  Email
                </label>

                <div className='col-xl-8 col-lg-7 col-md-10 fv-row'>
                  <HookFormTextField
                    name='email'
                    control={control}
                    placeholder='Email'
                    defaultValue={teacherDetail?.detail?.email}
                    required
                    disabled
                    guideline={GUIDELINE_EMAIL}
                  />
                </div>
                <div
                  className='col-xl-1 col-lg-2 col-md-2 d-flex justify-content-end mt-md-0 mt-3'
                  onClick={() => setOpenEditEmail(true)}
                >
                  <ButtonPrimary title='Edit' className='sapp-p-button--custom' />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Phone</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='phone'
                    control={control}
                    placeholder='Phone Number'
                    defaultValue={teacherDetail?.detail?.phone}
                    required
                    disabled
                    guideline={GUIDELINE_PHONE}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Status</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    name='status'
                    control={control}
                    size='large'
                    defaultValue={teacherDetail?.status}
                    className="fs-6"
                  >
                    {STATUS_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Gender</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    name='sex'
                    control={control}
                    size='large'
                    defaultValue={teacherDetail?.detail?.sex ?? ''}
                    placeholder='Please select'
                    className="fs-6"
                  >
                    {GENDER_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary
                title='Discard'
                className='btn-active-light-primary me-2'
                onClick={hanleCancel}
              />
              <ButtonPrimary title='Save Changes' onClick={() => setOpenPopupConfirm(true)} loading={loadingSubmit}/>
            </div>
          </div>
        </div>
        <ModalEditEmail
          duration={duration}
          open={openEditEmail}
          setDuration={setDuration}
          setOpen={setOpenEditEmail}
          showInputOtp={showInputOtp}
          isActive={isActive}
          onSubmitSendOTP={onSubmitSendOTP}
          setIsActive={setIsActive}
          setUserDetail={setTeacherDetail}
        />
        <PopupConfirm
          open={openPopupConfirm}
          setOpen={setOpenPopupConfirm}
          onClick={handleSubmit(onSubmit)}
          body='Bạn có chắc chắn muốn lưu không?'
          okButtonCaption='Yes, save'
        />
      </div>
    </>
  )
}

export default EditProfileTeacher
